import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useEventApi() {
  const callApi = useApiCall();


  const getAllEvents = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.GET_EVENTS, data, onSuccess, onError);
  };

  const getEventDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.GET_EVENT_DETAILS, data, onSuccess, onError);
  };
  const updateEvent = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.UPDATE_EVENT, data, onSuccess, onError);
  };
  const saveEvent = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.SAVE_EVENT, data, onSuccess, onError);
  };
  const deleteEvent = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.DELETE_EVENT, data, onSuccess, onError);
  };
  const eventChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.CHANGE_STATUS_EVENT, data, onSuccess, onError);
  };

  const getAllEventtypes = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.GET_EVENTS_TYPES, data, onSuccess, onError);
  };

  const getAllEventorganiser = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.GET_EVENTS_ORGANISER, data, onSuccess, onError);
  };
  const uploadEventAttendee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.UPLOAD_EVENT_ATTENDEE, data, onSuccess, onError);
  };

  const getEventFarmerImportAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EVENT.GET_EVENT_FARMER_IMPORT_ALL, data, onSuccess, onError);
  };


  return {
    getAllEvents, getEventDetails, saveEvent, updateEvent, deleteEvent, eventChangeStatus, getAllEventtypes, getAllEventorganiser, uploadEventAttendee,
    getEventFarmerImportAll
  };
}
