import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useReportApi() {
  const callApi = useApiCall();


  const getFarmerList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.REPORT.FARMER_LIST, data, onSuccess, onError);
  };
 
  const getFarmList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.REPORT.FARM_LIST, data, onSuccess, onError);
  };
 
  const getFarmerContactList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.REPORT.FARMER_CONTACT_LIST, data, onSuccess, onError);
  };
  const getFarmerTrainingAttendanceList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.REPORT.FARMERS_TRAINING_ATTENDANCE_LIST, data, onSuccess, onError);
  };
 



  return {
    getFarmerList, getFarmList, getFarmerContactList, getFarmerTrainingAttendanceList
  };
}
