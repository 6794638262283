import { Outlet, Navigate } from 'react-router-dom';
import { useAuthStatus } from '../_common/hooks/authHook';
import { URLS } from '.';

const PrivateRoutes = () => {
    const isAuth = useAuthStatus();
    return (
        isAuth ? <Outlet /> : <Navigate to={URLS.LOGIN} />
    )
}

export default PrivateRoutes;