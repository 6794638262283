import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDeviceApi } from "../../_common/hooks/api/deviceApiHook";
import { URLS } from "../../_config";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import TitleBar from "../../components/TopBar/TitleBar";
import Loader from "../../components/loader";
import Footer from "../../components/footer/Footer";
import Status from "../../components/status";

const DeviceDetails = () => {
    const deviceapi = useDeviceApi();
    const [dataReset, setDataReset] = useState<boolean>(false)

    const params: any = useParams();

    const [id, setId] = useState<any>(params.id)
    const [devicedetail, setDeviceDetails] = useState<any>({})

    const getDeviceDetails = () => {
        deviceapi.getDeviceDetails({ urlParams: { DeviceId: id } }, (message: any, resp: any) => {
            let devicedetail: any = resp.data?.device[0];
            setDeviceDetails(devicedetail);
            setDataReset(true)
        }, (message: any) => {
            //----
        })
    }

    useEffect(() => {
        if (id) {
            getDeviceDetails()
        }
    }, [id])

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`Device  Details`}
                            breadcrumbs={{
                                'IoT Device': '#',
                                'DEVICE ': URLS.DEVICE_LIST,
                                'Details': '#',
                            }} />


                        {id && !dataReset ? <Loader /> :
                            <div className="row" data-aos="fade-up">
                                <div className="col-sm-3">
                                    <div className="white-box-card profile-card h-100 ">
                                        <h3 className="card-heading mb-4 ">Device  Details</h3>

                                        <h4 className="md-text mb-3">{devicedetail?.name ? devicedetail?.name : '--'}</h4>

                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Device Category</h5>
                                        <p className="text-break">{devicedetail?.deviceTemplate ? devicedetail?.deviceTemplate : '--'}</p>

                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Assignment Status</h5>
                                        <p><Status status={devicedetail?.assignmentStatus} /></p>

                                        <h5 className="xsm-text light-text mb-0 text-uppercase">assigned Station</h5>
                                        {
                                            devicedetail?.assignedStation ? <p><Status status={devicedetail?.assignedStation} /></p> : <p>--</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="white-box-card mb-4 h-100 ">
                                        <div className="row">
                                            <div className="col-sm-12">

                                                <div className="row">

                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Device Description</h5>
                                                        <p>{devicedetail?.description ? devicedetail?.description : '--'}</p>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Device Serial Number</h5>
                                                        <p>{devicedetail?.deviceSerialNumber ? devicedetail?.deviceSerialNumber : '--'}</p>
                                                    </div>



                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Device MqttUsername</h5>
                                                        <p>{devicedetail?.mqttUsername ? devicedetail?.mqttUsername : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Device MqttPassword</h5>
                                                        <p>{devicedetail?.mqttSubTopic ? devicedetail?.mqttSubTopic : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Device MqttPubTopic</h5>
                                                        <p>{devicedetail?.mqttPubTopic ? devicedetail?.mqttPubTopic : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Device MqttClientID</h5>
                                                        <p>{devicedetail?.mqttClientID ? devicedetail?.mqttClientID : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Device MqttHost</h5>
                                                        <p>{devicedetail?.mqttHost ? devicedetail?.mqttHost : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Working Status</h5>
                                                        <p>{devicedetail?.workingStatus ? devicedetail?.workingStatus : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase">Instalation Location</h5>
                                                        <p>{`${devicedetail?.districtName}, ${devicedetail?.blockName}, ${devicedetail?.gpuName}, ${devicedetail?.wardName}`}</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="mt-3 d-flex gap-2 justify-content-end ">
                                            <Link to={URLS.DEVICE_LIST} className="btn btn-default">Back  </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default DeviceDetails;