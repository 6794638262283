import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";

interface props {
    pageName?: string
    setSearch: (txt: string) => void
    setDistricts: (list: any) => void
    setBlocks: (list: any) => void
    setGpus: (list: any) => void
    setWards: (list: any) => void

    setKhasraNumber?: (txt: string) => void
    setPorchaNumber?: (txt: string) => void

    eventtypes?: any
    evenStatus?: any
    setEventTypeID?: (txt: any) => void
    setEventStatus?: (txt: any) => void
}


const SearchWithLocation = ({ pageName, setSearch, setDistricts, setBlocks, setGpus, setWards,
    setKhasraNumber, setPorchaNumber,
    eventtypes, evenStatus, setEventTypeID, setEventStatus
}: props) => {
    const { control, handleSubmit, reset, watch } = useForm();

    const districtWatch = watch("district");
    const blockWatch = watch("blocks");
    const gpuWatch = watch("gpus");

    const [fieldColClass, setFieldColClass] = useState<string>('col-sm-4')

    const [filteredBlocks, setFilteredBlocks] = useState<any[] | null>(null)
    const [filteredGpus, setFilteredGpus] = useState<any[] | null>(null)
    const [filteredWards, setFilteredWards] = useState<any[] | null>(null)

    const districts: any = useDistricts();
    const blocks: any = useBlocks();
    const gpus: any = useGpus();
    const wards: any = useWards();


    useEffect(() => {
        if (districtWatch?.length) {
            let _blocks: any = [];
            let _gpus: any = [];
            let _wards: any = [];
            blocks.map((block: any) => {
                districtWatch.map((dist: any) => {
                    if (block.fkDistrictID == dist.districtId) {
                        _blocks.push(block);
                    }
                })
            })
            gpus.map((gpu: any) => {
                districtWatch.map((dist: any) => {
                    if (gpu.fkDistrictID == dist.districtId) {
                        _gpus.push(gpu);
                    }
                })
            })
            wards.map((ward: any) => {
                districtWatch.map((dist: any) => {
                    if (ward.fkDistrictID == dist.districtId) {
                        _wards.push(ward);
                    }
                })
            })
            setFilteredBlocks(_blocks);
            setFilteredGpus(_gpus);
            setFilteredWards(_wards);
        } else {
            setFilteredBlocks(null);
            setFilteredGpus(null);
            setFilteredWards(null);
        }
    }, [districtWatch])


    useEffect(() => {
        if (blockWatch?.length) {
            let _gpus: any = [];
            let _wards: any = [];
            gpus.map((gpu: any) => {
                blockWatch.map((block: any) => {
                    if (gpu.fkBlockID == block.blockID) {
                        _gpus.push(gpu);
                    }
                })
            })
            wards.map((ward: any) => {
                console.log('ward', ward)
                blockWatch.map((block: any) => {
                    if (ward.fkBloackID == block.blockID) {
                        _wards.push(ward);
                    }
                })
            })
            setFilteredGpus(_gpus);
            setFilteredWards(_wards);
        } else {
            setFilteredGpus(null);
            setFilteredWards(null);
        }
    }, [blockWatch])


    useEffect(() => {
        if (gpuWatch?.length) {
            let _wards: any = [];
            wards.map((ward: any) => {

                gpuWatch.map((gpu: any) => {
                    if (ward.fkgpuid == gpu.gpiid) {
                        _wards.push(ward);
                    }
                })
            })
            setFilteredWards(_wards);
            console.log(gpuWatch, wards);
        } else {
            setFilteredWards(null);
        }
    }, [gpuWatch])

    useEffect(() => {
        if (pageName === 'farms' || pageName === 'farms-report' || pageName === 'events') {
            setFieldColClass('col-sm-3')
        }
    }, [pageName])


    const submitHandler = (data: any) => {
        setSearch(data.search)
        if (pageName === 'farms' || pageName === 'farms-report') {
            setKhasraNumber && setKhasraNumber(data.khasraNumber)
            setPorchaNumber && setPorchaNumber(data.porchaNumber)
        }
        if (pageName === 'events') {
            setEventTypeID && setEventTypeID(data?.eventype?.eventTypeID)
            setEventStatus && setEventStatus(data?.evenStatus?.value)
        }
        setDistricts(Array.isArray(data?.district)
            ? data?.district?.map((data: any) => {
                return [data.districtId]
            }) : [])
        setBlocks(Array.isArray(data?.blocks)
            ? data?.blocks?.map((data: any) => {
                return [data.blockID]
            }) : [])
        setGpus(Array.isArray(data?.gpus)
            ? data?.gpus?.map((data: any) => {
                return [data.gpiid]
            }) : [])
        setWards(Array.isArray(data?.wards)
            ? data?.wards?.map((data: any) => {
                return [data.wardID]
            }) : [])
    }

    const resetHandler = (data: any) => {
        reset({ search: '', districts: [], blocks: [], gpus: [], wards: [] })
        setSearch('')
        if (pageName === 'farms' || pageName === 'farms-report') {
            setKhasraNumber && setKhasraNumber('')
            setPorchaNumber && setPorchaNumber('')
        }
        if (pageName === 'events') {
            setEventTypeID && setEventTypeID('')
            setEventStatus && setEventStatus('')
        }
        setDistricts([])
        setBlocks([])
        setGpus([])
        setWards([])
    }



    return (
        <form action="" onSubmit={handleSubmit(submitHandler)}>
            <div className="row mb-3">
                {/* <div className="col-sm-10"> */}
                <div className="row">
                    <div className={`${fieldColClass} mt-3`}>
                        <div className="form-group float-label-wrap mb-0">
                            <label htmlFor="" className="float-label">Select District</label>
                            <Controller
                                defaultValue={""}
                                control={control}
                                name="district"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <Select
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        options={districts ? districts : []}
                                        value={value}
                                        isMulti={true}
                                    />
                                )} />
                        </div>
                    </div>

                    <div className={`${fieldColClass} mt-3`}>
                        <div className="form-group float-label-wrap mb-0">
                            <label htmlFor="" className="float-label">Select Block</label>
                            <Controller
                                defaultValue={""}
                                control={control}
                                name="blocks"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <Select
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        options={filteredBlocks != null ? filteredBlocks : blocks}
                                        value={value}
                                        isMulti={true}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className={`${fieldColClass} mt-3`}>
                        <div className="form-group float-label-wrap mb-0">
                            <label htmlFor="" className="float-label">Select GPU</label>
                            <Controller
                                defaultValue={""}
                                control={control}
                                name="gpus"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <Select
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        options={filteredGpus != null ? filteredGpus : gpus}
                                        value={value}
                                        isMulti={true}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className={`${fieldColClass} mt-3`}>
                        <div className="form-group float-label-wrap mb-0">
                            <label htmlFor="" className="float-label">Select Ward</label>
                            <Controller
                                defaultValue={""}
                                control={control}
                                name="wards"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <Select
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        options={filteredWards != null ? filteredWards : wards}
                                        value={value}
                                        isMulti={true}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    {
                        (pageName === 'farms' || pageName === 'farms-report') &&
                        <>
                            <div className={`${fieldColClass} mt-3`}>
                                <div className="form-group float-label-wrap mb-0">
                                    <label htmlFor="" className="float-label">Khasra Number</label>
                                    <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="khasraNumber"
                                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                            <input type="text" name={name} onChange={onChange} value={value} className="form-control" placeholder="Khasra Number..." />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={`${fieldColClass} mt-3`}>
                                <div className="form-group float-label-wrap mb-0">
                                    <label htmlFor="" className="float-label">Porcha Number</label>
                                    <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="porchaNumber"
                                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                            <input type="text" name={name} onChange={onChange} value={value} className="form-control" placeholder="Porcha Number..." />
                                        )}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    {
                        (pageName === 'events') &&
                        <>
                            <div className={`${fieldColClass} mt-3`}>
                                <div className="form-group float-label-wrap mb-0 mt-2">
                                    <label htmlFor="" className="float-label">Event type</label>
                                    <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="eventype"
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                        }) => (
                                            <Select
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                options={eventtypes ? eventtypes : []}
                                                value={value}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={`${fieldColClass} mt-3`}>
                                <div className="form-group float-label-wrap mb-0 mt-2">
                                    <label htmlFor="" className="float-label">Status</label>
                                    <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="evenStatus"
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                        }) => (
                                            <Select
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                options={evenStatus}
                                                value={value}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    <div className={`${fieldColClass} mt-3`}>
                        <div className="form-group float-label-wrap mb-0">
                            <label htmlFor="" className="float-label">Search</label>
                            <Controller
                                defaultValue={""}
                                control={control}
                                name="search"
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" placeholder="Search..." />
                                )}
                            />
                        </div>
                    </div>
                    <div className={`${fieldColClass} mt-3`}>
                        <div className="two-btn-wrap">
                            <button type="button" className="btn btn-grey" onClick={resetHandler}>Reset</button>
                            <button type="submit" className="btn btn-blue">Search</button>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </form>

    )
}

export default SearchWithLocation;