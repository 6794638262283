import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { useCollatralApi } from "../_common/hooks/api/collateralApiHook";
import { useUser } from "../_common/hooks/authHook";
import Loader from "../components/loader";
import { Controller, useForm } from "react-hook-form";
import { toastHandeler } from "../_common/functions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { MAPAPI_BASE_URL } from "../_config/site_urls";
import Icon from "../pages/map/components/icon";
import ForecastTemperatureModal from "../pages/map/modals/forecastTemperature.modal";
import { useFarmsApi } from "../_common/hooks/api/farmApiHook";
import moment from "moment";

interface props {
    show: any
    hide: any
    fieldID: any
}
const TipsAndSuggestionsModal = ({ show, hide, fieldID }: props) => {
    const { showLoader, hideLoader } = useAppLoader();
    const farmsApi = useFarmsApi();
    const [alerts, setAlerts] = useState<any>(null);
    const [showAlert, setShowAlert] = useState<any>(null);
    const [field, setField] = useState<any>(null);
    const [farm, setFarm] = useState<any>(null);

    const getFieldDeatils = async () => {
        showLoader();
        try {
            const response = await fetch(MAPAPI_BASE_URL + "/fields/" + fieldID);
            const data = await response.json();
            setField(data);
            getFarmDetails(data.farm_id);
            
        } catch (error) {
            
            console.error("Error fetching data:", error);
        }
    }

    const getFarmDetails = (farmID:any) => {
        farmsApi.farmDetails({ urlParams: { FarmID: farmID } }, (message: any, resp: any) => {
            let farm: any = resp.data?.farm[0];
            setFarm(farm)
            hideLoader();
        }, (message: any) => {
            setFarm(null)
            hideLoader();
        })
    }

    const getFieldAlerts = async () => {
        showLoader();
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/alerts?${new URLSearchParams({
                field_or_farm_id: fieldID,
            })}`);
            const data: any = await response.json();

            setAlerts(data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        if (show) {
            getFieldDeatils();
            getFieldAlerts();
        } else {
            setField(null);
            setFarm(null);
            setAlerts(null);
        }
    }, [show])
    return (
        <Offcanvas show={show} onHide={hide} placement={"end"} className="farming-method-offcanvas">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Tips and Suggestions</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            {alerts && alerts.length > 0 &&
                            <div className='mb-3'>
                                <p className='m-0  border-b border-gray-400 mb-2 py-1 font-semibold text-lg '>Alerts</p>
                                <div className=''>
                                    {alerts.map((alert: any, i: number) => (
                                        <div className={`flex gap-2 items-start p-2  mb-2 rounded border-1 
                                        ${alert.is_ucl?'bg-orange-50':'bg-blue-50'} 
                                        ${alert.is_ucl?'border-orange-200':'border-blue-200'}`} key={`alert-${i}}`}>
                                            <div className='w-[32px] flex-shrink-0 flex items-center justify-center'>
                                                <div className={`w-[32px] h-[32px] rounded flex justify-center items-center
                                                    ${alert.is_ucl ? 'bg-orange-200' : 'bg-blue-200'} 
                                                `}>
                                                    <Icon name='crop' size={24} className={alert.is_ucl ? 'text-orange-500' : 'text-blue-500'} />
                                                </div>
                                            </div>

                                            <div className='flex-grow'>
                                                <p className='m-0 text-xs text-grey-800'>{alert.message}<br />
                                                    {alert.is_read ? <span className='mt-2 block text-green-500'>Suggestion published</span> : null}
                                                </p>
                                                <div className='flex justify-between items-center'>
                                                    <p className={`m-0 pt-1 font-size-11 italic font-semibold
                                                        ${alert.is_ucl?'text-orange-500':'text-blue-500'} 
                                                        `}>{moment(alert.timestamp).format('DD MMM, YYYY, hh:mm A')}</p>
                                                    <div>
                                                        {alert.is_read ? null : 
                                                        <div className={`rounded flex items-center gap-1 py-1 px-2 cursor-pointer border-1
                                                        ${alert.is_ucl?'bg-orange-100':'bg-blue-100'} 
                                                        ${alert.is_ucl?'border-orange-200':'border-blue-200'} 
                                                        `}>
                                                            <Icon name='bulb' size={24} className='text-yellow-300' onClick={() => { setShowAlert(alert) }} />
                                                            <p className={`m-0 text-xs font-semibold ${alert.is_ucl?'text-orange-500':'text-blue-500'} `}>Tips</p>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }   

                

                {alerts && farm && alerts.length == 0 && <p className='text-center'>Suggestion not  found</p>}
                <ForecastTemperatureModal open={showAlert != null} close={() => { setShowAlert(null) }} alert={showAlert} farm={farm} />
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default TipsAndSuggestionsModal;