import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useCollatralApi() {
  const callApi = useApiCall();


  const cropCategoryList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.LIST, data, onSuccess, onError);
  };
  const cropCategoryDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.DETAILS, data, onSuccess, onError);
  };
  const cropCategorySave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.SAVE, data, onSuccess, onError);
  };
  const cropCategoryUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.UPDATE, data, onSuccess, onError);
  };
  const cropCategoryChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.CHANGE_STATUS, data, onSuccess, onError);
  };
  const cropCategoryDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.DELETE, data, onSuccess, onError);
  };




  const cropList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.LIST, data, onSuccess, onError);
  };
  const cropDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.DETAILS, data, onSuccess, onError);
  };
  const cropSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.SAVE, data, onSuccess, onError);
  };
  const cropUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.UPDATE, data, onSuccess, onError);
  };
  const cropChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.CHANGE_STATUS, data, onSuccess, onError);
  };
  const cropDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.DELETE, data, onSuccess, onError);
  };

  const cropFileUpload = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.FILE_UPLOAD, data, onSuccess, onError);
  };



  const cropCultivationMethods = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.CULTIVATION_METHODS_VIEW, data, onSuccess, onError);
  };
  const saveCultivationMethods = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.CULTIVATION_METHODS_UPDATE, data, onSuccess, onError);
  };
  const cropProtectionMethods = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.PROTECTION_METHODS_VIEW, data, onSuccess, onError);
  };
  const saveProtectionMethods = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP.PROTECTION_METHODS_UPDATE, data, onSuccess, onError);
  };



  const cropProtectionMethodList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.LIST, data, onSuccess, onError);
  };
  const cropProtectionMethodDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.DETAILS, data, onSuccess, onError);
  };
  const cropProtectionMethodSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.SAVE, data, onSuccess, onError);
  };
  const cropProtectionMethodUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.UPDATE, data, onSuccess, onError);
  };
  const cropProtectionMethodChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.CHANGE_STATUS, data, onSuccess, onError);
  };
  const cropProtectionMethodDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.DELETE, data, onSuccess, onError);
  };



  return {
    cropCategoryList, cropCategoryDetails, cropCategorySave, cropCategoryUpdate, cropCategoryChangeStatus, cropCategoryDelete,

    
    cropList, cropDetails, cropSave, cropUpdate, cropChangeStatus, cropDelete, cropFileUpload,

    cropCultivationMethods, saveCultivationMethods,


    cropProtectionMethodList,
    cropProtectionMethodDetails,
    saveProtectionMethods,
    cropProtectionMethodSave,
    cropProtectionMethods,
    cropProtectionMethodUpdate,
    cropProtectionMethodChangeStatus,
    cropProtectionMethodDelete,



  };
}
