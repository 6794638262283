import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import TitleBar from "../../components/TopBar/TitleBar";
import { URLS } from "../../_config";
import { Link, useParams } from "react-router-dom";
import { useFarmsApi } from "../../_common/hooks/api/farmApiHook";
import Loader from "../../components/loader";


const FarmDetails = () => {
    const params: any = useParams();
    const [dataID, setDataID] = useState<any>(params.id)
    const [dataItem, setDataItem] = useState<any>({})
    const farmsApi = useFarmsApi();
    const [dataReset, setDataReset] = useState<boolean>(false)


    const getFarmDetails = () => {
        farmsApi.farmDetails({ urlParams: { FarmID: dataID } }, (message: any, resp: any) => {
            let dataDetail: any = resp.data?.farm[0];
            setDataItem(dataDetail)
            setDataReset(true)
        }, (message: any) => {
            setDataItem([])
            setDataReset(true)
            //----
        })
    }


    useEffect(() => {
        if (dataID) {
            getFarmDetails()
        }
    }, [dataID])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`  Farm Details `}
                            breadcrumbs={{
                                'Farms': URLS.FARMS.LIST,
                                'Details': '#',
                            }} />

                        {
                            !dataReset ? <Loader /> :
                                <div className="row" data-aos="fade-up">
                                    <div className="col-sm-4">
                                        <div className="white-box-card h-100">
                                            <div className="col-sm-12">
                                                <h3 className="card-heading mb-4">Farm Details</h3>

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <h5 className="xsm-text light-text mb-0">OWNER NAME</h5>
                                                        <p>{dataItem.ownerName}</p>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <h5 className="xsm-text light-text mb-0">PHONE NUMBER</h5>
                                                        <p>{dataItem.ownersPhoneNumber}</p>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <h5 className="xsm-text light-text mb-0">AADHAAR NUMBER</h5>
                                                        <p>{dataItem.ownersAdharNumber ? dataItem.ownersAdharNumber : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <h5 className="xsm-text light-text mb-0">KHASRA NO</h5>
                                                        <p>{dataItem.khasraNumber ? dataItem.khasraNumber : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <h5 className="xsm-text light-text mb-0 text-uppercase ">Khatiyan Number</h5>
                                                        <p>{dataItem.khatiyanNumber ? dataItem.khatiyanNumber : '--'}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="white-box-card h-100 ">

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    {/* <h3 className="card-heading mb-4">Other Details</h3> */}
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <h5 className="xsm-text light-text mb-0">FARM SIZE</h5>
                                                            <p>{dataItem.farmSize} Acre</p>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h5 className="xsm-text light-text mb-0 text-uppercase">Cultivation Area</h5>
                                                            <p>{dataItem.cultivationArea} Hectare</p>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h5 className="xsm-text light-text mb-0 text-uppercase ">Land Type</h5>
                                                            <p>{dataItem.landTypeName ? dataItem.landTypeName : '--'}</p>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h5 className="xsm-text light-text mb-0 text-uppercase ">Ownership Type</h5>
                                                            <p>{(dataItem.ownershipTypeName) ? dataItem.ownershipTypeName : '--'}</p>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h5 className="xsm-text light-text mb-0 text-uppercase ">District</h5>
                                                            <p>{dataItem.districtName}</p>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h5 className="xsm-text light-text mb-0 text-uppercase ">Block</h5>
                                                            <p>{dataItem.blockName}</p>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h5 className="xsm-text light-text mb-0 text-uppercase ">GPU</h5>
                                                            <p>{dataItem.gpuName}</p>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h5 className="xsm-text light-text mb-0 text-uppercase ">Ward</h5>
                                                            <p>{dataItem.wardName}</p>
                                                        </div>
                                                        {/* <div className="col-sm-6">
                                                            <h5 className="xsm-text light-text mb-0 text-uppercase">Porcha</h5>
                                                            <p><a href="#" className="text-primary ">Download</a></p>
                                                        </div> */}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                <Link to={URLS.FARMS.LIST} className="btn btn-default">Back</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                        }



                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default FarmDetails;