import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useGroupApi() {
  const callApi = useApiCall();


  const getAllGroups = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GROUP.GET_GROUPS, data, onSuccess, onError);
  };

  const getGruopDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GROUP.GET_GROUP_DETAILS, data, onSuccess, onError);
  };
  const updateGroup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GROUP.UPDATE_GROUP, data, onSuccess, onError);
  };
  const saveGroup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GROUP.SAVE_GROUP, data, onSuccess, onError);
  };
  const deleteGroup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GROUP.DELETE_GROUP, data, onSuccess, onError);
  };
  const groupChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GROUP.CHANGE_STATUS_GROUP, data, onSuccess, onError);
  };


  return {
    getAllGroups, getGruopDetails, saveGroup, updateGroup, deleteGroup, groupChangeStatus
  };
}
