import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Transition } from "@headlessui/react";
import { API_BASE_URL } from "../_config";
import Select from 'react-select';
import moment from "moment";
import toast from "react-hot-toast";
import { GoogleMap, Marker } from "@react-google-maps/api";
//import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { useAppLoader } from "../../../_common/hooks/common/appLoaderHook";
import { Button, Modal } from 'react-bootstrap'
import { CiCirclePlus } from "react-icons/ci";
interface UpdateDevicesProps {
    open: boolean;
    close: () => void;
    update: () => void;
    field?: any;
}

interface FormData {
    devices: any;
    
}

const UpdateDevices = ({ open, close, field, update }: UpdateDevicesProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { showLoader, hideLoader } = useAppLoader();
    const [devices, setDevices] = useState<any>(null);
    const { control, register, handleSubmit, setValue, reset, watch, formState: { errors, isValid } } = useForm<FormData>({ mode: 'onBlur' });

    const closeModal = () => {
        close();
    }
    const getDevices = async () => {
        try {
            const response = await fetch(API_BASE_URL + "/devices");
            const data = await response.json();
            setDevices(data.map((device: any) => ({ ...device, value: device._id, label: device.name })));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const onSubmit = async (data: FormData) => {
        showLoader();
        let params: any = {
            "name": field.name,
            "device_ids": data.devices.map((device: any) => device._id),
            "crop_ids": field.crops.map((crop: any) => crop._id),
            "area": field.area,
            "area_unit": field.area_unit,
            "location": {
                "latitude": field.location.latitude,
                "longitude": field.location.longitude
            },
            "sowing_date": moment(field.sowing_date).format('YYYY-MM-DD'),
            "harvesting_date": moment(field.harvesting_date).format('YYYY-MM-DD'),
            "boundary": field.boundary
        }


        let API: string = API_BASE_URL + "/fields/"+field._id;



        try {
            // Make API call to save sensor details
            const response = await fetch(API, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }).then(async (response) => {
                if (response.ok) {
                    const result = await response.json();
                    toast.success('Field Update successfully');
                    closeModal();
                    update();
                } else {
                    const result = await response.json();
                    if (typeof result.detail === 'string') {
                        toast.error(result.detail);
                    } else if (typeof result.detail === 'object') {
                        toast.error(result.detail[0].msg);
                    } else {
                        toast.error('Something went wrong');
                    }
                }
                hideLoader();
            }).catch((error) => {
                console.log("Error saving sensor details:", error);
                hideLoader();
            });
        } catch (error) {
            console.log("Error saving sensor details:", error);
            hideLoader();
        }
    };

    useEffect(() => {
        setIsOpen(open);
        if(open && devices){
            console.log('field------>', field);
            setValue('devices', devices.filter((device: any) => {
                let isPresent:boolean = false;
                field.devices.map((fieldDevice: any) => {
                    if(fieldDevice._id === device._id){
                        isPresent = true;
                    }
                });
                return isPresent;
            }));
        }
    }, [open,devices]);

    useEffect(() => {
        getDevices();
    }, []);



    return (
        <Modal show={open} onHide={close} >
            <Modal.Header closeButton>
                <Modal.Title>Update Field Devices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="relative bg-white rounded-lg">

                        <div className="mb-2">
                            <label className="block text-sm font-medium text-gray-700">Devices</label>
                            <Controller
                                control={control}
                                name="devices"
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        isMulti={true}
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={devices ? devices : []}
                                    />
                                )}
                            />
                            {errors.devices && <p className="text-red-600 text-sm">This field is required</p>}
                        </div>

                        <div className="flex justify-end">
                            <button className="bg-green-500 text-gray-50 font-semibold px-3 py-2 rounded">Update</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

    );
};

export default UpdateDevices;