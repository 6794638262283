import { combineReducers } from 'redux';

import loaderReducer from './common/loaderReducer'
// import platformReducer from './platforms/platformReducer';
import userReducer from './userReducer'
import masterReducer from './masterReducer'
import { ACTIONS } from '../../_config';

const appReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  master: masterReducer
  //   platform: platformReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === ACTIONS.RESET_REDUCER) {
    state = undefined;
    localStorage.removeItem('persist:root');
  }

  return appReducer(state, action)
}

export default rootReducer