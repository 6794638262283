import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

interface FormTextAreaInputProps {
    onChange: (...args: any) => void;
    onBlur: () => void;
    onFocus?: () => void;
    onPaste?: (args: React.ClipboardEvent) => void;
    value: any;
    name: string;
    type: string;
    className?: string;
    inputRef?: any;
    placeholder?: string;
    error?: any;
    id?: string;
    pattern?: string;
    maxLength?: number;
    appendText?: string;
    step?: string;
    readOnly?: boolean;
    rows?:number;
}

function FormTexAreatInput({
    onChange,
    onBlur,
    onPaste,
    value,
    name,
    inputRef,
    type,
    placeholder,
    error,
    id,
    pattern,
    onFocus,
    maxLength,
    className,
    step,
    appendText,
    readOnly,
    rows
}: FormTextAreaInputProps) {
    return (
        <>
            <Form.Control
                type={type}
                name={name}
                id={id}
                as="textarea"
                value={value}
                rows={rows ? rows : 8}
                maxLength={maxLength}
                onBlur={onBlur}
                placeholder={placeholder}
                onChange={(evt) => onChange(evt.target.value)}
                ref={inputRef}
                // autoComplete={"no-auto-complete"}
                autoComplete="off"
                onPaste={onPaste}
            />
            {error && error.message ? (
                <p className="form-error">
                    {error.message}
                </p>
            ) : null}
        </>
    );
}

export default FormTexAreatInput;
