import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useFarmerApi() {
  const callApi = useApiCall();


  const getAllFarmers = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMER.GET_FARMER, data, onSuccess, onError);
  };

  const getFarmerDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMER.GET_FARMER_DETAILS, data, onSuccess, onError);
  };
 
  const farmerChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMER.CHANGE_STATUS_FARMER, data, onSuccess, onError);
  };
  const importFarmer = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMER.IMPORT_FARMER, data, onSuccess, onError);
  };



  return {
    getAllFarmers, getFarmerDetails, farmerChangeStatus, importFarmer
  };
}
