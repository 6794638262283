import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'

import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';
// const API_BASE_URL = process.env.REACT_APP_API_URL;


export function* login(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
        if (resp.status == 200  && resp.data && resp.data.data.statusCode>=200 && resp.data.data.statusCode<300) {
            localStorage.setItem(STORAGE, JSON.stringify(resp.data.data));
            let userdata = atob(resp.data.data.userDetailsToken).split('|');
            let user = {
                userID: userdata[0],
                rollID: userdata[1],
                userRollID: userdata[2],
                displayName: userdata[3],
                Email: userdata[4],
                avatar: userdata[5],
                resetPassword: userdata[6],
                EmployeeID: userdata[7],
                userData:resp.data.data.userDetailsToken
            }

            let API = UpdateWithVariables(API_URL.MASTER.ROLE.PERMISSION, {fKRoleID: user.rollID })
            const resp2 = yield call(CallApi.GET, API, data, true);
            if (resp2.status == 200 && resp2.data) {
                let response: any = resp2.data.data.permissionList;
                let permissions: any = {
                    menus: {
                        Dashboard: { read: false, write: false, delete: false },
                        Employee: { read: false, write: false, delete: false },
                        Farmer: { read: false, write: false, delete: false },
                        Farms: { read: false, write: false, delete: false },
                        Collateral: { read: false, write: false, delete: false },
                        'Training Group': { read: false, write: false, delete: false },
                        Events: { read: false, write: false, delete: false },
                        'IoT Device': { read: false, write: false, delete: false },
                        Reports: { read: false, write: false, delete: false },
                        Settings: { read: false, write: false, delete: false },
                    }
                }

                let menuKey: any = {
                    'DAS': 'Dashboard',
                    'EMP': 'Employee',
                    'FMR': 'Farmer',
                    'FMS': 'Farms',
                    'COL': 'Collateral',
                    'TRG': 'Training Group',
                    'EVN': 'Events',
                    'IOT': 'IoT Device',
                    'REP': 'Reports',
                    'SET': 'Settings',
                }

                response.map((menu: any) => {
                    switch (menu.type) {
                        case 1:
                            permissions.menus[menuKey[menu.code]].read = menu.read;
                            permissions.menus[menuKey[menu.code]].write = menu.write;
                            permissions.menus[menuKey[menu.code]].delete = menu.delete;
                            break;
                        case 2:
                            permissions.actions[menuKey[menu.code]] = menu.read;
                            break;
                        default:
                    }
                })


                console.log('PAYLOAD--',{
                    user: user,
                    token: resp.data.data.accessToken,
                    permissions: permissions
                })

                yield put({
                    type: ACTIONS.USER.LOGIN,
                    payload: {
                        user: user,
                        token: resp.data.data.accessToken,
                        permissions: permissions
                    }
                })


                action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
            } else {
                action && action.callbackError && action.callbackError(resp2.data.data.statusMessage);
            }
        } else {
          // alert(resp.data.data);
             action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
      //  alert(e);
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* logout(action: any): any {
    try {
        localStorage.removeItem(STORAGE)
        // sessionStorage.removeItem(UPDATED_USER)
        yield put({
            type: ACTIONS.USER.LOGOUT,
        });
        // yield put({
        //     type: ACTIONS.RESET_REDUCER,
        // });
        action && action.callbackSuccess && action.callbackSuccess({});
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* forgetPassword(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.USER.FORGET_PASSWORD, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
      
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* restPassword(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.USER.RESET_PASSWORD, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
      
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* restprofilePassword(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.USER.PASSWORD_RESET, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
      
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


