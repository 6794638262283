import toast from "react-hot-toast";
import Swal from "sweetalert2";

export class ConfirmAlert {
	static delete(name: string, api: any, params: any) {
		return new Promise((resolve: any, reject: any) => {
			Swal.fire({
				title: 'Confirm Delete',
				text: `Are you sure you want to delete this ${name}?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#60864d',
				cancelButtonColor: '#44546A',
				confirmButtonText: `Yes, delete it!`
			}).then((result) => {
				if (result.isConfirmed) {
					toast.promise(
						api(params), {
						loading: 'Deleting...',
						success: (msg: any) => {
							resolve(msg)
							return <b>{msg}</b>
						},
						error: (msg: any) => {
							return <b>{msg}</b>
						},
					});
				}
			});
		})

	}

	static changeStatus(name: string, api: any, params: any) {
		return new Promise((resolve: any, reject: any) => {
			Swal.fire({
				title: 'Change Status',
				text: `Are you sure you want to change this ${name} status?`,
				icon: 'success',
				showCancelButton: true,
				confirmButtonColor: '#60864d',
				cancelButtonColor: '#44546A',
				confirmButtonText: `Yes, Change it!`
			}).then((result) => {
				if (result.isConfirmed) {
					toast.promise(
						api(params), {
						loading: 'Updating...',
						success: (msg: any) => {
							resolve(msg)
							return <b>{msg}</b>
						},
						error: (msg: any) => {
							return <b>{msg}</b>
						},
					});
				} else {
					reject();
				}
			});
		})

	}

	static warning(message: string) {
		return new Promise((resolve: any, reject: any) => {
			Swal.fire({
				title: 'Warning',
				text: `${message}`,
				icon: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#60864d',
				// cancelButtonColor: '#44546A',
				confirmButtonText: `Okay`
			}).then((result) => { 
			});
		})

	}
}
