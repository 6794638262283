import { useEffect, useState } from 'react';
import React from 'react';
import Header from '../../../components/header/Header';
import LeftPanel from '../../../components/leftPanel/LeftPanel';
import Search from '../../../components/search/Search';
import Pagination from '../../../components/pagination/Pagination';
import Footer from '../../../components/footer/Footer';
import { useCollatralApi } from '../../../_common/hooks/api/collateralApiHook';
import { useUser } from '../../../_common/hooks/authHook';
import TitleBar from '../../../components/TopBar/TitleBar';
import { URLS } from '../../../_config';
import { Link, useParams } from 'react-router-dom';
import Icon from '../../../components/icon';
import { getSerialNumber, replaceUrlParams, showDate } from '../../../_common/functions';
import TableLoader from '../../../components/tableLoader';
import Status from '../../../components/status';
import { Form } from 'react-bootstrap';
import { ConfirmAlert } from '../../../_common/confirmAlert';
import ProtectionMethods from './ProtectionMethods';


const FarmingMethods = () => {
    const params: any = useParams();
    const [protectionMethod, setProtectionMethod] = useState<any>(null);
    const handleClose = () => setProtectionMethod(null);

    const collateralApi = useCollatralApi()
    const [dataList, setDataList] = useState<any>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;
    const user: any = useUser()

    const getCropList = () => {
        collateralApi.cropList({

            PageIndex: page,
            PageSize: limit,
            SearchText: search

        }, (message: any, resp: any) => {
            setDataList(resp.data.crop)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setDataList([])
            setTotal(0)
        })
    }

    const changeStatus = (params: any) => {
        return new Promise((resolve, reject) => {
            collateralApi.cropChangeStatus(params, resolve, reject)
        })
    }
    const changeStatusHandler = (el: any, crop: any) => {
        ConfirmAlert.changeStatus('Crop', changeStatus, {
            "cropID": crop.cropID,
            "updatedBy": 1,
            "status": el.checked
        }).then(() => {
            getCropList();
        }).catch(() => {
            el.checked = !el.checked;
        })
    }

    const deleteCrop = (params: any) => {
        return new Promise((resolve, reject) => {
            collateralApi.cropDelete(params, resolve, reject)
        })
    }
    const deleteCropHandler = (crop: any) => {
        ConfirmAlert.delete('Crop', deleteCrop, {
            "cropID": crop.cropID,
            "deletedBy": user.userID,
        }).then(() => {
            getCropList();
        })
    }


    // useEffect(() => {
    //     if(dataList){
    //         getCropList();
    //     }

    // }, [search])

    // useEffect(() => {
    //     if(dataList){
    //         getCropList();
    //     }
    // }, [page])
    useEffect(() => {
        if(dataList != null){
            setDataList(null);
            getCropList();
        }
       
    }, [page, search])

    useEffect(()=>{getCropList();},[])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title="Farming Methods" breadcrumbs={{
                            'Collateral': '#',
                            'Farming Methods': '#',
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading">Farming Methods</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            <Search setSearch={(str: string) => {
                                                setSearch(str)
                                                setPage(1)
                                            }}></Search>

                                            <Link to={URLS.COLLATERAL.CROP.ADD} className="btn btn-primary d-flex align-items-center gap-2 ">
                                                <Icon name='plus' size={16} /> Add New
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: 80 }}>#</th>
                                                    <th scope="col">Crop Image</th>
                                                    <th scope="col">Crop Category</th>
                                                    <th scope="col">Crop Name</th>
                                                    <th scope="col">Created Date</th>
                                                    <th scope="col">Farming Process</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col" style={{ width: 150 }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataList && dataList?.map((dataItem: any, i: number) => (
                                                        <tr key={dataItem.cropID}>
                                                            <td>{getSerialNumber(page, limit, i)}</td>
                                                            <td><div className="table-pic"><img src={dataItem?.cropImage} alt={dataItem?.cropName} className="category-img" /></div></td>
                                                            <td>{dataItem?.cropCategoryName}</td>
                                                            <td>{dataItem?.cropName}</td>
                                                            <td>{showDate(dataItem?.addedDate)}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center gap-2">
                                                                    <Link to={replaceUrlParams(URLS.COLLATERAL.CROP.CULTIVATION_METHODS_VIEW, { id: dataItem.cropID })} className="btn btn-primary">Cultivation Methods</Link>
                                                                    <span className="btn btn-success right-bar-toggle" onClick={() => { setProtectionMethod(dataItem) }}> Protection Methods</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <Status status={dataItem.isActive} />
                                                            </td>
                                                            <td>
                                                                <div className="action-btns">
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id={`status-switch-${dataItem.cropID}`}
                                                                        label=""
                                                                        onChange={(e) => {
                                                                            changeStatusHandler(e.target, dataItem)
                                                                        }}
                                                                        defaultChecked={dataItem.isActive}
                                                                    />
                                                                    <Link to={replaceUrlParams(URLS.COLLATERAL.CROP.EDIT, { id: dataItem.cropID })}><Icon name='edit' size={18} /></Link>
                                                                    <Link to={replaceUrlParams(URLS.COLLATERAL.CROP.DETAILS, { id: dataItem.cropID })}><Icon name='view' size={18} /></Link>
                                                                    <Icon name='delete' size={18} onClick={() => { deleteCropHandler(dataItem) }} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                        <TableLoader data={dataList} />
                                    </div>
                                    <Pagination total={total} page={page} pageLimit={limit} setPage={setPage}></Pagination>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>

            <ProtectionMethods show={protectionMethod != null} handleClose={handleClose} crop={protectionMethod} />
        </React.Fragment>
    )
}

export default FarmingMethods;