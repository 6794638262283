import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import Footer from "../../../components/footer/Footer";
import TitleBar from "../../../components/TopBar/TitleBar";
import { Link, useParams } from "react-router-dom";
import { URLS } from "../../../_config";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import { replaceUrlParams } from "../../../_common/functions";
import Loader from "../../../components/loader";
import parse from 'html-react-parser';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Icon from "../../../components/icon";
import DetailsElement from "../../../components/detailsElement";

const CropProtectionMethodDetails = () => {
    const params: any = useParams();
    const [dataID, setDataID] = useState<any>(params.id)
    const [dataItem, setDataItem] = useState<any>({})
    const collateralApi = useCollatralApi();
    const [dataReset, setDataReset] = useState<boolean>(false)


    const getCropProtectionMethodDetails = () => {
        collateralApi.cropProtectionMethodDetails({ urlParams: { ProtectionMethodID: dataID } }, (message: any, resp: any) => {
            let dataDetail: any = resp.data?.protectionMethod[0];
            setDataItem(dataDetail)
            setDataReset(true)
        }, (message: any) => {
            setDataItem([])
            setDataReset(true)
            //----
        })
    }


    useEffect(() => {
        if (dataID) {
            getCropProtectionMethodDetails()
        }
    }, [dataID])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`  Crop Protection Details `}
                            breadcrumbs={{
                                'Collateral': '#',
                                'Crop Protection List': URLS.COLLATERAL.CROP_PROTECTION_METHOD.LIST,
                                'Details': '#',
                            }} />

                        <div data-aos="fade-up">
                            {
                                !dataReset ? <Loader /> :

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="white-box-card profile-card">
                                                {
                                                    dataItem.objimages?.length > 1 ?
                                                        <Slider {...settings}>
                                                            {dataItem.objimages.map((imageObj: any, i: any) => (
                                                                <div key={`protection-method-image-${i}`} className="method-image-slide" >
                                                                    <img src={imageObj?.imageURL} alt="image" style={{ height: '230px' }} />
                                                                </div>
                                                            ))}
                                                        </Slider>
                                                        :
                                                        <img src={dataItem?.objimages[0]?.imageURL} alt="image" style={{ width: '100%' }} />
                                                }

                                            </div>
                                            {/* <div className="white-box-card profile-card">
                                                        {
                                                            dataItem?.objimages && dataItem?.objimages.length === 0 && <p className="text-center">No Images!</p>
                                                        }
                                                        {
                                                            dataItem?.objimages && dataItem?.objimages.length > 0 &&
                                                            dataItem?.objimages.map((img: any) => (
                                                                <div className="profile-avatar">
                                                                    <img src={img?.imageURL} alt={img.fkProtectionMethodID} />
                                                                </div>
                                                            ))
                                                        }
                                                    </div> */}
                                        </div>
                                        <div className="col-sm-9">
                                            <div className="white-box-card mb-4 h-100 ">

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <h3 className="card-heading mb-4">Crop Protection Details</h3>

                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <DetailsElement title={'Pest Name'} value={dataItem?.pestName ? dataItem?.pestName : '--'}></DetailsElement>
                                                            </div>

                                                            <div className="col-sm-12">
                                                                <DetailsElement title={'Symtoms'} >
                                                                    <p>{parse(dataItem?.symptoms ? dataItem?.symptoms : '--')}</p>
                                                                </DetailsElement>
                                                            </div>

                                                            <div className="col-sm-12">
                                                                <DetailsElement title={'Prventive Measures'} >
                                                                    <p>{parse(dataItem?.preventiveMeasures ? dataItem?.preventiveMeasures : '--')}</p>
                                                                </DetailsElement>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                                <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                    <Link to={URLS.COLLATERAL.CROP_PROTECTION_METHOD.LIST} className="btn btn-default align-items-center d-flex">Back</Link>
                                                    <Link to={replaceUrlParams(URLS.COLLATERAL.CROP_PROTECTION_METHOD.EDIT, { id: dataID })}
                                                        className="btn btn-primary rounded-pill-btn ">
                                                        <Icon name="edit" />
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                            }

                        </div>


                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default CropProtectionMethodDetails;