import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import Footer from "../../../components/footer/Footer";
import TitleBar from "../../../components/TopBar/TitleBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URLS } from "../../../_config";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import { Controller, useForm } from "react-hook-form";
import Error from "../../../components/formFieldError";
import FormTextInput from "../../../_common/form-elements/textinput/formTextInput";
import toast from "react-hot-toast";
import { useUser } from "../../../_common/hooks/authHook";
import Editor from "react-simple-wysiwyg";
import { CiCirclePlus } from "react-icons/ci";
import { IoCameraOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import FileUploadModal from "../../../modals/fileUpload.modal";



const CropProtectionMethodForm = () => {

    const params: any = useParams();
    const navigate: any = useNavigate();
    const [dataID, setDataID] = useState<any>(params.id)
    const collateralApi = useCollatralApi();
    const user: any = useUser();
    const [cropImg, setCropImg] = useState<string>("/images/no_image.jpg");
    const [uploadedImgs, setUploadedImgs] = useState<any>([]);
    const [dataReset, setDataReset] = useState<boolean>(false)
    const [uploadImageModal, setUploadImageModal] = useState<boolean>(false)

    const imgRef: any = useRef();

    const {
        control,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm();



    const getCropProtectionMethodDetails = () => {
        collateralApi.cropProtectionMethodDetails({ urlParams: { ProtectionMethodID: dataID } }, (message: any, resp: any) => {
            let dataDetail: any = resp.data?.protectionMethod[0];
            let resetValues: any = {
                "pestName": dataDetail.pestName,
                "symptoms": dataDetail.symptoms,
                "preventiveMeasures": dataDetail?.preventiveMeasures,
            }

            dataDetail?.objimages && dataDetail?.objimages.length > 0 && dataDetail.objimages.map((item: any) => {
                setUploadedImgs((oldArray: any) => [...oldArray, { imageUrl: item.imageURL }])
            })

            reset(resetValues);
            setDataReset(true)

        }, (message: any) => {
            //----
        })
    }

    useEffect(() => {
        if (dataID) {
            getCropProtectionMethodDetails()
        }
    }, [dataID])

    const saveData = (data: any) => {
        return new Promise((resolve, reject) => {
            let params: any = {
                "pestName": data.pestName,
                "symptoms": data.symptoms,
                "preventiveMeasures": data.preventiveMeasures,
                "addedBy": user?.userID,
                "isActive": true,
                "imgages": uploadedImgs,
            }

            if (dataID) {
                params.protectionMethodID = dataID;
                collateralApi.cropProtectionMethodUpdate(params, resolve, reject)
            } else {
                collateralApi.cropProtectionMethodSave(params, resolve, reject)
            }
        })
    }

    const submitCropHandler = (data: any) => {
        toast.promise(
            saveData(data), {
            loading: dataID ? 'Updating...' : 'Adding...',
            success: (msg: any) => {
                navigate(URLS.COLLATERAL.CROP_PROTECTION_METHOD.LIST)
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const handlePicChange = (event: any) => {
        // Access the selected file(s) from event.target.files
        const selectedFile = event.target.files[0];

        // Do something with the selected file, e.g., display its name

        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                // Base64 string representation of the selected file
                const base64String = reader.result as string | null;

                if (base64String !== null) {
                    setCropImg(base64String);
                    console.log(base64String);
                    collateralApi.cropFileUpload([{ image: base64String }], (message: any, resp: any) => {
                        setUploadedImgs((oldArray: any) => [...oldArray, { imageUrl: resp.data?.cropImage[0].imageUrl }])
                        console.log('resp.data', resp.data?.cropImage[0].imageUrl)
                        console.log('resp.data', uploadedImgs)
                    }, (message: any) => {
                        //----
                    })
                }

                // You can use the base64String as needed (e.g., set it in the state)
            };

            // Read the selected file as a Data URL (Base64)
            reader.readAsDataURL(selectedFile);
        }
    };

    const removePic = (i: number) => {
        Swal.fire({
            title: 'Change Status',
            text: `Are you sure you want to remove this image?`,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, Remove it!`
        }).then((result) => {
            if (result.isConfirmed) {
                let newImgs = uploadedImgs && uploadedImgs.length > 0 &&
                    uploadedImgs?.filter((img: any, index: number) => {
                        return i !== index
                    })
                setUploadedImgs(newImgs)
            }
        });


    };

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`${dataID ? 'Edit' : 'Add'} Crop Protection`}
                            breadcrumbs={{
                                'Collateral': '#',
                                'Crop Protection': URLS.COLLATERAL.CROP_PROTECTION_METHOD.LIST,
                                [dataID ? 'Edit' : 'Add']: '#',
                            }} />

                        <div className="row" data-aos="fade-up">

                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 " >
                                    <form className="row" onSubmit={handleSubmit(submitCropHandler)}>

                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="white-box-card profile-card">
                                                    <div className="profile-avatar">
                                                        <img src={uploadedImgs.length > 0 ? uploadedImgs[0].imageUrl : cropImg} alt="" className="cursor-pointer" onClick={(e) => {
                                                            if (uploadedImgs?.length > 0) {
                                                                setUploadImageModal(true)
                                                            }
                                                        }} />
                                                        {(uploadedImgs && uploadedImgs.length > 0) ? null :
                                                            <div className="upload-pic-btn">
                                                                <CiCirclePlus />
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="eventPic"
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                    }) => (
                                                                        <input accept="image/*" type="file" ref={imgRef} onChange={(e) => {
                                                                            handlePicChange(e);
                                                                        }} />
                                                                    )}
                                                                />
                                                            </div>
                                                        }
 

                                                        <div className="cam-icon">
                                                            <IoCameraOutline />
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div className="col-sm-9">
                                                <div className="white-box-card mb-4 h-100 ">

                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="mb-3">
                                                                        <label>Name<sup className="text-danger">*</sup></label>
                                                                        <Controller
                                                                            defaultValue={""}
                                                                            rules={{ required: "Pest Name is required" }}
                                                                            control={control}
                                                                            name="pestName"
                                                                            render={({
                                                                                field: { onChange, onBlur, value, name, ref },
                                                                            }) => (
                                                                                <FormTextInput
                                                                                    name={name}
                                                                                    onChange={onChange}
                                                                                    onBlur={onBlur}
                                                                                    value={value}
                                                                                    inputRef={ref}
                                                                                    type="text"
                                                                                    placeholder="Enter pest name"
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Error error={errors["pestName"]} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="mb-3">
                                                                        <label>Symptoms</label>
                                                                        <Controller
                                                                            defaultValue={""}
                                                                            control={control}
                                                                            name="symptoms"
                                                                            render={({
                                                                                field: { onChange, onBlur, value, name, ref },
                                                                            }) => (
                                                                                <Editor value={value} onChange={onChange} />
                                                                            )}

                                                                        />
                                                                        <Error error={errors["symptoms"]} />

                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="mb-3">
                                                                        <label>Preventive Measures</label>
                                                                        <Controller
                                                                            defaultValue={""}
                                                                            control={control}
                                                                            name="preventiveMeasures"
                                                                            render={({
                                                                                field: { onChange, onBlur, value, name, ref },
                                                                            }) => (
                                                                                <Editor value={value} onChange={onChange} />
                                                                            )}
                                                                        />
                                                                        <Error error={errors["preventiveMeasures"]} />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                        <Link to={URLS.COLLATERAL.CROP_PROTECTION_METHOD.LIST} className="btn btn-default">Cancel</Link>
                                                        <button className="btn btn-primary ">Save</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                <FileUploadModal show={uploadImageModal} onClose={setUploadImageModal} images={uploadedImgs} setUploadedImgs={setUploadedImgs} />
                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default CropProtectionMethodForm;