import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import TitleBar from "../../components/TopBar/TitleBar";
import TableLoader from "../../components/tableLoader";
import Icon from "../../components/icon";
import { useReportApi } from "../../_common/hooks/api/reportApiHook";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import toast from "react-hot-toast";
import SearchWithLocation from "../../components/search/SearchWithLocation";


const FarmsListsReport = () => {
    const reportAPI = useReportApi();
    const [farms, setFarms] = useState<any[] | null>(null);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;
    const [search, setSearch] = useState<string>('');
    const [searchDistricts, setSearchDistricts] = useState([]);
    const [searchBlocks, setSearchBlocks] = useState([]);
    const [searchGpus, setSearchGpus] = useState([]);
    const [searchWards, setSearchWards] = useState([]);
    const [khasraNumber, setKhasraNumber] = useState<string>('');
    const [porchaNumber, setPorchaNumber] = useState<string>('');

    const getFarmList = () => {
        reportAPI.getFarmList({
            PageIndex: page,
            PageSize: limit,
            SearchText: search,
            districtIDs: searchDistricts.join(),
            BlockIDs: searchBlocks.join(),
            WardIDs: searchWards.join(),
            GPUIDs: searchGpus.join(),

            khasraNumber: khasraNumber,
            porchaNumber: porchaNumber,
        }, (message: any, resp: any) => {
            console.log('farm =>', resp.data.farmView)
            setFarms(resp.data.farmView)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setFarms([])
        })
    }


    const downloadFarmsExcel = async () => {
        reportAPI.getFarmList({
            SearchText: search,
            districtIDs: searchDistricts.join(),
            BlockIDs: searchBlocks.join(),
            WardIDs: searchWards.join(),
            GPUIDs: searchGpus.join(),

            khasraNumber: khasraNumber,
            porchaNumber: porchaNumber,
        }, (message: any, resp: any) => {
            exportReport(resp.data.farmView)
        }, (message: any) => {
        })
    }

    const exportReport = (dataList: any) => {
        if (dataList) {
            let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let fileExt = '.xlsx';
            let data: any = [];
            dataList?.map((farm: any) => {
                data.push({
                    "Owner Name": farm.ownerName,
                    "Khasra Number": farm.khasraNumber,
                    "Khatiyan Number": farm.khatiyanNumber,
                    "Farm Size": farm.farmSize,
                    "Cultivation Area": farm.cultivationArea,
                    "Ownership Type": farm.ownershipTypeName,
                    "Land Type": farm.landTypeName,
                    "Location": `${farm.districtName}, ${farm.blockName}, ${farm.gpuName}, ${farm.wardName}`,
                })
            })
            let ws: any = XLSX.utils.json_to_sheet(data)
            let wb: any = { Sheets: { 'Farms': ws }, SheetNames: ['Farms'] }
            let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            let _data: any = new Blob([excelBuffer], { type: fileType })
            FileSaver.saveAs(_data, 'FarmsList' + fileExt)
        } else {
            toast.error('Not enough data to export!!!')
        }
    }

    useEffect(() => {
        setFarms(null)
        getFarmList();
    }, [page, search, searchBlocks, searchWards, searchGpus])

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Farms Report`}
                            breadcrumbs={{
                                'Reports': '#',
                                'Farms Report': '#',
                            }} />


                        <div className="events">

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                        <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                            <h2 className="card-heading mb-4">Farms Lists</h2>
                                            <div className="d-flex align-items-center gap-2 ">
                                                <div className="dropdown">
                                                    <button className="btn btn-outline-secondary dropdown-toggle" onClick={downloadFarmsExcel}  >
                                                        <Icon name="download" size={18} /> Export
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                        <SearchWithLocation
                                            pageName='farms-report'
                                            setSearch={setSearch}
                                            setDistricts={setSearchDistricts}
                                            setBlocks={setSearchBlocks}
                                            setGpus={setSearchGpus}
                                            setWards={setSearchWards}

                                            setKhasraNumber={setKhasraNumber}
                                            setPorchaNumber={setPorchaNumber}
                                        />
                                        <div className="table-wrapper">
                                            <table className="table gen-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Owner’s Name </th>
                                                        <th scope="col">Owner’s Aadhaar</th>
                                                        <th scope="col">Khasra | Porcha Number</th>
                                                        <th scope="col">Farm size (Acres)</th>
                                                        <th scope="col">Cultivation Area (Hectare)</th>
                                                        {/* <th scope="col">Crops Name</th> */}
                                                        <th scope="col">District</th>
                                                        <th scope="col">Block</th>
                                                        <th scope="col">GPU</th>
                                                        <th scope="col">Ward</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        farms?.map((itemData, index) => (
                                                            <tr key={`farm-${itemData.farmID}`}>
                                                                <td>{index + 1 + ((page - 1) * limit)}</td>
                                                                <td>{itemData.ownerName}</td>
                                                                <td>{itemData?.ownersAdharNumber ? itemData?.ownersAdharNumber : '--'}</td>
                                                                <td>
                                                                    {itemData.khasraNumber ? itemData.khasraNumber : '--'}
                                                                    <br />
                                                                    | {itemData.porchaNumber ? itemData.porchaNumber : '--'}
                                                                </td>
                                                                <td>{itemData.farmSize}  </td>
                                                                <td>{itemData.cultivationArea}</td>
                                                                {/* <td> </td> */}
                                                                {/* <td>{itemData.ownershipTypeName}</td>
                                                                <td>{itemData.landTypeName}</td> */}
                                                                
                                                                <td>{itemData?.districtName ? itemData?.districtName : '--'}</td>
                                                                <td>{itemData?.blockName ? itemData?.blockName : '--'}</td>
                                                                <td>{itemData?.gpuName ? itemData?.gpuName : '--'}</td>
                                                                <td>{itemData?.wardName ? itemData?.wardName : '--'}</td>
                                                            </tr>
                                                        ))
                                                    }


                                                </tbody>
                                            </table>
                                            <TableLoader data={farms} />
                                        </div>
                                        {farms ? <Pagination total={total} page={page} setPage={(n: number) => {
                                            setPage(n)
                                            setFarms(null)
                                        }} pageLimit={limit} /> : null}
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default FarmsListsReport;