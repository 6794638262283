import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useEmployeeApi() {
  const callApi = useApiCall();

  const addEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.ADD_EMPLOYEE, data, onSuccess, onError);
  };

  const updateEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.UPDATE_EMPLOYEE, data, onSuccess, onError);
  };

  

  const getAllEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.GET_EMPLOYEE, data, onSuccess, onError);
  };
  const getAllActiveEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.GET_ACTIVE_EMPLOYEE, data, onSuccess, onError);
  };
  const getEmployeeDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.GET_EMPLOYEE_DETAILS, data, onSuccess, onError);
  };
  const updateEmployeeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.UPDATE_EMPLOYEE_STATUS, data, onSuccess, onError);
  };

  const deleteEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.DELETE_EMPLOYEE, data, onSuccess, onError);
  };

 

  






  return {
    addEmployee,
    updateEmployee,
    getAllEmployee,
    getEmployeeDetails,
    updateEmployeeStatus, 
    deleteEmployee,
    getAllActiveEmployee

  };
}
