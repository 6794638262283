import React, { useEffect, useState } from "react";
import Search from "../../components/search/Search";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";
import { URLS } from "../../_config";
import { useGroupApi } from "../../_common/hooks/api/groupApiHook";
import Icon from "../../components/icon";
import { replaceUrlParams } from "../../_common/functions";
import TableLoader from "../../components/tableLoader";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import Status from "../../components/status"; 
import TitleBar from "../../components/TopBar/TitleBar";
import LocationModal from "../../modals/Location.modal";
import { Form, Tooltip } from "react-bootstrap";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";

const TrainingGroupList = () => {

    const user:any = useUser();

    const permissions: any = useUserPermissions();

    const groupApi = useGroupApi();
    const [groups, setGroups] = useState<any[] | null>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;
    const [showLocationModalData, setShowLocationModalData] = useState<any>(null);

    const getAllGroups = () => {
        groupApi.getAllGroups({
            PageIndex: page,
            PageSize: limit,
            SearchText: search
        }, (message: any, resp: any) => {
            setGroups(resp.data.group);
            setTotal(resp.totalRecords)
        }, (message: any) => { })
    }

    const deleteGroup = (group: any) => {
        return new Promise((resolve, reject) => {
            groupApi.deleteGroup({
                groupID: group.groupID,
                deletedBy: 1
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => { reject(message) })
        })
    }

    const deleteGroupHandler = (group: any) => {
        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this group?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteGroup(group), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        getAllGroups();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }

    const changeStatus = (status: boolean, group: any) => {
        return new Promise((resolve, reject) => {
            groupApi.groupChangeStatus({
                groupID: group.groupID,
                status: status,
                updatedBy: user.userID
              },(message:any,resp:any)=>{
                resolve(message)
            },(message:any)=>{reject(message)})
            setTimeout(() => { resolve('Success') }, 1500)
        })

    }

    const changeStatusHandler = (el: any, group: any) => {
        let status:boolean = el.checked
        Swal.fire({
            title: 'Confirm Status Change',
            text: `Are you sure you want to ${status ? 'active' : 'inactive'} this group?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${status ? 'Active' : 'Inactive'} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    changeStatus(status, group), {
                    loading: 'Status changing...',
                    success: (msg: any) => {
                        getAllGroups();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            } else {
                el.checked = !el.checked
            }
        });
    }

    useEffect(() => {
        if (groups == null) {
            getAllGroups();
        }
    }, [groups])




    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'Training Group'} breadcrumbs={{
                            'Training Group': '#'
                        }} />


                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Training Group List</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            <Search setSearch={(str: string) => {
                                                setSearch(str)
                                                setGroups(null)
                                            }}></Search>
                                            {permissions.menus['Training Group'].write &&
                                                <Link to={URLS.TRAINING_GROUP_ADD} className="btn btn-primary d-flex align-items-center gap-2 "><GoPlus /> Create Group</Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Group ID</th>
                                                    <th scope="col">Group Name</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">No. Of Group Members</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {groups?.map((group: any) => (
                                                    <tr key={`group-${group.groupID}`}>
                                                        <td>{group.refGroupID}</td>
                                                        <td>{group.groupName}</td>
                                                        <td>
                                                            <Icon name="location" size={18} onClick={() => setShowLocationModalData(group)} />
                                                        </td>
                                                        <td>
                                                            {group.groupUsersList.length}
                                                        </td>
                                                        <td>
                                                            <Status status={group.isActive} />
                                                        </td>
                                                        <td>
                                                            <div className="action-btns">
                                                                
                                                                {permissions.menus['Training Group'].write &&
                                                                    <>
                                                                        <Form.Check // prettier-ignore
                                                                            type="switch"
                                                                            id={`status-switch-${group.groupID}`}
                                                                            label=""
                                                                            onChange={(e) => {
                                                                                changeStatusHandler(e.target, group)
                                                                            }}
                                                                            defaultChecked={group.isActive}
                                                                        />
                                                                        <Link to={replaceUrlParams(URLS.TRAINING_GROUP_EDIT, { groupID: group.groupID })}><Icon name='edit' size={18} /></Link>
                                                                    </>
                                                                }
                                                                {permissions.menus['Training Group'].delete &&
                                                                    <Icon name='delete' size={18} onClick={() => { deleteGroupHandler(group) }} />
                                                                }


                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                        <TableLoader data={groups} />
                                    </div>
                                    {groups ? <Pagination total={total} page={page} setPage={(n: number) => {
                                        setPage(n)
                                        setGroups(null)
                                    }} pageLimit={limit} /> : null}
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                <LocationModal show={showLocationModalData != null}
                    onClose={() => setShowLocationModalData(null)}
                    data={showLocationModalData} />

                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default TrainingGroupList;