import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const ChartSoilSalinity = () => {
    const data = [
        {
            name: 'Page A',
            uv: 500,
            // pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 800,
            // pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 750,
            // pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 1000,
            // pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            // pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            // pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 2200,
            // pv: 4300,
            amt: 2100,
        },
    ];

    const color = "#fee895";
    return (
        <React.Fragment>
            <div className="white-graph-box mt-5">
                <div className="white-graph-box-head">
                    <h2>Soil Salinity <span>1.23</span></h2>

                </div>
                <div className="device-name-graph"><img src="/images/device-icon.svg" alt="" /> Device Name</div>
                {/* <div className="demo-graph-wrap">
                    <img src="/images/g5.jpg" alt="" />
                </div> */}

                <div className="demo-graph-wrap" style={{ height: '250px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                       


                        <AreaChart width={730} height={250} data={data}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="color_Uv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#fecd1b" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#fee895" stopOpacity={0.5} />
                                </linearGradient>
                                {/* <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                </linearGradient> */}
                            </defs>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="uv" stroke="#e4bb74" fillOpacity={1} fill="url(#color_Uv)" />
                            {/* <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
                        </AreaChart>
                    </ResponsiveContainer>

                </div>
            </div>
        </React.Fragment>
    )

}

export default ChartSoilSalinity;