import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useFarmsApi() {
  const callApi = useApiCall();




  const farmList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.LIST, data, onSuccess, onError);
  };
  const farmDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.DETAILS, data, onSuccess, onError);
  };
  const farmSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.SAVE, data, onSuccess, onError);
  };
  const farmUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.UPDATE, data, onSuccess, onError);
  };
  const farmChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.CHANGE_STATUS, data, onSuccess, onError);
  };
  const farmDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.DELETE, data, onSuccess, onError);
  };
  const importFarms = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.IMPORT_FARMS, data, onSuccess, onError);
  };


  const getFarmsCropMappingList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.FARMS_CROP_MAPPING_LIST, data, onSuccess, onError);
  };
  const farmCropsMappingSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.FARMS_CROP_MAPPING_SAVE, data, onSuccess, onError);
  };
  
  const getForecastSensorRangeChecklists = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORECAST.SENSOR_RANGE_CHECKLIST, data, onSuccess, onError);
  };
  const getFieldsHistory = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FARMS.FIELDS_HISTORY, data, onSuccess, onError);
  };
  

  return {
    farmList, farmDetails, farmSave, farmUpdate, farmChangeStatus, farmDelete, importFarms,
    getFarmsCropMappingList, farmCropsMappingSave, getFieldsHistory,

    getForecastSensorRangeChecklists

  };
}
