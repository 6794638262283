import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import Footer from "../../../components/footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URLS } from "../../../_config";
import TitleBar from "../../../components/TopBar/TitleBar";
import { Controller, useForm } from "react-hook-form";
import FormTextInput from "../../../_common/form-elements/textinput/formTextInput";
import { useUser } from "../../../_common/hooks/authHook";
import toast from "react-hot-toast";
import { useMasterApi } from "../../../_common/hooks/api/masterApiHook";
import { Button } from "react-bootstrap";

const RoleAdd = () => {
    const user: any = useUser()
    const navigate = useNavigate();
    const params: any = useParams();
    const masterApi = useMasterApi();
    const [roleId, setRoleId] = useState<any>(params?.id); 

    const {
        control,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const getRoleDetails = () => {
        masterApi.getRoleDetails({ urlParams: { RoleId: roleId } }, (message: any, resp: any) => {
            reset({
                title: resp.data?.role[0]?.title,
            })
        }, (message: any) => {
        })
    }

    useEffect(() => {
        if (roleId) {
            getRoleDetails();
        }
    }, [roleId])


    const roleSave = (data: any) => {
        let param: any = {
            title: data.title,
            isActive: true,
            isDeleted: false,
        };

        return new Promise((resolve, reject) => {
            console.log("params", param);
            if (roleId) {
                param.roleId = roleId;
                param.updatedBy = user?.userID;

                masterApi.roleUpdate(param, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })

            } else {
                param.addedBy = user?.userID;
                masterApi.roleSave(param, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })
            }


            // setTimeout(() => {
            //     resolve("Role saved successfully.");
            // }, 2000);
        });
    };

    const addRoleHandler = (data: any) => {
        toast.promise(roleSave(data), {
            loading: "Saving...",
            success: (msg: any) => {
                navigate(URLS.ROLE.LIST);
                return <b>{msg}</b>;
            },
            error: (msg: any) => {
                return <b>{msg}</b>;
            },
        });
    };

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'Role MANAGEMENT'} breadcrumbs={{
                            'Settings': '#',
                            'Role List': URLS.ROLE.LIST,
                            'Form': '#',
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " data-aos="fade-up">

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="card-heading mb-4 ">Role Data</h3>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <form className="row" onSubmit={handleSubmit(addRoleHandler)}>
                                                        <div className="mb-3">
                                                            <label>Name</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="title"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <FormTextInput
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        inputRef={ref}
                                                                        type="text"
                                                                        placeholder="Enter Role"
                                                                        error={errors.first_name}
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                            <Link to={URLS.ROLE.LIST} className="btn btn-default">Cancel</Link>
                                                            <Button type="submit">Save</Button>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>

                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default RoleAdd;