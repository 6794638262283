import React, { useEffect, useRef } from 'react';
interface FieldProps {
    field: any;
}
const Field = ({ field }: FieldProps) => {

    const canvasRef: any = useRef();




    useEffect(() => {
        if (field && canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                const width = canvas.width = 60;
                const height = canvas.height = 60;
                ctx.fillStyle = '#94a3b8';
                ctx.strokeStyle = '#334155';
                ctx.lineWidth = 1;

                if (field.boundary && field.boundary.length > 2) {
                    let points = field.boundary.map((point: any) => {
                        return [point.longitude, point.latitude];
                    });

                    let minX: any, minY: any, maxX: any, maxY: any;

                    points.forEach((p: any, i: any) => {
                        if (i === 0) { // if first point 
                            minX = maxX = p[0];
                            minY = maxY = p[1];
                        } else {
                            minX = Math.min(p[0], minX);
                            minY = Math.min(p[1], minY);
                            maxX = Math.max(p[0], maxX);
                            maxY = Math.max(p[1], maxY);
                        }
                    });
                    // now get the map width and heigth in its local coords
                    const mapWidth = maxX - minX;
                    const mapHeight = maxY - minY;
                    const mapCenterX = (maxX + minX) / 2;
                    const mapCenterY = (maxY + minY) / 2;

                    // to find the scale that will fit the canvas get the min scale to fit height or width
                    const scale = Math.min(canvas.width / mapWidth, canvas.height / mapHeight);


                    ctx.beginPath();
                    points.forEach((p: any) => {
                        ctx.lineTo(
                            (p[0] - mapCenterX) * scale + canvas.width / 2,
                            (p[1] - mapCenterY) * scale + canvas.height / 2
                        );
                    });
                    

                } else {
                    
                    ctx.beginPath();
                    ctx.moveTo(0, 0);
                    ctx.lineTo(100, 50);
                    ctx.lineTo(50, 100);
                    ctx.lineTo(0, 90);
                    ctx.lineTo(0, 0);
                }
                ctx.stroke();
                ctx.closePath();
                ctx.fill();








            }
        }



    }, [field, canvasRef]);

    return (
        <div className='flex gap-2 p-2 w-full rounded bg-slate-200 hover:bg-green-200 overflow-hidden flex-shrink-0'>
            <div className='w-[60px] flex-shrink-0'>
                <canvas ref={canvasRef} className='' style={{transform: 'scaleY(-1)'}}></canvas>
            </div>
            <div className='flex-grow flex flex-col gap-1 relative'>
                <p className='font-semibold truncate whitespace-nowrap p-0 m-0'>{field?.name}</p>
                {/* <p className='text-xs text-slate-500'>Mr. Raj</p> */}
                <p className='text-xs text-slate-500 p-0 m-0'>{field?.total_area} {field?.area_unit}</p>
                <p className='text-xs text-slate-500 p-0 m-0'>{field?.location?.latitude}<sup>o</sup>N {field?.location?.longitude}<sup>o</sup>E </p>
            </div>
        </div>
    );
};

export default Field;