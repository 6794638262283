import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import Footer from "../../../components/footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URLS } from "../../../_config";
import TitleBar from "../../../components/TopBar/TitleBar";
import { Controller, useForm } from "react-hook-form";
import FormTextInput from "../../../_common/form-elements/textinput/formTextInput";
import toast from "react-hot-toast";
import { useMasterApi } from "../../../_common/hooks/api/masterApiHook";
import { Button } from "react-bootstrap";
import FormTexAreatInput from "../../../_common/form-elements/textareaInput";
import Error from "../../../components/formFieldError";
import { validateEmail } from "../../../_common/functions";
import { ConfirmAlert } from "../../../_common/confirmAlert";

const EmailSettingsForm = () => {
    const navigate = useNavigate();
    const params: any = useParams();
    const masterApi = useMasterApi();
    const [emailSettingId, setEmailSettingId] = useState<any>(params?.id);

    const { control, reset, formState: { errors }, handleSubmit, } = useForm();

    const getEmailSettingsDetails = () => {
        masterApi.getEmailSettingsDetails({ urlParams: { id: emailSettingId } }, (message: any, resp: any) => {
            reset({
                subject: resp.data?.emailSenderInformation[0]?.subject,
                emailIDs: resp.data?.emailSenderInformation[0]?.emailIDs,
            })
        }, (message: any) => {
        })
    }

    useEffect(() => {
        if (emailSettingId) {
            getEmailSettingsDetails();
        }
    }, [emailSettingId])


    const emailSettingsSave = (data: any) => {
        let param: any = {
            emailSenderInformationID: emailSettingId,
            // subject: data.subject,
            emailIDs: data.emailIDs,
        };

        return new Promise((resolve, reject) => {
            console.log("params", param);
            if (emailSettingId) {
                masterApi.emailSettingsUpdate(param, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })

            }
        });
    };

    const emailSettingsSaveHandler = (data: any) => {
        let allEmailValid = 1;

        if (data?.emailIDs != '') {
            let _emailIds = data?.emailIDs.split(',');
            _emailIds && _emailIds?.map((_email: string) => {
                if (!validateEmail(_email.trim())) {
                    allEmailValid = allEmailValid * 0
                }
            })
        } else {
            allEmailValid = 0;
        }

        if (allEmailValid == 1) {
            toast.promise(emailSettingsSave(data), {
                loading: "Saving...",
                success: (msg: any) => {
                    navigate(URLS.SETTINGS.EMAIL_SETTINGS.LIST);
                    return <b>{msg}</b>;
                },
                error: (msg: any) => {
                    return <b>{msg}</b>;
                },
            });
        } else {
            ConfirmAlert.warning('Please enter valid email.').then(() => {
            }).catch(() => { })
        }
    };

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'Email Settings'} breadcrumbs={{
                            'Settings': '#',
                            'Email Settings': URLS.SETTINGS.EMAIL_SETTINGS.LIST,
                            'Form': '#',
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " data-aos="fade-up">

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="card-heading mb-4 ">Email Settings Data</h3>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <form className="row" onSubmit={handleSubmit(emailSettingsSaveHandler)}>
                                                        <div className="mb-3">
                                                            <label>Subject </label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="subject"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <FormTextInput
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        inputRef={ref}
                                                                        type="text"
                                                                        error={errors.emailIDs}
                                                                        disabled={true}
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="mb-3">
                                                            <label>Email Ids<span className="text-danger">*</span> 
                                                            <em className="text-danger font-size-12">(Enter email ids with comma (,) separator )</em>
                                                            </label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="emailIDs"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <FormTexAreatInput
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        inputRef={ref}
                                                                        type="text"
                                                                        placeholder="Enter email ids"
                                                                        error={errors.emailIDs}
                                                                        rows={4}
                                                                    />
                                                                )}
                                                            />
                                                            <Error error={errors["emailIDs"]} />

                                                        </div>

                                                        <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                            <Link to={URLS.SETTINGS.EMAIL_SETTINGS.LIST} className="btn btn-default">Cancel</Link>
                                                            <Button type="submit">Save</Button>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>

                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default EmailSettingsForm;