import React, { useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import Error from "../../../components/formFieldError";
import Select from "react-select";
import Editor from "react-simple-wysiwyg";
import { getSelectedValue } from "../../../_common/functions";

interface props {
    id?: any,
    categoryDetails?: any,
    addParam?: boolean,
    setAddParam: (s: boolean) => void,
    deviceParamType?: any,
    deviceParamMaster?: any,
    deviceUnitMaster?: any,
    control?: any,
    errors?: any,
}

const DeviceCategoryParamsForm = ({ id, categoryDetails, addParam, setAddParam, deviceParamType, deviceParamMaster, deviceUnitMaster, control, errors }: props) => {
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "categoryParams",
    });

    useEffect(() => {
        if (id && categoryDetails) {
            categoryDetails?.deviceTemplateParamList.length && categoryDetails?.deviceTemplateParamList.map((paramItem: any) => {
                append({
                    'fkDeviceParamMasterValue': getSelectedValue(paramItem.fkDeviceParamMasterValue, 'value', deviceParamMaster),
                    'param': paramItem.param,
                    'conversionFormula': paramItem.conversionFormula,
                    'fkUnitValue': getSelectedValue(paramItem.fkUnitValue, 'value', deviceUnitMaster),
                    'fkDeviceParamTypeValue': getSelectedValue(paramItem.fkDeviceParamTypeValue, 'value', deviceParamType),
                    'note': paramItem.note,
                })
            })

        }
    }, [id, categoryDetails])

    useEffect(() => {
        if (addParam) {
            append({})
            setAddParam(false)
        }
    }, [addParam])

    return (
        <React.Fragment>

            {fields.map((field: any, i) => (
                <div className="row white-box-card mb-3" key={`paramKeys_${field.id}`}>

                    <div className="col-sm-4">
                        <div className="mb-3">
                            <label>Param Master</label>
                            <Controller
                                defaultValue={""}
                                rules={{ required: "Param Master is required" }}
                                control={control}
                                name={`categoryParams.${i}.fkDeviceParamMasterValue`}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (

                                    <React.Fragment>
                                        <Select
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            options={deviceParamMaster ? deviceParamMaster : []}
                                            value={value}
                                            placeholder="Enter Param Master..."
                                        />

                                        <Error error={errors[`categoryParams`]?.[i]?.['fkDeviceParamMasterValue']} />
                                    </React.Fragment>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="mb-3">
                            <label>Param</label>
                            <Controller
                                defaultValue={""}
                                control={control}
                                name={`categoryParams.${i}.param`}
                                rules={{ required: 'Param is required' }}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <React.Fragment>
                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" />

                                        <Error error={errors[`categoryParams`]?.[i]?.['param']} />
                                    </React.Fragment>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="mb-3">
                            <label>Conversion Formula</label>
                            <Controller
                                defaultValue={""}
                                control={control}
                                name={`categoryParams.${i}.conversionFormula`}
                                // rules={{ required: 'Conversion Formula is required' }}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <React.Fragment>
                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" />

                                        <Error error={errors[`categoryParams`]?.[i]?.['conversionFormula']} />
                                    </React.Fragment>
                                )}
                            />
                        </div>
                    </div>


                    <div className="col-sm-4">
                        <div className="mb-3">
                            <label>Device Unit</label>
                            <Controller
                                defaultValue={""}
                                rules={{ required: "Device Unit is required" }}
                                control={control}
                                // name="fkUnitValue"
                                name={`categoryParams.${i}.fkUnitValue`}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <React.Fragment>
                                        <Select
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            options={deviceUnitMaster ? deviceUnitMaster : []}
                                            value={value}
                                            placeholder="Enter Device Unit..."
                                        />

                                        <Error error={errors[`categoryParams`]?.[i]?.['fkUnitValue']} />
                                    </React.Fragment>
                                )}
                            />
                        </div>
                    </div>


                    <div className="col-sm-4">
                        <div className="mb-3">
                            <label>Device Param Type</label>
                            <Controller
                                defaultValue={""}
                                rules={{ required: "Device Param Type is required" }}
                                control={control}
                                name={`categoryParams.${i}.fkDeviceParamTypeValue`}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (

                                    <React.Fragment>
                                        <Select
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            options={deviceParamType ? deviceParamType : []}
                                            value={value}
                                            placeholder="Enter Device Param Type..."
                                        />

                                        <Error error={errors[`categoryParams`]?.[i]?.['fkDeviceParamTypeValue']} />
                                    </React.Fragment>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="mb-3">
                            <label>Note</label>
                            <Controller
                                defaultValue={""}
                                control={control}
                                name={`categoryParams.${i}.note`}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (

                                    <React.Fragment>

                                        <Editor value={value} onChange={onChange} />
                                        <Error error={errors[`categoryParams`]?.[i]?.['note']} />
                                    </React.Fragment>
                                )}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </React.Fragment>
    )
}

export default DeviceCategoryParamsForm;