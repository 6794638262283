import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useDeviceApi } from "../../../_common/hooks/api/deviceApiHook";
import { useUser } from "../../../_common/hooks/authHook";
import { URLS } from "../../../_config";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import Loader from "../../../components/loader";
import Error from "../../../components/formFieldError";
import Footer from "../../../components/footer/Footer";
import Select from "react-select";
import Editor from "react-simple-wysiwyg";
import DeviceCategoryParamsForm from "./DeviceCategoryParamsForm";
import { Button } from "react-bootstrap";
import Icon from "../../../components/icon";
import parse from 'html-react-parser';
import { getSelectedValue } from "../../../_common/functions";


const DeviceCategoryForm = () => {
    const deviceapi = useDeviceApi();
    const [deviceParamType, setDeviceParamType] = useState<any>([])
    const [deviceUnitMaster, setDeviceUnitMaster] = useState<any>([])
    const [deviceParamMaster, setDeviceParamMaster] = useState<any>([])
    const [dataReset, setDataReset] = useState<boolean>(false)
    const navigate: any = useNavigate();
    const params: any = useParams();

    const { control, formState: { errors }, handleSubmit, reset } = useForm();
    const user: any = useUser()
    // const [addParamNo, setAddParamNo] = useState<any>(1)
    const [id, setId] = useState<any>(params.id)
    const [categoryDetails, setCategoryDetails] = useState<any>(null)

    const [addParam, setAddParam] = useState<boolean>(false)


    const getDeviceCategoryDetails = () => {
        deviceapi.deviceCategoryDetails({ urlParams: { DeviceTemplateID: id } }, (message: any, resp: any) => {
            let devicedetail: any = resp.data?.deviceTemplate[0];

            let resetValues = {
                deviceTemplateName: devicedetail.deviceName,
                description: devicedetail.description,
                fkDeviceParamTypeValue: getSelectedValue(devicedetail.fkDeviceParamTypeValue, 'value', deviceParamType),
            }

            setCategoryDetails(devicedetail)
            reset(resetValues);
            setDataReset(true)
        }, (message: any) => {
            //----
        })

    }


    const deviceParamTypeAll = () => {
        deviceapi.deviceParamTypeAll({
            urlParams: { PageIndex: 1, PageSize: 999 }
        }, (message: any, resp: any) => {
            const tempArr = resp.data.deviceParamType;

            tempArr && tempArr?.map((tempItem: any) => {
                tempItem.label = tempItem.type
                tempItem.value = tempItem.typeValue
            })
            setDeviceParamType(tempArr)
        }, (message: any) => {
        })
    }
    const deviceUnitMasterAll = () => {
        deviceapi.deviceUnitMasterAll({
            urlParams: { PageIndex: 1, PageSize: 999 }
        }, (message: any, resp: any) => {
            const tempArr = resp.data.unitMaster;

            tempArr && tempArr?.map((tempItem: any) => {
                tempItem.label = parse(tempItem.unit)
                tempItem.value = tempItem.unitMasterID
            })
            setDeviceUnitMaster(tempArr)
        }, (message: any) => {
        })
    }
    const deviceParamMasterAll = () => {
        deviceapi.deviceParamMasterAll({
            urlParams: { PageIndex: 1, PageSize: 999 }
        }, (message: any, resp: any) => {
            const tempArr = resp.data.deviceParamMaster;

            tempArr && tempArr?.map((tempItem: any) => {
                tempItem.label = tempItem.deviceKeyName
                tempItem.value = tempItem.deviceParamMasterID
            })
            setDeviceParamMaster(tempArr)
        }, (message: any) => {
        })
    }


    useEffect(() => {
        if (deviceParamType.length > 0 && deviceUnitMaster.length > 0 && deviceParamMaster.length > 0) {
            if (id) {
                getDeviceCategoryDetails()
            } else {
                setAddParam(true)
            }
        }

    }, [deviceParamType, deviceUnitMaster, deviceParamMaster])


    useEffect(() => {
        deviceParamTypeAll();
        deviceUnitMasterAll();
        deviceParamMasterAll();
    }, [])

    const addNewParamHandler = () => {
        setAddParam(true)
    }

    const saveData = (data: any) => {
        return new Promise((resolve, reject) => {
            console.log('data============', data)
            let params: any = {
                "deviceTemplateName": data.deviceTemplateName,
                "fkDeviceParamTypeValue": data.fkDeviceParamTypeValue.typeValue,
                "description": data.description,
                "addedBy": user?.userID,
                "status": true,
                "isActive": true,
                deviceTemplateParamList: data.categoryParams.map((paramItem: any, index: number) => {
                    return {
                        deviceTemplateParamID: id,
                        param: paramItem?.param,
                        conversionFormula: paramItem?.conversionFormula,
                        note: paramItem?.note,
                        fkDeviceParamMasterValue: paramItem.fkDeviceParamMasterValue.value,
                        fkDeviceParamTypeValue: paramItem.fkDeviceParamTypeValue.value,
                        fkUnitValue: paramItem.fkUnitValue.value,
                    }
                })

            }


            if (id) {
                params.deviceTemplateID = id;
                deviceapi.deviceCategoryUpdate(params, resolve, reject)
            } else {
                deviceapi.deviceCategorySave(params, resolve, reject)
            }
        })
    }

    const saveTemplateHandler = (data: any) => {
        toast.promise(
            saveData(data), {
            loading: id ? 'Updating...' : 'Adding...',
            success: (msg: any) => {
                navigate(URLS.DEVICE_CATEGORY.LIST)
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`${id ? 'Edit' : 'Add'} Device Category`}
                            breadcrumbs={{
                                'IoT Device': '#',
                                'Device Category': URLS.DEVICE_CATEGORY.LIST,
                                'Form': '#',
                            }} />

                        {id && !dataReset ? <Loader /> :
                            <form className="row" onSubmit={handleSubmit(saveTemplateHandler)}>
                                <div className="col-sm-12">
                                    <div className="white-box-card mb-4 h-100 aos-init aos-animate" data-aos="fade-up">

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h3 className="card-heading mb-4 ">Device Category Details</h3>
                                                <div className="row">

                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Template Name</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="deviceTemplateName"
                                                                rules={{ required: 'Template Name is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['deviceTemplateName']} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Param Type</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                rules={{ required: "Param Type is required" }}
                                                                control={control}
                                                                name="fkDeviceParamTypeValue"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={deviceParamType ? deviceParamType : []}
                                                                        value={value}
                                                                        placeholder="Enter Param Type..."
                                                                    />
                                                                )}
                                                            />
                                                            <Error error={errors["fkDeviceParamTypeValue"]} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="mb-3">
                                                            <label>Description</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="description"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Editor value={value} onChange={onChange} style={{ minHeight: '172px' }} />
                                                                )}
                                                            />
                                                            <Error error={errors['description']} />
                                                        </div>
                                                    </div>

                                                </div>


                                                <DeviceCategoryParamsForm
                                                    id={id}
                                                    categoryDetails={categoryDetails}
                                                    addParam={addParam}
                                                    setAddParam={setAddParam}
                                                    deviceParamType={deviceParamType}
                                                    deviceParamMaster={deviceParamMaster}
                                                    deviceUnitMaster={deviceUnitMaster}
                                                    control={control}
                                                    errors={errors}
                                                />

                                            </div>

                                        </div>
                                        <div className="mt-3 d-flex gap-2 justify-content-start ">
                                            <Button onClick={addNewParamHandler}><Icon name="plus" size={18} /> Add New</Button>
                                        </div>
                                        <hr />
                                        <div className="mt-3 d-flex gap-2 justify-content-end ">
                                            <Link to={URLS.DEVICE_CATEGORY.LIST} className="btn btn-default">Cancel</Link>
                                            <button type="submit" className="btn btn-primary ">{id ? 'Update' : 'Save'}</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        }



                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default DeviceCategoryForm;