import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import TitleBar from "../../components/TopBar/TitleBar"; 
import TableLoader from "../../components/tableLoader";
import Icon from "../../components/icon";
import { Controller, useForm } from "react-hook-form";
import { useBlocks, useGpus, useWards } from "../../_common/hooks/masterHook";
import Select from "react-select";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import toast from "react-hot-toast";
import { useReportApi } from "../../_common/hooks/api/reportApiHook";


const FarmersTrainingAttendanceReport = () => {

    const reportAPI = useReportApi();
    const [farmers, setFarmers] = useState<any[] | null>(null);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;

    const [search, setSearch] = useState<string>('');
    const [searchBlocks, setSearchBlocks] = useState([]);
    const [searchGpus, setSearchGpus] = useState([]);
    const [searchWards, setSearchWards] = useState([]);

    const [filteredGpus, setFilteredGpus] = useState<any[] | null>(null)
    const [filteredWards, setFilteredWards] = useState<any[] | null>(null)

    const blocks: any = useBlocks();
    const gpus: any = useGpus();
    const wards: any = useWards();

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        watch,
    } = useForm();


    const blockWatch = watch("blocks");
    const gpuWatch = watch("gpus"); 

    const getFarmerList = () => {
        reportAPI.getFarmerList({
            PageIndex: page,
            PageSize: limit,
            SearchText: search,
            Blocks: searchBlocks,
            Wards: searchWards,
            Gpus: searchGpus
        }, (message: any, resp: any) => {
            console.log('Farmers =>', resp.data.farmer)
            setFarmers(resp.data.farmer)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setFarmers([])
        })
    }

    const downloadFarmersExcel = async () => {
        reportAPI.getFarmerList({
            PageIndex: 1,
            PageSize: 1000000,
            SearchText: search,
            Blocks: searchBlocks,
            Wards: searchWards,
            Gpus: searchGpus
        }, (message: any, resp: any) => {
            exportReport(resp.data.farmer)
        }, (message: any) => {
        })
    }

    const exportReport = (dataList: any) => {
        if (dataList) {
            let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let fileExt = '.xlsx';
            let data: any = [];
            dataList?.map((farm: any) => {
                data.push({
                    "Farmer Name": farm.farmerName,
                    "Phone Number": farm.phoneNumber,
                    "Aadhaar Number": farm.aadhaarNumber,
                    "Block": farm.block,
                    "Ward": farm.ward,
                    "GPU": farm.gpu,
                    "Kisan Parichay Patra URL": farm.kisanParichayPatraURL,
                })
            })
            let ws: any = XLSX.utils.json_to_sheet(data)
            let wb: any = { Sheets: { 'Farmers': ws }, SheetNames: ['Farmers'] }
            let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            let _data: any = new Blob([excelBuffer], { type: fileType })
            FileSaver.saveAs(_data, 'FarmersList' + fileExt)
        } else {
            toast.error('Not enough data to export!!!')
        }
    }


    useEffect(() => {
        setFarmers(null)
        getFarmerList();
    }, [page, search, searchBlocks, searchWards, searchGpus])


    useEffect(() => {
        if (blockWatch?.length) {
            let _gpus: any = [];
            let _wards: any = [];
            gpus.map((gpu: any) => {
                blockWatch.map((block: any) => {
                    if (gpu.fkBlockID == block.blockID) {
                        _gpus.push(gpu);
                    }
                })
            })
            wards.map((ward: any) => {
                console.log('ward', ward)
                blockWatch.map((block: any) => {
                    if (ward.fkBloackID == block.blockID) {
                        _wards.push(ward);
                    }
                })
            })
            setFilteredGpus(_gpus);
            setFilteredWards(_wards);
        } else {
            setFilteredGpus(null);
            setFilteredWards(null);
        }
    }, [blockWatch])


    useEffect(() => {
        if (gpuWatch?.length) {
            let _wards: any = [];
            wards.map((ward: any) => {

                gpuWatch.map((gpu: any) => {
                    if (ward.fkgpuid == gpu.gpiid) {
                        _wards.push(ward);
                    }
                })
            })
            setFilteredWards(_wards);
            console.log(gpuWatch, wards);
        } else {
            setFilteredGpus(null);
        }
    }, [gpuWatch])



    const submitHandler = (data: any) => {
        console.log(data);
        setSearch(data.search)
        setSearchBlocks(Array.isArray(data?.blocks)
            ? data?.blocks?.map((data: any) => {
                return [data.blockID]
            }) : [])
        setSearchGpus(Array.isArray(data?.gpus)
            ? data?.gpus?.map((data: any) => {
                return [data.gpiid]
            }) : [])
        setSearchWards(Array.isArray(data?.wards)
            ? data?.wards?.map((data: any) => {
                return [data.wardID]
            }) : [])
    }

 const resetHandler = (data: any) => {
        reset({ search: '', blocks: [], gpus: [], wards: [] })
    }

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Farmers Report`}
                            breadcrumbs={{
                                'Reports': '#',
                                'Farmers Report': '#',
                            }} />


                        <div className="events">

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                        <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                            <h2 className="card-heading mb-4">Farmers Lists</h2>
                                            <div className="d-flex align-items-center gap-2 ">

                                                <div className="dropdown">
                                                    <button className="btn btn-outline-secondary dropdown-toggle" onClick={downloadFarmersExcel}  >
                                                        <Icon name="download" size={18} /> Export
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <form action="" onSubmit={handleSubmit(submitHandler)}>
                                            <div className="row mb-3">
                                                <div className="col-sm-10">
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-group float-label-wrap mb-0">
                                                                <label htmlFor="" className="float-label">Select Block</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="blocks"
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                    }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={blocks ? blocks : []}
                                                                            value={value}
                                                                            isMulti={true}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group float-label-wrap mb-0">
                                                                <label htmlFor="" className="float-label">Select GPU</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="gpus"
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                    }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={filteredGpus != null ? filteredGpus : gpus}
                                                                            value={value}
                                                                            isMulti={true}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group float-label-wrap mb-0">
                                                                <label htmlFor="" className="float-label">Select Ward</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="wards"
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                    }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={filteredWards != null ? filteredWards : wards}
                                                                            value={value}
                                                                            isMulti={true}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group float-label-wrap mb-0">
                                                                <label htmlFor="" className="float-label">Search</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="search"
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" placeholder="Search..." />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="two-btn-wrap">
                                                        <button className="btn btn-grey" onClick={resetHandler}>Reset</button>
                                                        <button type="submit" className="btn btn-blue">Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="table-wrapper">
                                            <table className="table gen-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Phone no</th>
                                                        <th scope="col">Aadhaar Number</th>
                                                        <th scope="col">Block</th>
                                                        <th scope="col">Ward</th>
                                                        <th scope="col">GPU</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        farmers?.map((data, index) => (
                                                            <tr key={`farmer-${data.farmerID}`}>
                                                                <td>{index + 1 + ((page - 1) * limit)}</td>
                                                                <td>{data?.farmerName}</td>
                                                                <td>{data?.phoneNumber}</td>
                                                                <td>{data?.aadhaarNumber}</td>
                                                                <td>{data?.block}</td>
                                                                <td>{data?.ward}</td>
                                                                <td>{data?.gpu}</td>
                                                                {/* <td><span className="badge text-bg-danger">No</span></td>
                                                                <td><span className="badge text-bg-danger">Not Certified</span></td> */}
                                                            </tr>
                                                        ))
                                                    }


                                                </tbody>
                                            </table>
                                            <TableLoader data={farmers} />
                                        </div>
                                        {farmers ? <Pagination total={total} page={page} setPage={(n: number) => {
                                            setPage(n)
                                            setFarmers(null)
                                        }} pageLimit={limit} /> : null}
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default FarmersTrainingAttendanceReport;