import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { useUser, useUserPermissions } from "../../../_common/hooks/authHook";
import { useDeviceApi } from "../../../_common/hooks/api/deviceApiHook";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import Search from "../../../components/search/Search";
import { URLS } from "../../../_config";
import Status from "../../../components/status";
import { replaceUrlParams } from "../../../_common/functions";
import TableLoader from "../../../components/tableLoader";
import Pagination from "../../../components/pagination/Pagination";
import Footer from "../../../components/footer/Footer";
import Icon from "../../../components/icon";
import { Form } from "react-bootstrap";
import { ConfirmAlert } from "../../../_common/confirmAlert";


const DeviceCategoryList = () => {
    const user: any = useUser()
    const permissions: any = useUserPermissions();
    const deviceapi = useDeviceApi();

    const [devices, setDevices] = useState<any>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;

    const getAllDevicestype = () => {
        deviceapi.getAllDevicestype({
            urlParams: {
                PageIndex: page,
                PageSize: limit, 
            }, SearchText: search
        }, (message: any, resp: any) => {
            setDevices(resp.data.deviceTemplate)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setDevices([])
            setTotal(0)
        })
    }
 

    const changeDeviceCategoryStatus = (params: any) => {
        return new Promise((resolve, reject) => {
            deviceapi.deviceCategoryChangeStatus(params, resolve, reject)
        })
    }

    const devicesCategoryStatushandler = (el: any, devices: any) => {
        ConfirmAlert.changeStatus('Device type', changeDeviceCategoryStatus, {
            "DeviceTemplateID": devices.deviceTemplateID,
            "updatedBy": user?.userID,
            "status": el.checked,
            "isDeleted": false,
        }).then(() => {
            getAllDevicestype();
        }).catch(() => {
            el.checked = !el.checked
        })
    }
 
    const deleteDataItem = (dataItem: any) => {
        return new Promise((resolve, reject) => {
            deviceapi.deviceCategoryDelete({
                "deviceTemplateID": dataItem.deviceTemplateID,
                "deletedBy": user?.userID,
                "isDeleted": true,
            }, resolve, reject)
        })
    }

    const deleteDataItemhandler = (dataItem: any) => {
        ConfirmAlert.delete('Device Category', deleteDataItem, dataItem).then(() => {
            getAllDevicestype();
        })
    }



    useEffect(() => {
        getAllDevicestype();
    }, [page, search])

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'Device Category'} breadcrumbs={{
                            'IoT Device': '#',
                            'Device Category': '#',
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 aos-init aos-animate" data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center mb-4 ">
                                        <h2 className="card-heading mb-4">Device Category List</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            <Search setSearch={(str: string) => {
                                                setSearch(str)
                                                setDevices(null)
                                                setPage(1)
                                            }}></Search>
                                            {permissions.menus['IoT Device'].write &&
                                                <Link to={URLS.DEVICE_CATEGORY.ADD} className="btn btn-primary d-flex align-items-center gap-2 "><GoPlus /> Add New</Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Device Name</th>
                                                    {/* <th scope="col">Sensor Key</th> */}
                                                    <th scope="col">Device Type</th>
                                                    {/* <th scope="col">Sensor Unit</th> */}
                                                    {/* <th scope="col">Description</th> */}
                                                    <th scope="col">Status</th>
                                                    <th scope="col" style={{ width: 150 }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    devices?.map((data: any, i: number) => (
                                                        <tr key={`device-${data.deviceTemplateID}`}>
                                                            <td>{i + 1}</td>
                                                            <td>{data.deviceName}</td>
                                                            <td>{data.deviceType}</td>
                                                            {/* <td>{data.description}</td> */}
                                                            <td>
                                                                <Status status={data.isActive} />
                                                            </td>
                                                            <td>
                                                                <div className="action-btns">
                                                                    {permissions.menus['IoT Device'].write &&
                                                                        <>
                                                                            <Form.Check // prettier-ignore
                                                                                type="switch"
                                                                                id={`status-switch-${data.deviceTemplateID}`}
                                                                                label=""
                                                                                onChange={(e) => { devicesCategoryStatushandler(e.target, data) }}
                                                                                defaultChecked={data.isActive}
                                                                            />


                                                                            <Link to={replaceUrlParams(URLS.DEVICE_CATEGORY.EDIT, { id: data.deviceTemplateID })}><Icon name="edit" size={18} /></Link>

                                                                        </>
                                                                    }

                                                                    <Link to={replaceUrlParams(URLS.DEVICE_CATEGORY.DETAILS, { id: data.deviceTemplateID })}><Icon name="view" size={18} /></Link>
 
                                                                     {
                                                                        permissions.menus['IoT Device'].delete &&
                                                                        <Icon name="delete" size={18}
                                                                            onClick={() => deleteDataItemhandler(data)} />
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <TableLoader data={devices} />
                                    </div>
                                    {devices ? <Pagination total={total} page={page} setPage={(n: number) => {
                                        setPage(n)
                                        setDevices(null)
                                    }} pageLimit={limit} /> : null}

                                </div>

                            </div>
                        </div>


                    </div>
                </div>


                <Footer></Footer>
            </div >
        </React.Fragment >
    )
}

export default DeviceCategoryList;