import React, { useState } from 'react';
import { Button, Carousel, Modal } from 'react-bootstrap';
import { MAPAPI_DOMAIN } from '../../../_config/site_urls';

interface GalleryModalProps {
    open: boolean;
    close: () => void;
    urls: string[];
    start?: number;
}

const GalleryModal: React.FC<GalleryModalProps> = ({ open, close, urls, start }) => {
    const [index, setIndex] = useState(start||0);

  const handleSelect = (selectedIndex:any) => {
    setIndex(selectedIndex);
  };
    return (
        <Modal show={open} onHide={close} >
            <Modal.Header closeButton>
                <Modal.Title>Field Assests</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                    {urls.map((url, i) => (
                        <Carousel.Item key={i}>
                            <img className="d-block w-100" src={MAPAPI_DOMAIN + url} alt="First slide" />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Modal.Body>
        </Modal>
    );
};

export default GalleryModal;