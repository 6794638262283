import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from 'react-dropzone';
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import Footer from "../../../components/footer/Footer";
import TitleBar from "../../../components/TopBar/TitleBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URLS } from "../../../_config";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import { Controller, useForm } from "react-hook-form";
import Error from "../../../components/formFieldError";
import Select from "react-select";
import FormTextInput from "../../../_common/form-elements/textinput/formTextInput";
import toast from "react-hot-toast";
import { useUser } from "../../../_common/hooks/authHook";
import FormTexAreatInput from "../../../_common/form-elements/textareaInput";
import { getSelectedValue, localFileToBase64 } from "../../../_common/functions";



const CropAdd = () => {

    const params: any = useParams();
    const navigate: any = useNavigate();
    const cropID = params.id
    const collateralApi = useCollatralApi();
    const [cropCats, setCropCats] = useState<any[] | null>(null)
    const user: any = useUser();
    const [cropImg, setCropImg] = useState<string>("/images/no_image.jpg");
    const [cropImages, setCropImages] = useState<any[]>([]);
    const [dataReset, setDataReset] = useState<boolean>(false)

    const imgRef: any = useRef();
    const {
        control,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm();


    const getCropCategoryList = () => {
        collateralApi.cropCategoryList({  PageIndex: 1, PageSize: 100, IsActiveCropCategory: true },
            (message: any, resp: any) => {
                const tempArr = resp.data.cropCategory;
                tempArr && tempArr?.map((tempItem: any) => {
                    tempItem.label = tempItem.cropCategoryName
                    tempItem.value = tempItem.cropCategoryID
                })
                setCropCats(tempArr)
            }, (message: any) => {
                setCropCats([])
            })
    }

    const getCropCategoryDetails = () => {
        collateralApi.cropDetails({ urlParams: { CropID: cropID } }, (message: any, resp: any) => {
            console.log('Crop Details => ',)
            let crop: any = resp.data.crop[0];
            let resetValues: any = {
                // "fkCropCategoryID": crop?.fkCropCategoryID,
                "cropName": crop.cropName,
                "cropDescription": crop.cropDescription,
                "cropImage": crop?.cropImage,
                fkCropCategoryID: getSelectedValue(crop?.fkCropCategoryID, "value", cropCats as any),
            }
            if (crop.cropImage) {
                setCropImages([crop?.cropImage])
                setCropImg(crop?.cropImage);
            }
            reset(resetValues);
            setDataReset(true)

        }, (message: any) => {
            //----
        })
    }


    useEffect(() => {
        getCropCategoryList();
    }, [])

    useEffect(() => {
        if (cropID && cropCats) {
            getCropCategoryDetails()
        }
    }, [cropID, cropCats])

    const saveData = (data: any) => {
        return new Promise((resolve, reject) => {
            let params: any = {
                "fkCropCategoryID": data?.fkCropCategoryID?.value,
                "cropName": data.cropName,
                "cropDescription": data.cropDescription,
                "cropImage": cropImages[0],
                "addedBy": user?.userID,
                "isActive": true,
            }
            if (cropID) {
                params.cropID = cropID;
                collateralApi.cropUpdate(params, resolve, reject)
            } else {
                collateralApi.cropSave(params, resolve, reject)
            }
        })
    }

    const submitCropHandler = (data: any) => {
        toast.promise(
            saveData(data), {
            loading: cropID ? 'Updating...' : 'Adding...',
            success: (msg: any) => {
                navigate(URLS.COLLATERAL.CROP.LIST)
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const handlePicChange = (event: any) => {
        localFileToBase64(event.target.files).then((images: any) => {
            collateralApi.cropFileUpload(images.map((img: any) => { return { image: img } }), (message: any, resp: any) => {
                setCropImages(resp.data.cropImage.map((imgData: any) => { return imgData.imageUrl }))
                setCropImg(resp.data.cropImage[0].imageUrl)
            }, () => { })
        })
    };

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`${cropID ? 'Edit' : 'Add'} Farming Method `}
                            breadcrumbs={{
                                'Collateral': '#',
                                'Farming Methods': URLS.COLLATERAL.CROP.LIST,
                                [cropID ? 'Edit' : 'Add']: '#',
                            }} />

                        <div className="row" data-aos="fade-up">

                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 " >
                                    <form className="row" onSubmit={handleSubmit(submitCropHandler)}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label>Category Name <span className="text-danger">*</span></label>
                                                    <Controller
                                                        defaultValue={""}
                                                        rules={{ required: "Category is required" }}
                                                        control={control}
                                                        name="fkCropCategoryID"
                                                        render={({
                                                            field: { onChange, onBlur, value, name, ref },
                                                        }) => (
                                                            <Select
                                                                name={name}
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                options={cropCats ? cropCats : []}
                                                                value={value}
                                                                placeholder="Enter Category..."
                                                            />
                                                        )}
                                                    />
                                                    <Error error={errors["fkCropCategoryID"]} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label>Crop Name <span className="text-danger">*</span></label>
                                                    <Controller
                                                        defaultValue={""}
                                                        rules={{ required: "Crop name is required" }}
                                                        control={control}
                                                        name="cropName"
                                                        render={({
                                                            field: { onChange, onBlur, value, name, ref },
                                                        }) => (
                                                            <FormTextInput
                                                                name={name}
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                type="text"
                                                                placeholder="Enter crop name"
                                                            />
                                                        )}
                                                    />
                                                    <Error error={errors["cropName"]} />

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="mb-1">Category Description <span className="text-danger">*</span></label>
                                                    <Controller
                                                        defaultValue={""}
                                                        rules={{ required: "Crop description is required" }}
                                                        control={control}
                                                        name="cropDescription"
                                                        render={({
                                                            field: { onChange, onBlur, value, name, ref },
                                                        }) => (
                                                            <FormTexAreatInput
                                                                name={name}
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                type="text"
                                                                placeholder="Enter crop description  "
                                                                rows={6}
                                                            />
                                                        )}
                                                    />
                                                    <Error error={errors["cropDescription"]} />
                                                </div>

                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="mb-1">Upload Crop Image</label>
                                                    <div>
                                                        <form action="#" className="dropzone">
                                                            <div className="fallback">
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="cropImage"
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            accept="image/*"
                                                                            ref={imgRef}
                                                                            onChange={(e) => {
                                                                                handlePicChange(e);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="dz-message needsclick">
                                                                <div className="mb-3">
                                                                    <img src={cropImg} alt="" width="120px" />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                    <Link to={URLS.COLLATERAL.CROP.LIST} className="btn btn-default">Cancel</Link>
                                                    <button className="btn btn-primary ">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default CropAdd;