import React from "react";

const Footer = () => {
    const fullyear = new Date().getFullYear();
    return(
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        {fullyear} &copy; Precision Farming.
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;