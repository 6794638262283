import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import Header from "../../components/header/Header";
import Icon from "../../components/icon";
import ImportExcelModal from "../../modals/importExcel.modal";
import { useFarmerApi } from "../../_common/hooks/api/farmerApiHook";
import { Link } from "react-router-dom";
import { getSelectedValue, replaceUrlParams } from "../../_common/functions";
import { URLS } from "../../_config";
import TableLoader from "../../components/tableLoader";
import TitleBar from "../../components/TopBar/TitleBar";
import Status from "../../components/status";
import { Form } from "react-bootstrap";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import { ConfirmAlert } from "../../_common/confirmAlert";
import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";
import SearchWithLocation from "../../components/search/SearchWithLocation";

const FarmerList = () => {
    const [importModal, setImportModal] = useState<boolean>(false)
    const farmerapi = useFarmerApi();
    const [farmers, setFarmers] = useState<any[] | null>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;
    const permissions: any = useUserPermissions();
    const user: any = useUser()

    const farmerApi = useFarmerApi();

    const districts: any = useDistricts();
    const wards: any = useWards();
    const gpus: any = useGpus();
    const blocks: any = useBlocks();
    const [stateData, setStateData] = useState<boolean>(false);

    const [searchDistricts, setSearchDistricts] = useState([]);
    const [searchBlocks, setSearchBlocks] = useState([]);
    const [searchGpus, setSearchGpus] = useState([]);
    const [searchWards, setSearchWards] = useState([]);


    const getFarmerList = () => {
        farmerapi.getAllFarmers({
            PageIndex: page,
            PageSize: limit,
            SearchText: search,
            districtIDs: searchDistricts.join(),
            BlockIDs: searchBlocks.join(),
            WardIDs: searchWards.join(),
            GPUIDs: searchGpus.join()
        }, (message: any, resp: any) => {
            console.log('Farmers =>', resp.data.farmer)
            setFarmers(resp.data.farmer)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setFarmers([])
        })
    }


    const dataItemChangeStatus = (params: any) => {
        return new Promise((resolve, reject) => {
            farmerapi.farmerChangeStatus(params, resolve, reject)
        })
    }

    const dataItemChangeStatusHandler = (el: any, dataItem: any) => {
        ConfirmAlert.changeStatus('Farmer', dataItemChangeStatus, {
            "farmerID": dataItem.farmerID,
            "updatedBy": user?.userID,
            "status": el.checked,
            "isDeleted": false,
        }).then(() => {
            getFarmerList();
        }).catch(() => {
            el.checked = !el.checked
        })
    }

    const uploadFarmer = (data: any) => {
        return new Promise((resolve, reject) => {
            farmerApi.importFarmer({ farmerList: data }, (message: any, resp: any) => { resolve(resp) }, reject)
        })
    }

    useEffect(() => {
        if (districts?.length > 0 && blocks?.length > 0 && gpus?.length > 0 && wards?.length > 0) {
            setStateData(true)
            // getFarmerList();
        }
    }, [districts, blocks, gpus, wards])


    useEffect(() => {
        setFarmers(null)
        getFarmerList();
    }, [page, search, searchBlocks, searchWards, searchGpus])



    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`Farmer`}
                            breadcrumbs={{
                                'Farmer': '#',
                            }} />


                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 ">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-0">Farmer List</h2>
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="d-flex align-items-center gap-2 ">
                                            </div>
                                            <Button onClick={() => { setImportModal(true) }} disabled={!stateData} ><Icon name="upload" size={18} /> Upload</Button>
                                        </div>
                                    </div>


                                    <SearchWithLocation
                                        setSearch={setSearch}
                                        setDistricts={setSearchDistricts}
                                        setBlocks={setSearchBlocks}
                                        setGpus={setSearchGpus}
                                        setWards={setSearchWards}
                                    />

                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Farmer Code</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Phone no</th>
                                                    <th scope="col">District</th>
                                                    <th scope="col">Block</th>
                                                    <th scope="col">GPU</th>
                                                    <th scope="col">Ward</th>
                                                    {/* <th scope="col">Organic</th> */}
                                                    {/* <th scope="col">Kisan Parichaypatra</th> */}
                                                    <th scope="col">Verified</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    farmers?.map(data => (
                                                        <tr>
                                                            <td>{data?.farmerCode}</td>
                                                            <td>{data?.farmerName}</td>
                                                            <td>{data?.phoneNumber}</td>
                                                            <td>{data?.district ? data?.district : '--'}</td>
                                                            <td>{data?.block ? data?.block : '--'}</td>
                                                            <td>{data?.gpu ? data?.gpu : '--'}</td>
                                                            <td>{data?.ward ? data?.ward : '--'}</td>
                                                            {/* <td><Organicfarmer status={data.organic} /></td> */}
                                                            {/* <td>
                                                                {data?.kisanParichayPatraURL ?
                                                                    <Link to={data?.kisanParichayPatraURL} target="_blank" className="text-primary ">Downalod</Link>
                                                                    : <>--</>}
                                                            </td> */}
                                                            <td><Status status={(data.verified == 1) ? 'Verified' : 'Not verified'} /></td>
                                                            <td> <Status status={data.isActive} /></td>
                                                            <td>
                                                                <div className="action-btns">
                                                                    {
                                                                        permissions.menus['Farmer'].write &&
                                                                        <>
                                                                            <Form.Check
                                                                                type="switch"
                                                                                id={`status-switch-${data.farmID}`}
                                                                                onChange={(e) => { dataItemChangeStatusHandler(e.target, data) }}
                                                                                defaultChecked={data.isActive}
                                                                            />

                                                                        </>
                                                                    }
                                                                    <Link to={replaceUrlParams(URLS.FARMER_DETAILS, { farmerID: data.farmerID })}><Icon name="view" size={18} /></Link>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }





                                            </tbody>
                                        </table>
                                        <TableLoader data={farmers} />
                                    </div>
                                    {farmers ? <Pagination total={total} page={page} setPage={(n: number) => {
                                        setPage(n)
                                        setFarmers(null)
                                    }} pageLimit={limit} /> : null}
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                {stateData && <ImportExcelModal
                    show={importModal}
                    onClose={setImportModal}
                    sampleName="Farmers"
                    dataTypes={[
                        { title: 'Farmer Name', type: 'string', required: true, apiKey: 'farmerName' },
                        { title: 'Code', type: 'string', apiKey: 'farmerCode' , required: true},
                        { title: 'DOB', type: 'date', apiKey: 'dob' },
                        { title: 'Father/Husband Name', type: 'string', apiKey: 'fatherHusbandName' },
                        { title: 'Email', type: 'string', apiKey: 'email' },
                        { title: 'Contact Number', type: 'number', apiKey: 'phoneNumber', min: 10, max: 10, required: true },
                        { title: 'Aadhaar Number', type: 'number', apiKey: 'adharNumber', min: 12, max: 12, required: true },
                        { title: 'Marital Status', type: 'option', apiKey: 'maritalStatus', options: ['Single', 'Married', 'Widowed', 'Divorced'] },
                        { title: 'Coi', type: 'string', apiKey: 'coi' },
                        { title: 'Kishan Parichay Patra', type: 'string', apiKey: 'kisanParichayPatra' },
                        { title: 'Kishan Parichay Patra Url', type: 'url', apiKey: 'kisanParichayPatraURL' },
                        { title: 'District Name', type: 'option', apiKey: 'districtName', options: districts.map((dist: any) => { return dist.districtName }), required: true },
                        { title: 'Block Name', type: 'option', apiKey: 'blockName', relativeOptions: blocks?.map((block: any) => { return { name: block.blockName, parent: getSelectedValue(block.fkDistrictID, 'districtId', districts).districtName } }), relWith: 'District Name', required: true },
                        { title: 'Gpu Name', type: 'option', apiKey: 'gpuName', relativeOptions: gpus.map((gpu: any) => { return { name: gpu.cpuName, parent: getSelectedValue(gpu.fkBlockID, 'blockID', blocks).blockName } }), relWith: 'Block Name', required: true },
                        { title: 'Ward Name', type: 'option', apiKey: 'wardName', relativeOptions: wards.map((ward: any) => { return { name: ward.wardName, parent: getSelectedValue(ward.fkgpuid, 'gpiid', gpus).cpuName } }), relWith: 'Gpu Name', required: true },
                        { title: 'Farmer Image', type: 'img', apiKey: 'farmerImage' },
                        /*  { title: 'Verified', type: 'option', apiKey: 'verified', options: ['verified','not verified']},
                         { title: 'Organic', type: 'option', apiKey: 'organic', options: ['organic','inorganic']}, */

                    ]}
                    upload={uploadFarmer}
                    params={{}}
                    onUploadComplete={getFarmerList}
                />}
                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default FarmerList;