import React, { HtmlHTMLAttributes } from 'react'

import { FaHome} from 'react-icons/fa';
import { CiEdit } from "react-icons/ci";
import { MdSensors } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";
import { FaPlus, FaSeedling, FaTrashCan } from "react-icons/fa6";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoIosArrowBack, IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import { TiLocationArrow } from "react-icons/ti";
import { PiWarningCircleFill } from "react-icons/pi";
import { GiSettingsKnobs } from "react-icons/gi";
import { TbAlertSquareRoundedFilled, TbBulb } from 'react-icons/tb';
import { BsFileEarmarkPdfFill } from 'react-icons/bs';

type nameType ='home' | 'alert'| 'ai' | 'crop'| 'bulb' | 'edit' | 'sensor' | 'pdf' |'settings'| 'alertSq' | 'warning' | 'trash' | 'plus' | 'info' | 'arrow-right'| 'arrow-left' | 'wind-direction' | 'risk' | 'knob-settings' | 'back';

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    name: nameType;
    size?: any;
    className?: string;
    onClick?: (e: any) => void
}
const Icon: React.FC<props> = ({
    children,
    name,
    size = '24',
    className = '',
    onClick,
    ...args
}) => {


    const getIconByName = (name: nameType) => {
        switch (name) {
            
            case 'home': return <FaHome style={{ fontSize: `${size}px` }} />;
            case 'edit': return <CiEdit style={{ fontSize: `${size}px` }} />;
            case 'sensor': return <MdSensors style={{ fontSize: `${size}px` }} />;
            case 'trash': return <FaTrashCan style={{ fontSize: `${size}px` }} />;
            case 'plus': return <FaPlus style={{ fontSize: `${size}px` }} />;
            case 'info': return <RiErrorWarningFill style={{ fontSize: `${size}px` }} />;
            case 'settings': return <IoSettingsSharp style={{ fontSize: `${size}px` }} />;
            case 'arrow-right': return <IoIosArrowForward style={{ fontSize: `${size}px` }} />;
            case 'arrow-left': return <IoIosArrowBack style={{ fontSize: `${size}px` }} />;
            case 'wind-direction': return <TiLocationArrow style={{ fontSize: `${size}px` }} />;
            case 'back': return <IoMdArrowBack style={{ fontSize: `${size}px` }} />;
            case 'risk': return <PiWarningCircleFill style={{ fontSize: `${size}px` }} />;
            case 'alertSq': return <TbAlertSquareRoundedFilled style={{ fontSize: `${size}px` }} />;
            case 'pdf': return <BsFileEarmarkPdfFill style={{ fontSize: `${size}px` }} />;
            case 'knob-settings': return <GiSettingsKnobs style={{ fontSize: `${size}px` }} className='rotate-90' />;
            case 'bulb': return <TbBulb style={{ fontSize: `${size}px` }}  />;
            case 'crop': return <FaSeedling style={{ fontSize: `${size}px` }}  />;
            case 'alert': return (
                    <svg stroke="" style={{ fontSize: `${size}px` }} fill="url(#alert-icon)" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <radialGradient id="alert-icon" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                                <stop offset="30%" stopColor="#ef4444" />
                                <stop offset="100%" stopColor="#b91c1c" />
                            </radialGradient>
                        </defs>
                        <path d="M6 8a6 6 0 1 1 12 0v2.917c0 .703.228 1.387.65 1.95L20.7 15.6a1.5 1.5 0 0 1-1.2 2.4h-15a1.5 1.5 0 0 1-1.2-2.4l2.05-2.733a3.25 3.25 0 0 0 .65-1.95Zm6 13.5A3.502 3.502 0 0 1 8.645 19h6.71A3.502 3.502 0 0 1 12 21.5Z"></path>
                    </svg>
            );
            case 'warning': return (
                <svg  stroke="" fill="url(#warning)" style={{ fontSize: `${size}px` }} strokeWidth="0" viewBox="0 0 512 512" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <radialGradient id="warning" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                        <stop offset="30%" stopColor="#fbbf24" />
                        <stop offset="100%" stopColor="#d97706" />
                    </radialGradient>
                </defs>
            <path  d="M228.9 79.9L51.8 403.1C40.6 423.3 55.5 448 78.9 448h354.3c23.3 0 38.2-24.7 27.1-44.9L283.1 79.9c-11.7-21.2-42.5-21.2-54.2 0zM273.6 214L270 336h-28l-3.6-122h35.2zM256 402.4c-10.7 0-19.1-8.1-19.1-18.4s8.4-18.4 19.1-18.4 19.1 8.1 19.1 18.4-8.4 18.4-19.1 18.4z"></path></svg>
            );
            case 'ai': return (
                <svg xmlns="http://www.w3.org/2000/svg" fill='currentColor' viewBox="0 0 512 512" height="1em" width="1em" style={{ fontSize: `${size}px` }}><path d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c-35.3 0-64 28.7-64 64l-40 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l40 0 0 56-40 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l40 0 0 56-40 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l40 0c0 35.3 28.7 64 64 64l0 40c0 13.3 10.7 24 24 24s24-10.7 24-24l0-40 56 0 0 40c0 13.3 10.7 24 24 24s24-10.7 24-24l0-40 56 0 0 40c0 13.3 10.7 24 24 24s24-10.7 24-24l0-40c35.3 0 64-28.7 64-64l40 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-40 0 0-56 40 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-40 0 0-56 40 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-40 0c0-35.3-28.7-64-64-64l0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40-56 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40-56 0 0-40zm32 152c6.4 0 12.1 3.8 14.7 9.6l42 96 14 32c3.5 8.1-.1 17.5-8.2 21.1s-17.5-.2-21.1-8.2L239.5 304l-63.1 0-9.8 22.4c-3.5 8.1-13 11.8-21.1 8.2s-11.8-13-8.2-21.1l14-32 42-96c2.5-5.8 8.3-9.6 14.7-9.6zm17.5 96L208 231.9 190.5 272l35.1 0zM336 176c8.8 0 16 7.2 16 16l0 128c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16z"/></svg>
            );
            default: return null;
        }
    }

    const getStyle = () => {
        let style: any = {}
        if (onClick) {
            style.cursor = 'pointer';
        }
        return style
    }

    return (
        <span onClick={onClick ? onClick : (e: any) => { }} className={`${className} ${(onClick) ? 'cursor-pointer' : ''}`}
            style={getStyle()}>
            {getIconByName(name)}
        </span>
    )
}

export default Icon;
