import React, { PureComponent, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { fromUrl, fromArrayBuffer } from "geotiff";
import 'mapbox-gl/dist/mapbox-gl.css';
import { Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Line, Legend } from 'recharts';
import moment from 'moment';
import Select from 'react-select';
import { API_BASE_URL, DATA } from '../../_config';
import * as ExifReader from 'exifreader';
import axios from 'axios';
import { decode } from 'tiff';
import { isPresentInOptions } from '../../_config/functions';
import { Link, useParams } from 'react-router-dom';
import { useAppLoader } from '../../../../_common/hooks/common/appLoaderHook';
import LeftPanel from '../../../../components/leftPanel/LeftPanel';
import Header from '../../../../components/header/Header';
import RightPanel from '../../components/rightPannel/rightPanel';
import { GRAPH_COLORS, URLS } from '../../../../_config';
import { replaceUrlParams } from '../../../../_common/functions';
import Loader from '../../../../components/loader';
import { MAPAPI_BASE_URL } from '../../../../_config/site_urls';
import TitleBar from '../../../../components/TopBar/TitleBar';
import Slider from 'react-slick';



const Weather: React.FC = () => {
    const { showLoader, hideLoader } = useAppLoader();
    const params: any = useParams();

    const mapContainerRef: any = useRef(null);
    const mapRef: any = useRef(null);
    const [mapboxLoaded, setMapboxLoaded] = useState<boolean>(false);

    const [tiffImage, setTiffImage] = useState<any>(null);
    const [graphData, setGraphData] = useState<any>(null);
    const [fromDate, setFromDate] = useState<any>(moment().add(-7, 'days').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState<any>(moment().format('YYYY-MM-DD'));
    const [selectedDataType, setSelectedDataType] = useState<any>(null);
    const [selectedIndexDataType, setSelectedIndexDataType] = useState<any>({ value: 'ndvi', label: 'NDVI' });
    const [fields, setFields] = useState<any[] | null>(null);
    const [field, setField] = useState<any>(null);
    const [history, setHistory] = useState<any>(null);
    const [drawings, setDrawings] = useState<any[]>([]);
    const [selectedCrop, setSelectedCrop] = useState<any>(null);
    const [selectedNdviMap, setSelectedNdviMap] = useState<any>(null);
    const [ndviMapData, setNdviMapData] = useState<any>(null);
    const [alerts, setAlerts] = useState<any>(null);
    const [modelAlerts, setModelAlerts] = useState<any>(null);
    const [ruleAlerts, setRuleAlerts] = useState<any>(null);
    const [forecast, setForecast] = useState<any>(null);
    const [fieldAssets, setFieldAssets] = useState<any>([]);
    const [indexHealth, setindexHealth] = useState<string | null>(null);

    const graphLineThickness = 2;

    const sourceLoadingRef:any = useRef();
    const ndviSlider:any = useRef();

    const [dataTypes, setDataTypes] = useState([
        { value: 'Air Humidity', label: 'Air Humidity' },
        { value: 'Soil EC', label: 'Soil EC' },
        { value: 'Soil Moisture', label: 'Soil Moisture' },
        { value: 'Soil Salinity', label: 'Soil Salinity' },
        { value: 'Soil Temperature', label: 'Soil Temperature' },
        { value: 'Soil pH', label: 'Soil pH' },
        { value: 'Solar Radiation', label: 'Solar Radiation' },
        { value: 'Wind Direction', label: 'Wind Direction' },
        { value: 'Wind speed', label: 'Wind speed' },
        /* { value: 'Co2', label: 'Co2' }, */
    ]);

    const [indexDataTypes, setIndexDataTypes] = useState([
        { value: 'ndvi', label: 'NDVI' },
    ]);

    const getFields = async () => {
        showLoader();
        try {
            const response = await fetch(API_BASE_URL + "/fields");
            const data = await response.json();
            setFields(data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    };

    const getFieldDeatils = async () => {
        showLoader();
        try {
            const response = await fetch(API_BASE_URL + "/fields/" + params.id);
            const data = await response.json();
            if (data.map_data && data.map_data.length > 0) {
                let mapDates: any = [];
                let maps: any = [];
                data.map_data.map((d: any) => {
                    if (moment(d.timestamp).isBetween(moment(fromDate), moment(toDate), undefined, '[]')) {
                        let dt = moment(d.timestamp).format('YYYY-MM-DD');
                        if (mapDates.indexOf(dt) === -1) {
                            mapDates.push(dt);
                            maps.push({ ...d, key: moment(d.timestamp).format('YYYY-MM-DD') + data._id });
                        }
                    }
                });
                setNdviMapData(maps);
            } else {
                setNdviMapData([]);
            }
            setField(data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    }

    const getFieldHistory = async () => {
        showLoader();
        try {
            const response = await fetch(API_BASE_URL + `/fields/${params.id}/history?${new URLSearchParams({
                _from: fromDate + ' 00:00:00',
                _to: toDate + ' 23:59:59'
            })}`);
            const data: any = await response.json();

            var _ndvidata = data[0]?.field_index_data;
            if (_ndvidata) {

                data[0].crops.map((crop: any) => {
                    let data: any = {};
                    crop.sensors.map((sensor: any) => {
                        let dt = moment(sensor.timestamp).format('YYYY-MM-DD');
                        if (Object.keys(data).indexOf(dt) === -1) {
                            data[dt] = {};
                        }
                        if (Object.keys(data[dt]).indexOf(sensor.sensor_name) === -1) {
                            data[dt][sensor.sensor_name] = [];
                        }

                        data[dt][sensor.sensor_name].push(sensor);
                    });

                    crop.data = data;
                    crop.label = crop.crop_name;
                    crop.value = crop.crop_id;


                    let dayWiseData: any = [];
                    Object.keys(data).map((dt: any) => {
                        let el: any = { date: dt }
                        Object.keys(data[dt]).map((key: any) => {
                            el[key] = data[dt][key]?.[0]?.value || 0;
                        });
                        dayWiseData.push(el);
                    });


                    _ndvidata.map((ndvidata: any) => {
                        let _dt = moment(ndvidata.timestamp).format('YYYY-MM-DD');
                        if (moment(ndvidata.timestamp).isBetween(moment(fromDate), moment(toDate), undefined, '[]')) {
                            let placedData = false;

                            dayWiseData.map((el: any) => {
                                let dt = moment(el.date).format('YYYY-MM-DD');
                                if (_dt == dt) {
                                    placedData = true;
                                    el['ndvi'] = ndvidata.index_value_sd;
                                }
                                return el;
                            });
                            if (!placedData) {
                                let el: any = { date: _dt }
                                el['ndvi'] = ndvidata.index_value_sd;
                                dayWiseData.push(el);
                            }
                        }
                    });

                    crop.dayWiseData = dayWiseData;
                });

                //setHistory(data[0]);
                getForecast().then((forecast: any) => {
                    Object.keys(forecast).map((fdt: any) => {
                        data[0].crops.map((crop: any) => {
                            let pushed: boolean = false;
                            crop.dayWiseData.map((d: any) => {
                                if (d.date == fdt) {
                                    forecast[fdt].map((f: any) => {
                                        d[f.sensor_name + ' forecast'] = f.average_prediction;
                                    });
                                    pushed = true;
                                }
                            });
                            if (!pushed) {
                                let el: any = { date: fdt }
                                forecast[fdt].map((f: any) => {
                                    el[f.sensor_name + ' forecast'] = f.average_prediction;
                                });
                                crop.dayWiseData.push(el);
                            }

                            return crop;
                        });
                    });

                    data[0].crops.map((crop: any) => {
                        crop.dayWiseData = crop.dayWiseData.sort((a: any, b: any) => {
                            return new Date(a.date).getTime() - new Date(b.date).getTime();
                        });
                        return crop;
                    });

                    setHistory(data[0]);
                });
            } else {
                setHistory(null);
            }

            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    }

    const getForecast = async () => {
        return new Promise(async (resolve, reject) => {
            const response = await fetch(API_BASE_URL + `/fields/${params.id}/forecast?${new URLSearchParams({
                from_date: moment().add(1, 'days').format('YYYY-MM-DD'),
                to_date: moment().add(10, 'days').format('YYYY-MM-DD')
            })}`);

            const data = await response.json();
            let _forecast: any = {};
            data.forEach((d: any) => {
                let dt = moment(d.date).format('YYYY-MM-DD');
                if (!_forecast[dt]) {
                    _forecast[dt] = [];
                }
                _forecast[dt].push(d);
            });
            console.log('forecast--->', _forecast);
            setForecast(_forecast);
            resolve(_forecast);
        });
    };

    const getFieldCropAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/alerts?${new URLSearchParams({
                field_or_farm_id: params.id
            })}`);
            const data: any = await response.json();
            setAlerts(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getFieldModelAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/model-alerts?${new URLSearchParams({
                field_or_farm_id: params.id
            })}`);
            const data: any = await response.json();
            setModelAlerts(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getFieldRuleAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/rule-alerts?${new URLSearchParams({
                field_or_farm_id: params.id
            })}`);
            const data: any = await response.json();
            setRuleAlerts(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    class CustomizedXAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={10} fontSize={10} textAnchor="end" fill="#222222" transform="rotate(0)">
                        {moment(payload.value).format('DD-MMM')}
                    </text>
                </g>
            );
        }
    }
    class CustomizedYAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={0} textAnchor="end" fontSize={11} fill="#222222" transform="rotate(0)">
                        {payload.value.toFixed(2)}
                    </text>
                </g>
            );
        }
    }
    const renderLegend = (props: any) => {
        const { payload } = props;
        return (
            <div className="flex justify-center gap-2" >
                {
                    payload.map((entry: any, index: any) => {
                        let lable = entry.value;
                        let icon: any = '';
                        switch (entry.value) {
                            case 'Soil EC forecast':
                            case 'Soil pH forecast':
                            case 'Soil Moisture forecast':
                            case 'Soil temp forecast':
                            case 'Soil Salinity forecast':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M112 476h160v72H112zm320 0h160v72H432zm320 0h160v72H752z"></path></svg>
                                break;
                            case 'ndvi':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 21H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z"></path></svg>
                                lable = 'NDVI'
                                break;
                            default:
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z"></path></svg>
                                lable = entry.value
                        }
                        return (
                            <div className="flex items-center gap-1" key={`item-${index}`}>
                                {icon}
                                <p className="m-0 font-semibold text-xs" style={{ color: '#555b64' }}>{lable}</p>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    const CustomizedDot = (props: any) => {
        const { cx, cy, stroke, payload, value, dataKey } = props;
        if (payload.alerts && payload.alerts[dataKey]) {
            return (
                <svg x={cx - 5} y={cy - 5} viewBox="0 0 24 24" height="12px" width="10px" xmlns="http://www.w3.org/2000/svg">
                    <circle fill='#dc2626' cx="12" cy="12" r="10" strokeWidth="4" stroke='#ffffff'></circle>
                </svg>
            );


        }
        if (payload.alert_level == "Critical" || payload.alert_level == "danger") {
            return (
                <svg x={cx - 10} y={cy - 20} stroke="alert" fill="url(#alert)" strokeWidth="0" viewBox="0 0 24 24" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <radialGradient id="alert" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                            <stop offset="30%" stopColor="#ef4444" />
                            <stop offset="100%" stopColor="#b91c1c" />
                        </radialGradient>
                    </defs>
                    <path d="M6 8a6 6 0 1 1 12 0v2.917c0 .703.228 1.387.65 1.95L20.7 15.6a1.5 1.5 0 0 1-1.2 2.4h-15a1.5 1.5 0 0 1-1.2-2.4l2.05-2.733a3.25 3.25 0 0 0 .65-1.95Zm6 13.5A3.502 3.502 0 0 1 8.645 19h6.71A3.502 3.502 0 0 1 12 21.5Z"></path></svg>
            );
        }
        if (payload.alert_level == "warning") {
            return (
                <svg x={cx - 10} y={cy - 20} stroke="alert" fill="url(#warning)" strokeWidth="0" viewBox="0 0 512 512" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <radialGradient id="warning" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                            <stop offset="30%" stopColor="#fbbf24" />
                            <stop offset="100%" stopColor="#d97706" />
                        </radialGradient>
                    </defs>
                    <path d="M228.9 79.9L51.8 403.1C40.6 423.3 55.5 448 78.9 448h354.3c23.3 0 38.2-24.7 27.1-44.9L283.1 79.9c-11.7-21.2-42.5-21.2-54.2 0zM273.6 214L270 336h-28l-3.6-122h35.2zM256 402.4c-10.7 0-19.1-8.1-19.1-18.4s8.4-18.4 19.1-18.4 19.1 8.1 19.1 18.4-8.4 18.4-19.1 18.4z"></path></svg>
            );
        }

        return (null);
    };

    const changeFromDate = (e: any) => {
        setFromDate(e.target.value);
    }

    const changeToDate = (e: any) => {
        setToDate(e.target.value);
    }

    const getBoundsFromGeoJSON = (geojson: any) => {
        let minLng = Infinity, minLat = Infinity;
        let maxLng = -Infinity, maxLat = -Infinity;

        // Loop through each coordinate in the GeoJSON boundary
        geojson.features.forEach((feature: any) => {
            feature.geometry.coordinates[0].forEach((coord: any) => {
                const [lng, lat] = coord;
                if (lng < minLng) minLng = lng;
                if (lng > maxLng) maxLng = lng;
                if (lat < minLat) minLat = lat;
                if (lat > maxLat) maxLat = lat;
            });
        });

        return { minLng, minLat, maxLng, maxLat };
    }

    const mapboxInit = () => {
        mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_KEY}`;
        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/rajsekhardas/cm2edf6w200tt01pi34159jq8', //mapbox://styles/mapbox/dark-v11
            center: [88.380731475021548, 22.440502697362547],//[88.32393090000001, 27.3516407],
            zoom: 20
        });

        mapRef.current.on('sourcedata', (e: any) => {
            if (sourceLoadingRef.current && 'other:' + sourceLoadingRef.current == e.sourceCacheId) {
                removeNDVILoader();
            }
        });

        mapRef.current.on('load', () => {
            mapRef.current.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
            setMapboxLoaded(true);
        });
    }

    const addNDVILoader = (lat: any, lng: any) => {
        removeNDVILoader();
        let s: any = mapRef.current.getSource('dot-point');
        if (!s) {
            mapRef.current.addSource('dot-point', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [lng, lat]
                            }
                        }
                    ]
                }
            });
        }


        mapRef.current.addLayer({
            id: 'layer-with-pulsing-dot',
            type: 'symbol',
            source: 'dot-point',
            layout: {
                'icon-image': 'pulsing-dot'
            }
        });
    }

    const removeNDVILoader = () => {

        let l: any = mapRef.current.getLayer('layer-with-pulsing-dot');
        if (l) {
            mapRef.current.removeLayer('layer-with-pulsing-dot');
        }
    }

    const drawField = (name: string, data: any) => {
        setDrawings([...drawings, name]);
        let source = mapRef.current.getSource(name)
        if (!source) {
            mapRef.current.addSource(name, {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        // These coordinates outline Maine.
                        coordinates: [
                            data.map((coord: any) => {
                                return [coord.longitude, coord.latitude];
                            })
                        ]
                    }
                }
            });
        }


        mapRef.current.addLayer({
            id: name,
            type: 'fill',
            source: name,
            layout: {},
            paint: {
                'fill-color': '#fbbf24',
                'fill-opacity': 0.5
            }
        });

        mapRef.current.addLayer({
            id: name + '_outline',
            type: 'line',
            source: name,
            layout: {},
            paint: {
                'line-color': '#f59e0b',
                'line-width': 1
            }
        });
    }

    const pulsingDot: any = {
        size: 100,
        width: 100,
        height: 100,
        data: new Uint8Array(100 * 100 * 4),

        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
        },

        render: function () {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;

            const radius = (this.size / 2) * 0.3;
            const outerRadius = (this.size / 2) * 0.7 * t + radius;
            const context = this.context;

            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                outerRadius,
                0,
                Math.PI * 2
            );
            context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
            context.fill();

            context.beginPath();
            context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
            context.fillStyle = 'rgba(255, 100, 100, 1)';
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            this.data = context.getImageData(0, 0, this.width, this.height).data;

            mapRef.current.triggerRepaint();

            return true;
        }
    };

    const drawNDVI = (ndvidata: any) => {
        addNDVILoader(field.location.latitude, field.location.longitude);
        fetch(ndvidata.geojson_url).then((response) => { //'http://localhost:3000/nvdi/nvdi.geojson'
            return response.json();
        }).then((data) => {
            // create ploygon
            /* let polyKey = 'poly-'+ndvidata.key;
            let polySource = mapRef.current.getSource(polyKey);
            if (!polySource) {
                mapRef.current.addSource(polyKey, {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        geometry: data.features[0].geometry
                    }
                });
            }

            mapRef.current.addLayer({
                id: polyKey,
                type: 'fill',
                source: polyKey,
                layout: {},
                paint: {
                    'fill-color': '#ff0000',
                    'fill-opacity': 0.5
                }
            }); */


            ndviMapData.map((ndvidata: any) => {
                let layer = mapRef.current.getLayer(ndvidata.key);
                if (layer) {
                    mapRef.current.removeLayer(ndvidata.key);
                }
            });

            let source = mapRef.current.getSource(ndvidata.key);

            if (!source) {
                let { minLng, minLat, maxLng, maxLat } = getBoundsFromGeoJSON(data);
                const bounds: any = [
                    [minLng, maxLat], // Northwest corner of the boundary
                    [maxLng, maxLat], // Northeast corner of the boundary
                    [maxLng, minLat], // Southeast corner of the boundary
                    [minLng, minLat], // Southwest corner of the boundary
                ];



                console.log('lat', minLat +  (maxLat - minLat) / 2);
                console.log('lng', minLng +  (maxLng - minLng) / 2);



                /* mapRef.current.loadImage(ndvidata?.ndvi_url, (error: any, image: any) => {

                    if (error) throw error;

                    //mapRef.current.addImage(ndvidata.key, image,);

                    
                }); */
                sourceLoadingRef.current = ndvidata.key;
                mapRef.current.addSource(ndvidata.key, {
                    type: 'image',
                    url: ndvidata?.ndvi_url,//'http://localhost:3000/nvdi/ndvi_1.png',
                    coordinates: bounds,
                });


            }

            mapRef.current.addLayer({
                id: ndvidata.key,
                type: 'raster',
                source: ndvidata.key,
                paint: {
                    'raster-fade-duration': 0,
                    'raster-opacity': 0.7,
                }
            });
        });
    }

    useEffect(() => {
        mapboxInit();
        getFields();
        //getFieldDeatils();
    }, []);

    useEffect(() => {
        getFieldDeatils();
    }, [params.id]);

    useEffect(() => {
        //if (!tiffImage) { return; }

    }, [tiffImage]);

    useEffect(() => {
        if (history) {
            console.log('History =>', history.crops[0]);
            setSelectedCrop(history.crops[0]);
            console.log('Selected Plot', Object.keys(history.crops[0] ? history.crops[0].data : { '': '' })[0]);
        }
    }, [history]);

    useEffect(() => {
        if (dataTypes) {
            setSelectedDataType([dataTypes[2]]);
        }
    }, [dataTypes]);

    useEffect(() => {
        if (params.id && fields && mapboxLoaded) {
            drawings.forEach((d) => {
                mapRef.current.removeLayer(d);
                mapRef.current.removeLayer(d + '_outline');
            });
        }
    }, [params.id, mapboxLoaded, fields]);

    useEffect(() => {
        if (field && mapboxLoaded && ndviMapData) {
            console.log('ndviMapData =>', ndviMapData);
            mapRef.current.flyTo({
                center: [field.location?.longitude || 88.32393090000001, field.location?.latitude || 27.3516407],
                zoom: 16
            });
            /* if (field.boundary) {
                drawField(field._id, field.boundary);
            } */

            if (ndviMapData && ndviMapData.length > 0) {
                setSelectedNdviMap(ndviMapData[ndviMapData.length - 1]);
                if (ndviSlider.current) {
                    ndviSlider.current.innerSlider.onWindowResized();
                    ndviSlider.current.slickGoTo(ndviMapData.length - 1);
                }
            } else {
                setSelectedNdviMap(null);
            }
        }
    }, [field, ndviMapData]);

    const getAssets = async () => {
        const response = await fetch(API_BASE_URL + `/fields/${params.id}/files?file_or_folder_path=${moment(selectedNdviMap.timestamp).format('YYYY-MM-DD')}/assets`);
        const data = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        const linkElements = doc.querySelectorAll('ul li a');
        const extractedLinks = Array.from(linkElements).map(link => link.getAttribute('href') || '');
        //console.log('Extracted Links', extractedLinks);
        setFieldAssets(extractedLinks);
    };

    useEffect(() => {
        if (selectedNdviMap && selectedCrop) {
            let dateKey: string = moment(selectedNdviMap.timestamp).format('YYYY-MM-DD');
            let ndviData = selectedCrop.dayWiseData.filter((d: any) => d.date == dateKey);
            if (ndviData.length > 0) {
                if (ndviData[0].ndvi > 0.6) {
                    setindexHealth('Healthy Vegetation');
                } else if (ndviData[0].ndvi > 0.3 && ndviData[0].ndvi <= 0.6) {
                    setindexHealth('Moderate Health');
                } else if (ndviData[0].ndvi <= 0.3) {
                    setindexHealth('Poor Health');
                }
            }
            drawNDVI(selectedNdviMap);
            getAssets();
        }
    }, [selectedNdviMap, selectedCrop]);

    useEffect(() => {
        if (selectedCrop) {
            console.log('selected crop ----------------------->', selectedCrop.dayWiseData);
            setGraphData(selectedCrop.dayWiseData);
        }
    }, [selectedCrop]);


    useEffect(() => {
        if (fromDate && toDate && field) {
            getFieldHistory();
            getFieldCropAlerts();
            getFieldModelAlerts();
            getFieldRuleAlerts();
        }
    }, [fromDate, toDate, field]);

    useEffect(() => {
        if (graphData && alerts && modelAlerts && ruleAlerts) {

            alerts.map((alert: any) => {
                let dt: any = moment(alert.timestamp).format('YYYY-MM-DD');
                if (graphData.filter((d: any) => d.date == dt).length > 0) {
                    graphData.map((d: any) => {
                        if (d.date == dt) {
                            if (!d.alerts) {
                                d['alerts'] = {};
                            }
                            if (!d.alerts[alert.sensor.name]) {
                                d.alerts[alert.sensor.name] = { crop: [] };
                            }
                            d.alerts[alert.sensor.name].crop.push(alert);
                        }
                        return d;
                    });
                }
            });

            modelAlerts.map((alert: any) => {
                let dt: any = moment(alert.timestamp).format('YYYY-MM-DD');
                if (graphData.filter((d: any) => d.date == dt).length > 0) {
                    graphData.map((d: any) => {
                        if (d.date == dt) {
                            if (!d.alerts) {
                                d['alerts'] = {};
                            }
                            if (!d.alerts[alert.sensor.name]) {
                                d.alerts[alert.sensor.name] = { model: [] };
                            } else if (!d.alerts[alert.sensor.name].model) {
                                d.alerts[alert.sensor.name].model = [];
                            }
                            d.alerts[alert.sensor.name].model.push(alert);
                        }
                        return d;
                    });
                }
            });

            ruleAlerts.map((alert: any) => {
                let dt: any = moment(alert.timestamp).format('YYYY-MM-DD');
                if (graphData.filter((d: any) => d.date == dt).length > 0) {
                    graphData.map((d: any) => {
                        if (d.date == dt) {
                            if (!d.alerts) {
                                d['alerts'] = {};
                            }
                            if (!d.alerts[alert.sensor.name]) {
                                d.alerts[alert.sensor.name] = { rule: [] };
                            } else if (!d.alerts[alert.sensor.name].rule) {
                                d.alerts[alert.sensor.name].rule = [];
                            }
                            d.alerts[alert.sensor.name].rule.push({ ...alert, from: 'rule' });
                        }
                        return d;
                    });
                }
            });

            console.log('Graph Data', graphData);
        }
    }, [graphData, alerts, modelAlerts, ruleAlerts]);

    const getColorCode = (key: string) => {
        switch (key) {
            case 'Soil EC':
                return GRAPH_COLORS.SOIL_EC.PRIMARY;
            case 'Soil Moisture':
                return GRAPH_COLORS.SOIL_MOISTURE.PRIMARY;
            case 'Soil pH':
                return GRAPH_COLORS.SOIL_PH.PRIMARY;
            case 'Soil Temperature':
                return GRAPH_COLORS.SOIL_TEMP.PRIMARY;
            case 'Soil Salinity':
                return GRAPH_COLORS.SOIL_SALINITY.PRIMARY;
            case 'Solar Radiation':
                return GRAPH_COLORS.SOLAR_RADIATION.PRIMARY;
            case 'Wind Direction':
                return GRAPH_COLORS.WIND_DIRECTION.PRIMARY;
            case 'Wind speed':
                return GRAPH_COLORS.WIND_SPEED.PRIMARY;
            case 'Air Humidity':
                return GRAPH_COLORS.AIR_HUMIDITY.PRIMARY;
            default:
                return '#222222';
        }
    }


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 12,
        slidesToScroll: 12,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 12,
                    slidesToScroll: 12,

                }
            },
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 11,
                    slidesToScroll: 11,

                }
            },
            {
                breakpoint: 1795,
                settings: {
                    slidesToShow: 10,
                    slidesToScroll: 10,

                }
            },
            {
                breakpoint: 1690,
                settings: {
                    slidesToShow: 9,
                    slidesToScroll: 9,

                }
            },
            {
                breakpoint: 1585,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,

                }
            },
            {
                breakpoint: 1480,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7,

                }
            },
            {
                breakpoint: 1375,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,

                }
            },
            {
                breakpoint: 1270,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,

                }
            },
            {
                breakpoint: 1165,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,

                }
            },
            {
                breakpoint: 1060,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                }
            },
            {
                breakpoint: 955,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,

                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='h-screen'>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content pb-0">
                    <div className="container-fluid">
                        <TitleBar title={`Vegetation Index Analytics — ${field?.name}`} breadcrumbs={{ 'Vegetation Index Analytics': '#', }} />
                        <div className="white-box-card mb-1 h-100 p-0 overflow-hidden" data-aos="fade-up">

                            <div className="flex w-full h-full st-height">
                                <div className='flex-grow flex flex-col st-height'>
                                    <div className='flex-grow relative' id="map" ref={mapContainerRef}>
                                        {(ndviMapData && ndviMapData.length > 0) &&
                                            <div className=' w-full absolute bottom-0 left-0 z-10 p-0 px-2 bg-gray-700 '>
                                                <div className=' p-2 px-4'>
                                                    <Slider onLazyLoad={(slider:any)=>{
                                                        slider.innerSlider.onWindowResized();
                                                    }} {...{...settings, slidesToShow:ndviMapData.length, slidesToScroll:ndviMapData.length}} ref={ndviSlider}>
                                                        {ndviMapData?.map((ndvi: any, index: number) => {
                                                            return (
                                                                <div key={`${ndvi.key}`}>
                                                                    <div className='flex justify-center w-full'>
                                                                        <div onClick={() => { setSelectedNdviMap(ndvi) }}  style={{ lineHeight: '1.7', fontWeight: '500' }} className={`btn btn-sm ${selectedNdviMap?.key == ndvi.key ? 'btn-primary' : 'btn-dark'}`}>
                                                                            {moment(ndvi.timestamp).format('DD-MMM-YYYY')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        {/* {ndviMapData?.map((ndvi: any, index: number) => {
                                                        return (
                                                            <div onClick={() => { setSelectedNdviMap(ndvi) }} key={`${ndvi.key}`} style={{ lineHeight: '1.7', fontWeight: '500' }} className={`btn btn-sm ${selectedNdviMap?.key == ndvi.key ? 'btn-primary' : 'btn-dark'}`}>
                                                                {moment(ndvi.timestamp).format('DD-MMM-YYYY')}
                                                            </div>
                                                        )
                                                    })}; */}
                                                    </Slider>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='h-[320px] flex'>
                                        <div className='w-[200px] bg-slate-300 p-3 flex flex-col gap-3 overflow-auto'>
                                            {/* <div>
                                        <label className='text-sm'>Plot</label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={false}
                                            value={selectedCrop}
                                            isSearchable={false}
                                            isMulti={false}
                                            onChange={(e: any) => {
                                                setSelectedCrop(e);
                                            }}
                                            name="color"
                                            options={history?.plots || []}
                                        />
                                    </div> */}
                                            <div>
                                                <label className='text-sm'>Weather Data</label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isClearable={false}
                                                    value={selectedDataType}
                                                    isSearchable={false}
                                                    isMulti={true}
                                                    onChange={(e: any) => {
                                                        setSelectedDataType(e);
                                                    }}
                                                    name="color"
                                                    options={dataTypes}
                                                />
                                            </div>

                                            <div>
                                                <label className='text-sm'>Start Date</label>
                                                <input type="date" max={moment().format('YYYY-MM-DD')} value={fromDate} className="border border-gray-300 rounded-md p-2 m-0 w-full" onChange={changeFromDate} />
                                            </div>

                                            <div>
                                                <label className='text-sm'>End Date</label>
                                                <input type="date" min={fromDate} max={moment().format('YYYY-MM-DD')} value={toDate} className="border border-gray-300 rounded-md p-2 m-0 w-full" onChange={changeToDate} />
                                            </div>

                                            <div>
                                                <label className='text-sm'>Index Data</label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isClearable={false}
                                                    value={selectedIndexDataType}
                                                    isSearchable={false}
                                                    onChange={(e: any) => {
                                                        setSelectedIndexDataType(e);
                                                    }}
                                                    name="color"
                                                    options={indexDataTypes}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex-grow bg-slate-200'>

                                            {graphData == null && <Loader />}
                                            {selectedCrop?.data &&
                                                <div className='relative w-100 h-100'>
                                                    <ResponsiveContainer width="100%" height="100%">
                                                        <ComposedChart width={500} height={300} data={graphData} margin={{ top: 5, right: 25, left: 0, bottom: 30, }} >
                                                            <defs>
                                                                <linearGradient id="ndvi" x1="0%" x2="0%" y1="0%" y2="100%">
                                                                    <stop offset="0%" stopColor={GRAPH_COLORS.NDVI.LOW} />
                                                                    <stop offset="100%" stopColor={GRAPH_COLORS.NDVI.HIGH} />
                                                                </linearGradient>
                                                            </defs>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
                                                            <YAxis domain={['dataMin-1', 'dataMax+1']} tick={<CustomizedYAxisTick />} orientation='left' />
                                                            <Tooltip
                                                                contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                                                labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                                                itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                                                                labelFormatter={(value: any) => moment(value).format('DD-MMM')}
                                                            />

                                                            {dataTypes.map((d: any) => {
                                                                return isPresentInOptions(selectedDataType, d.value) && <>
                                                                    <Line type="monotone" dataKey={d.value} stroke={getColorCode(d.value)} strokeWidth={graphLineThickness} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                                    <Line type="monotone" dataKey={d.value + ' forecast'} stroke={getColorCode(d.value)} strokeWidth={graphLineThickness} dot={<CustomizedDot />} activeDot={{ r: 4 }} strokeDasharray="5 5" />
                                                                </>
                                                            })}

                                                            <Bar dataKey="ndvi" fill="url(#ndvi)" radius={5} activeBar={<Rectangle radius={5} />} />
                                                            <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                                                        </ComposedChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <RightPanel page={'weather'} cropDetails={true} deviceDetails={true} fields={fields} field={field} updateFiledDetails={getFieldDeatils} alerts={alerts} assets={fieldAssets} indexHealth={indexHealth} url={URLS.FARMS.WEATHER} />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Weather;