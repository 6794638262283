import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { useDeviceInstallationStationApi } from "../../_common/hooks/api/deviceInstallationStationHook";
import TableLoader from "../../components/tableLoader";
import Icon from "../../components/icon";
import { ConfirmAlert } from "../../_common/confirmAlert";
import { useUser } from "../../_common/hooks/authHook";
import { replaceUrlParams } from "../../_common/functions";
import { URLS } from "../../_config";
import { Link } from "react-router-dom";
import TitleBar from "../../components/TopBar/TitleBar";
import CropProductionMethods from "./CropProductionMethods";
import IotDeviceMethods from "./IotDeviceMethods";
import Search from "../../components/search/Search";

const IotInstallationStationList = () => {
    const [DISList, setDISLists] = useState<any[] | null>(null);
    const user: any = useUser();

    const deviceInstallationStationAPI = useDeviceInstallationStationApi();

    const [cropProduction, setCropProduction] = useState<any>(null);
    const handleClose = () => setCropProduction(null);

    const [iotDevice, setIotDevice] = useState<any>(null);
    const handleCloseIotDevice = () => setIotDevice(null);



    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;

    const getDISList = () => {
        return new Promise((resolve: any, reject: any) => {
            deviceInstallationStationAPI.deviceInstallationStationList({
                PageIndex: page,
                PageSize: limit,
                SearchText: search
            }, (message: any, resp: any) => {
                setDISLists(resp.data.deviceInstallationStation)
                setTotal(resp.totalRecords)
            }, (message: any) => {
                setDISLists([])
            })
        })
    }

    const deleteDataItem = (DIS: any) => {
        return new Promise((resolve, reject) => {
            deviceInstallationStationAPI.deviceInstallationStationDelete({
                "installationStationID": DIS.installationStationID,
                "deletedBy": user?.userID,
            }, resolve, reject)
        })
    }

    const deleteDataItemhandler = (DIS: any) => {
        ConfirmAlert.delete(`Device Installation Station ${DIS.stationName}`, deleteDataItem, DIS).then(() => {
            getDISList();
        })
    }


    useEffect(() => {
        getDISList();
    }, [page, search])



    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`IOT Installation Stations`}
                            breadcrumbs={{
                                'IOT Installation Stations': '#',
                            }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">IOT Installation Station List</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            <Search setSearch={(str: string) => {
                                                setSearch(str)
                                                setDISLists(null)
                                                setPage(1)
                                            }}></Search>

                                            <Link to={URLS.IOT_INSTALLATION_ADD} className="btn btn-primary d-flex align-items-center gap-1 ">
                                                <Icon name="plus" size={18} />Add New</Link>

                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"> </th>
                                                    <th scope="col">Station Name</th>
                                                    <th scope="col">Station ID</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Assignments</th>

                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {DISList?.map((DIS: any, i: any) => (
                                                    <tr key={`dis-${DIS.installationStationID}`}>
                                                        <td>
                                                            <div className="table-pic">
                                                                <img src={DIS.stationImage} alt="" />
                                                            </div>
                                                        </td>
                                                        <td>{DIS.stationName}</td>
                                                        <td>{DIS.stationID}</td>
                                                        <td>{[DIS.districtName, DIS.block, DIS.gpu].join(', ')}</td>
                                                        <td>
                                                            <div className="d-flex gap-2 align-items-center">
                                                                <span className="btn btn-primary right-bar-toggle" onClick={() => { setIotDevice(DIS) }}> IOT Devices</span>
                                                                <span className="btn btn-success right-bar-toggle" onClick={() => { setCropProduction(DIS) }}> Crop Production</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="action-btns">
                                                                <Link to={replaceUrlParams(URLS.IOT_INSTALLATION_EDIT, { deviceID: DIS.installationStationID })}>
                                                                    <Icon name="edit" size={18} />
                                                                </Link>
                                                                <Icon name="delete" size={18} onClick={() => { deleteDataItemhandler(DIS) }} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                        <TableLoader data={DISList} />
                                        {
                                            DISList && <Pagination total={total} page={page} setPage={(n: number) => {
                                                setPage(n)
                                                setDISLists(null)
                                            }} pageLimit={limit} />
                                        }
                                    </div> 
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                <Footer></Footer>

            </div>

            <IotDeviceMethods show={iotDevice != null} handleClose={handleCloseIotDevice} crop={iotDevice} />
            <CropProductionMethods show={cropProduction != null} handleClose={handleClose} crop={cropProduction} />
        </React.Fragment>
    )
}

export default IotInstallationStationList;