import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";
import {
  BlankReq,
  LoginReq,
  SignUpReq,
  UpdateUserPlatformReq,
} from "../../interfaces/ApiReqRes";
import { LoginFormValues } from "../../form-props";

export function useUserApi() {
  const callApi = useApiCall();

  const login = (data: LoginFormValues, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  };

  const logout = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, data, onSuccess, onError);
  };

  const forgetpass = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGET_PASSWORD, data, onSuccess, onError);
  };

  const resetpass = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  };

  const restprofilePassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.PASSWORD_RESET, data, onSuccess, onError);
  };

  

  return {
    login,
    logout,
    forgetpass,
    resetpass,
    restprofilePassword
  };
}
