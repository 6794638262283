import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { URLS } from '../../../../_config';
import { updateUrlParams } from '../../_config/functions';
import Field from './field';
import Icon from '../icon';
import { replaceUrlParams } from '../../../../_common/functions';
import moment from 'moment';
import { MAPAPI_BASE_URL, MAPAPI_DOMAIN } from '../../../../_config/site_urls';
import { useFarmsApi } from '../../../../_common/hooks/api/farmApiHook';
import ForecastTemperatureModal from '../../modals/forecastTemperature.modal';
import UpdateCrops from '../../modals/updateCrops.modal';
import UpdateDevices from '../../modals/updateDevices.modal';
import GalleryModal from '../../modals/gallery.modal';
import { get } from 'lodash';

interface props {
    page: string
    fields: any
    field: any
    backUrl?: string
    updateFiledDetails?: () => void
    alerts?: any[] | null
    assets?: any[]
    indexHealth?: any
    cropDetails?: boolean
    deviceDetails?: boolean
    url:any
}

const RightPanel = ({ page, fields, field, backUrl, updateFiledDetails, assets, indexHealth, cropDetails = false, deviceDetails = false, url }: props) => {
    const params: any = useParams();
    const farmsApi = useFarmsApi();
    const [viewAllFields, setViewAllFields] = useState<boolean>(false);
    const [listFilter, setListFilter] = useState<string>('');
    const [accordion, setAccordion] = useState<number>(0);
    const [showAlert, setShowAlert] = useState<any>(null);
    const [farm, setFarm] = useState<any>(null);

    const [updateCrops, setUpdateCrops] = useState<boolean>(false);
    const [updateDevices, setUpdateDevices] = useState<boolean>(false);
    const [gallery, setGallery] = useState<boolean>(false);
    const [galleryStartFrom, setGalleryStartFrom] = useState<number>(0);
    const [reposts, setReposts] = useState<any>(null);

    const [alerts, setAlerts] = useState<any>(null);
    const [modelAlerts, setModelAlerts] = useState<any>(null);
    const [ruleAlerts, setRuleAlerts] = useState<any>(null);

    const [allAlerts, setAllAlerts] = useState<any>(null);




    const getFarmDetails = () => {
        farmsApi.farmDetails({ urlParams: { FarmID: field.farm_id } }, (message: any, resp: any) => {
            let farm: any = resp.data?.farm[0];
            setFarm(farm)
        }, (message: any) => {
            setFarm(null)
        })
    }

    const getFieldCropAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/alerts?${new URLSearchParams({
                field_or_farm_id: params.id
            })}`);
            const data: any = await response.json();
            setAlerts(data.map((d: any) => {
                d.type = 'crop';
                return d;
            }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getFieldModelAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/model-alerts?${new URLSearchParams({
                field_or_farm_id: params.id
            })}`);
            const data: any = await response.json();
            setModelAlerts(data.map((d: any) => {
                d.type = 'model';
                return d;
            }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getFieldRuleAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/rule-alerts?${new URLSearchParams({
                field_or_farm_id: params.id
            })}`);
            const data: any = await response.json();
            setRuleAlerts(data.map((d: any) => {
                d.type = 'rule';
                return d;
            }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const getFileTypeFromUrl = (url: string): string => {
        const extension = url.split('.').pop();
        if (!extension) return 'unknown';
        switch (extension.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return 'image';
            case 'mp4':
            case 'avi':
            case 'mov':
                return 'video';
            case 'pdf':
                return 'document';
            default:
                return 'unknown';
        }
    };

    useEffect(() => {
        if (assets) {
            let r: any = [];
            assets.map((url: string, i: number) => {
                if (getFileTypeFromUrl(MAPAPI_DOMAIN + url) == 'document') {
                    r.push(MAPAPI_DOMAIN + url);
                }
            });
            if (r.length > 0) {
                setReposts(r);
            } else {
                setReposts(null);
            }
        }
    }, [assets]);

    useEffect(() => {
        if (params.id && field) {
            getFarmDetails();
            getFieldCropAlerts();
            getFieldModelAlerts();
            getFieldRuleAlerts();
        }
    }, [params.id, field, fields]);

    useEffect(() => {
        if (alerts && modelAlerts && ruleAlerts) {
            let allAlerts: any = [];
            allAlerts = allAlerts.concat(alerts);
            allAlerts = allAlerts.concat(modelAlerts);
            allAlerts = allAlerts.concat(ruleAlerts);
            setAllAlerts(allAlerts.sort((a: any, b: any) => {
                return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
            }));
        }
    }, [alerts, modelAlerts, ruleAlerts]);





    return (
        <div className='w-[300px] bg-slate-300 border-l border-slate-300 flex flex-col gap-1 st-height'>
            <div className='flex justify-between bg-slate-100 p-2'>
                <Link to={backUrl ? backUrl : URLS.FARMS.LIST} className='p-1 pr-2 text-sm font-semibold text-slate-700 flex gap-1 items-center rounded hover:bg-slate-800 hover:text-slate-200'><Icon name='back' size={14} />Back</Link>
                <button className='p-1 text-sm font-semibold text-slate-700  hover:bg-slate-800 hover:text-slate-200 rounded px-2' onClick={(e: any) => { setViewAllFields(!viewAllFields) }} >{viewAllFields ? 'View Details' : 'All Field'}</button>
            </div>
            {viewAllFields ?
                <div className='flex flex-col max-h-[100%] flex-grow w-full overflow-hidden'>
                    <div className='p-2'>
                        <input type="text" placeholder="Search Field by name" className="border border-gray-300 rounded-md p-2 m-0 w-full" onKeyUp={(e: any) => { setListFilter(e.target.value) }} />
                    </div>
                    <div className=' flex-grow overflow-auto'>
                        {fields && fields.filter((f: any) => {
                            if (listFilter == '') {
                                return true;
                            } else {
                                return f.name.toLowerCase().includes(listFilter.toLowerCase());
                            }
                        }).map((f: any, i: number) => (

                            <Link to={updateUrlParams(url, { id: f._id })} className='bg-slate-100 p-2 flex gap-2 items-center hover:bg-slate-200' key={f._id}>
                                <Field field={f} />
                            </Link>
                        ))}
                    </div>
                </div>
                :
                <div className='max-h-[100%] overflow-auto flex-grow flex flex-col'>
                    {field && <Field field={field} />}
                    <div className='flex items-center p-2 gap-2'>
                        {page != 'weather' && <Link className='btn btn-primary btn-sm' style={{ lineHeight: '1.7', fontWeight: '500', borderRadius: '3px' }} to={replaceUrlParams(URLS.FARMS.WEATHER, { id: params.id })}>Vegetation Index Analytics</Link>}
                        {page != 'forecast' && <Link className='btn btn-primary btn-sm' style={{ lineHeight: '1.7', fontWeight: '500', borderRadius: '3px' }} to={replaceUrlParams(URLS.FARMS.FORECAST, { id: params.id })}>Forecast</Link>}
                        {page != 'analytics' && <Link className='btn btn-primary btn-sm' style={{ lineHeight: '1.7', fontWeight: '500', borderRadius: '3px' }} to={replaceUrlParams(URLS.FARMS.ANALYTICS, { id: params.id })}>Analytics</Link>}
                    </div>
                    <div className='flex-grow overflow-auto bg-slate-100 p-2'>
                        {/* <div className='p-1 font-semibold bg-slate-300 rounded mb-1'>
                            <h2 className='text-sm m-0'>Crop Details</h2>
                        </div>
                        <div className=" bg-slate-300 p-1 mb-1">
                            <h2>
                                <button
                                    className="flex items-center justify-between w-full text-sm text-left font-semibold py-2"
                                    onClick={(e) => { e.preventDefault(); setAccordion(accordion == 1 ? 0 : 1); }}
                                    aria-expanded={accordion == 1 ? 'true' : 'false'}
                                    aria-controls={`accordion-text-01`}
                                >
                                    <span>Season: {field?.season?.name}</span>
                                    <Icon name="arrow-right" size="16" className={`transform origin-center transition duration-200 ease-out ${accordion == 1 && '!rotate-90'}`} />
                                </button>
                            </h2>
                            <div
                                id={`accordion-text-01`}
                                role="region"
                                aria-labelledby={`accordion-title-01`}
                                className={`grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${accordion == 1 ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}
                            >
                                <div className="overflow-hidden">
                                    <div className='grid grid-cols-2 gap-3 text-slate-900'>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Crop</label>
                                            <p className=''>{field?.crop?.name}</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Showing Date</label>
                                            <p className=''>{field ? moment(field.sowing_date).format('DD-MMM-YYYY') : ''}</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>vareity</label>
                                            <p className=''>-</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Harvesting Date</label>
                                            <p className=''>{field ? moment(field.harvesting_date).format('DD-MMM-YYYY') : ''}</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Maturity</label>
                                            <p className=''>-</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Irrigation Type</label>
                                            <p className=''>-</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Tillage Type</label>
                                            <p className=''>-</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Target Yield t/ha</label>
                                            <p className=''>-</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Actual Yield t/ha</label>
                                            <p className=''>-</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Growth Stages</label>
                                            <p className=''>-</p>
                                        </div>
                                        <div>
                                            <label className='block text-xs text-slate-600'>Notes</label>
                                            <p className=''>-</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {indexHealth &&
                            <div className='mb-3'>
                                <p className='m-0 text-sm border-b border-gray-400 mb-2 py-1 flex justify-between items-center'>
                                    <span>Index Health</span>
                                </p>
                                <div className='flex gap-2 flex-wrap'>
                                    <span className={`badge badge-neutral ${indexHealth == 'Healthy Vegetation' ? 'bg-green-600 border-green-600' : (indexHealth == 'Moderate Health') ? 'bg-yellow-600 border-yellow-600' : 'bg-red-600 border-red-600'}`}>{indexHealth}</span>
                                </div>
                            </div>
                        }
                        {cropDetails &&
                            <div className='mb-3'>
                                <p className='m-0 text-sm border-b border-gray-400 mb-2 py-1 flex justify-between items-center'>
                                    <span>Crops</span>
                                    <span className='btn btn-primary btn-xs' style={{ lineHeight: '0.8', borderRadius: '3px' }} onClick={() => { setUpdateCrops(true) }}>Edit</span>
                                </p>
                                <div className='flex gap-2 flex-wrap'>
                                    {field?.crops?.map((crop: any, i: number) => (
                                        <span key={`crop-${crop._id}`} className='badge badge-neutral'>{crop.name}</span>
                                    ))}

                                </div>
                            </div>
                        }

                        {deviceDetails &&
                            <div className='mb-3'>
                                <p className='m-0 text-sm border-b border-gray-400 mb-2 py-1 flex justify-between items-center'>
                                    <span>Devices</span>
                                    <span className='btn btn-primary btn-xs' style={{ lineHeight: '0.8', borderRadius: '3px' }} onClick={() => { setUpdateDevices(true) }}>Edit</span>
                                </p>
                                <div className='flex gap-2 flex-wrap'>
                                    {field?.devices?.map((device: any, i: number) => (
                                        <span key={`device-${device._id}`} className='badge badge-neutral'>{device.name}</span>
                                    ))}

                                </div>
                            </div>
                        }

                        {/* <div className='p-1 font-semibold bg-slate-300 rounded mb-1'>
                            <Link to={replaceUrlParams(URLS.FARMS.FORECAST, { id: params.id })}>Forecast</Link>
                        </div> */}
                        {/* <div className='p-1 font-semibold bg-slate-300 rounded mb-1'>
                            <Link to={replaceUrlParams(URLS.FARMS.ANALYTICS, { id: params.id })}>Analytics</Link>
                        </div> */}


                        {assets && assets.length > 0 &&
                            <div className='mb-3'>
                                <p className='m-0 text-sm border-b border-gray-400 mb-2 py-1 '>Field Assets</p>
                                <div className='flex gap-2 items-start '>
                                    {assets.map((url: any, i: number) => (
                                        <div className=' mb-2' key={`alert-${i}}`}>
                                            {getFileTypeFromUrl(MAPAPI_DOMAIN + url) == 'image' && <img src={MAPAPI_DOMAIN + url} alt='asset' className='w-12 h-12 object-cover rounded-md cursor-pointer' onClick={() => {

                                                setGalleryStartFrom(i);
                                                setGallery(true);
                                            }} />}
                                            {getFileTypeFromUrl(MAPAPI_DOMAIN + url) == 'video' && <video src={MAPAPI_DOMAIN + url} className='w-12 h-12 object-cover rounded-md cursor-pointer' onClick={() => {
                                                setGalleryStartFrom(i);
                                                setGallery(true);
                                            }} />}

                                        </div>
                                    ))}
                                </div>
                                {gallery && <GalleryModal open={gallery} close={() => { setGallery(false) }} urls={assets} start={galleryStartFrom} />}
                            </div>
                        }

                        {reposts && reposts.length > 0 &&
                            <div className='mb-3'>
                                <p className='m-0 text-sm border-b border-gray-400 mb-2 py-1 '>Field Reports</p>
                                <div className='flex gap-2 items-start '>
                                    {reposts.map((url: any, i: number) => (
                                        <div className=' mb-2' key={`alert-${i}}`}>
                                            <a href={url} target='_blank' title={url.split('/').pop()} className='text-blue-500 underline'>
                                                <Icon name='pdf' size={32} className='text-red-800' />
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        }

                        {allAlerts && allAlerts.length > 0 &&
                            <div className='mb-3 flex-grow'>
                                <p className='m-0 text-sm border-b border-gray-400 mb-2 py-1 '>Alerts</p>
                                <div className=' overflow-auto'>
                                    {allAlerts.map((alert: any, i: number) => (
                                        <div className={`flex gap-2 items-start p-2  mb-2 rounded border-1 
                                        ${alert.type == 'crop' ? (alert.is_ucl ? 'bg-orange-50' : 'bg-blue-50') : (alert.type == 'model') ? 'bg-violet-100' : 'bg-violet-100'} 
                                        ${alert.type == 'crop' ? (alert.is_ucl ? 'border-orange-200' : 'border-blue-200') : (alert.type == 'model') ? 'border-violet-200' : 'border-violet-200'}`} key={`alert-${i}}`}>
                                            <div className='w-[32px] flex-shrink-0 flex items-center justify-center'>
                                                <div className={`w-[32px] h-[32px] rounded flex justify-center items-center
                                                    ${alert.type == 'crop' ? (alert.is_ucl ? 'bg-orange-200' : 'bg-blue-200') : (alert.type == 'model') ? 'bg-violet-300' : (alert.alert_type == 'warning') ? 'bg-blue-200' : 'bg-red-200'} 
                                                `}>
                                                    {alert.type == 'crop' && <Icon name='crop' size={24} className={alert.is_ucl ? 'text-orange-500' : 'text-blue-500'} />}
                                                    {alert.type == 'model' && <Icon name='ai' size={24} className='text-violet-600' />}
                                                    {alert.type == 'rule' && <Icon name='alertSq' size={24} className={alert.alert_type == 'warning' ? 'text-blue-500' : 'text-red-500'} />}
                                                </div>
                                            </div>

                                            <div className='flex-grow'>
                                                <p className='m-0 text-xs text-grey-800'>{alert.message}<br />
                                                    {alert.type == 'crop' && <>
                                                        {alert.is_read ? <span className='mt-2 block text-green-500'>Suggestion published</span> : null}
                                                    </>}
                                                </p>
                                                <div className='flex justify-between items-center'>
                                                    <p className={`m-0 pt-1 font-size-11 italic font-semibold
                                                        ${alert.type == 'crop' ? (alert.is_ucl ? 'text-orange-500' : 'text-blue-500') : (alert.type == 'model') ? 'text-violet-600' : 'text-violet-600'} 
                                                        `}>{moment(alert.timestamp).format('DD MMM, YYYY, hh:mm A')}</p>
                                                    <div>
                                                        {alert.type == 'crop' && <>
                                                            {alert.is_read ? null : <Icon name='bulb' size={24} className='text-yellow-400' onClick={() => { setShowAlert(alert) }} />}
                                                        </>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }


                        {/* <div className='p-1 font-semibold bg-slate-300 rounded mb-1'>
                                    <h2>Field Details</h2>
                                </div>

                                <div className=" bg-slate-300 p-1 mb-1">
                                    <h2>
                                        <button
                                            className="flex items-center justify-between w-full text-left font-semibold py-2"
                                            onClick={(e) => { e.preventDefault(); setAccordion(accordion == 2 ? 0 : 2); }}
                                            aria-expanded={accordion == 2 ? 'true' : 'false'}
                                            aria-controls={`accordion-text-01`}
                                        >
                                            <span>Index Risk</span>
                                            <Icon name="arrow-right" size="16" className={`transform origin-center transition duration-200 ease-out ${accordion == 2 && '!rotate-90'}`} />
                                        </button>
                                    </h2>
                                    <div
                                        id={`accordion-text-01`}
                                        role="region"
                                        aria-labelledby={`accordion-title-01`}
                                        className={`grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${accordion == 2 ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}
                                    >
                                        <div className="overflow-hidden">
                                            <p className="pb-3">
                                                -
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className=" bg-slate-300 p-1 mb-1">
                                    <h2>
                                        <button
                                            className="flex items-center justify-between w-full text-left font-semibold py-2"
                                            onClick={(e) => { e.preventDefault(); setAccordion(accordion == 3 ? 0 : 3); }}
                                            aria-expanded={accordion == 3 ? 'true' : 'false'}
                                            aria-controls={`accordion-text-01`}
                                        >
                                            <span>Disease Risk</span>
                                            <Icon name="arrow-right" size="16" className={`transform origin-center transition duration-200 ease-out ${accordion == 3 && '!rotate-90'}`} />
                                        </button>
                                    </h2>
                                    <div
                                        id={`accordion-text-01`}
                                        role="region"
                                        aria-labelledby={`accordion-title-01`}
                                        className={`grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${accordion == 3 ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}
                                    >
                                        <div className="overflow-hidden">
                                            <p className="pb-3">
                                                -
                                            </p>
                                        </div>
                                    </div>
                                </div> */}

                    </div>
                </div>
            }
            <ForecastTemperatureModal open={showAlert != null} close={() => { setShowAlert(null) }} alert={showAlert} farm={farm} />
            <UpdateCrops open={updateCrops} close={() => { setUpdateCrops(false) }} field={field} update={updateFiledDetails ? updateFiledDetails : () => { }} />
            <UpdateDevices open={updateDevices} close={() => { setUpdateDevices(false) }} field={field} update={updateFiledDetails ? updateFiledDetails : () => { }} />
        </div>
    );
};

export default RightPanel;