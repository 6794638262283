import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../_config";
import AddEditSensorModal from "../../modals/addEditSensor.modal";
//import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import Icon from "../../components/icon";
import SensorConfigModal from "../../modals/sensorConfig.modal";
import { useAppLoader } from "../../../../_common/hooks/common/appLoaderHook";
import LeftPanel from "../../../../components/leftPanel/LeftPanel";
import Header from "../../../../components/header/Header";
import TitleBar from "../../../../components/TopBar/TitleBar";

const Sensors = () => {

    
    const {showLoader, hideLoader} = useAppLoader();
    const [sensors, setSensors] = useState<any[] | null>(null);
    const [selectedSensor, setSelectedSensor] = useState<any>(null);

    const [viewModal, setViewModal] = useState(false);


    const fetchData = async () => {
        try {
            showLoader();
            const response = await fetch(API_BASE_URL + "/sensors");
            const data = await response.json();
            // Process the data
            setSensors(data);
            console.log('Response ====> ',data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='h-screen'>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content pb-0">
                    <div className="container-fluid">
                        <TitleBar title={`Sensor Configuration`} breadcrumbs={{ 'Sensor Configuration': '#', }} />
                        <div className="white-box-card mb-1 h-100 p-0 overflow-hidden" data-aos="fade-up">
                            <div className="w-full h-full st-height">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Sensor Name
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                                                Unit
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Configuration
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {/* Replace this with your data mapping logic */}
                                        {sensors?.map((sensor) => (
                                            <tr key={sensor.id}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">{sensor.name}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm">
                                                    {sensor.metric_unit}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center flex justify-center gap-2">
                                                    <Icon name="settings" size={20} onClick={() => { setSelectedSensor(sensor); setViewModal(true) }} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <SensorConfigModal open={viewModal} sensor={selectedSensor} close={() => { setViewModal(false); setSelectedSensor(null) }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sensors;