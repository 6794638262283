import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import Header from "../../components/header/Header";
import { useFarmerApi } from "../../_common/hooks/api/farmerApiHook";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Icon from "../../components/icon";
import TitleBar from "../../components/TopBar/TitleBar";
import { URLS } from "../../_config";
import DetailsElement from "../../components/detailsElement";
import { showDate } from "../../_common/functions";


const FarmerDetails = () => {
    const urlParams: any = useParams();
    const farmerID: any = urlParams.farmerID;
    const farmerApi = useFarmerApi();
    const [farmerDetials, setFarmerdetails] = useState<any>(null);
    const [farmerimage, setFarmerimages] = useState<string>("/images/no_image.jpg");

    const getFarmerData = () => {
        farmerApi.getFarmerDetails({ urlParams: { FarmerID: farmerID } }, (message: string, resp: any) => {
            let farmerdetails = resp.data?.farmer?.[0];
            setFarmerdetails(farmerdetails);
            if (farmerdetails.farmerImage) {
                setFarmerimages(farmerdetails.farmerImage);
            }
        }, (message: string) => { });
    };

    useEffect(() => {
        if (farmerID) {
            getFarmerData()
        }
    }, [farmerID])

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Farmer Details`}
                            breadcrumbs={{
                                'Farmer': URLS.FARMER_LIST,
                                'Details': '#',
                            }} />


                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-3">
                                <div className="white-box-card profile-card  ">
                                    <div className="profile-avatar">
                                        <img src={farmerimage} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-9">

                                <div className="white-box-card mb-4 ">
                                    <div className="row">
                                        <div className="col-sm-12 profile-card">

                                            <h3 className="card-heading mb-4">Personal Details</h3>

                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <DetailsElement title={`Farmer Name`} value={farmerDetials?.farmerName} />
                                                </div>

                                                <div className="col-sm-4">
                                                    <DetailsElement title={`DOB`} value={showDate(farmerDetials?.dob, 'DD-MM-YYYY')} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <DetailsElement title={`FATHER / HUSBAND NAME`} value={farmerDetials?.fatherHusbandName} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <DetailsElement title={`Marital Status`} value={farmerDetials?.maritalStatus} />
                                                </div>
                                            </div>


                                            <hr className="divider-line" />
                                            <h3 className="card-heading mb-4">Contact Information</h3>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <DetailsElement title={`Email`} value={farmerDetials?.email} icon={<Icon name="mail" size={18} />} />
                                                </div>

                                                <div className="col-sm-4">
                                                    <DetailsElement title={`Phone No`} value={farmerDetials?.phoneNumber} icon={<Icon name="phone" size={18} />} />
                                                </div>

                                                <div className="col-sm-4">
                                                    <DetailsElement title={`AADHAR NO`} value={farmerDetials?.aadhaarNumber} icon={<Icon name="aadhaar" />} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="white-box-card mb-4 ">

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="card-heading mb-4">All Details</h3>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <h5 className="xsm-text light-text mb-0">COI</h5>
                                                    <p>{farmerDetials?.coi ? farmerDetials?.coi : '--'}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h5 className="xsm-text light-text mb-0">FARMER CODE</h5>
                                                    <p>{farmerDetials?.farmerCode}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h5 className="xsm-text light-text mb-0"> KISAN PARICHAY PATRA</h5>
                                                    <p>{farmerDetials?.kisanParichayPatraURL ? <Link to={farmerDetials?.kisanParichayPatraURL} target="_blank" className="text-primary ">Downalod</Link>
                                                        : <>--</>}
                                                    </p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h5 className="xsm-text light-text mb-0">DISTRICT</h5>
                                                    <p>{farmerDetials?.district}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h5 className="xsm-text light-text mb-0">BLOCK</h5>
                                                    <p>{farmerDetials?.block}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h5 className="xsm-text light-text mb-0">WARD</h5>
                                                    <p>{farmerDetials?.ward}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h5 className="xsm-text light-text mb-0">GPU</h5>
                                                    <p>{farmerDetials?.gpu}</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default FarmerDetails;