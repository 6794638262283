import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDeviceInstallationStationApi } from "../../_common/hooks/api/deviceInstallationStationHook";
import { Controller, useForm } from "react-hook-form";
import { useMasterApi } from "../../_common/hooks/api/masterApiHook";
import { URLS } from "../../_config";
import TitleBar from "../../components/TopBar/TitleBar";
import { getSelectedValue, toastHandeler } from "../../_common/functions";
import Loader from "../../components/loader";
import Select from "react-select";
import Error from "../../components/formFieldError";
import { Button } from "react-bootstrap";
import { useUser } from "../../_common/hooks/authHook";
import { CiCirclePlus } from "react-icons/ci";
import { IoCameraOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";
import FileUploadModal from "../../modals/fileUpload.modal";
import { useDashboardApi } from "../../_common/hooks/api/dashboardApiHook";

const IotInstallationAdd = () => {

    const params: any = useParams();
    const navigate: any = useNavigate();
    const deviceID = params.deviceID;
    const [device, setDevice] = useState<any>(null);
    const deviceInstallationStationAPI = useDeviceInstallationStationApi();
    const masterApi = useMasterApi();
    const user: any = useUser();
    const dashboardAPI: any = useDashboardApi();

    const { control, reset, formState: { errors }, handleSubmit, setValue, watch, } = useForm();
    const [iotimage, setIotimages] = useState<string | "/images/no_image.jpg">("/images/no_image.jpg");

    const districts: any = useDistricts();
    const wards: any = useWards();
    const gpus: any = useGpus();
    const blocks: any = useBlocks();
    const [stateData, setStateData] = useState<boolean>(false);

    const [dataReset, setDataReset] = useState<boolean>(true)

    const [filteredBlocks, setFilteredBlocks] = useState<any[]>([])
    const [filteredGpus, setFilteredGpus] = useState<any[]>([])
    const [filteredWards, setFilteredWards] = useState<any[]>([])

    const [uploadImageModal, setUploadImageModal] = useState<boolean>(false);
    const [uploadedImgs, setUploadedImgs] = useState<any>([]);


    const districtWatch = watch('district');
    const blockWatch = watch('block');
    const gpuWatch = watch('gpu');
    const wardWatch = watch('ward');



    const iotimgRef: any = useRef();



    const getDeviceData = () => {
        return new Promise((resolve: any, reject: any) => {
            deviceInstallationStationAPI.deviceInstallationStationDetails({
                urlParams: { InstallationStationID: deviceID }
            }, (message: any, resp: any) => {
                setDevice(resp.data.deviceInstallationStation[0]);




                /* 
                
                {
                              "stationName": "string",
                              "stationID": "string",
                              "fkDistrictID": 0,
                              "fkBlock": 0,
                              "fkWard": 0,
                              "fkgpu": 0,
                              "stationImage": "string",
                              "addedBy": 0
                            }
                */


            }, reject)
        })
    }

    const getAreaPictures = () => {
        dashboardAPI.getAreaPictures({ urlParams: { LocationID: deviceID } }, (message: string, resp: any) => {
            console.log(resp.data.objLocImgData)
            setUploadedImgs(resp.data.objLocImgData.map((imgObj: any) => { return { ...imgObj, imageUrl: imgObj.locationImageUrl } }))
        }, (message: string) => { });
    }
    const deviceStationSave = (data: any) => {
        return new Promise((resolve: any, reject: any) => {
            deviceInstallationStationAPI.deviceInstallationStationSave(data, resolve, reject)
        })
    }
    const deviceStationUpdate = (data: any) => {

        return new Promise((resolve: any, reject: any) => {
            deviceInstallationStationAPI.deviceInstallationStationUpdate(data, resolve, reject)
        })
    }


    const saveDeviceStationHandler = (data: any) => {
        if (iotimage == "/images/no_image.jpg") {
            toast.error("Image is required");
            return false;
        }
        let params: any = {
            "stationName": data.name,
            "stationID": data.id,
            "fkDistrictID": districtWatch.value,
            "fkBlock": blockWatch.value,
            "fkWard": data?.ward?.value,
            "fkgpu": gpuWatch.value,
            //"stationImage": iotimage,
            "addedBy": user.userID
        }
        if (deviceID) {
            params.installationStationID = deviceID
        } else {
            params.stationImage = iotimage
        }

        toastHandeler(deviceID ? deviceStationUpdate : deviceStationSave, params, deviceID ? 'Updating...' : 'Adding').then(() => {
            navigate(URLS.IOT_INSTALLATION_STATIONS_LIST)
        })
    }


    useEffect(() => {
        setFilteredBlocks(blocks.filter((block: any) => { return block.fkDistrictID == districtWatch?.value }));
        /* setValue('block',null)
        setValue('gpu',null)
        setValue('ward',null) */
    }, [districtWatch])

    useEffect(() => {
        setFilteredGpus(gpus.filter((gpu: any) => { return gpu.fkBlockID == blockWatch?.value }));
        /* setValue('gpu',null)
        setValue('ward',null) */
    }, [blockWatch])

    useEffect(() => {
        setFilteredWards(wards.filter((ward: any) => { return ward.fkgpuid == gpuWatch?.value }));
        //setValue('ward', null)
    }, [gpuWatch])


    const handleIotPicChange = (event: any) => {
        // Access the selected file(s) from event.target.files
        const selectedFile = event.target.files[0];

        // Do something with the selected file, e.g., display its name

        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                // Base64 string representation of the selected file
                const base64String = reader.result as string | null;

                if (base64String !== null) {
                    setIotimages(base64String);
                }

                // You can use the base64String as needed (e.g., set it in the state)
            };

            // Read the selected file as a Data URL (Base64)
            reader.readAsDataURL(selectedFile);
        }
    };

    useEffect(() => {
        if (device) {

            console.log('Device=>', device)

            let resetValues = {
                id: device.stationID,
                name: device.stationName,
                district: getSelectedValue(device.fkDistrictID, 'value', districts ? districts : []),
                block: getSelectedValue(device.fkBlock, 'value', blocks ? blocks : []),
                gpu: getSelectedValue(device.fkgpu, 'value', gpus ? gpus : []),
                ward: getSelectedValue(device.fkWard, 'value', wards ? wards : []),
            }
            reset(resetValues);
            if (device.stationImage) {
                setIotimages(device.stationImage);
                setValue('iotPic', device.stationImage)
            }
            setDataReset(true)
        }
    }, [device])


    useEffect(() => {
        if (districts?.length > 0 && blocks?.length > 0 && gpus?.length > 0 && wards?.length > 0) {
            setStateData(true)
            if (deviceID) {
                getDeviceData();
                getAreaPictures();
            }
        }
    }, [districts, blocks, gpus, wards])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`${deviceID ? 'Edit' : 'Add'} IOT Installation Station `}
                            breadcrumbs={{
                                'IOT Device': '#',
                                'IOT Installation Station': URLS.IOT_INSTALLATION_STATIONS_LIST,
                                'Form': '#',
                            }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100" data-aos="fade-up">

                                    {deviceID && !dataReset ? <Loader /> :

                                        <form className="row" onSubmit={handleSubmit(saveDeviceStationHandler)}>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="card-heading mb-4 ">IOT Installation Station Details</h3>
                                                    <div className="row">

                                                        <div className="col-sm-6">
                                                            <div className="mb-3">
                                                                <label>IOT Installation Station Name</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="name"
                                                                    rules={{ required: 'Station Name is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                    )}
                                                                />
                                                                <Error error={errors['name']} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-3">
                                                                <label>IOT Installation Station ID</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="id"
                                                                    rules={{ required: 'Group ID is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                    )}
                                                                />
                                                                <Error error={errors['id']} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-3">
                                                                <label>District</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="district"
                                                                    // rules={{ required: 'District is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={districts ? districts : []}
                                                                            value={value}
                                                                            isMulti={false}
                                                                        />
                                                                    )}
                                                                />
                                                                <Error error={errors['district']} />
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="mb-3">
                                                                <label>Block</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="block"
                                                                    // rules={{ required: 'Block is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={filteredBlocks ? filteredBlocks : []}
                                                                            value={value}
                                                                            isMulti={false}
                                                                        />
                                                                    )}
                                                                />
                                                                <Error error={errors['block']} />
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="mb-3">
                                                                <label>GPU</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="gpu"
                                                                    // rules={{ required: 'GPU ID is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={filteredGpus ? filteredGpus : []}
                                                                            value={value}
                                                                            isMulti={false}
                                                                        />
                                                                    )}
                                                                />
                                                                <Error error={errors['gpu']} />
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="mb-3">
                                                                <label>Ward</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="ward"
                                                                    // rules={{ required: 'Ward ID is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={filteredWards ? filteredWards : []}
                                                                            value={value}
                                                                            isMulti={false}
                                                                        />
                                                                    )}
                                                                />
                                                                <Error error={errors['ward']} />
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="mb-3">
                                                                <label className="mb-1">Upload  Image</label>
                                                                <div>


                                                                    <div className="col-sm-6">
                                                                        <div className="white-box-card profile-card" data-aos="fade-up">
                                                                            {
                                                                                (deviceID) ?
                                                                                    <div className="profile-avatar" onClick={() => { setUploadImageModal(true) }}>
                                                                                        <img src={iotimage} alt="" />
                                                                                        <div className="cam-icon">
                                                                                            <IoCameraOutline />
                                                                                        </div>
                                                                                    </div> :
                                                                                    <div className="profile-avatar"  >
                                                                                        <img src={iotimage} alt="" />
                                                                                        <div className="upload-pic-btn">
                                                                                            <CiCirclePlus />
                                                                                            <Controller
                                                                                                defaultValue={""}
                                                                                                control={control}
                                                                                                name="iotPic"
                                                                                                render={({
                                                                                                    field: { onChange, onBlur, value, name, ref },
                                                                                                }) => (
                                                                                                    <input
                                                                                                        accept="image/*"
                                                                                                        type="file"
                                                                                                        ref={iotimgRef}
                                                                                                        onChange={(e) => {
                                                                                                            handleIotPicChange(e);
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="cam-icon">
                                                                                            <IoCameraOutline />
                                                                                        </div>
                                                                                    </div>
                                                                            }

                                                                        </div>
                                                                        <Error error={errors["iotPic"]} />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                <Link to={URLS.IOT_INSTALLATION_STATIONS_LIST} className="btn btn-default">Cancel</Link>
                                                <Button type="submit">Save</Button>
                                            </div>
                                        </form>

                                    }
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                <FileUploadModal show={uploadImageModal} onClose={setUploadImageModal} images={uploadedImgs} onUpdate={(images: any) => {
                    let _params: any = {
                        "installationStationID": deviceID,
                        "addedBy": user.userID,
                        "installationStationImageList": images.map((imgObj: any) => { return { "imageUrl": imgObj.imageUrl } })
                    }

                    console.log('call update images -----', _params);

                    deviceInstallationStationAPI.saveStationImages(_params, (message: any, resp: any) => {
                        console.log('after upload images response', resp)
                    }, (message: any) => { });
                }}
                    setUploadedImgs={setUploadedImgs} />


                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <script>
                                    document.write(new Date().getFullYear())
                                </script>2024 © Precision Farming.
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    )
}

export default IotInstallationAdd;