import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDeviceApi } from "../../../_common/hooks/api/deviceApiHook";
import { URLS } from "../../../_config";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import Loader from "../../../components/loader";
import Footer from "../../../components/footer/Footer";
import parse from 'html-react-parser';

const DeviceCategoryDetails = () => {
    const deviceapi = useDeviceApi();
    const [dataReset, setDataReset] = useState<boolean>(false)

    const params: any = useParams();

    const [id, setId] = useState<any>(params.id)
    const [deviceCategoryDetails, setDeviceCategoryDetails] = useState<any>({})

    const getDeviceCategoryDetails = () => {
        deviceapi.deviceCategoryDetails({ urlParams: { DeviceTemplateID: id } }, (message: any, resp: any) => {
            let devicedetail: any = resp.data?.deviceTemplate[0];
            setDeviceCategoryDetails(devicedetail);
            setDataReset(true)
        }, (message: any) => {
            //----
        })
    }

    useEffect(() => {
        if (id) {
            getDeviceCategoryDetails()
        }
    }, [id])

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={` Device Category Details`}
                            breadcrumbs={{
                                'IoT Device': '#',
                                'Device Category': URLS.DEVICE_CATEGORY.LIST,
                                'Details': '#',
                            }} />

                        {id && !dataReset ? <Loader /> :

                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="white-box-card mb-4 h-100 aos-init aos-animate" data-aos="fade-up">

                                        <div className="col-sm-12 mb-5">
                                            <h3 className="card-heading mb-4 ">  Category Details</h3>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="mb-3">
                                                        <h5 className="xsm-text light-text mb-0">DEVICE NAME</h5>
                                                        <p className="mb-0 ">{deviceCategoryDetails?.deviceName ? deviceCategoryDetails?.deviceName : '--'}</p>

                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="mb-3">
                                                        <h5 className="xsm-text light-text mb-0">DEVICE TYPE</h5>
                                                        <p className="mb-0 ">{deviceCategoryDetails?.deviceType ? deviceCategoryDetails?.deviceType : '--'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="mb-3">
                                                        <h5 className="xsm-text light-text mb-0">DESCRIPTION</h5>
                                                        <p className="mb-0 ">{parse(deviceCategoryDetails?.description ? deviceCategoryDetails?.description : '--')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-8">
                                    <div className="white-box-card mb-4 h-100 aos-init aos-animate" data-aos="fade-up">

                                        <div className="row">

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="card-heading mb-4 ">Device Category Params</h3>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Param Master</th>
                                                                <th>Param</th>
                                                                <th>Param Type</th>
                                                                {/* <th>Conversion Formula</th>  */}
                                                                <th>Unit</th>
                                                                <th>Note</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                deviceCategoryDetails?.deviceTemplateParamList && deviceCategoryDetails?.deviceTemplateParamList?.length > 0 ?
                                                                    deviceCategoryDetails?.deviceTemplateParamList?.map((tempParam: any, index: number) =>
                                                                        <tr>
                                                                            <td>{tempParam?.fkDeviceParamMasterValue}</td>
                                                                            <td>{tempParam?.param}</td>
                                                                            <td>{tempParam?.deviceParamType}</td>
                                                                            {/* <td>{tempParam?.conversionFormula}</td>  */}
                                                                            <td>{parse(tempParam?.unit)}</td>
                                                                            <td>
                                                                                <div>{parse(tempParam?.note ? tempParam?.note : '--')}</div>
                                                                            </td>
                                                                        </tr>
                                                                    ) :
                                                                    <tr>
                                                                        <td colSpan={6} className="text-center">No params found!</td>
                                                                    </tr>

                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 d-flex gap-2 justify-content-end ">
                                            <Link to={URLS.DEVICE_CATEGORY.LIST} className="btn btn-default">Back  </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        }



                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default DeviceCategoryDetails;