import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { useDeviceApi } from "../../_common/hooks/api/deviceApiHook";
import { useNavigate, useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { URLS } from "../../_config";
import Select from "react-select";
import Error from "../../components/formFieldError";
import { getSelectedValue } from "../../_common/functions";
import { Link } from "react-router-dom";
import Loader from "../../components/loader";
import { useUser } from "../../_common/hooks/authHook";
import TitleBar from "../../components/TopBar/TitleBar";

const DeviceUnitForm = () => {
    const deviceapi = useDeviceApi();
    const [devicetypes, setDevicestype] = useState<any[] | null>(null)
    const [dataReset, setDataReset] = useState<boolean>(false)
    const navigate: any = useNavigate();
    const assignmentStatusOptions = [
        { value: 1, label: 'Assigned' },
        { value: 0, label: 'Not Assigned' },
    ];

    const workstaus = [
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ];



    const params: any = useParams();

    const { control, formState: { errors }, handleSubmit, reset } = useForm();
    const [id, setId] = useState<any>(params.deviceID)
    const user: any = useUser()

    const getDivicetype = () => {
        deviceapi.getAllDevicestype({ urlParams: { PageIndex: 1, PageSize: 999 } }, (message: any, resp: any) => {
            //   console.log(resp.data);
            setDevicestype(resp.data.deviceTemplate)
        }, (message: any) => { })
    }

    const saveDeviceCategory = (data: any) => {
        return new Promise((resolve, reject) => {
            let params: any = {
                "name": data.name,
                "deviceType": data.deviceType,
                "description": data.description,
                "addedBy": user?.userID
            }
            //console.log(params);
            if (id) {
                params.deviceTemplateID = id;
                deviceapi.updateDevice(params, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })
            } else {
                deviceapi.saveDevice(params, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })
            }
        })

    }


    const saveDeviceHandler = (data: any) => {
        toast.promise(
            saveDeviceCategory(data), {
            loading: id ? 'Updating...' : 'Adding...',
            success: (msg: any) => {
                navigate(URLS.DEVICE_LIST)
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const getDeviceDetails = () => {
        //console.log(id);
        deviceapi.getDeviceDetails({ urlParams: { DeviceId: id } }, (message: any, resp: any) => {
            let devicedetail: any = resp.data.device[0];
            //console.log(devicedetail);
            let resetValues = {
                fkDeviceTemplateID: getSelectedValue(devicedetail?.fkDeviceTemplateID, "value", devicetypes ? devicetypes : []),
                description: devicedetail.description,
                deviceSerialNumber: devicedetail.deviceSerialNumber,
                refDeviceID: devicedetail.refDeviceID,
                assignmentStatus: getSelectedValue(devicedetail?.fkAssignmentStatusID, "value", assignmentStatusOptions ? assignmentStatusOptions : []),
                workingStatus: getSelectedValue(devicedetail?.fkWorkingStatus, "value", workstaus ? workstaus : []),
            }
            console.log(resetValues);
            reset(resetValues);
            setDataReset(true)

        }, (message: any) => {
            //----
        })

    }
    useEffect(() => {
        if (id && devicetypes) {

            getDeviceDetails()
        }
    }, [devicetypes])
    useEffect(() => {
        getDivicetype();

    }, [])
    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">Add New device</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Administrator</a></li>
                                            <li className="breadcrumb-item active">Add New Device</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
 

                        {id && !dataReset ? <Loader /> :
                            <form className="row" onSubmit={handleSubmit(saveDeviceHandler)}>
                                <div className="col-sm-12">
                                    <div className="white-box-card mb-4 h-100 aos-init aos-animate" data-aos="fade-up">

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h3 className="card-heading mb-4 ">Device Details</h3>
                                                <div className="row">

                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Name</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="fkDeviceTemplateID"
                                                                rules={{ required: 'Device Category is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={devicetypes ? devicetypes : []}
                                                                        value={value}

                                                                    />
                                                                )}
                                                            />
                                                            <Error error={errors['fkDeviceTemplateID']} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Sensor Key</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="description"
                                                                rules={{ required: 'Description is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['description']} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Sensor Unit</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="deviceSerialNumber"
                                                                rules={{ required: 'Device Serial is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['deviceSerialNumber']} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                        <div className="mt-3 d-flex gap-2 justify-content-end ">
                                            <Link to={URLS.DEVICE_CATEGORY.LIST} className="btn btn-default">Cancel</Link>
                                            <button type="submit" className="btn btn-primary ">{id ? 'Update' : 'Save'}</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        }



                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default DeviceUnitForm;