import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { IoCameraOutline } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { Controller, useForm } from "react-hook-form";
import FormTextInput from "../../_common/form-elements/textinput/formTextInput";
import { salutationData } from "../../_common/staticData";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ACTIONS, URLS } from "../../_config";
import toast from "react-hot-toast";
import { useEmployeeApi } from "../../_common/hooks/api/employeeApiHook";
import { convertLocalFileToBase64, getSelectedValue } from "../../_common/functions";
import { useMasterApi } from "../../_common/hooks/api/masterApiHook";
import Select from "react-select";
import Loader from "../../components/loader";
import Error from "../../components/formFieldError";
import { useDispatch } from "react-redux";
import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";
import { useUser } from "../../_common/hooks/authHook";

const ProfileAdd = () => {
  const employeeApi = useEmployeeApi();
  const masterApi = useMasterApi();

  const navigate = useNavigate();
  const urlParams: any = useParams()
  const dispatch = useDispatch();

  const employeeID: any = urlParams.employeeID;
  console.log("employeeID", employeeID);
  //let userimage:any = '/images/no_image.jpg';

  const [districtOptions, setDistrictOptions] = useState<any>(salutationData);

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const profilePicRef: any = useRef();
  const user: any = useUser();

  const [detaments, setDetaments] = useState<any[] | null>(null);
  const [userimage, setUserimages] = useState<string | '/images/no_image.jpg'>('/images/no_image.jpg');
  const [designations, setDesignations] = useState<any[] | null>(null);

  const districts: any = useDistricts();
  const wards: any = useWards();
  const gpus: any = useGpus();
  const blocks: any = useBlocks();
 
  const [roles, setRole] = useState<any[] | null>(null);
  const [dataReset, setDataReset] = useState<boolean>(false)

  const location: any = useLocation();

  const addEmployee = (param: any) => {

    return new Promise((resolve, reject) => {

      console.log("params", param);
      if (employeeID) {
        if (!param.avatar) {
          param.avatar = userimage;
        }

        param.userID = employeeID;
        employeeApi.updateEmployee(param
          , (message: any, resp: any) => {
            console.log(resp);
            let _resp: any = resp.data?.employeeList[0]
            let _user: any = {
              userID: _resp.userID,
              rollID: _resp.roleID,
              userRollID: _resp.roleID,
              displayName: _resp.displayName,
              Email: _resp.email,
              avatar: _resp.avatar,
              resetPassword: _resp.resetPassword,
              EmployeeID: _resp.fkRefEmployeeID,
              userData: ""

            }

            dispatch({
              type: ACTIONS.USER.USER,
              payload: _user
            })





            resolve(message)
          }, (message: any) => {
            reject(message)
          })

        setTimeout(() => {
          resolve("Profile Update successfully.");
        }, 2000);

      } else {
        if (!param.avatar && userimage != '/images/no_image.jpg') {
          param.avatar = userimage;
        }

        employeeApi.addEmployee(param
          , (message: any, resp: any) => {
            resolve(message)
          }, (message: any) => {
            reject(message)
          })

        setTimeout(() => {
          resolve("Employee added successfully.");
        }, 2000);

      }

    });
  };

  const getDepartments = () => {
    masterApi.getAllDepartment(
      {
        urlParams: { PageIndex: 1, PageSize: 999 },
      },
      (message: any, resp: any) => {
        console.log("departs =>", resp.data.departments);

        setDetaments(resp.data.departments);
      },
      (message: any) => {
        setDetaments([]);
      }
    );
  };

  const getDesignation = () => {
    masterApi.getAllDesignation(
      {
        urlParams: { PageIndex: 1, PageSize: 999 },
      },
      (message: any, resp: any) => {
        console.log("designation =>", resp.data.designations);

        setDesignations(resp.data.designations);
      },
      (message: any) => {
        setDesignations([]);
      }
    );
  };


  const getRolesall = () => {
    masterApi.getAllRoles(
      {
        urlParams: { PageIndex: 1, PageSize: 999 },
      },
      (message: any, resp: any) => {

        console.log("All roles", resp.data.role);
        setRole(resp.data.role);
      },
      (message: any) => {
        setRole([]);
      }
    );
  };

  const getEmplData = () => {

    employeeApi.getEmployeeDetails(
      {
        urlParams: { employeeID: employeeID, },
      },
      (message: string, resp: any) => {
        //  console.log(resp);
        let empdetails = resp.data?.employeeList?.[0];
        // console.log(empdetails?.fkDepartmentID);
        reset({
          firstName: empdetails?.firstName,
          lastName: empdetails?.lastName,
          email: empdetails?.email,
          adaharno: empdetails?.aadhaarNo,
          father_name: empdetails?.fatherOrHunsbandName,
          phone: empdetails?.phone,
          role: getSelectedValue(empdetails?.roleID, "value", roles ? roles : []),
          department: getSelectedValue(empdetails?.fkDepartmentID, "value", detaments ? detaments : []),
          designation: getSelectedValue(empdetails?.fkDesignationID, "value", designations ? designations : []),
          mode_appinment: empdetails?.modeOfAppointmenty,
          employee_id: empdetails?.fkRefEmployeeID,
          personalqulification: empdetails?.personalQualification,
          qualification: empdetails?.qualification,
          district: getSelectedValue(empdetails.userDistrictsList.map((dist: any) => { return dist.districtId }), 'value', districts ? districts : []),
          gpu: getSelectedValue(empdetails.userGPUList.map((dist: any) => { return dist.gpuid }), 'value', gpus ? gpus : []),
          ward: getSelectedValue(empdetails.userWardList.map((dist: any) => { return dist.wardID }), 'value', wards ? wards : [])
          //role:




        })
        if (empdetails.avatar) {
          setUserimages(empdetails.avatar);
          //userimage=empdetails.avatar;
        }

        setDataReset(true);





      }, (message: string) => {
        //   hideLoader();
      })
  }

  const addEmployeeHandler = (data: any) => {
    //console.log(data);
    let param: any = {
      firstName: data.firstName,
      fkRefEmployeeID: data.employee_id,
      lastName: data.lastName,
      email: data.email,
      roleID: data.role.roleId,
      addedBy: user?.userID,
      resetPasswordURL: (window as any).location.origin + "/reset-password",
      fkDepartmentID: data.department?.departmentID,
      fkDesignationID: data.designation?.designationID,
      fkModeOfAppointmentID: 0,
      phone: data.phone,
      qualification: data.qualification,
      personalQualification: data.personalqulification,

      aadhaarNo: data.adaharno,
      fatherOrHunsbandName: data.father_name,
      userDistrictsList: Array.isArray(data?.district) ? data?.district?.map((data: any) => {
        return { districtId: data.districtId };
      }) : [],
      userGPUList: Array.isArray(data?.gpu) ? data?.gpu?.map((data: any) => {
        return { gpuid: data.gpiid };
      }) : [],

      userWardList: Array.isArray(data?.ward) ? data?.ward?.map((data: any) => {
        return { wardID: data.wardID };
      }) : [],


    };
    //  console.log(data);
    //  return;
    if (data.profilePic) {
      convertLocalFileToBase64(profilePicRef.current.files[0]).then(
        (base64File) => {
          param.avatar = base64File;
          toast.promise(addEmployee(param), {
            loading: employeeID ? 'Updating...' : 'Adding...',
            success: (msg: any) => {
              navigate(URLS.PROFILE);
              return <b>{msg}</b>;
            },
            error: (msg: any) => {
              return <b>{msg}</b>;
            },
          });
        }
      )
    } else {
      toast.promise(addEmployee(param), {
        loading: employeeID ? 'Updating...' : 'Adding...',
        success: (msg: any) => {
          navigate(URLS.PROFILE);
          return <b>{msg}</b>;
        },
        error: (msg: any) => {
          return <b>{msg}</b>;
        },
      });
    }


  };

  const handleProfilePicChange = (event: any) => {
    // Access the selected file(s) from event.target.files
    const selectedFile = event.target.files[0];

    // Do something with the selected file, e.g., display its name
    console.log('Selected File:', selectedFile.name);
    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Base64 string representation of the selected file
        const base64String = reader.result as string | null;
        console.log('Base64 String:', base64String);
        if (base64String !== null) {
          setUserimages(base64String);
        }

        // You can use the base64String as needed (e.g., set it in the state)
      };

      // Read the selected file as a Data URL (Base64)
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    getDepartments();
    getDesignation();
    getRolesall();
  }, []);
  useEffect(() => {
    if (employeeID && detaments && designations && districts && wards && gpus && roles) {
      getEmplData()
    }
  }, [employeeID, detaments, designations, districts, wards, gpus, roles])

  return (
    <React.Fragment>
      <Header></Header>
      <LeftPanel></LeftPanel>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18"> Edit Profile</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="">Administrator</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit Profile
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {employeeID && !dataReset ? <Loader /> :
              <form className="row" onSubmit={handleSubmit(addEmployeeHandler)}>
                <div className="col-sm-3">
                  <div
                    className="white-box-card profile-card "
                    data-aos="fade-up"
                  >
                    <div className="profile-avatar">
                      <img src={userimage} alt="" />
                      <div className="upload-pic-btn">
                        <CiCirclePlus />

                        <Controller
                          defaultValue={""}
                          control={control}
                          name="profilePic"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <input
                              type="file"
                              ref={profilePicRef}
                              onChange={(e) => {

                                handleProfilePicChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="cam-icon">
                        <IoCameraOutline />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-9" data-aos="fade-up">
                  <div
                    className="white-box-card mb-4 h-100 aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <h3 className="card-heading mb-4 ">Personal Details</h3>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label>First Name<sup className="text-danger">*</sup></label>
                              <Controller
                                defaultValue={""}
                                control={control}
                                rules={{ required: 'First name is required' }}
                                name="firstName"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    placeholder="Enter First name"

                                  />
                                )}
                              />

                              <Error error={errors['firstName']} />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label>Last name<sup className="text-danger">*</sup></label>
                              <Controller
                                defaultValue={""}
                                rules={{ required: 'Last name is required' }}
                                control={control}
                                name="lastName"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    placeholder="Enter Last name"

                                  />
                                )}
                              />
                              <Error error={errors['lastName']} />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label>Email<sup className="text-danger">*</sup></label>
                              <Controller
                                defaultValue={""}
                                rules={{
                                  required: 'Email name is required', pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: 'Invalid email format',
                                  }
                                }}
                                control={control}
                                name="email"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="email"
                                    placeholder="Enter Email"
                                    error={errors.email}
                                  />
                                )}
                              />
                              <Error error={errors['email']} />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label>Aadhaar No<sup className="text-danger">*</sup></label>
                              <Controller
                                defaultValue={""}
                                rules={{
                                  required: 'Adahar no is required', pattern: {
                                    value: /^\d{12}$/,
                                    message: 'Aadhar no must be 12 digits',
                                  }
                                }}
                                control={control}
                                name="adaharno"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    placeholder="Enter Aadhaar No"
                                    error={errors.adaharno}
                                  />
                                )}
                              />
                              <Error error={errors['adaharno']} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label>Father / Husband Name</label>
                              <Controller
                                defaultValue={""}

                                control={control}
                                name="father_name"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    placeholder="Enter Father / Husband Name"
                                    error={errors.father_name}
                                  />
                                )}
                              />
                              <Error error={errors['father_name']} />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label>Phone<sup className="text-danger">*</sup></label>
                              <Controller
                                defaultValue={""}
                                control={control}
                                name="phone"
                                rules={{
                                  required: 'Phone is required',
                                  pattern: {
                                    value: /^\d{10}$/,
                                    message: 'Phone no must be 10 digits',
                                  }
                                }}
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    placeholder="Enter Phone"
                                    error={errors.phone}
                                  />

                                )}
                              />
                              <Error error={errors['phone']} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="my-4">
                          <hr className="divider" />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <h3 className="card-heading mb-4">Office Details</h3>
                        <div className="row">

                          <div className="col-sm-4">
                            <div className="mb-3">
                              <label>Role<sup className="text-danger">*</sup></label>
                              <Controller
                                defaultValue={""}
                                rules={{ required: 'Role is required' }}
                                control={control}
                                name="role"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <Select
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    options={roles ? roles : []}
                                    value={value}
                                    placeholder="Enter Role"
                                  />
                                )}
                              />
                              <Error error={errors['role']} />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="mb-3">
                              <label>Department</label>
                              <Controller
                                defaultValue={""}
                                control={control}

                                name="department"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <Select
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    options={detaments ? detaments : []}
                                    value={value}
                                    placeholder="Enter Department"
                                  />
                                )}
                              />
                              <Error error={errors['department']} />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="mb-3">
                              <label>Designation</label>
                              <Controller
                                defaultValue={""}

                                control={control}
                                name="designation"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <Select
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    options={designations ? designations : []}
                                    value={value}
                                    placeholder="Enter Designation"
                                  />
                                )}
                              />
                              <Error error={errors['designation']} />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="mb-3">
                              <label>Mode Of Appointment</label>
                              <Controller
                                defaultValue={""}
                                control={control}

                                name="mode_appinment"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    placeholder="Enter Mode Of Appointment"
                                    error={errors.mode_appinment}
                                  />
                                )}
                              />
                              <Error error={errors['mode_appinment']} />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="mb-3">
                              <label>Employee ID<sup className="text-danger">*</sup></label>
                              <Controller
                                defaultValue={""}
                                control={control}
                                rules={{ required: 'Employee ID is required' }}
                                name="employee_id"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    placeholder="Enter Employee ID"
                                    error={errors.employee_id}
                                  />
                                )}
                              />
                              <Error error={errors['employee_id']} />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="mb-3">
                              <label>Qualification</label>
                              <Controller
                                defaultValue={""}
                                control={control}

                                name="qualification"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    placeholder="Enter Qualification"
                                    error={errors.qualification}
                                  />
                                )}
                              />
                              <Error error={errors['qualification']} />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="mb-3">
                              <label>Personal Qualification</label>
                              <Controller
                                defaultValue={""}
                                control={control}

                                name="personalqulification"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    placeholder="Enter Personal Qualification"
                                    error={errors.personalqulification}
                                  />
                                )}
                              />
                              <Error error={errors['personalqulification']} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="my-4">
                          <hr className="divider" />
                        </div>
                        <div className="col-sm-12">
                          <h3 className="card-heading mb-4">Place of Posting</h3>
                          <div className="row">
                            <div className="col-sm-4">
                              <label>District</label>
                              <Controller
                                defaultValue={""}
                                control={control}

                                name="district"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <Select
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    options={districts ? districts : []}
                                    value={value}
                                    isMulti={true}
                                    placeholder="Enter District"
                                  />
                                )}
                              />
                              <Error error={errors['district']} />
                            </div>
                            <div className="col-sm-4">
                              <label>GPU</label>
                              <Controller
                                defaultValue={""}

                                control={control}
                                name="gpu"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <Select
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    isMulti={true}
                                    options={gpus ? gpus : []}
                                    value={value}
                                    placeholder="Enter Gpu"
                                  />
                                )}
                              />
                              <Error error={errors['gpu']} />
                            </div>
                            <div className="col-sm-4">
                              <label>Ward</label>
                              <Controller
                                defaultValue={""}

                                control={control}
                                name="ward"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <Select
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    options={wards ? wards : []}
                                    value={value}
                                    isMulti={true}
                                    placeholder="Enter WARD"
                                  />
                                )}
                              />
                              <Error error={errors['ward']} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 d-flex gap-2 justify-content-end ">
                        <Link to={URLS.EMPLOYEE_LIST} className="btn btn-default">
                          Cancel
                        </Link>
                        <Button type="submit">Save</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            }
          </div>
        </div>

        <Footer></Footer>
      </div>
    </React.Fragment>
  );
};

export default ProfileAdd;
