import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { CiCirclePlus } from "react-icons/ci";
import { useCollatralApi } from '../_common/hooks/api/collateralApiHook';
import Icon from '../components/icon';
import Swal from 'sweetalert2';

interface props {
    show: boolean,
    onClose: (status: boolean) => void,
    images:any,
    setUploadedImgs:any,
    onUpdate?:(images:any)=>void
}

export default function FileUploadModal({ show, onClose, images , setUploadedImgs, onUpdate = (images:any)=>{}}: props) {
    const handleClose = () => { onClose(false) };
    const collateralApi = useCollatralApi();

    const handlePicChange = (event: any) => {
        // Access the selected file(s) from event.target.files
        const selectedFile = event.target.files[0];

        // Do something with the selected file, e.g., display its name

        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                // Base64 string representation of the selected file
                const base64String = reader.result as string | null;

                if (base64String !== null) {
                    collateralApi.cropFileUpload([{ image: base64String }], (message: any, resp: any) => {
                        setUploadedImgs((oldArray: any) => {
                            onUpdate([...oldArray, { imageUrl: resp.data?.cropImage[0].imageUrl }])
                            return [...oldArray, { imageUrl: resp.data?.cropImage[0].imageUrl }]
                        })
                    }, (message: any) => {
                        //----
                    })
                }

                // You can use the base64String as needed (e.g., set it in the state)
            };

            // Read the selected file as a Data URL (Base64)
            reader.readAsDataURL(selectedFile);
        }
    };

    const removePic = (i: number) => {
        Swal.fire({
            title: 'Change Status',
            text: `Are you sure you want to remove this image?`,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, Remove it!`
        }).then((result) => {
            if (result.isConfirmed) {
                let newImgs = images && images.length > 0 &&
                images?.filter((img: any, index: number) => {
                    return i !== index
                })
                setUploadedImgs(newImgs)
                onUpdate(newImgs)
            }
        });

      
    };

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Upload Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {images.map((img:any, i:any)=>(
                        <div className='col-md-3 mb-3' key={`protection-method-image-${i}`}>
                            <div className="method-image-slide position-relative">
                                <img src={img.imageUrl} alt="image" />
                                <Icon name="times" size={16} className="text-danger remove-uploaded-img position-absolute"
                                                                    onClick={() => {removePic(i)}} />
                            </div>
                        </div>
                    ))}
                    <div className='col-md-3 mb-3'>
                        <div className="method-image-slide profile-avatar" style={{height:'auto'}}>
                            <img src={'/images/users/no-image.jpg'} alt="image" />
                            <div className="upload-pic-btn d-flex align-items-center justify-content-center">
                                <CiCirclePlus />
                                <input accept="image/*" type="file" onChange={(e) => {
                                    handlePicChange(e);
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                       {/*  <p className="m-2"><b>District: </b>
                            {data?.groupDistrictsList?.map((dist: any) => { return dist.districtName }).join(', ')}
                        </p>
                        <p className="m-2"><b>Block: </b>
                            {data?.groupBlockList?.map((block: any) => { return block.blockName }).join(', ')}
                        </p>
                        <p className="m-2"><b>GPU: </b>
                            {data?.groupGPUList?.map((gpu: any) => { return gpu.gpuName }).join(', ')}
                        </p>
                        <p className="m-2"><b>Ward: </b>
                            {data?.groupWardList?.map((ward: any) => { return ward.wardName }).join(', ')}
                        </p> */}
                    </div>
                </div>

            </Modal.Body> 
        </Modal>
    )
}