import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { CiEdit } from "react-icons/ci";
import { MdOutlineMail, MdOutlinePhone } from "react-icons/md";
import { useParams } from "react-router";
import { useEmployeeApi } from "../../_common/hooks/api/employeeApiHook";
import { Link } from "react-router-dom";
import { URLS } from "../../_config";
import { replaceUrlParams } from "../../_common/functions";
import Loader from "../../components/loader";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import ResetPasswordModal from "../../modals/resetPassword.modal";
import TitleBar from "../../components/TopBar/TitleBar";

interface props {
    myProfile?: boolean
}

const EmployeeDetails = ({ myProfile }: props) => {
    const permissions: any = useUserPermissions();
    const user: any = useUser();
    const urlParams: any = useParams();
    const [showForgetModalData, setShowForgetModalData] = useState<any>(null);
    const employeeID: any = myProfile ? user?.userID : urlParams.employeeID;
    const employeeApi = useEmployeeApi();
    const [empDetials, setEmpdetails] = useState<any>(null);
    const getEmplData = () => {

        employeeApi.getEmployeeDetails(
            {
                urlParams: { employeeID: employeeID, },
            },
            (message: string, resp: any) => {
                console.log(resp);
                let empdetails = resp.data?.employeeList?.[0];
                // console.log(empdetails?.fkDepartmentID);
                setEmpdetails(empdetails);
                if (empdetails.avatar) {
                    setUserimages(empdetails.avatar);
                    //userimage=empdetails.avatar;
                }
            }, (message: string) => {
                //   hideLoader();
            })
    }

    useEffect(() => {
        if (employeeID) {
            getEmplData()
        }

    }, [employeeID])

    const [userimage, setUserimages] = useState<string | '/images/no_image.jpg'>('/images/no_image.jpg');
    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`Employee Details`}
                            breadcrumbs={{
                                'Employee': URLS.EMPLOYEE_LIST,
                                'Details': '#',
                            }} />

                        {employeeID && !empDetials ? <Loader /> :
                            <div className="row" data-aos="fade-up">
                                <div className="col-sm-3">
                                    <div className="white-box-card profile-card h-100 ">
                                        <div className="profile-avatar">
                                            <img src={userimage} alt="" />
                                            {/* <div className="upload-pic-btn">
                                            <CiCirclePlus />
                                            <input type="file" />
                                        </div>
                                        <div className="cam-icon"><IoCameraOutline /></div> */}
                                        </div>
                                        <h3 className="card-heading mb-4 ">Personal Details</h3>
                                        <h4 className="md-text mb-3">{empDetials?.displayName}</h4>
                                        <h5 className="xsm-text light-text mb-0">FATHER / HUSBAND NAME</h5>
                                        <p>{empDetials?.fatherOrHunsbandName}</p>
                                        <p className="d-flex gap-2 align-items-center mb-1"><MdOutlineMail /> <span className="text-truncate" title={empDetials?.email}>{empDetials?.email}</span></p>
                                        <p className="d-flex gap-2 align-items-center  mb-3"><MdOutlinePhone /> {empDetials?.phone}</p>
                                        <h5 className="xsm-text light-text mb-0">AADHAAR NO</h5>
                                        <p className="mb-0 ">{empDetials?.aadhaarNo}</p>
                                        {permissions.menus.Employee.write && <>
                                            {myProfile ?
                                                <Link to={replaceUrlParams(URLS.PROFILE_EDIT, { employeeID: empDetials?.userID })}> <button className="btn btn-primary d-flex gap-2 align-items-center rounded-pill-btn edit-btn "><CiEdit /></button> </Link>
                                                :
                                                <Link to={replaceUrlParams(URLS.EMPLOYEE_EDIT, { employeeID: empDetials?.userID })}> <button className="btn btn-primary d-flex gap-2 align-items-center rounded-pill-btn edit-btn "><CiEdit /></button> </Link>
                                            }       </>}

                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="white-box-card mb-4 h-100 ">

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h3 className="card-heading mb-4">Office Details</h3>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">DEPARTMENT</h5>
                                                        <p>{empDetials?.department ? empDetials?.department : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">DESIGNATION</h5>
                                                        <p>{empDetials?.designation ? empDetials?.designation : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">MODE OF APPOINTMENT</h5>
                                                        <p>{empDetials?.modeOfAppointment ? empDetials?.modeOfAppointment : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">EMPLOYEE ID</h5>
                                                        <p>{empDetials?.fkRefEmployeeID ? empDetials?.fkRefEmployeeID : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">QUALIFICATION</h5>
                                                        <p>{empDetials?.qualification ? empDetials?.qualification : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">PERSONAL QUALIFICATION</h5>
                                                        <p>{empDetials?.personalQualification ? empDetials?.personalQualification : '--'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <hr className="my-4 divider-line" />
                                            </div>
                                            <div className="col-sm-12">
                                                <h3 className="card-heading mb-4">Place of Posting</h3>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">DISTRICT</h5>
                                                        {
                                                            empDetials && empDetials.length > 0 ? empDetials?.userDistrictsList?.map((data: any) => (
                                                                <p>{data.districtName}</p>
                                                            )) :
                                                                <p>--</p>
                                                        }
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">Block</h5>
                                                        {
                                                            empDetials && empDetials.length > 0 ? empDetials?.userBlockList?.map((data: any) => (
                                                                <p>{data.blockName}</p>
                                                            )) :
                                                                <p>--</p>
                                                        }
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">GPU</h5>
                                                        {
                                                            empDetials && empDetials.length > 0 ? empDetials?.userGPUList?.map((data: any) => (
                                                                <p>{data.gpuName}</p>
                                                            )) :
                                                                <p>--</p>
                                                        }
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">WARD</h5>
                                                        {
                                                            empDetials && empDetials.length > 0 ? empDetials?.userWardList?.map((data: any) => (
                                                                <p>{data.wardName}</p>
                                                            )) :
                                                                <p>--</p>
                                                        }
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">QUALIFICATION</h5>
                                                        <p>{empDetials?.qualification ? empDetials?.qualification : '--'}</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h5 className="xsm-text light-text mb-0">PERSONAL QUALIFICATION</h5>
                                                        <p>{empDetials?.personalQualification ? empDetials?.personalQualification : '--'}</p>
                                                    </div>
                                                </div>
                                                {myProfile ?
                                                    <Button type="button" onClick={() => setShowForgetModalData(empDetials)}>Change Password</Button>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                        {permissions.menus.Employee.write && <>
                                            {myProfile ?
                                                <Link to={replaceUrlParams(URLS.PROFILE_EDIT, { employeeID: empDetials?.userID })}> <button className="btn btn-primary d-flex gap-2 align-items-center rounded-pill-btn edit-btn "><CiEdit /></button> </Link>
                                                :
                                                <Link to={replaceUrlParams(URLS.EMPLOYEE_EDIT, { employeeID: empDetials?.userID })}> <button className="btn btn-primary d-flex gap-2 align-items-center rounded-pill-btn edit-btn "><CiEdit /></button> </Link>
                                            }

                                        </>
                                        }


                                    </div>

                                </div>
                            </div>

                        }

                    </div>
                </div>

                <ResetPasswordModal show={showForgetModalData != null}
                    onClose={() => setShowForgetModalData(null)}
                    data={showForgetModalData} />
                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default EmployeeDetails;