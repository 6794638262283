import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useDashboardApi() {
  const callApi = useApiCall();


  const getAllDeviceAreas = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DASHBOARD.GET_DEVICE_AREAS, data, onSuccess, onError);
  };

  const getAreaPictures = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DASHBOARD.GET_AREA_PICTURES, data, onSuccess, onError);
  };
  const getAreaChartData = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DASHBOARD.GET_AREA_CHARTS, data, onSuccess, onError);
  };
  const getCurrentWeatherReport = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DASHBOARD.GET_CURRENT_WEATHER_REPORT, data, onSuccess, onError);
  };
  const getWeatherHistoryReport = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DASHBOARD.GET_WEATHER_HISTORY_REPORT, data, onSuccess, onError);
  };


  return {
    getAllDeviceAreas, getAreaPictures,  getAreaChartData, getCurrentWeatherReport,getWeatherHistoryReport
  };
}
