import React, { useEffect, useState } from "react";
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import Button from 'react-bootstrap/Button';
import { Controller, useForm } from "react-hook-form";
import { useUserApi } from "../../_common/hooks/api/userApiHook";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormValues } from "../../_common/form-props";
import { LoginvalidationSchema } from "../../_common/form-validation";
import { URLS } from "../../_config";
import Icon from "../../components/icon";


const Login = () => {

    const userApi = useUserApi();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const { showLoader, hideLoader } = useAppLoader()

    const { control, formState: { errors }, handleSubmit, } = useForm<LoginFormValues>({
        resolver: yupResolver(LoginvalidationSchema),
        defaultValues: {
            username: "",
            password: "",
        },
    });
    const fullyear = new Date().getFullYear();
    const login = (data: any) => {
        showLoader();
        userApi.login({
            username: data.username,
            password: data.password
        }, (msg: any, resp: any) => {
            hideLoader();
            navigate(URLS.DASHBOARD);

        }, (msg: any) => {
            hideLoader();
            toast.error(msg);
        })
    }
    useEffect(() => {
        hideLoader();
    }, [])

    return (
        <React.Fragment>
            <div className="login-bg">
                <div className="login-transp-bg">
                    <div className="login-panel-box">
                        <div className="login-logo"><img src="./images/logo.png" alt="" /></div>
                        <div className="d-flex form-img-cont">
                            <div className="login-flds-panel col">
                                <div className="login-heading">
                                    <h1>Sign In</h1>
                                </div>
                                <form autoComplete="off" onSubmit={handleSubmit(login)}>
                                    <div className="login-flds-row mb-4">
                                        <label>User Name</label>
                                        <Controller
                                            name={"username"}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                <>
                                                    <input
                                                        type="email" autoComplete="new-email"
                                                        className={"form-control " + (errors?.username ? ' border-danger' : '')}
                                                        id="email"
                                                        name={name}
                                                        onChange={onChange}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        placeholder="Enter email"
                                                    />
                                                </>
                                            )}
                                        />
                                        {errors && errors.username &&
                                            <span className='text-danger'>{errors?.username?.message}</span>
                                        }
                                    </div>
                                    <div className="login-flds-row mb-2">
                                        <label>Password</label>
                                        <Controller
                                            name="password"
                                            control={control}
                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                <>
                                                    <div className="passwithicon">
                                                        <input
                                                            type={!showPassword ? "password" : "text"}
                                                            className={"form-control " + (errors?.password ? ' border-danger' : '')}
                                                            id="password"
                                                            autoComplete="new-password"
                                                            name={name}
                                                            onChange={onChange}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            placeholder="Enter password"
                                                        />

                                                        {!showPassword ?
                                                            <Icon name="eye-slash" onClick={() => setShowPassword(!showPassword)} size={18}/>
                                                            :
                                                            <Icon name="view" onClick={() => setShowPassword(!showPassword)} size={18}/>
                                                        }


                                                    </div>
                                                </>
                                            )}
                                        />


                                        {errors && errors.password &&
                                            <span className='text-danger'>{errors?.password?.message}</span>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-end pe-3 forgot-pass">
                                        <p> <Link to={URLS.FORGOT_PASSWORD} className="text-primary" >Forgot password?</Link>
                                        </p>

                                    </div>
                                    <div className="d-flex justify-content-center pt-4">
                                        <Button type="submit">Login</Button>
                                        {/* <a href="/dashboard" className="btn btn-primary custom-btn-lg">Login</a> */}
                                    </div>
                                </form>
                            </div>
                            <div className="login-pic col">
                                <img src="/images/loginImg4.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <p className="copyright">
                    {fullyear} © Precision Farming </p>
            </div>
        </React.Fragment>
    )
}

export default Login;