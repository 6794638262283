import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { API_BASE_URL } from "../_config";
import { useAppLoader } from "../../../_common/hooks/common/appLoaderHook";
import { Button, Modal } from "react-bootstrap";
import { useFarmsApi } from "../../../_common/hooks/api/farmApiHook";
import toast from "react-hot-toast";
import { useUser } from "../../../_common/hooks/authHook";
import { useNotificationApi } from "../../../_common/hooks/api/notificationApiHook";
interface ForecastTemperatureModalProps {
    open: boolean;
    close: () => void;
    alert?: any;
    farm?: any;
}

interface FormData {
    month: any;
    checklist: string;
    warning: string;
    critical: string;
    comment: string;
    district?: any;
    block?: any;
    gpu?: any;
    ward?: any;
}

const ForecastTemperatureModal = ({ open, close, alert, farm }: ForecastTemperatureModalProps) => {
    const user: any = useUser()
    const { control, register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm<FormData>();
    const { showLoader, hideLoader } = useAppLoader();
    const [checklists, setChecklists] = useState<any[] | null>(null);
    const [submitBtnDisable, setSubmitBtnDisable] = useState<boolean>(false);
    const notificationApi = useNotificationApi()

    const getForecastSensorRangeChecklists = async () => {
        showLoader();
        const response = await fetch(API_BASE_URL + `/sensor/range/checklists/${alert.sensor._id}/predictive-checklist`);
        const data: any = await response.json();
        if (alert.is_lcl) {
            setChecklists(data.lcl_checklist)
        } else {
            setChecklists(data.ucl_checklist);
        }
        hideLoader();

    }


    const saveData = (data: any) => {
        return new Promise(async (resolve, reject) => {
            let params: any = {
                "subject": `Recommended suggestion for ${alert.crop.name}, for the field ${alert.field.name}`,
                "notificationMessage": 'Alert message:\n\n' + alert.message + '\n\n Recomendation: \n\n' + data.checklist.join('\n\n') + '\n\n Additional Comment: \n\n' + data.comment,
                "addedBy": user?.userID,
                "senderID": user?.userID,
                "senderName": user?.displayName,
                "districtIDs": data.district ? data.district : '',
                "blockIDs": data.block ? data.block : '',
                "gpuiDs": data.gpu ? data.gpu : '',
                "wardIDs": data.ward ? data.ward : '',
            }
            let response = await fetch(API_BASE_URL + `/sensors/alerts/${alert._id}`,{
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({  is_read: true })
            });
            let _data: any = await response.json();
            notificationApi.notificationStore(params, resolve, reject);
        })
    }

    const saveNotificationHandler = (data: FormData) => {
        setSubmitBtnDisable(true)
        toast.promise(
            saveData(data), {
            loading: 'Sending...',
            success: (msg: any) => {
                close();
                return <b>{msg}</b>
            },
            error: (msg: any) => {
                setSubmitBtnDisable(false);
                return <b>{msg}</b>
            },
        });
    }

    useEffect(() => {
        if (open) {
            getForecastSensorRangeChecklists();
            console.log('Farm=>', farm)
        } else {
            setChecklists(null)
        }
    }, [open])


    return (
        <>
            <Modal show={open} onHide={close} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Preventive Suggestions</Modal.Title>
                </Modal.Header>
                <form autoComplete="off" onSubmit={handleSubmit(saveNotificationHandler)}  >
                    {farm &&
                        <Modal.Body>
                            <div style={{maxHeight:'calc(100vh - 250px)'}} className="mb-2  overflow-auto p-3">
                            <p className="font-semibold font-size-16">Suggestion Checklist</p>

                            {
                                            checklists?.map((checklist: any, index: number) => {
                                                return (
                                                    <div key={`checklist-${index}-A`} className="pf-card  mb-4 ">
                                                        <div className="flex items-stretch relative ">
                                                            <div  className="w-[40px] bg-emerald-50 flex-shrink-0 flex items-center justify-center border-r border-zinc-200">
                                                                <input type="checkbox" value={checklist} {...register("checklist", { required: true })} className="border border-gray-500 rounded-sm text-green-600" />
                                                                
                                                            </div>
                                                            <div className="flex-grow p-3">
                                                                <label className={`block text-sm font-medium text-gray-700 `}>{checklist}</label>
                                                                {errors.checklist && <p className="text-red-600 text-sm">Required</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="mb-2">
                                                    <label className="block text-sm font-medium text-gray-700">Comment </label>
                                                    <textarea rows={3} {...register("comment", { required: true })}
                                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                                        placeholder="Enter your comment here..."
                                                    />
                                                    {errors.comment && <p className="text-red-600 text-sm">This field is required</p>}
                                                </div>
                                                <div className="flex gap-2">
                                                    <fieldset className="border border-gray-500 rounded px-1 min-h-0">
                                                        <legend className="text-xs font-medium text-gray-700 mt-[-8px] px-2 float-none w-auto">District</legend>
                                                        <div className="flex gap-2 px-2 pb-2 items-center">
                                                            <input value={farm.districtID} type="checkbox" {...register("district", { required: false })} className="border border-gray-500 rounded-sm" />
                                                            <label className="block text-sm font-medium text-gray-700">{farm.districtName}</label>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="border border-gray-500 rounded px-1 min-h-0">
                                                        <legend className="text-xs font-medium text-gray-700 mt-[-8px] px-2 float-none w-auto">Block</legend>
                                                        <div className="flex gap-2 px-2 pb-2 items-center">
                                                            <input value={farm.blockID} type="checkbox" {...register("block", { required: false })} className="border border-gray-500 rounded-sm" />
                                                            <label className="block text-sm font-medium text-gray-700">{farm.blockName}</label>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="border border-gray-500 rounded px-1 min-h-0">
                                                        <legend className="text-xs font-medium text-gray-700 mt-[-8px] px-2 float-none w-auto">GPU</legend>
                                                        <div className="flex gap-2 px-2 pb-2 items-center">
                                                            <input value={farm.gpuid} type="checkbox" {...register("gpu", { required: false })} className="border border-gray-500 rounded-sm" />
                                                            <label className="block text-sm font-medium text-gray-700">{farm.gpuName}</label>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="border border-gray-500 rounded px-1 min-h-0">
                                                        <legend className="text-xs font-medium text-gray-700 mt-[-8px] px-2 float-none w-auto">Ward</legend>
                                                        <div className="flex gap-2 px-2 pb-2 items-center">
                                                            <input value={farm.wardID} type="checkbox" {...register("ward", { required: false })} className="border border-gray-500 rounded-sm" />
                                                            <label className="block text-sm font-medium text-gray-700">{farm.wardName}</label>
                                                        </div>
                                                    </fieldset>
                                                </div>



                                                {
                                            checklists && checklists.length == 0 && <div>
                                                <p  className="text-center">No data found</p>
                                            </div>
                                        }
                            </div>
                        </Modal.Body>
                    }
                    <Modal.Footer>
                        <Button variant="secondary" onClick={close}> Close </Button>
                        <Button type="submit">Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default ForecastTemperatureModal;




