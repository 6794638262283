import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ChartSoilTemperature = () => {
    const data = [
        {
            name: 'Pg A',
            uv: 4000,
            // pv: 2400,
            amt: 2400,
        },
        {
            name: 'Pg B',
            uv: 3000,
            // pv: 1398,
            amt: 2210,
        },
        {
            name: 'Pg C',
            uv: 2000,
            // pv: 9800,
            amt: 2290,
        },
        {
            name: 'Pg D',
            uv: 2780,
            // pv: 3908,
            amt: 2000,
        },
        {
            name: 'Pg E',
            uv: 1890,
            // pv: 4800,
            amt: 2181,
        },
    ];

    //   static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

    return (
        <React.Fragment>

            <div className="white-graph-box mt-5">
                <div className="white-graph-box-head">
                    <h2>Soil Temperature <span>14<sup>o</sup>C</span></h2>
                </div>
                <div className="device-name-graph"><img src="/images/device-icon.svg" alt="" /> Device Name</div>
                {/* <div className="demo-graph-wrap">
                    <img src="/images/g3.jpg" alt="" />
                </div> */}
                <div className="demo-graph-wrap" style={{ height: '250px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 10,
                                left: 0,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </React.Fragment>
    )

}

export default ChartSoilTemperature;