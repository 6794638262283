import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useDeviceApi() {
  const callApi = useApiCall();


  const getAllDevices = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.GET_DEVICES, data, onSuccess, onError);
  };

  const getDeviceDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.GET_DEVICES_DETAILS, data, onSuccess, onError);
  };
  const updateDevice = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.UPDATE_DEVICES, data, onSuccess, onError);
  };
  const saveDevice = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.SAVE_DEVICES, data, onSuccess, onError);
  };
  const deleteDevice = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DELETE_DEVICES, data, onSuccess, onError);
  };
  const changeDeviceStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.CHANGE_DEVICES_STATUS, data, onSuccess, onError);
  };


  const getAllDevicestype = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.GET_DEVICES_TEPLATE, data, onSuccess, onError);
  };

  const getAllDevicesatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DEVICES_WORKING_STATUS, data, onSuccess, onError);
  };
  const deviceCategoryDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_DETAILS, data, onSuccess, onError);
  };
  const deviceCategorySave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_SAVE, data, onSuccess, onError);
  };
  const deviceCategoryUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_UPDATE, data, onSuccess, onError);
  };
  const deviceCategoryChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_CHANGE_STATUS, data, onSuccess, onError);
  };
  const deviceCategoryDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_DELETE, data, onSuccess, onError);
  };

  
  const deviceParamTypeAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DEVICES_PARAM_TYPE_ALL, data, onSuccess, onError);
  };
  const deviceUnitMasterAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DEVICES_UNIT_MASTER_ALL, data, onSuccess, onError);
  };
  const deviceParamMasterAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.DEVICES_PARAM_MASTER_ALL, data, onSuccess, onError);
  };




  const deviceUnitList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.LIST, data, onSuccess, onError);
  };
  const deviceUnitDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.DETAILS, data, onSuccess, onError);
  };
  const deviceUnitSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.SAVE, data, onSuccess, onError);
  };
  const deviceUnitUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.UPDATE, data, onSuccess, onError);
  };
  const deviceUnitChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.CHANGE_STATUS, data, onSuccess, onError);
  };
  const deviceUnitDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.DELETE, data, onSuccess, onError);
  };


  return {
    getAllDevices, getDeviceDetails, saveDevice, updateDevice, deleteDevice, changeDeviceStatus,getAllDevicesatus,

    getAllDevicestype, deviceCategoryDetails, deviceCategorySave, deviceCategoryUpdate, deviceCategoryChangeStatus, deviceCategoryDelete,
    deviceParamTypeAll, deviceUnitMasterAll, deviceParamMasterAll, 


    deviceUnitList, deviceUnitDetails, deviceUnitSave, deviceUnitUpdate, deviceUnitChangeStatus, deviceUnitDelete,
    
  };
}
