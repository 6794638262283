import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import TitleBar from "../../components/TopBar/TitleBar";
import { Link, useNavigate } from "react-router-dom";
import { URLS } from "../../_config";
import toast from "react-hot-toast";
import { useUser } from "../../_common/hooks/authHook";
import { Controller, useForm } from "react-hook-form";
import Error from "../../components/formFieldError";
import Editor from "react-simple-wysiwyg";
import FormTextInput from "../../_common/form-elements/textinput/formTextInput";
import { useNotificationApi } from "../../_common/hooks/api/notificationApiHook";
import Select from "react-select";
import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";
import TableLoader from "../../components/tableLoader";
import Pagination from "../../components/pagination/Pagination";
import { useReportApi } from "../../_common/hooks/api/reportApiHook";
import { getRelativeListWithMultiSelected } from "../../_common/functions";
import { ConfirmAlert } from "../../_common/confirmAlert";
import Footer from "../../components/footer/Footer";

const InviteFarmer = () => {
    const notificationApi = useNotificationApi()
    const reportAPI = useReportApi();
    const user: any = useUser()
    const navigate: any = useNavigate();

    const [farmerIDs, setFarmerIDs] = useState<any>([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [submitBtnDisable, setSubmitBtnDisable] = useState<boolean>(false);

    const [farmers, setFarmers] = useState<any[] | null>(null);
    const [farmerIDsAll, setFarmerIDsAll] = useState([]);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;


    const { control, reset, formState: { errors }, handleSubmit, setValue, watch } = useForm();

    const [filteredBlocks, setFilteredBlocks] = useState<any[]>([]);
    const [filteredGpus, setFilteredGpus] = useState<any[] | null>(null)
    const [filteredWards, setFilteredWards] = useState<any[] | null>(null)

    const districts: any = useDistricts();
    const blocks: any = useBlocks();
    const gpus: any = useGpus();
    const wards: any = useWards();

    const districtWatch = watch("district");
    const blockWatch = watch("block");
    const gpuWatch = watch("gpu");
    const wardWatch = watch("ward");

    const saveData = (data: any) => {

        return new Promise((resolve, reject) => {
            let params: any = {
                "subject": data.subject,
                "notificationMessage": data.notificationMessage,
                "userID": user?.userID,
                "addedBy": user?.userID,
                "senderID": user?.userID,
                "senderName": user?.displayName,

                "searchText": '',
                "districtIDs": data.district ? data.district.map((dist: any) => { return dist.districtId }).join() : '',
                "blockIDs": data.block ? data.block.map((dist: any) => { return dist.blockID }).join() : '',
                "gpuiDs": data.gpu ? data.gpu.map((dist: any) => { return dist.gpiid }).join() : '',
                "wardIDs": data.ward ? data.ward.map((dist: any) => { return dist.wardID }).join() : '',

                "farmerIDs": farmerIDs.join(),
                "isSelectAll": isSelectAll
            }
            // console.log('params', params)
            notificationApi.inviteFramerNotification(params, resolve, reject)
        })


    }

    const saveNotificationHandler = (data: any) => {
        if (isSelectAll == false && farmerIDs.join() == '') {
            ConfirmAlert.warning('Please select at least a farmer.').then(() => {
            }).catch(() => { })
        } else {
            setSubmitBtnDisable(true)
            toast.promise(
                saveData(data), {
                loading: 'Updating...',
                success: (msg: any) => {
                    navigate(URLS.NOTIFICATION.INVITE_FARMERS_LIST)
                    return <b>{msg}</b>
                },
                error: (msg: any) => {
                    setSubmitBtnDisable(false)
                    return <b>{msg}</b>
                },
            });
        }
    }


    useEffect(() => {
        let { list, selected } = getRelativeListWithMultiSelected(
            districtWatch,
            blocks,
            blockWatch,
            "districtId",
            "fkDistrictID",
            "blockID"
        );
        setFilteredBlocks(list);
        setValue("block", selected);
    }, [districtWatch]);


    useEffect(() => {
        let { list, selected } = getRelativeListWithMultiSelected(
            blockWatch,
            gpus,
            gpuWatch,
            "blockID",
            "fkBlockID",
            "gpiid"
        );
        setFilteredGpus(list);
        setValue("gpu", selected);
    }, [blockWatch]);

    useEffect(() => {
        let { list, selected } = getRelativeListWithMultiSelected(
            gpuWatch,
            wards,
            wardWatch,
            "gpiid",
            "fkgpuid",
            "wardID"
        );
        setFilteredWards(list);
        setValue("ward", selected);
    }, [gpuWatch]);



    const getFarmerList = () => {
        let fIdsAll: any = [];
        reportAPI.getFarmerList({
            PageIndex: page,
            PageSize: limit,
            SearchText: search,
            "districtIDs": districtWatch ? districtWatch.map((dist: any) => { return dist.districtId }).join() : '',
            "blockIDs": blockWatch ? blockWatch.map((dist: any) => { return dist.blockID }).join() : '',
            "gpuiDs": gpuWatch ? gpuWatch.map((dist: any) => { return dist.gpiid }).join() : '',
            "wardIDs": wardWatch ? wardWatch.map((dist: any) => { return dist.wardID }).join() : '',
        }, (message: any, resp: any) => {
            setFarmers(resp.data.farmerRpt)
            setTotal(resp.totalRecords)

            if (resp.data.farmerRpt && resp.data.farmerRpt.length > 0) {
                resp.data.farmerRpt?.map((fData: any) => {
                    fIdsAll.push(fData.farmerID)
                })
                setFarmerIDsAll(fIdsAll);
            }
        }, (message: any) => {
            setFarmers([])
        })
    }

    const getFarmerListHandler = () => {
        setFarmers(null)
        if ((districtWatch?.length + blockWatch?.length + gpuWatch?.length + wardWatch?.length) > 0) {
            getFarmerList();
        } else {
            setFarmers([])
            setTotal(0)
        }
    }

    useEffect(() => {
        setFarmers(null)
        if ((blockWatch?.length + gpuWatch?.length + wardWatch?.length) > 0) {
            getFarmerList();
        } else {
            setFarmers([])
            setTotal(0)
        }
    }, [page])


    const selectAllHandler = (event: any) => {
        setIsSelectAll(event.target.checked)
        if (event.target.checked) {
            setFarmerIDs(farmerIDsAll)
        } else {
            setFarmerIDs([])
        }
    }


    const selectFarmerIdHandler = (event: any) => {
        let fIds: any = [...farmerIDs];
        let fId = parseInt(event.target.value);
        if (farmerIDs.indexOf(fId) == -1) {
            fIds.push(fId);
        } else {
            const index = fIds.indexOf(fId);
            if (index > -1) { // only splice array when item is found
                fIds.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setFarmerIDs(fIds)
    }
    const resetHandler = (data: any) => {
        reset({ districts: [], blocks: [], gpus: [], wards: [] })
    }


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Invite Farmers`}
                            breadcrumbs={{
                                'Notification': '#',
                                'Invite Farmers': URLS.NOTIFICATION.INVITE_FARMERS_LIST,
                                'Send Form': '#',
                            }} />

                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 ">
                                    <form className="row" action="" onSubmit={handleSubmit(saveNotificationHandler)}>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h3 className="card-heading mb-4 ">Invite Farmers</h3>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group float-label-wrap mb-3">
                                                            <label className="float-label">Notification Subject</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="subject"
                                                                rules={{ required: "Notification Subject is required" }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <FormTextInput
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        inputRef={ref}
                                                                        type="text"
                                                                        placeholder="Enter subject"
                                                                        error={errors.subject} />
                                                                )} />
                                                            <Error error={errors["subject"]} />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className="form-group float-label-wrap mb-0">
                                                            <label htmlFor="" className="float-label">Select District</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="district"
                                                                rules={{ required: "District is required" }}
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={districts ? districts : []}
                                                                        value={value}
                                                                        isMulti={true}
                                                                    />
                                                                )} />
                                                            <Error error={errors["district"]} />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className="form-group float-label-wrap mb-0">
                                                            <label htmlFor="" className="float-label">Select Block</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="block"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={filteredBlocks ? filteredBlocks : []}
                                                                        value={value}
                                                                        isMulti={true}
                                                                    />
                                                                )} />
                                                            <Error error={errors["block"]} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group float-label-wrap mb-0">
                                                            <label htmlFor="" className="float-label">Select GPU</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="gpu"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={filteredGpus != null ? filteredGpus : gpus}
                                                                        value={value}
                                                                        isMulti={true}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group float-label-wrap mb-0">
                                                            <label htmlFor="" className="float-label">Select Ward</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="ward"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={filteredWards != null ? filteredWards : wards}
                                                                        value={value}
                                                                        isMulti={true}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9"></div>
                                                    <div className="col-sm-3 mt-2">
                                                        <div className="two-btn-wrap">
                                                            <button type="button" className="btn btn-grey" onClick={resetHandler}>Reset</button>
                                                            <button type="button" className="btn btn-blue" onClick={getFarmerListHandler}>Search</button>
                                                        </div>

                                                    </div>
                                                    {/* <div className="col-sm-1 mt-2">
                                                        <Icon name="search" onClick={getFarmerListHandler} />
                                                    </div> */}


                                                    <div className="col-sm-12 mt-2">
                                                        <label>Notification Message</label>
                                                        <Controller
                                                            defaultValue={""}
                                                            control={control}
                                                            name="notificationMessage"
                                                            rules={{ required: "Notification Message is required" }}
                                                            render={({
                                                                field: { onChange, onBlur, value, name, ref },
                                                            }) => (
                                                                <Editor value={value} onChange={onChange} />
                                                            )}
                                                        />

                                                        <Error error={errors["notificationMessage"]} />
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="table-wrapper">
                                                        <table className="table gen-table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        <input type="checkbox" title="Select All"
                                                                            onChange={selectAllHandler}
                                                                            checked={farmerIDs.length == farmerIDsAll.length} /> &nbsp;
                                                                        All
                                                                    </th>
                                                                    <th scope="col">Farmer Code</th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Phone no</th>
                                                                    <th scope="col">District</th>
                                                                    <th scope="col">Block</th>
                                                                    <th scope="col">Ward</th>
                                                                    <th scope="col">GPU</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    farmers?.map(data => (
                                                                        <tr key={`farmer-${data.farmerID}`}>
                                                                            <td>
                                                                                <input type="checkbox" value={data.farmerID}
                                                                                    onChange={selectFarmerIdHandler}
                                                                                    checked={farmerIDs.indexOf(data.farmerID) > -1 ? true : false}
                                                                                />
                                                                            </td>
                                                                            <td>{data?.farmerCode}</td>
                                                                            <td>{data?.farmerName}</td>
                                                                            <td>{data?.phoneNumber}</td>
                                                                            <td>{data?.district ? data?.district : '--'}</td>
                                                                            <td>{data?.block ? data?.block : '--'}</td>
                                                                            <td>{data?.ward ? data?.ward : '--'}</td>
                                                                            <td>{data?.gpu ? data?.gpu : '--'}</td>
                                                                        </tr>
                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>
                                                        <TableLoader data={farmers} />
                                                        {
                                                            farmers && farmers?.length == 0 && <p className="text-center">Please select a district and search.</p>
                                                        }
                                                    </div>
                                                    {farmers ? <Pagination total={total} page={page} setPage={(n: number) => {
                                                        setPage(n)
                                                        setFarmers(null)
                                                    }} pageLimit={limit} /> : null}
                                                </div>
                                            </div>

                                            <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                <Link to={URLS.NOTIFICATION.INVITE_FARMERS_LIST} className="btn btn-default">Cancel</Link>
                                                <button type="submit" className="btn btn-primary" disabled={submitBtnDisable}>Invite</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>



                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default InviteFarmer;