import { ActionExtended } from '../../_common/interfaces/ActionExtended';
import { ACTIONS } from '../../_config'

export interface MasterReducer {
  districts: any[] | null;
  blocks: any[] | null;
  gpus: any[] | null;
  wards: any[] | null;
}

const initialState: MasterReducer = {
  districts: null,
  blocks: null,
  gpus: null,
  wards: null,
};

const masterReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.MASTER.DISTRICTS:
      return {
        ...state,
        districts: action.payload.districts
      };
      case ACTIONS.MASTER.WARDS:
      return {
        ...state,
        wards: action.payload.wards
      };
      case ACTIONS.MASTER.GPUS:
      return {
        ...state,
        gpus: action.payload.gpus
      };
      case ACTIONS.MASTER.BLOCKS:
      return {
        ...state,
        blocks: action.payload.blocks
      };

     
       
    default:
      return state;
  }
};

export default masterReducer;
