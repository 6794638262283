import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import { useUser } from "../../../_common/hooks/authHook";
import Loader from "../../../components/loader";
import { Controller, useForm } from "react-hook-form";
import { toastHandeler } from "../../../_common/functions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface props {
    show: any
    handleClose: any
    crop: any
}
const ProtectionMethods = ({ show, handleClose, crop }: props) => {

    const collateralApi = useCollatralApi()
    const user: any = useUser();

    const [methods, setMethods] = useState<any[] | null>(null);

    const { control, reset, formState: { errors }, handleSubmit, setValue, } = useForm();

    const getCropProtectionMethods = () => {
        return new Promise((resolve, reject) => {
            collateralApi.cropProtectionMethods({
                urlParams: { CropID: crop.cropID }
            }, (message: any, resp: any) => {
                setMethods(resp.data.cropProtectionMethod)
                let prevData:any = {};
                resp.data.cropProtectionMethod.map((method:any)=>{
                    prevData[`method-${method.protectionMethodID}`] = method.isChecked
                })
                reset(prevData);
                resolve(message)
            }, reject)
        })
    }


    const saveData = (params:any)=>{
        return new Promise((resolve:any,reject:any)=>{
            collateralApi.saveProtectionMethods(params,resolve,reject)
        })
    }


    const onSubmitHandler = (data: any) => {
        let selected:any = []
        Object.keys(data).map((key: any) => {
            if(data[key]){
                selected.push({ fkProtectionMethodID: key.replace('method-','') })
            }
        })

        toastHandeler(saveData, {
            cropID: crop.cropID,
            addedBy: user.userID,
            protectionMethodList: selected
        }, 'Updating...').then(()=>{
            getCropProtectionMethods();
        })
    }




    useEffect(() => {
        if (show) {
            getCropProtectionMethods()
        }
        return ()=>{
            setMethods(null)
        }
    }, [show])


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
      };
    return (
        <Offcanvas show={show} onHide={handleClose} placement={"end"} className="farming-method-offcanvas">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Protection Methods</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {methods ?
                    <>
                        {methods.length > 0 ?
                            <form onSubmit={handleSubmit(onSubmitHandler)}>
                                <div className="row add-crop-block-wrapper">
                                    {methods?.map((method: any, i: any) => (
                                        <div className="col-sm-4" key={`crop-method-${i}`}>
                                            <div className="crop-pic">
                                                <label className="form-check-label w-100 h-100" htmlFor={`method-${method.protectionMethodID}`}>
                                                    {method.objimages.length > 1 ?
                                                        <Slider {...settings}>
                                                            {method.objimages.map((imageObj: any, i: any) => (
                                                                <div key={`protection-method-image-${i}`} className="method-image-slide">
                                                                    <img src={imageObj.imageURL} alt="image" />
                                                                </div>
                                                            ))}
                                                        </Slider>

                                                        :
                                                        <div className="method-image-slide">
                                                            <img src={method.image} alt="image" />
                                                        </div>


                                                    }

                                                
                                            </label>
                                            
                                    
                                            

                                            <div className="crop-pic-check-box">
                                                <div className="form-check">
                                                    <Controller
                                                        defaultValue=""
                                                        control={control}
                                                        name={`method-${method.protectionMethodID}`}
                                                        rules={{ required: false }}
                                                        render={({ field: { onChange, value, ref, name } }) => (
                                                            <>
                                                                <input className="form-check-input" type="checkbox" value={method.protectionMethodID} id={name} onChange={onChange} checked={value} />

                                                            </>
                                                        )}
                                                    />


                                                </div>
                                            </div>


                                            



                                        </div>
                                        <h6>{method.pestName}</h6>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <Button type="submit">Save</Button>
                            </div>
                            </form>
                            : 'No data found'
                        }
                    </> :
                    <Loader />}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default ProtectionMethods;


/*  <div className="col-sm-4">
                            <div className="crop-pic">
                                <a href="crop-protection-add.php"><img src="./images/crop-1.jpg" /></a>
                                <div className="crop-pic-check-box">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked />
                                        <label className="form-check-label" htmlFor="flexCheckChecked"></label>
                                    </div>
                                </div>
                            </div>
                            <h6><a href="crop-protection-add.php">Thrips</a></h6>
                        </div>
                        <div className="col-sm-4">
                            <div className="crop-pic">
                                <a href="crop-protection-add.php"><img src="./images/crop-2.jpg" /></a>
                                <div className="crop-pic-check-box">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="2" id="flexCheckChecked1" checked />
                                        <label className="form-check-label" htmlFor="flexCheckChecked1">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <h6><a href="crop-protection-add.php">Gall Midge</a></h6>
                        </div>
                        <div className="col-sm-4">
                            <div className="crop-pic">
                                <a href="crop-protection-add.php"><img src="./images/crop-3.jpg" /></a>
                                <div className="crop-pic-check-box">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="3" id="flexCheckChecked2" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked2">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <h6><a href="crop-protection-add.php">Brown Spot</a></h6>
                        </div>
                        <div className="col-sm-4">
                            <div className="crop-pic">
                                <a href="crop-protection-add.php"><img src="./images/crop-4.jpg" /></a>
                                <div className="crop-pic-check-box">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="4" id="flexCheckChecked3" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked3">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <h6><a href="crop-protection-add.php">Blast</a></h6>
                        </div>
                        <div className="col-sm-4">
                            <div className="crop-pic">
                                <a href="crop-protection-add.php"><img src="./images/crop-5.jpg" /></a>
                                <div className="crop-pic-check-box">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="5" id="flexCheckChecked4" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked4">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <h6><a href="crop-protection-add.php">Steam Borer</a></h6>
                        </div>
                        <div className="col-sm-4">
                            <div className="crop-pic">
                                <a href="crop-protection-add.php"><img src="./images/crop-6.jpg" /></a>
                                <div className="crop-pic-check-box">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="6" id="flexCheckChecked5" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked5">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <h6><a href="crop-protection-add.php">Rice Armyworm</a></h6>
                        </div>  */