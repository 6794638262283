import React, { useEffect, useState } from "react";
import './RolePermission.scss'
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useMasterApi } from "../../../_common/hooks/api/masterApiHook";
import { useUser } from "../../../_common/hooks/authHook";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import { URLS } from "../../../_config";
import TableLoader from "../../../components/tableLoader";
import Footer from "../../../components/footer/Footer";

const RoleManagementSettings = () => {
    const masterApi = useMasterApi();
    const user: any = useUser()
    const params: any = useParams();
    const [roleId, setRoleId] = useState<any>(params?.id);
    const [permissionList, setPermissionList] = useState<any>(null)
    const navigate: any = useNavigate();


    const getRolePermission = () => {
        masterApi.getRolePermission({ urlParams: { fKRoleID: roleId } }, (message: any, resp: any) => {
            setPermissionList(resp.data.permissionList);
        }, (message: any, resp: any) => {
            setPermissionList([])
        })
    }

    useEffect(() => {
        getRolePermission();
    }, [])

    const onChangeHandler = (type: string, codeId: any) => {
        const newSet = permissionList && permissionList.filter((row: any) => {
            if ((row.code == codeId)) {
                if (type == 'read' && row[type] == true) {
                    row.read = false;
                    row.write = false;
                    row.delete = false;
                } else if (type == 'read' && row[type] == false) {
                    row.read = true;
                    row.write = false;
                    row.delete = false;
                }

                if (type == 'write' && row[type] == true) {
                    row.write = false;
                    row.delete = false;
                } else if (type == 'write' && row[type] == false) {
                    row.read = true;
                    row.write = true;
                }

                if (type == 'delete' && row[type] == true) {
                    row.delete = false;
                } else if (type == 'delete' && row[type] == false) {
                    row.read = true;
                    row.write = true;
                    row.delete = true;
                }
            }
            return row;
        })
        setPermissionList(newSet)
    }

    const submitData = () => {
        let formParam = {
            "permissionList": permissionList,
            "userID": user?.userID,
            "fkRoleID": roleId,
        };

        return new Promise((resolve, reject) => {
            masterApi.rolePermissionSave(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any) => {
                reject(message);
            })
        })
    }

    const submitDataHandler = () => {
        toast.promise(
            submitData(), {
            loading: 'Updating...',
            success: (msg: any) => {
                navigate(URLS.ROLE.LIST)
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={'Permission Management'} breadcrumbs={{
                            'Settings': '#',
                            'Role List': URLS.ROLE.LIST,
                            'Permission Management': '#'
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Permission Management</h2>
                                    </div>


                                    <div className="row role-permission-toggler-wrapper">
                                        {
                                            permissionList && permissionList.length > 0 && permissionList.filter((itemData: any) => itemData?.type == 1).map((item: any) => (
                                                <div className="col-sm-6 mb-4" key={`permission${item.code}`}>
                                                    <div className="d-flex gap-3 align-items-center ">
                                                        <div className="form-check d-flex gap-2">
                                                            <input className="form-check-input wh-20 cursor-pointer" type="checkbox"
                                                                id={`flexCheck${item.code}`} checked={item?.read ? true : false}
                                                                onChange={() => onChangeHandler('read', item.code)} />

                                                            <label className="form-check-label mt-1 mb-0" htmlFor={`flexCheck${item.code}`} >{item?.name}</label>
                                                        </div> 

                                                        <div className="d-flex gap-5 role-permission-toggler">

                                                            <div className="form-check form-switch mb-0">
                                                                <span>R</span>
                                                                <div className="form-switch-wrapper">
                                                                    <input className="form-check-input cursor-pointer" type="checkbox" role="switch"
                                                                        id={`flexSwitchCheckRead${item.code}`} title="Read"
                                                                        onChange={() => onChangeHandler('read', item.code)}
                                                                        checked={item?.read ? true : false}
                                                                        key={`permissionread${item.code}`} />
                                                                </div>
                                                            </div>

                                                            <div className="form-check form-switch mb-0">
                                                                <span>W</span>
                                                                <div className="form-switch-wrapper">
                                                                    <input className="form-check-input cursor-pointer" type="checkbox" role="switch"
                                                                        id={`flexSwitchCheckWrite${item.code}`} title="Write"
                                                                        onChange={() => onChangeHandler('write', item.code)}
                                                                        checked={item?.write ? true : false}
                                                                        key={`permissionwrite${item.code}`} />
                                                                </div>
                                                            </div>


                                                            <div className="form-check form-switch mb-0">
                                                                <span>D</span>
                                                                <div className="form-switch-wrapper">
                                                                    <input className="form-check-input cursor-pointer" type="checkbox" role="switch"
                                                                        id={`flexSwitchCheckDelete${item.code}`} title="Delete"
                                                                        onChange={() => onChangeHandler('delete', item.code)}
                                                                        checked={item?.delete ? true : false}
                                                                        key={`permissiondelete${item.code}`} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            permissionList && permissionList.length > 0 && (
                                                <div className="col-sm-12 d-flex justify-content-end gap-3">
                                                    <Link to={URLS.ROLE.LIST} className="btn btn-dark mr-2">Back</Link>
                                                    <button onClick={submitDataHandler} className="btn btn-primary d-flex align-items-center gap-2 ">Submit</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <TableLoader data={permissionList} />

                                </div>

                            </div>
                        </div>


                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default RoleManagementSettings;