/* for all table loader with no-data view  */
interface props {
    status?: any
}
export default function Status({ status }: props) {
    let _status = status
    if (typeof status == 'boolean') {
        _status = status ? 'Active' : 'Inactive'
    }
    switch (_status?.toLowerCase()) {
        case 'active': return <span className="badge text-bg-success ">{_status}</span>
        case 'inactive': return <span className="badge text-bg-danger ">{_status}</span>
        case 'not assigned': return <span className="badge text-bg-danger ">{_status}</span>
        case 'assigned': return <span className="badge text-bg-success ">{_status}</span>

        case 'upcoming': return <span className="badge text-bg-warning ">{_status}</span>
        case 'completed': return <span className="badge text-bg-success ">{_status}</span>

        case 'verified': return <span className="badge text-bg-success ">{_status}</span>
        case 'not verified': return <span className="badge text-bg-danger ">{_status}</span>
        default:
            return <span className="badge text-bg-primary">{_status}</span>

    }

}