import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel"; 
import { useDeviceApi } from "../../_common/hooks/api/deviceApiHook";
import { Link } from "react-router-dom";
import Icon from "../../components/icon";
import { replaceUrlParams } from "../../_common/functions";
import { URLS } from "../../_config";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import TableLoader from "../../components/tableLoader";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import TitleBar from "../../components/TopBar/TitleBar";
import Status from "../../components/status";
import SearchWithLocation from "../../components/search/SearchWithLocation";


const DeviceList = () => {
    const user: any = useUser()
    const permissions: any = useUserPermissions();
    const deviceapi = useDeviceApi();

    const [devices, setDevices] = useState<any[] | null>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;

    const [searchDistricts, setSearchDistricts] = useState([]);
    const [searchBlocks, setSearchBlocks] = useState([]);
    const [searchGpus, setSearchGpus] = useState([]);
    const [searchWards, setSearchWards] = useState([]);


    const getAllDevices = () => {
        deviceapi.getAllDevices({
            PageIndex: page,
            PageSize: limit,
            SearchText: search,
            districtIDs: searchDistricts.join(),
            BlockIDs: searchBlocks.join(),
            WardIDs: searchWards.join(),
            GPUIDs: searchGpus.join(),
        }, (message: any, resp: any) => {
            console.log('device =>', resp.data);
            setDevices(resp.data.device)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setDevices([])
            setTotal(0)
        })
    }

    const changeDeviceStatus = (devices: any) => {
        return new Promise((resolve, reject) => {
            deviceapi.changeDeviceStatus({
                "deviceId": devices.deviceId,
                "updatedBy": user?.userID,
                "workingStatus": (devices?.workingStatus == 'Enabled') ? 0 : 1,
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => {
                reject(message)
            })
        })
    }

    const devicesStatushandler = (devices: any) => {
        Swal.fire({
            title: 'Confirm Change Status',
            text: `Are you sure you want to change this device status?`,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, Change it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    changeDeviceStatus(devices), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        getAllDevices();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }

    const deleteDevice = (devices: any) => {
        return new Promise((resolve, reject) => {
            deviceapi.deleteDevice({
                "deviceId": devices.deviceId,
                "updatedBy": 1,
                "status": true
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => {
                reject(message)
            })
        })
    }

    const deleteDeviceshandler = (devices: any) => {
        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this Device?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteDevice(devices), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        getAllDevices();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }


    useEffect(() => {
        setDevices(null)
        getAllDevices();
    }, [page, search, searchBlocks, searchWards, searchGpus])




    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'Device Management'} breadcrumbs={{
                            'IoT Device': '#',
                            'Device Management': '#',
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Device List</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            {permissions.menus['IoT Device'].write &&
                                                <Link to={URLS.DEVICE_ADD} className="btn btn-primary d-flex align-items-center gap-2 "><Icon name="plus" size={14}/> Add New</Link>
                                            }
                                        </div>

                                    </div>


                                    <SearchWithLocation
                                        setSearch={setSearch}
                                        setDistricts={setSearchDistricts}
                                        setBlocks={setSearchBlocks}
                                        setGpus={setSearchGpus}
                                        setWards={setSearchWards}
                                    />


                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Device ID</th>
                                                    <th scope="col">Category</th>
                                                    {/* <th scope="col">Serial Number</th> */}
                                                    {/* <th scope="col">Name</th> */}
                                                    <th scope="col">Installation Location</th>
                                                    <th scope="col" className="text-center">Assignment Status</th>
                                                    <th scope="col" className="text-center">Working Status</th>
                                                    <th scope="col" style={{ width: 150 }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    devices?.map(data => (
                                                        <tr key={`device-${data.deviceId}`}>
                                                            <td>{data.deviceUUID}</td>
                                                            <td>{data.deviceTemplate}</td>
                                                            {/* <td>{data.deviceSerialNumber}</td> */}
                                                            {/* <td>{data.name}</td> */}
                                                            <td>{`${data?.districtName}, ${data?.blockName}, ${data?.gpuName}, ${data?.wardName}`}</td>
                                                            <td className="text-center"><Status status={data.assignmentStatus} /></td>


                                                            {/* <td>--</td> */}
                                                            <td className="text-center">
                                                                {
                                                                    (data.fkWorkingStatusValue == 1) &&
                                                                    <div className="badge text-success cursor-pointer" title="Enabled">
                                                                        <Icon name="plug-check" size={18}
                                                                            onClick={() => devicesStatushandler(data)} /></div>
                                                                }
                                                                {
                                                                    (data.fkWorkingStatusValue != 1) &&
                                                                    <div className="badge text-danger cursor-pointer" title="Disabled">
                                                                        <Icon name="plug-times" size={18}
                                                                            onClick={() => devicesStatushandler(data)} /> </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="action-btns">
                                                                    {permissions.menus['IoT Device'].write &&
                                                                        <Link to={replaceUrlParams(URLS.DEVICE_EDIT, { deviceID: data.deviceId })}><Icon name="edit" size={18} /></Link>
                                                                    }

                                                                    {permissions.menus['IoT Device'].delete &&
                                                                        <Icon name="delete" size={18} onClick={() => { deleteDeviceshandler(data) }} />
                                                                    }

                                                                    <Link to={replaceUrlParams(URLS.DEVICE_DETAILS, { id: data.deviceId })}><Icon name="view" size={18} /></Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }




                                            </tbody>
                                        </table>
                                        <TableLoader data={devices} />
                                    </div>
                                    {devices ? <Pagination total={total} page={page} setPage={(n: number) => {
                                        setPage(n)
                                        setDevices(null)
                                    }} pageLimit={limit} /> : null}

                                </div>

                            </div>
                        </div>


                    </div>
                </div>


                <Footer></Footer>
            </div >
        </React.Fragment >
    )
}

export default DeviceList;