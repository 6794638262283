import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { URLS } from "../../_config";
import { Link } from "react-router-dom";
import TitleBar from "../../components/TopBar/TitleBar";
import { useNotificationApi } from "../../_common/hooks/api/notificationApiHook";
import TableLoader from "../../components/tableLoader";
import Pagination from "../../components/pagination/Pagination";
import parse from 'html-react-parser';
import { replaceUrlParams, utcToLocalDateTime } from "../../_common/functions";
import Icon from "../../components/icon";
import Footer from "../../components/footer/Footer";

const InviteFarmerList = () => {
    const notificationAPI = useNotificationApi()
    const [notifications, setNotifications] = useState<any[] | null>(null);

    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;


    const getNotificationList = () => {
        notificationAPI.getNotificationList({
            NotificationType: 2,
            PageIndex: page,
            PageSize: limit,
        }, (message: any, resp: any) => {
            setNotifications(resp.data.notificationList)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setNotifications([])
        })
    }

    useEffect(() => {
        getNotificationList();
    }, [page])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`Invite Farmer List`}
                            breadcrumbs={{
                                'Notification': '#',
                                'Invite Farmer List': '#',
                            }} />

                        <div className="d-flex justify-content-end mb-3">
                            <Link to={URLS.NOTIFICATION.INVITE_FARMERS} className="btn btn-primary d-flex align-items-center gap-2 "><i className="bx bx-plus"></i> Invite Farmer</Link>
                        </div>


                        <div className="row">

                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 ">


                                    {
                                        notifications?.map((data: any) => (
                                            <div className="notification-row" key={`notification-${data?.notificationBroadcastingID}`}>
                                                <div className="d-flex gap-4">
                                                    <div className="notification-date-panel">
                                                        <div className="notification-date">
                                                            <h5>{utcToLocalDateTime(data?.notificationDateTime, 'DD')}</h5>
                                                            <h6>{utcToLocalDateTime(data?.notificationDateTime, 'MMM YY')}</h6>
                                                        </div>
                                                        <p>{utcToLocalDateTime(data?.notificationDateTime, 'hh:mm A')}</p>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h4>{data?.notificationSubject}</h4>
                                                        <p>{parse(data?.notificationMessage ? data?.notificationMessage : '--')}</p>
                                                    </div>
                                                    <div className="float-right">
                                                        <div>
                                                            <Link to={replaceUrlParams(URLS.NOTIFICATION.NOTIFICATION_DETAILS, { NotificationID: data.notificationBroadcastingID }) + "?type=2"} title="Invite Farmers">
                                                                <Icon name="users" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    <TableLoader data={notifications} />
                                </div>
                                {notifications ? <Pagination total={total} page={page} setPage={(n: number) => {
                                    setPage(n)
                                    setNotifications(null)
                                }} pageLimit={limit} /> : null}
                            </div>
                        </div>
                    </div>
                </div>



                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default InviteFarmerList;