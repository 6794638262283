import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import { URLS } from "../../../_config";
import Pagination from "../../../components/pagination/Pagination";
import Footer from "../../../components/footer/Footer";
import { Link } from "react-router-dom";
import Icon from "../../../components/icon";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import { replaceUrlParams, showDate } from "../../../_common/functions";
import { useUser, useUserPermissions } from "../../../_common/hooks/authHook";
import TableLoader from "../../../components/tableLoader";
import { ConfirmAlert } from "../../../_common/confirmAlert";
import Status from "../../../components/status";
import { Form } from "react-bootstrap";
import Search from "../../../components/search/Search";

const CropCategoryList = () => {
    const collateralApi = useCollatralApi()
    const [dataList, setDataList] = useState<any>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;
    const user: any = useUser()
    const permissions: any = useUserPermissions();

    const getCropCategoryList = () => { 
        collateralApi.cropCategoryList({
            PageIndex: page,
            PageSize: limit,
            query: search
        }, (message: any, resp: any) => {
            setDataList(resp.data.cropCategory)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setDataList([])
            setTotal(0)
        })
    }


    const dataItemChangeStatus = (params: any) => {
        return new Promise((resolve, reject) => {
            collateralApi.cropCategoryChangeStatus(params, resolve, reject)
        })
    }

    const dataItemChangeStatusHandler = (el: any, category: any) => {
        if (category?.addedCrops > 0) {
            ConfirmAlert.warning('Unable to perform the operation as this category contains products assigned under it.').then(() => {
            }).catch(() => {
                el.checked = !el.checked
            })
        } else {
            ConfirmAlert.changeStatus('Crop Category', dataItemChangeStatus, {
                "cropCategoryID": category.cropCategoryID,
                "updatedBy": user?.userID,
                "isActive": el.checked,
                "isDeleted": false,
            }).then(() => {
                getCropCategoryList();
            }).catch(() => {
                el.checked = !el.checked
            })
        }

    }


    const deleteDataItem = (dataItem: any) => {
        return new Promise((resolve, reject) => {
            collateralApi.cropCategoryDelete({
                "cropCategoryID": dataItem.cropCategoryID,
                "updatedBy": user?.userID,
                "isDeleted": true,
            }, resolve, reject)
        })
    }

    const deleteDataItemhandler = (dataItem: any) => { 
        if (dataItem?.addedCrops > 0) {
            ConfirmAlert.warning('Unable to perform the operation as this category contains products assigned under it.').then(() => {
            }).catch(() => { })
        } else {
            ConfirmAlert.delete('Crop Category', deleteDataItem, dataItem).then(() => {
                getCropCategoryList();
            })
        }
    }


    useEffect(() => {
        if (dataList != null) {
            setDataList(null)
            getCropCategoryList();
        }
    }, [page, search])
    useEffect(() => {
        getCropCategoryList();
    }, [])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title="Crop Category" breadcrumbs={{
                            'Collateral': '#',
                            'Crop Category': '#',
                        }} />

                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " >
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Crop Category List</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            <Search setSearch={(str: string) => {
                                                setSearch(str)
                                                setPage(1)
                                            }}></Search>
                                            {
                                                permissions.menus['Collateral'].write &&
                                                <Link to={URLS.COLLATERAL.CROP_CATEGORY.ADD} className="btn btn-primary d-flex align-items-center gap-2 "><Icon name="plus" size={16} /> Add New</Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: 80 }}>#</th>
                                                    <th scope="col">Crop Category Name</th>
                                                    <th scope="col">Created Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col" style={{ width: 150 }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataList && dataList.map((dataItem: any, index: number) => (
                                                        <tr key={dataItem.cropCategoryID}>
                                                            <td>{index + 1}</td>
                                                            <td>{dataItem.cropCategoryName}</td>
                                                            <td>{showDate(dataItem.addedDate)}</td>
                                                            <td>
                                                                <Status status={dataItem.isActive} />
                                                            </td>
                                                            <td>
                                                                <div className="action-btns">
                                                                    {
                                                                        permissions.menus['Collateral'].write &&
                                                                        <>
                                                                            <Form.Check // prettier-ignore
                                                                                type="switch"
                                                                                id={`status-switch-${dataItem.cropCategoryID}`}
                                                                                label=""
                                                                                onChange={(e) => { dataItemChangeStatusHandler(e.target, dataItem) }}
                                                                                defaultChecked={dataItem.isActive}
                                                                            />

                                                                            <Link to={replaceUrlParams(URLS.COLLATERAL.CROP_CATEGORY.EDIT, { id: dataItem.cropCategoryID })}><Icon name="edit" size={18} /></Link>
                                                                        </>
                                                                    }


                                                                    {
                                                                        permissions.menus['Collateral'].delete &&
                                                                        <Icon name="delete" size={18}
                                                                            onClick={() => deleteDataItemhandler(dataItem)} />
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <TableLoader data={dataList} />

                                        {
                                            dataList && <Pagination total={total} page={page} setPage={(n: number) => {
                                                setPage(n)
                                            }} pageLimit={limit} />
                                        }
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default CropCategoryList;