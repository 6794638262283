import React, { useEffect, useState } from "react";
import { useMasterApi } from "../../../_common/hooks/api/masterApiHook";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import { Link } from "react-router-dom";
import { URLS } from "../../../_config";
import { replaceUrlParams } from "../../../_common/functions";
import TableLoader from "../../../components/tableLoader";
import Footer from "../../../components/footer/Footer";
import Icon from "../../../components/icon"; 
import Pagination from "../../../components/pagination/Pagination";

const EmailSettingsList = () => {
    const masterApi = useMasterApi();
    const [emailList, setEmailList] = useState<any>(null)

    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;

    const getAllEmailSettings = () => {
        masterApi.getAllEmailSettings({ urlParams: { PageIndex: 1, PageSize: 100 } }, (message: any, resp: any) => {
            setEmailList(resp.data?.emailSenderInformation)
        }, (message: any) => {
            setEmailList([])
        })
    }

    useEffect(() => {
        getAllEmailSettings();
    }, [])

   
    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'Email Settings'} breadcrumbs={{
                            'Settings': '#',
                            'Email Settings': '#'
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Email List</h2> 
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Subject</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col" style={{ width: 150 }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    emailList && emailList?.map((item: any, index: number) => (
                                                        <tr key={`role_${index}`}>
                                                            <td>{item?.subject}</td>
                                                            <td>{item?.emailIDs}</td>
                                                            <td>
                                                                <div className="action-btns">
                                                                    <Link to={replaceUrlParams(URLS.SETTINGS.EMAIL_SETTINGS.EDIT, { id: item.emailSenderInformationID })}><Icon name="edit" size={18} /></Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <TableLoader data={emailList} />
                                        {
                                            emailList && <Pagination total={total} page={page} setPage={(n: number) => {
                                                setPage(n)
                                                setEmailList(null)
                                            }} pageLimit={limit} />
                                        }
                                    </div> 
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default EmailSettingsList;