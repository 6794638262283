import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { URLS } from "../../_config";
import { Link, useParams, useSearchParams } from "react-router-dom";
import TitleBar from "../../components/TopBar/TitleBar";
import { useNotificationApi } from "../../_common/hooks/api/notificationApiHook";
import TableLoader from "../../components/tableLoader";
import Pagination from "../../components/pagination/Pagination";
import Icon from "../../components/icon";
import Footer from "../../components/footer/Footer";

const ManualNotificationDetails = () => {
    const notificationAPI = useNotificationApi()
    const urlParams: any = useParams();
    const NotificationID: any = urlParams.NotificationID;

    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get("type")
    console.log('urlParams', type)

    const [notifications, setNotifications] = useState<any[] | null>(null);

    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;


    const getNotificationDetails = () => {
        notificationAPI.getNotificationDetails({
            NotificationID: NotificationID
        }, (message: any, resp: any) => {
            setNotifications(resp.data.notificationDtlsList)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setNotifications([])
        })
    }

    useEffect(() => {
        getNotificationDetails();
    }, [page])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`Farmer List`}
                            breadcrumbs={{
                                'Notification': '#',
                                [(type === '2') ? 'Invite Farmers' : 'Manual Notifications']: (type === '2') ? URLS.NOTIFICATION.INVITE_FARMERS_LIST : URLS.NOTIFICATION.MANUAL_NOTIFICATION,
                                'Farmer List': '#',
                            }} />


                        <div className="row">

                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 ">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-0">
                                            {
                                                (notifications && notifications?.length > 0) ? notifications[0]?.notificationSubject : 'Farmer List'
                                            }
                                        </h2>
                                        {
                                            (type === '2') ?
                                                <Link to={URLS.NOTIFICATION.INVITE_FARMERS} className="btn btn-primary d-flex align-items-center gap-2 "><i className="bx bx-plus"></i> Invite Farmer</Link>
                                                :
                                                <Link to={URLS.NOTIFICATION.NOTIFICATION_BROADCAST} className="btn btn-primary d-flex align-items-center gap-2 "><i className="bx bx-plus"></i> New Broadcast</Link>
                                        }
                                    </div>


                                    {
                                        notifications?.map((data: any) => (
                                            <div className="notification-row" key={`notification-${data?.aadhaarNumber}`}>
                                                <div className="d-flex gap-4">
                                                    <div className="notification-date-panel">
                                                        <div className="notification-date"><Icon name="user" /></div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5>Farmer Name: {data?.farmerName}</h5>
                                                        <p>
                                                            <span className="p-2"><Icon name="phone" /> {data?.phoneNumber}</span>
                                                            <span className="p-2"><Icon name="aadhaar" /> {data?.aadhaarNumber}</span>
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }

                                    <TableLoader data={notifications} />
                                </div>
                                {notifications ? <Pagination total={total} page={page} setPage={(n: number) => {
                                    setPage(n)
                                    setNotifications(null)
                                }} pageLimit={limit} /> : null}
                            </div>
                        </div>
                    </div>
                </div>



                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default ManualNotificationDetails;