export const API_BASE_URL  = process.env.REACT_APP_MAPAPI_URL
export const STORAGE = "farming-ai"
export const STORAGE_VERSION = "202407221509"


export const URLS = {
    DASHBOARD: "/",
    SENSORS: "/sensors",
    WEATHER: "/weather/:fieldID",
    ANALYTICS: "/analytics",
    FORECAST: "/forecast",
    WEATHER_STATIONS: "/weather_stations",
    SEASON: "/seasons",
    FIELDS: "/fields",
}

export const API_URL = {
    USER: {
        LOGIN: `${API_BASE_URL}/Login/token`,
        FORGET_PASSWORD: `${API_BASE_URL}/Login/ForgotPassword`,
        RESET_PASSWORD: `${API_BASE_URL}/Login/ForgotPasswordReset`,
        PASSWORD_RESET: `${API_BASE_URL}/Login/PasswordReset`,
        REFRESH_TOKEN: `${API_BASE_URL}/Login/RefreshToken`,
    },
    EMPLOYEE: {
        ADD_EMPLOYEE: `${API_BASE_URL}/Employee/Save`,
        UPDATE_EMPLOYEE: `${API_BASE_URL}/Employee/Update`,
        GET_EMPLOYEE: `${API_BASE_URL}/Employee/getAllEmployee`,
        GET_ACTIVE_EMPLOYEE: `${API_BASE_URL}/Employee/getActiveEmployee`,
        GET_EMPLOYEE_DETAILS: `${API_BASE_URL}/Employee/getEmployeeByID/:employeeID`,
        UPDATE_EMPLOYEE_STATUS: `${API_BASE_URL}/Employee/StatusUpdate`,
        DELETE_EMPLOYEE: `${API_BASE_URL}/Employee/AccountStatusUpdate`,


    },
    MASTER: {
        GET_DEPARTMENTS: `${API_BASE_URL}/Departments/getDepartmentsAll/:PageIndex/:PageSize`,
        GET_DESIGNATIONS: `${API_BASE_URL}/Designations/getDesignationsAll/:PageIndex/:PageSize`,
        GET_DISTRICTS: `${API_BASE_URL}/District/getDistrictAll/:PageIndex/:PageSize`,
        GET_GPUS: `${API_BASE_URL}/GPU/getGPUAll/:PageIndex/:PageSize`,
        GET_WARDS: `${API_BASE_URL}/Ward/getWardAll/:PageIndex/:PageSize`,
        GET_BLOCKS: `${API_BASE_URL}/Block/getBlockAll/:PageIndex/:PageSize`,


        ROLE: {
            LIST: `${API_BASE_URL}/Role/getRoleAll/:PageIndex/:PageSize`,
            DETAILS: `${API_BASE_URL}/Role/getRole/:RoleId`,
            SAVE: `${API_BASE_URL}/Role/Save`,
            UPDATE: `${API_BASE_URL}/Role/Update`,
            DELETE: `${API_BASE_URL}/Role/Delete`,

            PERMISSION: `${API_BASE_URL}/RolePermission/GetByRoleID/:fKRoleID`,
            PERMISSION_SAVE: `${API_BASE_URL}/RolePermission/Save`,
        },
        GET_ROLES: `${API_BASE_URL}/Role/getRoleAll/:PageIndex/:PageSize`,

        SETTINGS: {
            EMAIL_SETTINGS: {
                LIST: `${API_BASE_URL}/EmailSenderInformation/EmailSenderInformationGetAll`,
                DETAILS: `${API_BASE_URL}/EmailSenderInformation/EmailSenderInformationGetByID/:id`, 
                UPDATE: `${API_BASE_URL}/EmailSenderInformation/Update`, 
            },
        },
    },

    GROUP: {
        GET_GROUPS: `${API_BASE_URL}/Group/getGroupAll`,
        GET_GROUP_DETAILS: `${API_BASE_URL}/Group/getGroup/:GroupID`,
        SAVE_GROUP: `${API_BASE_URL}/Group/Save`,
        UPDATE_GROUP: `${API_BASE_URL}/Group/Update`,
        DELETE_GROUP: `${API_BASE_URL}/Group/Delete`,
        CHANGE_STATUS_GROUP: `${API_BASE_URL}/Group/StatusUpdate`,

    },

    DEVICE: {
        GET_DEVICES: `${API_BASE_URL}/Device/getDeviceAll`,
        GET_DEVICES_DETAILS: `${API_BASE_URL}/Device/getDevice/:DeviceId`,
        SAVE_DEVICES: `${API_BASE_URL}/Device/Save`,
        UPDATE_DEVICES: `${API_BASE_URL}/Device/Update`,
        DELETE_DEVICES: `${API_BASE_URL}/Device/Delete`,
        CHANGE_DEVICES_STATUS: `${API_BASE_URL}/Device/StatusUpdate`,
        DEVICES_WORKING_STATUS: `${API_BASE_URL}/Device/getAllDeviceWorkingStatus?PageIndex=1&PageSize=999`,


        GET_DEVICES_TEPLATE: `${API_BASE_URL}/DeviceTemplate/getDeviceTemplateAll/:PageIndex/:PageSize`,
        DEVICES_TEMPLATE_DETAILS: `${API_BASE_URL}/DeviceTemplate/getDeviceTemplate/:DeviceTemplateID`,
        DEVICES_TEMPLATE_SAVE: `${API_BASE_URL}/DeviceTemplate/Save`,
        DEVICES_TEMPLATE_UPDATE: `${API_BASE_URL}/DeviceTemplate/Update`,
        DEVICES_TEMPLATE_CHANGE_STATUS: `${API_BASE_URL}/DeviceTemplate/StatusUpdate`,
        DEVICES_TEMPLATE_DELETE: `${API_BASE_URL}/DeviceTemplate/Delete`,

        DEVICES_PARAM_TYPE_ALL: `${API_BASE_URL}/DeviceTemplate/getAllDeviceParamType`,
        DEVICES_UNIT_MASTER_ALL: `${API_BASE_URL}/DeviceTemplate/getAllUnitMaster`,
        DEVICES_PARAM_MASTER_ALL: `${API_BASE_URL}/DeviceTemplate/getAllDeviceParamMaster`,


        CATEGORY_UNIT: {
            LIST: `${API_BASE_URL}/DeviceTemplateParam/getDeviceTemplateParamAll/:PageIndex/:PageSize`,
            DETAILS: `${API_BASE_URL}/DeviceTemplateParam/getDeviceTemplateParam/:DeviceTemplateParamID`,
            SAVE: `${API_BASE_URL}/DeviceTemplateParam/Save`,
            UPDATE: `${API_BASE_URL}/DeviceTemplateParam/Update`,
            CHANGE_STATUS: `${API_BASE_URL}/DeviceTemplateParam/StatusUpdate`,
            DELETE: `${API_BASE_URL}/DeviceTemplateParam/Delete`,
        }
    },

    EVENT: {
        GET_EVENTS: `${API_BASE_URL}/Event/getEventAll`,
        GET_EVENTS_TYPES: `${API_BASE_URL}/Event/GetEventType`,
        GET_EVENTS_ORGANISER: `${API_BASE_URL}/Event/GetOrganizer`,
        GET_EVENT_DETAILS: `${API_BASE_URL}/Event/getEvent/:eventID`,
        SAVE_EVENT: `${API_BASE_URL}/Event/Save`,
        UPDATE_EVENT: `${API_BASE_URL}/Event/Update`,
        DELETE_EVENT: `${API_BASE_URL}/Event/Delete`,
        CHANGE_STATUS_EVENT: `${API_BASE_URL}/Event/StatusUpdate`,
        UPLOAD_EVENT_ATTENDEE: `${API_BASE_URL}/Event/EventFarmerImport`,
        GET_EVENT_FARMER_IMPORT_ALL: `${API_BASE_URL}/Event/getEventFarmerImportAll/:EventID`,
    },

    FARMER: {
        GET_FARMER: `${API_BASE_URL}/Farmer/getFarmerAll`,
        GET_FARMER_DETAILS: `${API_BASE_URL}/Farmer/getFarmer/:FarmerID`,
        CHANGE_STATUS_FARMER: `${API_BASE_URL}/Farmer/StatusUpdate`,
        IMPORT_FARMER: `${API_BASE_URL}/Farmer/FarmerImport`,

    },


    FARMS: {
        LIST: `${API_BASE_URL}/Farm/getFarmAll`,
        DETAILS: `${API_BASE_URL}/Farm/getFarm/:FarmID`,
        SAVE: `${API_BASE_URL}/Farm/Save`,
        UPDATE: `${API_BASE_URL}/Farm/Update`,
        CHANGE_STATUS: `${API_BASE_URL}/Farm/StatusUpdate`,
        DELETE: `${API_BASE_URL}/Farm/Delete`,
        IMPORT_FARMS: `${API_BASE_URL}/Farm/FarmImport`,


        FARMS_CROP_MAPPING_LIST: `${API_BASE_URL}/Farm/getFarmsCropMappingList`,
        FARMS_CROP_MAPPING_SAVE: `${API_BASE_URL}/Farm/FarmCropsMappingSave`,
    },
    DASHBOARD: {
        GET_DEVICE_AREAS: `${API_BASE_URL}/DeviceReport/GetLocationList`,
        GET_AREA_PICTURES: `${API_BASE_URL}/DeviceReport/GetLocationImageList/:LocationID`,
        GET_AREA_CHARTS: `${API_BASE_URL}/DeviceReport/GetDeviceChart/:LocationID`,
        GET_CURRENT_WEATHER_REPORT: `${API_BASE_URL}/DeviceReport/CurrentWeatherReport/:LocationID`,
        GET_WEATHER_HISTORY_REPORT: `${API_BASE_URL}/DeviceReport/WeatherDetailsHistoryReport/:LocationID`
    },
    DEVICE_INSTALLATION_STATION: {
        LIST: `${API_BASE_URL}/DeviceInstallationStation/getDeviceInstallationStationAll`,
        DETAILS: `${API_BASE_URL}/DeviceInstallationStation/getDeviceInstallationStation/:InstallationStationID`,
        SAVE: `${API_BASE_URL}/DeviceInstallationStation/Save`,
        UPDATE: `${API_BASE_URL}/DeviceInstallationStation/Update`,
        CHANGE_STATUS: `${API_BASE_URL}/DeviceInstallationStation/StatusUpdate`,
        DELETE: `${API_BASE_URL}/DeviceInstallationStation/Delete`,

        // /api/v{version}/DeviceInstallationStation/getUnAssignedStationDevice/{InstallationStationID}
        // /api/v{version}/DeviceInstallationStation/getAssignedStationDevice/{InstallationStationID}
        // /api/v{version}/DeviceInstallationStation/SaveStationDevice
        UN_ASSIGNED_STATION_DEVICE: `${API_BASE_URL}/DeviceInstallationStation/getUnAssignedStationDevice/:InstallationStationID`,
        ASSIGNED_STATION_DEVICE: `${API_BASE_URL}/DeviceInstallationStation/getAssignedStationDevice/:InstallationStationID`,
        SAVE_STATION_DEVICE: `${API_BASE_URL}/DeviceInstallationStation/SaveStationDevice`,

        STATION_CROP_ALL: `${API_BASE_URL}/DeviceInstallationStation/getStationCropAll`,
        ASSIGNED_STATION_CROP: `${API_BASE_URL}/DeviceInstallationStation/getAssignedStationCrop/:InstallationStationID`,
        SAVE_STATION_CROP: `${API_BASE_URL}/DeviceInstallationStation/SaveStationCrop`,
        SAVE_STATION_IMAGES: `${API_BASE_URL}/DeviceInstallationStation/SaveStationImage`,

    },


    COLLATERAL: {
        CROP_CATEGORY: {
            LIST: `${API_BASE_URL}/CropCategory/getCropCategoryAll`,
            // LIST: `${API_BASE_URL}/CropCategory/getCropCategoryAll/:PageIndex/:PageSize`,
            DETAILS: `${API_BASE_URL}/CropCategory/getCropCategory/:CropCategoryID`,
            SAVE: `${API_BASE_URL}/CropCategory/Save`,
            UPDATE: `${API_BASE_URL}/CropCategory/Update`,
            CHANGE_STATUS: `${API_BASE_URL}/CropCategory/StatusUpdate`,
            DELETE: `${API_BASE_URL}/CropCategory/Delete`,
        },

        CROP: {
            LIST: `${API_BASE_URL}/Crop/getCropAll`,
            DETAILS: `${API_BASE_URL}/Crop/getCrop/:CropID`,
            SAVE: `${API_BASE_URL}/Crop/Save`,
            UPDATE: `${API_BASE_URL}/Crop/Update`,
            CHANGE_STATUS: `${API_BASE_URL}/Crop/StatusUpdate`,
            DELETE: `${API_BASE_URL}/Crop/Delete`,
            FILE_UPLOAD: `${API_BASE_URL}/Crop/FileUpload`,

            CULTIVATION_METHODS_VIEW: `${API_BASE_URL}/Crop/getCropCultivationMethod/:CropID`,
            CULTIVATION_METHODS_UPDATE: `${API_BASE_URL}/Crop/SaveCropCultivationMethod`,
            PROTECTION_METHODS_VIEW: `${API_BASE_URL}/Crop/getCropProtectionMethod/:CropID`,
            PROTECTION_METHODS_UPDATE: `${API_BASE_URL}/Crop/SaveCropProtectionMethod`,
        },

        CROP_PROTECTION_METHOD: {
            LIST: `${API_BASE_URL}/CropProtectionMethod/getProtectionMethodAll`,
            DETAILS: `${API_BASE_URL}/CropProtectionMethod/getProtectionMethod/:ProtectionMethodID`,
            SAVE: `${API_BASE_URL}/CropProtectionMethod/Save`,
            UPDATE: `${API_BASE_URL}/CropProtectionMethod/Update`,
            CHANGE_STATUS: `${API_BASE_URL}/CropProtectionMethod/StatusUpdate`,
            DELETE: `${API_BASE_URL}/CropProtectionMethod/Delete`,
        },

    },

    REPORT: {
        FARMER_LIST: `${API_BASE_URL}/Farmer/FarmerListReport`,
        FARMER_CONTACT_LIST: `${API_BASE_URL}/Farmer/FarmerListReport`,
        FARM_LIST: `${API_BASE_URL}/Farm/getFarmListReport`,
        FARMERS_TRAINING_ATTENDANCE_LIST: `${API_BASE_URL}/Event/getEventAll`,
    },

    NOTIFICATION: {
        MANUAL_NOTIFICATION: `${API_BASE_URL}/NotificationBroadcasting/NotificationGet`,
        NOTIFICATION_DETAILS: `${API_BASE_URL}/NotificationBroadcasting/NotificationDetailsGet_ByID`,
        NOTIFICATION_BROADCAST: `${API_BASE_URL}/NotificationBroadcasting/ManualNotificationSave`,
        INVITE_FARMERS: `${API_BASE_URL}/Farmer/FarmerListReport`,
        INVITE_FARMERS_STORE: `${API_BASE_URL}/NotificationBroadcasting/InviteFramerNotification`,

    }
}

export const ACTIONS = {
    RESET_REDUCER: "ACTIONS/RESET_REDUCER",
    LOADER: {
        SET_FP_STATUS: "ACTIONS/LOADER/SET_FP_STATUS",
    },


}

export const SAGA_ACTIONS = {
    USER: {
        LOGIN: "SAGA_ACTIONS/USER/LOGIN",
        LOGOUT: "SAGA_ACTIONS/USER/LOGOUT",
        FORGET_PASSWORD: "SAGA_ACTIONS/USER/FORGET_PASSWORD",
        RESET_PASSWORD: "SAGA_ACTIONS/USER/RESET_PASSWORD",
        PASSWORD_RESET: "SAGA_ACTIONS/USER/PASSWORD_RESET",

    },
    EMPLOYEE: {
        ADD_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/ADD_EMPLOYEE",
        UPDATE_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/UPDATE_EMPLOYEE",
        GET_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/GET_EMPLOYEE",
        GET_ACTIVE_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/GET_ACTIVE_EMPLOYEE",
        GET_EMPLOYEE_DETAILS: "SAGA_ACTIONS/EMPLOYEE/GET_EMPLOYEE_DETAILS",
        UPDATE_EMPLOYEE_STATUS: "SAGA_ACTIONS/EMPLOYEE/UPDATE_EMPLOYEE_STATUS",
        DELETE_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/DELETE_EMPLOYEE",


    },

    MASTER: {
        GET_DEPARTMENTS: `SAGA_ACTIONS/MASTER/GET_DEPARTMENTS`,
        GET_DESIGNATIONS: `SAGA_ACTIONS/MASTER/GET_DESIGNATIONS`,
        GET_DISTRICTS: `SAGA_ACTIONS/MASTER/GET_DISTRICTS`,
        GET_GPUS: `SAGA_ACTIONS/MASTER/GET_GPUS`,
        GET_WARDS: `SAGA_ACTIONS/MASTER/GET_WARDS`,
        GET_BLOCKS: `SAGA_ACTIONS/MASTER/GET_BLOCKS`,

        ROLE: {
            LIST: `SAGA_ACTIONS/MASTER/ROLE/LIST`,
            DETAILS: `SAGA_ACTIONS/MASTER/ROLE/DETAILS`,
            SAVE: `SAGA_ACTIONS/MASTER/ROLE/SAVE`,
            UPDATE: `SAGA_ACTIONS/MASTER/ROLE/UPDATE`,
            DELETE: `SAGA_ACTIONS/MASTER/ROLE/DELETE`,

            PERMISSION: `SAGA_ACTIONS/MASTER/ROLE/PERMISSION`,
            PERMISSION_SAVE: `SAGA_ACTIONS/MASTER/ROLE/PERMISSION_SAVE`,
        },
        GET_ROLES: `SAGA_ACTIONS/MASTER/GET_ROLES`,
        SETTINGS: {
            EMAIL_SETTINGS: {
                LIST: `SAGA_ACTIONS/MASTER/SETTINGS/EMAIL_SETTINGS/LIST`, 
                DETAILS: `SAGA_ACTIONS/MASTER/SETTINGS/EMAIL_SETTINGS/DETAILS`, 
                UPDATE: `SAGA_ACTIONS/MASTER/SETTINGS/EMAIL_SETTINGS/UPDATE`, 
            },
        },
    },

    GROUP: {
        GET_GROUPS: `SAGA_ACTIONS/GROUP/GET_GROUPS`,
        GET_GROUP_DETAILS: `SAGA_ACTIONS/GROUP/GET_GROUP_DETAILS`,
        SAVE_GROUP: `SAGA_ACTIONS/GROUP/SAVE_GROUP`,
        UPDATE_GROUP: `SAGA_ACTIONS/GROUP/UPDATE_GROUP`,
        DELETE_GROUP: `SAGA_ACTIONS/GROUP/DELETE_GROUP`,
        CHANGE_STATUS_GROUP: `SAGA_ACTIONS/GROUP/CHANGE_STATUS_GROUP`,
    },

    EVENT: {
        GET_EVENTS: `SAGA_ACTIONS/EVENT/GET_EVENTS`,
        GET_EVENTS_TYPES: `SAGA_ACTIONS/EVENT/GET_EVENTS_TYPES`,
        GET_EVENTS_ORGANISER: `SAGA_ACTIONS/EVENT/GET_EVENTS_ORGANISER`,
        GET_EVENT_DETAILS: `SAGA_ACTIONS/EVENT/GET_EVENT_DETAILS`,
        SAVE_EVENT: `SAGA_ACTIONS/EVENT/SAVE_EVENT`,
        UPDATE_EVENT: `SAGA_ACTIONS/EVENT/UPDATE_EVENT`,
        DELETE_EVENT: `SAGA_ACTIONS/EVENT/DELETE_EVENT`,
        CHANGE_STATUS_EVENT: `SAGA_ACTIONS/EVENT/CHANGE_STATUS_EVENT`,
        UPLOAD_EVENT_ATTENDEE: `SAGA_ACTIONS/EVENT/UPLOAD_EVENT_ATTENDEE`,
        GET_EVENT_FARMER_IMPORT_ALL: `SAGA_ACTIONS/EVENT/GET_EVENT_FARMER_IMPORT_ALL`,
    },

    FARMER: {
        GET_FARMER: `SAGA_ACTIONS/FARMER/GET_FARMER`,
        GET_FARMER_DETAILS: `SAGA_ACTIONS/FARMER/GET_FARMER_DETAILS`,
        CHANGE_STATUS_FARMER: `SAGA_ACTIONS/FARMER/CHANGE_STATUS_FARMER`,
        IMPORT_FARMER: `SAGA_ACTIONS/FARMER/IMPORT_FARMER`,
    },

    FARMS: {
        LIST: `SAGA_ACTIONS/FARMS/LIST`,
        DETAILS: `SAGA_ACTIONS/FARMS/DETAILS`,
        SAVE: `SAGA_ACTIONS/FARMS/SAVE`,
        UPDATE: `SAGA_ACTIONS/FARMS/UPDATE`,
        CHANGE_STATUS: `SAGA_ACTIONS/FARMS/CHANGE_STATUS`,
        DELETE: `SAGA_ACTIONS/FARMS/DELETE`,
        IMPORT_FARMS: `SAGA_ACTIONS/FARMS/IMPORT_FARMS`,

        FARMS_CROP_MAPPING_LIST: `SAGA_ACTIONS/FARMS/FARMS_CROP_MAPPING_LIST`,
        FARMS_CROP_MAPPING_SAVE: `SAGA_ACTIONS/FARMS/FARMS_CROP_MAPPING_SAVE`,
    },
    DASHBOARD: {
        GET_DEVICE_AREAS: `SAGA_ACTIONS/DASHBOARD/GET_DEVICE_AREAS`,
        GET_AREA_PICTURES: `SAGA_ACTIONS/DASHBOARD/GET_AREA_PICTURES`,
        GET_AREA_CHARTS: `SAGA_ACTIONS/DASHBOARD/GET_AREA_CHARTS`,
        GET_AREA_INFORMATIONS: `SAGA_ACTIONS/DASHBOARD/GET_AREA_INFORMATIONS`,

        GET_CURRENT_WEATHER_REPORT: `SAGA_ACTIONS/DASHBOARD/GET_CURRENT_WEATHER_REPORT`,
        GET_WEATHER_HISTORY_REPORT: `SAGA_ACTIONS/DASHBOARD/GET_WEATHER_HISTORY_REPORT`,

    },
    DEVICE_INSTALLATION_STATION: {
        LIST: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/LIST`,
        DETAILS: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/DETAILS`,
        SAVE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/SAVE`,
        UPDATE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/UPDATE`,
        CHANGE_STATUS: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/CHANGE_STATUS`,
        DELETE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/DELETE`,

        UN_ASSIGNED_STATION_DEVICE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/UN_ASSIGNED_STATION_DEVICE`,
        ASSIGNED_STATION_DEVICE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/ASSIGNED_STATION_DEVICE`,
        SAVE_STATION_DEVICE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/SAVE_STATION_DEVICE`,

        STATION_CROP_ALL: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/STATION_CROP_ALL`,
        ASSIGNED_STATION_CROP: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/ASSIGNED_STATION_CROP`,
        SAVE_STATION_CROP: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/SAVE_STATION_CROP`,
        SAVE_STATION_IMAGES: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/SAVE_STATION_IMAGES`,
    },
    DEVICE: {
        GET_DEVICES: `SAGA_ACTIONS/DEVICE/GET_DEVICES`,
        GET_DEVICES_DETAILS: `SAGA_ACTIONS/DEVICE/GET_DEVICES_DETAILS`,
        SAVE_DEVICES: `SAGA_ACTIONS/DEVICE/SAVE_DEVICES`,
        UPDATE_DEVICES: `SAGA_ACTIONS/DEVICE/UPDATE_DEVICES`,
        DELETE_DEVICES: `SAGA_ACTIONS/DEVICE/DELETE_DEVICES`,
        CHANGE_DEVICES_STATUS: `SAGA_ACTIONS/DEVICE/CHANGE_DEVICES_STATUS`,
        DEVICES_WORKING_STATUS: `SAGA_ACTIONS/DEVICE/DEVICES_WORKING_STATUS`,

        GET_DEVICES_TEPLATE: `SAGA_ACTIONS/DEVICE/GET_DEVICES_TEPLATE`,
        DEVICES_TEMPLATE_DETAILS: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_DETAILS`,
        DEVICES_TEMPLATE_SAVE: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_SAVE`,
        DEVICES_TEMPLATE_UPDATE: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_UPDATE`,
        DEVICES_TEMPLATE_CHANGE_STATUS: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_CHANGE_STATUS`,
        DEVICES_TEMPLATE_DELETE: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_DELETE`,
        DEVICES_PARAM_TYPE_ALL: `SAGA_ACTIONS/DEVICE/DEVICES_PARAM_TYPE_ALL`,
        DEVICES_UNIT_MASTER_ALL: `SAGA_ACTIONS/DEVICE/DEVICES_UNIT_MASTER_ALL`,
        DEVICES_PARAM_MASTER_ALL: `SAGA_ACTIONS/DEVICE/DEVICES_PARAM_MASTER_ALL`,


        CATEGORY_UNIT: {
            LIST: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/LIST`,
            DETAILS: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/DETAILS`,
            SAVE: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/SAVE`,
            UPDATE: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/UPDATE`,
            CHANGE_STATUS: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/CHANGE_STATUS`,
            DELETE: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/DELETE`,
        }
    },


    COLLATERAL: {
        CROP_CATEGORY: {
            LIST: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/LIST`,
            DETAILS: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/DETAILS`,
            SAVE: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/SAVE`,
            UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/UPDATE`,
            CHANGE_STATUS: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/CHANGE_STATUS`,
            DELETE: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/DELETE`,
        },

        CROP: {
            LIST: `SAGA_ACTIONS/COLLATERAL/CROP/LIST`,
            DETAILS: `SAGA_ACTIONS/COLLATERAL/CROP/DETAILS`,
            SAVE: `SAGA_ACTIONS/COLLATERAL/CROP/SAVE`,
            UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP/UPDATE`,
            CHANGE_STATUS: `SAGA_ACTIONS/COLLATERAL/CROP/CHANGE_STATUS`,
            DELETE: `SAGA_ACTIONS/COLLATERAL/CROP/DELETE`,
            FILE_UPLOAD: `SAGA_ACTIONS/COLLATERAL/CROP/FILE_UPLOAD`,

            CULTIVATION_METHODS_VIEW: `SAGA_ACTIONS/COLLATERAL/CROP/CULTIVATION_METHODS_VIEW`,
            CULTIVATION_METHODS_UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP/CULTIVATION_METHODS_UPDATE`,
            PROTECTION_METHODS_VIEW: `SAGA_ACTIONS/COLLATERAL/CROP/PROTECTION_METHODS_VIEW`,
            PROTECTION_METHODS_UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP/PROTECTION_METHODS_UPDATE`,

        },

        CROP_PROTECTION_METHOD: {
            LIST: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/LIST`,
            DETAILS: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/DETAILS`,
            SAVE: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/SAVE`,
            UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/UPDATE`,
            CHANGE_STATUS: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/CHANGE_STATUS`,
            DELETE: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/DELETE`,
        },

    },


    REPORT: {
        FARMER_LIST: `SAGA_ACTIONS/REPORT/FARMER_LIST`,
        FARMER_CONTACT_LIST: `SAGA_ACTIONS/REPORT/FARMER_CONTACT_LIST`,
        FARM_LIST: `SAGA_ACTIONS/REPORT/FARM_LIST`,
        FARMERS_TRAINING_ATTENDANCE_LIST: `SAGA_ACTIONS/REPORT/FARMERS_TRAINING_ATTENDANCE_LIST`,
    },

    NOTIFICATION: {
        MANUAL_NOTIFICATION: `SAGA_ACTIONS/NOTIFICATION/MANUAL_NOTIFICATION`,
        NOTIFICATION_DETAILS: `SAGA_ACTIONS/NOTIFICATION/NOTIFICATION_DETAILS`,
        NOTIFICATION_BROADCAST: `SAGA_ACTIONS/NOTIFICATION/NOTIFICATION_BROADCAST`,

        INVITE_FARMERS: `SAGA_ACTIONS/NOTIFICATION/INVITE_FARMERS`,
        INVITE_FARMERS_STORE: `SAGA_ACTIONS/NOTIFICATION/INVITE_FARMERS_STORE`,
    }




}

export const DATA = [
    {
        "date": "2024-07-19 15:53:09.251267",
        "air_temp": 22.49080237694725,
        "humidity": 72.5573792440837,
        "rainfall": 1,
        "wind_speed": 4.879906047049445,
        "soil_moisture": 32.065621173807436,
        "ndvi": 0.6620534187524492,
        "soil_temp": 17.359145324676092,
        "soil_humidity": 31.039291749031214
    },
    {
        "date": "2024-07-19 16:53:09.251267",
        "air_temp": 34.01428612819832,
        "humidity": 71.04642837392156,
        "rainfall": 0.5,
        "wind_speed": 19.33468455070216,
        "soil_moisture": 29.913748718243323,
        "ndvi": 0.4773970984755696,
        "soil_temp": 13.250299655580891,
        "soil_humidity": 36.11223237273133
    },
    {
        "date": "2024-07-19 17:53:09.251267",
        "air_temp": 29.6398788362281,
        "humidity": 57.61874733048272,
        "rainfall": 0,
        "wind_speed": 19.162762396089924,
        "soil_moisture": 13.401612866577176,
        "ndvi": 0.6284522136110106,
        "soil_temp": 12.545851596218094,
        "soil_humidity": 55.07414485014134
    },
    {
        "date": "2024-07-19 18:53:09.251267",
        "air_temp": 26.973169683940732,
        "humidity": 82.072377654238,
        "rainfall": 0.5,
        "wind_speed": 4.952632861428774,
        "soil_moisture": 31.61848871991145,
        "ndvi": 0.6415612351624673,
        "soil_temp": 10.00236616867085,
        "soil_humidity": 32.94473046134727
    },
    {
        "date": "2024-07-19 19:53:09.251267",
        "air_temp": 18.12037280884873,
        "humidity": 63.56435836990823,
        "rainfall": 0,
        "wind_speed": 6.96103910954641,
        "soil_moisture": 25.00915469063544,
        "ndvi": 0.28296531660668517,
        "soil_temp": 20.773569924719265,
        "soil_humidity": 37.58785459400513
    },
    {
        "date": "2024-07-19 20:53:09.251267",
        "air_temp": 18.119890406724053,
        "humidity": 88.95524690075773,
        "rainfall": 0.5,
        "wind_speed": 17.9111148174777,
        "soil_moisture": 36.82298608318477,
        "ndvi": 0.7797068149007222,
        "soil_temp": 11.666458132689858,
        "soil_humidity": 43.51415542781215
    },
    {
        "date": "2024-07-19 21:53:09.251267",
        "air_temp": 16.161672243363988,
        "humidity": 71.70710691478683,
        "rainfall": 0,
        "wind_speed": 19.33244441792215,
        "soil_moisture": 37.59612802391628,
        "ndvi": 0.6926191682578586,
        "soil_temp": 13.657586495439107,
        "soil_humidity": 33.11480257225465
    },
    {
        "date": "2024-07-19 22:53:09.251267",
        "air_temp": 32.3235229154987,
        "humidity": 46.313237078225136,
        "rainfall": 0.5,
        "wind_speed": 8.868782056720882,
        "soil_moisture": 11.654697718053564,
        "ndvi": 0.3226900767174941,
        "soil_temp": 14.4681449728419,
        "soil_humidity": 37.66797084646538
    },
    {
        "date": "2024-07-19 23:53:09.251267",
        "air_temp": 27.022300234864176,
        "humidity": 73.80887810377169,
        "rainfall": 0,
        "wind_speed": 6.419940912817957,
        "soil_moisture": 34.619246810634365,
        "ndvi": 0.31169902144764217,
        "soil_temp": 18.405697902051525,
        "soil_humidity": 66.45079155934044
    },
    {
        "date": "2024-07-20 00:53:09.251267",
        "air_temp": 29.16145155592091,
        "humidity": 56.25520077158495,
        "rainfall": 2,
        "wind_speed": 10.521880265030841,
        "soil_moisture": 11.130921712680118,
        "ndvi": 0.2753966873477497,
        "soil_temp": 15.96935915943654,
        "soil_humidity": 30.77196528907939
    },
    {
        "date": "2024-07-20 01:53:09.251267",
        "air_temp": 15.411689885916049,
        "humidity": 74.31635983430488,
        "rainfall": 0.5,
        "wind_speed": 13.755384127314695,
        "soil_moisture": 21.01508877085068,
        "ndvi": 0.2830271375251511,
        "soil_temp": 20.120109880347428,
        "soil_humidity": 56.13727696697448
    },
    {
        "date": "2024-07-20 02:53:09.251267",
        "air_temp": 34.39819704323989,
        "humidity": 43.48205740812028,
        "rainfall": 0.5,
        "wind_speed": 5.469490307499596,
        "soil_moisture": 26.268999268632374,
        "ndvi": 0.6488970794172177,
        "soil_temp": 10.834579889930541,
        "soil_humidity": 45.3763572366074
    },
    {
        "date": "2024-07-20 03:53:09.251267",
        "air_temp": 31.648852816008436,
        "humidity": 48.744071858797966,
        "rainfall": 1,
        "wind_speed": 14.729463492381456,
        "soil_moisture": 14.443023958813823,
        "ndvi": 0.48179255417097416,
        "soil_temp": 11.859087913966592,
        "soil_humidity": 56.90594957729485
    },
    {
        "date": "2024-07-20 04:53:09.251267",
        "air_temp": 19.246782213565524,
        "humidity": 82.78686294528931,
        "rainfall": 1,
        "wind_speed": 1.0331471422928207,
        "soil_moisture": 32.3894143770723,
        "ndvi": 0.6697605036439205,
        "soil_temp": 17.488172552999,
        "soil_humidity": 53.54766881453618
    },
    {
        "date": "2024-07-20 05:53:09.251267",
        "air_temp": 18.636499344142013,
        "humidity": 51.358977851175744,
        "rainfall": 0,
        "wind_speed": 0.9858898552883288,
        "soil_moisture": 10.250641125164158,
        "ndvi": 0.6477529366101233,
        "soil_temp": 15.054471817702447,
        "soil_humidity": 38.93995017418577
    },
    {
        "date": "2024-07-20 06:53:09.251267",
        "air_temp": 18.668090197068675,
        "humidity": 81.852056262147,
        "rainfall": 0.5,
        "wind_speed": 7.725754102910905,
        "soil_moisture": 15.057971453021105,
        "ndvi": 0.5627676281031331,
        "soil_temp": 14.437200441270395,
        "soil_humidity": 63.88837950293554
    },
    {
        "date": "2024-07-20 07:53:09.251267",
        "air_temp": 21.084844859190753,
        "humidity": 53.96380442272052,
        "rainfall": 0,
        "wind_speed": 7.069463841214412,
        "soil_moisture": 27.42390489560427,
        "ndvi": 0.6118199639472077,
        "soil_temp": 11.005421185562842,
        "soil_humidity": 49.67761530437427
    },
    {
        "date": "2024-07-20 08:53:09.251267",
        "air_temp": 25.495128632644757,
        "humidity": 72.14409550293539,
        "rainfall": 2,
        "wind_speed": 6.312664307343045,
        "soil_moisture": 31.431626861118772,
        "ndvi": 0.2110134346274851,
        "soil_temp": 23.435787621220022,
        "soil_humidity": 62.90144075653887
    },
    {
        "date": "2024-07-20 09:53:09.251267",
        "air_temp": 23.638900372842315,
        "humidity": 74.70752867549155,
        "rainfall": 1,
        "wind_speed": 9.8203952533782,
        "soil_moisture": 37.49929308081748,
        "ndvi": 0.3870665880830132,
        "soil_temp": 20.37727879187576,
        "soil_humidity": 46.24050144752877
    },
    {
        "date": "2024-07-20 10:53:09.251267",
        "air_temp": 20.824582803960837,
        "humidity": 65.63273765725265,
        "rainfall": 0,
        "wind_speed": 6.414671646231424,
        "soil_moisture": 31.164899558038716,
        "ndvi": 0.5826933075556462,
        "soil_temp": 11.824272352294741,
        "soil_humidity": 69.38742215660956
    },
    {
        "date": "2024-07-20 11:53:09.251267",
        "air_temp": 27.237057894447588,
        "humidity": 55.26553383483656,
        "rainfall": 0,
        "wind_speed": 3.8451583693618385,
        "soil_moisture": 30.153378107645977,
        "ndvi": 0.20560697209174653,
        "soil_temp": 10.975689366057109,
        "soil_humidity": 60.81957434005185
    },
    {
        "date": "2024-07-20 12:53:09.251267",
        "air_temp": 17.789877213040835,
        "humidity": 50.632218818554804,
        "rainfall": 1,
        "wind_speed": 6.189390981772018,
        "soil_moisture": 21.83023439051993,
        "ndvi": 0.21978616620955604,
        "soil_temp": 24.623088001468645,
        "soil_humidity": 68.63800820450294
    },
    {
        "date": "2024-07-20 13:53:09.251267",
        "air_temp": 20.842892970704362,
        "humidity": 41.659467728121314,
        "rainfall": 0.5,
        "wind_speed": 19.4980854562047,
        "soil_moisture": 10.038532765804042,
        "ndvi": 0.7111327894822299,
        "soil_temp": 20.21758825659257,
        "soil_humidity": 50.57305915639435
    },
    {
        "date": "2024-07-20 14:53:09.251267",
        "air_temp": 22.327236865873836,
        "humidity": 55.19732006203619,
        "rainfall": 0,
        "wind_speed": 8.222268339896972,
        "soil_moisture": 13.604777762068657,
        "ndvi": 0.6104149170152431,
        "soil_temp": 16.261841066406124,
        "soil_humidity": 48.94764529162802
    },
    {
        "date": "2024-07-20 15:53:09.251267",
        "air_temp": 24.12139968434072,
        "humidity": 72.65813952656416,
        "rainfall": 1,
        "wind_speed": 8.894255537166716,
        "soil_moisture": 26.036636342681174,
        "ndvi": 0.3592218398722064,
        "soil_temp": 20.525965611336773,
        "soil_humidity": 60.21815237301031
    },
    {
        "date": "2024-07-20 16:53:09.251267",
        "air_temp": 30.703519227860273,
        "humidity": 86.91524092242413,
        "rainfall": 0.5,
        "wind_speed": 11.5308997990228,
        "soil_moisture": 30.25389657533259,
        "ndvi": 0.6127777040712254,
        "soil_temp": 21.344976797202385,
        "soil_humidity": 65.2116505205372
    },
    {
        "date": "2024-07-20 17:53:09.251267",
        "air_temp": 18.993475643167194,
        "humidity": 83.5602241131526,
        "rainfall": 2,
        "wind_speed": 19.66681536828453,
        "soil_moisture": 22.523448516893875,
        "ndvi": 0.6754740014835581,
        "soil_temp": 21.69943303583169,
        "soil_humidity": 68.56583183462232
    },
    {
        "date": "2024-07-20 18:53:09.251267",
        "air_temp": 25.284688768272233,
        "humidity": 78.30323882676512,
        "rainfall": 1,
        "wind_speed": 4.83202552871526,
        "soil_moisture": 31.920370345851705,
        "ndvi": 0.38407656986606076,
        "soil_temp": 17.168159584795866,
        "soil_humidity": 42.98207624129999
    },
    {
        "date": "2024-07-20 19:53:09.251267",
        "air_temp": 26.848291377240848,
        "humidity": 79.42236716266493,
        "rainfall": 0,
        "wind_speed": 13.625642210648676,
        "soil_moisture": 25.655868859965363,
        "ndvi": 0.22162242649951247,
        "soil_temp": 16.44824137187272,
        "soil_humidity": 61.948182953705555
    },
    {
        "date": "2024-07-20 20:53:09.251267",
        "air_temp": 15.929008254399955,
        "humidity": 73.24924517379976,
        "rainfall": 2,
        "wind_speed": 14.319693870834483,
        "soil_moisture": 12.225285867829367,
        "ndvi": 0.7596674748126409,
        "soil_temp": 10.165421264033505,
        "soil_humidity": 38.42582390542147
    },
    {
        "date": "2024-07-20 21:53:09.251267",
        "air_temp": 27.150897038028766,
        "humidity": 53.01434287830015,
        "rainfall": 0.5,
        "wind_speed": 12.146488335861319,
        "soil_moisture": 15.760487529038464,
        "ndvi": 0.4071079029773783,
        "soil_temp": 23.89835672712757,
        "soil_humidity": 51.82297386981781
    },
    {
        "date": "2024-07-20 22:53:09.251267",
        "air_temp": 18.41048247374583,
        "humidity": 85.3597515352915,
        "rainfall": 0.5,
        "wind_speed": 1.7715667927328815,
        "soil_moisture": 35.19994669731829,
        "ndvi": 0.26388905797082385,
        "soil_temp": 19.049618175834148,
        "soil_humidity": 37.19392593636797
    },
    {
        "date": "2024-07-20 23:53:09.251267",
        "air_temp": 16.30103185970559,
        "humidity": 73.53661559529169,
        "rainfall": 1,
        "wind_speed": 13.537445516551529,
        "soil_moisture": 31.747382398733183,
        "ndvi": 0.49511412283588846,
        "soil_temp": 13.047309057849521,
        "soil_humidity": 43.89589447001356
    },
    {
        "date": "2024-07-21 00:53:09.251267",
        "air_temp": 33.977710745066666,
        "humidity": 68.02203154902199,
        "rainfall": 1,
        "wind_speed": 1.2818942207235096,
        "soil_moisture": 36.842859610374845,
        "ndvi": 0.6610806773984803,
        "soil_temp": 23.79405129132155,
        "soil_humidity": 67.25433468825837
    },
    {
        "date": "2024-07-21 01:53:09.251267",
        "air_temp": 34.31264066149119,
        "humidity": 45.54947346067405,
        "rainfall": 1,
        "wind_speed": 11.922852990997939,
        "soil_moisture": 13.526717473628096,
        "ndvi": 0.3329883887705355,
        "soil_temp": 23.723978526554447,
        "soil_humidity": 35.843627990923444
    },
    {
        "date": "2024-07-21 02:53:09.251267",
        "air_temp": 31.16794696232922,
        "humidity": 62.35277719032127,
        "rainfall": 0.5,
        "wind_speed": 14.791623544434852,
        "soil_moisture": 39.706676038452215,
        "ndvi": 0.36169403008001805,
        "soil_temp": 24.837762465491437,
        "soil_humidity": 55.29603580540786
    },
    {
        "date": "2024-07-21 03:53:09.251267",
        "air_temp": 21.092275383467413,
        "humidity": 63.01791356009632,
        "rainfall": 2,
        "wind_speed": 4.219397812399008,
        "soil_moisture": 16.28220141849801,
        "ndvi": 0.34562899207914993,
        "soil_temp": 23.110848447084685,
        "soil_humidity": 53.71817091709613
    },
    {
        "date": "2024-07-21 04:53:09.251267",
        "air_temp": 16.95344228012768,
        "humidity": 83.2281804761335,
        "rainfall": 0.5,
        "wind_speed": 16.832440150855483,
        "soil_moisture": 10.35339628859926,
        "ndvi": 0.7950038316990069,
        "soil_temp": 24.96676581730097,
        "soil_humidity": 34.82953410835946
    },
    {
        "date": "2024-07-21 05:53:09.251267",
        "air_temp": 28.684660530243136,
        "humidity": 67.33241100938943,
        "rainfall": 0,
        "wind_speed": 0.6070761106600853,
        "soil_moisture": 39.92268817160662,
        "ndvi": 0.31844224023832557,
        "soil_temp": 13.670292821849088,
        "soil_humidity": 43.039161750752484
    },
    {
        "date": "2024-07-21 06:53:09.251267",
        "air_temp": 23.803049874792027,
        "humidity": 59.02002744852048,
        "rainfall": 0.5,
        "wind_speed": 16.87625727316612,
        "soil_moisture": 18.432216563353393,
        "ndvi": 0.5453121301796744,
        "soil_temp": 22.633743777988514,
        "soil_humidity": 52.51926018061063
    },
    {
        "date": "2024-07-21 07:53:09.251267",
        "air_temp": 17.440764696895577,
        "humidity": 88.84001642553297,
        "rainfall": 0,
        "wind_speed": 9.206581012855303,
        "soil_moisture": 30.040583575376797,
        "ndvi": 0.7551587849941788,
        "soil_temp": 22.345424631936467,
        "soil_humidity": 37.48714435953961
    },
    {
        "date": "2024-07-21 08:53:09.251267",
        "air_temp": 24.903538202225405,
        "humidity": 45.536029666315784,
        "rainfall": 2,
        "wind_speed": 4.35175879863289,
        "soil_moisture": 15.300469425289156,
        "ndvi": 0.2881379413266676,
        "soil_temp": 13.464105388311006,
        "soil_humidity": 62.024946256512614
    },
    {
        "date": "2024-07-21 09:53:09.251267",
        "air_temp": 15.687770422304368,
        "humidity": 61.127421415597226,
        "rainfall": 1,
        "wind_speed": 2.67556289987936,
        "soil_moisture": 27.406855965974252,
        "ndvi": 0.7825125187206203,
        "soil_temp": 11.476563235014861,
        "soil_humidity": 68.88626038139
    },
    {
        "date": "2024-07-21 10:53:09.251267",
        "air_temp": 33.186408041575646,
        "humidity": 42.10123375857027,
        "rainfall": 1,
        "wind_speed": 19.06614335164745,
        "soil_moisture": 37.121049593636776,
        "ndvi": 0.7684863406373748,
        "soil_temp": 24.691307705163993,
        "soil_humidity": 38.590375998498885
    },
    {
        "date": "2024-07-21 11:53:09.251267",
        "air_temp": 20.17559963200034,
        "humidity": 76.99520603834651,
        "rainfall": 0.5,
        "wind_speed": 1.4533117516231342,
        "soil_moisture": 19.605435022853158,
        "ndvi": 0.7307431922349577,
        "soil_temp": 14.440368272809156,
        "soil_humidity": 37.476478591623035
    },
    {
        "date": "2024-07-21 12:53:09.251267",
        "air_temp": 28.25044568707964,
        "humidity": 85.90382760995753,
        "rainfall": 1,
        "wind_speed": 3.9818683987185133,
        "soil_moisture": 30.107619281330777,
        "ndvi": 0.32873391279800135,
        "soil_temp": 23.06337685701709,
        "soil_humidity": 48.197402871897786
    },
    {
        "date": "2024-07-21 13:53:09.251267",
        "air_temp": 21.23422152178822,
        "humidity": 54.00186647752447,
        "rainfall": 2,
        "wind_speed": 6.956446948660919,
        "soil_moisture": 15.637107663952495,
        "ndvi": 0.58043303360659,
        "soil_temp": 10.061907320119058,
        "soil_humidity": 59.91061108560129
    },
    {
        "date": "2024-07-21 14:53:09.251267",
        "air_temp": 25.401360423556216,
        "humidity": 82.91708383995604,
        "rainfall": 1,
        "wind_speed": 12.40469697922241,
        "soil_moisture": 28.74386196156176,
        "ndvi": 0.6565348837767442,
        "soil_temp": 10.315372108294033,
        "soil_humidity": 41.264024409410055
    },
    {
        "date": "2024-07-21 15:53:09.251267",
        "air_temp": 25.934205586865595,
        "humidity": 54.610911523094885,
        "rainfall": 0.5,
        "wind_speed": 10.896021705521967,
        "soil_moisture": 20.093510554525302,
        "ndvi": 0.7916167569178578,
        "soil_temp": 16.988069630445526,
        "soil_humidity": 30.795196347891217
    },
    {
        "date": "2024-07-21 16:53:09.251267",
        "air_temp": 18.69708911051054,
        "humidity": 85.53850808219576,
        "rainfall": 2,
        "wind_speed": 2.5128698482462797,
        "soil_moisture": 16.33624775763855,
        "ndvi": 0.30825429324882225,
        "soil_temp": 13.580720259025954,
        "soil_humidity": 51.64882512131576
    },
    {
        "date": "2024-07-21 17:53:09.251267",
        "air_temp": 34.39169255529117,
        "humidity": 77.6980951798392,
        "rainfall": 0,
        "wind_speed": 5.794559674764292,
        "soil_moisture": 19.395461521109056,
        "ndvi": 0.6596155397115779,
        "soil_temp": 20.347619251539463,
        "soil_humidity": 68.2640227106923
    },
    {
        "date": "2024-07-21 18:53:09.251267",
        "air_temp": 30.50265646722229,
        "humidity": 80.24533884207193,
        "rainfall": 0.5,
        "wind_speed": 4.775035421720482,
        "soil_moisture": 15.365232055233982,
        "ndvi": 0.4032807019562678,
        "soil_temp": 18.483464883519314,
        "soil_humidity": 45.65107421627782
    },
    {
        "date": "2024-07-21 19:53:09.251267",
        "air_temp": 33.78997883128378,
        "humidity": 40.90032778002273,
        "rainfall": 0,
        "wind_speed": 4.270857089806828,
        "soil_moisture": 37.05748323513649,
        "ndvi": 0.3053758793408014,
        "soil_temp": 11.162286961757372,
        "soil_humidity": 46.80538216732401
    },
    {
        "date": "2024-07-21 20:53:09.251267",
        "air_temp": 32.89654700855297,
        "humidity": 88.1405178537013,
        "rainfall": 2,
        "wind_speed": 8.222886729599741,
        "soil_moisture": 21.915606589513864,
        "ndvi": 0.452826164728313,
        "soil_temp": 12.439424444735756,
        "soil_humidity": 68.75606868456151
    },
    {
        "date": "2024-07-21 21:53:09.251267",
        "air_temp": 26.957999576221702,
        "humidity": 76.33354960351616,
        "rainfall": 0.5,
        "wind_speed": 14.080629051833139,
        "soil_moisture": 17.35837914923102,
        "ndvi": 0.6763015472891699,
        "soil_temp": 15.820739050876213,
        "soil_humidity": 57.784304465069134
    },
    {
        "date": "2024-07-21 22:53:09.251267",
        "air_temp": 33.437484700462335,
        "humidity": 55.23755476930667,
        "rainfall": 0.5,
        "wind_speed": 4.349058644344719,
        "soil_moisture": 23.45223523047799,
        "ndvi": 0.47890988222243136,
        "soil_temp": 12.784060959331498,
        "soil_humidity": 58.76687691368501
    },
    {
        "date": "2024-07-21 23:53:09.251267",
        "air_temp": 16.76985004103839,
        "humidity": 81.46973324294234,
        "rainfall": 2,
        "wind_speed": 7.333577594059628,
        "soil_moisture": 19.616085004194865,
        "ndvi": 0.5225138601218291,
        "soil_temp": 21.883335208658927,
        "soil_humidity": 52.50808021484271
    },
    {
        "date": "2024-07-22 00:53:09.251267",
        "air_temp": 18.919657248382904,
        "humidity": 54.0760599207465,
        "rainfall": 1,
        "wind_speed": 7.818348536823246,
        "soil_moisture": 32.33257761439978,
        "ndvi": 0.7970956461200729,
        "soil_temp": 16.2410535332992,
        "soil_humidity": 68.98436843452967
    },
    {
        "date": "2024-07-22 01:53:09.251267",
        "air_temp": 15.90454577821076,
        "humidity": 83.6376919783064,
        "rainfall": 0.5,
        "wind_speed": 19.529130588708203,
        "soil_moisture": 39.136597356564835,
        "ndvi": 0.43946557973445566,
        "soil_temp": 11.40147800263749,
        "soil_humidity": 57.55465937702225
    },
    {
        "date": "2024-07-22 02:53:09.251267",
        "air_temp": 21.506606615265287,
        "humidity": 45.62901952059685,
        "rainfall": 0.5,
        "wind_speed": 19.093327137581376,
        "soil_moisture": 23.141009700929406,
        "ndvi": 0.5692404528255439,
        "soil_temp": 17.265143534081872,
        "soil_humidity": 65.80131006420677
    },
    {
        "date": "2024-07-22 03:53:09.251267",
        "air_temp": 22.77354579378964,
        "humidity": 75.18429887303978,
        "rainfall": 0,
        "wind_speed": 16.002751655041518,
        "soil_moisture": 35.83100199916721,
        "ndvi": 0.41914652096922317,
        "soil_temp": 11.362572715921212,
        "soil_humidity": 57.97515462932885
    },
    {
        "date": "2024-07-22 04:53:09.251267",
        "air_temp": 20.426980635477918,
        "humidity": 67.03492383224551,
        "rainfall": 1,
        "wind_speed": 4.833178416383703,
        "soil_moisture": 26.975892206788586,
        "ndvi": 0.43567138779722303,
        "soil_temp": 11.934059581297259,
        "soil_humidity": 59.404632993615834
    },
    {
        "date": "2024-07-22 05:53:09.251267",
        "air_temp": 31.57475018303859,
        "humidity": 44.82672350977204,
        "rainfall": 0,
        "wind_speed": 1.3607462818333937,
        "soil_moisture": 14.717069952827003,
        "ndvi": 0.6173844520672449,
        "soil_temp": 13.594147480423079,
        "soil_humidity": 41.616436885689865
    },
    {
        "date": "2024-07-22 06:53:09.251267",
        "air_temp": 22.135066533871786,
        "humidity": 52.09469141294195,
        "rainfall": 0,
        "wind_speed": 6.338082754412495,
        "soil_moisture": 30.718682262440247,
        "ndvi": 0.7627321856569302,
        "soil_temp": 22.892915578712348,
        "soil_humidity": 42.13914792055276
    },
    {
        "date": "2024-07-22 07:53:09.251267",
        "air_temp": 20.618690193747614,
        "humidity": 40.62020181386574,
        "rainfall": 1,
        "wind_speed": 5.335890988676617,
        "soil_moisture": 25.403034913774604,
        "ndvi": 0.525415370295393,
        "soil_temp": 23.86918798499458,
        "soil_humidity": 61.31938598304117
    },
    {
        "date": "2024-07-22 08:53:09.251267",
        "air_temp": 25.853921663164968,
        "humidity": 63.43839827322529,
        "rainfall": 1,
        "wind_speed": 10.118516002927878,
        "soil_moisture": 29.518195697563105,
        "ndvi": 0.661039957369268,
        "soil_temp": 22.393865939981037,
        "soil_humidity": 41.86825156679598
    },
    {
        "date": "2024-07-22 09:53:09.251267",
        "air_temp": 17.818484499495252,
        "humidity": 55.06326303800002,
        "rainfall": 2,
        "wind_speed": 15.554558201355862,
        "soil_moisture": 36.69062237714995,
        "ndvi": 0.2535239352901783,
        "soil_temp": 15.01115826126771,
        "soil_humidity": 52.510802832061266
    },
    {
        "date": "2024-07-22 10:53:09.251267",
        "air_temp": 31.043939615080795,
        "humidity": 69.91787421488795,
        "rainfall": 1,
        "wind_speed": 17.166458777577162,
        "soil_moisture": 23.21303711968138,
        "ndvi": 0.3266948224591562,
        "soil_temp": 14.086696616632475,
        "soil_humidity": 39.97409243626808
    },
    {
        "date": "2024-07-22 11:53:09.251267",
        "air_temp": 16.491012873595416,
        "humidity": 54.86189038543086,
        "rainfall": 0.5,
        "wind_speed": 16.89728170821017,
        "soil_moisture": 37.035328696520665,
        "ndvi": 0.5012789845517196,
        "soil_temp": 18.23733536185359,
        "soil_humidity": 62.679335232362774
    },
    {
        "date": "2024-07-22 12:53:09.251267",
        "air_temp": 34.73773873201034,
        "humidity": 54.995955575349235,
        "rainfall": 1,
        "wind_speed": 7.193511224461355,
        "soil_moisture": 32.52007042844964,
        "ndvi": 0.21200703417726488,
        "soil_temp": 20.827797407996684,
        "soil_humidity": 34.4200540491076
    },
    {
        "date": "2024-07-22 13:53:09.251267",
        "air_temp": 30.444895385933147,
        "humidity": 77.15965993658017,
        "rainfall": 2,
        "wind_speed": 13.744686869213659,
        "soil_moisture": 24.658147776339543,
        "ndvi": 0.3517129872575353,
        "soil_temp": 24.900591822402625,
        "soil_humidity": 63.17786559046658
    },
    {
        "date": "2024-07-22 14:53:09.251267",
        "air_temp": 18.974313630683447,
        "humidity": 42.40714179891767,
        "rainfall": 2,
        "wind_speed": 6.256683950484401,
        "soil_moisture": 28.09666406973891,
        "ndvi": 0.5104407738949797,
        "soil_temp": 11.973560678183894,
        "soil_humidity": 54.10919202030407
    },
    {
        "date": "2024-07-22 15:53:09.251267",
        "air_temp": 15.110442342472048,
        "humidity": 85.14474975959936,
        "rainfall": 2,
        "wind_speed": 1.2181108647036543,
        "soil_moisture": 19.76743397557034,
        "ndvi": 0.20897671895378045,
        "soil_temp": 10.739379985357067,
        "soil_humidity": 37.47638139308309
    },
    {
        "date": "2024-07-22 16:53:09.251267",
        "air_temp": 31.309228569096682,
        "humidity": 82.61319371955906,
        "rainfall": 1,
        "wind_speed": 14.031305312058846,
        "soil_moisture": 39.507918992163226,
        "ndvi": 0.3587277717356251,
        "soil_temp": 20.415338334929064,
        "soil_humidity": 51.681415420244726
    },
    {
        "date": "2024-07-22 17:53:09.251267",
        "air_temp": 29.137146876952343,
        "humidity": 73.39023416132034,
        "rainfall": 1,
        "wind_speed": 0.1965510513957569,
        "soil_moisture": 30.02053489151188,
        "ndvi": 0.5084184916855217,
        "soil_temp": 22.483386752558584,
        "soil_humidity": 41.299953473893616
    },
    {
        "date": "2024-07-22 18:53:09.251267",
        "air_temp": 29.580143360819747,
        "humidity": 69.66107850536922,
        "rainfall": 0,
        "wind_speed": 8.183651749169771,
        "soil_moisture": 11.315405574898897,
        "ndvi": 0.5438895487887474,
        "soil_temp": 22.69523169799635,
        "soil_humidity": 42.938693389219864
    },
    {
        "date": "2024-07-22 19:53:09.251267",
        "air_temp": 30.425406933718914,
        "humidity": 84.61512490698262,
        "rainfall": 0,
        "wind_speed": 6.587585386041514,
        "soil_moisture": 19.798657172440265,
        "ndvi": 0.7554510158331833,
        "soil_temp": 20.17695323615741,
        "soil_humidity": 44.06112166387521
    },
    {
        "date": "2024-07-22 20:53:09.251267",
        "air_temp": 16.480893034681806,
        "humidity": 49.26650897643572,
        "rainfall": 0.5,
        "wind_speed": 13.867550770377049,
        "soil_moisture": 28.699433012771724,
        "ndvi": 0.5872073739503647,
        "soil_temp": 24.310237061787806,
        "soil_humidity": 32.532769878892836
    },
    {
        "date": "2024-07-22 21:53:09.251267",
        "air_temp": 22.169314570885454,
        "humidity": 43.948458109541434,
        "rainfall": 0,
        "wind_speed": 15.324909455207578,
        "soil_moisture": 11.689998333977194,
        "ndvi": 0.4488414815179963,
        "soil_temp": 17.665071453168803,
        "soil_humidity": 69.43846737605669
    },
    {
        "date": "2024-07-22 22:53:09.251267",
        "air_temp": 17.317381190502594,
        "humidity": 51.9755084954769,
        "rainfall": 0.5,
        "wind_speed": 6.081892719425676,
        "soil_moisture": 10.716525739574283,
        "ndvi": 0.7582107062798409,
        "soil_temp": 23.70722197504319,
        "soil_humidity": 31.261385125248964
    },
    {
        "date": "2024-07-22 23:53:09.251267",
        "air_temp": 32.26206851751187,
        "humidity": 79.72891393500952,
        "rainfall": 2,
        "wind_speed": 16.23254028889707,
        "soil_moisture": 23.500727185834076,
        "ndvi": 0.6709297900326767,
        "soil_temp": 24.517182054872848,
        "soil_humidity": 56.49987492737114
    },
    {
        "date": "2024-07-23 00:53:09.251267",
        "air_temp": 27.46596253655116,
        "humidity": 41.733513336324165,
        "rainfall": 0,
        "wind_speed": 5.878335210098675,
        "soil_moisture": 19.980705390780102,
        "ndvi": 0.32000535266764857,
        "soil_temp": 22.707361855046614,
        "soil_humidity": 44.849105910297226
    },
    {
        "date": "2024-07-23 01:53:09.251267",
        "air_temp": 21.617960497052984,
        "humidity": 69.14034175417449,
        "rainfall": 1,
        "wind_speed": 8.926527199731225,
        "soil_moisture": 23.14895824099498,
        "ndvi": 0.27850541680929186,
        "soil_temp": 14.64637844887931,
        "soil_humidity": 55.84041163786796
    },
    {
        "date": "2024-07-23 02:53:09.251267",
        "air_temp": 16.27116700572047,
        "humidity": 89.7718758095655,
        "rainfall": 0,
        "wind_speed": 14.540370540282678,
        "soil_moisture": 17.880799296400156,
        "ndvi": 0.6054869814456398,
        "soil_temp": 12.94323476755443,
        "soil_humidity": 44.24993012350335
    },
    {
        "date": "2024-07-23 03:53:09.251267",
        "air_temp": 21.219646434313244,
        "humidity": 82.78480469187697,
        "rainfall": 0.5,
        "wind_speed": 13.858404584495771,
        "soil_moisture": 14.941532408289428,
        "ndvi": 0.5359298993461903,
        "soil_temp": 18.221800974727277,
        "soil_humidity": 56.95002585035435
    },
    {
        "date": "2024-07-23 04:53:09.251267",
        "air_temp": 21.503666440534943,
        "humidity": 66.07228445217947,
        "rainfall": 0,
        "wind_speed": 19.747369446244,
        "soil_moisture": 30.67430070941013,
        "ndvi": 0.6738041682756535,
        "soil_temp": 16.795221255702828,
        "soil_humidity": 59.16942037873443
    },
    {
        "date": "2024-07-23 05:53:09.251267",
        "air_temp": 29.59212356676128,
        "humidity": 43.18204546433212,
        "rainfall": 2,
        "wind_speed": 4.503220484318637,
        "soil_moisture": 25.437133488034014,
        "ndvi": 0.6432830300472119,
        "soil_temp": 23.166375132890995,
        "soil_humidity": 57.62210561598579
    },
    {
        "date": "2024-07-23 06:53:09.251267",
        "air_temp": 27.751149427104263,
        "humidity": 81.5686757192855,
        "rainfall": 1,
        "wind_speed": 13.246358270791648,
        "soil_moisture": 16.004626337481007,
        "ndvi": 0.5751269203145091,
        "soil_temp": 11.500475066007306,
        "soil_humidity": 34.55847925432609
    },
    {
        "date": "2024-07-23 07:53:09.251267",
        "air_temp": 32.744254851526534,
        "humidity": 69.94892540886053,
        "rainfall": 0.5,
        "wind_speed": 12.193638165515548,
        "soil_moisture": 19.58768278779995,
        "ndvi": 0.7918293029773353,
        "soil_temp": 13.866100919672778,
        "soil_humidity": 56.275729386187386
    },
    {
        "date": "2024-07-23 08:53:09.251267",
        "air_temp": 24.444298503238986,
        "humidity": 45.746649852978344,
        "rainfall": 1,
        "wind_speed": 18.301969089800597,
        "soil_moisture": 27.75423722989978,
        "ndvi": 0.5201538380662453,
        "soil_temp": 21.27698416778063,
        "soil_humidity": 41.199501978163894
    },
    {
        "date": "2024-07-23 09:53:09.251267",
        "air_temp": 17.391884918766035,
        "humidity": 44.69286424116781,
        "rainfall": 1,
        "wind_speed": 3.127242832897501,
        "soil_moisture": 12.166880448174336,
        "ndvi": 0.26786345390103194,
        "soil_temp": 11.424799674659681,
        "soil_humidity": 31.15205482277334
    },
    {
        "date": "2024-07-23 10:53:09.251267",
        "air_temp": 29.2648957444599,
        "humidity": 85.48134097182961,
        "rainfall": 0.5,
        "wind_speed": 15.035963271738659,
        "soil_moisture": 14.423590277985387,
        "ndvi": 0.6969641388895931,
        "soil_temp": 14.811466210325502,
        "soil_humidity": 46.59322687555816
    },
    {
        "date": "2024-07-23 11:53:09.251267",
        "air_temp": 30.21570097233795,
        "humidity": 73.46001314690392,
        "rainfall": 1,
        "wind_speed": 11.365372325375366,
        "soil_moisture": 12.197368123221704,
        "ndvi": 0.3511120174074254,
        "soil_temp": 15.807330910809746,
        "soil_humidity": 47.34172411215204
    },
    {
        "date": "2024-07-23 12:53:09.251267",
        "air_temp": 26.225543951389923,
        "humidity": 81.46433996583778,
        "rainfall": 1,
        "wind_speed": 5.493317395758108,
        "soil_moisture": 34.369519981423565,
        "ndvi": 0.6663812888863511,
        "soil_temp": 19.33890694844035,
        "soil_humidity": 48.68696533409786
    },
    {
        "date": "2024-07-23 13:53:09.251267",
        "air_temp": 30.41934359909122,
        "humidity": 83.94894501411336,
        "rainfall": 0,
        "wind_speed": 9.488698282943481,
        "soil_moisture": 32.57227699636027,
        "ndvi": 0.7742642576000309,
        "soil_temp": 12.371989685790469,
        "soil_humidity": 58.19296661562865
    },
    {
        "date": "2024-07-23 14:53:09.251267",
        "air_temp": 24.875911927287817,
        "humidity": 68.58861760241611,
        "rainfall": 1,
        "wind_speed": 6.675531941395956,
        "soil_moisture": 32.59938795637771,
        "ndvi": 0.6180773083786049,
        "soil_temp": 22.909067355245643,
        "soil_humidity": 42.35375236569837
    },
    {
        "date": "2024-07-23 15:53:09.251267",
        "air_temp": 25.45465658763988,
        "humidity": 65.87231758775206,
        "rainfall": 1,
        "wind_speed": 11.586268055769217,
        "soil_moisture": 31.489760304280843,
        "ndvi": 0.5576180965698807,
        "soil_temp": 16.713133531994114,
        "soil_humidity": 42.611220524156906
    },
    {
        "date": "2024-07-23 16:53:09.251267",
        "air_temp": 23.550820367170992,
        "humidity": 61.52137051604951,
        "rainfall": 1,
        "wind_speed": 17.78001221629332,
        "soil_moisture": 30.081562710096076,
        "ndvi": 0.4762317054050244,
        "soil_temp": 23.755325678208347,
        "soil_humidity": 32.422090249650424
    },
    {
        "date": "2024-07-23 17:53:09.251267",
        "air_temp": 15.508382534881903,
        "humidity": 55.847329389555966,
        "rainfall": 1,
        "wind_speed": 11.386099688244041,
        "soil_moisture": 24.30975746134525,
        "ndvi": 0.418645919597242,
        "soil_temp": 17.018048704508974,
        "soil_humidity": 57.47284863969184
    },
    {
        "date": "2024-07-23 18:53:09.251267",
        "air_temp": 17.157828539866088,
        "humidity": 61.729798042935585,
        "rainfall": 0.5,
        "wind_speed": 12.63444836653168,
        "soil_moisture": 29.26834490521345,
        "ndvi": 0.4118028505831018,
        "soil_temp": 14.125603884111001,
        "soil_humidity": 52.713043655983356
    },
    {
        "date": "2024-07-23 19:53:09.251267",
        "air_temp": 15.628583713734685,
        "humidity": 78.69398278650782,
        "rainfall": 2,
        "wind_speed": 10.374956942294189,
        "soil_moisture": 11.15084664363924,
        "ndvi": 0.5818236234257544,
        "soil_temp": 12.127432430315123,
        "soil_humidity": 43.97700450775152
    },
    {
        "date": "2024-07-23 20:53:09.251267",
        "air_temp": 27.72820822527561,
        "humidity": 70.09617155075658,
        "rainfall": 0,
        "wind_speed": 1.0233101289095181,
        "soil_moisture": 28.64393267970354,
        "ndvi": 0.6507638701626477,
        "soil_temp": 10.056176420669273,
        "soil_humidity": 58.20755915669419
    },
    {
        "date": "2024-07-23 21:53:09.251267",
        "air_temp": 21.287119621526536,
        "humidity": 84.62616449774231,
        "rainfall": 1,
        "wind_speed": 4.657171137521885,
        "soil_moisture": 33.33633116610632,
        "ndvi": 0.6071102416906486,
        "soil_temp": 17.982362274772534,
        "soil_humidity": 51.49430996907509
    },
    {
        "date": "2024-07-23 22:53:09.251267",
        "air_temp": 25.171413823294056,
        "humidity": 62.16900071895938,
        "rainfall": 1,
        "wind_speed": 9.840034588641656,
        "soil_moisture": 25.010750811531707,
        "ndvi": 0.31904606071613767,
        "soil_temp": 11.607350453903852,
        "soil_humidity": 48.76979783482159
    },
    {
        "date": "2024-07-23 23:53:09.251267",
        "air_temp": 33.15132947852186,
        "humidity": 70.35447893593769,
        "rainfall": 0.5,
        "wind_speed": 6.204705674894095,
        "soil_moisture": 20.72539985281711,
        "ndvi": 0.49639230808609475,
        "soil_temp": 15.875916454467674,
        "soil_humidity": 50.45298174644506
    },
    {
        "date": "2024-07-24 00:53:09.251267",
        "air_temp": 19.9858445829775,
        "humidity": 71.56537765459944,
        "rainfall": 2,
        "wind_speed": 3.6352719552238844,
        "soil_moisture": 18.322802044029373,
        "ndvi": 0.6749840433098379,
        "soil_temp": 18.839076418556303,
        "soil_humidity": 55.45392501068226
    },
    {
        "date": "2024-07-24 01:53:09.251267",
        "air_temp": 23.207658460712594,
        "humidity": 69.58485811531628,
        "rainfall": 1,
        "wind_speed": 10.082656838368766,
        "soil_moisture": 29.284061504862475,
        "ndvi": 0.4854224469134338,
        "soil_temp": 14.135288453540504,
        "soil_humidity": 56.40010418298398
    },
    {
        "date": "2024-07-24 02:53:09.251267",
        "air_temp": 30.111022770860973,
        "humidity": 75.13168865427582,
        "rainfall": 0.5,
        "wind_speed": 6.456195868957007,
        "soil_moisture": 30.784035958897263,
        "ndvi": 0.5219739678412963,
        "soil_temp": 12.628364385491729,
        "soil_humidity": 41.558312765628685
    },
    {
        "date": "2024-07-24 03:53:09.251267",
        "air_temp": 19.57596330983245,
        "humidity": 51.871673418740315,
        "rainfall": 0,
        "wind_speed": 5.162152863468295,
        "soil_moisture": 36.12902799587628,
        "ndvi": 0.6626816289024934,
        "soil_temp": 21.620824339140125,
        "soil_humidity": 35.62973453564613
    },
    {
        "date": "2024-07-24 04:53:09.251267",
        "air_temp": 16.53959819657586,
        "humidity": 65.61818887728953,
        "rainfall": 0,
        "wind_speed": 9.279708348807617,
        "soil_moisture": 20.27002247382972,
        "ndvi": 0.5967591837590549,
        "soil_temp": 24.20186758422431,
        "soil_humidity": 36.26536724762075
    },
    {
        "date": "2024-07-24 05:53:09.251267",
        "air_temp": 20.79502905827536,
        "humidity": 45.21124014041738,
        "rainfall": 1,
        "wind_speed": 2.665685161643463,
        "soil_moisture": 33.093169221567564,
        "ndvi": 0.4456069525294356,
        "soil_temp": 11.50660626792949,
        "soil_humidity": 55.575635436120066
    },
    {
        "date": "2024-07-24 06:53:09.251267",
        "air_temp": 18.22442574508009,
        "humidity": 59.22556638224063,
        "rainfall": 1,
        "wind_speed": 18.554914661508146,
        "soil_moisture": 26.840674725680138,
        "ndvi": 0.7372476267165315,
        "soil_temp": 12.333854865775118,
        "soil_humidity": 64.91493143068504
    },
    {
        "date": "2024-07-24 07:53:09.251267",
        "air_temp": 33.59395304685146,
        "humidity": 64.38335343106323,
        "rainfall": 2,
        "wind_speed": 17.35404195838478,
        "soil_moisture": 36.485094720342374,
        "ndvi": 0.2975402411980872,
        "soil_temp": 24.40018985879078,
        "soil_humidity": 46.37985113382207
    },
    {
        "date": "2024-07-24 08:53:09.251267",
        "air_temp": 31.16240759128834,
        "humidity": 72.61121297727362,
        "rainfall": 2,
        "wind_speed": 14.152411714810707,
        "soil_moisture": 25.834363248868623,
        "ndvi": 0.7973098691541949,
        "soil_temp": 10.566763327183693,
        "soil_humidity": 36.780806413178496
    },
    {
        "date": "2024-07-24 09:53:09.251267",
        "air_temp": 27.66807513020847,
        "humidity": 87.52655262931017,
        "rainfall": 1,
        "wind_speed": 2.124276551769124,
        "soil_moisture": 25.528894851939015,
        "ndvi": 0.27131154708574234,
        "soil_temp": 24.664485048257085,
        "soil_humidity": 60.26409993175419
    },
    {
        "date": "2024-07-24 10:53:09.251267",
        "air_temp": 32.429211803754356,
        "humidity": 70.03255332741779,
        "rainfall": 0.5,
        "wind_speed": 15.51754508939386,
        "soil_moisture": 27.33631244322727,
        "ndvi": 0.20896998266254335,
        "soil_temp": 10.069667246274832,
        "soil_humidity": 63.299578976777944
    },
    {
        "date": "2024-07-24 11:53:09.251267",
        "air_temp": 31.07344153798229,
        "humidity": 77.17969580882799,
        "rainfall": 2,
        "wind_speed": 16.15914656218038,
        "soil_moisture": 32.47946065427303,
        "ndvi": 0.734011340689259,
        "soil_temp": 23.105029834769972,
        "soil_humidity": 65.22329772483866
    },
    {
        "date": "2024-07-24 12:53:09.251267",
        "air_temp": 18.731401177720716,
        "humidity": 65.31330297476013,
        "rainfall": 1,
        "wind_speed": 15.355292354286753,
        "soil_moisture": 38.94071436485383,
        "ndvi": 0.34697210501957365,
        "soil_temp": 14.609877714816243,
        "soil_humidity": 54.27325715654933
    },
    {
        "date": "2024-07-24 13:53:09.251267",
        "air_temp": 32.85117996979956,
        "humidity": 71.70520156881656,
        "rainfall": 2,
        "wind_speed": 2.279317742384823,
        "soil_moisture": 12.206900626472969,
        "ndvi": 0.6378197764680327,
        "soil_temp": 24.078832872659447,
        "soil_humidity": 64.42914237552338
    },
    {
        "date": "2024-07-24 14:53:09.251267",
        "air_temp": 25.786844838313016,
        "humidity": 43.54661080668867,
        "rainfall": 1,
        "wind_speed": 12.878159141355294,
        "soil_moisture": 11.603038071730515,
        "ndvi": 0.6866092249592417,
        "soil_temp": 14.754179329533782,
        "soil_humidity": 30.616598337936306
    },
    {
        "date": "2024-07-24 15:53:09.251267",
        "air_temp": 31.14880310328125,
        "humidity": 52.71957904209235,
        "rainfall": 0,
        "wind_speed": 16.686495914987972,
        "soil_moisture": 38.126510122220154,
        "ndvi": 0.7153918346192703,
        "soil_temp": 19.81459482301084,
        "soil_humidity": 34.28943207078094
    },
    {
        "date": "2024-07-24 16:53:09.251267",
        "air_temp": 32.92182599846986,
        "humidity": 58.09265466025693,
        "rainfall": 1,
        "wind_speed": 11.547068024393354,
        "soil_moisture": 25.896268765546182,
        "ndvi": 0.7949565413985,
        "soil_temp": 11.20048854648905,
        "soil_humidity": 33.70304268208395
    },
    {
        "date": "2024-07-24 17:53:09.251267",
        "air_temp": 21.360069499437277,
        "humidity": 63.62466775032568,
        "rainfall": 0,
        "wind_speed": 12.92814750456178,
        "soil_moisture": 28.447090437008082,
        "ndvi": 0.3764238260239995,
        "soil_temp": 13.634947171806084,
        "soil_humidity": 58.50418737018932
    },
    {
        "date": "2024-07-24 18:53:09.251267",
        "air_temp": 17.201038490553536,
        "humidity": 42.282437275119186,
        "rainfall": 2,
        "wind_speed": 9.167269588526123,
        "soil_moisture": 24.472708047841866,
        "ndvi": 0.6261460051563555,
        "soil_temp": 21.60519019718876,
        "soil_humidity": 32.78327134174316
    },
    {
        "date": "2024-07-24 19:53:09.251267",
        "air_temp": 19.558703250838832,
        "humidity": 47.001204877665884,
        "rainfall": 1,
        "wind_speed": 10.574146397681051,
        "soil_moisture": 12.809723578224235,
        "ndvi": 0.269239914968497,
        "soil_temp": 17.930287805182296,
        "soil_humidity": 49.85457489865228
    },
    {
        "date": "2024-07-24 20:53:09.251267",
        "air_temp": 23.542155772525128,
        "humidity": 53.84071538956529,
        "rainfall": 2,
        "wind_speed": 0.6875371670345043,
        "soil_moisture": 33.042681352559995,
        "ndvi": 0.4440855748141097,
        "soil_temp": 23.919535926805672,
        "soil_humidity": 48.908275945451564
    },
    {
        "date": "2024-07-24 21:53:09.251267",
        "air_temp": 31.36029531844986,
        "humidity": 88.57663454505254,
        "rainfall": 0,
        "wind_speed": 12.779260439465691,
        "soil_moisture": 32.41673935265761,
        "ndvi": 0.7087830159875543,
        "soil_temp": 16.431271125120315,
        "soil_humidity": 42.428140942329634
    },
    {
        "date": "2024-07-24 22:53:09.251267",
        "air_temp": 32.21461166512687,
        "humidity": 56.56735035515254,
        "rainfall": 1,
        "wind_speed": 1.6289442578231839,
        "soil_moisture": 19.35109964478436,
        "ndvi": 0.4205948276354651,
        "soil_temp": 23.047185487208846,
        "soil_humidity": 64.81620679768399
    },
    {
        "date": "2024-07-24 23:53:09.251267",
        "air_temp": 15.139042610623815,
        "humidity": 64.10205290560413,
        "rainfall": 2,
        "wind_speed": 8.700291886804468,
        "soil_moisture": 19.18717735685201,
        "ndvi": 0.5546572283213684,
        "soil_temp": 16.734331562173562,
        "soil_humidity": 36.81732807519984
    },
    {
        "date": "2024-07-25 00:53:09.251267",
        "air_temp": 25.214946051551316,
        "humidity": 49.80488563691965,
        "rainfall": 0,
        "wind_speed": 5.8531019533481565,
        "soil_moisture": 35.13524142716555,
        "ndvi": 0.5022640662347805,
        "soil_temp": 24.68872418052978,
        "soil_humidity": 44.09851137596959
    },
    {
        "date": "2024-07-25 01:53:09.251267",
        "air_temp": 23.348220062975578,
        "humidity": 70.5390035537884,
        "rainfall": 2,
        "wind_speed": 10.908559004234261,
        "soil_moisture": 12.170717732239797,
        "ndvi": 0.5875709186672271,
        "soil_temp": 24.39751572098315,
        "soil_humidity": 60.966860000917166
    },
    {
        "date": "2024-07-25 02:53:09.251267",
        "air_temp": 19.442156209414605,
        "humidity": 54.03416077579872,
        "rainfall": 0.5,
        "wind_speed": 2.6683267104641417,
        "soil_moisture": 36.433154450696975,
        "ndvi": 0.783736633632617,
        "soil_temp": 14.937050583341204,
        "soil_humidity": 31.21689257573137
    },
    {
        "date": "2024-07-25 03:53:09.251267",
        "air_temp": 17.397307346673657,
        "humidity": 50.34962892192134,
        "rainfall": 0,
        "wind_speed": 13.832868039466364,
        "soil_moisture": 17.699850696877434,
        "ndvi": 0.23344131590084127,
        "soil_temp": 23.288855056252046,
        "soil_humidity": 56.918016009854746
    },
    {
        "date": "2024-07-25 04:53:09.251267",
        "air_temp": 21.75230342807256,
        "humidity": 65.82863142229895,
        "rainfall": 0,
        "wind_speed": 15.900230738569407,
        "soil_moisture": 27.789542432664817,
        "ndvi": 0.5443769105009779,
        "soil_temp": 22.71417061323474,
        "soil_humidity": 62.655909054462825
    },
    {
        "date": "2024-07-25 05:53:09.251267",
        "air_temp": 33.858194078250385,
        "humidity": 40.27528995802104,
        "rainfall": 0,
        "wind_speed": 3.26708899486313,
        "soil_moisture": 38.07982537005333,
        "ndvi": 0.7178172675501708,
        "soil_temp": 13.160205959913442,
        "soil_humidity": 67.29071355352669
    },
    {
        "date": "2024-07-25 06:53:09.251267",
        "air_temp": 21.464058640415104,
        "humidity": 40.383201632461265,
        "rainfall": 1,
        "wind_speed": 2.086261747037643,
        "soil_moisture": 30.343675046099392,
        "ndvi": 0.4888296270490566,
        "soil_temp": 19.364356011749045,
        "soil_humidity": 65.0518656196594
    },
    {
        "date": "2024-07-25 07:53:09.251267",
        "air_temp": 25.37581243486732,
        "humidity": 50.95344045509289,
        "rainfall": 1,
        "wind_speed": 2.910309655747183,
        "soil_moisture": 22.4328943740111,
        "ndvi": 0.2471163115649437,
        "soil_temp": 11.090234733847224,
        "soil_humidity": 42.77536467334741
    },
    {
        "date": "2024-07-25 08:53:09.251267",
        "air_temp": 29.060379177903556,
        "humidity": 41.83606812504106,
        "rainfall": 1,
        "wind_speed": 17.542551237970034,
        "soil_moisture": 30.686026267162454,
        "ndvi": 0.5363236114500626,
        "soil_temp": 22.550660808751925,
        "soil_humidity": 53.454565652918106
    },
    {
        "date": "2024-07-25 09:53:09.251267",
        "air_temp": 22.27259204758588,
        "humidity": 45.40128770653457,
        "rainfall": 0.5,
        "wind_speed": 10.563785235127124,
        "soil_moisture": 20.319389089491104,
        "ndvi": 0.3992443344942923,
        "soil_temp": 12.618188252552448,
        "soil_humidity": 33.09173972601863
    },
    {
        "date": "2024-07-25 10:53:09.251267",
        "air_temp": 34.435641654419214,
        "humidity": 56.94303246693758,
        "rainfall": 0.5,
        "wind_speed": 9.345219920150052,
        "soil_moisture": 18.317752490475584,
        "ndvi": 0.29296507120276155,
        "soil_temp": 11.062220520786468,
        "soil_humidity": 40.314800141920344
    },
    {
        "date": "2024-07-25 11:53:09.251267",
        "air_temp": 34.24894589884222,
        "humidity": 80.12928397899064,
        "rainfall": 0,
        "wind_speed": 2.1342363820470855,
        "soil_moisture": 39.90049833804039,
        "ndvi": 0.7094207079700838,
        "soil_temp": 16.003286433082167,
        "soil_humidity": 33.768895149900295
    },
    {
        "date": "2024-07-25 12:53:09.251267",
        "air_temp": 20.035645916507285,
        "humidity": 68.60243216846504,
        "rainfall": 0.5,
        "wind_speed": 12.991120135139235,
        "soil_moisture": 32.52370693539363,
        "ndvi": 0.512959707794673,
        "soil_temp": 18.653832078994654,
        "soil_humidity": 42.607491606158554
    },
    {
        "date": "2024-07-25 13:53:09.251267",
        "air_temp": 24.94497011784771,
        "humidity": 65.63338629782082,
        "rainfall": 2,
        "wind_speed": 17.3599918379018,
        "soil_moisture": 29.341599321454527,
        "ndvi": 0.75996584742744,
        "soil_temp": 22.823754378396565,
        "soil_humidity": 65.00748853834915
    },
    {
        "date": "2024-07-25 14:53:09.251267",
        "air_temp": 21.017566196335395,
        "humidity": 54.67444116040608,
        "rainfall": 0,
        "wind_speed": 16.563717386469058,
        "soil_moisture": 20.658689296579563,
        "ndvi": 0.6836099027618415,
        "soil_temp": 24.613637882826048,
        "soil_humidity": 43.8866735869717
    },
    {
        "date": "2024-07-25 15:53:09.251267",
        "air_temp": 20.696809887549353,
        "humidity": 86.58768597164081,
        "rainfall": 0,
        "wind_speed": 12.521094179616025,
        "soil_moisture": 11.474824874651445,
        "ndvi": 0.6157830762642005,
        "soil_temp": 16.592387688547955,
        "soil_humidity": 52.47592412005706
    },
    {
        "date": "2024-07-25 16:53:09.251267",
        "air_temp": 15.737738947090655,
        "humidity": 59.85075206185958,
        "rainfall": 2,
        "wind_speed": 1.5055743330334725,
        "soil_moisture": 10.931706281054668,
        "ndvi": 0.20301530201918716,
        "soil_temp": 10.906703844779052,
        "soil_humidity": 47.33523392459763
    },
    {
        "date": "2024-07-25 17:53:09.251267",
        "air_temp": 27.191286679597937,
        "humidity": 44.35463833978857,
        "rainfall": 0,
        "wind_speed": 2.2479405435202593,
        "soil_moisture": 26.625437663463963,
        "ndvi": 0.45669407398295003,
        "soil_temp": 21.23673368538806,
        "soil_humidity": 48.35666097194941
    },
    {
        "date": "2024-07-25 18:53:09.251267",
        "air_temp": 25.05358046457723,
        "humidity": 70.85332580589007,
        "rainfall": 0.5,
        "wind_speed": 0.9861797421964935,
        "soil_moisture": 32.86098163117153,
        "ndvi": 0.3184917689912194,
        "soil_temp": 18.47759884335906,
        "soil_humidity": 68.78288207346233
    },
    {
        "date": "2024-07-25 19:53:09.251267",
        "air_temp": 16.029575024999787,
        "humidity": 45.69191967383007,
        "rainfall": 1,
        "wind_speed": 5.149584329849386,
        "soil_moisture": 26.12137571331565,
        "ndvi": 0.7464787466293208,
        "soil_temp": 16.718370172666948,
        "soil_humidity": 41.846178730554314
    },
    {
        "date": "2024-07-25 20:53:09.251267",
        "air_temp": 20.57292928473223,
        "humidity": 57.261157140084606,
        "rainfall": 2,
        "wind_speed": 2.713229343207555,
        "soil_moisture": 35.22355215817343,
        "ndvi": 0.3047503908058162,
        "soil_temp": 14.639033181882844,
        "soil_humidity": 55.092978780926856
    },
    {
        "date": "2024-07-25 21:53:09.251267",
        "air_temp": 33.165317719333075,
        "humidity": 65.37059693589045,
        "rainfall": 0.5,
        "wind_speed": 5.200960805048678,
        "soil_moisture": 11.00279599279665,
        "ndvi": 0.3716360309955713,
        "soil_temp": 17.535977917653035,
        "soil_humidity": 46.80250678317788
    },
    {
        "date": "2024-07-25 22:53:09.251267",
        "air_temp": 19.79123781333945,
        "humidity": 83.71112623425878,
        "rainfall": 2,
        "wind_speed": 7.701161114379234,
        "soil_moisture": 21.0025622927782,
        "ndvi": 0.5101020606406996,
        "soil_temp": 15.080194066855464,
        "soil_humidity": 32.44584724190745
    },
    {
        "date": "2024-07-25 23:53:09.251267",
        "air_temp": 17.897897441824462,
        "humidity": 64.67732901700593,
        "rainfall": 2,
        "wind_speed": 18.859655681422712,
        "soil_moisture": 17.11211173522534,
        "ndvi": 0.3580181713988476,
        "soil_temp": 21.355178016210402,
        "soil_humidity": 49.57323326496944
    },
    {
        "date": "2024-07-26 00:53:09.251267",
        "air_temp": 24.78905520555126,
        "humidity": 75.11293830158321,
        "rainfall": 0,
        "wind_speed": 12.207521195633841,
        "soil_moisture": 23.41818904471366,
        "ndvi": 0.3259188063674727,
        "soil_temp": 18.158405447389615,
        "soil_humidity": 62.533733161804335
    },
    {
        "date": "2024-07-26 01:53:09.251267",
        "air_temp": 34.71300908221201,
        "humidity": 89.64084194995351,
        "rainfall": 0,
        "wind_speed": 16.699475713659247,
        "soil_moisture": 12.171291795823674,
        "ndvi": 0.5594595950616515,
        "soil_temp": 23.15271661231168,
        "soil_humidity": 33.95011874726754
    },
    {
        "date": "2024-07-26 02:53:09.251267",
        "air_temp": 19.84110543023001,
        "humidity": 46.57445599489908,
        "rainfall": 0.5,
        "wind_speed": 14.207030014246644,
        "soil_moisture": 38.623949247132174,
        "ndvi": 0.25335771365301435,
        "soil_temp": 18.153158719741633,
        "soil_humidity": 32.78367389585236
    },
    {
        "date": "2024-07-26 03:53:09.251267",
        "air_temp": 28.442710948117572,
        "humidity": 53.73657627597598,
        "rainfall": 2,
        "wind_speed": 2.250062610532635,
        "soil_moisture": 16.697662032080828,
        "ndvi": 0.23490065523255485,
        "soil_temp": 13.57393620009895,
        "soil_humidity": 36.145260815730666
    },
    {
        "date": "2024-07-26 04:53:09.251267",
        "air_temp": 30.23239230657435,
        "humidity": 59.72880377988232,
        "rainfall": 0,
        "wind_speed": 14.718408127478977,
        "soil_moisture": 37.66923326450754,
        "ndvi": 0.4233471771116429,
        "soil_temp": 22.76637823348217,
        "soil_humidity": 60.576004455572246
    },
    {
        "date": "2024-07-26 05:53:09.251267",
        "air_temp": 19.752750879847994,
        "humidity": 61.09140892694157,
        "rainfall": 0,
        "wind_speed": 5.501977631182489,
        "soil_moisture": 22.776794651100452,
        "ndvi": 0.6323986727047175,
        "soil_temp": 18.35828567395066,
        "soil_humidity": 58.95981536578952
    },
    {
        "date": "2024-07-26 06:53:09.251267",
        "air_temp": 29.56432697223719,
        "humidity": 60.55105376327729,
        "rainfall": 2,
        "wind_speed": 12.782156939894127,
        "soil_moisture": 39.56572399765654,
        "ndvi": 0.5328840549643689,
        "soil_temp": 13.102615572153418,
        "soil_humidity": 52.600710756244425
    },
    {
        "date": "2024-07-26 07:53:09.251267",
        "air_temp": 22.355662654385064,
        "humidity": 85.38055012782775,
        "rainfall": 0.5,
        "wind_speed": 6.8192394728597705,
        "soil_moisture": 26.621751503806323,
        "ndvi": 0.45372588108006734,
        "soil_temp": 19.35261714934333,
        "soil_humidity": 48.729056163705295
    },
    {
        "date": "2024-07-26 08:53:09.251267",
        "air_temp": 27.64611661187159,
        "humidity": 75.70163222334281,
        "rainfall": 0.5,
        "wind_speed": 19.953445265293183,
        "soil_moisture": 37.92498592527423,
        "ndvi": 0.5394509385347495,
        "soil_temp": 15.365751474136413,
        "soil_humidity": 65.53303258810487
    },
    {
        "date": "2024-07-26 09:53:09.251267",
        "air_temp": 27.670594215217896,
        "humidity": 70.39526070453519,
        "rainfall": 2,
        "wind_speed": 5.271029589935557,
        "soil_moisture": 10.160469660826685,
        "ndvi": 0.4192708907919642,
        "soil_temp": 22.666084184380985,
        "soil_humidity": 60.6679129533393
    },
    {
        "date": "2024-07-26 10:53:09.251267",
        "air_temp": 25.71549368149517,
        "humidity": 55.46863589313911,
        "rainfall": 0.5,
        "wind_speed": 5.582609415485393,
        "soil_moisture": 32.447164185427,
        "ndvi": 0.7616878012592678,
        "soil_temp": 16.799496079698145,
        "soil_humidity": 62.477690052072205
    },
    {
        "date": "2024-07-26 11:53:09.251267",
        "air_temp": 16.805795401088165,
        "humidity": 81.18951921106473,
        "rainfall": 0,
        "wind_speed": 18.613445111247966,
        "soil_moisture": 37.09151959307546,
        "ndvi": 0.23294293454646461,
        "soil_temp": 20.89662037161125,
        "soil_humidity": 62.53049205402617
    },
    {
        "date": "2024-07-26 12:53:09.251267",
        "air_temp": 31.70604991178476,
        "humidity": 87.75302557424945,
        "rainfall": 2,
        "wind_speed": 0.4928880927264534,
        "soil_moisture": 14.191617009368622,
        "ndvi": 0.6040348874179011,
        "soil_temp": 11.433043114543558,
        "soil_humidity": 55.708686189822316
    },
    {
        "date": "2024-07-26 13:53:09.251267",
        "air_temp": 21.415601299434716,
        "humidity": 81.05991412751132,
        "rainfall": 0,
        "wind_speed": 13.933779757463938,
        "soil_moisture": 12.883775551964545,
        "ndvi": 0.7347695988966505,
        "soil_temp": 15.940365194535193,
        "soil_humidity": 48.32058497263783
    },
    {
        "date": "2024-07-26 14:53:09.251267",
        "air_temp": 18.730370207997083,
        "humidity": 40.078255259573645,
        "rainfall": 1,
        "wind_speed": 14.506527814717563,
        "soil_moisture": 22.737178215578666,
        "ndvi": 0.6823308252411522,
        "soil_temp": 24.748621547085982,
        "soil_humidity": 64.58711010342601
    },
    {
        "date": "2024-07-26 15:53:09.251267",
        "air_temp": 15.815502831095278,
        "humidity": 71.82006850917035,
        "rainfall": 1,
        "wind_speed": 16.727571548207987,
        "soil_moisture": 38.01036718398805,
        "ndvi": 0.31042462089286804,
        "soil_temp": 22.126904342157225,
        "soil_humidity": 67.04029670413003
    },
    {
        "date": "2024-07-26 16:53:09.251267",
        "air_temp": 26.817858863764837,
        "humidity": 42.556769072105034,
        "rainfall": 0.5,
        "wind_speed": 19.488786212769508,
        "soil_moisture": 26.271596805713163,
        "ndvi": 0.377555809388173,
        "soil_temp": 12.39911203550393,
        "soil_humidity": 30.95224173033372
    },
    {
        "date": "2024-07-26 17:53:09.251267",
        "air_temp": 28.55128723684565,
        "humidity": 52.880361016686095,
        "rainfall": 1,
        "wind_speed": 19.51203838313305,
        "soil_moisture": 25.823693911651965,
        "ndvi": 0.6302287632242629,
        "soil_temp": 14.166460742158826,
        "soil_humidity": 32.06219493065005
    },
    {
        "date": "2024-07-26 18:53:09.251267",
        "air_temp": 15.331756578557123,
        "humidity": 42.97623501738858,
        "rainfall": 0,
        "wind_speed": 1.3055351513292779,
        "soil_moisture": 14.233783548979655,
        "ndvi": 0.25056233550845936,
        "soil_temp": 14.947237132845416,
        "soil_humidity": 68.3497318763375
    },
    {
        "date": "2024-07-26 19:53:09.251267",
        "air_temp": 25.24186116598562,
        "humidity": 70.18977264746938,
        "rainfall": 1,
        "wind_speed": 16.43678645505805,
        "soil_moisture": 10.023685332796564,
        "ndvi": 0.6871170198995504,
        "soil_temp": 19.755483407431683,
        "soil_humidity": 41.08642455376779
    },
    {
        "date": "2024-07-26 20:53:09.251267",
        "air_temp": 19.529915503958758,
        "humidity": 74.32949567463034,
        "rainfall": 0,
        "wind_speed": 12.926198673147626,
        "soil_moisture": 34.30420272236384,
        "ndvi": 0.6313441667628743,
        "soil_temp": 21.354921392344835,
        "soil_humidity": 64.93477758597675
    },
    {
        "date": "2024-07-26 21:53:09.251267",
        "air_temp": 27.903455808188998,
        "humidity": 45.72438190329525,
        "rainfall": 0.5,
        "wind_speed": 12.946842687461825,
        "soil_moisture": 12.627236889587147,
        "ndvi": 0.6377639895412056,
        "soil_temp": 21.242899132118644,
        "soil_humidity": 45.5518047584561
    },
    {
        "date": "2024-07-26 22:53:09.251267",
        "air_temp": 18.487328580099827,
        "humidity": 59.19194415224898,
        "rainfall": 0.5,
        "wind_speed": 2.566998532293896,
        "soil_moisture": 29.015494562059718,
        "ndvi": 0.26014482216055484,
        "soil_temp": 17.64811084459604,
        "soil_humidity": 34.76400230437422
    },
    {
        "date": "2024-07-26 23:53:09.251267",
        "air_temp": 28.81875476204932,
        "humidity": 62.811825447777096,
        "rainfall": 0,
        "wind_speed": 13.003743328913068,
        "soil_moisture": 37.43849363545574,
        "ndvi": 0.6306563268897386,
        "soil_temp": 22.16230806757227,
        "soil_humidity": 33.21772800837337
    },
    {
        "date": "2024-07-27 00:53:09.251267",
        "air_temp": 22.734706926010748,
        "humidity": 58.452651088045826,
        "rainfall": 1,
        "wind_speed": 19.649651612024957,
        "soil_moisture": 16.25955739475293,
        "ndvi": 0.607890875131982,
        "soil_temp": 16.556171241362126,
        "soil_humidity": 36.26031596321826
    },
    {
        "date": "2024-07-27 01:53:09.251267",
        "air_temp": 33.73459977473469,
        "humidity": 46.05125002425965,
        "rainfall": 0.5,
        "wind_speed": 18.23637139078894,
        "soil_moisture": 29.958185087107324,
        "ndvi": 0.6985534944931104,
        "soil_temp": 11.85820847943343,
        "soil_humidity": 39.99572779469459
    },
    {
        "date": "2024-07-27 02:53:09.251267",
        "air_temp": 17.750418882919867,
        "humidity": 60.94738950937377,
        "rainfall": 2,
        "wind_speed": 8.78261321609701,
        "soil_moisture": 12.360957301225284,
        "ndvi": 0.2249575737538817,
        "soil_temp": 18.4666276248072,
        "soil_humidity": 49.54073415723305
    },
    {
        "date": "2024-07-27 03:53:09.251267",
        "air_temp": 21.82132702100517,
        "humidity": 77.55890239042091,
        "rainfall": 0.5,
        "wind_speed": 0.7989594657218868,
        "soil_moisture": 28.11265431512839,
        "ndvi": 0.21386640108067534,
        "soil_temp": 24.284520769016602,
        "soil_humidity": 58.05991602263134
    },
    {
        "date": "2024-07-27 04:53:09.251267",
        "air_temp": 17.269470424811782,
        "humidity": 43.550383226662774,
        "rainfall": 1,
        "wind_speed": 7.245869800004097,
        "soil_moisture": 14.917951628596082,
        "ndvi": 0.4238042599271365,
        "soil_temp": 21.89490477088767,
        "soil_humidity": 69.455943059412
    },
    {
        "date": "2024-07-27 05:53:09.251267",
        "air_temp": 33.49387236557126,
        "humidity": 44.00900070409517,
        "rainfall": 2,
        "wind_speed": 16.527806232011105,
        "soil_moisture": 32.078606815455345,
        "ndvi": 0.28927297030949173,
        "soil_temp": 18.680509715383636,
        "soil_humidity": 47.66026973156684
    },
    {
        "date": "2024-07-27 06:53:09.251267",
        "air_temp": 32.54678706761962,
        "humidity": 57.73856459179921,
        "rainfall": 1,
        "wind_speed": 14.029982029303321,
        "soil_moisture": 19.036938843817246,
        "ndvi": 0.5350306865421333,
        "soil_temp": 19.702084905236454,
        "soil_humidity": 50.7273976937244
    },
    {
        "date": "2024-07-27 07:53:09.251267",
        "air_temp": 20.15883255430311,
        "humidity": 87.08628558094625,
        "rainfall": 0,
        "wind_speed": 9.186873111879128,
        "soil_moisture": 23.90173026413939,
        "ndvi": 0.7504093409040493,
        "soil_temp": 23.95843040553359,
        "soil_humidity": 50.872628055330225
    },
    {
        "date": "2024-07-27 08:53:09.251267",
        "air_temp": 28.19968092068358,
        "humidity": 73.42863233614571,
        "rainfall": 2,
        "wind_speed": 1.1020307031766219,
        "soil_moisture": 27.982916260828652,
        "ndvi": 0.4348878382004095,
        "soil_temp": 16.425141916524407,
        "soil_humidity": 51.57737421951744
    },
    {
        "date": "2024-07-27 09:53:09.251267",
        "air_temp": 31.344444004024318,
        "humidity": 73.93349783362824,
        "rainfall": 1,
        "wind_speed": 8.537894556466053,
        "soil_moisture": 15.138202319987187,
        "ndvi": 0.7069702263635627,
        "soil_temp": 14.97091208413956,
        "soil_humidity": 50.70962628417849
    },
    {
        "date": "2024-07-27 10:53:09.251267",
        "air_temp": 26.10401623198925,
        "humidity": 58.09599954189325,
        "rainfall": 2,
        "wind_speed": 9.38049879435767,
        "soil_moisture": 32.249134558528624,
        "ndvi": 0.2001445247107199,
        "soil_temp": 24.658623475006735,
        "soil_humidity": 30.350990202476712
    },
    {
        "date": "2024-07-27 11:53:09.251267",
        "air_temp": 25.59301156712013,
        "humidity": 69.68303956042692,
        "rainfall": 1,
        "wind_speed": 18.57111065788272,
        "soil_moisture": 19.079210219104436,
        "ndvi": 0.30110878607551056,
        "soil_temp": 22.951647002655,
        "soil_humidity": 30.06899288439363
    },
    {
        "date": "2024-07-27 12:53:09.251267",
        "air_temp": 19.837045818009035,
        "humidity": 40.50655920138717,
        "rainfall": 2,
        "wind_speed": 12.821722071643189,
        "soil_moisture": 29.871155797382606,
        "ndvi": 0.21825412379245138,
        "soil_temp": 14.845653769279036,
        "soil_humidity": 33.906652725044914
    },
    {
        "date": "2024-07-27 13:53:09.251267",
        "air_temp": 16.862055356117985,
        "humidity": 71.80480232047711,
        "rainfall": 1,
        "wind_speed": 6.233058333092978,
        "soil_moisture": 14.74849152098121,
        "ndvi": 0.3968883083090412,
        "soil_temp": 16.95670896041384,
        "soil_humidity": 34.062264137167155
    },
    {
        "date": "2024-07-27 14:53:09.251267",
        "air_temp": 32.944315159066534,
        "humidity": 85.66434737881184,
        "rainfall": 0,
        "wind_speed": 10.506365289014427,
        "soil_moisture": 30.64352977619051,
        "ndvi": 0.7449137574179019,
        "soil_temp": 16.226171935116987,
        "soil_humidity": 58.19587635819184
    },
    {
        "date": "2024-07-27 15:53:09.251267",
        "air_temp": 33.00836114326661,
        "humidity": 70.62867341724096,
        "rainfall": 2,
        "wind_speed": 1.3211061564432014,
        "soil_moisture": 13.845642184313853,
        "ndvi": 0.25817127415939267,
        "soil_temp": 12.23643518007151,
        "soil_humidity": 38.19664130063999
    },
    {
        "date": "2024-07-27 16:53:09.251267",
        "air_temp": 27.66202914546536,
        "humidity": 83.68492967331724,
        "rainfall": 2,
        "wind_speed": 19.451364712994735,
        "soil_moisture": 22.760694888099508,
        "ndvi": 0.5638458660462803,
        "soil_temp": 24.367289857198884,
        "soil_humidity": 65.78856419022452
    },
    {
        "date": "2024-07-27 17:53:09.251267",
        "air_temp": 21.780595820974014,
        "humidity": 76.19865277330469,
        "rainfall": 2,
        "wind_speed": 7.20119803861452,
        "soil_moisture": 17.416182212166305,
        "ndvi": 0.47089756347166656,
        "soil_temp": 16.064686468296383,
        "soil_humidity": 67.14425318308078
    },
    {
        "date": "2024-07-27 18:53:09.251267",
        "air_temp": 21.984191492253217,
        "humidity": 46.02792082018451,
        "rainfall": 2,
        "wind_speed": 9.557347098262717,
        "soil_moisture": 32.45125748284548,
        "ndvi": 0.7630809274194876,
        "soil_temp": 23.12993623956862,
        "soil_humidity": 69.03573351583594
    },
    {
        "date": "2024-07-27 19:53:09.251267",
        "air_temp": 29.519113577404788,
        "humidity": 85.12266536429209,
        "rainfall": 0,
        "wind_speed": 7.105679484738452,
        "soil_moisture": 17.861050135206238,
        "ndvi": 0.27351148552225635,
        "soil_temp": 12.840343415209976,
        "soil_humidity": 67.5632521954808
    },
    {
        "date": "2024-07-27 20:53:09.251267",
        "air_temp": 32.94220519905154,
        "humidity": 43.322192217788746,
        "rainfall": 2,
        "wind_speed": 13.650234602324003,
        "soil_moisture": 36.45151919186189,
        "ndvi": 0.32721671030133737,
        "soil_temp": 18.218062159558297,
        "soil_humidity": 40.875532876740984
    },
    {
        "date": "2024-07-27 21:53:09.251267",
        "air_temp": 32.741728485302346,
        "humidity": 66.6983736273882,
        "rainfall": 0.5,
        "wind_speed": 13.24464316659186,
        "soil_moisture": 36.754606342198386,
        "ndvi": 0.217725181166896,
        "soil_temp": 10.359420776864047,
        "soil_humidity": 50.3910737881405
    },
    {
        "date": "2024-07-27 22:53:09.251267",
        "air_temp": 30.597510917152476,
        "humidity": 47.10685618967288,
        "rainfall": 0,
        "wind_speed": 14.306446210582713,
        "soil_moisture": 15.893239456683576,
        "ndvi": 0.4603611497788243,
        "soil_temp": 21.46330725763463,
        "soil_humidity": 37.01819880787764
    },
    {
        "date": "2024-07-27 23:53:09.251267",
        "air_temp": 27.840632923085757,
        "humidity": 40.5853305365452,
        "rainfall": 0.5,
        "wind_speed": 5.362864592238417,
        "soil_moisture": 28.415378350231727,
        "ndvi": 0.3666997397387926,
        "soil_temp": 18.713708473694176,
        "soil_humidity": 62.24838620689732
    },
    {
        "date": "2024-07-28 00:53:09.251267",
        "air_temp": 16.682799299900978,
        "humidity": 61.10171300638195,
        "rainfall": 2,
        "wind_speed": 6.31336962328132,
        "soil_moisture": 12.63152925159893,
        "ndvi": 0.7219435603341926,
        "soil_temp": 19.38384375204629,
        "soil_humidity": 65.53726820576384
    },
    {
        "date": "2024-07-28 01:53:09.251267",
        "air_temp": 18.232574281892276,
        "humidity": 54.75203538270781,
        "rainfall": 1,
        "wind_speed": 2.505024621924159,
        "soil_moisture": 21.746112988048875,
        "ndvi": 0.5629644879186531,
        "soil_temp": 10.775813238319802,
        "soil_humidity": 64.0515535719006
    },
    {
        "date": "2024-07-28 02:53:09.251267",
        "air_temp": 32.97108377054158,
        "humidity": 64.30002468988015,
        "rainfall": 0.5,
        "wind_speed": 13.110199436922251,
        "soil_moisture": 31.399651885038168,
        "ndvi": 0.568454442785421,
        "soil_temp": 13.16557495312923,
        "soil_humidity": 47.334677947873395
    },
    {
        "date": "2024-07-28 03:53:09.251267",
        "air_temp": 27.1285811931918,
        "humidity": 68.85999433672754,
        "rainfall": 2,
        "wind_speed": 0.795942546713051,
        "soil_moisture": 29.782697405497487,
        "ndvi": 0.5191464181287815,
        "soil_temp": 11.114963085303703,
        "soil_humidity": 60.34695538747124
    },
    {
        "date": "2024-07-28 04:53:09.251267",
        "air_temp": 15.183941032332593,
        "humidity": 42.186953615447365,
        "rainfall": 0.5,
        "wind_speed": 11.953646710469181,
        "soil_moisture": 28.74102008219824,
        "ndvi": 0.25511020233752063,
        "soil_temp": 14.333714737057177,
        "soil_humidity": 33.90316678305078
    },
    {
        "date": "2024-07-28 05:53:09.251267",
        "air_temp": 17.02943085732064,
        "humidity": 46.15016475618728,
        "rainfall": 2,
        "wind_speed": 17.393917697032677,
        "soil_moisture": 18.476225909873307,
        "ndvi": 0.4670112102352132,
        "soil_temp": 19.411585115232263,
        "soil_humidity": 31.609790824770027
    },
    {
        "date": "2024-07-28 06:53:09.251267",
        "air_temp": 28.270035382161115,
        "humidity": 67.93211982252127,
        "rainfall": 2,
        "wind_speed": 17.81664296579267,
        "soil_moisture": 21.34074848303206,
        "ndvi": 0.7997031138251101,
        "soil_temp": 18.49278976476063,
        "soil_humidity": 60.129456572350435
    },
    {
        "date": "2024-07-28 07:53:09.251267",
        "air_temp": 15.101231676924375,
        "humidity": 57.15836765863291,
        "rainfall": 2,
        "wind_speed": 8.980755207399234,
        "soil_moisture": 13.088384341008584,
        "ndvi": 0.7011375745076089,
        "soil_temp": 19.182247324200855,
        "soil_humidity": 47.01421097314735
    },
    {
        "date": "2024-07-28 08:53:09.251267",
        "air_temp": 18.216161028349973,
        "humidity": 76.45867437747239,
        "rainfall": 1,
        "wind_speed": 9.186073764261476,
        "soil_moisture": 31.57068461300803,
        "ndvi": 0.7360754325002696,
        "soil_temp": 18.238769070668415,
        "soil_humidity": 69.82197058958278
    },
    {
        "date": "2024-07-28 09:53:09.251267",
        "air_temp": 25.974675787331723,
        "humidity": 72.614582429597,
        "rainfall": 0.5,
        "wind_speed": 1.5393836601603472,
        "soil_moisture": 25.116663903738207,
        "ndvi": 0.6939358155478997,
        "soil_temp": 14.09543193513789,
        "soil_humidity": 33.8901686342213
    },
    {
        "date": "2024-07-28 10:53:09.251267",
        "air_temp": 28.837903953853864,
        "humidity": 82.28021814949534,
        "rainfall": 0,
        "wind_speed": 13.415300508860714,
        "soil_moisture": 31.262968970369638,
        "ndvi": 0.26182501605858965,
        "soil_temp": 13.667612460166378,
        "soil_humidity": 64.22510726140935
    },
    {
        "date": "2024-07-28 11:53:09.251267",
        "air_temp": 28.03922519005201,
        "humidity": 74.62464133346126,
        "rainfall": 0,
        "wind_speed": 16.42518840745691,
        "soil_moisture": 17.468305743906182,
        "ndvi": 0.3364689524388798,
        "soil_temp": 12.05589525304872,
        "soil_humidity": 49.199322621159666
    },
    {
        "date": "2024-07-28 12:53:09.251267",
        "air_temp": 19.485386189211198,
        "humidity": 61.49655386950741,
        "rainfall": 0,
        "wind_speed": 4.893101092094261,
        "soil_moisture": 19.733146859051985,
        "ndvi": 0.2018729908138458,
        "soil_temp": 22.73606312355238,
        "soil_humidity": 63.08600782015508
    },
    {
        "date": "2024-07-28 13:53:09.251267",
        "air_temp": 29.24358442695072,
        "humidity": 73.64833111972162,
        "rainfall": 0,
        "wind_speed": 8.426017632667293,
        "soil_moisture": 20.02646011780344,
        "ndvi": 0.3821060749268669,
        "soil_temp": 19.247190378118667,
        "soil_humidity": 62.68509128680579
    },
    {
        "date": "2024-07-28 14:53:09.251267",
        "air_temp": 19.744981749936002,
        "humidity": 53.76905871277093,
        "rainfall": 2,
        "wind_speed": 16.776786735305407,
        "soil_moisture": 10.772272292416487,
        "ndvi": 0.2651644414078956,
        "soil_temp": 12.797665187560897,
        "soil_humidity": 66.6396800544831
    },
    {
        "date": "2024-07-28 15:53:09.251267",
        "air_temp": 21.507993963185356,
        "humidity": 55.31567493891063,
        "rainfall": 0.5,
        "wind_speed": 17.54240935324576,
        "soil_moisture": 20.231436184918707,
        "ndvi": 0.4836760071809499,
        "soil_temp": 12.658140697318593,
        "soil_humidity": 41.91400045144411
    },
    {
        "date": "2024-07-28 16:53:09.251267",
        "air_temp": 29.92982810236048,
        "humidity": 79.44925799603966,
        "rainfall": 0.5,
        "wind_speed": 4.863599605284518,
        "soil_moisture": 34.099137205541005,
        "ndvi": 0.6062764931443412,
        "soil_temp": 23.09553402289205,
        "soil_humidity": 67.99197413848012
    },
    {
        "date": "2024-07-28 17:53:09.251267",
        "air_temp": 27.992657980944294,
        "humidity": 62.32097582007911,
        "rainfall": 1,
        "wind_speed": 11.011412690827285,
        "soil_moisture": 36.91895487249215,
        "ndvi": 0.7247031770228463,
        "soil_temp": 21.14096295644979,
        "soil_humidity": 36.06667841430481
    },
    {
        "date": "2024-07-28 18:53:09.251267",
        "air_temp": 31.98446820988356,
        "humidity": 79.91901702591763,
        "rainfall": 0.5,
        "wind_speed": 3.849863012716541,
        "soil_moisture": 25.546549469077824,
        "ndvi": 0.6495302990545391,
        "soil_temp": 19.89098619858057,
        "soil_humidity": 33.27875110373962
    },
    {
        "date": "2024-07-28 19:53:09.251267",
        "air_temp": 28.15225784600687,
        "humidity": 81.12111868341393,
        "rainfall": 0.5,
        "wind_speed": 2.037575282791131,
        "soil_moisture": 23.665050856576606,
        "ndvi": 0.7631513574671032,
        "soil_temp": 16.102961613089498,
        "soil_humidity": 47.264620694893964
    },
    {
        "date": "2024-07-28 20:53:09.251267",
        "air_temp": 26.36617206670943,
        "humidity": 82.8781168830642,
        "rainfall": 0.5,
        "wind_speed": 18.22241002783915,
        "soil_moisture": 10.924738560981108,
        "ndvi": 0.23233897423627328,
        "soil_temp": 13.929633464177762,
        "soil_humidity": 63.132918745673706
    },
    {
        "date": "2024-07-28 21:53:09.251267",
        "air_temp": 16.87349535656185,
        "humidity": 85.83176179206036,
        "rainfall": 1,
        "wind_speed": 16.410150084642183,
        "soil_moisture": 36.37613012756148,
        "ndvi": 0.20730790124992488,
        "soil_temp": 24.691761446692354,
        "soil_humidity": 48.93684522533782
    },
    {
        "date": "2024-07-28 22:53:09.251267",
        "air_temp": 22.35431606118867,
        "humidity": 61.54926102566481,
        "rainfall": 0,
        "wind_speed": 15.200698036063478,
        "soil_moisture": 10.390306402776181,
        "ndvi": 0.5968028683017277,
        "soil_temp": 21.958593625342004,
        "soil_humidity": 63.171467101220564
    },
    {
        "date": "2024-07-28 23:53:09.251267",
        "air_temp": 20.30404735363451,
        "humidity": 55.94334091605694,
        "rainfall": 0.5,
        "wind_speed": 0.9753263762628084,
        "soil_moisture": 38.34250240630635,
        "ndvi": 0.37361144613334774,
        "soil_temp": 14.526688347044146,
        "soil_humidity": 59.187472231772205
    },
    {
        "date": "2024-07-29 00:53:09.251267",
        "air_temp": 19.87979286758167,
        "humidity": 69.1099373297057,
        "rainfall": 1,
        "wind_speed": 16.874575854205148,
        "soil_moisture": 24.67648746064272,
        "ndvi": 0.3521006096634639,
        "soil_temp": 22.71347194417742,
        "soil_humidity": 38.37713544555734
    },
    {
        "date": "2024-07-29 01:53:09.251267",
        "air_temp": 34.46021109504891,
        "humidity": 58.55862117188138,
        "rainfall": 1,
        "wind_speed": 8.760881518107675,
        "soil_moisture": 20.2580348072418,
        "ndvi": 0.39853225261627656,
        "soil_temp": 21.383979229220223,
        "soil_humidity": 67.95526499597855
    },
    {
        "date": "2024-07-29 02:53:09.251267",
        "air_temp": 22.861954493335208,
        "humidity": 70.05370834001165,
        "rainfall": 2,
        "wind_speed": 6.847902465917192,
        "soil_moisture": 37.7265530452798,
        "ndvi": 0.36187763473541856,
        "soil_temp": 14.456208248169975,
        "soil_humidity": 32.43124674517614
    },
    {
        "date": "2024-07-29 03:53:09.251267",
        "air_temp": 32.84093110354227,
        "humidity": 75.27929313219123,
        "rainfall": 1,
        "wind_speed": 10.929527220507529,
        "soil_moisture": 10.731729793302902,
        "ndvi": 0.5068618437809524,
        "soil_temp": 16.398292813624593,
        "soil_humidity": 65.9311883017518
    },
    {
        "date": "2024-07-29 04:53:09.251267",
        "air_temp": 27.622772519945258,
        "humidity": 74.42025092460685,
        "rainfall": 0,
        "wind_speed": 1.8270648544711166,
        "soil_moisture": 27.442746846072612,
        "ndvi": 0.46408906833447955,
        "soil_temp": 12.7893016808654,
        "soil_humidity": 42.17773795961496
    },
    {
        "date": "2024-07-29 05:53:09.251267",
        "air_temp": 30.896226070832967,
        "humidity": 58.72764435100606,
        "rainfall": 0.5,
        "wind_speed": 15.132930987047978,
        "soil_moisture": 26.46797573498013,
        "ndvi": 0.5074571308259939,
        "soil_temp": 11.66578666567164,
        "soil_humidity": 50.00592332657597
    },
    {
        "date": "2024-07-29 06:53:09.251267",
        "air_temp": 25.052741862103844,
        "humidity": 48.34301008493764,
        "rainfall": 1,
        "wind_speed": 11.648701537765918,
        "soil_moisture": 16.579612036341462,
        "ndvi": 0.6036281669002618,
        "soil_temp": 20.84143049259545,
        "soil_humidity": 46.89407490802938
    },
    {
        "date": "2024-07-29 07:53:09.251267",
        "air_temp": 26.53807769252718,
        "humidity": 61.52646692311038,
        "rainfall": 0,
        "wind_speed": 3.7318019374633504,
        "soil_moisture": 22.866622940506318,
        "ndvi": 0.432278305382689,
        "soil_temp": 14.012677860307999,
        "soil_humidity": 36.87338227667665
    },
    {
        "date": "2024-07-29 08:53:09.251267",
        "air_temp": 24.850353876377277,
        "humidity": 47.12970720409544,
        "rainfall": 1,
        "wind_speed": 3.764643995380912,
        "soil_moisture": 29.567920178313106,
        "ndvi": 0.3507846122852941,
        "soil_temp": 22.300890486193804,
        "soil_humidity": 44.325874656067725
    },
    {
        "date": "2024-07-29 09:53:09.251267",
        "air_temp": 18.90485975596089,
        "humidity": 84.50485175919042,
        "rainfall": 1,
        "wind_speed": 3.6849730314534845,
        "soil_moisture": 31.813935906457825,
        "ndvi": 0.2997203220852473,
        "soil_temp": 24.934130672716392,
        "soil_humidity": 37.71737745171181
    },
    {
        "date": "2024-07-29 10:53:09.251267",
        "air_temp": 29.44904230523011,
        "humidity": 57.29387303689287,
        "rainfall": 1,
        "wind_speed": 1.1400477510386797,
        "soil_moisture": 10.021505761197393,
        "ndvi": 0.40968447171957534,
        "soil_temp": 11.14929720894473,
        "soil_humidity": 38.155459018420956
    },
    {
        "date": "2024-07-29 11:53:09.251267",
        "air_temp": 20.615447248817116,
        "humidity": 47.72298736116619,
        "rainfall": 1,
        "wind_speed": 11.755968987433697,
        "soil_moisture": 38.10560290273046,
        "ndvi": 0.6450838851477033,
        "soil_temp": 17.095526876255505,
        "soil_humidity": 37.48227344105521
    },
    {
        "date": "2024-07-29 12:53:09.251267",
        "air_temp": 15.486319328629076,
        "humidity": 41.272387995287545,
        "rainfall": 1,
        "wind_speed": 16.806963551770163,
        "soil_moisture": 14.935761171784371,
        "ndvi": 0.6540229460668818,
        "soil_temp": 22.030938389662047,
        "soil_humidity": 49.56069521386377
    },
    {
        "date": "2024-07-29 13:53:09.251267",
        "air_temp": 27.909445918143355,
        "humidity": 72.29111931432422,
        "rainfall": 0,
        "wind_speed": 0.349964279747772,
        "soil_moisture": 17.007413026670775,
        "ndvi": 0.6001432320105262,
        "soil_temp": 21.834263448118502,
        "soil_humidity": 61.917254700452624
    },
    {
        "date": "2024-07-29 14:53:09.251267",
        "air_temp": 18.542213588140978,
        "humidity": 71.84511587273715,
        "rainfall": 0.5,
        "wind_speed": 0.980092899425471,
        "soil_moisture": 17.904203370815445,
        "ndvi": 0.3404169051841248,
        "soil_temp": 21.39995737496453,
        "soil_humidity": 52.758801350449644
    },
    {
        "date": "2024-07-29 15:53:09.251267",
        "air_temp": 33.80917168705828,
        "humidity": 57.03020832906458,
        "rainfall": 0.5,
        "wind_speed": 11.455405533738915,
        "soil_moisture": 11.134604096561059,
        "ndvi": 0.5528517475128187,
        "soil_temp": 15.234924029528663,
        "soil_humidity": 65.72826354203367
    },
    {
        "date": "2024-07-29 16:53:09.251267",
        "air_temp": 34.07857154005175,
        "humidity": 43.58559444238255,
        "rainfall": 0.5,
        "wind_speed": 16.389913707721046,
        "soil_moisture": 13.691563707709747,
        "ndvi": 0.5424137969461089,
        "soil_temp": 16.807798835275783,
        "soil_humidity": 55.46177954306897
    },
    {
        "date": "2024-07-29 17:53:09.251267",
        "air_temp": 33.29728780440897,
        "humidity": 60.4814447447616,
        "rainfall": 0.5,
        "wind_speed": 6.534777811372672,
        "soil_moisture": 39.59049768832486,
        "ndvi": 0.4905597188658353,
        "soil_temp": 18.924525360122054,
        "soil_humidity": 57.57360462530418
    },
    {
        "date": "2024-07-29 18:53:09.251267",
        "air_temp": 22.403174005108887,
        "humidity": 55.56087475316968,
        "rainfall": 0,
        "wind_speed": 6.992287976154444,
        "soil_moisture": 19.456502121318582,
        "ndvi": 0.5299873918303186,
        "soil_temp": 24.609108753598857,
        "soil_humidity": 56.70428918908013
    },
    {
        "date": "2024-07-29 19:53:09.251267",
        "air_temp": 15.309132330577349,
        "humidity": 73.85603198675047,
        "rainfall": 2,
        "wind_speed": 11.926929949935518,
        "soil_moisture": 13.459032710171481,
        "ndvi": 0.4950743795742009,
        "soil_temp": 14.913209064077392,
        "soil_humidity": 47.97836772734965
    },
    {
        "date": "2024-07-29 20:53:09.251267",
        "air_temp": 33.566371251754504,
        "humidity": 70.28896247263452,
        "rainfall": 2,
        "wind_speed": 5.48425778248294,
        "soil_moisture": 13.2097378352387,
        "ndvi": 0.539273819644465,
        "soil_temp": 23.847158315752864,
        "soil_humidity": 57.57355288800331
    },
    {
        "date": "2024-07-29 21:53:09.251267",
        "air_temp": 23.563682966346285,
        "humidity": 58.229686409877004,
        "rainfall": 0,
        "wind_speed": 0.5321673215536826,
        "soil_moisture": 31.583799030099158,
        "ndvi": 0.6293035727726773,
        "soil_temp": 14.040353772707412,
        "soil_humidity": 32.788309858758446
    },
    {
        "date": "2024-07-29 22:53:09.251267",
        "air_temp": 34.33309638087339,
        "humidity": 50.894627712749504,
        "rainfall": 0,
        "wind_speed": 14.704570362031921,
        "soil_moisture": 35.637059920979226,
        "ndvi": 0.2657769166420639,
        "soil_temp": 24.83611698210557,
        "soil_humidity": 64.39405887662653
    },
    {
        "date": "2024-07-29 23:53:09.251267",
        "air_temp": 34.272399541785056,
        "humidity": 89.40179830777959,
        "rainfall": 1,
        "wind_speed": 12.512998258829063,
        "soil_moisture": 17.708568985254097,
        "ndvi": 0.6497754512894143,
        "soil_temp": 20.053955302413875,
        "soil_humidity": 61.11248573775669
    },
    {
        "date": "2024-07-30 00:53:09.251267",
        "air_temp": 32.060189109347206,
        "humidity": 62.70008105980574,
        "rainfall": 1,
        "wind_speed": 11.615119981113182,
        "soil_moisture": 10.627285370057049,
        "ndvi": 0.28153169007655654,
        "soil_temp": 16.952672205378263,
        "soil_humidity": 39.809198329267154
    },
    {
        "date": "2024-07-30 01:53:09.251267",
        "air_temp": 20.888977841391714,
        "humidity": 74.41371178901186,
        "rainfall": 1,
        "wind_speed": 14.833682579836463,
        "soil_moisture": 16.692700553360673,
        "ndvi": 0.23997679904622482,
        "soil_temp": 19.200856420954075,
        "soil_humidity": 60.721298170163294
    },
    {
        "date": "2024-07-30 02:53:09.251267",
        "air_temp": 22.701954572038506,
        "humidity": 47.02764837891804,
        "rainfall": 0.5,
        "wind_speed": 13.50461675124075,
        "soil_moisture": 11.69547072390272,
        "ndvi": 0.7856478117328807,
        "soil_temp": 14.087967535603102,
        "soil_humidity": 30.505704056000738
    },
    {
        "date": "2024-07-30 03:53:09.251267",
        "air_temp": 32.02273343033714,
        "humidity": 64.27948498296989,
        "rainfall": 1,
        "wind_speed": 4.117639763566423,
        "soil_moisture": 13.101848098901245,
        "ndvi": 0.774195061322265,
        "soil_temp": 14.112607862672,
        "soil_humidity": 38.689862899235216
    },
    {
        "date": "2024-07-30 04:53:09.251267",
        "air_temp": 21.338440103125553,
        "humidity": 41.37683599347327,
        "rainfall": 2,
        "wind_speed": 7.049620938867022,
        "soil_moisture": 22.94524359542927,
        "ndvi": 0.23626192696433845,
        "soil_temp": 11.703318157652408,
        "soil_humidity": 32.60548954683584
    },
    {
        "date": "2024-07-30 05:53:09.251267",
        "air_temp": 18.38985493372185,
        "humidity": 65.2727558955225,
        "rainfall": 0.5,
        "wind_speed": 2.5222028335725,
        "soil_moisture": 16.897551125848977,
        "ndvi": 0.46595206488497737,
        "soil_temp": 11.710754465580981,
        "soil_humidity": 61.20907666471579
    },
    {
        "date": "2024-07-30 06:53:09.251267",
        "air_temp": 26.136025249167005,
        "humidity": 88.20087035588512,
        "rainfall": 1,
        "wind_speed": 2.5971402683576206,
        "soil_moisture": 25.478376355762702,
        "ndvi": 0.3117469629798649,
        "soil_temp": 15.21507612203367,
        "soil_humidity": 60.23496604713549
    },
    {
        "date": "2024-07-30 07:53:09.251267",
        "air_temp": 33.723095483215616,
        "humidity": 59.210335649290016,
        "rainfall": 0.5,
        "wind_speed": 13.523914927130784,
        "soil_moisture": 17.60481259771571,
        "ndvi": 0.49120693828065093,
        "soil_temp": 21.442832168445577,
        "soil_humidity": 62.54544095207323
    },
    {
        "date": "2024-07-30 08:53:09.251267",
        "air_temp": 28.92059593349946,
        "humidity": 41.94980062874558,
        "rainfall": 2,
        "wind_speed": 17.172002139649965,
        "soil_moisture": 38.21611903308843,
        "ndvi": 0.6981063457997525,
        "soil_temp": 19.119567513972438,
        "soil_humidity": 30.179267733021153
    },
    {
        "date": "2024-07-30 09:53:09.251267",
        "air_temp": 26.4012234017873,
        "humidity": 41.54778954596718,
        "rainfall": 1,
        "wind_speed": 19.254651677222782,
        "soil_moisture": 37.17831903778794,
        "ndvi": 0.7703670648457344,
        "soil_temp": 11.538768404965312,
        "soil_humidity": 50.04930906713632
    },
    {
        "date": "2024-07-30 10:53:09.251267",
        "air_temp": 16.943529875415372,
        "humidity": 59.39933799734347,
        "rainfall": 2,
        "wind_speed": 12.566942350988889,
        "soil_moisture": 27.00418315031398,
        "ndvi": 0.5805515920709226,
        "soil_temp": 22.783547874638664,
        "soil_humidity": 58.19344790069346
    },
    {
        "date": "2024-07-30 11:53:09.251267",
        "air_temp": 27.300144533983396,
        "humidity": 48.001246823159704,
        "rainfall": 2,
        "wind_speed": 3.6820264672191394,
        "soil_moisture": 20.632719759621587,
        "ndvi": 0.41234340032709027,
        "soil_temp": 19.020514404850996,
        "soil_humidity": 36.21604514956062
    },
    {
        "date": "2024-07-30 12:53:09.251267",
        "air_temp": 34.801077002085265,
        "humidity": 41.167604816409295,
        "rainfall": 2,
        "wind_speed": 2.113006908978614,
        "soil_moisture": 21.434406461042066,
        "ndvi": 0.5619953393867592,
        "soil_temp": 24.412833651771695,
        "soil_humidity": 55.99162910050499
    },
    {
        "date": "2024-07-30 13:53:09.251267",
        "air_temp": 17.801680304730482,
        "humidity": 77.81068069750884,
        "rainfall": 1,
        "wind_speed": 16.257019430735156,
        "soil_moisture": 22.35556849433491,
        "ndvi": 0.38720078366507027,
        "soil_temp": 20.052782011168112,
        "soil_humidity": 33.36440397238743
    },
    {
        "date": "2024-07-30 14:53:09.251267",
        "air_temp": 25.366593047274733,
        "humidity": 62.92615044409453,
        "rainfall": 0,
        "wind_speed": 11.575129527199985,
        "soil_moisture": 31.827379664048323,
        "ndvi": 0.7458941844992364,
        "soil_temp": 12.627622955562714,
        "soil_humidity": 37.253034244172525
    },
    {
        "date": "2024-07-30 15:53:09.251267",
        "air_temp": 32.54746143855911,
        "humidity": 54.46223859863272,
        "rainfall": 1,
        "wind_speed": 12.365700420274397,
        "soil_moisture": 23.549169865565137,
        "ndvi": 0.7222402778635022,
        "soil_temp": 17.106750434617332,
        "soil_humidity": 49.03187259978404
    },
    {
        "date": "2024-07-30 16:53:09.251267",
        "air_temp": 29.815372355084087,
        "humidity": 85.00415915082404,
        "rainfall": 2,
        "wind_speed": 15.629241775094185,
        "soil_moisture": 35.701898395176926,
        "ndvi": 0.5349390501677966,
        "soil_temp": 19.727463963208063,
        "soil_humidity": 56.14260610724408
    },
    {
        "date": "2024-07-30 17:53:09.251267",
        "air_temp": 28.94031481990536,
        "humidity": 45.80694801002628,
        "rainfall": 1,
        "wind_speed": 14.065544554649032,
        "soil_moisture": 38.32284357791094,
        "ndvi": 0.3948793492282163,
        "soil_temp": 10.423970515749666,
        "soil_humidity": 35.83476507815584
    },
    {
        "date": "2024-07-30 18:53:09.251267",
        "air_temp": 29.049681679742186,
        "humidity": 87.79594144776595,
        "rainfall": 2,
        "wind_speed": 16.689165057121933,
        "soil_moisture": 20.078384601368015,
        "ndvi": 0.37603380534978703,
        "soil_temp": 10.223653962322118,
        "soil_humidity": 37.40893323323752
    },
    {
        "date": "2024-07-30 19:53:09.251267",
        "air_temp": 22.189823024395103,
        "humidity": 55.698335868867645,
        "rainfall": 0,
        "wind_speed": 1.1242781091975695,
        "soil_moisture": 26.820834441270403,
        "ndvi": 0.2200507968230357,
        "soil_temp": 11.941045008978518,
        "soil_humidity": 38.17785468262652
    },
    {
        "date": "2024-07-30 20:53:09.251267",
        "air_temp": 20.871836885289866,
        "humidity": 84.42018361543407,
        "rainfall": 0.5,
        "wind_speed": 14.950412734930097,
        "soil_moisture": 12.851696104976932,
        "ndvi": 0.6894925106151772,
        "soil_temp": 13.77496652568206,
        "soil_humidity": 39.54803218795914
    },
    {
        "date": "2024-07-30 21:53:09.251267",
        "air_temp": 31.187223109570272,
        "humidity": 70.14714382397308,
        "rainfall": 0,
        "wind_speed": 17.01705514644191,
        "soil_moisture": 16.014071432990768,
        "ndvi": 0.47022992782340484,
        "soil_temp": 16.443236852810134,
        "soil_humidity": 39.06215394105165
    },
    {
        "date": "2024-07-30 22:53:09.251267",
        "air_temp": 31.202267893583617,
        "humidity": 81.33536798707436,
        "rainfall": 0,
        "wind_speed": 5.722700849166287,
        "soil_moisture": 22.403973001304806,
        "ndvi": 0.3950797570401587,
        "soil_temp": 17.311607106396828,
        "soil_humidity": 45.80596433990547
    },
    {
        "date": "2024-07-30 23:53:09.251267",
        "air_temp": 32.341446371602075,
        "humidity": 89.2006581605232,
        "rainfall": 0.5,
        "wind_speed": 12.808945479865057,
        "soil_moisture": 16.186081477053364,
        "ndvi": 0.48266621299865237,
        "soil_temp": 12.070414134068452,
        "soil_humidity": 56.15158603346892
    },
    {
        "date": "2024-07-31 00:53:09.251267",
        "air_temp": 33.26481105112943,
        "humidity": 54.420271890937286,
        "rainfall": 1,
        "wind_speed": 6.283241276739442,
        "soil_moisture": 36.29681472366153,
        "ndvi": 0.6912427942727912,
        "soil_temp": 20.10087368673746,
        "soil_humidity": 47.52127132537879
    },
    {
        "date": "2024-07-31 01:53:09.251267",
        "air_temp": 25.226847977218757,
        "humidity": 88.05677427037965,
        "rainfall": 0.5,
        "wind_speed": 19.15942132964908,
        "soil_moisture": 14.748587681772943,
        "ndvi": 0.27476852462451273,
        "soil_temp": 18.94207092544802,
        "soil_humidity": 61.03895634999553
    },
    {
        "date": "2024-07-31 02:53:09.251267",
        "air_temp": 25.030325893743992,
        "humidity": 59.47409474512102,
        "rainfall": 1,
        "wind_speed": 0.6684714487100751,
        "soil_moisture": 23.669824646592758,
        "ndvi": 0.7145935929450964,
        "soil_temp": 14.416961482617403,
        "soil_humidity": 58.770740237944594
    },
    {
        "date": "2024-07-31 03:53:09.251267",
        "air_temp": 30.965903579335503,
        "humidity": 59.275595236846115,
        "rainfall": 1,
        "wind_speed": 11.5958568766591,
        "soil_moisture": 28.17097721379627,
        "ndvi": 0.7385053010650124,
        "soil_temp": 10.20489825152938,
        "soil_humidity": 35.06107656196303
    },
    {
        "date": "2024-07-31 04:53:09.251267",
        "air_temp": 27.999278615555305,
        "humidity": 57.01935521272529,
        "rainfall": 2,
        "wind_speed": 3.3300709409903018,
        "soil_moisture": 30.755917244825334,
        "ndvi": 0.7680247490203329,
        "soil_temp": 11.164718730299361,
        "soil_humidity": 31.953664932829756
    },
    {
        "date": "2024-07-31 05:53:09.251267",
        "air_temp": 29.03933754515407,
        "humidity": 67.07038036737926,
        "rainfall": 2,
        "wind_speed": 14.244404103543173,
        "soil_moisture": 21.4920810917952,
        "ndvi": 0.4384927954535767,
        "soil_temp": 15.87542163266259,
        "soil_humidity": 65.11766255998424
    },
    {
        "date": "2024-07-31 06:53:09.251267",
        "air_temp": 30.91585338872202,
        "humidity": 47.707252158924355,
        "rainfall": 0.5,
        "wind_speed": 3.5178056683732417,
        "soil_moisture": 36.07297206174185,
        "ndvi": 0.3302842424226031,
        "soil_temp": 12.30961526748526,
        "soil_humidity": 33.19324266251493
    },
    {
        "date": "2024-07-31 07:53:09.251267",
        "air_temp": 32.800106836351326,
        "humidity": 67.68557426790056,
        "rainfall": 0,
        "wind_speed": 4.707665237967151,
        "soil_moisture": 27.049574532337765,
        "ndvi": 0.42418449108001915,
        "soil_temp": 15.652294092219563,
        "soil_humidity": 35.933567228788526
    },
    {
        "date": "2024-07-31 08:53:09.251267",
        "air_temp": 21.759903137030715,
        "humidity": 67.0852406205002,
        "rainfall": 0.5,
        "wind_speed": 9.887416220915162,
        "soil_moisture": 23.185233413426552,
        "ndvi": 0.399747257738842,
        "soil_temp": 19.42273906765533,
        "soil_humidity": 30.88533102232472
    },
    {
        "date": "2024-07-31 09:53:09.251267",
        "air_temp": 22.51165905279888,
        "humidity": 78.0950906302435,
        "rainfall": 1,
        "wind_speed": 18.29669899993756,
        "soil_moisture": 12.017219021561099,
        "ndvi": 0.30569234750171603,
        "soil_temp": 18.208561418189255,
        "soil_humidity": 55.13345461194615
    },
    {
        "date": "2024-07-31 10:53:09.251267",
        "air_temp": 16.87963879681738,
        "humidity": 81.68249809687927,
        "rainfall": 2,
        "wind_speed": 4.234287442959417,
        "soil_moisture": 23.94068405335495,
        "ndvi": 0.5643600020608928,
        "soil_temp": 13.467638247060913,
        "soil_humidity": 53.294822526333945
    },
    {
        "date": "2024-07-31 11:53:09.251267",
        "air_temp": 26.56560281992348,
        "humidity": 62.0220675156212,
        "rainfall": 0.5,
        "wind_speed": 2.8848004223620705,
        "soil_moisture": 34.62207522447191,
        "ndvi": 0.48597449630517736,
        "soil_temp": 15.157337368881286,
        "soil_humidity": 69.47614665741023
    },
    {
        "date": "2024-07-31 12:53:09.251267",
        "air_temp": 15.718845475934842,
        "humidity": 55.116662467574685,
        "rainfall": 0.5,
        "wind_speed": 15.179536637080613,
        "soil_moisture": 28.436230546639464,
        "ndvi": 0.7194205953944022,
        "soil_temp": 19.21046797672976,
        "soil_humidity": 65.47729585268688
    },
    {
        "date": "2024-07-31 13:53:09.251267",
        "air_temp": 24.311960362649202,
        "humidity": 52.96674486585472,
        "rainfall": 2,
        "wind_speed": 10.01239064667862,
        "soil_moisture": 34.96487361187355,
        "ndvi": 0.21926574828923495,
        "soil_temp": 22.91282542065173,
        "soil_humidity": 46.43372709923639
    },
    {
        "date": "2024-07-31 14:53:09.251267",
        "air_temp": 25.85289269415153,
        "humidity": 49.73119183524359,
        "rainfall": 2,
        "wind_speed": 18.235702247635427,
        "soil_moisture": 24.820350117979167,
        "ndvi": 0.5863207565330759,
        "soil_temp": 21.29820158108316,
        "soil_humidity": 44.8634336365014
    },
    {
        "date": "2024-07-31 15:53:09.251267",
        "air_temp": 20.730825042565687,
        "humidity": 42.882379109951245,
        "rainfall": 2,
        "wind_speed": 2.9322911431968457,
        "soil_moisture": 13.553851152834454,
        "ndvi": 0.6577693271069367,
        "soil_temp": 18.301000402501224,
        "soil_humidity": 41.8740813711994
    },
    {
        "date": "2024-07-31 16:53:09.251267",
        "air_temp": 26.816665211380215,
        "humidity": 57.12239894173567,
        "rainfall": 1,
        "wind_speed": 12.918557860460787,
        "soil_moisture": 10.409195375076433,
        "ndvi": 0.6556919415129843,
        "soil_temp": 15.434394974747292,
        "soil_humidity": 36.09627680151525
    },
    {
        "date": "2024-07-31 17:53:09.251267",
        "air_temp": 15.610004998780989,
        "humidity": 53.512384606539165,
        "rainfall": 2,
        "wind_speed": 4.657582923036301,
        "soil_moisture": 19.096586401557737,
        "ndvi": 0.7316443802573152,
        "soil_temp": 15.475769163127081,
        "soil_humidity": 35.52334626408036
    },
    {
        "date": "2024-07-31 18:53:09.251267",
        "air_temp": 15.746963774984287,
        "humidity": 88.32086929332037,
        "rainfall": 0,
        "wind_speed": 3.135208573032786,
        "soil_moisture": 30.939714144933774,
        "ndvi": 0.6374202456976965,
        "soil_temp": 23.191259653965943,
        "soil_humidity": 35.291348320384216
    },
    {
        "date": "2024-07-31 19:53:09.251267",
        "air_temp": 31.452011213193167,
        "humidity": 67.8854413125022,
        "rainfall": 0.5,
        "wind_speed": 13.66858830314541,
        "soil_moisture": 33.70779711825081,
        "ndvi": 0.7566860369886179,
        "soil_temp": 10.588653640608197,
        "soil_humidity": 34.56459028747114
    },
    {
        "date": "2024-07-31 20:53:09.251267",
        "air_temp": 22.20381282822526,
        "humidity": 57.363851951525376,
        "rainfall": 2,
        "wind_speed": 11.873692398174665,
        "soil_moisture": 18.872585038609884,
        "ndvi": 0.3995939598925349,
        "soil_temp": 24.819739666303914,
        "soil_humidity": 39.620481696221475
    },
    {
        "date": "2024-07-31 21:53:09.251267",
        "air_temp": 17.541210253037697,
        "humidity": 69.0233800496848,
        "rainfall": 2,
        "wind_speed": 15.096549167246625,
        "soil_moisture": 24.869617626497416,
        "ndvi": 0.5019256500509259,
        "soil_temp": 19.965631162773434,
        "soil_humidity": 42.266745212524654
    },
    {
        "date": "2024-07-31 22:53:09.251267",
        "air_temp": 25.444865201096086,
        "humidity": 46.95983506728477,
        "rainfall": 1,
        "wind_speed": 6.189251528923647,
        "soil_moisture": 22.63356713302245,
        "ndvi": 0.20844779796973442,
        "soil_temp": 20.217065486085858,
        "soil_humidity": 68.46315884155663
    },
    {
        "date": "2024-07-31 23:53:09.251267",
        "air_temp": 30.399871061972217,
        "humidity": 62.202187460110146,
        "rainfall": 1,
        "wind_speed": 8.959433127643798,
        "soil_moisture": 22.315683530542834,
        "ndvi": 0.20417455238197474,
        "soil_temp": 10.569967559571133,
        "soil_humidity": 53.13893004007543
    },
    {
        "date": "2024-08-01 00:53:09.251267",
        "air_temp": 19.316420549936865,
        "humidity": 71.31171607105318,
        "rainfall": 0.5,
        "wind_speed": 8.10943352660837,
        "soil_moisture": 16.940461396004515,
        "ndvi": 0.34407597227091197,
        "soil_temp": 19.082939123369083,
        "soil_humidity": 69.50383098053123
    },
    {
        "date": "2024-08-01 01:53:09.251267",
        "air_temp": 27.457809516380003,
        "humidity": 64.44489158962257,
        "rainfall": 0,
        "wind_speed": 19.681789279330285,
        "soil_moisture": 14.091133150883751,
        "ndvi": 0.2604843182834512,
        "soil_temp": 17.271670901356707,
        "soil_humidity": 44.89867791302693
    },
    {
        "date": "2024-08-01 02:53:09.251267",
        "air_temp": 16.70694929987536,
        "humidity": 60.08894003271621,
        "rainfall": 0.5,
        "wind_speed": 5.844979134787758,
        "soil_moisture": 15.900656746936393,
        "ndvi": 0.3561268207614522,
        "soil_temp": 11.586683603411005,
        "soil_humidity": 48.30506530357764
    },
    {
        "date": "2024-08-01 03:53:09.251267",
        "air_temp": 16.033634423372153,
        "humidity": 89.6924194449849,
        "rainfall": 2,
        "wind_speed": 5.8963447669353375,
        "soil_moisture": 11.659971614900384,
        "ndvi": 0.30622597671703844,
        "soil_temp": 14.168830161540386,
        "soil_humidity": 42.983146366611166
    },
    {
        "date": "2024-08-01 04:53:09.251267",
        "air_temp": 25.62709263136296,
        "humidity": 84.01573810744635,
        "rainfall": 0,
        "wind_speed": 7.090964176572148,
        "soil_moisture": 15.94392225470731,
        "ndvi": 0.21711201490318896,
        "soil_temp": 23.35471684320477,
        "soil_humidity": 37.31550603586211
    },
    {
        "date": "2024-08-01 05:53:09.251267",
        "air_temp": 25.81270243220213,
        "humidity": 71.17029790797201,
        "rainfall": 0,
        "wind_speed": 11.29188162580643,
        "soil_moisture": 10.276026928761384,
        "ndvi": 0.7455824878436297,
        "soil_temp": 18.249826237989296,
        "soil_humidity": 35.726588311103164
    },
    {
        "date": "2024-08-01 06:53:09.251267",
        "air_temp": 27.748598029964132,
        "humidity": 68.46886805911414,
        "rainfall": 0,
        "wind_speed": 5.046454885274905,
        "soil_moisture": 28.817752019079347,
        "ndvi": 0.20493387883333491,
        "soil_temp": 16.20124862760863,
        "soil_humidity": 67.80316791231328
    },
    {
        "date": "2024-08-01 07:53:09.251267",
        "air_temp": 29.52182667445323,
        "humidity": 71.0330239912292,
        "rainfall": 0,
        "wind_speed": 18.30579880758548,
        "soil_moisture": 19.491262221630485,
        "ndvi": 0.6416492866022514,
        "soil_temp": 11.090232337612164,
        "soil_humidity": 69.47242727195024
    },
    {
        "date": "2024-08-01 08:53:09.251267",
        "air_temp": 34.517041589250695,
        "humidity": 50.065605391121665,
        "rainfall": 0.5,
        "wind_speed": 5.31371129732527,
        "soil_moisture": 39.19434134280726,
        "ndvi": 0.2912887321517099,
        "soil_temp": 20.711822911034584,
        "soil_humidity": 31.796379860349262
    },
    {
        "date": "2024-08-01 09:53:09.251267",
        "air_temp": 25.326006966023904,
        "humidity": 59.75688716321593,
        "rainfall": 1,
        "wind_speed": 17.916314631203893,
        "soil_moisture": 15.811511523288935,
        "ndvi": 0.7473377731997601,
        "soil_temp": 20.54276235094049,
        "soil_humidity": 36.01383498927066
    },
    {
        "date": "2024-08-01 10:53:09.251267",
        "air_temp": 21.45912945882492,
        "humidity": 41.972976644544964,
        "rainfall": 1,
        "wind_speed": 2.069501272931693,
        "soil_moisture": 20.374857109287817,
        "ndvi": 0.7356775637539317,
        "soil_temp": 19.210654104588453,
        "soil_humidity": 46.9409636920409
    },
    {
        "date": "2024-08-01 11:53:09.251267",
        "air_temp": 30.90372389537407,
        "humidity": 63.77730187434133,
        "rainfall": 2,
        "wind_speed": 13.131254047993046,
        "soil_moisture": 20.735234759245998,
        "ndvi": 0.5923406878215545,
        "soil_temp": 17.653939693309738,
        "soil_humidity": 37.45333905377629
    },
    {
        "date": "2024-08-01 12:53:09.251267",
        "air_temp": 20.416645025241486,
        "humidity": 67.15122919788315,
        "rainfall": 1,
        "wind_speed": 18.139710368764874,
        "soil_moisture": 10.253544881873816,
        "ndvi": 0.6033404898803632,
        "soil_temp": 16.50676603311059,
        "soil_humidity": 44.55521574507533
    },
    {
        "date": "2024-08-01 13:53:09.251267",
        "air_temp": 23.779428414112722,
        "humidity": 51.38748680516048,
        "rainfall": 1,
        "wind_speed": 3.3749887679645907,
        "soil_moisture": 35.17623859118377,
        "ndvi": 0.20320348518181314,
        "soil_temp": 18.899066394819883,
        "soil_humidity": 35.163853994385335
    },
    {
        "date": "2024-08-01 14:53:09.251267",
        "air_temp": 16.56912762684532,
        "humidity": 88.20144235827568,
        "rainfall": 1,
        "wind_speed": 19.3665392019851,
        "soil_moisture": 10.959833804329742,
        "ndvi": 0.7909229396681614,
        "soil_temp": 13.500931775926102,
        "soil_humidity": 48.26047026887161
    },
    {
        "date": "2024-08-01 15:53:09.251267",
        "air_temp": 15.50701486830915,
        "humidity": 85.4727305468621,
        "rainfall": 1,
        "wind_speed": 9.057667208310233,
        "soil_moisture": 38.20539504187724,
        "ndvi": 0.7266995621454888,
        "soil_temp": 24.118885962664717,
        "soil_humidity": 40.867784646770374
    },
    {
        "date": "2024-08-01 16:53:09.251267",
        "air_temp": 34.2529682935585,
        "humidity": 76.1071617727953,
        "rainfall": 2,
        "wind_speed": 18.91430216882049,
        "soil_moisture": 36.762230532808616,
        "ndvi": 0.7370637354380554,
        "soil_temp": 18.831917937703444,
        "soil_humidity": 44.07620223034643
    },
    {
        "date": "2024-08-01 17:53:09.251267",
        "air_temp": 31.719602410244118,
        "humidity": 66.66933143826813,
        "rainfall": 0.5,
        "wind_speed": 13.53640087260094,
        "soil_moisture": 26.065657482578906,
        "ndvi": 0.5955467222305233,
        "soil_temp": 14.667886665315649,
        "soil_humidity": 33.772506058509904
    },
    {
        "date": "2024-08-01 18:53:09.251267",
        "air_temp": 28.91948412187396,
        "humidity": 83.49845798238425,
        "rainfall": 0,
        "wind_speed": 6.031737397471031,
        "soil_moisture": 26.307867247338763,
        "ndvi": 0.40687215719272196,
        "soil_temp": 16.74519776327736,
        "soil_humidity": 47.12512202840567
    },
    {
        "date": "2024-08-01 19:53:09.251267",
        "air_temp": 23.179058888285397,
        "humidity": 46.532617191173145,
        "rainfall": 1,
        "wind_speed": 3.7660504983421283,
        "soil_moisture": 29.61333768868469,
        "ndvi": 0.7361891894542887,
        "soil_temp": 22.085271833153385,
        "soil_humidity": 69.33030443846279
    },
    {
        "date": "2024-08-01 20:53:09.251267",
        "air_temp": 18.465886401416917,
        "humidity": 79.52548971374425,
        "rainfall": 0.5,
        "wind_speed": 6.074700924501988,
        "soil_moisture": 21.61150035804661,
        "ndvi": 0.37491015689071544,
        "soil_temp": 12.424240910954461,
        "soil_humidity": 43.15332187524761
    },
    {
        "date": "2024-08-01 21:53:09.251267",
        "air_temp": 18.12874085342172,
        "humidity": 46.24104069834242,
        "rainfall": 1,
        "wind_speed": 1.8603481852534998,
        "soil_moisture": 29.416668501935664,
        "ndvi": 0.5376269260053218,
        "soil_temp": 14.392534336903907,
        "soil_humidity": 65.05717099369741
    },
    {
        "date": "2024-08-01 22:53:09.251267",
        "air_temp": 20.004857963291904,
        "humidity": 79.71053312747027,
        "rainfall": 1,
        "wind_speed": 2.8773593268665953,
        "soil_moisture": 11.343051660654606,
        "ndvi": 0.2598385834063295,
        "soil_temp": 20.192620341048332,
        "soil_humidity": 63.727965664947405
    },
    {
        "date": "2024-08-01 23:53:09.251267",
        "air_temp": 25.984533294122407,
        "humidity": 53.78837841256785,
        "rainfall": 0,
        "wind_speed": 2.093208806929623,
        "soil_moisture": 11.621428820491797,
        "ndvi": 0.7531733780796501,
        "soil_temp": 14.73233809723336,
        "soil_humidity": 68.08233673188843
    },
    {
        "date": "2024-08-02 00:53:09.251267",
        "air_temp": 29.291918454001248,
        "humidity": 83.85452905090945,
        "rainfall": 0,
        "wind_speed": 8.458870874831154,
        "soil_moisture": 23.716912588586332,
        "ndvi": 0.284462274662728,
        "soil_temp": 21.88752522792199,
        "soil_humidity": 61.60438049068378
    },
    {
        "date": "2024-08-02 01:53:09.251267",
        "air_temp": 28.203947534354626,
        "humidity": 87.2021046912699,
        "rainfall": 0,
        "wind_speed": 15.911567525151728,
        "soil_moisture": 26.488669033181047,
        "ndvi": 0.3349380834382444,
        "soil_temp": 11.956802307660412,
        "soil_humidity": 56.38387308235259
    },
    {
        "date": "2024-08-02 02:53:09.251267",
        "air_temp": 20.598677938918858,
        "humidity": 47.43979847025578,
        "rainfall": 1,
        "wind_speed": 2.827671084326804,
        "soil_moisture": 36.634529210074575,
        "ndvi": 0.534880494876929,
        "soil_temp": 21.440292556819127,
        "soil_humidity": 64.2633777690983
    },
    {
        "date": "2024-08-02 03:53:09.251267",
        "air_temp": 34.097305613263885,
        "humidity": 63.13378025326968,
        "rainfall": 1,
        "wind_speed": 2.6064086634467176,
        "soil_moisture": 13.139819017236888,
        "ndvi": 0.6584558001277889,
        "soil_temp": 11.025275722758494,
        "soil_humidity": 33.025787226293986
    },
    {
        "date": "2024-08-02 04:53:09.251267",
        "air_temp": 29.75793833391537,
        "humidity": 89.04936890813426,
        "rainfall": 1,
        "wind_speed": 12.868578990346615,
        "soil_moisture": 34.679437815816684,
        "ndvi": 0.5716107835617794,
        "soil_temp": 10.188889190012421,
        "soil_humidity": 48.51322628795231
    },
    {
        "date": "2024-08-02 05:53:09.251267",
        "air_temp": 26.087081050228015,
        "humidity": 64.17037179865733,
        "rainfall": 0,
        "wind_speed": 3.9436596393613255,
        "soil_moisture": 16.30142011876209,
        "ndvi": 0.33928034768009374,
        "soil_temp": 23.47335278248243,
        "soil_humidity": 69.57024954460235
    },
    {
        "date": "2024-08-02 06:53:09.251267",
        "air_temp": 27.234414924687044,
        "humidity": 83.17737612572725,
        "rainfall": 2,
        "wind_speed": 1.952200762179832,
        "soil_moisture": 21.822509455300956,
        "ndvi": 0.2005658255164942,
        "soil_temp": 18.72008825398448,
        "soil_humidity": 60.78400605178929
    },
    {
        "date": "2024-08-02 07:53:09.251267",
        "air_temp": 23.3920012485558,
        "humidity": 69.436576106566,
        "rainfall": 0.5,
        "wind_speed": 3.3369715641115527,
        "soil_moisture": 19.498073044822387,
        "ndvi": 0.6542903481844635,
        "soil_temp": 15.277403456302173,
        "soil_humidity": 68.48301512148893
    },
    {
        "date": "2024-08-02 08:53:09.251267",
        "air_temp": 19.95461979002315,
        "humidity": 58.766495395980094,
        "rainfall": 2,
        "wind_speed": 18.47462791753552,
        "soil_moisture": 22.00465818596197,
        "ndvi": 0.7911243955466563,
        "soil_temp": 13.488919554457755,
        "soil_humidity": 61.18537424533916
    },
    {
        "date": "2024-08-02 09:53:09.251267",
        "air_temp": 22.119453573025233,
        "humidity": 54.28917777629351,
        "rainfall": 1,
        "wind_speed": 16.429425055523815,
        "soil_moisture": 14.514864942177567,
        "ndvi": 0.6859478721836543,
        "soil_temp": 19.25328674891224,
        "soil_humidity": 64.99527869768576
    },
    {
        "date": "2024-08-02 10:53:09.251267",
        "air_temp": 30.15692220928738,
        "humidity": 50.16115455430436,
        "rainfall": 1,
        "wind_speed": 7.081291247101882,
        "soil_moisture": 25.107102232106648,
        "ndvi": 0.47612397089396924,
        "soil_temp": 17.63503275305107,
        "soil_humidity": 58.261185292127635
    },
    {
        "date": "2024-08-02 11:53:09.251267",
        "air_temp": 15.287869772595117,
        "humidity": 78.08990956888486,
        "rainfall": 2,
        "wind_speed": 13.116415346547857,
        "soil_moisture": 34.04927769494102,
        "ndvi": 0.7422603568394641,
        "soil_temp": 24.873966632949777,
        "soil_humidity": 60.420080176689595
    },
    {
        "date": "2024-08-02 12:53:09.251267",
        "air_temp": 17.321452810138325,
        "humidity": 59.3270253411514,
        "rainfall": 1,
        "wind_speed": 10.745513473360761,
        "soil_moisture": 23.24602031136691,
        "ndvi": 0.44590860387101006,
        "soil_temp": 24.748239799806186,
        "soil_humidity": 50.927120393580026
    },
    {
        "date": "2024-08-02 13:53:09.251267",
        "air_temp": 15.920052840435055,
        "humidity": 65.56377311003756,
        "rainfall": 0.5,
        "wind_speed": 13.542485269651278,
        "soil_moisture": 24.718407276945975,
        "ndvi": 0.5149604505460805,
        "soil_temp": 19.525368492124144,
        "soil_humidity": 66.96950803256532
    },
    {
        "date": "2024-08-02 14:53:09.251267",
        "air_temp": 15.814576046379402,
        "humidity": 64.6162740000591,
        "rainfall": 1,
        "wind_speed": 13.653423049255187,
        "soil_moisture": 35.46302755843943,
        "ndvi": 0.48627786043741,
        "soil_temp": 16.64305759158836,
        "soil_humidity": 63.00155642493446
    },
    {
        "date": "2024-08-02 15:53:09.251267",
        "air_temp": 32.10921168022014,
        "humidity": 68.86395146165657,
        "rainfall": 0.5,
        "wind_speed": 12.574680301199859,
        "soil_moisture": 31.181743439140924,
        "ndvi": 0.468574323599315,
        "soil_temp": 17.36711453701993,
        "soil_humidity": 63.755398626625286
    },
    {
        "date": "2024-08-02 16:53:09.251267",
        "air_temp": 29.073157187600472,
        "humidity": 83.2788572812276,
        "rainfall": 2,
        "wind_speed": 5.322027370746172,
        "soil_moisture": 24.71363225559354,
        "ndvi": 0.6651144615763436,
        "soil_temp": 20.237185993552927,
        "soil_humidity": 56.920364038522095
    },
    {
        "date": "2024-08-02 17:53:09.251267",
        "air_temp": 24.483476581746505,
        "humidity": 89.03696717917147,
        "rainfall": 2,
        "wind_speed": 0.7801284152563626,
        "soil_moisture": 17.73086944567539,
        "ndvi": 0.5160613443616953,
        "soil_temp": 11.52361816353072,
        "soil_humidity": 42.18319788348996
    },
    {
        "date": "2024-08-02 18:53:09.251267",
        "air_temp": 16.95668321302003,
        "humidity": 60.37921042869085,
        "rainfall": 1,
        "wind_speed": 9.111335639802176,
        "soil_moisture": 36.547802405349,
        "ndvi": 0.3900302732151297,
        "soil_temp": 14.925281107160773,
        "soil_humidity": 31.425191815605572
    },
    {
        "date": "2024-08-02 19:53:09.251267",
        "air_temp": 24.832317502336647,
        "humidity": 81.37594610531515,
        "rainfall": 0.5,
        "wind_speed": 17.819477693814076,
        "soil_moisture": 27.31061205071675,
        "ndvi": 0.354894900595087,
        "soil_temp": 11.129083615054057,
        "soil_humidity": 67.42620517638179
    },
    {
        "date": "2024-08-02 20:53:09.251267",
        "air_temp": 24.469435415611315,
        "humidity": 78.22638973273588,
        "rainfall": 1,
        "wind_speed": 11.389945170371519,
        "soil_moisture": 16.634428303320803,
        "ndvi": 0.22264839018046004,
        "soil_temp": 11.100411392753793,
        "soil_humidity": 53.444813952219874
    },
    {
        "date": "2024-08-02 21:53:09.251267",
        "air_temp": 18.464037398200304,
        "humidity": 68.67644757277867,
        "rainfall": 0,
        "wind_speed": 16.647820834038157,
        "soil_moisture": 18.07359257914031,
        "ndvi": 0.5499170971298277,
        "soil_temp": 12.937588628413506,
        "soil_humidity": 41.92571884592561
    },
    {
        "date": "2024-08-02 22:53:09.251267",
        "air_temp": 23.67703298475946,
        "humidity": 87.80235718059666,
        "rainfall": 0,
        "wind_speed": 19.832486859224563,
        "soil_moisture": 34.09399266808738,
        "ndvi": 0.33797684924062055,
        "soil_temp": 17.2787988188984,
        "soil_humidity": 35.965404313901516
    },
    {
        "date": "2024-08-02 23:53:09.251267",
        "air_temp": 22.97009468794747,
        "humidity": 50.023725776420136,
        "rainfall": 0,
        "wind_speed": 3.2723240827655253,
        "soil_moisture": 11.324366862623664,
        "ndvi": 0.28888035320982985,
        "soil_temp": 21.77161481744184,
        "soil_humidity": 63.180926620030185
    },
    {
        "date": "2024-08-03 00:53:09.251267",
        "air_temp": 27.31700196104433,
        "humidity": 45.46321051548107,
        "rainfall": 0,
        "wind_speed": 13.835552782839645,
        "soil_moisture": 39.762765610864605,
        "ndvi": 0.5922979782966067,
        "soil_temp": 17.282438367488087,
        "soil_humidity": 63.460807777903156
    },
    {
        "date": "2024-08-03 01:53:09.251267",
        "air_temp": 27.701873017352874,
        "humidity": 82.69809834777111,
        "rainfall": 1,
        "wind_speed": 13.485871474000493,
        "soil_moisture": 13.910550391443753,
        "ndvi": 0.6087255546929293,
        "soil_temp": 15.032149158413047,
        "soil_humidity": 62.58031872522126
    },
    {
        "date": "2024-08-03 02:53:09.251267",
        "air_temp": 15.90608019544089,
        "humidity": 61.957490831687416,
        "rainfall": 0,
        "wind_speed": 19.59910894716557,
        "soil_moisture": 33.88205576190812,
        "ndvi": 0.6512929353545996,
        "soil_temp": 11.235294855051372,
        "soil_humidity": 55.80330767867145
    },
    {
        "date": "2024-08-03 03:53:09.251267",
        "air_temp": 22.492252292529425,
        "humidity": 82.34844271035303,
        "rainfall": 0.5,
        "wind_speed": 19.712014679056008,
        "soil_moisture": 21.843259503150485,
        "ndvi": 0.5021461378031549,
        "soil_temp": 18.254432847746944,
        "soil_humidity": 44.640615503675036
    },
    {
        "date": "2024-08-03 04:53:09.251267",
        "air_temp": 27.517198314284727,
        "humidity": 84.65451025977075,
        "rainfall": 2,
        "wind_speed": 13.73357223448259,
        "soil_moisture": 24.766485498624007,
        "ndvi": 0.736432763122332,
        "soil_temp": 14.06902879724765,
        "soil_humidity": 42.16281139121028
    },
    {
        "date": "2024-08-03 05:53:09.251267",
        "air_temp": 25.062725171601755,
        "humidity": 43.122879680953574,
        "rainfall": 2,
        "wind_speed": 4.872295839643885,
        "soil_moisture": 36.419517323937335,
        "ndvi": 0.7618056520389367,
        "soil_temp": 19.22401266875721,
        "soil_humidity": 30.34219080472681
    },
    {
        "date": "2024-08-03 06:53:09.251267",
        "air_temp": 32.12979682376644,
        "humidity": 84.17323814930303,
        "rainfall": 0,
        "wind_speed": 15.971116762611981,
        "soil_moisture": 22.387335687676398,
        "ndvi": 0.6844225638754937,
        "soil_temp": 24.657816086986273,
        "soil_humidity": 63.34430234752961
    },
    {
        "date": "2024-08-03 07:53:09.251267",
        "air_temp": 28.173872632378902,
        "humidity": 62.41595276847111,
        "rainfall": 0,
        "wind_speed": 2.140582684220762,
        "soil_moisture": 26.124812610197033,
        "ndvi": 0.5813560892049123,
        "soil_temp": 11.669714822163252,
        "soil_humidity": 33.4973078997457
    },
    {
        "date": "2024-08-03 08:53:09.251267",
        "air_temp": 18.258688541628594,
        "humidity": 65.52162445021162,
        "rainfall": 0,
        "wind_speed": 15.613986083432573,
        "soil_moisture": 11.478989872275099,
        "ndvi": 0.38223257215471323,
        "soil_temp": 13.1818809808291,
        "soil_humidity": 55.23019992163071
    },
    {
        "date": "2024-08-03 09:53:09.251267",
        "air_temp": 16.411374948008596,
        "humidity": 71.32962555466051,
        "rainfall": 1,
        "wind_speed": 18.20392248289393,
        "soil_moisture": 10.070424216023595,
        "ndvi": 0.7897669529721327,
        "soil_temp": 17.09641807149062,
        "soil_humidity": 50.16299787360353
    },
    {
        "date": "2024-08-03 10:53:09.251267",
        "air_temp": 27.84838556412631,
        "humidity": 86.31913033650434,
        "rainfall": 2,
        "wind_speed": 7.656667895368705,
        "soil_moisture": 17.343996190051776,
        "ndvi": 0.364481700315163,
        "soil_temp": 24.529207230188945,
        "soil_humidity": 51.06757435705404
    },
    {
        "date": "2024-08-03 11:53:09.251267",
        "air_temp": 15.530226210832437,
        "humidity": 40.9558047214882,
        "rainfall": 0,
        "wind_speed": 12.61270589966161,
        "soil_moisture": 30.41749278756302,
        "ndvi": 0.32159415319425866,
        "soil_temp": 21.577146644049932,
        "soil_humidity": 67.06851381231093
    },
    {
        "date": "2024-08-03 12:53:09.251267",
        "air_temp": 26.715511625469265,
        "humidity": 63.842173415890855,
        "rainfall": 0.5,
        "wind_speed": 8.602854641936526,
        "soil_moisture": 13.6379468007741,
        "ndvi": 0.4652924682719497,
        "soil_temp": 23.47343789744245,
        "soil_humidity": 31.264600450849684
    },
    {
        "date": "2024-08-03 13:53:09.251267",
        "air_temp": 33.80460482849915,
        "humidity": 74.38610212152804,
        "rainfall": 0,
        "wind_speed": 8.755301099762569,
        "soil_moisture": 13.412858764136484,
        "ndvi": 0.2341043424274331,
        "soil_temp": 15.005722727136893,
        "soil_humidity": 60.93568898515089
    },
    {
        "date": "2024-08-03 14:53:09.251267",
        "air_temp": 26.50948355751758,
        "humidity": 76.13534788491143,
        "rainfall": 2,
        "wind_speed": 6.207417782162401,
        "soil_moisture": 22.328818241077098,
        "ndvi": 0.7870966788030747,
        "soil_temp": 24.88057710667892,
        "soil_humidity": 32.08209593469228
    },
    {
        "date": "2024-08-03 15:53:09.251267",
        "air_temp": 22.763398524130437,
        "humidity": 74.6266215768554,
        "rainfall": 0,
        "wind_speed": 15.242592409006493,
        "soil_moisture": 29.435111158246794,
        "ndvi": 0.553060133849855,
        "soil_temp": 11.54249224096236,
        "soil_humidity": 31.52614423741509
    },
    {
        "date": "2024-08-03 16:53:09.251267",
        "air_temp": 27.865764368847064,
        "humidity": 46.72175647040609,
        "rainfall": 2,
        "wind_speed": 18.723531283330104,
        "soil_moisture": 31.362159767437916,
        "ndvi": 0.5341712549985052,
        "soil_temp": 10.794593590691512,
        "soil_humidity": 47.19270792565528
    },
    {
        "date": "2024-08-03 17:53:09.251267",
        "air_temp": 24.165057809830333,
        "humidity": 54.96977941858406,
        "rainfall": 0,
        "wind_speed": 18.81501718468922,
        "soil_moisture": 15.353520222951243,
        "ndvi": 0.593477767939832,
        "soil_temp": 22.834039405810422,
        "soil_humidity": 32.523006973718665
    },
    {
        "date": "2024-08-03 18:53:09.251267",
        "air_temp": 25.9123357863187,
        "humidity": 57.935776270850674,
        "rainfall": 0.5,
        "wind_speed": 3.4956256974986766,
        "soil_moisture": 12.387644421161273,
        "ndvi": 0.49821418866632405,
        "soil_temp": 23.908973053104795,
        "soil_humidity": 61.565758051716486
    },
    {
        "date": "2024-08-03 19:53:09.251267",
        "air_temp": 33.8292961755305,
        "humidity": 80.22186038397524,
        "rainfall": 0,
        "wind_speed": 17.49487299313132,
        "soil_moisture": 38.21191101836632,
        "ndvi": 0.21923811025672496,
        "soil_temp": 11.358780363191526,
        "soil_humidity": 51.18252374593349
    },
    {
        "date": "2024-08-03 20:53:09.251267",
        "air_temp": 22.722052756015486,
        "humidity": 53.93799362072765,
        "rainfall": 0.5,
        "wind_speed": 14.748628828542444,
        "soil_moisture": 17.819991476863052,
        "ndvi": 0.5673867733042381,
        "soil_temp": 11.701411401187622,
        "soil_humidity": 31.161551306390976
    },
    {
        "date": "2024-08-03 21:53:09.251267",
        "air_temp": 34.22381127647829,
        "humidity": 50.53524819336016,
        "rainfall": 0.5,
        "wind_speed": 5.056748013805786,
        "soil_moisture": 35.600394928261714,
        "ndvi": 0.6297043261738584,
        "soil_temp": 10.497612366336607,
        "soil_humidity": 31.62002745927678
    },
    {
        "date": "2024-08-03 22:53:09.251267",
        "air_temp": 33.107012839121275,
        "humidity": 87.87240095190481,
        "rainfall": 0,
        "wind_speed": 1.345692258430291,
        "soil_moisture": 35.90474351388455,
        "ndvi": 0.45004943637443195,
        "soil_temp": 11.567613439640862,
        "soil_humidity": 54.506741189579174
    },
    {
        "date": "2024-08-03 23:53:09.251267",
        "air_temp": 18.915822695785927,
        "humidity": 40.443164255673445,
        "rainfall": 0.5,
        "wind_speed": 5.359945337687559,
        "soil_moisture": 33.17567775287633,
        "ndvi": 0.6543773432427158,
        "soil_temp": 13.141297252004213,
        "soil_humidity": 62.2444727063362
    },
    {
        "date": "2024-08-04 00:53:09.251267",
        "air_temp": 16.38722601750331,
        "humidity": 89.89104278409891,
        "rainfall": 1,
        "wind_speed": 18.244164735630513,
        "soil_moisture": 15.065008199577102,
        "ndvi": 0.3362007796030524,
        "soil_temp": 24.551149283650012,
        "soil_humidity": 47.51198125099094
    },
    {
        "date": "2024-08-04 01:53:09.251267",
        "air_temp": 17.01556002754853,
        "humidity": 73.84106494286978,
        "rainfall": 0,
        "wind_speed": 12.663573497915445,
        "soil_moisture": 34.654043777626015,
        "ndvi": 0.3327659546397105,
        "soil_temp": 20.017903333595207,
        "soil_humidity": 52.18322686142498
    },
    {
        "date": "2024-08-04 02:53:09.251267",
        "air_temp": 15.364436513030995,
        "humidity": 81.42346497245586,
        "rainfall": 0,
        "wind_speed": 14.421093217957438,
        "soil_moisture": 33.0775167772476,
        "ndvi": 0.2968501926125444,
        "soil_temp": 21.67829538277813,
        "soil_humidity": 61.99317816199606
    },
    {
        "date": "2024-08-04 03:53:09.251267",
        "air_temp": 16.88885921511857,
        "humidity": 54.730969817309656,
        "rainfall": 0.5,
        "wind_speed": 5.143047022181917,
        "soil_moisture": 27.901689703186513,
        "ndvi": 0.42319685587349715,
        "soil_temp": 21.64209105537283,
        "soil_humidity": 54.42024696167277
    },
    {
        "date": "2024-08-04 04:53:09.251267",
        "air_temp": 28.660135468327137,
        "humidity": 40.715763883353,
        "rainfall": 0.5,
        "wind_speed": 14.387763111496405,
        "soil_moisture": 20.116061055068585,
        "ndvi": 0.7082072212334503,
        "soil_temp": 18.02610624668822,
        "soil_humidity": 38.990409506352776
    },
    {
        "date": "2024-08-04 05:53:09.251267",
        "air_temp": 16.42377296920458,
        "humidity": 76.8934759280765,
        "rainfall": 0,
        "wind_speed": 0.5624301255584752,
        "soil_moisture": 17.875258127996325,
        "ndvi": 0.6967472372894387,
        "soil_temp": 21.96233466569526,
        "soil_humidity": 54.52208103477915
    },
    {
        "date": "2024-08-04 06:53:09.251267",
        "air_temp": 21.379512605875227,
        "humidity": 81.7072298500338,
        "rainfall": 1,
        "wind_speed": 7.506426915460276,
        "soil_moisture": 13.656676362410769,
        "ndvi": 0.4296945247781023,
        "soil_temp": 18.937112985849545,
        "soil_humidity": 55.13117836260854
    },
    {
        "date": "2024-08-04 07:53:09.251267",
        "air_temp": 31.89750621938909,
        "humidity": 77.02380659767596,
        "rainfall": 0.5,
        "wind_speed": 12.535066970846625,
        "soil_moisture": 25.292712007026143,
        "ndvi": 0.553471241588142,
        "soil_temp": 14.718191464050363,
        "soil_humidity": 59.364279235245064
    },
    {
        "date": "2024-08-04 08:53:09.251267",
        "air_temp": 15.465438714716518,
        "humidity": 47.14251436504193,
        "rainfall": 0.5,
        "wind_speed": 2.9219313108264044,
        "soil_moisture": 27.557826265295397,
        "ndvi": 0.6324897898849495,
        "soil_temp": 18.416651631526012,
        "soil_humidity": 54.83496750227205
    },
    {
        "date": "2024-08-04 09:53:09.251267",
        "air_temp": 31.289369651778717,
        "humidity": 77.67139611284846,
        "rainfall": 2,
        "wind_speed": 16.460071488938695,
        "soil_moisture": 20.78016971547362,
        "ndvi": 0.34472406477681383,
        "soil_temp": 18.002628792017703,
        "soil_humidity": 68.76968521832151
    },
    {
        "date": "2024-08-04 10:53:09.251267",
        "air_temp": 20.637095495468,
        "humidity": 78.44613910538206,
        "rainfall": 1,
        "wind_speed": 4.700625976238372,
        "soil_moisture": 24.260772030426057,
        "ndvi": 0.3008846515368483,
        "soil_temp": 22.35179580638981,
        "soil_humidity": 37.89153234868274
    },
    {
        "date": "2024-08-04 11:53:09.251267",
        "air_temp": 17.363296552433127,
        "humidity": 72.92702578350274,
        "rainfall": 1,
        "wind_speed": 13.045622256368926,
        "soil_moisture": 20.905433721078,
        "ndvi": 0.7832575959273866,
        "soil_temp": 24.725908260724886,
        "soil_humidity": 68.04362718268305
    },
    {
        "date": "2024-08-04 12:53:09.251267",
        "air_temp": 28.934743307283014,
        "humidity": 78.30579663977932,
        "rainfall": 0,
        "wind_speed": 8.103942851506593,
        "soil_moisture": 29.230181719621037,
        "ndvi": 0.5705343879257712,
        "soil_temp": 16.16563848131396,
        "soil_humidity": 51.53357961400065
    },
    {
        "date": "2024-08-04 13:53:09.251267",
        "air_temp": 27.578856935597678,
        "humidity": 82.2960719838866,
        "rainfall": 1,
        "wind_speed": 11.920871909552943,
        "soil_moisture": 37.0377762338969,
        "ndvi": 0.7888494331001985,
        "soil_temp": 21.450860176620395,
        "soil_humidity": 56.87824561979648
    },
    {
        "date": "2024-08-04 14:53:09.251267",
        "air_temp": 32.549440270541055,
        "humidity": 70.68020571187293,
        "rainfall": 0,
        "wind_speed": 2.1490110525781625,
        "soil_moisture": 14.917607861408346,
        "ndvi": 0.4026535922793375,
        "soil_temp": 11.496445060572004,
        "soil_humidity": 33.9784638980876
    },
    {
        "date": "2024-08-04 15:53:09.251267",
        "air_temp": 29.701420876077716,
        "humidity": 44.430235698483955,
        "rainfall": 2,
        "wind_speed": 0.3382559984321465,
        "soil_moisture": 21.4422642970937,
        "ndvi": 0.621819019984479,
        "soil_temp": 21.652470541724327,
        "soil_humidity": 45.22303765512428
    },
    {
        "date": "2024-08-04 16:53:09.251267",
        "air_temp": 31.06961860769697,
        "humidity": 64.38139048336055,
        "rainfall": 1,
        "wind_speed": 18.191288547606025,
        "soil_moisture": 14.22605811852584,
        "ndvi": 0.33356218192474263,
        "soil_temp": 15.571253679898483,
        "soil_humidity": 60.775165858526904
    },
    {
        "date": "2024-08-04 17:53:09.251267",
        "air_temp": 20.64069145142613,
        "humidity": 43.88246673358025,
        "rainfall": 0.5,
        "wind_speed": 5.392600175343656,
        "soil_moisture": 28.74831311223496,
        "ndvi": 0.3567201027643478,
        "soil_temp": 10.5908524433453,
        "soil_humidity": 58.276440603959614
    },
    {
        "date": "2024-08-04 18:53:09.251267",
        "air_temp": 18.548790875594456,
        "humidity": 60.37715155162154,
        "rainfall": 2,
        "wind_speed": 16.393540055609183,
        "soil_moisture": 31.866115284657873,
        "ndvi": 0.5071412727617745,
        "soil_temp": 15.448692678432659,
        "soil_humidity": 31.605575526616406
    },
    {
        "date": "2024-08-04 19:53:09.251267",
        "air_temp": 30.012295032817168,
        "humidity": 60.35532445628138,
        "rainfall": 0,
        "wind_speed": 19.987006009444002,
        "soil_moisture": 37.957381977923674,
        "ndvi": 0.3917708753048428,
        "soil_temp": 19.15964126382467,
        "soil_humidity": 51.863770539889785
    },
    {
        "date": "2024-08-04 20:53:09.251267",
        "air_temp": 31.13669478534528,
        "humidity": 43.30049220701208,
        "rainfall": 2,
        "wind_speed": 4.228625159978224,
        "soil_moisture": 18.366157787828946,
        "ndvi": 0.3005729383043507,
        "soil_temp": 11.788752515221999,
        "soil_humidity": 33.53112771873638
    },
    {
        "date": "2024-08-04 21:53:09.251267",
        "air_temp": 34.81010284001347,
        "humidity": 57.44102669847396,
        "rainfall": 0,
        "wind_speed": 5.3034148236671115,
        "soil_moisture": 38.5063361759629,
        "ndvi": 0.3577284211935956,
        "soil_temp": 23.860517386453818,
        "soil_humidity": 69.8342571671265
    },
    {
        "date": "2024-08-04 22:53:09.251267",
        "air_temp": 23.25235353822853,
        "humidity": 45.54990493956574,
        "rainfall": 2,
        "wind_speed": 13.257195752693313,
        "soil_moisture": 11.897880154620244,
        "ndvi": 0.2214490366795188,
        "soil_temp": 11.16508619909258,
        "soil_humidity": 65.89186354133179
    },
    {
        "date": "2024-08-04 23:53:09.251267",
        "air_temp": 22.440361715855666,
        "humidity": 80.4117605004611,
        "rainfall": 0,
        "wind_speed": 19.308972692012425,
        "soil_moisture": 28.090415900937646,
        "ndvi": 0.622654172886492,
        "soil_temp": 23.95010079749749,
        "soil_humidity": 30.84322641714148
    },
    {
        "date": "2024-08-05 00:53:09.251267",
        "air_temp": 30.528259214839935,
        "humidity": 87.38440129822354,
        "rainfall": 1,
        "wind_speed": 18.31628696381488,
        "soil_moisture": 11.7139815802999,
        "ndvi": 0.495269994251517,
        "soil_temp": 20.607062769035295,
        "soil_humidity": 64.34598315610523
    },
    {
        "date": "2024-08-05 01:53:09.251267",
        "air_temp": 21.816070805060356,
        "humidity": 43.61582376289693,
        "rainfall": 0.5,
        "wind_speed": 10.057510401311697,
        "soil_moisture": 36.81322563285835,
        "ndvi": 0.2565458398427154,
        "soil_temp": 14.945682631213053,
        "soil_humidity": 66.77718402233631
    },
    {
        "date": "2024-08-05 02:53:09.251267",
        "air_temp": 33.61514651207129,
        "humidity": 87.75576036458507,
        "rainfall": 0,
        "wind_speed": 15.902205650534494,
        "soil_moisture": 25.894271712101624,
        "ndvi": 0.3030163958770947,
        "soil_temp": 15.861255215636398,
        "soil_humidity": 44.141928902538204
    },
    {
        "date": "2024-08-05 03:53:09.251267",
        "air_temp": 32.16825503686024,
        "humidity": 66.12882996683726,
        "rainfall": 2,
        "wind_speed": 2.714917061998021,
        "soil_moisture": 30.080450522883883,
        "ndvi": 0.26152072286758643,
        "soil_temp": 21.112140470991164,
        "soil_humidity": 58.780066201910635
    },
    {
        "date": "2024-08-05 04:53:09.251267",
        "air_temp": 23.579880547500366,
        "humidity": 54.97828389769369,
        "rainfall": 0,
        "wind_speed": 19.548763684080246,
        "soil_moisture": 34.947217777313575,
        "ndvi": 0.5675556849655259,
        "soil_temp": 19.02206651321811,
        "soil_humidity": 47.68414984831116
    },
    {
        "date": "2024-08-05 05:53:09.251267",
        "air_temp": 30.017421355829946,
        "humidity": 43.84310376380817,
        "rainfall": 0,
        "wind_speed": 19.28706468071973,
        "soil_moisture": 14.509318674519063,
        "ndvi": 0.2565262181674963,
        "soil_temp": 20.07464382182418,
        "soil_humidity": 56.20929938305376
    },
    {
        "date": "2024-08-05 06:53:09.251267",
        "air_temp": 30.090857481693646,
        "humidity": 65.0312134035209,
        "rainfall": 0,
        "wind_speed": 3.2745791575834193,
        "soil_moisture": 11.157746858144016,
        "ndvi": 0.6525693998211297,
        "soil_temp": 15.837469247702199,
        "soil_humidity": 43.61269617192766
    },
    {
        "date": "2024-08-05 07:53:09.251267",
        "air_temp": 17.062477376718654,
        "humidity": 79.72577722453744,
        "rainfall": 2,
        "wind_speed": 5.988462496062132,
        "soil_moisture": 36.366434377385566,
        "ndvi": 0.21230655159223472,
        "soil_temp": 17.317202227113984,
        "soil_humidity": 42.902369465330736
    },
    {
        "date": "2024-08-05 08:53:09.251267",
        "air_temp": 33.05105813359133,
        "humidity": 75.35432386333491,
        "rainfall": 0.5,
        "wind_speed": 2.0030999501353386,
        "soil_moisture": 19.447996823767973,
        "ndvi": 0.3496254727975353,
        "soil_temp": 24.848012260568403,
        "soil_humidity": 46.97735555430316
    },
    {
        "date": "2024-08-05 09:53:09.251267",
        "air_temp": 25.105047448957144,
        "humidity": 42.51130057319869,
        "rainfall": 1,
        "wind_speed": 5.434351977963594,
        "soil_moisture": 13.869367237814958,
        "ndvi": 0.7344000055351445,
        "soil_temp": 13.208843057495766,
        "soil_humidity": 35.822710346100045
    },
    {
        "date": "2024-08-05 10:53:09.251267",
        "air_temp": 31.529149322154833,
        "humidity": 43.645091768395176,
        "rainfall": 0.5,
        "wind_speed": 16.31911578830413,
        "soil_moisture": 24.053837112612598,
        "ndvi": 0.6311187908733478,
        "soil_temp": 16.26784279783197,
        "soil_humidity": 63.2455585774153
    },
    {
        "date": "2024-08-05 11:53:09.251267",
        "air_temp": 21.400992020612236,
        "humidity": 60.14366413163185,
        "rainfall": 0,
        "wind_speed": 7.56631214330519,
        "soil_moisture": 27.949182270880563,
        "ndvi": 0.24378099975541265,
        "soil_temp": 14.37734781360951,
        "soil_humidity": 43.94791581446547
    },
    {
        "date": "2024-08-05 12:53:09.251267",
        "air_temp": 32.91046456992401,
        "humidity": 54.76452470239717,
        "rainfall": 2,
        "wind_speed": 1.7679393429351675,
        "soil_moisture": 10.929118102675304,
        "ndvi": 0.6926756626911079,
        "soil_temp": 21.47667265659775,
        "soil_humidity": 46.005982156233806
    },
    {
        "date": "2024-08-05 13:53:09.251267",
        "air_temp": 22.784033574683264,
        "humidity": 51.619216152802395,
        "rainfall": 0.5,
        "wind_speed": 6.844510495578091,
        "soil_moisture": 18.24686974771263,
        "ndvi": 0.4958210729241229,
        "soil_temp": 21.46018938498581,
        "soil_humidity": 65.76218450808578
    },
    {
        "date": "2024-08-05 14:53:09.251267",
        "air_temp": 15.216753029605968,
        "humidity": 54.05022494543568,
        "rainfall": 0,
        "wind_speed": 19.627666684825602,
        "soil_moisture": 10.98399622830688,
        "ndvi": 0.7080698163012977,
        "soil_temp": 14.386681914960938,
        "soil_humidity": 49.86495256252685
    },
    {
        "date": "2024-08-05 15:53:09.251267",
        "air_temp": 33.10763952838528,
        "humidity": 80.17413716484424,
        "rainfall": 1,
        "wind_speed": 12.593671905184058,
        "soil_moisture": 22.61006167586497,
        "ndvi": 0.5604438646702361,
        "soil_temp": 11.220854749494745,
        "soil_humidity": 51.770156374034514
    },
    {
        "date": "2024-08-05 16:53:09.251267",
        "air_temp": 16.82573353572267,
        "humidity": 86.46140268159269,
        "rainfall": 1,
        "wind_speed": 8.323218928123154,
        "soil_moisture": 36.853000871987334,
        "ndvi": 0.6090998192197152,
        "soil_temp": 10.171490150083061,
        "soil_humidity": 68.25992499108395
    },
    {
        "date": "2024-08-05 17:53:09.251267",
        "air_temp": 21.3862727518083,
        "humidity": 60.25513472637832,
        "rainfall": 1,
        "wind_speed": 3.3307702699454,
        "soil_moisture": 39.479572344807075,
        "ndvi": 0.796100936237425,
        "soil_temp": 16.411757998637285,
        "soil_humidity": 65.46905532579956
    },
    {
        "date": "2024-08-05 18:53:09.251267",
        "air_temp": 34.0012393410161,
        "humidity": 85.30555010662485,
        "rainfall": 0,
        "wind_speed": 19.723535945673895,
        "soil_moisture": 29.111426605108825,
        "ndvi": 0.33940132755441094,
        "soil_temp": 16.155063915036564,
        "soil_humidity": 36.77866835553758
    },
    {
        "date": "2024-08-05 19:53:09.251267",
        "air_temp": 34.012142938751126,
        "humidity": 56.0747850623941,
        "rainfall": 2,
        "wind_speed": 16.416980185262574,
        "soil_moisture": 14.849130813537755,
        "ndvi": 0.574367344856833,
        "soil_temp": 24.825848179597944,
        "soil_humidity": 48.9286011967242
    },
    {
        "date": "2024-08-05 20:53:09.251267",
        "air_temp": 26.46875776246572,
        "humidity": 63.82184931718958,
        "rainfall": 0.5,
        "wind_speed": 5.629546049018388,
        "soil_moisture": 37.17548169997917,
        "ndvi": 0.47619283403443907,
        "soil_temp": 17.047031135465193,
        "soil_humidity": 68.85184909714182
    },
    {
        "date": "2024-08-05 21:53:09.251267",
        "air_temp": 27.636744243395984,
        "humidity": 51.30144989079999,
        "rainfall": 1,
        "wind_speed": 1.4013739144237891,
        "soil_moisture": 25.487434696001813,
        "ndvi": 0.22367117175361487,
        "soil_temp": 13.84627554363131,
        "soil_humidity": 44.8946519291441
    },
    {
        "date": "2024-08-05 22:53:09.251267",
        "air_temp": 23.968910439566393,
        "humidity": 72.0238021087922,
        "rainfall": 0,
        "wind_speed": 1.1325111475511607,
        "soil_moisture": 14.612479363087543,
        "ndvi": 0.20868238490171903,
        "soil_temp": 23.368441615472012,
        "soil_humidity": 44.86292434064801
    },
    {
        "date": "2024-08-05 23:53:09.251267",
        "air_temp": 20.86421543396129,
        "humidity": 88.94905575874044,
        "rainfall": 0,
        "wind_speed": 2.4075629901106765,
        "soil_moisture": 37.651800083549844,
        "ndvi": 0.2715371466907152,
        "soil_temp": 17.366019244803677,
        "soil_humidity": 37.80120927156617
    },
    {
        "date": "2024-08-06 00:53:09.251267",
        "air_temp": 21.57329090739832,
        "humidity": 70.17465480216893,
        "rainfall": 0.5,
        "wind_speed": 11.615371672288433,
        "soil_moisture": 32.72407152169309,
        "ndvi": 0.589455044323129,
        "soil_temp": 18.987634528158665,
        "soil_humidity": 34.57292456476501
    },
    {
        "date": "2024-08-06 01:53:09.251267",
        "air_temp": 28.450369121540767,
        "humidity": 57.890704205677444,
        "rainfall": 1,
        "wind_speed": 18.575268424109847,
        "soil_moisture": 31.951158181694545,
        "ndvi": 0.5402978527480353,
        "soil_temp": 11.43503065642319,
        "soil_humidity": 59.612191630239266
    },
    {
        "date": "2024-08-06 02:53:09.251267",
        "air_temp": 30.0474905887536,
        "humidity": 72.39087229952642,
        "rainfall": 0,
        "wind_speed": 9.934868560119689,
        "soil_moisture": 31.27542111383214,
        "ndvi": 0.5320577366982365,
        "soil_temp": 15.74374610994661,
        "soil_humidity": 60.80121874806066
    },
    {
        "date": "2024-08-06 03:53:09.251267",
        "air_temp": 30.83158087451697,
        "humidity": 46.14603392326938,
        "rainfall": 0.5,
        "wind_speed": 16.858909649123945,
        "soil_moisture": 16.01019463289327,
        "ndvi": 0.21911405751695606,
        "soil_temp": 24.163355117106804,
        "soil_humidity": 68.76051854011999
    },
    {
        "date": "2024-08-06 04:53:09.251267",
        "air_temp": 30.792362855891078,
        "humidity": 84.43295401326993,
        "rainfall": 2,
        "wind_speed": 8.917732397196156,
        "soil_moisture": 16.33020075122578,
        "ndvi": 0.4815817978160043,
        "soil_temp": 18.842580762631382,
        "soil_humidity": 60.93672843621452
    },
    {
        "date": "2024-08-06 05:53:09.251267",
        "air_temp": 16.824122060973806,
        "humidity": 65.15419753899181,
        "rainfall": 2,
        "wind_speed": 14.171670915356504,
        "soil_moisture": 30.64878995281765,
        "ndvi": 0.7547736907055171,
        "soil_temp": 15.167527827136926,
        "soil_humidity": 39.73409976134395
    },
    {
        "date": "2024-08-06 06:53:09.251267",
        "air_temp": 24.88840609405163,
        "humidity": 62.4674871019821,
        "rainfall": 2,
        "wind_speed": 14.637454745730016,
        "soil_moisture": 16.591682503311908,
        "ndvi": 0.438555121586388,
        "soil_temp": 19.385007709473896,
        "soil_humidity": 31.60466313154062
    },
    {
        "date": "2024-08-06 07:53:09.251267",
        "air_temp": 16.151175200332887,
        "humidity": 69.29323942267595,
        "rainfall": 1,
        "wind_speed": 3.479274118066773,
        "soil_moisture": 23.160150904091466,
        "ndvi": 0.5749534818730739,
        "soil_temp": 23.656166343671742,
        "soil_humidity": 69.41749537478641
    },
    {
        "date": "2024-08-06 08:53:09.251267",
        "air_temp": 25.990577646474712,
        "humidity": 71.23919313947013,
        "rainfall": 0.5,
        "wind_speed": 7.224321179516235,
        "soil_moisture": 33.46005476781824,
        "ndvi": 0.6678912053738861,
        "soil_temp": 14.10985900708885,
        "soil_humidity": 43.61802782174423
    },
    {
        "date": "2024-08-06 09:53:09.251267",
        "air_temp": 23.83061002746754,
        "humidity": 43.58879031132565,
        "rainfall": 0.5,
        "wind_speed": 12.746043130760478,
        "soil_moisture": 33.518575542935224,
        "ndvi": 0.6703551674851327,
        "soil_temp": 17.576750777060116,
        "soil_humidity": 42.47886169729683
    },
    {
        "date": "2024-08-06 10:53:09.251267",
        "air_temp": 32.754083655166,
        "humidity": 74.13086106117012,
        "rainfall": 0,
        "wind_speed": 1.4139321636873237,
        "soil_moisture": 23.59785317577579,
        "ndvi": 0.5115680494371628,
        "soil_temp": 24.38035980867633,
        "soil_humidity": 66.2382083899753
    },
    {
        "date": "2024-08-06 11:53:09.251267",
        "air_temp": 22.018300251041573,
        "humidity": 52.09658401577648,
        "rainfall": 0,
        "wind_speed": 7.2308037552591315,
        "soil_moisture": 33.76569814825736,
        "ndvi": 0.7147711672414019,
        "soil_temp": 12.58594214947679,
        "soil_humidity": 53.403995941799444
    },
    {
        "date": "2024-08-06 12:53:09.251267",
        "air_temp": 17.341340328552118,
        "humidity": 75.69763162532949,
        "rainfall": 0,
        "wind_speed": 10.695989325623849,
        "soil_moisture": 35.1586538859417,
        "ndvi": 0.4348268560182482,
        "soil_temp": 20.794958883950674,
        "soil_humidity": 38.59484695396539
    },
    {
        "date": "2024-08-06 13:53:09.251267",
        "air_temp": 17.859833641056717,
        "humidity": 81.12673971920984,
        "rainfall": 0.5,
        "wind_speed": 19.924489860892773,
        "soil_moisture": 29.643272538527807,
        "ndvi": 0.733215928870879,
        "soil_temp": 19.217215123044735,
        "soil_humidity": 65.56384970864958
    },
    {
        "date": "2024-08-06 14:53:09.251267",
        "air_temp": 30.230212634349446,
        "humidity": 80.197925377626,
        "rainfall": 0,
        "wind_speed": 9.490671252448815,
        "soil_moisture": 16.852775569252607,
        "ndvi": 0.3441362998373272,
        "soil_temp": 20.195126967109523,
        "soil_humidity": 69.73443420218973
    },
    {
        "date": "2024-08-06 15:53:09.251267",
        "air_temp": 27.364361266325222,
        "humidity": 67.62504837039445,
        "rainfall": 0.5,
        "wind_speed": 16.321135142286472,
        "soil_moisture": 18.220353411566425,
        "ndvi": 0.3681298441943427,
        "soil_temp": 15.268294460013545,
        "soil_humidity": 33.46714244603323
    },
    {
        "date": "2024-08-06 16:53:09.251267",
        "air_temp": 17.022453522455805,
        "humidity": 66.00849461553756,
        "rainfall": 0.5,
        "wind_speed": 8.641345701332355,
        "soil_moisture": 19.44523466226206,
        "ndvi": 0.23187237451940068,
        "soil_temp": 14.708190602256474,
        "soil_humidity": 64.49006513209949
    },
    {
        "date": "2024-08-06 17:53:09.251267",
        "air_temp": 16.682136122299948,
        "humidity": 47.14379806697059,
        "rainfall": 2,
        "wind_speed": 15.927814452394177,
        "soil_moisture": 13.50412323201856,
        "ndvi": 0.6773491019253698,
        "soil_temp": 20.263145058825557,
        "soil_humidity": 45.62062790737576
    },
    {
        "date": "2024-08-06 18:53:09.251267",
        "air_temp": 29.0193826291824,
        "humidity": 78.76730752015678,
        "rainfall": 0,
        "wind_speed": 11.908296032402168,
        "soil_moisture": 18.194788611098353,
        "ndvi": 0.45023314173962337,
        "soil_temp": 13.23314927656818,
        "soil_humidity": 50.96289786026817
    },
    {
        "date": "2024-08-06 19:53:09.251267",
        "air_temp": 16.45526012728387,
        "humidity": 53.570469022335715,
        "rainfall": 2,
        "wind_speed": 17.731908830047967,
        "soil_moisture": 16.28557672937596,
        "ndvi": 0.33746996834683946,
        "soil_temp": 16.294821172885076,
        "soil_humidity": 66.59263903059923
    },
    {
        "date": "2024-08-06 20:53:09.251267",
        "air_temp": 31.437201185807126,
        "humidity": 64.83477114089733,
        "rainfall": 1,
        "wind_speed": 8.218851047002198,
        "soil_moisture": 23.659862494258064,
        "ndvi": 0.3390741665699478,
        "soil_temp": 12.82261969079377,
        "soil_humidity": 68.93516783100469
    },
    {
        "date": "2024-08-06 21:53:09.251267",
        "air_temp": 29.124844543129925,
        "humidity": 54.21370464637583,
        "rainfall": 1,
        "wind_speed": 12.086088396429577,
        "soil_moisture": 37.253126436167946,
        "ndvi": 0.7408399647375401,
        "soil_temp": 10.124572616849946,
        "soil_humidity": 40.486556416351974
    },
    {
        "date": "2024-08-06 22:53:09.251267",
        "air_temp": 16.626975612837995,
        "humidity": 46.691418149984656,
        "rainfall": 1,
        "wind_speed": 12.600373612376547,
        "soil_moisture": 13.134409502618743,
        "ndvi": 0.7003499602766221,
        "soil_temp": 24.55086040057104,
        "soil_humidity": 38.51403083839655
    },
    {
        "date": "2024-08-06 23:53:09.251267",
        "air_temp": 16.69675428170384,
        "humidity": 71.47788484458682,
        "rainfall": 0.5,
        "wind_speed": 8.347658836009465,
        "soil_moisture": 31.60803771842187,
        "ndvi": 0.53211853121431,
        "soil_temp": 16.475544679846287,
        "soil_humidity": 33.22066234366047
    },
    {
        "date": "2024-08-07 00:53:09.251267",
        "air_temp": 34.73279157002351,
        "humidity": 42.71660174114519,
        "rainfall": 0.5,
        "wind_speed": 2.8862408737496303,
        "soil_moisture": 30.61849013239383,
        "ndvi": 0.5696664802494327,
        "soil_temp": 24.54024421629738,
        "soil_humidity": 52.47191410648552
    },
    {
        "date": "2024-08-07 01:53:09.251267",
        "air_temp": 22.485415915122406,
        "humidity": 77.43226170631,
        "rainfall": 0.5,
        "wind_speed": 1.871188138246711,
        "soil_moisture": 12.87262595990073,
        "ndvi": 0.48459534233017465,
        "soil_temp": 12.104852796517392,
        "soil_humidity": 30.54417581802673
    },
    {
        "date": "2024-08-07 02:53:09.251267",
        "air_temp": 22.412842941337818,
        "humidity": 55.87933976305583,
        "rainfall": 0,
        "wind_speed": 0.333431011992662,
        "soil_moisture": 37.677172147174005,
        "ndvi": 0.43499992572728535,
        "soil_temp": 18.810501691862292,
        "soil_humidity": 46.47593190439746
    },
    {
        "date": "2024-08-07 03:53:09.251267",
        "air_temp": 31.25599134515005,
        "humidity": 40.00673465022427,
        "rainfall": 2,
        "wind_speed": 17.47068528490429,
        "soil_moisture": 27.05416606470459,
        "ndvi": 0.7492861287309991,
        "soil_temp": 13.014610491868595,
        "soil_humidity": 32.93536066608034
    },
    {
        "date": "2024-08-07 04:53:09.251267",
        "air_temp": 33.94497154767717,
        "humidity": 65.55645696186149,
        "rainfall": 0.5,
        "wind_speed": 4.729498244773891,
        "soil_moisture": 20.911765634070527,
        "ndvi": 0.27536762211218124,
        "soil_temp": 21.85111190444912,
        "soil_humidity": 46.209031317173356
    },
    {
        "date": "2024-08-07 05:53:09.251267",
        "air_temp": 34.720021276457416,
        "humidity": 42.34259542638016,
        "rainfall": 2,
        "wind_speed": 4.230134985635361,
        "soil_moisture": 32.6961575048592,
        "ndvi": 0.4510680738297368,
        "soil_temp": 21.75554483141463,
        "soil_humidity": 31.59728757062437
    },
    {
        "date": "2024-08-07 06:53:09.251267",
        "air_temp": 30.067563705178834,
        "humidity": 53.80847789104497,
        "rainfall": 2,
        "wind_speed": 14.72070800852131,
        "soil_moisture": 17.720963752049023,
        "ndvi": 0.40673714479272527,
        "soil_temp": 11.689107235589677,
        "soil_humidity": 63.40869042435075
    },
    {
        "date": "2024-08-07 07:53:09.251267",
        "air_temp": 22.525191710618316,
        "humidity": 75.3488243727858,
        "rainfall": 2,
        "wind_speed": 6.400322261747835,
        "soil_moisture": 30.80529511138961,
        "ndvi": 0.49889043215068907,
        "soil_temp": 23.680966793145835,
        "soil_humidity": 38.389559757542635
    },
    {
        "date": "2024-08-07 08:53:09.251267",
        "air_temp": 16.670014333973377,
        "humidity": 43.13448108109329,
        "rainfall": 0,
        "wind_speed": 10.120893848236731,
        "soil_moisture": 11.191336227167417,
        "ndvi": 0.4745791247271051,
        "soil_temp": 12.919444708048369,
        "soil_humidity": 43.13234841431648
    },
    {
        "date": "2024-08-07 09:53:09.251267",
        "air_temp": 30.542938318548735,
        "humidity": 81.96692461780012,
        "rainfall": 0,
        "wind_speed": 3.250221305949126,
        "soil_moisture": 35.18224112827816,
        "ndvi": 0.5113337189906488,
        "soil_temp": 19.194660123840407,
        "soil_humidity": 64.80191986425567
    },
    {
        "date": "2024-08-07 10:53:09.251267",
        "air_temp": 26.1680849947161,
        "humidity": 40.19099613292916,
        "rainfall": 1,
        "wind_speed": 19.194914429239386,
        "soil_moisture": 23.445125410639555,
        "ndvi": 0.2839513863361965,
        "soil_temp": 24.78450430131556,
        "soil_humidity": 59.8506424532882
    },
    {
        "date": "2024-08-07 11:53:09.251267",
        "air_temp": 23.484440184939526,
        "humidity": 52.34119346009511,
        "rainfall": 1,
        "wind_speed": 17.031628000344746,
        "soil_moisture": 29.10078743073825,
        "ndvi": 0.5078849009079662,
        "soil_temp": 23.235948021495457,
        "soil_humidity": 38.76153446568234
    },
    {
        "date": "2024-08-07 12:53:09.251267",
        "air_temp": 33.12708770189472,
        "humidity": 77.04520280760357,
        "rainfall": 2,
        "wind_speed": 17.275792459300064,
        "soil_moisture": 16.63713459420051,
        "ndvi": 0.3454512883323265,
        "soil_temp": 24.348458928417536,
        "soil_humidity": 32.87597674629984
    },
    {
        "date": "2024-08-07 13:53:09.251267",
        "air_temp": 17.223949646123028,
        "humidity": 55.81350748646534,
        "rainfall": 1,
        "wind_speed": 8.135474971440104,
        "soil_moisture": 30.31221137975344,
        "ndvi": 0.3105988093929148,
        "soil_temp": 15.138981568271792,
        "soil_humidity": 58.62695107421358
    },
    {
        "date": "2024-08-07 14:53:09.251267",
        "air_temp": 24.852502085817182,
        "humidity": 45.094619403300676,
        "rainfall": 2,
        "wind_speed": 15.247224274143456,
        "soil_moisture": 33.1672082767983,
        "ndvi": 0.23890053127171473,
        "soil_temp": 11.58363463329195,
        "soil_humidity": 45.62810202752438
    },
    {
        "date": "2024-08-07 15:53:09.251267",
        "air_temp": 15.227072895348382,
        "humidity": 58.0116959400214,
        "rainfall": 0,
        "wind_speed": 2.877523107795603,
        "soil_moisture": 24.19639560653196,
        "ndvi": 0.6575915352536846,
        "soil_temp": 12.825203099341751,
        "soil_humidity": 60.64319996472885
    },
    {
        "date": "2024-08-07 16:53:09.251267",
        "air_temp": 24.373212839882527,
        "humidity": 53.51966431922628,
        "rainfall": 0,
        "wind_speed": 0.17238005183560556,
        "soil_moisture": 28.53180772185383,
        "ndvi": 0.45646515251393605,
        "soil_temp": 17.50742342614921,
        "soil_humidity": 47.811790895008414
    },
    {
        "date": "2024-08-07 17:53:09.251267",
        "air_temp": 16.126065513636746,
        "humidity": 82.13559510802774,
        "rainfall": 0,
        "wind_speed": 0.3994586515605625,
        "soil_moisture": 23.689777714671955,
        "ndvi": 0.5759862099870551,
        "soil_temp": 21.898445174099834,
        "soil_humidity": 43.84009651889106
    },
    {
        "date": "2024-08-07 18:53:09.251267",
        "air_temp": 17.376358325361437,
        "humidity": 55.66739587925716,
        "rainfall": 0.5,
        "wind_speed": 11.732034748091209,
        "soil_moisture": 36.822741077735365,
        "ndvi": 0.6365560059828461,
        "soil_temp": 12.252304662758128,
        "soil_humidity": 48.04821632849586
    },
    {
        "date": "2024-08-07 19:53:09.251267",
        "air_temp": 17.350524935542097,
        "humidity": 79.44662358880036,
        "rainfall": 2,
        "wind_speed": 9.107898104958815,
        "soil_moisture": 24.936075415429574,
        "ndvi": 0.6075899991881322,
        "soil_temp": 19.895972983911356,
        "soil_humidity": 59.94235942682707
    },
    {
        "date": "2024-08-07 20:53:09.251267",
        "air_temp": 27.984206042321272,
        "humidity": 84.59360724053673,
        "rainfall": 0,
        "wind_speed": 10.360306978170396,
        "soil_moisture": 35.25426127086457,
        "ndvi": 0.4823004314538094,
        "soil_temp": 14.853664060592678,
        "soil_humidity": 66.08881146161497
    },
    {
        "date": "2024-08-07 21:53:09.251267",
        "air_temp": 29.920897585308467,
        "humidity": 61.69087097882648,
        "rainfall": 2,
        "wind_speed": 0.1492628083443881,
        "soil_moisture": 34.04056174810746,
        "ndvi": 0.43273911737103793,
        "soil_temp": 15.118031085882302,
        "soil_humidity": 31.990687863543023
    },
    {
        "date": "2024-08-07 22:53:09.251267",
        "air_temp": 26.667375301943192,
        "humidity": 85.4971571927196,
        "rainfall": 1,
        "wind_speed": 8.453732393148513,
        "soil_moisture": 22.315144640450697,
        "ndvi": 0.4255759457843983,
        "soil_temp": 19.928765952368895,
        "soil_humidity": 50.9644616490782
    },
    {
        "date": "2024-08-07 23:53:09.251267",
        "air_temp": 34.24345096949084,
        "humidity": 58.865902698159644,
        "rainfall": 1,
        "wind_speed": 14.361363224017216,
        "soil_moisture": 16.90253475572425,
        "ndvi": 0.5840292781580338,
        "soil_temp": 17.004991292998305,
        "soil_humidity": 31.313417163355105
    },
    {
        "date": "2024-08-08 00:53:09.251267",
        "air_temp": 22.49741159047408,
        "humidity": 88.20392082450542,
        "rainfall": 0,
        "wind_speed": 8.853874039348717,
        "soil_moisture": 22.18246397679313,
        "ndvi": 0.4968766129651241,
        "soil_temp": 22.18049939538991,
        "soil_humidity": 48.73638180097435
    },
    {
        "date": "2024-08-08 01:53:09.251267",
        "air_temp": 20.714241725637216,
        "humidity": 44.46447622627865,
        "rainfall": 2,
        "wind_speed": 14.644384126244445,
        "soil_moisture": 34.786521273256604,
        "ndvi": 0.7094793487931064,
        "soil_temp": 12.338777644942406,
        "soil_humidity": 65.41131316387602
    },
    {
        "date": "2024-08-08 02:53:09.251267",
        "air_temp": 32.3719825637892,
        "humidity": 74.35096332516213,
        "rainfall": 0,
        "wind_speed": 4.660379287936696,
        "soil_moisture": 16.82897607458177,
        "ndvi": 0.7248211905960273,
        "soil_temp": 21.849224046505576,
        "soil_humidity": 44.463610656812385
    },
    {
        "date": "2024-08-08 03:53:09.251267",
        "air_temp": 19.47191677038905,
        "humidity": 64.69070202284644,
        "rainfall": 1,
        "wind_speed": 16.26284008963451,
        "soil_moisture": 25.73309037573248,
        "ndvi": 0.3023362268039111,
        "soil_temp": 10.963528720861312,
        "soil_humidity": 67.29801684322572
    },
    {
        "date": "2024-08-08 04:53:09.251267",
        "air_temp": 34.264450788812226,
        "humidity": 59.382440272782354,
        "rainfall": 0,
        "wind_speed": 11.768624809069046,
        "soil_moisture": 34.182902438822424,
        "ndvi": 0.2817401909274354,
        "soil_temp": 12.910808203684075,
        "soil_humidity": 55.224338866024375
    },
    {
        "date": "2024-08-08 05:53:09.251267",
        "air_temp": 15.243089493796326,
        "humidity": 71.63559393743004,
        "rainfall": 2,
        "wind_speed": 4.215261988598124,
        "soil_moisture": 20.604324253851363,
        "ndvi": 0.5782405556215997,
        "soil_temp": 19.84224571754748,
        "soil_humidity": 67.36621375172025
    },
    {
        "date": "2024-08-08 06:53:09.251267",
        "air_temp": 34.397576534152776,
        "humidity": 75.19285458171656,
        "rainfall": 1,
        "wind_speed": 4.734409517076658,
        "soil_moisture": 29.266289110080923,
        "ndvi": 0.38073544883736765,
        "soil_temp": 22.82388484766607,
        "soil_humidity": 60.28666501650079
    },
    {
        "date": "2024-08-08 07:53:09.251267",
        "air_temp": 15.863198239011522,
        "humidity": 40.21816348251546,
        "rainfall": 0.5,
        "wind_speed": 12.56060929139074,
        "soil_moisture": 17.368947221464435,
        "ndvi": 0.21114243125905932,
        "soil_temp": 13.790194416090236,
        "soil_humidity": 34.13740666595049
    },
    {
        "date": "2024-08-08 08:53:09.251267",
        "air_temp": 32.82286227396142,
        "humidity": 48.347654437359935,
        "rainfall": 1,
        "wind_speed": 16.598791864248867,
        "soil_moisture": 13.278383361044797,
        "ndvi": 0.7331856016177731,
        "soil_temp": 14.907874087433694,
        "soil_humidity": 39.24605470250708
    },
    {
        "date": "2024-08-08 09:53:09.251267",
        "air_temp": 25.554022181725998,
        "humidity": 75.65230253171593,
        "rainfall": 0.5,
        "wind_speed": 8.312555640583103,
        "soil_moisture": 33.83152675278569,
        "ndvi": 0.336140460346285,
        "soil_temp": 18.0568112243011,
        "soil_humidity": 45.76681818334025
    },
    {
        "date": "2024-08-08 10:53:09.251267",
        "air_temp": 34.85929592238601,
        "humidity": 73.31927067891033,
        "rainfall": 0,
        "wind_speed": 5.166008582551845,
        "soil_moisture": 12.969058702261204,
        "ndvi": 0.7575683910114857,
        "soil_temp": 13.173592097339535,
        "soil_humidity": 36.80427528048304
    },
    {
        "date": "2024-08-08 11:53:09.251267",
        "air_temp": 16.475931294707976,
        "humidity": 88.30237525643386,
        "rainfall": 0,
        "wind_speed": 17.34023213821201,
        "soil_moisture": 18.141441852387537,
        "ndvi": 0.6304606705269522,
        "soil_temp": 22.167343923812584,
        "soil_humidity": 49.79646419106642
    },
    {
        "date": "2024-08-08 12:53:09.251267",
        "air_temp": 26.077085688026415,
        "humidity": 78.05188951253915,
        "rainfall": 2,
        "wind_speed": 13.385365835016698,
        "soil_moisture": 35.65328265992321,
        "ndvi": 0.6575920939468907,
        "soil_temp": 21.60454967508641,
        "soil_humidity": 48.854726228059626
    },
    {
        "date": "2024-08-08 13:53:09.251267",
        "air_temp": 34.38605071238198,
        "humidity": 87.53863538656691,
        "rainfall": 0.5,
        "wind_speed": 8.96170974566153,
        "soil_moisture": 20.394078612360016,
        "ndvi": 0.32087689747589626,
        "soil_temp": 12.179375679486725,
        "soil_humidity": 68.25036792109525
    },
    {
        "date": "2024-08-08 14:53:09.251267",
        "air_temp": 25.461956883402976,
        "humidity": 75.12702507674035,
        "rainfall": 0,
        "wind_speed": 9.758003905586037,
        "soil_moisture": 39.19136048542484,
        "ndvi": 0.3405166553700878,
        "soil_temp": 16.95744597724948,
        "soil_humidity": 47.349115315839114
    },
    {
        "date": "2024-08-08 15:53:09.251267",
        "air_temp": 27.58797276270525,
        "humidity": 54.90261043197216,
        "rainfall": 0.5,
        "wind_speed": 4.085129358727928,
        "soil_moisture": 39.3920300432158,
        "ndvi": 0.5127606522944395,
        "soil_temp": 21.622416499819323,
        "soil_humidity": 61.425072171519915
    },
    {
        "date": "2024-08-08 16:53:09.251267",
        "air_temp": 28.914973779692343,
        "humidity": 45.268246112542826,
        "rainfall": 0,
        "wind_speed": 12.536052601296879,
        "soil_moisture": 18.0482806136067,
        "ndvi": 0.30325991158991733,
        "soil_temp": 15.059942966551223,
        "soil_humidity": 67.6764217940592
    },
    {
        "date": "2024-08-08 17:53:09.251267",
        "air_temp": 24.090821295355465,
        "humidity": 79.09119316724679,
        "rainfall": 1,
        "wind_speed": 7.174850210857773,
        "soil_moisture": 11.229346667496714,
        "ndvi": 0.3832590156098194,
        "soil_temp": 19.496602340765975,
        "soil_humidity": 65.07955323895942
    },
    {
        "date": "2024-08-08 18:53:09.251267",
        "air_temp": 27.55116160168127,
        "humidity": 72.2074610737958,
        "rainfall": 0,
        "wind_speed": 19.912903533486986,
        "soil_moisture": 31.151898526161375,
        "ndvi": 0.3928774867686827,
        "soil_temp": 10.753196821863591,
        "soil_humidity": 38.34670498046367
    },
    {
        "date": "2024-08-08 19:53:09.251267",
        "air_temp": 26.686286238462003,
        "humidity": 42.40930061966481,
        "rainfall": 2,
        "wind_speed": 9.603670305237756,
        "soil_moisture": 23.52959507945031,
        "ndvi": 0.29577795995165845,
        "soil_temp": 19.13033140793977,
        "soil_humidity": 49.841195770141766
    },
    {
        "date": "2024-08-08 20:53:09.251267",
        "air_temp": 33.02316020981978,
        "humidity": 58.00252329504892,
        "rainfall": 1,
        "wind_speed": 9.717712123792264,
        "soil_moisture": 13.02258531878729,
        "ndvi": 0.3231097000370965,
        "soil_temp": 12.289794700009105,
        "soil_humidity": 43.90959129183703
    },
    {
        "date": "2024-08-08 21:53:09.251267",
        "air_temp": 15.908927606829158,
        "humidity": 87.8399907742143,
        "rainfall": 2,
        "wind_speed": 11.317035748213438,
        "soil_moisture": 31.225684569341283,
        "ndvi": 0.673131105204954,
        "soil_temp": 22.45874987671677,
        "soil_humidity": 45.994347805488616
    },
    {
        "date": "2024-08-08 22:53:09.251267",
        "air_temp": 20.619263791844606,
        "humidity": 65.02005728551006,
        "rainfall": 1,
        "wind_speed": 18.335120062374934,
        "soil_moisture": 10.935247273357637,
        "ndvi": 0.30031751272836105,
        "soil_temp": 15.331741953707176,
        "soil_humidity": 44.432273049830044
    },
    {
        "date": "2024-08-08 23:53:09.251267",
        "air_temp": 34.008229681531176,
        "humidity": 61.63645680098523,
        "rainfall": 0.5,
        "wind_speed": 16.016361997195006,
        "soil_moisture": 36.10590071476726,
        "ndvi": 0.2170744351063535,
        "soil_temp": 14.223307940922936,
        "soil_humidity": 63.05436840700017
    },
    {
        "date": "2024-08-09 00:53:09.251267",
        "air_temp": 32.80527567781833,
        "humidity": 62.8849802579647,
        "rainfall": 2,
        "wind_speed": 9.022601395956828,
        "soil_moisture": 14.159575873399112,
        "ndvi": 0.5776129655773632,
        "soil_temp": 22.613334650672275,
        "soil_humidity": 48.0855622048979
    },
    {
        "date": "2024-08-09 01:53:09.251267",
        "air_temp": 24.11313505571426,
        "humidity": 50.444136200258185,
        "rainfall": 0,
        "wind_speed": 14.373985319455919,
        "soil_moisture": 13.81935636240175,
        "ndvi": 0.7855898716354701,
        "soil_temp": 10.752160346077153,
        "soil_humidity": 50.94777328978753
    },
    {
        "date": "2024-08-09 02:53:09.251267",
        "air_temp": 27.402651956030734,
        "humidity": 58.43535854323976,
        "rainfall": 0.5,
        "wind_speed": 11.724709975615234,
        "soil_moisture": 15.270168939057536,
        "ndvi": 0.3987282887738729,
        "soil_temp": 15.949131990015172,
        "soil_humidity": 69.29789428188299
    },
    {
        "date": "2024-08-09 03:53:09.251267",
        "air_temp": 20.54762365962265,
        "humidity": 58.49066279935883,
        "rainfall": 0,
        "wind_speed": 9.847340974024979,
        "soil_moisture": 38.807990754761995,
        "ndvi": 0.2711934219177514,
        "soil_temp": 15.55005310862547,
        "soil_humidity": 32.39917088766066
    },
    {
        "date": "2024-08-09 04:53:09.251267",
        "air_temp": 18.762423194475225,
        "humidity": 42.61775100937362,
        "rainfall": 1,
        "wind_speed": 3.8463154556870105,
        "soil_moisture": 30.79508080761538,
        "ndvi": 0.5422323952205186,
        "soil_temp": 14.272005613743262,
        "soil_humidity": 49.518305591629954
    },
    {
        "date": "2024-08-09 05:53:09.251267",
        "air_temp": 24.273968098799642,
        "humidity": 78.37838142589591,
        "rainfall": 1,
        "wind_speed": 9.27872526909782,
        "soil_moisture": 34.041146063909096,
        "ndvi": 0.7438125266573878,
        "soil_temp": 23.676314980022156,
        "soil_humidity": 60.96246827784464
    },
    {
        "date": "2024-08-09 06:53:09.251267",
        "air_temp": 22.067044560521055,
        "humidity": 60.82522159519395,
        "rainfall": 0,
        "wind_speed": 4.916371740451493,
        "soil_moisture": 21.92904957198259,
        "ndvi": 0.6704370678970873,
        "soil_temp": 21.263925902750834,
        "soil_humidity": 36.04647832752906
    },
    {
        "date": "2024-08-09 07:53:09.251267",
        "air_temp": 26.67312223701744,
        "humidity": 81.10899434082117,
        "rainfall": 0,
        "wind_speed": 18.296842997033092,
        "soil_moisture": 20.089381825148667,
        "ndvi": 0.5735095210476296,
        "soil_temp": 10.958847462879081,
        "soil_humidity": 54.461263784590955
    },
    {
        "date": "2024-08-09 08:53:09.251267",
        "air_temp": 16.554692739299696,
        "humidity": 82.51740005599477,
        "rainfall": 0.5,
        "wind_speed": 6.708136269926939,
        "soil_moisture": 17.0345202034951,
        "ndvi": 0.38903389796455967,
        "soil_temp": 20.720311741524313,
        "soil_humidity": 39.57859118137867
    },
    {
        "date": "2024-08-09 09:53:09.251267",
        "air_temp": 34.487896153323334,
        "humidity": 50.599702734932904,
        "rainfall": 0,
        "wind_speed": 19.045280290667446,
        "soil_moisture": 24.41944084937227,
        "ndvi": 0.5251281039657321,
        "soil_temp": 17.90948699693191,
        "soil_humidity": 64.6961645289827
    },
    {
        "date": "2024-08-09 10:53:09.251267",
        "air_temp": 34.72421488959206,
        "humidity": 72.86767410850644,
        "rainfall": 2,
        "wind_speed": 10.661105801894223,
        "soil_moisture": 26.554862006025054,
        "ndvi": 0.25474690478255324,
        "soil_temp": 23.51301841602369,
        "soil_humidity": 43.08398661600317
    },
    {
        "date": "2024-08-09 11:53:09.251267",
        "air_temp": 28.963234280394904,
        "humidity": 63.614090208149086,
        "rainfall": 2,
        "wind_speed": 12.630887573523207,
        "soil_moisture": 28.953245309582663,
        "ndvi": 0.607367254568402,
        "soil_temp": 14.694184260225377,
        "soil_humidity": 43.162512676036386
    },
    {
        "date": "2024-08-09 12:53:09.251267",
        "air_temp": 25.72192732688241,
        "humidity": 84.00783689607425,
        "rainfall": 0,
        "wind_speed": 10.858808889786555,
        "soil_moisture": 17.713555119960837,
        "ndvi": 0.6350576113385906,
        "soil_temp": 18.612429994143746,
        "soil_humidity": 53.92775514071574
    },
    {
        "date": "2024-08-09 13:53:09.251267",
        "air_temp": 21.190552325726557,
        "humidity": 50.787033958822704,
        "rainfall": 2,
        "wind_speed": 2.587612339334018,
        "soil_moisture": 38.45962242708972,
        "ndvi": 0.7790189737459514,
        "soil_temp": 17.51132912111479,
        "soil_humidity": 37.34251632479635
    },
    {
        "date": "2024-08-09 14:53:09.251267",
        "air_temp": 31.275900394138972,
        "humidity": 73.89066001550222,
        "rainfall": 0,
        "wind_speed": 7.516424380158819,
        "soil_moisture": 15.092754946214075,
        "ndvi": 0.356238415247043,
        "soil_temp": 17.343413593414084,
        "soil_humidity": 41.39554555612244
    },
    {
        "date": "2024-08-09 15:53:09.251267",
        "air_temp": 28.694623451077586,
        "humidity": 70.38760611263612,
        "rainfall": 0.5,
        "wind_speed": 1.9829222455524786,
        "soil_moisture": 38.96959524299237,
        "ndvi": 0.21107385142581359,
        "soil_temp": 16.84158099275819,
        "soil_humidity": 46.665274004776464
    },
    {
        "date": "2024-08-09 16:53:09.251267",
        "air_temp": 18.252338786897827,
        "humidity": 54.765073878591636,
        "rainfall": 2,
        "wind_speed": 12.459080883667635,
        "soil_moisture": 10.445683675225114,
        "ndvi": 0.5273650786562905,
        "soil_temp": 13.084442132984218,
        "soil_humidity": 44.14551452560441
    },
    {
        "date": "2024-08-09 17:53:09.251267",
        "air_temp": 33.21854368987685,
        "humidity": 46.83004518255863,
        "rainfall": 2,
        "wind_speed": 8.93748390400878,
        "soil_moisture": 24.433770754505368,
        "ndvi": 0.6240743026380888,
        "soil_temp": 21.33564883738589,
        "soil_humidity": 50.99508872022329
    },
    {
        "date": "2024-08-09 18:53:09.251267",
        "air_temp": 31.45074485846338,
        "humidity": 72.58198802386383,
        "rainfall": 0,
        "wind_speed": 15.559699431418736,
        "soil_moisture": 20.001035143496885,
        "ndvi": 0.6742444855297929,
        "soil_temp": 16.77004049108933,
        "soil_humidity": 53.98560576275865
    },
    {
        "date": "2024-08-09 19:53:09.251267",
        "air_temp": 33.99599826583848,
        "humidity": 76.92987290661654,
        "rainfall": 2,
        "wind_speed": 4.409409619987397,
        "soil_moisture": 23.473702417907525,
        "ndvi": 0.3162433598148864,
        "soil_temp": 24.377744125039086,
        "soil_humidity": 52.66669815000658
    },
    {
        "date": "2024-08-09 20:53:09.251267",
        "air_temp": 29.5143901677672,
        "humidity": 55.7813671881675,
        "rainfall": 1,
        "wind_speed": 3.2913211685866983,
        "soil_moisture": 27.11993736942367,
        "ndvi": 0.3570517214222142,
        "soil_temp": 13.912126407891645,
        "soil_humidity": 56.16673247967729
    },
    {
        "date": "2024-08-09 21:53:09.251267",
        "air_temp": 27.268303918715798,
        "humidity": 72.2416302011157,
        "rainfall": 0.5,
        "wind_speed": 8.126644619275357,
        "soil_moisture": 23.06767474038127,
        "ndvi": 0.5075545551603778,
        "soil_temp": 20.241157594405617,
        "soil_humidity": 42.30998066431281
    },
    {
        "date": "2024-08-09 22:53:09.251267",
        "air_temp": 23.364860725812377,
        "humidity": 59.75654538057516,
        "rainfall": 0.5,
        "wind_speed": 18.531707664367623,
        "soil_moisture": 22.44540550904785,
        "ndvi": 0.39836048598200113,
        "soil_temp": 24.110756194586628,
        "soil_humidity": 35.05704908620112
    },
    {
        "date": "2024-08-09 23:53:09.251267",
        "air_temp": 33.65456966708027,
        "humidity": 75.66034416413089,
        "rainfall": 2,
        "wind_speed": 14.208436300248234,
        "soil_moisture": 31.981702462399195,
        "ndvi": 0.7163068919524671,
        "soil_temp": 19.951874845241388,
        "soil_humidity": 60.43230165724309
    },
    {
        "date": "2024-08-10 00:53:09.251267",
        "air_temp": 32.32127779000817,
        "humidity": 49.96076374470265,
        "rainfall": 2,
        "wind_speed": 8.378472495858373,
        "soil_moisture": 30.57628825259394,
        "ndvi": 0.48425609224323224,
        "soil_temp": 23.06909361426092,
        "soil_humidity": 64.03121530522728
    },
    {
        "date": "2024-08-10 01:53:09.251267",
        "air_temp": 15.904373402123788,
        "humidity": 84.51075013180872,
        "rainfall": 1,
        "wind_speed": 10.34544339508621,
        "soil_moisture": 34.998138461075435,
        "ndvi": 0.6797471864612019,
        "soil_temp": 22.41221439635509,
        "soil_humidity": 40.28024928889729
    },
    {
        "date": "2024-08-10 02:53:09.251267",
        "air_temp": 15.52733948994504,
        "humidity": 54.370479823278984,
        "rainfall": 1,
        "wind_speed": 1.6842602235419668,
        "soil_moisture": 17.49876943718487,
        "ndvi": 0.5483718562894123,
        "soil_temp": 15.401765732335058,
        "soil_humidity": 46.28367824632079
    },
    {
        "date": "2024-08-10 03:53:09.251267",
        "air_temp": 22.52926733756099,
        "humidity": 58.38929938780765,
        "rainfall": 2,
        "wind_speed": 19.691517927522263,
        "soil_moisture": 28.285855292621907,
        "ndvi": 0.6638540849702672,
        "soil_temp": 19.00185909909245,
        "soil_humidity": 49.94276867854221
    },
    {
        "date": "2024-08-10 04:53:09.251267",
        "air_temp": 31.211066615636657,
        "humidity": 42.90459760401749,
        "rainfall": 2,
        "wind_speed": 13.32814609389838,
        "soil_moisture": 27.555843561785615,
        "ndvi": 0.39960379191819434,
        "soil_temp": 12.343920097170333,
        "soil_humidity": 51.33307371011746
    },
    {
        "date": "2024-08-10 05:53:09.251267",
        "air_temp": 34.74552258629889,
        "humidity": 45.57561061606382,
        "rainfall": 1,
        "wind_speed": 5.70808136926845,
        "soil_moisture": 21.092134326180663,
        "ndvi": 0.6988499731581868,
        "soil_temp": 18.03288824043399,
        "soil_humidity": 56.379606843506075
    },
    {
        "date": "2024-08-10 06:53:09.251267",
        "air_temp": 18.008337822070565,
        "humidity": 65.7930951356164,
        "rainfall": 0,
        "wind_speed": 11.716167884304888,
        "soil_moisture": 24.581726257080426,
        "ndvi": 0.364743389330613,
        "soil_temp": 13.456329577215058,
        "soil_humidity": 31.664129334212852
    },
    {
        "date": "2024-08-10 07:53:09.251267",
        "air_temp": 26.8826143070427,
        "humidity": 53.379633836398575,
        "rainfall": 1,
        "wind_speed": 2.1896473213798795,
        "soil_moisture": 19.413464299171864,
        "ndvi": 0.5276592848019745,
        "soil_temp": 21.156052224286682,
        "soil_humidity": 58.859083309360685
    },
    {
        "date": "2024-08-10 08:53:09.251267",
        "air_temp": 22.61781713262043,
        "humidity": 81.77401128710972,
        "rainfall": 0,
        "wind_speed": 19.31673337615167,
        "soil_moisture": 14.255331928509555,
        "ndvi": 0.32840426790432453,
        "soil_temp": 17.92087632838187,
        "soil_humidity": 32.47884027917641
    },
    {
        "date": "2024-08-10 09:53:09.251267",
        "air_temp": 34.398287956292066,
        "humidity": 40.734669432747694,
        "rainfall": 0.5,
        "wind_speed": 3.4956997576673188,
        "soil_moisture": 16.414036319881987,
        "ndvi": 0.4853159129757161,
        "soil_temp": 16.168410439474908,
        "soil_humidity": 48.731542706914716
    },
    {
        "date": "2024-08-10 10:53:09.251267",
        "air_temp": 31.842378462714173,
        "humidity": 58.95470243359681,
        "rainfall": 0.5,
        "wind_speed": 12.960606361104283,
        "soil_moisture": 13.165409392036032,
        "ndvi": 0.39090512570522196,
        "soil_temp": 14.031956442504663,
        "soil_humidity": 63.10684542276776
    },
    {
        "date": "2024-08-10 11:53:09.251267",
        "air_temp": 31.766574094222758,
        "humidity": 56.867277338454635,
        "rainfall": 1,
        "wind_speed": 4.909089250778608,
        "soil_moisture": 30.492087255703723,
        "ndvi": 0.3600220583823286,
        "soil_temp": 24.980477227619303,
        "soil_humidity": 48.04861253113585
    },
    {
        "date": "2024-08-10 12:53:09.251267",
        "air_temp": 24.373863195899403,
        "humidity": 40.966381545880054,
        "rainfall": 0,
        "wind_speed": 14.351227652970715,
        "soil_moisture": 29.821821793274545,
        "ndvi": 0.7801910108168226,
        "soil_temp": 11.07565781327399,
        "soil_humidity": 35.96358995072991
    },
    {
        "date": "2024-08-10 13:53:09.251267",
        "air_temp": 23.296390046753302,
        "humidity": 46.21841995002551,
        "rainfall": 1,
        "wind_speed": 15.53642734480562,
        "soil_moisture": 38.51819114739618,
        "ndvi": 0.2960444632920384,
        "soil_temp": 10.653410208586301,
        "soil_humidity": 69.40135380023838
    },
    {
        "date": "2024-08-10 14:53:09.251267",
        "air_temp": 20.468141438614126,
        "humidity": 60.682133745931765,
        "rainfall": 1,
        "wind_speed": 13.468394578228652,
        "soil_moisture": 31.89035240941724,
        "ndvi": 0.6723955024744372,
        "soil_temp": 13.258861007407969,
        "soil_humidity": 65.57141848297053
    },
    {
        "date": "2024-08-10 15:53:09.251267",
        "air_temp": 16.127509933018544,
        "humidity": 64.64322922237233,
        "rainfall": 0,
        "wind_speed": 12.28998920510385,
        "soil_moisture": 11.561375162683943,
        "ndvi": 0.790498975689929,
        "soil_temp": 24.566086879417295,
        "soil_humidity": 60.85254532900056
    },
    {
        "date": "2024-08-10 16:53:09.251267",
        "air_temp": 32.29444752510106,
        "humidity": 60.214487362743455,
        "rainfall": 1,
        "wind_speed": 17.849381879040234,
        "soil_moisture": 36.527355217868624,
        "ndvi": 0.21194089024404672,
        "soil_temp": 24.688572524799767,
        "soil_humidity": 46.46046411596842
    },
    {
        "date": "2024-08-10 17:53:09.251267",
        "air_temp": 31.258020182601552,
        "humidity": 66.5468839206047,
        "rainfall": 0.5,
        "wind_speed": 1.5725342197951608,
        "soil_moisture": 35.88025488837177,
        "ndvi": 0.6194164484252571,
        "soil_temp": 19.82391602158204,
        "soil_humidity": 37.35382907557191
    },
    {
        "date": "2024-08-10 18:53:09.251267",
        "air_temp": 34.99435346572261,
        "humidity": 69.75658374528408,
        "rainfall": 2,
        "wind_speed": 6.618295315603904,
        "soil_moisture": 23.29960962318096,
        "ndvi": 0.3517470833337723,
        "soil_temp": 23.201600008096072,
        "soil_humidity": 67.65644725674005
    },
    {
        "date": "2024-08-10 19:53:09.251267",
        "air_temp": 34.93273674147811,
        "humidity": 40.49619105639178,
        "rainfall": 2,
        "wind_speed": 4.96574959261692,
        "soil_moisture": 27.575891515837647,
        "ndvi": 0.22429269459698267,
        "soil_temp": 13.209425962611931,
        "soil_humidity": 51.29404506068556
    },
    {
        "date": "2024-08-10 20:53:09.251267",
        "air_temp": 26.10863411205255,
        "humidity": 63.204757972675196,
        "rainfall": 1,
        "wind_speed": 19.76027924130843,
        "soil_moisture": 39.28234997417557,
        "ndvi": 0.4417337473739785,
        "soil_temp": 24.580631412649353,
        "soil_humidity": 64.0942184154994
    },
    {
        "date": "2024-08-10 21:53:09.251267",
        "air_temp": 30.37974830361021,
        "humidity": 88.17496204437941,
        "rainfall": 0,
        "wind_speed": 7.78779559333747,
        "soil_moisture": 25.969130915444985,
        "ndvi": 0.7611306861025251,
        "soil_temp": 12.14177275209347,
        "soil_humidity": 43.68380023037703
    },
    {
        "date": "2024-08-10 22:53:09.251267",
        "air_temp": 33.895314597648564,
        "humidity": 65.95161400647743,
        "rainfall": 0,
        "wind_speed": 14.652253984807974,
        "soil_moisture": 37.159654851282696,
        "ndvi": 0.20705905662019863,
        "soil_temp": 11.516877822724874,
        "soil_humidity": 43.41153268317882
    },
    {
        "date": "2024-08-10 23:53:09.251267",
        "air_temp": 31.99294781354823,
        "humidity": 73.87711337294766,
        "rainfall": 0.5,
        "wind_speed": 13.694503277620091,
        "soil_moisture": 12.491162734305593,
        "ndvi": 0.552731415157869,
        "soil_temp": 11.683173088709093,
        "soil_humidity": 69.42161922696157
    },
    {
        "date": "2024-08-11 00:53:09.251267",
        "air_temp": 19.94696203486395,
        "humidity": 55.59319289584416,
        "rainfall": 0,
        "wind_speed": 4.2416247495475545,
        "soil_moisture": 15.731556061260486,
        "ndvi": 0.5008378871571946,
        "soil_temp": 18.596918849088468,
        "soil_humidity": 50.873321939793996
    },
    {
        "date": "2024-08-11 01:53:09.251267",
        "air_temp": 24.01088270620187,
        "humidity": 78.69956890763812,
        "rainfall": 0,
        "wind_speed": 4.779953604231027,
        "soil_moisture": 23.222648303239534,
        "ndvi": 0.7240304986226285,
        "soil_temp": 21.714616010770783,
        "soil_humidity": 35.62781695442985
    },
    {
        "date": "2024-08-11 02:53:09.251267",
        "air_temp": 17.5831883030299,
        "humidity": 78.64607409380575,
        "rainfall": 0.5,
        "wind_speed": 5.625691271060682,
        "soil_moisture": 27.54270425011914,
        "ndvi": 0.2504010287123754,
        "soil_temp": 15.500007668119498,
        "soil_humidity": 38.78698711978829
    },
    {
        "date": "2024-08-11 03:53:09.251267",
        "air_temp": 34.08102054517445,
        "humidity": 66.06480238270694,
        "rainfall": 1,
        "wind_speed": 8.222545565187584,
        "soil_moisture": 34.48683555588401,
        "ndvi": 0.6089156179319903,
        "soil_temp": 24.668439816791896,
        "soil_humidity": 49.277771175708835
    },
    {
        "date": "2024-08-11 04:53:09.251267",
        "air_temp": 27.123492689017603,
        "humidity": 88.80069116396068,
        "rainfall": 2,
        "wind_speed": 4.686501313010536,
        "soil_moisture": 39.6478634542956,
        "ndvi": 0.6080658190599664,
        "soil_temp": 16.51630207177494,
        "soil_humidity": 31.698836419536455
    },
    {
        "date": "2024-08-11 05:53:09.251267",
        "air_temp": 19.572856110069253,
        "humidity": 46.27751073813267,
        "rainfall": 0.5,
        "wind_speed": 0.3930296549369561,
        "soil_moisture": 21.665544894223494,
        "ndvi": 0.60144801011737,
        "soil_temp": 12.256728261598244,
        "soil_humidity": 48.501359415132015
    },
    {
        "date": "2024-08-11 06:53:09.251267",
        "air_temp": 28.434013688117133,
        "humidity": 40.84738324447905,
        "rainfall": 1,
        "wind_speed": 17.58786113614391,
        "soil_moisture": 11.422469084202548,
        "ndvi": 0.7654006969610223,
        "soil_temp": 16.98950122279205,
        "soil_humidity": 46.7576336832051
    },
    {
        "date": "2024-08-11 07:53:09.251267",
        "air_temp": 27.362564809157917,
        "humidity": 78.50791058785481,
        "rainfall": 0.5,
        "wind_speed": 19.31219662761768,
        "soil_moisture": 34.03514509885974,
        "ndvi": 0.6938525174041283,
        "soil_temp": 20.1855574813366,
        "soil_humidity": 61.173450612779476
    },
    {
        "date": "2024-08-11 08:53:09.251267",
        "air_temp": 22.163254360656808,
        "humidity": 80.35777686824413,
        "rainfall": 1,
        "wind_speed": 6.831407740631359,
        "soil_moisture": 28.131421572484243,
        "ndvi": 0.22518363574610362,
        "soil_temp": 22.48338670756568,
        "soil_humidity": 44.19767857956967
    },
    {
        "date": "2024-08-11 09:53:09.251267",
        "air_temp": 17.27115184399258,
        "humidity": 46.01036145602881,
        "rainfall": 0.5,
        "wind_speed": 6.770137588509457,
        "soil_moisture": 15.95628576604605,
        "ndvi": 0.48264971042270793,
        "soil_temp": 16.881524349348894,
        "soil_humidity": 69.30195046158397
    },
    {
        "date": "2024-08-11 10:53:09.251267",
        "air_temp": 28.431463911855992,
        "humidity": 53.2791416479303,
        "rainfall": 1,
        "wind_speed": 0.14183625548741974,
        "soil_moisture": 24.844853698642606,
        "ndvi": 0.43877183372080014,
        "soil_temp": 18.904102390737513,
        "soil_humidity": 37.23079677203613
    },
    {
        "date": "2024-08-11 11:53:09.251267",
        "air_temp": 25.406154018075867,
        "humidity": 40.877618168331445,
        "rainfall": 0.5,
        "wind_speed": 13.605013766607481,
        "soil_moisture": 32.26930736601173,
        "ndvi": 0.3860883326544813,
        "soil_temp": 14.0101706823828,
        "soil_humidity": 53.639467398725394
    },
    {
        "date": "2024-08-11 12:53:09.251267",
        "air_temp": 30.446367834712788,
        "humidity": 54.665430539028236,
        "rainfall": 1,
        "wind_speed": 16.508797914972575,
        "soil_moisture": 33.11235361205931,
        "ndvi": 0.47080203509245844,
        "soil_temp": 19.68874789608271,
        "soil_humidity": 33.58797188033019
    },
    {
        "date": "2024-08-11 13:53:09.251267",
        "air_temp": 25.403270022239866,
        "humidity": 78.65709369593358,
        "rainfall": 0.5,
        "wind_speed": 2.9321660072000633,
        "soil_moisture": 24.98855235996286,
        "ndvi": 0.4305165109555865,
        "soil_temp": 24.789579159255155,
        "soil_humidity": 59.35455935975928
    },
    {
        "date": "2024-08-11 14:53:09.251267",
        "air_temp": 32.043630006370805,
        "humidity": 65.89802638562895,
        "rainfall": 2,
        "wind_speed": 3.193326987343015,
        "soil_moisture": 19.156435903865773,
        "ndvi": 0.45940033992719925,
        "soil_temp": 10.735803588816907,
        "soil_humidity": 69.79014752247886
    },
    {
        "date": "2024-08-11 15:53:09.251267",
        "air_temp": 26.03813677548971,
        "humidity": 57.404779658414405,
        "rainfall": 2,
        "wind_speed": 15.801664999428755,
        "soil_moisture": 39.212277461154805,
        "ndvi": 0.7875571193651856,
        "soil_temp": 23.297960060577516,
        "soil_humidity": 62.76554551006529
    },
    {
        "date": "2024-08-11 16:53:09.251267",
        "air_temp": 26.218759430707728,
        "humidity": 58.586964745316514,
        "rainfall": 0,
        "wind_speed": 12.325814967082893,
        "soil_moisture": 29.900878328694784,
        "ndvi": 0.25317978484974846,
        "soil_temp": 18.434237541512843,
        "soil_humidity": 52.228141628812054
    },
    {
        "date": "2024-08-11 17:53:09.251267",
        "air_temp": 32.533072053166904,
        "humidity": 40.06768128577341,
        "rainfall": 1,
        "wind_speed": 0.8972111525543403,
        "soil_moisture": 22.24712558038896,
        "ndvi": 0.753338521903238,
        "soil_temp": 13.781479893434499,
        "soil_humidity": 49.38965429598768
    },
    {
        "date": "2024-08-11 18:53:09.251267",
        "air_temp": 23.06965732424794,
        "humidity": 54.990026378261796,
        "rainfall": 0.5,
        "wind_speed": 12.450939809245634,
        "soil_moisture": 18.413896726139573,
        "ndvi": 0.4881420280826161,
        "soil_temp": 24.32334565013887,
        "soil_humidity": 63.401198450673874
    },
    {
        "date": "2024-08-11 19:53:09.251267",
        "air_temp": 17.680304569012815,
        "humidity": 72.32293708189653,
        "rainfall": 1,
        "wind_speed": 6.178354456875321,
        "soil_moisture": 31.933089490106862,
        "ndvi": 0.5742453472997248,
        "soil_temp": 17.250314545883484,
        "soil_humidity": 67.43896338842897
    },
    {
        "date": "2024-08-11 20:53:09.251267",
        "air_temp": 15.57565352626678,
        "humidity": 88.70972219081786,
        "rainfall": 2,
        "wind_speed": 7.374503109416919,
        "soil_moisture": 17.265503367813032,
        "ndvi": 0.6981097799706164,
        "soil_temp": 14.975353775100785,
        "soil_humidity": 69.56826103192373
    },
    {
        "date": "2024-08-11 21:53:09.251267",
        "air_temp": 30.10274511347238,
        "humidity": 82.35304134919497,
        "rainfall": 1,
        "wind_speed": 15.394628365937603,
        "soil_moisture": 15.151299669210644,
        "ndvi": 0.4162778174895534,
        "soil_temp": 12.722464424062618,
        "soil_humidity": 45.86156910257937
    },
    {
        "date": "2024-08-11 22:53:09.251267",
        "air_temp": 27.406191027069294,
        "humidity": 41.17974823227646,
        "rainfall": 0.5,
        "wind_speed": 16.21870385345221,
        "soil_moisture": 25.89501666886211,
        "ndvi": 0.4582531448527802,
        "soil_temp": 13.66678315384993,
        "soil_humidity": 69.63669899178049
    },
    {
        "date": "2024-08-11 23:53:09.251267",
        "air_temp": 29.08159536198447,
        "humidity": 84.92803023374196,
        "rainfall": 1,
        "wind_speed": 8.007132536796613,
        "soil_moisture": 18.699621779324254,
        "ndvi": 0.44571108816722843,
        "soil_temp": 22.49273562387164,
        "soil_humidity": 36.23705008695326
    },
    {
        "date": "2024-08-12 00:53:09.251267",
        "air_temp": 19.259283230178212,
        "humidity": 79.15992066493129,
        "rainfall": 0.5,
        "wind_speed": 18.943058720127095,
        "soil_moisture": 28.86715434770845,
        "ndvi": 0.42483861352145597,
        "soil_temp": 19.216150139757488,
        "soil_humidity": 40.14199103727637
    },
    {
        "date": "2024-08-12 01:53:09.251267",
        "air_temp": 17.727429511735394,
        "humidity": 79.01880617854903,
        "rainfall": 2,
        "wind_speed": 8.129254359041445,
        "soil_moisture": 13.538160246227005,
        "ndvi": 0.755317592988137,
        "soil_temp": 23.698684292324742,
        "soil_humidity": 33.56064498935585
    },
    {
        "date": "2024-08-12 02:53:09.251267",
        "air_temp": 15.290893313357639,
        "humidity": 62.89978795017858,
        "rainfall": 2,
        "wind_speed": 15.436720704602198,
        "soil_moisture": 29.64823313701965,
        "ndvi": 0.6973579347158073,
        "soil_temp": 13.479526782366786,
        "soil_humidity": 40.86954065937927
    },
    {
        "date": "2024-08-12 03:53:09.251267",
        "air_temp": 22.011751176131938,
        "humidity": 59.90026464772514,
        "rainfall": 2,
        "wind_speed": 9.677545291285181,
        "soil_moisture": 18.498895839931066,
        "ndvi": 0.2653999939177857,
        "soil_temp": 11.817078607733523,
        "soil_humidity": 60.71750206472045
    },
    {
        "date": "2024-08-12 04:53:09.251267",
        "air_temp": 26.79835373709266,
        "humidity": 55.14578644165963,
        "rainfall": 0,
        "wind_speed": 17.95064653044168,
        "soil_moisture": 10.007288033035382,
        "ndvi": 0.26487401148625767,
        "soil_temp": 10.09131296463385,
        "soil_humidity": 64.116974327138
    },
    {
        "date": "2024-08-12 05:53:09.251267",
        "air_temp": 22.844880901994646,
        "humidity": 43.284742947718286,
        "rainfall": 2,
        "wind_speed": 18.155224873003572,
        "soil_moisture": 13.808081730225291,
        "ndvi": 0.45672170891179475,
        "soil_temp": 17.617930564239906,
        "soil_humidity": 61.155705804102865
    },
    {
        "date": "2024-08-12 06:53:09.251267",
        "air_temp": 23.749498440474582,
        "humidity": 51.41176817468495,
        "rainfall": 1,
        "wind_speed": 1.7483361574216771,
        "soil_moisture": 38.00846133505282,
        "ndvi": 0.25919442603968545,
        "soil_temp": 24.974603955729823,
        "soil_humidity": 55.24263112670785
    },
    {
        "date": "2024-08-12 07:53:09.251267",
        "air_temp": 33.08317388987497,
        "humidity": 52.33018174890123,
        "rainfall": 0,
        "wind_speed": 18.729866626204515,
        "soil_moisture": 36.02063917346074,
        "ndvi": 0.5714558571626719,
        "soil_temp": 20.424479032279585,
        "soil_humidity": 50.55866050090582
    },
    {
        "date": "2024-08-12 08:53:09.251267",
        "air_temp": 21.965109340466007,
        "humidity": 64.20385348551008,
        "rainfall": 2,
        "wind_speed": 16.510696830408314,
        "soil_moisture": 17.008270961087106,
        "ndvi": 0.28621496435704036,
        "soil_temp": 14.62591887947719,
        "soil_humidity": 58.34817156854586
    },
    {
        "date": "2024-08-12 09:53:09.251267",
        "air_temp": 25.279789783196215,
        "humidity": 77.3722203298347,
        "rainfall": 1,
        "wind_speed": 15.826214286253338,
        "soil_moisture": 33.35115713636473,
        "ndvi": 0.5134239583800807,
        "soil_temp": 23.152878745392577,
        "soil_humidity": 45.633341970688875
    },
    {
        "date": "2024-08-12 10:53:09.251267",
        "air_temp": 30.67306025482286,
        "humidity": 63.69049141989733,
        "rainfall": 1,
        "wind_speed": 4.018226793710449,
        "soil_moisture": 28.25714779521923,
        "ndvi": 0.20433196820223065,
        "soil_temp": 21.725833989819396,
        "soil_humidity": 63.65233405998807
    },
    {
        "date": "2024-08-12 11:53:09.251267",
        "air_temp": 22.930855646425403,
        "humidity": 42.89222247235803,
        "rainfall": 1,
        "wind_speed": 16.1023841750933,
        "soil_moisture": 39.127141343496085,
        "ndvi": 0.38995780904758803,
        "soil_temp": 10.231227491148088,
        "soil_humidity": 57.08760375907141
    },
    {
        "date": "2024-08-12 12:53:09.251267",
        "air_temp": 27.441734004557468,
        "humidity": 87.8894900494102,
        "rainfall": 0.5,
        "wind_speed": 17.002654693700737,
        "soil_moisture": 21.208169786021774,
        "ndvi": 0.6063144342035933,
        "soil_temp": 22.25290720811534,
        "soil_humidity": 57.912187939776345
    },
    {
        "date": "2024-08-12 13:53:09.251267",
        "air_temp": 32.2472741749349,
        "humidity": 87.13621595470562,
        "rainfall": 0,
        "wind_speed": 5.7830066890830185,
        "soil_moisture": 30.44381837551514,
        "ndvi": 0.4904233641367091,
        "soil_temp": 14.50790135857744,
        "soil_humidity": 51.43743972516285
    },
    {
        "date": "2024-08-12 14:53:09.251267",
        "air_temp": 33.990412473152844,
        "humidity": 79.25947715742042,
        "rainfall": 1,
        "wind_speed": 19.045201567246476,
        "soil_moisture": 11.906071672717557,
        "ndvi": 0.7869616915993929,
        "soil_temp": 12.245101968754017,
        "soil_humidity": 65.4091593684469
    },
    {
        "date": "2024-08-12 15:53:09.251267",
        "air_temp": 17.941469618580758,
        "humidity": 89.56639507821103,
        "rainfall": 0.5,
        "wind_speed": 0.997202927983285,
        "soil_moisture": 21.88742040449218,
        "ndvi": 0.7244622776397265,
        "soil_temp": 13.394962596056118,
        "soil_humidity": 40.0805893471621
    },
    {
        "date": "2024-08-12 16:53:09.251267",
        "air_temp": 33.53175250322989,
        "humidity": 67.22411664001315,
        "rainfall": 2,
        "wind_speed": 2.9981850649427932,
        "soil_moisture": 38.91452983564541,
        "ndvi": 0.41544246742589075,
        "soil_temp": 17.028697065627085,
        "soil_humidity": 45.24590613444203
    },
    {
        "date": "2024-08-12 17:53:09.251267",
        "air_temp": 24.842325861590766,
        "humidity": 88.13841751266031,
        "rainfall": 1,
        "wind_speed": 10.751622761030287,
        "soil_moisture": 26.169426233503618,
        "ndvi": 0.22729192488336478,
        "soil_temp": 16.373018906020164,
        "soil_humidity": 61.10570278710756
    },
    {
        "date": "2024-08-12 18:53:09.251267",
        "air_temp": 20.164887765979167,
        "humidity": 43.78025203083754,
        "rainfall": 0.5,
        "wind_speed": 11.515849422446195,
        "soil_moisture": 18.636385100045338,
        "ndvi": 0.7073757800690115,
        "soil_temp": 14.333309490613402,
        "soil_humidity": 49.70995584517351
    },
    {
        "date": "2024-08-12 19:53:09.251267",
        "air_temp": 24.182715124765224,
        "humidity": 58.28403741306857,
        "rainfall": 1,
        "wind_speed": 12.903001666018067,
        "soil_moisture": 13.608128893858206,
        "ndvi": 0.746740586188803,
        "soil_temp": 12.583274833105492,
        "soil_humidity": 65.56856053696762
    },
    {
        "date": "2024-08-12 20:53:09.251267",
        "air_temp": 34.60065150570954,
        "humidity": 51.26906340033829,
        "rainfall": 0,
        "wind_speed": 0.33938258630932916,
        "soil_moisture": 31.10585020544591,
        "ndvi": 0.6923764360514222,
        "soil_temp": 21.43346184463254,
        "soil_humidity": 43.855972790514095
    },
    {
        "date": "2024-08-12 21:53:09.251267",
        "air_temp": 24.85236187985739,
        "humidity": 49.7916542067135,
        "rainfall": 0,
        "wind_speed": 19.207335492442606,
        "soil_moisture": 29.345681302891805,
        "ndvi": 0.2767699645518589,
        "soil_temp": 24.851646756023754,
        "soil_humidity": 44.77320675278579
    },
    {
        "date": "2024-08-12 22:53:09.251267",
        "air_temp": 21.575032205750162,
        "humidity": 47.0401474446338,
        "rainfall": 2,
        "wind_speed": 0.8917618546828798,
        "soil_moisture": 21.21816140811324,
        "ndvi": 0.7987490806473727,
        "soil_temp": 21.666873706295355,
        "soil_humidity": 65.54943157546501
    },
    {
        "date": "2024-08-12 23:53:09.251267",
        "air_temp": 27.668017086334515,
        "humidity": 71.12068207829984,
        "rainfall": 1,
        "wind_speed": 2.8612390544680477,
        "soil_moisture": 38.52824055597503,
        "ndvi": 0.3551505329316643,
        "soil_temp": 14.439054515073117,
        "soil_humidity": 48.574812807281646
    },
    {
        "date": "2024-08-13 00:53:09.251267",
        "air_temp": 19.802912375563864,
        "humidity": 79.06609278736525,
        "rainfall": 0.5,
        "wind_speed": 0.2802903505211396,
        "soil_moisture": 10.66608438963551,
        "ndvi": 0.634522947685022,
        "soil_temp": 13.672134421182001,
        "soil_humidity": 50.644983368099474
    },
    {
        "date": "2024-08-13 01:53:09.251267",
        "air_temp": 16.51726656217328,
        "humidity": 68.91491997101804,
        "rainfall": 2,
        "wind_speed": 11.337168665881894,
        "soil_moisture": 19.111715548954773,
        "ndvi": 0.7448209258014331,
        "soil_temp": 13.264988140989074,
        "soil_humidity": 43.31054861342717
    },
    {
        "date": "2024-08-13 02:53:09.251267",
        "air_temp": 17.577594438212984,
        "humidity": 47.34813547941049,
        "rainfall": 1,
        "wind_speed": 18.645337678419445,
        "soil_moisture": 37.63208458898744,
        "ndvi": 0.34141226859842566,
        "soil_temp": 12.050426117335544,
        "soil_humidity": 41.06679768716327
    },
    {
        "date": "2024-08-13 03:53:09.251267",
        "air_temp": 17.56091677915545,
        "humidity": 80.55618849667354,
        "rainfall": 2,
        "wind_speed": 13.318943321609284,
        "soil_moisture": 19.628063149583213,
        "ndvi": 0.7449551328062398,
        "soil_temp": 18.26995824538452,
        "soil_humidity": 56.23794843353568
    },
    {
        "date": "2024-08-13 04:53:09.251267",
        "air_temp": 18.038053870245886,
        "humidity": 71.79773535818643,
        "rainfall": 0.5,
        "wind_speed": 16.453149369608546,
        "soil_moisture": 17.567585015655112,
        "ndvi": 0.3856889475169202,
        "soil_temp": 19.08100557930016,
        "soil_humidity": 34.79362646164815
    },
    {
        "date": "2024-08-13 05:53:09.251267",
        "air_temp": 17.776543452988204,
        "humidity": 59.408285306349775,
        "rainfall": 2,
        "wind_speed": 0.26562406917160164,
        "soil_moisture": 26.02661229793742,
        "ndvi": 0.7858479334304338,
        "soil_temp": 20.58253990994706,
        "soil_humidity": 65.17090564731637
    },
    {
        "date": "2024-08-13 06:53:09.251267",
        "air_temp": 27.817494896064293,
        "humidity": 73.70651477680315,
        "rainfall": 2,
        "wind_speed": 10.840305969594521,
        "soil_moisture": 34.71405249761614,
        "ndvi": 0.3687560122282848,
        "soil_temp": 20.20320056400502,
        "soil_humidity": 61.168125470604316
    },
    {
        "date": "2024-08-13 07:53:09.251267",
        "air_temp": 18.637601687982897,
        "humidity": 52.995248962141645,
        "rainfall": 2,
        "wind_speed": 9.191259550110438,
        "soil_moisture": 13.094402826350832,
        "ndvi": 0.7720614948547011,
        "soil_temp": 14.384999669250737,
        "soil_humidity": 37.62993054823211
    },
    {
        "date": "2024-08-13 08:53:09.251267",
        "air_temp": 21.913345666477262,
        "humidity": 57.25961042471053,
        "rainfall": 1,
        "wind_speed": 9.979358884415861,
        "soil_moisture": 38.29062128937208,
        "ndvi": 0.23212679053560495,
        "soil_temp": 14.954665080952697,
        "soil_humidity": 57.76079783298262
    },
    {
        "date": "2024-08-13 09:53:09.251267",
        "air_temp": 32.935768198120236,
        "humidity": 85.87429215659951,
        "rainfall": 0.5,
        "wind_speed": 1.444263706703759,
        "soil_moisture": 33.852186435434916,
        "ndvi": 0.6255632070018879,
        "soil_temp": 20.22271803163507,
        "soil_humidity": 54.10531685142539
    },
    {
        "date": "2024-08-13 10:53:09.251267",
        "air_temp": 24.479232805257446,
        "humidity": 54.52522528357102,
        "rainfall": 0,
        "wind_speed": 13.68870722138615,
        "soil_moisture": 15.437507666546558,
        "ndvi": 0.5192666803022024,
        "soil_temp": 15.241714611754425,
        "soil_humidity": 37.38140202946576
    },
    {
        "date": "2024-08-13 11:53:09.251267",
        "air_temp": 28.351154770420543,
        "humidity": 63.49941340789683,
        "rainfall": 1,
        "wind_speed": 10.064408297172688,
        "soil_moisture": 31.36023883594488,
        "ndvi": 0.4867262462736384,
        "soil_temp": 13.189347844289696,
        "soil_humidity": 40.9281402420093
    },
    {
        "date": "2024-08-13 12:53:09.251267",
        "air_temp": 18.446397424032597,
        "humidity": 84.50705686980073,
        "rainfall": 0,
        "wind_speed": 15.30297699722528,
        "soil_moisture": 21.548761679126713,
        "ndvi": 0.334096588553504,
        "soil_temp": 15.615042305451095,
        "soil_humidity": 46.359460149353374
    },
    {
        "date": "2024-08-13 13:53:09.251267",
        "air_temp": 18.845780376173416,
        "humidity": 75.38606751337637,
        "rainfall": 0,
        "wind_speed": 9.705812681234306,
        "soil_moisture": 22.068840342289562,
        "ndvi": 0.6233574738658952,
        "soil_temp": 14.081262363669666,
        "soil_humidity": 57.969709409818954
    },
    {
        "date": "2024-08-13 14:53:09.251267",
        "air_temp": 15.817372325329577,
        "humidity": 43.110161308235135,
        "rainfall": 1,
        "wind_speed": 2.9876327245286416,
        "soil_moisture": 33.49870277316599,
        "ndvi": 0.31448569486778427,
        "soil_temp": 11.324690818278667,
        "soil_humidity": 55.548936352680236
    },
    {
        "date": "2024-08-13 15:53:09.251267",
        "air_temp": 18.37870126144329,
        "humidity": 47.36515221394852,
        "rainfall": 1,
        "wind_speed": 12.96513908155644,
        "soil_moisture": 38.883544239421965,
        "ndvi": 0.5317050775908609,
        "soil_temp": 22.452083534149804,
        "soil_humidity": 51.28939390706792
    },
    {
        "date": "2024-08-13 16:53:09.251267",
        "air_temp": 20.57180678063917,
        "humidity": 40.392079538595844,
        "rainfall": 0.5,
        "wind_speed": 3.4477272421033245,
        "soil_moisture": 13.799676185415818,
        "ndvi": 0.3519472178241505,
        "soil_temp": 22.796670837355546,
        "soil_humidity": 60.73642172456137
    },
    {
        "date": "2024-08-13 17:53:09.251267",
        "air_temp": 18.540209685534936,
        "humidity": 71.54608951145818,
        "rainfall": 0.5,
        "wind_speed": 17.4478912670963,
        "soil_moisture": 22.61890710636458,
        "ndvi": 0.7733728278688137,
        "soil_temp": 13.296254502503192,
        "soil_humidity": 60.317509584246636
    },
    {
        "date": "2024-08-13 18:53:09.251267",
        "air_temp": 16.774050675141112,
        "humidity": 62.38367127052092,
        "rainfall": 2,
        "wind_speed": 12.26232478138483,
        "soil_moisture": 24.428248242529108,
        "ndvi": 0.2171787163013917,
        "soil_temp": 10.971582992878027,
        "soil_humidity": 54.714366162490236
    },
    {
        "date": "2024-08-13 19:53:09.251267",
        "air_temp": 17.412717422012015,
        "humidity": 46.713742545113774,
        "rainfall": 0,
        "wind_speed": 3.144077671139085,
        "soil_moisture": 21.28935450864993,
        "ndvi": 0.31983696806674194,
        "soil_temp": 21.082411560267694,
        "soil_humidity": 61.47960333539301
    },
    {
        "date": "2024-08-13 20:53:09.251267",
        "air_temp": 24.215575360654515,
        "humidity": 87.89670605283852,
        "rainfall": 0.5,
        "wind_speed": 19.246761149624135,
        "soil_moisture": 20.89567371148675,
        "ndvi": 0.3453863638324687,
        "soil_temp": 13.172929387951205,
        "soil_humidity": 43.37053499116837
    },
    {
        "date": "2024-08-13 21:53:09.251267",
        "air_temp": 19.12667436811585,
        "humidity": 66.482986197281,
        "rainfall": 2,
        "wind_speed": 10.367309266706412,
        "soil_moisture": 11.568060026847895,
        "ndvi": 0.4484406954606899,
        "soil_temp": 15.901532341552905,
        "soil_humidity": 65.24998113290422
    },
    {
        "date": "2024-08-13 22:53:09.251267",
        "air_temp": 22.28539722096151,
        "humidity": 52.09466470564446,
        "rainfall": 0.5,
        "wind_speed": 1.4579690374331,
        "soil_moisture": 30.5661586168787,
        "ndvi": 0.33963814512203216,
        "soil_temp": 10.962028783560749,
        "soil_humidity": 54.59640343207763
    },
    {
        "date": "2024-08-13 23:53:09.251267",
        "air_temp": 25.068345417097138,
        "humidity": 65.03017855773538,
        "rainfall": 1,
        "wind_speed": 12.536658088301344,
        "soil_moisture": 23.08643251360136,
        "ndvi": 0.4948094541612712,
        "soil_temp": 13.394043771376527,
        "soil_humidity": 62.89454055249722
    },
    {
        "date": "2024-08-14 00:53:09.251267",
        "air_temp": 28.807896572587307,
        "humidity": 73.98101663675538,
        "rainfall": 0.5,
        "wind_speed": 5.0639781402562045,
        "soil_moisture": 15.843814079317802,
        "ndvi": 0.7234793139551234,
        "soil_temp": 21.893534174944534,
        "soil_humidity": 51.85116419161136
    },
    {
        "date": "2024-08-14 01:53:09.251267",
        "air_temp": 15.786242796821979,
        "humidity": 43.81196089867822,
        "rainfall": 0,
        "wind_speed": 16.07386903754605,
        "soil_moisture": 34.48100506906205,
        "ndvi": 0.6272380528455026,
        "soil_temp": 22.065946703728027,
        "soil_humidity": 38.73752979954722
    },
    {
        "date": "2024-08-14 02:53:09.251267",
        "air_temp": 30.988207978180853,
        "humidity": 53.7351907523946,
        "rainfall": 0.5,
        "wind_speed": 16.356441471323812,
        "soil_moisture": 34.07682244454884,
        "ndvi": 0.5479771883210667,
        "soil_temp": 13.106272373315656,
        "soil_humidity": 51.696405147132346
    },
    {
        "date": "2024-08-14 03:53:09.251267",
        "air_temp": 27.558007789818156,
        "humidity": 80.34827454419968,
        "rainfall": 0,
        "wind_speed": 19.578947117093236,
        "soil_moisture": 24.591748842666814,
        "ndvi": 0.25340190678960783,
        "soil_temp": 15.038707676600342,
        "soil_humidity": 44.844466551063526
    },
    {
        "date": "2024-08-14 04:53:09.251267",
        "air_temp": 16.635180638977438,
        "humidity": 62.98324352013306,
        "rainfall": 1,
        "wind_speed": 10.037390620490074,
        "soil_moisture": 18.953519834432818,
        "ndvi": 0.6163952650335655,
        "soil_temp": 12.013576286406153,
        "soil_humidity": 57.89996144148378
    },
    {
        "date": "2024-08-14 05:53:09.251267",
        "air_temp": 32.47157248213554,
        "humidity": 67.32885710161474,
        "rainfall": 0,
        "wind_speed": 9.099137594554742,
        "soil_moisture": 29.14278177389357,
        "ndvi": 0.29984857058656395,
        "soil_temp": 11.112589454273836,
        "soil_humidity": 49.031751669389436
    },
    {
        "date": "2024-08-14 06:53:09.251267",
        "air_temp": 33.417448010636264,
        "humidity": 61.640800110473464,
        "rainfall": 0.5,
        "wind_speed": 15.069512108929612,
        "soil_moisture": 18.644564073322094,
        "ndvi": 0.6718204315770189,
        "soil_temp": 24.882575716726144,
        "soil_humidity": 63.44451219710751
    },
    {
        "date": "2024-08-14 07:53:09.251267",
        "air_temp": 16.221559197097275,
        "humidity": 42.194997132150256,
        "rainfall": 2,
        "wind_speed": 2.6494131268385313,
        "soil_moisture": 17.08023790971136,
        "ndvi": 0.350034055106092,
        "soil_temp": 19.83390926867259,
        "soil_humidity": 57.12290049287667
    },
    {
        "date": "2024-08-14 08:53:09.251267",
        "air_temp": 20.537552962944076,
        "humidity": 48.2874889910078,
        "rainfall": 1,
        "wind_speed": 10.938310275862484,
        "soil_moisture": 31.9154396207898,
        "ndvi": 0.4502292369925406,
        "soil_temp": 18.13144763797478,
        "soil_humidity": 57.381777321252954
    },
    {
        "date": "2024-08-14 09:53:09.251267",
        "air_temp": 31.124025595861227,
        "humidity": 62.27708193709454,
        "rainfall": 0.5,
        "wind_speed": 10.924952002617019,
        "soil_moisture": 11.20975719162852,
        "ndvi": 0.4102821213907568,
        "soil_temp": 21.48247750607949,
        "soil_humidity": 38.6862133396426
    },
    {
        "date": "2024-08-14 10:53:09.251267",
        "air_temp": 29.965193807673167,
        "humidity": 50.459273270396395,
        "rainfall": 1,
        "wind_speed": 1.7894624562077444,
        "soil_moisture": 12.84305413103782,
        "ndvi": 0.7152883488049142,
        "soil_temp": 22.601157395315862,
        "soil_humidity": 69.36999065965162
    },
    {
        "date": "2024-08-14 11:53:09.251267",
        "air_temp": 18.690420387127546,
        "humidity": 42.49895143298394,
        "rainfall": 1,
        "wind_speed": 8.359681710138272,
        "soil_moisture": 10.203645220707694,
        "ndvi": 0.2759266752280449,
        "soil_temp": 22.100721520670913,
        "soil_humidity": 63.66700837085955
    },
    {
        "date": "2024-08-14 12:53:09.251267",
        "air_temp": 19.186986466734204,
        "humidity": 82.18248320259119,
        "rainfall": 0.5,
        "wind_speed": 17.065695971344038,
        "soil_moisture": 14.588295594262785,
        "ndvi": 0.5393869294308733,
        "soil_temp": 10.24809173934815,
        "soil_humidity": 61.560195382427786
    },
    {
        "date": "2024-08-14 13:53:09.251267",
        "air_temp": 22.40944205582764,
        "humidity": 89.0596264582058,
        "rainfall": 0.5,
        "wind_speed": 17.123072439893207,
        "soil_moisture": 12.069700261313784,
        "ndvi": 0.28523696566583734,
        "soil_temp": 12.802118472857478,
        "soil_humidity": 43.2086090064011
    },
    {
        "date": "2024-08-14 14:53:09.251267",
        "air_temp": 24.690459703820427,
        "humidity": 79.65714369983698,
        "rainfall": 2,
        "wind_speed": 1.98475329839362,
        "soil_moisture": 39.874071033397556,
        "ndvi": 0.622877501679312,
        "soil_temp": 19.823579531767464,
        "soil_humidity": 51.42526611388928
    },
    {
        "date": "2024-08-14 15:53:09.251267",
        "air_temp": 27.36509543060592,
        "humidity": 82.68924608523335,
        "rainfall": 1,
        "wind_speed": 1.814417654956617,
        "soil_moisture": 18.66429909862269,
        "ndvi": 0.7430468766766889,
        "soil_temp": 15.636390217059443,
        "soil_humidity": 35.48412752297315
    },
    {
        "date": "2024-08-14 16:53:09.251267",
        "air_temp": 22.378272791395446,
        "humidity": 52.09885651585227,
        "rainfall": 1,
        "wind_speed": 5.2539095400387055,
        "soil_moisture": 14.478040380077418,
        "ndvi": 0.4607645923203521,
        "soil_temp": 24.737239765916605,
        "soil_humidity": 51.53903906650824
    },
    {
        "date": "2024-08-14 17:53:09.251267",
        "air_temp": 24.25069432266296,
        "humidity": 88.03134877565998,
        "rainfall": 0.5,
        "wind_speed": 17.49532125107987,
        "soil_moisture": 17.22988773987754,
        "ndvi": 0.49285535615898557,
        "soil_temp": 19.981071889573812,
        "soil_humidity": 35.83556985933149
    },
    {
        "date": "2024-08-14 18:53:09.251267",
        "air_temp": 29.94941876267513,
        "humidity": 49.84628522267142,
        "rainfall": 0,
        "wind_speed": 2.578051701469979,
        "soil_moisture": 38.08376714620404,
        "ndvi": 0.7564537018896575,
        "soil_temp": 24.348233630938097,
        "soil_humidity": 52.12151375958405
    },
    {
        "date": "2024-08-14 19:53:09.251267",
        "air_temp": 15.733664057811957,
        "humidity": 87.57149051809162,
        "rainfall": 0.5,
        "wind_speed": 14.399936052548926,
        "soil_moisture": 25.60480669073187,
        "ndvi": 0.5125948331896144,
        "soil_temp": 13.021697224686907,
        "soil_humidity": 55.94887520619622
    },
    {
        "date": "2024-08-14 20:53:09.251267",
        "air_temp": 20.048738886880415,
        "humidity": 89.74096469829755,
        "rainfall": 1,
        "wind_speed": 2.0233123922620644,
        "soil_moisture": 20.944542889235706,
        "ndvi": 0.4925590628297366,
        "soil_temp": 22.95935542172535,
        "soil_humidity": 45.60783868999609
    },
    {
        "date": "2024-08-14 21:53:09.251267",
        "air_temp": 29.26699171769105,
        "humidity": 75.58614028730244,
        "rainfall": 0.5,
        "wind_speed": 12.462757074956507,
        "soil_moisture": 36.20174373727658,
        "ndvi": 0.5469974616225826,
        "soil_temp": 14.820284587228379,
        "soil_humidity": 49.94179513325173
    },
    {
        "date": "2024-08-14 22:53:09.251267",
        "air_temp": 32.904136753743984,
        "humidity": 89.0571865895911,
        "rainfall": 2,
        "wind_speed": 7.121345982382897,
        "soil_moisture": 21.849708116470975,
        "ndvi": 0.6135378418177713,
        "soil_temp": 24.843472123567103,
        "soil_humidity": 65.02613550698344
    },
    {
        "date": "2024-08-14 23:53:09.251267",
        "air_temp": 25.233548842313322,
        "humidity": 68.47698897835518,
        "rainfall": 0,
        "wind_speed": 15.77827925931734,
        "soil_moisture": 20.050257098730874,
        "ndvi": 0.26413002505221883,
        "soil_temp": 12.365330081097209,
        "soil_humidity": 34.342525788112376
    },
    {
        "date": "2024-08-15 00:53:09.251267",
        "air_temp": 25.642269705306312,
        "humidity": 52.97708915880452,
        "rainfall": 0.5,
        "wind_speed": 4.673808976507273,
        "soil_moisture": 16.218475676492066,
        "ndvi": 0.6926663166449097,
        "soil_temp": 17.7450972825749,
        "soil_humidity": 61.669315719986116
    },
    {
        "date": "2024-08-15 01:53:09.251267",
        "air_temp": 17.14344022679552,
        "humidity": 61.84979428328104,
        "rainfall": 2,
        "wind_speed": 12.784036587799196,
        "soil_moisture": 21.333904717282344,
        "ndvi": 0.4613030164719556,
        "soil_temp": 21.89266800755683,
        "soil_humidity": 36.830182039847855
    },
    {
        "date": "2024-08-15 02:53:09.251267",
        "air_temp": 23.948247336469095,
        "humidity": 69.6780467689393,
        "rainfall": 1,
        "wind_speed": 4.713650691517699,
        "soil_moisture": 19.50053068050778,
        "ndvi": 0.24340910593849135,
        "soil_temp": 16.48963720906054,
        "soil_humidity": 31.468319312185137
    },
    {
        "date": "2024-08-15 03:53:09.251267",
        "air_temp": 25.652345329100463,
        "humidity": 43.65407810135075,
        "rainfall": 0.5,
        "wind_speed": 14.273205662243075,
        "soil_moisture": 30.31911877894423,
        "ndvi": 0.6717096015189279,
        "soil_temp": 17.0212609028147,
        "soil_humidity": 37.42298410980191
    },
    {
        "date": "2024-08-15 04:53:09.251267",
        "air_temp": 19.849410072694592,
        "humidity": 71.1171632308623,
        "rainfall": 1,
        "wind_speed": 17.473560691214974,
        "soil_moisture": 18.68748114012181,
        "ndvi": 0.4261699830117633,
        "soil_temp": 24.795336932586117,
        "soil_humidity": 60.601727598819586
    },
    {
        "date": "2024-08-15 05:53:09.251267",
        "air_temp": 20.38486461898762,
        "humidity": 89.05889183811473,
        "rainfall": 2,
        "wind_speed": 2.536035982535201,
        "soil_moisture": 27.315992392676844,
        "ndvi": 0.2602204970570324,
        "soil_temp": 16.3796222545173,
        "soil_humidity": 46.2050429334744
    },
    {
        "date": "2024-08-15 06:53:09.251267",
        "air_temp": 22.545683262092453,
        "humidity": 49.50538248918638,
        "rainfall": 1,
        "wind_speed": 17.33191377191007,
        "soil_moisture": 11.976764950959181,
        "ndvi": 0.5802674237259504,
        "soil_temp": 19.061081508985954,
        "soil_humidity": 64.90082067384728
    },
    {
        "date": "2024-08-15 07:53:09.251267",
        "air_temp": 15.401423955554527,
        "humidity": 79.62975581441916,
        "rainfall": 2,
        "wind_speed": 11.871505088285959,
        "soil_moisture": 15.73867470089543,
        "ndvi": 0.7280788120601851,
        "soil_temp": 10.818094910127261,
        "soil_humidity": 50.98830164729063
    },
    {
        "date": "2024-08-15 08:53:09.251267",
        "air_temp": 21.441583311663564,
        "humidity": 85.39493968898097,
        "rainfall": 2,
        "wind_speed": 2.5422098173404795,
        "soil_moisture": 22.222158148340462,
        "ndvi": 0.7326521056483919,
        "soil_temp": 22.6331512031539,
        "soil_humidity": 30.890014077053237
    },
    {
        "date": "2024-08-15 09:53:09.251267",
        "air_temp": 19.22896013993089,
        "humidity": 87.18507964101104,
        "rainfall": 2,
        "wind_speed": 8.55083710719424,
        "soil_moisture": 38.10847761726086,
        "ndvi": 0.774598084885384,
        "soil_temp": 19.722520734693152,
        "soil_humidity": 58.668544384077066
    },
    {
        "date": "2024-08-15 10:53:09.251267",
        "air_temp": 21.54994704355829,
        "humidity": 88.00678022027236,
        "rainfall": 0.5,
        "wind_speed": 3.217205869701958,
        "soil_moisture": 21.317559571470863,
        "ndvi": 0.7206232758600128,
        "soil_temp": 16.060301542942202,
        "soil_humidity": 34.40931634884931
    },
    {
        "date": "2024-08-15 11:53:09.251267",
        "air_temp": 17.395242636385024,
        "humidity": 66.0729819360736,
        "rainfall": 1,
        "wind_speed": 14.00695777733139,
        "soil_moisture": 29.922223635995024,
        "ndvi": 0.20128455160728514,
        "soil_temp": 10.242543099317796,
        "soil_humidity": 61.57518533847698
    },
    {
        "date": "2024-08-15 12:53:09.251267",
        "air_temp": 32.8105456147979,
        "humidity": 88.86539539957519,
        "rainfall": 1,
        "wind_speed": 15.177362688358993,
        "soil_moisture": 17.652616672641944,
        "ndvi": 0.42814469984803416,
        "soil_temp": 18.875032308902558,
        "soil_humidity": 36.7241019922072
    },
    {
        "date": "2024-08-15 13:53:09.251267",
        "air_temp": 26.871849071080973,
        "humidity": 77.86551035672194,
        "rainfall": 0,
        "wind_speed": 2.115930405455979,
        "soil_moisture": 13.811007279196952,
        "ndvi": 0.6911255479433019,
        "soil_temp": 10.189867427262433,
        "soil_humidity": 43.81785543222054
    },
    {
        "date": "2024-08-15 14:53:09.251267",
        "air_temp": 28.582046382889793,
        "humidity": 48.083571811848095,
        "rainfall": 0,
        "wind_speed": 10.122797053892887,
        "soil_moisture": 11.756098002401504,
        "ndvi": 0.4197518035990846,
        "soil_temp": 10.018054208635268,
        "soil_humidity": 60.450857185014414
    },
    {
        "date": "2024-08-15 15:53:09.251267",
        "air_temp": 30.783424772146766,
        "humidity": 63.845005323398496,
        "rainfall": 0,
        "wind_speed": 16.292501120518345,
        "soil_moisture": 19.16240495628254,
        "ndvi": 0.5609291038278237,
        "soil_temp": 19.24147843224032,
        "soil_humidity": 68.16925429905686
    },
    {
        "date": "2024-08-15 16:53:09.251267",
        "air_temp": 24.968843978581145,
        "humidity": 75.9165587686002,
        "rainfall": 0,
        "wind_speed": 18.723260831019928,
        "soil_moisture": 14.72983080390724,
        "ndvi": 0.5637901684247193,
        "soil_temp": 12.835256010018314,
        "soil_humidity": 44.857358025869296
    },
    {
        "date": "2024-08-15 17:53:09.251267",
        "air_temp": 16.738405761748474,
        "humidity": 52.36701579095417,
        "rainfall": 1,
        "wind_speed": 10.270357334835401,
        "soil_moisture": 15.220313440483725,
        "ndvi": 0.4190151824232939,
        "soil_temp": 12.87831324577143,
        "soil_humidity": 63.17600880795743
    },
    {
        "date": "2024-08-15 18:53:09.251267",
        "air_temp": 25.742130836370954,
        "humidity": 72.03075917744437,
        "rainfall": 2,
        "wind_speed": 19.00558447222814,
        "soil_moisture": 32.8227792516085,
        "ndvi": 0.7426843563687726,
        "soil_temp": 19.799073487491157,
        "soil_humidity": 37.63169061903355
    },
    {
        "date": "2024-08-15 19:53:09.251267",
        "air_temp": 26.736822360417584,
        "humidity": 73.33002395233096,
        "rainfall": 2,
        "wind_speed": 10.713779409120596,
        "soil_moisture": 10.475142128346226,
        "ndvi": 0.5019370072316707,
        "soil_temp": 10.805457819090615,
        "soil_humidity": 62.287190808099645
    },
    {
        "date": "2024-08-15 20:53:09.251267",
        "air_temp": 29.9087894836866,
        "humidity": 48.13498719042829,
        "rainfall": 0.5,
        "wind_speed": 7.883809891998159,
        "soil_moisture": 26.734303772063033,
        "ndvi": 0.47887052356250054,
        "soil_temp": 14.701083422118344,
        "soil_humidity": 56.29976237025004
    },
    {
        "date": "2024-08-15 21:53:09.251267",
        "air_temp": 23.63319092459359,
        "humidity": 68.25426562263782,
        "rainfall": 2,
        "wind_speed": 16.96406257105791,
        "soil_moisture": 21.383841417803943,
        "ndvi": 0.7985082473971377,
        "soil_temp": 12.241478532130285,
        "soil_humidity": 37.709740288494736
    },
    {
        "date": "2024-08-15 22:53:09.251267",
        "air_temp": 17.551606055911275,
        "humidity": 78.58134554893039,
        "rainfall": 1,
        "wind_speed": 9.859654209161782,
        "soil_moisture": 25.196499997256417,
        "ndvi": 0.49610021844357005,
        "soil_temp": 18.702645546948006,
        "soil_humidity": 42.100158414056466
    },
    {
        "date": "2024-08-15 23:53:09.251267",
        "air_temp": 20.67551811597449,
        "humidity": 64.9447832267971,
        "rainfall": 1,
        "wind_speed": 9.438050211290461,
        "soil_moisture": 26.884907116438086,
        "ndvi": 0.30703895115714863,
        "soil_temp": 19.926477320910937,
        "soil_humidity": 35.27083554388144
    },
    {
        "date": "2024-08-16 00:53:09.251267",
        "air_temp": 22.2616459279727,
        "humidity": 40.60515583880537,
        "rainfall": 1,
        "wind_speed": 9.806249788196567,
        "soil_moisture": 19.401030833718345,
        "ndvi": 0.5078416537180629,
        "soil_temp": 20.817037803985652,
        "soil_humidity": 45.31868044257253
    },
    {
        "date": "2024-08-16 01:53:09.251267",
        "air_temp": 27.918344826632023,
        "humidity": 40.45192345396461,
        "rainfall": 0.5,
        "wind_speed": 16.93570003739491,
        "soil_moisture": 18.853265805528558,
        "ndvi": 0.688410041311958,
        "soil_temp": 13.828468253778482,
        "soil_humidity": 38.145945644931096
    },
    {
        "date": "2024-08-16 02:53:09.251267",
        "air_temp": 26.41556609337824,
        "humidity": 57.85146719850061,
        "rainfall": 0,
        "wind_speed": 16.63883083376104,
        "soil_moisture": 32.002055480047176,
        "ndvi": 0.666116196138969,
        "soil_temp": 11.33874627454876,
        "soil_humidity": 55.75991814521894
    },
    {
        "date": "2024-08-16 03:53:09.251267",
        "air_temp": 22.121934517956923,
        "humidity": 86.30970342158909,
        "rainfall": 2,
        "wind_speed": 8.070399848804223,
        "soil_moisture": 36.13830964363471,
        "ndvi": 0.6731490590650826,
        "soil_temp": 11.180254854128467,
        "soil_humidity": 69.78020416882235
    },
    {
        "date": "2024-08-16 04:53:09.251267",
        "air_temp": 34.730304975859596,
        "humidity": 51.43383898018044,
        "rainfall": 1,
        "wind_speed": 6.6659971974105,
        "soil_moisture": 15.777448973755837,
        "ndvi": 0.6248142703987472,
        "soil_temp": 16.392273226760857,
        "soil_humidity": 30.480366523370567
    },
    {
        "date": "2024-08-16 05:53:09.251267",
        "air_temp": 27.115496387137743,
        "humidity": 71.71818444358436,
        "rainfall": 1,
        "wind_speed": 9.838560480782586,
        "soil_moisture": 12.92856281605447,
        "ndvi": 0.352530697440013,
        "soil_temp": 24.187666135940724,
        "soil_humidity": 51.63679253149195
    },
    {
        "date": "2024-08-16 06:53:09.251267",
        "air_temp": 19.74453583471989,
        "humidity": 51.103793534666025,
        "rainfall": 0,
        "wind_speed": 11.45545019474218,
        "soil_moisture": 36.93387419176078,
        "ndvi": 0.7172504515041389,
        "soil_temp": 13.265935513705369,
        "soil_humidity": 52.94666390196039
    },
    {
        "date": "2024-08-16 07:53:09.251267",
        "air_temp": 17.035649452408073,
        "humidity": 56.08349948297743,
        "rainfall": 1,
        "wind_speed": 4.766744212559939,
        "soil_moisture": 20.49686390942196,
        "ndvi": 0.7360660792257592,
        "soil_temp": 13.85112402012324,
        "soil_humidity": 66.56835158723248
    },
    {
        "date": "2024-08-16 08:53:09.251267",
        "air_temp": 18.057182783686642,
        "humidity": 82.4021044800742,
        "rainfall": 2,
        "wind_speed": 15.879101694311812,
        "soil_moisture": 26.539521719884426,
        "ndvi": 0.31649161797112946,
        "soil_temp": 11.513332031587884,
        "soil_humidity": 50.2402133906775
    },
    {
        "date": "2024-08-16 09:53:09.251267",
        "air_temp": 19.919154567690164,
        "humidity": 76.44307211349735,
        "rainfall": 0.5,
        "wind_speed": 9.729655314648458,
        "soil_moisture": 20.786294335600104,
        "ndvi": 0.2782733819308364,
        "soil_temp": 19.083554108408954,
        "soil_humidity": 51.7480567857967
    },
    {
        "date": "2024-08-16 10:53:09.251267",
        "air_temp": 18.213627465191113,
        "humidity": 44.76996386566053,
        "rainfall": 1,
        "wind_speed": 6.680910968399192,
        "soil_moisture": 13.774070090381539,
        "ndvi": 0.7853629811534673,
        "soil_temp": 20.198093109207623,
        "soil_humidity": 48.866112053135545
    },
    {
        "date": "2024-08-16 11:53:09.251267",
        "air_temp": 18.731340481026116,
        "humidity": 61.43510104932168,
        "rainfall": 2,
        "wind_speed": 2.1364198592798123,
        "soil_moisture": 27.67994934843184,
        "ndvi": 0.7209943781902208,
        "soil_temp": 19.6439681224114,
        "soil_humidity": 40.75617218245925
    },
    {
        "date": "2024-08-16 12:53:09.251267",
        "air_temp": 20.70190337387694,
        "humidity": 41.458488182263224,
        "rainfall": 1,
        "wind_speed": 4.776903208072138,
        "soil_moisture": 25.75422555308889,
        "ndvi": 0.7359416883259682,
        "soil_temp": 22.916953645671718,
        "soil_humidity": 32.241165329956544
    },
    {
        "date": "2024-08-16 13:53:09.251267",
        "air_temp": 18.467471905895096,
        "humidity": 64.04450733015045,
        "rainfall": 0,
        "wind_speed": 18.985623435119674,
        "soil_moisture": 16.089806775735916,
        "ndvi": 0.7318258307746373,
        "soil_temp": 17.296387525391076,
        "soil_humidity": 46.34630728839345
    },
    {
        "date": "2024-08-16 14:53:09.251267",
        "air_temp": 32.935308492528506,
        "humidity": 73.1217005850534,
        "rainfall": 1,
        "wind_speed": 6.101993736390532,
        "soil_moisture": 23.556071339568334,
        "ndvi": 0.525012094420237,
        "soil_temp": 12.016855611505967,
        "soil_humidity": 32.10365884962714
    },
    {
        "date": "2024-08-16 15:53:09.251267",
        "air_temp": 16.604674913232845,
        "humidity": 45.92513117990758,
        "rainfall": 1,
        "wind_speed": 3.3771857873407063,
        "soil_moisture": 33.908425120469374,
        "ndvi": 0.58784104199948,
        "soil_temp": 14.34107696056619,
        "soil_humidity": 52.01969877863671
    },
    {
        "date": "2024-08-16 16:53:09.251267",
        "air_temp": 25.490227791405093,
        "humidity": 54.44424694545828,
        "rainfall": 0.5,
        "wind_speed": 9.213455833099864,
        "soil_moisture": 27.71278309323674,
        "ndvi": 0.527217711280185,
        "soil_temp": 19.936449601320277,
        "soil_humidity": 42.291745729691726
    },
    {
        "date": "2024-08-16 17:53:09.251267",
        "air_temp": 23.20793653979323,
        "humidity": 59.89183127510785,
        "rainfall": 0,
        "wind_speed": 6.141754867204973,
        "soil_moisture": 12.907519812009845,
        "ndvi": 0.23072380951419746,
        "soil_temp": 19.196829606123398,
        "soil_humidity": 51.34409606101711
    },
    {
        "date": "2024-08-16 18:53:09.251267",
        "air_temp": 34.647572338172125,
        "humidity": 85.97655123280248,
        "rainfall": 0,
        "wind_speed": 0.7031850054238831,
        "soil_moisture": 26.440644916874053,
        "ndvi": 0.6473018512116637,
        "soil_temp": 17.965046946144437,
        "soil_humidity": 35.371878575807465
    },
    {
        "date": "2024-08-16 19:53:09.251267",
        "air_temp": 17.240778043361047,
        "humidity": 89.66275077114821,
        "rainfall": 0.5,
        "wind_speed": 6.4629043455883455,
        "soil_moisture": 31.082148978687606,
        "ndvi": 0.5031992200015283,
        "soil_temp": 20.244466280330307,
        "soil_humidity": 67.30519862957439
    },
    {
        "date": "2024-08-16 20:53:09.251267",
        "air_temp": 22.95711198091483,
        "humidity": 42.24556494978079,
        "rainfall": 1,
        "wind_speed": 10.882172478789371,
        "soil_moisture": 33.78110217342254,
        "ndvi": 0.5886254448741379,
        "soil_temp": 19.56086275559949,
        "soil_humidity": 65.7808497419974
    },
    {
        "date": "2024-08-16 21:53:09.251267",
        "air_temp": 34.389408665507375,
        "humidity": 78.0503961747696,
        "rainfall": 2,
        "wind_speed": 11.034247440399339,
        "soil_moisture": 28.873527359028003,
        "ndvi": 0.5015838829853212,
        "soil_temp": 23.219873341974274,
        "soil_humidity": 37.59986036809138
    },
    {
        "date": "2024-08-16 22:53:09.251267",
        "air_temp": 32.310142517879605,
        "humidity": 58.586203320117164,
        "rainfall": 0,
        "wind_speed": 7.131178423537774,
        "soil_moisture": 14.579744936223513,
        "ndvi": 0.4950867933934736,
        "soil_temp": 18.79024566217445,
        "soil_humidity": 43.7377713317604
    },
    {
        "date": "2024-08-16 23:53:09.251267",
        "air_temp": 31.3414414189856,
        "humidity": 59.622909311474004,
        "rainfall": 0,
        "wind_speed": 8.946055125052013,
        "soil_moisture": 31.30519799770482,
        "ndvi": 0.3030734656866601,
        "soil_temp": 21.679020380508348,
        "soil_humidity": 54.539710874079184
    },
    {
        "date": "2024-08-17 00:53:09.251267",
        "air_temp": 20.158056540898798,
        "humidity": 77.71324866735114,
        "rainfall": 1,
        "wind_speed": 19.31035836469533,
        "soil_moisture": 30.32025538244549,
        "ndvi": 0.36722822908468944,
        "soil_temp": 13.730607339497018,
        "soil_humidity": 33.82744829223625
    },
    {
        "date": "2024-08-17 01:53:09.251267",
        "air_temp": 18.417751747801315,
        "humidity": 85.92132160727044,
        "rainfall": 1,
        "wind_speed": 7.546969544606233,
        "soil_moisture": 10.732806488218996,
        "ndvi": 0.2953116151663735,
        "soil_temp": 20.17273876883602,
        "soil_humidity": 44.18271284464603
    },
    {
        "date": "2024-08-17 02:53:09.251267",
        "air_temp": 28.37286439848862,
        "humidity": 87.54642972512741,
        "rainfall": 0,
        "wind_speed": 5.16453626519966,
        "soil_moisture": 22.352430027707296,
        "ndvi": 0.31739977737520547,
        "soil_temp": 23.532911775740136,
        "soil_humidity": 40.04449147276024
    },
    {
        "date": "2024-08-17 03:53:09.251267",
        "air_temp": 33.587519782551716,
        "humidity": 68.85612930191604,
        "rainfall": 1,
        "wind_speed": 4.869340881335202,
        "soil_moisture": 19.9885074088712,
        "ndvi": 0.7837864282521831,
        "soil_temp": 15.785984918497498,
        "soil_humidity": 66.62066914866787
    },
    {
        "date": "2024-08-17 04:53:09.251267",
        "air_temp": 26.135257860278596,
        "humidity": 57.856057965025144,
        "rainfall": 2,
        "wind_speed": 9.155292254082683,
        "soil_moisture": 30.082361927609302,
        "ndvi": 0.29186806746331906,
        "soil_temp": 21.6865628100797,
        "soil_humidity": 64.10042545156821
    },
    {
        "date": "2024-08-17 05:53:09.251267",
        "air_temp": 26.432253789397997,
        "humidity": 79.37745429293896,
        "rainfall": 0,
        "wind_speed": 15.19040297433888,
        "soil_moisture": 16.484074369327622,
        "ndvi": 0.36156225458312463,
        "soil_temp": 20.87183951793153,
        "soil_humidity": 53.19330965067455
    },
    {
        "date": "2024-08-17 06:53:09.251267",
        "air_temp": 20.599581873205683,
        "humidity": 52.55006916311501,
        "rainfall": 2,
        "wind_speed": 4.119763683769464,
        "soil_moisture": 27.726157689227755,
        "ndvi": 0.554353794643151,
        "soil_temp": 10.892892953077338,
        "soil_humidity": 50.81385347486297
    },
    {
        "date": "2024-08-17 07:53:09.251267",
        "air_temp": 30.389858663838737,
        "humidity": 68.20370144167589,
        "rainfall": 2,
        "wind_speed": 14.185922542815362,
        "soil_moisture": 37.39617885442517,
        "ndvi": 0.5661071457419852,
        "soil_temp": 18.722624644361083,
        "soil_humidity": 67.22903178181588
    },
    {
        "date": "2024-08-17 08:53:09.251267",
        "air_temp": 18.74087497115047,
        "humidity": 57.92892307624366,
        "rainfall": 1,
        "wind_speed": 0.4229964669883257,
        "soil_moisture": 33.934071166258235,
        "ndvi": 0.29238447784629745,
        "soil_temp": 11.952840238012035,
        "soil_humidity": 67.2971113191526
    },
    {
        "date": "2024-08-17 09:53:09.251267",
        "air_temp": 21.473584728084873,
        "humidity": 72.8315536853938,
        "rainfall": 2,
        "wind_speed": 8.37865955967892,
        "soil_moisture": 37.643732086495916,
        "ndvi": 0.6762304554711275,
        "soil_temp": 24.509787583458866,
        "soil_humidity": 68.77320501406642
    },
    {
        "date": "2024-08-17 10:53:09.251267",
        "air_temp": 23.508728772328336,
        "humidity": 52.019945644675346,
        "rainfall": 1,
        "wind_speed": 15.517301304055737,
        "soil_moisture": 39.355179007980695,
        "ndvi": 0.6711217207476952,
        "soil_temp": 22.171112540509842,
        "soil_humidity": 35.015155364327505
    },
    {
        "date": "2024-08-17 11:53:09.251267",
        "air_temp": 25.1522075736891,
        "humidity": 49.57964728674129,
        "rainfall": 0.5,
        "wind_speed": 6.774659115488628,
        "soil_moisture": 10.320103810184882,
        "ndvi": 0.34239062252233465,
        "soil_temp": 11.645857018706803,
        "soil_humidity": 36.42231243052681
    },
    {
        "date": "2024-08-17 12:53:09.251267",
        "air_temp": 19.848194648301604,
        "humidity": 85.91195433377186,
        "rainfall": 1,
        "wind_speed": 11.274742028907989,
        "soil_moisture": 28.682238291324328,
        "ndvi": 0.37319978160527634,
        "soil_temp": 11.826279964715177,
        "soil_humidity": 57.06946794719546
    },
    {
        "date": "2024-08-17 13:53:09.251267",
        "air_temp": 17.29673649478407,
        "humidity": 45.09018414747685,
        "rainfall": 2,
        "wind_speed": 4.099584656518216,
        "soil_moisture": 22.385181932767193,
        "ndvi": 0.7627927192756982,
        "soil_temp": 18.917107823689285,
        "soil_humidity": 51.657379064393794
    },
    {
        "date": "2024-08-17 14:53:09.251267",
        "air_temp": 27.21240084883265,
        "humidity": 65.29789538674657,
        "rainfall": 0.5,
        "wind_speed": 14.839907822523447,
        "soil_moisture": 35.79563471005365,
        "ndvi": 0.6954028675876869,
        "soil_temp": 15.173337612597749,
        "soil_humidity": 30.738636195729438
    },
    {
        "date": "2024-08-17 15:53:09.251267",
        "air_temp": 20.772611064805115,
        "humidity": 51.04263914815044,
        "rainfall": 1,
        "wind_speed": 1.0455820653751924,
        "soil_moisture": 17.554441060273952,
        "ndvi": 0.6186782198375144,
        "soil_temp": 10.523587886096639,
        "soil_humidity": 32.47900143958078
    },
    {
        "date": "2024-08-17 16:53:09.251267",
        "air_temp": 26.624764428452245,
        "humidity": 41.94649816628337,
        "rainfall": 2,
        "wind_speed": 17.05085289751001,
        "soil_moisture": 39.43088467345228,
        "ndvi": 0.24933993290636033,
        "soil_temp": 18.124622617495795,
        "soil_humidity": 65.5232152903522
    },
    {
        "date": "2024-08-17 17:53:09.251267",
        "air_temp": 18.087254305484045,
        "humidity": 41.80094091457132,
        "rainfall": 0,
        "wind_speed": 3.963927728433363,
        "soil_moisture": 28.41123908631242,
        "ndvi": 0.4509584485415455,
        "soil_temp": 15.817676495287703,
        "soil_humidity": 64.75483182157545
    },
    {
        "date": "2024-08-17 18:53:09.251267",
        "air_temp": 24.62280203709635,
        "humidity": 48.76108860529136,
        "rainfall": 0,
        "wind_speed": 17.487960634166615,
        "soil_moisture": 33.80612206686493,
        "ndvi": 0.5355811232460963,
        "soil_temp": 11.51614738557614,
        "soil_humidity": 44.290836879841805
    },
    {
        "date": "2024-08-17 19:53:09.251267",
        "air_temp": 25.651788651031715,
        "humidity": 83.33851772530002,
        "rainfall": 1,
        "wind_speed": 7.050584826010347,
        "soil_moisture": 12.56798658468305,
        "ndvi": 0.31244677279026617,
        "soil_temp": 22.028805883839738,
        "soil_humidity": 65.64202467722139
    },
    {
        "date": "2024-08-17 20:53:09.251267",
        "air_temp": 16.03647073644854,
        "humidity": 54.12393719885134,
        "rainfall": 1,
        "wind_speed": 14.453671489489732,
        "soil_moisture": 15.503736542765951,
        "ndvi": 0.46893594926493476,
        "soil_temp": 23.72615147918209,
        "soil_humidity": 53.747002975916075
    },
    {
        "date": "2024-08-17 21:53:09.251267",
        "air_temp": 21.732085563878414,
        "humidity": 87.52295852876479,
        "rainfall": 0,
        "wind_speed": 6.384505955723769,
        "soil_moisture": 32.75211019102609,
        "ndvi": 0.560615526493214,
        "soil_temp": 21.348909010211948,
        "soil_humidity": 62.23622588075607
    },
    {
        "date": "2024-08-17 22:53:09.251267",
        "air_temp": 17.688293538779483,
        "humidity": 69.08111464945968,
        "rainfall": 2,
        "wind_speed": 18.118233424692953,
        "soil_moisture": 10.659595661179708,
        "ndvi": 0.39642600806923955,
        "soil_temp": 22.33633465024416,
        "soil_humidity": 47.09724284640857
    },
    {
        "date": "2024-08-17 23:53:09.251267",
        "air_temp": 16.267499409455354,
        "humidity": 61.8307094884383,
        "rainfall": 2,
        "wind_speed": 2.532742510367487,
        "soil_moisture": 34.50633957681349,
        "ndvi": 0.6685668913702925,
        "soil_temp": 13.247358508991761,
        "soil_humidity": 34.53593309379635
    },
    {
        "date": "2024-08-18 00:53:09.251267",
        "air_temp": 34.7992046477989,
        "humidity": 69.00444173347273,
        "rainfall": 2,
        "wind_speed": 3.570395785451974,
        "soil_moisture": 10.418470560757344,
        "ndvi": 0.7042697489832834,
        "soil_temp": 13.953594263779015,
        "soil_humidity": 47.34908250584065
    },
    {
        "date": "2024-08-18 01:53:09.251267",
        "air_temp": 21.44707689949446,
        "humidity": 65.83491926131339,
        "rainfall": 1,
        "wind_speed": 9.860602503176047,
        "soil_moisture": 29.7135651718382,
        "ndvi": 0.3941656816604425,
        "soil_temp": 13.127913104487817,
        "soil_humidity": 48.29199191987591
    },
    {
        "date": "2024-08-18 02:53:09.251267",
        "air_temp": 31.1974889170927,
        "humidity": 77.93881268786626,
        "rainfall": 0.5,
        "wind_speed": 1.6387687807629225,
        "soil_moisture": 20.952817606954177,
        "ndvi": 0.7031942926954744,
        "soil_temp": 21.47595147048346,
        "soil_humidity": 52.037767753624124
    },
    {
        "date": "2024-08-18 03:53:09.251267",
        "air_temp": 20.092813095275275,
        "humidity": 54.12480329864261,
        "rainfall": 0.5,
        "wind_speed": 3.9196036940352386,
        "soil_moisture": 28.344845581575974,
        "ndvi": 0.26214593966180905,
        "soil_temp": 24.218756184017458,
        "soil_humidity": 34.22330851617712
    },
    {
        "date": "2024-08-18 04:53:09.251267",
        "air_temp": 28.630054444478585,
        "humidity": 57.652519580015124,
        "rainfall": 0.5,
        "wind_speed": 18.875327644240286,
        "soil_moisture": 30.88094220967341,
        "ndvi": 0.7026374591735938,
        "soil_temp": 20.75255810100854,
        "soil_humidity": 69.65607452198446
    },
    {
        "date": "2024-08-18 05:53:09.251267",
        "air_temp": 30.204557197793733,
        "humidity": 84.70471512666253,
        "rainfall": 0.5,
        "wind_speed": 19.529793222957743,
        "soil_moisture": 20.274256575589128,
        "ndvi": 0.29165025900056996,
        "soil_temp": 23.222001473193338,
        "soil_humidity": 39.76547283227625
    },
    {
        "date": "2024-08-18 06:53:09.251267",
        "air_temp": 26.912774812156886,
        "humidity": 87.32282565752027,
        "rainfall": 0.5,
        "wind_speed": 7.525186586608439,
        "soil_moisture": 13.238873443325065,
        "ndvi": 0.24975629150583564,
        "soil_temp": 19.58048642324759,
        "soil_humidity": 37.94209614148372
    },
    {
        "date": "2024-08-18 07:53:09.251267",
        "air_temp": 24.431523771003167,
        "humidity": 84.62791137432373,
        "rainfall": 1,
        "wind_speed": 18.465720420278714,
        "soil_moisture": 12.08937603244797,
        "ndvi": 0.5850652426646068,
        "soil_temp": 15.814898508381987,
        "soil_humidity": 51.07211675423746
    },
    {
        "date": "2024-08-18 08:53:09.251267",
        "air_temp": 23.23681828294537,
        "humidity": 60.97239969786568,
        "rainfall": 1,
        "wind_speed": 10.896493513468634,
        "soil_moisture": 27.668080676876436,
        "ndvi": 0.4831624194531767,
        "soil_temp": 16.386424223954577,
        "soil_humidity": 51.48832717579555
    },
    {
        "date": "2024-08-18 09:53:09.251267",
        "air_temp": 21.977365330859904,
        "humidity": 79.01827515034408,
        "rainfall": 0.5,
        "wind_speed": 5.394849317475341,
        "soil_moisture": 25.619660612023345,
        "ndvi": 0.7447343602476997,
        "soil_temp": 11.129821482511073,
        "soil_humidity": 47.592829513329775
    },
    {
        "date": "2024-08-18 10:53:09.251267",
        "air_temp": 33.59058288495652,
        "humidity": 63.81678666505494,
        "rainfall": 1,
        "wind_speed": 14.047974421611972,
        "soil_moisture": 32.26603027363397,
        "ndvi": 0.41791351765450957,
        "soil_temp": 10.47185792869039,
        "soil_humidity": 60.87568585301018
    },
    {
        "date": "2024-08-18 11:53:09.251267",
        "air_temp": 31.612388155754584,
        "humidity": 64.87699260730511,
        "rainfall": 1,
        "wind_speed": 13.178036131497144,
        "soil_moisture": 25.292065300118114,
        "ndvi": 0.37229135806257724,
        "soil_temp": 20.432134062836163,
        "soil_humidity": 50.66617602914411
    },
    {
        "date": "2024-08-18 12:53:09.251267",
        "air_temp": 34.300538213330256,
        "humidity": 50.234005452864196,
        "rainfall": 0.5,
        "wind_speed": 19.782506382174667,
        "soil_moisture": 22.673373700926373,
        "ndvi": 0.5004409436865191,
        "soil_temp": 16.57643338016771,
        "soil_humidity": 54.38781820579936
    },
    {
        "date": "2024-08-18 13:53:09.251267",
        "air_temp": 17.485944469710894,
        "humidity": 69.55651516292582,
        "rainfall": 2,
        "wind_speed": 5.300945748185666,
        "soil_moisture": 21.942615347086928,
        "ndvi": 0.47356771146119686,
        "soil_temp": 18.28322602081944,
        "soil_humidity": 58.94985541631142
    },
    {
        "date": "2024-08-18 14:53:09.251267",
        "air_temp": 29.617349504072884,
        "humidity": 49.306794887667394,
        "rainfall": 1,
        "wind_speed": 13.441190053234708,
        "soil_moisture": 10.793407077930024,
        "ndvi": 0.406656827730313,
        "soil_temp": 18.364918714336245,
        "soil_humidity": 31.001004062554664
    },
    {
        "date": "2024-08-18 15:53:09.251267",
        "air_temp": 33.76680913642076,
        "humidity": 56.57823495335969,
        "rainfall": 2,
        "wind_speed": 1.0404751005889379,
        "soil_moisture": 20.066716050899714,
        "ndvi": 0.6162447792189972,
        "soil_temp": 14.16063719999952,
        "soil_humidity": 52.82576730180179
    },
    {
        "date": "2024-08-18 16:53:09.251267",
        "air_temp": 18.6246613233132,
        "humidity": 82.75151008099675,
        "rainfall": 1,
        "wind_speed": 2.272920773934388,
        "soil_moisture": 39.280604725734825,
        "ndvi": 0.655737100348345,
        "soil_temp": 17.138387662442362,
        "soil_humidity": 65.36777884913477
    },
    {
        "date": "2024-08-18 17:53:09.251267",
        "air_temp": 16.32992534733555,
        "humidity": 50.35379046054276,
        "rainfall": 1,
        "wind_speed": 0.07836749902186524,
        "soil_moisture": 36.20666728681266,
        "ndvi": 0.7117903814180822,
        "soil_temp": 17.061403325015533,
        "soil_humidity": 30.695549717017357
    },
    {
        "date": "2024-08-18 18:53:09.251267",
        "air_temp": 29.82241298580118,
        "humidity": 43.557914258671644,
        "rainfall": 0.5,
        "wind_speed": 19.989213621193464,
        "soil_moisture": 19.881342011026533,
        "ndvi": 0.7837139628262058,
        "soil_temp": 15.826408318417855,
        "soil_humidity": 46.20728415203844
    },
    {
        "date": "2024-08-18 19:53:09.251267",
        "air_temp": 26.489462263598238,
        "humidity": 43.45037963064082,
        "rainfall": 1,
        "wind_speed": 16.045473942949556,
        "soil_moisture": 27.633862151991167,
        "ndvi": 0.35999371527950275,
        "soil_temp": 22.310130751016057,
        "soil_humidity": 67.0490980082332
    },
    {
        "date": "2024-08-18 20:53:09.251267",
        "air_temp": 31.83657553516544,
        "humidity": 87.03922602515539,
        "rainfall": 0,
        "wind_speed": 15.734460294670816,
        "soil_moisture": 35.41890236606481,
        "ndvi": 0.629656089493977,
        "soil_temp": 16.76746005531394,
        "soil_humidity": 44.12617609130152
    },
    {
        "date": "2024-08-18 21:53:09.251267",
        "air_temp": 17.79544753252579,
        "humidity": 65.34602189809173,
        "rainfall": 0,
        "wind_speed": 10.194521505763595,
        "soil_moisture": 23.238230102502058,
        "ndvi": 0.4365549412974472,
        "soil_temp": 19.054667867589817,
        "soil_humidity": 48.34915296800963
    },
    {
        "date": "2024-08-18 22:53:09.251267",
        "air_temp": 30.905346237197804,
        "humidity": 60.470603590850914,
        "rainfall": 0.5,
        "wind_speed": 6.001341658108799,
        "soil_moisture": 10.538735912504528,
        "ndvi": 0.6062491831706649,
        "soil_temp": 23.43280180571239,
        "soil_humidity": 69.26165572177251
    },
    {
        "date": "2024-08-18 23:53:09.251267",
        "air_temp": 19.032546400954892,
        "humidity": 80.54392905039592,
        "rainfall": 2,
        "wind_speed": 3.0252856892535185,
        "soil_moisture": 12.235313854559202,
        "ndvi": 0.48558901420953227,
        "soil_temp": 22.99350434288384,
        "soil_humidity": 37.97295435802295
    },
    {
        "date": "2024-08-19 00:53:09.251267",
        "air_temp": 18.27311885731409,
        "humidity": 81.79129639552579,
        "rainfall": 2,
        "wind_speed": 5.693494776929273,
        "soil_moisture": 25.3117816404612,
        "ndvi": 0.7962638616607591,
        "soil_temp": 23.356333056687475,
        "soil_humidity": 32.61020756500024
    },
    {
        "date": "2024-08-19 01:53:09.251267",
        "air_temp": 18.285315958619858,
        "humidity": 56.60955165578023,
        "rainfall": 2,
        "wind_speed": 7.519424438582782,
        "soil_moisture": 39.08318127027635,
        "ndvi": 0.63734128147445,
        "soil_temp": 14.05007494697521,
        "soil_humidity": 64.37690363445218
    },
    {
        "date": "2024-08-19 02:53:09.251267",
        "air_temp": 31.29149440462764,
        "humidity": 74.68090287368976,
        "rainfall": 0,
        "wind_speed": 15.126201939605881,
        "soil_moisture": 30.79628626656603,
        "ndvi": 0.7714947802605947,
        "soil_temp": 10.524737324620942,
        "soil_humidity": 64.73861364849475
    },
    {
        "date": "2024-08-19 03:53:09.251267",
        "air_temp": 28.303944413924,
        "humidity": 78.55614079259433,
        "rainfall": 2,
        "wind_speed": 10.793235778063048,
        "soil_moisture": 12.127485743157086,
        "ndvi": 0.7009817296887115,
        "soil_temp": 13.916001998429937,
        "soil_humidity": 48.883113387200524
    },
    {
        "date": "2024-08-19 04:53:09.251267",
        "air_temp": 25.461308495382386,
        "humidity": 72.73253434329773,
        "rainfall": 2,
        "wind_speed": 1.5496725311096538,
        "soil_moisture": 15.680826273755883,
        "ndvi": 0.7417406066980228,
        "soil_temp": 14.18061018431063,
        "soil_humidity": 50.568286979091454
    },
    {
        "date": "2024-08-19 05:53:09.251267",
        "air_temp": 22.17660968247005,
        "humidity": 47.57975374000497,
        "rainfall": 0.5,
        "wind_speed": 0.3605801791782448,
        "soil_moisture": 33.139694296505496,
        "ndvi": 0.6886515183288051,
        "soil_temp": 23.640404900874522,
        "soil_humidity": 63.43225478471344
    },
    {
        "date": "2024-08-19 06:53:09.251267",
        "air_temp": 32.544010816262166,
        "humidity": 83.79415417585285,
        "rainfall": 2,
        "wind_speed": 2.046643590681263,
        "soil_moisture": 35.243192798687346,
        "ndvi": 0.4754954782255469,
        "soil_temp": 19.738640992569557,
        "soil_humidity": 59.5423799246318
    },
    {
        "date": "2024-08-19 07:53:09.251267",
        "air_temp": 22.84890214845271,
        "humidity": 66.95472763680044,
        "rainfall": 0,
        "wind_speed": 8.209974273947587,
        "soil_moisture": 28.530837585657725,
        "ndvi": 0.35107307200813065,
        "soil_temp": 11.225559394033432,
        "soil_humidity": 40.605960088680426
    },
    {
        "date": "2024-08-19 08:53:09.251267",
        "air_temp": 31.33198878943154,
        "humidity": 54.123621318500184,
        "rainfall": 0,
        "wind_speed": 18.663858340980365,
        "soil_moisture": 29.332695088894496,
        "ndvi": 0.42219210527031337,
        "soil_temp": 12.030678940166517,
        "soil_humidity": 30.67573420286074
    },
    {
        "date": "2024-08-19 09:53:09.251267",
        "air_temp": 23.78269817140437,
        "humidity": 61.261409271884695,
        "rainfall": 0,
        "wind_speed": 12.748049624184432,
        "soil_moisture": 10.85012351150154,
        "ndvi": 0.21214173958397028,
        "soil_temp": 12.33514188312963,
        "soil_humidity": 49.18800650134183
    },
    {
        "date": "2024-08-19 10:53:09.251267",
        "air_temp": 22.538888588498153,
        "humidity": 41.87855451994537,
        "rainfall": 0.5,
        "wind_speed": 16.036517997510302,
        "soil_moisture": 34.855907787445254,
        "ndvi": 0.5143959004788003,
        "soil_temp": 19.456326222533846,
        "soil_humidity": 64.35649304382565
    },
    {
        "date": "2024-08-19 11:53:09.251267",
        "air_temp": 24.25359571339213,
        "humidity": 46.39334006112088,
        "rainfall": 0.5,
        "wind_speed": 9.327491890090972,
        "soil_moisture": 33.648256245124884,
        "ndvi": 0.5030346801622453,
        "soil_temp": 21.031210382605046,
        "soil_humidity": 65.83338706760648
    },
    {
        "date": "2024-08-19 12:53:09.251267",
        "air_temp": 21.02755748328284,
        "humidity": 78.27733752579304,
        "rainfall": 1,
        "wind_speed": 7.233321663900856,
        "soil_moisture": 17.771054323100415,
        "ndvi": 0.5151502788982929,
        "soil_temp": 22.894658658480942,
        "soil_humidity": 68.24904788304616
    },
    {
        "date": "2024-08-19 13:53:09.251267",
        "air_temp": 29.952187603525022,
        "humidity": 40.00058173776831,
        "rainfall": 2,
        "wind_speed": 2.1680074543324546,
        "soil_moisture": 31.26030159896521,
        "ndvi": 0.7844187349832383,
        "soil_temp": 24.109548262093647,
        "soil_humidity": 65.18252950451125
    },
    {
        "date": "2024-08-19 14:53:09.251267",
        "air_temp": 25.054407801849585,
        "humidity": 60.82829142411461,
        "rainfall": 0.5,
        "wind_speed": 17.518660443724098,
        "soil_moisture": 16.445638192749943,
        "ndvi": 0.7468724020729036,
        "soil_temp": 13.025568360796626,
        "soil_humidity": 53.201742211923936
    },
    {
        "date": "2024-08-19 15:53:09.251267",
        "air_temp": 19.644253902936345,
        "humidity": 66.12550905427942,
        "rainfall": 0,
        "wind_speed": 15.663200580558057,
        "soil_moisture": 11.20990483154539,
        "ndvi": 0.2970276669980648,
        "soil_temp": 14.646587270860735,
        "soil_humidity": 47.784223969444035
    },
    {
        "date": "2024-08-19 16:53:09.251267",
        "air_temp": 32.991491465491364,
        "humidity": 42.731721000048616,
        "rainfall": 0,
        "wind_speed": 3.3623619519545023,
        "soil_moisture": 16.03409627771132,
        "ndvi": 0.42132951293353355,
        "soil_temp": 13.60484321117246,
        "soil_humidity": 60.99399386136396
    },
    {
        "date": "2024-08-19 17:53:09.251267",
        "air_temp": 22.67782442746423,
        "humidity": 88.65390814861664,
        "rainfall": 0.5,
        "wind_speed": 10.245211277525211,
        "soil_moisture": 15.358866302628986,
        "ndvi": 0.4543737076779572,
        "soil_temp": 15.381423511992066,
        "soil_humidity": 59.592865374623855
    },
    {
        "date": "2024-08-19 18:53:09.251267",
        "air_temp": 25.871057222279774,
        "humidity": 51.3062665624836,
        "rainfall": 0.5,
        "wind_speed": 9.747496158424898,
        "soil_moisture": 20.13243990204542,
        "ndvi": 0.4163197507109887,
        "soil_temp": 23.71997661135624,
        "soil_humidity": 51.525737203258174
    },
    {
        "date": "2024-08-19 19:53:09.251267",
        "air_temp": 33.12944221929094,
        "humidity": 55.20993598181404,
        "rainfall": 0.5,
        "wind_speed": 12.501831053730573,
        "soil_moisture": 22.553484915263745,
        "ndvi": 0.6790975781623478,
        "soil_temp": 18.626688504090424,
        "soil_humidity": 33.49309490233877
    },
    {
        "date": "2024-08-19 20:53:09.251267",
        "air_temp": 27.484759918279842,
        "humidity": 55.197125612751364,
        "rainfall": 1,
        "wind_speed": 18.923737879825225,
        "soil_moisture": 25.256166744931235,
        "ndvi": 0.5144226019205522,
        "soil_temp": 21.072878459867486,
        "soil_humidity": 67.04325387147675
    },
    {
        "date": "2024-08-19 21:53:09.251267",
        "air_temp": 17.337960814167282,
        "humidity": 51.520833105867496,
        "rainfall": 0,
        "wind_speed": 11.595428835249592,
        "soil_moisture": 24.302616265249846,
        "ndvi": 0.543259890182975,
        "soil_temp": 17.35178854190173,
        "soil_humidity": 48.22410150006648
    },
    {
        "date": "2024-08-19 22:53:09.251267",
        "air_temp": 33.796642472269504,
        "humidity": 40.07369110431557,
        "rainfall": 1,
        "wind_speed": 10.804345335572371,
        "soil_moisture": 18.12023817895868,
        "ndvi": 0.7118081353927554,
        "soil_temp": 10.432868700041867,
        "soil_humidity": 44.53335127665057
    },
    {
        "date": "2024-08-19 23:53:09.251267",
        "air_temp": 27.554161061428356,
        "humidity": 76.46723970439982,
        "rainfall": 1,
        "wind_speed": 1.0064586586932789,
        "soil_moisture": 10.875120057222233,
        "ndvi": 0.6835568920492152,
        "soil_temp": 10.962932289967215,
        "soil_humidity": 55.18269580675087
    },
    {
        "date": "2024-08-20 00:53:09.251267",
        "air_temp": 21.698112293141723,
        "humidity": 88.34227498996427,
        "rainfall": 2,
        "wind_speed": 1.2581495203604787,
        "soil_moisture": 28.153048667114522,
        "ndvi": 0.2793269136921798,
        "soil_temp": 18.068845469018285,
        "soil_humidity": 56.95351844667908
    },
    {
        "date": "2024-08-20 01:53:09.251267",
        "air_temp": 17.785441453267744,
        "humidity": 51.21467416425225,
        "rainfall": 0,
        "wind_speed": 3.7469358265357777,
        "soil_moisture": 21.872344895420632,
        "ndvi": 0.6283487504501806,
        "soil_temp": 20.781835522300383,
        "soil_humidity": 38.427516681212055
    },
    {
        "date": "2024-08-20 02:53:09.251267",
        "air_temp": 30.88050378540592,
        "humidity": 73.15235959017113,
        "rainfall": 0,
        "wind_speed": 0.6494154338349656,
        "soil_moisture": 18.96016472162649,
        "ndvi": 0.46126167734122137,
        "soil_temp": 13.229957912930972,
        "soil_humidity": 63.47755379239825
    },
    {
        "date": "2024-08-20 03:53:09.251267",
        "air_temp": 27.40145511857027,
        "humidity": 77.09481634793576,
        "rainfall": 0.5,
        "wind_speed": 14.867057911400249,
        "soil_moisture": 36.00823128491537,
        "ndvi": 0.37678934183750035,
        "soil_temp": 20.82864360988456,
        "soil_humidity": 41.597483746495115
    },
    {
        "date": "2024-08-20 04:53:09.251267",
        "air_temp": 25.66922183952643,
        "humidity": 82.42126895452623,
        "rainfall": 0.5,
        "wind_speed": 14.466606079879263,
        "soil_moisture": 32.402299233944795,
        "ndvi": 0.2300112925413118,
        "soil_temp": 22.159986602252687,
        "soil_humidity": 37.467357036572665
    },
    {
        "date": "2024-08-20 05:53:09.251267",
        "air_temp": 32.87785166101915,
        "humidity": 61.13145985068046,
        "rainfall": 2,
        "wind_speed": 2.721124688632859,
        "soil_moisture": 35.73296645117327,
        "ndvi": 0.4923497362072706,
        "soil_temp": 16.17846217543167,
        "soil_humidity": 46.2559941141799
    },
    {
        "date": "2024-08-20 06:53:09.251267",
        "air_temp": 30.771944224490614,
        "humidity": 55.146545282021464,
        "rainfall": 1,
        "wind_speed": 16.721578015905617,
        "soil_moisture": 24.84948384137383,
        "ndvi": 0.4132620084347189,
        "soil_temp": 21.196288492661573,
        "soil_humidity": 59.22240323589861
    },
    {
        "date": "2024-08-20 07:53:09.251267",
        "air_temp": 18.033497594655024,
        "humidity": 56.264756701151555,
        "rainfall": 0.5,
        "wind_speed": 3.0957206969222395,
        "soil_moisture": 13.927937563803324,
        "ndvi": 0.3509078238058405,
        "soil_temp": 16.060449254926898,
        "soil_humidity": 68.61832713759213
    },
    {
        "date": "2024-08-20 08:53:09.251267",
        "air_temp": 21.234441355910967,
        "humidity": 75.63106665492563,
        "rainfall": 0.5,
        "wind_speed": 6.0235117399471765,
        "soil_moisture": 30.886643083638173,
        "ndvi": 0.6168030377581746,
        "soil_temp": 20.1752594418253,
        "soil_humidity": 62.37125057485733
    },
    {
        "date": "2024-08-20 09:53:09.251267",
        "air_temp": 19.969782796289316,
        "humidity": 80.8389733252818,
        "rainfall": 0,
        "wind_speed": 4.9795379832273,
        "soil_moisture": 16.18221339811526,
        "ndvi": 0.6616912323893522,
        "soil_temp": 21.459955739793145,
        "soil_humidity": 67.61393510680767
    },
    {
        "date": "2024-08-20 10:53:09.251267",
        "air_temp": 29.878925851453538,
        "humidity": 49.08069838783013,
        "rainfall": 0,
        "wind_speed": 7.446141971460955,
        "soil_moisture": 23.236890072751503,
        "ndvi": 0.3468737114176549,
        "soil_temp": 24.878899274621663,
        "soil_humidity": 35.62423216306107
    },
    {
        "date": "2024-08-20 11:53:09.251267",
        "air_temp": 15.670648694715588,
        "humidity": 58.547050407888264,
        "rainfall": 0.5,
        "wind_speed": 5.093275317799238,
        "soil_moisture": 11.950023668466654,
        "ndvi": 0.22966846200760838,
        "soil_temp": 15.180949930953942,
        "soil_humidity": 32.84707195454707
    },
    {
        "date": "2024-08-20 12:53:09.251267",
        "air_temp": 26.39779369742633,
        "humidity": 85.0970098374575,
        "rainfall": 0,
        "wind_speed": 9.779018528769042,
        "soil_moisture": 34.75925207268948,
        "ndvi": 0.709251620339141,
        "soil_temp": 16.165611708619174,
        "soil_humidity": 52.308190476266844
    },
    {
        "date": "2024-08-20 13:53:09.251267",
        "air_temp": 30.24917371481381,
        "humidity": 80.33467598473696,
        "rainfall": 2,
        "wind_speed": 8.148157627093708,
        "soil_moisture": 13.778219231425018,
        "ndvi": 0.205975844722582,
        "soil_temp": 12.48430089115257,
        "soil_humidity": 41.61189932176137
    },
    {
        "date": "2024-08-20 14:53:09.251267",
        "air_temp": 32.53531273523499,
        "humidity": 89.2429201381238,
        "rainfall": 0.5,
        "wind_speed": 19.73138981558653,
        "soil_moisture": 37.09292521147929,
        "ndvi": 0.7622674963528833,
        "soil_temp": 13.061095876027103,
        "soil_humidity": 63.12219891877091
    },
    {
        "date": "2024-08-20 15:53:09.251267",
        "air_temp": 21.841634974318147,
        "humidity": 77.71241298410403,
        "rainfall": 0,
        "wind_speed": 1.1185270025645133,
        "soil_moisture": 35.926918067846714,
        "ndvi": 0.5765719243896237,
        "soil_temp": 20.400925038975664,
        "soil_humidity": 56.87951485609304
    },
    {
        "date": "2024-08-20 16:53:09.251267",
        "air_temp": 31.425146093440258,
        "humidity": 59.65976005139611,
        "rainfall": 2,
        "wind_speed": 2.8436275277470324,
        "soil_moisture": 23.93748293317738,
        "ndvi": 0.3931079811207922,
        "soil_temp": 23.75568385231714,
        "soil_humidity": 68.82912655943178
    },
    {
        "date": "2024-08-20 17:53:09.251267",
        "air_temp": 17.212634739104146,
        "humidity": 69.53189169973189,
        "rainfall": 0,
        "wind_speed": 1.0565178781720497,
        "soil_moisture": 32.33104612911972,
        "ndvi": 0.3478867457460417,
        "soil_temp": 15.919074274918607,
        "soil_humidity": 40.191694229252164
    },
    {
        "date": "2024-08-20 18:53:09.251267",
        "air_temp": 31.929045834690363,
        "humidity": 73.05071271236602,
        "rainfall": 0,
        "wind_speed": 12.516823899647516,
        "soil_moisture": 28.583352214544597,
        "ndvi": 0.20540339855868517,
        "soil_temp": 12.69766670253312,
        "soil_humidity": 49.4313645742093
    },
    {
        "date": "2024-08-20 19:53:09.251267",
        "air_temp": 17.54977324663965,
        "humidity": 43.922790815856196,
        "rainfall": 0,
        "wind_speed": 1.0901855482743916,
        "soil_moisture": 34.09226927013235,
        "ndvi": 0.3412828502065263,
        "soil_temp": 14.27649249074491,
        "soil_humidity": 50.04961909701413
    },
    {
        "date": "2024-08-20 20:53:09.251267",
        "air_temp": 22.945745811207345,
        "humidity": 67.22484795519325,
        "rainfall": 0.5,
        "wind_speed": 16.728794747522468,
        "soil_moisture": 22.954153023577362,
        "ndvi": 0.28263698091028466,
        "soil_temp": 16.82577557212208,
        "soil_humidity": 39.567071794295046
    },
    {
        "date": "2024-08-20 21:53:09.251267",
        "air_temp": 30.94590731559107,
        "humidity": 75.46604142363049,
        "rainfall": 1,
        "wind_speed": 10.238772613638556,
        "soil_moisture": 33.574254527330496,
        "ndvi": 0.5672997324338874,
        "soil_temp": 14.359754940480526,
        "soil_humidity": 53.60373597952031
    },
    {
        "date": "2024-08-20 22:53:09.251267",
        "air_temp": 17.998348546975475,
        "humidity": 48.366065200053015,
        "rainfall": 0,
        "wind_speed": 10.540152491630675,
        "soil_moisture": 23.87370451195444,
        "ndvi": 0.29992735315628083,
        "soil_temp": 13.503686246827993,
        "soil_humidity": 47.73143944316958
    },
    {
        "date": "2024-08-20 23:53:09.251267",
        "air_temp": 19.58502790465283,
        "humidity": 79.03158587127785,
        "rainfall": 2,
        "wind_speed": 11.231890154243885,
        "soil_moisture": 38.228973635283815,
        "ndvi": 0.5171779025219716,
        "soil_temp": 21.039767331356195,
        "soil_humidity": 47.86886223959495
    },
    {
        "date": "2024-08-21 00:53:09.251267",
        "air_temp": 29.445051367861325,
        "humidity": 69.18863902570558,
        "rainfall": 0.5,
        "wind_speed": 3.4162741261242147,
        "soil_moisture": 24.59116302475443,
        "ndvi": 0.5540698534066826,
        "soil_temp": 10.117673818609347,
        "soil_humidity": 44.509465918899345
    },
    {
        "date": "2024-08-21 01:53:09.251267",
        "air_temp": 29.400730730921488,
        "humidity": 87.61108928487974,
        "rainfall": 0.5,
        "wind_speed": 3.953806062289751,
        "soil_moisture": 23.878578158011884,
        "ndvi": 0.37882501900814874,
        "soil_temp": 18.088457910047246,
        "soil_humidity": 30.677174097021403
    },
    {
        "date": "2024-08-21 02:53:09.251267",
        "air_temp": 27.822952657705947,
        "humidity": 42.12111491034459,
        "rainfall": 2,
        "wind_speed": 6.376346457460094,
        "soil_moisture": 33.55215584690538,
        "ndvi": 0.22103896941123125,
        "soil_temp": 21.906502105446307,
        "soil_humidity": 50.88380947659156
    },
    {
        "date": "2024-08-21 03:53:09.251267",
        "air_temp": 28.878968889342,
        "humidity": 53.266306877773346,
        "rainfall": 0,
        "wind_speed": 11.86992831643703,
        "soil_moisture": 34.64733075282514,
        "ndvi": 0.22235232836851768,
        "soil_temp": 13.993424294762022,
        "soil_humidity": 43.40991342801324
    },
    {
        "date": "2024-08-21 04:53:09.251267",
        "air_temp": 25.854488866951925,
        "humidity": 70.0776939649912,
        "rainfall": 1,
        "wind_speed": 4.202355518804291,
        "soil_moisture": 26.63467342963512,
        "ndvi": 0.21580839931635057,
        "soil_temp": 19.04937265918026,
        "soil_humidity": 30.052794759373185
    },
    {
        "date": "2024-08-21 05:53:09.251267",
        "air_temp": 20.035981178139053,
        "humidity": 54.82799561029653,
        "rainfall": 0,
        "wind_speed": 2.3266345426652246,
        "soil_moisture": 15.393934404852262,
        "ndvi": 0.6434748002819278,
        "soil_temp": 20.033501584279264,
        "soil_humidity": 42.317748306477284
    },
    {
        "date": "2024-08-21 06:53:09.251267",
        "air_temp": 21.913919870078388,
        "humidity": 75.72120820356211,
        "rainfall": 2,
        "wind_speed": 19.402337728368643,
        "soil_moisture": 24.032746928727384,
        "ndvi": 0.3908058228885789,
        "soil_temp": 19.82537044607635,
        "soil_humidity": 41.14513332359028
    },
    {
        "date": "2024-08-21 07:53:09.251267",
        "air_temp": 18.631954336028514,
        "humidity": 77.95026412609656,
        "rainfall": 0.5,
        "wind_speed": 0.43963629619245825,
        "soil_moisture": 39.04217508729035,
        "ndvi": 0.6777801115805983,
        "soil_temp": 16.687744512980764,
        "soil_humidity": 45.73792612894543
    },
    {
        "date": "2024-08-21 08:53:09.251267",
        "air_temp": 33.169011226672566,
        "humidity": 45.125798203323725,
        "rainfall": 1,
        "wind_speed": 16.053948106720433,
        "soil_moisture": 32.58958579117555,
        "ndvi": 0.45903969430570957,
        "soil_temp": 15.198669818136688,
        "soil_humidity": 35.274079585735876
    },
    {
        "date": "2024-08-21 09:53:09.251267",
        "air_temp": 26.66783589532241,
        "humidity": 65.69272321033839,
        "rainfall": 0.5,
        "wind_speed": 15.760702991500441,
        "soil_moisture": 34.334878116373964,
        "ndvi": 0.47179886049797837,
        "soil_temp": 22.538861095417204,
        "soil_humidity": 50.30735298540338
    },
    {
        "date": "2024-08-21 10:53:09.251267",
        "air_temp": 23.017028335272798,
        "humidity": 65.4445277795528,
        "rainfall": 2,
        "wind_speed": 5.9442822769019195,
        "soil_moisture": 10.984764964577204,
        "ndvi": 0.5077295615057442,
        "soil_temp": 21.376689354997964,
        "soil_humidity": 57.6976141007252
    },
    {
        "date": "2024-08-21 11:53:09.251267",
        "air_temp": 24.240116072882653,
        "humidity": 58.46403851077706,
        "rainfall": 0,
        "wind_speed": 2.712532320146268,
        "soil_moisture": 24.035783879024798,
        "ndvi": 0.6278188738250624,
        "soil_temp": 14.818827601208689,
        "soil_humidity": 55.2074227621829
    },
    {
        "date": "2024-08-21 12:53:09.251267",
        "air_temp": 33.94566679223631,
        "humidity": 86.6462431284836,
        "rainfall": 1,
        "wind_speed": 2.9125729341084683,
        "soil_moisture": 29.39863400972666,
        "ndvi": 0.36410171949782427,
        "soil_temp": 11.545287916633091,
        "soil_humidity": 62.15019790276762
    },
    {
        "date": "2024-08-21 13:53:09.251267",
        "air_temp": 18.067028062321604,
        "humidity": 81.37531599366106,
        "rainfall": 0.5,
        "wind_speed": 13.94863234474385,
        "soil_moisture": 34.90098050884224,
        "ndvi": 0.27295240972011614,
        "soil_temp": 11.077113181682037,
        "soil_humidity": 48.080331519483245
    },
    {
        "date": "2024-08-21 14:53:09.251267",
        "air_temp": 26.724596640335946,
        "humidity": 74.86046909548185,
        "rainfall": 0.5,
        "wind_speed": 12.856131821723473,
        "soil_moisture": 11.478209607483144,
        "ndvi": 0.533634401114877,
        "soil_temp": 11.41413966848404,
        "soil_humidity": 50.763669213589125
    },
    {
        "date": "2024-08-21 15:53:09.251267",
        "air_temp": 25.11777357768932,
        "humidity": 75.71633178674988,
        "rainfall": 0.5,
        "wind_speed": 15.654913485027734,
        "soil_moisture": 35.57316656925619,
        "ndvi": 0.3961865739456436,
        "soil_temp": 18.743040417880643,
        "soil_humidity": 62.41607763435933
    },
    {
        "date": "2024-08-21 16:53:09.251267",
        "air_temp": 27.22908470869296,
        "humidity": 63.085807190655956,
        "rainfall": 0,
        "wind_speed": 18.749623447982092,
        "soil_moisture": 26.45202810225735,
        "ndvi": 0.4654947882008225,
        "soil_temp": 10.774932637434846,
        "soil_humidity": 47.108046832769546
    },
    {
        "date": "2024-08-21 17:53:09.251267",
        "air_temp": 15.36220367641681,
        "humidity": 86.0497260548288,
        "rainfall": 1,
        "wind_speed": 13.83854726533184,
        "soil_moisture": 15.919419117281798,
        "ndvi": 0.5881680250023833,
        "soil_temp": 15.053903124460575,
        "soil_humidity": 58.06397399598388
    },
    {
        "date": "2024-08-21 18:53:09.251267",
        "air_temp": 32.44247817888303,
        "humidity": 74.72977136266427,
        "rainfall": 0,
        "wind_speed": 3.117030731028869,
        "soil_moisture": 19.517656493491,
        "ndvi": 0.4964754446847624,
        "soil_temp": 21.29799552871344,
        "soil_humidity": 47.384094748719676
    },
    {
        "date": "2024-08-21 19:53:09.251267",
        "air_temp": 33.64236564967224,
        "humidity": 76.44905308279081,
        "rainfall": 0,
        "wind_speed": 4.868852335308214,
        "soil_moisture": 18.457886552546825,
        "ndvi": 0.5721672902605057,
        "soil_temp": 14.41522249773067,
        "soil_humidity": 46.709447780462504
    },
    {
        "date": "2024-08-21 20:53:09.251267",
        "air_temp": 26.30266367178418,
        "humidity": 83.08454518936726,
        "rainfall": 0,
        "wind_speed": 16.84387461746229,
        "soil_moisture": 25.925771028654275,
        "ndvi": 0.5265200012681768,
        "soil_temp": 21.449621157701948,
        "soil_humidity": 60.40822498116641
    },
    {
        "date": "2024-08-21 21:53:09.251267",
        "air_temp": 28.933016477537844,
        "humidity": 53.70357946710955,
        "rainfall": 2,
        "wind_speed": 4.186121516684171,
        "soil_moisture": 11.550570397333955,
        "ndvi": 0.6438778231616595,
        "soil_temp": 21.18200557310583,
        "soil_humidity": 36.993476618648444
    },
    {
        "date": "2024-08-21 22:53:09.251267",
        "air_temp": 33.449987623545915,
        "humidity": 80.35354519333515,
        "rainfall": 2,
        "wind_speed": 1.4504463390896283,
        "soil_moisture": 14.756084754997705,
        "ndvi": 0.623561970626972,
        "soil_temp": 13.687406989498244,
        "soil_humidity": 43.54343887766294
    },
    {
        "date": "2024-08-21 23:53:09.251267",
        "air_temp": 29.14477268626797,
        "humidity": 49.7620301478246,
        "rainfall": 2,
        "wind_speed": 14.06560108084501,
        "soil_moisture": 34.380791301273476,
        "ndvi": 0.5205139350851715,
        "soil_temp": 20.932332701391182,
        "soil_humidity": 38.96689106987607
    },
    {
        "date": "2024-08-22 00:53:09.251267",
        "air_temp": 18.050780858285226,
        "humidity": 57.26709761224439,
        "rainfall": 1,
        "wind_speed": 8.050527109802841,
        "soil_moisture": 13.978119722404331,
        "ndvi": 0.6073418125158242,
        "soil_temp": 21.21664957408151,
        "soil_humidity": 44.364397718703884
    },
    {
        "date": "2024-08-22 01:53:09.251267",
        "air_temp": 26.525767203336265,
        "humidity": 56.78052271349213,
        "rainfall": 0,
        "wind_speed": 18.400801050918073,
        "soil_moisture": 17.001888083743808,
        "ndvi": 0.7241357613635677,
        "soil_temp": 15.961808885005404,
        "soil_humidity": 39.21499289464096
    },
    {
        "date": "2024-08-22 02:53:09.251267",
        "air_temp": 27.13430092765712,
        "humidity": 88.92627341945703,
        "rainfall": 0,
        "wind_speed": 13.005101421873231,
        "soil_moisture": 23.60111816812472,
        "ndvi": 0.4593627726729012,
        "soil_temp": 13.156307256122773,
        "soil_humidity": 53.176225243466185
    },
    {
        "date": "2024-08-22 03:53:09.251267",
        "air_temp": 23.48261342604772,
        "humidity": 82.82686118739633,
        "rainfall": 2,
        "wind_speed": 1.808379555833215,
        "soil_moisture": 25.513626643399913,
        "ndvi": 0.44765784364927624,
        "soil_temp": 17.819062103378037,
        "soil_humidity": 61.92683210471381
    },
    {
        "date": "2024-08-22 04:53:09.251267",
        "air_temp": 29.728884712494455,
        "humidity": 75.05849307951298,
        "rainfall": 0,
        "wind_speed": 18.957131345073215,
        "soil_moisture": 32.908314388424756,
        "ndvi": 0.6585473725114281,
        "soil_temp": 23.820963820002945,
        "soil_humidity": 61.464522448716075
    },
    {
        "date": "2024-08-22 05:53:09.251267",
        "air_temp": 33.687340295380295,
        "humidity": 76.35283808076952,
        "rainfall": 0,
        "wind_speed": 5.171145272804917,
        "soil_moisture": 23.419972799985633,
        "ndvi": 0.5495323022251966,
        "soil_temp": 19.59425098648093,
        "soil_humidity": 44.5800385819517
    },
    {
        "date": "2024-08-22 06:53:09.251267",
        "air_temp": 33.51137025813553,
        "humidity": 68.10363972012856,
        "rainfall": 0.5,
        "wind_speed": 6.744249808125716,
        "soil_moisture": 33.69593863106616,
        "ndvi": 0.24260406813355315,
        "soil_temp": 12.593837464408917,
        "soil_humidity": 69.55041623577047
    },
    {
        "date": "2024-08-22 07:53:09.251267",
        "air_temp": 24.016787428082644,
        "humidity": 87.35453335542596,
        "rainfall": 0,
        "wind_speed": 8.045208273670841,
        "soil_moisture": 28.550389211363722,
        "ndvi": 0.7352672094146488,
        "soil_temp": 20.2413538710028,
        "soil_humidity": 53.75417458823203
    },
    {
        "date": "2024-08-22 08:53:09.251267",
        "air_temp": 17.264760916815106,
        "humidity": 64.81293764411808,
        "rainfall": 0.5,
        "wind_speed": 1.024485928086265,
        "soil_moisture": 13.392992182714673,
        "ndvi": 0.2184698613425841,
        "soil_temp": 16.40804540922752,
        "soil_humidity": 45.97601207113179
    },
    {
        "date": "2024-08-22 09:53:09.251267",
        "air_temp": 34.69682397924669,
        "humidity": 59.02588549349473,
        "rainfall": 0,
        "wind_speed": 9.930308444612097,
        "soil_moisture": 14.689943151785158,
        "ndvi": 0.6490378137258459,
        "soil_temp": 18.872931960330597,
        "soil_humidity": 63.42157116965702
    },
    {
        "date": "2024-08-22 10:53:09.251267",
        "air_temp": 31.777961728918683,
        "humidity": 48.15176684657955,
        "rainfall": 1,
        "wind_speed": 2.466369821505845,
        "soil_moisture": 23.111011763493124,
        "ndvi": 0.6552764856225879,
        "soil_temp": 21.01096052534662,
        "soil_humidity": 59.19496050570564
    },
    {
        "date": "2024-08-22 11:53:09.251267",
        "air_temp": 17.493253624065336,
        "humidity": 79.31028261305289,
        "rainfall": 2,
        "wind_speed": 8.019406533767036,
        "soil_moisture": 21.906681792671186,
        "ndvi": 0.7718666511691583,
        "soil_temp": 17.72421027271667,
        "soil_humidity": 59.971013472964174
    },
    {
        "date": "2024-08-22 12:53:09.251267",
        "air_temp": 33.416837652347446,
        "humidity": 76.72219660377957,
        "rainfall": 0.5,
        "wind_speed": 7.0141957598416855,
        "soil_moisture": 32.306328089118836,
        "ndvi": 0.23248064504139246,
        "soil_temp": 22.20975961859963,
        "soil_humidity": 37.84557398935486
    },
    {
        "date": "2024-08-22 13:53:09.251267",
        "air_temp": 32.39792724124257,
        "humidity": 59.217751926206745,
        "rainfall": 2,
        "wind_speed": 15.456801668530067,
        "soil_moisture": 27.422114193920557,
        "ndvi": 0.5581141164706946,
        "soil_temp": 20.83233368974942,
        "soil_humidity": 48.732972294742204
    },
    {
        "date": "2024-08-22 14:53:09.251267",
        "air_temp": 25.376761142521442,
        "humidity": 41.25967069796147,
        "rainfall": 0.5,
        "wind_speed": 16.063410232654622,
        "soil_moisture": 21.643992430067478,
        "ndvi": 0.4567043423762318,
        "soil_temp": 19.226100100277662,
        "soil_humidity": 64.4731252329841
    },
    {
        "date": "2024-08-22 15:53:09.251267",
        "air_temp": 26.825508714898586,
        "humidity": 81.94986638590053,
        "rainfall": 0.5,
        "wind_speed": 3.0682576477817602,
        "soil_moisture": 15.12280850351157,
        "ndvi": 0.39837977291572524,
        "soil_temp": 12.261892237485272,
        "soil_humidity": 50.16152030515029
    },
    {
        "date": "2024-08-22 16:53:09.251267",
        "air_temp": 22.980054077402606,
        "humidity": 40.57089917270368,
        "rainfall": 0.5,
        "wind_speed": 7.331023747584524,
        "soil_moisture": 16.805113069764854,
        "ndvi": 0.25419113617944383,
        "soil_temp": 22.539236663673524,
        "soil_humidity": 57.338109736842014
    },
    {
        "date": "2024-08-22 17:53:09.251267",
        "air_temp": 16.095232776440625,
        "humidity": 75.18498898145255,
        "rainfall": 1,
        "wind_speed": 7.467063220636634,
        "soil_moisture": 27.26503055407177,
        "ndvi": 0.5375924718027856,
        "soil_temp": 24.038583779797243,
        "soil_humidity": 53.51734932137058
    },
    {
        "date": "2024-08-22 18:53:09.251267",
        "air_temp": 21.70394483291802,
        "humidity": 88.51285548630285,
        "rainfall": 0,
        "wind_speed": 3.028208878721701,
        "soil_moisture": 22.703164146993775,
        "ndvi": 0.48742443540476826,
        "soil_temp": 12.054476857139422,
        "soil_humidity": 63.694271508735284
    },
    {
        "date": "2024-08-22 19:53:09.251267",
        "air_temp": 31.057068971960227,
        "humidity": 61.88306640741017,
        "rainfall": 0.5,
        "wind_speed": 15.96907010102381,
        "soil_moisture": 25.447825265820164,
        "ndvi": 0.2627326748906146,
        "soil_temp": 17.32361694150146,
        "soil_humidity": 41.3824335904641
    },
    {
        "date": "2024-08-22 20:53:09.251267",
        "air_temp": 15.092640460092056,
        "humidity": 51.74867024979764,
        "rainfall": 1,
        "wind_speed": 14.144566566708466,
        "soil_moisture": 15.12032305906701,
        "ndvi": 0.5674937042582501,
        "soil_temp": 13.883331129557568,
        "soil_humidity": 53.019011115454596
    },
    {
        "date": "2024-08-22 21:53:09.251267",
        "air_temp": 21.669983433822885,
        "humidity": 75.24355050216909,
        "rainfall": 0.5,
        "wind_speed": 3.8076357259406945,
        "soil_moisture": 29.887187570336973,
        "ndvi": 0.6427718721517632,
        "soil_temp": 21.31784961138347,
        "soil_humidity": 34.92938672039216
    },
    {
        "date": "2024-08-22 22:53:09.251267",
        "air_temp": 22.963373871818867,
        "humidity": 80.85640710077925,
        "rainfall": 0,
        "wind_speed": 7.334438171875981,
        "soil_moisture": 35.57616222567901,
        "ndvi": 0.660366980632189,
        "soil_temp": 20.824444540070502,
        "soil_humidity": 57.608431067978714
    },
    {
        "date": "2024-08-22 23:53:09.251267",
        "air_temp": 25.74791205875846,
        "humidity": 67.32151580389237,
        "rainfall": 0.5,
        "wind_speed": 2.1161004971302844,
        "soil_moisture": 17.738185203607017,
        "ndvi": 0.7432239129574565,
        "soil_temp": 12.11327680855598,
        "soil_humidity": 59.27871353410848
    },
    {
        "date": "2024-08-23 00:53:09.251267",
        "air_temp": 33.39711232825521,
        "humidity": 88.35176376280731,
        "rainfall": 1,
        "wind_speed": 12.564131941860879,
        "soil_moisture": 24.745426774954797,
        "ndvi": 0.4456824451332664,
        "soil_temp": 23.155409068924556,
        "soil_humidity": 31.713492847559937
    },
    {
        "date": "2024-08-23 01:53:09.251267",
        "air_temp": 21.926919887319226,
        "humidity": 42.583436082898054,
        "rainfall": 0,
        "wind_speed": 2.4274384909723934,
        "soil_moisture": 39.265819870627055,
        "ndvi": 0.7718436611732822,
        "soil_temp": 12.714595959670035,
        "soil_humidity": 57.03163854511941
    },
    {
        "date": "2024-08-23 02:53:09.251267",
        "air_temp": 21.939064037924553,
        "humidity": 65.2397799152264,
        "rainfall": 1,
        "wind_speed": 19.718683372498354,
        "soil_moisture": 19.99725221652183,
        "ndvi": 0.7192576430496118,
        "soil_temp": 21.95888538472161,
        "soil_humidity": 46.58760616663145
    },
    {
        "date": "2024-08-23 03:53:09.251267",
        "air_temp": 29.750024962194967,
        "humidity": 75.9226973661275,
        "rainfall": 0,
        "wind_speed": 15.613641493757246,
        "soil_moisture": 27.851390347712837,
        "ndvi": 0.37790094261436347,
        "soil_temp": 16.854705394420073,
        "soil_humidity": 34.963875044387294
    },
    {
        "date": "2024-08-23 04:53:09.251267",
        "air_temp": 24.044358817796144,
        "humidity": 83.13202355745895,
        "rainfall": 0,
        "wind_speed": 7.804770991679901,
        "soil_moisture": 12.705871006007332,
        "ndvi": 0.2955698224096751,
        "soil_temp": 14.92408440337227,
        "soil_humidity": 46.796301907938705
    },
    {
        "date": "2024-08-23 05:53:09.251267",
        "air_temp": 19.49209645879964,
        "humidity": 48.9627805953895,
        "rainfall": 2,
        "wind_speed": 11.571278645587089,
        "soil_moisture": 28.180450074383295,
        "ndvi": 0.4612336376768081,
        "soil_temp": 12.373588940113203,
        "soil_humidity": 37.76931718782327
    },
    {
        "date": "2024-08-23 06:53:09.251267",
        "air_temp": 24.04879032265387,
        "humidity": 80.00017408818407,
        "rainfall": 2,
        "wind_speed": 18.226836324328175,
        "soil_moisture": 12.688953199138538,
        "ndvi": 0.44113922477358003,
        "soil_temp": 12.51421384219944,
        "soil_humidity": 46.62238128493266
    },
    {
        "date": "2024-08-23 07:53:09.251267",
        "air_temp": 17.817140407595996,
        "humidity": 67.63535378592726,
        "rainfall": 2,
        "wind_speed": 11.081985607916522,
        "soil_moisture": 38.77706305971466,
        "ndvi": 0.5454315831987226,
        "soil_temp": 19.928358320513503,
        "soil_humidity": 45.37356111834111
    },
    {
        "date": "2024-08-23 08:53:09.251267",
        "air_temp": 18.527739730124466,
        "humidity": 59.82768409949816,
        "rainfall": 0,
        "wind_speed": 3.4899697279591035,
        "soil_moisture": 31.469879562169343,
        "ndvi": 0.5500317393966488,
        "soil_temp": 18.256455819206806,
        "soil_humidity": 59.5356654774423
    },
    {
        "date": "2024-08-23 09:53:09.251267",
        "air_temp": 24.967355454789594,
        "humidity": 46.58575142895468,
        "rainfall": 0.5,
        "wind_speed": 7.574067111623187,
        "soil_moisture": 33.64479364407119,
        "ndvi": 0.6499921906279853,
        "soil_temp": 11.83128132476849,
        "soil_humidity": 60.968622326654355
    },
    {
        "date": "2024-08-23 10:53:09.251267",
        "air_temp": 23.378508990090957,
        "humidity": 83.26478794544562,
        "rainfall": 1,
        "wind_speed": 16.86289730059491,
        "soil_moisture": 31.123130823149104,
        "ndvi": 0.5059339689233662,
        "soil_temp": 11.690467906574877,
        "soil_humidity": 54.28114617538186
    },
    {
        "date": "2024-08-23 11:53:09.251267",
        "air_temp": 33.296918021362,
        "humidity": 47.863660408985766,
        "rainfall": 1,
        "wind_speed": 12.884260319792357,
        "soil_moisture": 27.69187108229397,
        "ndvi": 0.2628935845644593,
        "soil_temp": 11.084658621591872,
        "soil_humidity": 30.9074736872979
    },
    {
        "date": "2024-08-23 12:53:09.251267",
        "air_temp": 22.24787798233266,
        "humidity": 55.489392960454964,
        "rainfall": 1,
        "wind_speed": 13.014487686676006,
        "soil_moisture": 23.49738343256516,
        "ndvi": 0.7149748013318638,
        "soil_temp": 21.156510210603162,
        "soil_humidity": 67.24089116283976
    },
    {
        "date": "2024-08-23 13:53:09.251267",
        "air_temp": 26.61176700556087,
        "humidity": 54.502276598121846,
        "rainfall": 2,
        "wind_speed": 17.336429713769782,
        "soil_moisture": 35.751201392831874,
        "ndvi": 0.38585874991111224,
        "soil_temp": 20.678231278439338,
        "soil_humidity": 57.44933120385791
    },
    {
        "date": "2024-08-23 14:53:09.251267",
        "air_temp": 27.645285758390607,
        "humidity": 83.57070170954272,
        "rainfall": 0,
        "wind_speed": 17.879461149250094,
        "soil_moisture": 38.70507002629189,
        "ndvi": 0.7827784761839554,
        "soil_temp": 10.876464862818628,
        "soil_humidity": 31.367817996847553
    },
    {
        "date": "2024-08-23 15:53:09.251267",
        "air_temp": 15.261889131766672,
        "humidity": 73.63514971044489,
        "rainfall": 2,
        "wind_speed": 18.395851435588725,
        "soil_moisture": 24.97108518712244,
        "ndvi": 0.2707160652078621,
        "soil_temp": 18.404051133954596,
        "soil_humidity": 62.75331596856471
    },
    {
        "date": "2024-08-23 16:53:09.251267",
        "air_temp": 28.270747440334212,
        "humidity": 79.83406985956861,
        "rainfall": 0.5,
        "wind_speed": 1.896983865063122,
        "soil_moisture": 37.946051474657864,
        "ndvi": 0.774950703339502,
        "soil_temp": 11.270142328640349,
        "soil_humidity": 38.010646188719846
    },
    {
        "date": "2024-08-23 17:53:09.251267",
        "air_temp": 18.560719337395028,
        "humidity": 52.52339493962495,
        "rainfall": 0,
        "wind_speed": 10.882520039262019,
        "soil_moisture": 27.499987299655526,
        "ndvi": 0.2546070841295102,
        "soil_temp": 23.556186559134126,
        "soil_humidity": 53.16884758284427
    },
    {
        "date": "2024-08-23 18:53:09.251267",
        "air_temp": 34.2214063493891,
        "humidity": 71.24370498034995,
        "rainfall": 1,
        "wind_speed": 14.810190424366372,
        "soil_moisture": 26.151525606892996,
        "ndvi": 0.3386216827682357,
        "soil_temp": 23.606761287701985,
        "soil_humidity": 48.665374798007484
    },
    {
        "date": "2024-08-23 19:53:09.251267",
        "air_temp": 17.973254555062258,
        "humidity": 68.58729915718743,
        "rainfall": 2,
        "wind_speed": 15.138536902540276,
        "soil_moisture": 23.634481988660703,
        "ndvi": 0.22794796525192496,
        "soil_temp": 20.473494847196953,
        "soil_humidity": 46.79355129420307
    },
    {
        "date": "2024-08-23 20:53:09.251267",
        "air_temp": 23.292482474540474,
        "humidity": 81.64151883941355,
        "rainfall": 2,
        "wind_speed": 12.134324039400077,
        "soil_moisture": 16.278632845903594,
        "ndvi": 0.6230678879833971,
        "soil_temp": 23.89283382159175,
        "soil_humidity": 65.5885488049759
    },
    {
        "date": "2024-08-23 21:53:09.251267",
        "air_temp": 16.706993361572877,
        "humidity": 85.30435302406303,
        "rainfall": 0.5,
        "wind_speed": 3.3357467036734834,
        "soil_moisture": 10.111589824541776,
        "ndvi": 0.7101792419430752,
        "soil_temp": 22.17179545620536,
        "soil_humidity": 47.49445166250459
    },
    {
        "date": "2024-08-23 22:53:09.251267",
        "air_temp": 34.93748503691894,
        "humidity": 40.60783857386934,
        "rainfall": 0.5,
        "wind_speed": 15.948411078833287,
        "soil_moisture": 17.24518680568184,
        "ndvi": 0.4604426490895883,
        "soil_temp": 22.42807863890574,
        "soil_humidity": 33.01376470292603
    },
    {
        "date": "2024-08-23 23:53:09.251267",
        "air_temp": 25.04390020662485,
        "humidity": 73.70099595293964,
        "rainfall": 2,
        "wind_speed": 14.402226670298914,
        "soil_moisture": 25.291695148106953,
        "ndvi": 0.7571132450254578,
        "soil_temp": 18.545617758549,
        "soil_humidity": 67.8655490885981
    },
    {
        "date": "2024-08-24 00:53:09.251267",
        "air_temp": 26.907700346400876,
        "humidity": 42.59178995598645,
        "rainfall": 0.5,
        "wind_speed": 1.7227154309807258,
        "soil_moisture": 21.398798567792575,
        "ndvi": 0.5541794430533598,
        "soil_temp": 14.590887973242637,
        "soil_humidity": 66.8214092495851
    },
    {
        "date": "2024-08-24 01:53:09.251267",
        "air_temp": 16.34152954776855,
        "humidity": 67.44293325386779,
        "rainfall": 1,
        "wind_speed": 9.276196493487525,
        "soil_moisture": 23.454556840079015,
        "ndvi": 0.578004281267914,
        "soil_temp": 13.266986726746325,
        "soil_humidity": 57.37730758326395
    },
    {
        "date": "2024-08-24 02:53:09.251267",
        "air_temp": 29.999209407983557,
        "humidity": 54.38163645218391,
        "rainfall": 0.5,
        "wind_speed": 3.4795479904360715,
        "soil_moisture": 39.41944658692149,
        "ndvi": 0.707251521020462,
        "soil_temp": 14.908000330583938,
        "soil_humidity": 48.715701125281
    },
    {
        "date": "2024-08-24 03:53:09.251267",
        "air_temp": 19.198111861911716,
        "humidity": 55.338829976107064,
        "rainfall": 0.5,
        "wind_speed": 6.749540914259438,
        "soil_moisture": 20.50817112007983,
        "ndvi": 0.3780801230311034,
        "soil_temp": 19.42743145133593,
        "soil_humidity": 33.71437891774085
    },
    {
        "date": "2024-08-24 04:53:09.251267",
        "air_temp": 32.96108578881427,
        "humidity": 57.64792517261783,
        "rainfall": 2,
        "wind_speed": 0.3616218441137087,
        "soil_moisture": 23.565488180803236,
        "ndvi": 0.41255943279657553,
        "soil_temp": 12.589848037485984,
        "soil_humidity": 61.629924743099046
    },
    {
        "date": "2024-08-24 05:53:09.251267",
        "air_temp": 19.102792809640142,
        "humidity": 71.0646224524805,
        "rainfall": 2,
        "wind_speed": 8.006597368215164,
        "soil_moisture": 14.005466540584587,
        "ndvi": 0.7435711515167867,
        "soil_temp": 13.879603549559825,
        "soil_humidity": 33.01911657696116
    },
    {
        "date": "2024-08-24 06:53:09.251267",
        "air_temp": 18.81375441327333,
        "humidity": 56.70249828343846,
        "rainfall": 0,
        "wind_speed": 10.997538423124208,
        "soil_moisture": 11.438731973109629,
        "ndvi": 0.7405780976232583,
        "soil_temp": 17.020912941931968,
        "soil_humidity": 32.74403939580583
    },
    {
        "date": "2024-08-24 07:53:09.251267",
        "air_temp": 15.730993356961898,
        "humidity": 76.63495252951401,
        "rainfall": 0,
        "wind_speed": 12.391651856541737,
        "soil_moisture": 33.262285437774835,
        "ndvi": 0.7858896657205225,
        "soil_temp": 13.495751477869234,
        "soil_humidity": 51.49535369757473
    },
    {
        "date": "2024-08-24 08:53:09.251267",
        "air_temp": 24.441338902199984,
        "humidity": 60.22636927869348,
        "rainfall": 2,
        "wind_speed": 13.269806902574201,
        "soil_moisture": 27.12049525215936,
        "ndvi": 0.7411029898650028,
        "soil_temp": 18.829655045740925,
        "soil_humidity": 36.40512960738561
    },
    {
        "date": "2024-08-24 09:53:09.251267",
        "air_temp": 26.29682266525233,
        "humidity": 43.41766001549793,
        "rainfall": 0.5,
        "wind_speed": 16.7258262620492,
        "soil_moisture": 32.82114418131685,
        "ndvi": 0.27642322162001653,
        "soil_temp": 23.847640426844947,
        "soil_humidity": 30.30921069361396
    },
    {
        "date": "2024-08-24 10:53:09.251267",
        "air_temp": 16.31417278856705,
        "humidity": 79.18799212420537,
        "rainfall": 0.5,
        "wind_speed": 4.309258145514601,
        "soil_moisture": 14.495883966596548,
        "ndvi": 0.6942345716821925,
        "soil_temp": 21.221340485546897,
        "soil_humidity": 46.92453723983145
    },
    {
        "date": "2024-08-24 11:53:09.251267",
        "air_temp": 30.51055233390021,
        "humidity": 54.2879162296353,
        "rainfall": 0,
        "wind_speed": 2.409636448769359,
        "soil_moisture": 27.154863135480728,
        "ndvi": 0.4459030868657565,
        "soil_temp": 19.996399212233698,
        "soil_humidity": 35.48868926440264
    },
    {
        "date": "2024-08-24 12:53:09.251267",
        "air_temp": 24.065776694960554,
        "humidity": 61.63834397996313,
        "rainfall": 0,
        "wind_speed": 17.030743437958513,
        "soil_moisture": 17.609274230241265,
        "ndvi": 0.7944808135675627,
        "soil_temp": 18.74082213975668,
        "soil_humidity": 36.42061661122765
    },
    {
        "date": "2024-08-24 13:53:09.251267",
        "air_temp": 25.4878053865516,
        "humidity": 74.27219416109509,
        "rainfall": 0,
        "wind_speed": 3.1322562978529978,
        "soil_moisture": 16.841854507000598,
        "ndvi": 0.4833685785972807,
        "soil_temp": 17.319218638855972,
        "soil_humidity": 33.076425549413386
    },
    {
        "date": "2024-08-24 14:53:09.251267",
        "air_temp": 23.81525493876456,
        "humidity": 56.6228078574356,
        "rainfall": 1,
        "wind_speed": 19.495201626723905,
        "soil_moisture": 10.345780421589557,
        "ndvi": 0.5323016495013324,
        "soil_temp": 12.798457894519895,
        "soil_humidity": 38.203750148944394
    },
    {
        "date": "2024-08-24 15:53:09.251267",
        "air_temp": 23.01526121750652,
        "humidity": 42.82928280733578,
        "rainfall": 1,
        "wind_speed": 16.85076458797274,
        "soil_moisture": 13.910221707072157,
        "ndvi": 0.5178649491540792,
        "soil_temp": 23.420656831402983,
        "soil_humidity": 58.619367190975574
    },
    {
        "date": "2024-08-24 16:53:09.251267",
        "air_temp": 26.19280662616436,
        "humidity": 58.696052350640684,
        "rainfall": 0,
        "wind_speed": 10.017532117791983,
        "soil_moisture": 19.278043344990863,
        "ndvi": 0.39304221791496674,
        "soil_temp": 13.728040030192545,
        "soil_humidity": 62.357092288711655
    },
    {
        "date": "2024-08-24 17:53:09.251267",
        "air_temp": 18.10480491861425,
        "humidity": 87.22242913471517,
        "rainfall": 0.5,
        "wind_speed": 19.628428498440506,
        "soil_moisture": 13.50636281772817,
        "ndvi": 0.2450132533357509,
        "soil_temp": 11.369633163672411,
        "soil_humidity": 48.10134576117203
    },
    {
        "date": "2024-08-24 18:53:09.251267",
        "air_temp": 18.63856260990542,
        "humidity": 72.08671986492791,
        "rainfall": 0,
        "wind_speed": 1.9475345821600842,
        "soil_moisture": 39.575753212328124,
        "ndvi": 0.7373907382411513,
        "soil_temp": 21.250227688430428,
        "soil_humidity": 50.26215263525671
    },
    {
        "date": "2024-08-24 19:53:09.251267",
        "air_temp": 32.235712420270346,
        "humidity": 73.57395743336035,
        "rainfall": 1,
        "wind_speed": 6.061315223502033,
        "soil_moisture": 19.25897945571852,
        "ndvi": 0.6816054875157722,
        "soil_temp": 12.634233014967496,
        "soil_humidity": 31.306201383768105
    },
    {
        "date": "2024-08-24 20:53:09.251267",
        "air_temp": 33.922309242672654,
        "humidity": 71.61410768188199,
        "rainfall": 0.5,
        "wind_speed": 12.764488300812229,
        "soil_moisture": 13.909718401069846,
        "ndvi": 0.2837160683358016,
        "soil_temp": 15.578439993117641,
        "soil_humidity": 32.77265045485267
    },
    {
        "date": "2024-08-24 21:53:09.251267",
        "air_temp": 22.46618632559506,
        "humidity": 49.94960694797184,
        "rainfall": 1,
        "wind_speed": 7.000137369463575,
        "soil_moisture": 15.972469384838732,
        "ndvi": 0.6660609628100438,
        "soil_temp": 10.424273924364696,
        "soil_humidity": 40.302709025980676
    },
    {
        "date": "2024-08-24 22:53:09.251267",
        "air_temp": 20.414893462871078,
        "humidity": 60.91669320511401,
        "rainfall": 0.5,
        "wind_speed": 18.567451039615836,
        "soil_moisture": 27.631096850244294,
        "ndvi": 0.575507999630366,
        "soil_temp": 12.056019138106722,
        "soil_humidity": 51.755936828525904
    },
    {
        "date": "2024-08-24 23:53:09.251267",
        "air_temp": 27.879990864780314,
        "humidity": 77.5469899181659,
        "rainfall": 1,
        "wind_speed": 1.3125217635563513,
        "soil_moisture": 11.921305715943655,
        "ndvi": 0.4454862727505734,
        "soil_temp": 12.598054461398682,
        "soil_humidity": 59.58204594939971
    },
    {
        "date": "2024-08-25 00:53:09.251267",
        "air_temp": 23.17468342196193,
        "humidity": 45.06864487765796,
        "rainfall": 0.5,
        "wind_speed": 10.988236641219292,
        "soil_moisture": 35.87302390423471,
        "ndvi": 0.35241268945733767,
        "soil_temp": 10.81615191625267,
        "soil_humidity": 54.200122453521324
    },
    {
        "date": "2024-08-25 01:53:09.251267",
        "air_temp": 15.507727113206897,
        "humidity": 53.892638804485614,
        "rainfall": 0.5,
        "wind_speed": 11.81732534790229,
        "soil_moisture": 38.84499037711442,
        "ndvi": 0.7158372701013396,
        "soil_temp": 19.849854349342678,
        "soil_humidity": 45.68511419947153
    },
    {
        "date": "2024-08-25 02:53:09.251267",
        "air_temp": 18.12305194732381,
        "humidity": 53.81595542329268,
        "rainfall": 0.5,
        "wind_speed": 13.054562475544483,
        "soil_moisture": 17.402422620021426,
        "ndvi": 0.620112955576432,
        "soil_temp": 22.55511693704676,
        "soil_humidity": 34.73296292012553
    },
    {
        "date": "2024-08-25 03:53:09.251267",
        "air_temp": 29.31944457694795,
        "humidity": 61.6009463797253,
        "rainfall": 0.5,
        "wind_speed": 5.113324466636289,
        "soil_moisture": 23.50285805772578,
        "ndvi": 0.5286439456814822,
        "soil_temp": 15.599731095489116,
        "soil_humidity": 61.23092939548758
    },
    {
        "date": "2024-08-25 04:53:09.251267",
        "air_temp": 28.178478838203027,
        "humidity": 89.01843704710924,
        "rainfall": 2,
        "wind_speed": 7.291328550397289,
        "soil_moisture": 10.664033530884913,
        "ndvi": 0.5657411203390785,
        "soil_temp": 23.11864715077301,
        "soil_humidity": 44.551442063962284
    },
    {
        "date": "2024-08-25 05:53:09.251267",
        "air_temp": 15.54191985006967,
        "humidity": 43.3751273341911,
        "rainfall": 1,
        "wind_speed": 16.873610593299553,
        "soil_moisture": 29.221306741816644,
        "ndvi": 0.2546397092493086,
        "soil_temp": 13.200198618401897,
        "soil_humidity": 59.44216057931158
    },
    {
        "date": "2024-08-25 06:53:09.251267",
        "air_temp": 19.43944323865899,
        "humidity": 65.93504971902975,
        "rainfall": 1,
        "wind_speed": 3.8988553794031966,
        "soil_moisture": 22.417781741577667,
        "ndvi": 0.4843518565467152,
        "soil_temp": 14.880856353418592,
        "soil_humidity": 50.51915367657367
    },
    {
        "date": "2024-08-25 07:53:09.251267",
        "air_temp": 19.62149593176143,
        "humidity": 48.96823410061052,
        "rainfall": 2,
        "wind_speed": 16.53735694346724,
        "soil_moisture": 18.10175398304667,
        "ndvi": 0.31622017567142124,
        "soil_temp": 12.261054895467526,
        "soil_humidity": 59.54648214247301
    },
    {
        "date": "2024-08-25 08:53:09.251267",
        "air_temp": 28.43785487197457,
        "humidity": 88.53382727048721,
        "rainfall": 0.5,
        "wind_speed": 11.253108458054848,
        "soil_moisture": 16.205113318498256,
        "ndvi": 0.32503020740529526,
        "soil_temp": 19.30838793128498,
        "soil_humidity": 66.29158245810288
    },
    {
        "date": "2024-08-25 09:53:09.251267",
        "air_temp": 15.394210755087283,
        "humidity": 45.66517523637321,
        "rainfall": 0.5,
        "wind_speed": 15.243750223881642,
        "soil_moisture": 29.620692329762814,
        "ndvi": 0.5967823773721201,
        "soil_temp": 16.298043863054602,
        "soil_humidity": 66.84118064215208
    },
    {
        "date": "2024-08-25 10:53:09.251267",
        "air_temp": 17.082171639691477,
        "humidity": 60.18005011807956,
        "rainfall": 1,
        "wind_speed": 14.272147325269431,
        "soil_moisture": 22.486510930969434,
        "ndvi": 0.746753691100573,
        "soil_temp": 19.19925020262848,
        "soil_humidity": 51.40427120002414
    },
    {
        "date": "2024-08-25 11:53:09.251267",
        "air_temp": 30.998321707463788,
        "humidity": 76.89424924173031,
        "rainfall": 2,
        "wind_speed": 4.150229907697581,
        "soil_moisture": 15.38977867089736,
        "ndvi": 0.44225396628700797,
        "soil_temp": 21.425123580912754,
        "soil_humidity": 50.46033459062555
    },
    {
        "date": "2024-08-25 12:53:09.251267",
        "air_temp": 18.570893241086672,
        "humidity": 75.22772140137226,
        "rainfall": 0.5,
        "wind_speed": 11.376254728677457,
        "soil_moisture": 30.67327244460472,
        "ndvi": 0.32464956380731425,
        "soil_temp": 18.18554524049423,
        "soil_humidity": 40.62713862011349
    },
    {
        "date": "2024-08-25 13:53:09.251267",
        "air_temp": 28.054922157037495,
        "humidity": 61.13643184469662,
        "rainfall": 0.5,
        "wind_speed": 17.35446305610185,
        "soil_moisture": 26.95216849110081,
        "ndvi": 0.5892481623419926,
        "soil_temp": 19.414455323526816,
        "soil_humidity": 67.43199326117166
    },
    {
        "date": "2024-08-25 14:53:09.251267",
        "air_temp": 19.76365562093453,
        "humidity": 57.32620839763591,
        "rainfall": 0.5,
        "wind_speed": 8.642324736038587,
        "soil_moisture": 35.760161647884885,
        "ndvi": 0.7227122278101907,
        "soil_temp": 24.028754715914992,
        "soil_humidity": 48.31676668798484
    },
    {
        "date": "2024-08-25 15:53:09.251267",
        "air_temp": 16.988827855186905,
        "humidity": 59.880618927643525,
        "rainfall": 0,
        "wind_speed": 19.331815404466333,
        "soil_moisture": 13.524276966294787,
        "ndvi": 0.6489912806734945,
        "soil_temp": 14.485964644132647,
        "soil_humidity": 35.10364957149508
    },
    {
        "date": "2024-08-25 16:53:09.251267",
        "air_temp": 19.86344381998908,
        "humidity": 53.213813157931966,
        "rainfall": 2,
        "wind_speed": 9.04430200203436,
        "soil_moisture": 33.56999805833368,
        "ndvi": 0.7212543383219523,
        "soil_temp": 15.387459079509297,
        "soil_humidity": 47.30819658506199
    },
    {
        "date": "2024-08-25 17:53:09.251267",
        "air_temp": 29.44533863711318,
        "humidity": 50.26664693533999,
        "rainfall": 0.5,
        "wind_speed": 2.8557127333576937,
        "soil_moisture": 30.20550360451777,
        "ndvi": 0.5219328026064797,
        "soil_temp": 20.765550425640054,
        "soil_humidity": 35.08213000952016
    },
    {
        "date": "2024-08-25 18:53:09.251267",
        "air_temp": 32.11392936212572,
        "humidity": 64.1519848779445,
        "rainfall": 0,
        "wind_speed": 7.788693601553465,
        "soil_moisture": 13.450328104842935,
        "ndvi": 0.6511257466215146,
        "soil_temp": 19.169582755830803,
        "soil_humidity": 50.101267370283004
    },
    {
        "date": "2024-08-25 19:53:09.251267",
        "air_temp": 31.604397291339833,
        "humidity": 53.42668780338037,
        "rainfall": 2,
        "wind_speed": 19.034048127810742,
        "soil_moisture": 30.844621896151622,
        "ndvi": 0.2840387356153619,
        "soil_temp": 12.677993568095722,
        "soil_humidity": 52.45102059424849
    },
    {
        "date": "2024-08-25 20:53:09.251267",
        "air_temp": 22.943670592369095,
        "humidity": 54.37308324486251,
        "rainfall": 0.5,
        "wind_speed": 6.394416986797085,
        "soil_moisture": 23.246418666394913,
        "ndvi": 0.4089647692179149,
        "soil_temp": 15.642209650157353,
        "soil_humidity": 43.51023045974341
    },
    {
        "date": "2024-08-25 21:53:09.251267",
        "air_temp": 28.361702731412922,
        "humidity": 72.83780317372785,
        "rainfall": 1,
        "wind_speed": 17.361963197341257,
        "soil_moisture": 38.349543566675294,
        "ndvi": 0.43372949868891353,
        "soil_temp": 17.017570196870725,
        "soil_humidity": 32.86602456925939
    },
    {
        "date": "2024-08-25 22:53:09.251267",
        "air_temp": 19.09968590831642,
        "humidity": 88.42686658499461,
        "rainfall": 0,
        "wind_speed": 17.625013633637796,
        "soil_moisture": 14.24834051853698,
        "ndvi": 0.5067774477476208,
        "soil_temp": 16.29084756952584,
        "soil_humidity": 67.74014142532957
    },
    {
        "date": "2024-08-25 23:53:09.251267",
        "air_temp": 20.862954605220267,
        "humidity": 70.18186002796875,
        "rainfall": 0,
        "wind_speed": 7.003730486639221,
        "soil_moisture": 29.28500172447214,
        "ndvi": 0.7574458161540942,
        "soil_temp": 12.674122124830612,
        "soil_humidity": 60.93412453933718
    },
    {
        "date": "2024-08-26 00:53:09.251267",
        "air_temp": 32.9267163704224,
        "humidity": 43.84897335324831,
        "rainfall": 0.5,
        "wind_speed": 1.6651531610126424,
        "soil_moisture": 21.45940136388838,
        "ndvi": 0.7245779361333047,
        "soil_temp": 23.866451376763933,
        "soil_humidity": 63.555243220204844
    },
    {
        "date": "2024-08-26 01:53:09.251267",
        "air_temp": 15.26003847021472,
        "humidity": 43.77918069270335,
        "rainfall": 0.5,
        "wind_speed": 14.491158183176365,
        "soil_moisture": 38.704440117726335,
        "ndvi": 0.28071529537351075,
        "soil_temp": 21.43478142540964,
        "soil_humidity": 44.67473577144918
    },
    {
        "date": "2024-08-26 02:53:09.251267",
        "air_temp": 16.71017061708936,
        "humidity": 87.5711610422783,
        "rainfall": 0,
        "wind_speed": 6.2302459802797205,
        "soil_moisture": 19.899246858708295,
        "ndvi": 0.30367379112947795,
        "soil_temp": 17.220337481617783,
        "soil_humidity": 60.43324120077802
    },
    {
        "date": "2024-08-26 03:53:09.251267",
        "air_temp": 19.157725102920544,
        "humidity": 54.86453951536668,
        "rainfall": 1,
        "wind_speed": 15.658472285151447,
        "soil_moisture": 38.865577284754,
        "ndvi": 0.41458432975334686,
        "soil_temp": 20.20091542460255,
        "soil_humidity": 49.414915806767766
    },
    {
        "date": "2024-08-26 04:53:09.251267",
        "air_temp": 15.530644077476394,
        "humidity": 44.60334930412377,
        "rainfall": 1,
        "wind_speed": 8.853096965208662,
        "soil_moisture": 37.044575533022474,
        "ndvi": 0.3744463195344044,
        "soil_temp": 23.459240082032828,
        "soil_humidity": 38.04333953624184
    },
    {
        "date": "2024-08-26 05:53:09.251267",
        "air_temp": 18.628708701795947,
        "humidity": 69.95222935751907,
        "rainfall": 2,
        "wind_speed": 9.70533484685243,
        "soil_moisture": 18.11149705931969,
        "ndvi": 0.5688625718937574,
        "soil_temp": 12.042612135423568,
        "soil_humidity": 34.976935076288534
    },
    {
        "date": "2024-08-26 06:53:09.251267",
        "air_temp": 26.66083121939384,
        "humidity": 71.18243891451331,
        "rainfall": 0.5,
        "wind_speed": 15.558188134970736,
        "soil_moisture": 34.10625330479283,
        "ndvi": 0.7416656286587271,
        "soil_temp": 13.755595760604665,
        "soil_humidity": 43.80203477288227
    },
    {
        "date": "2024-08-26 07:53:09.251267",
        "air_temp": 23.428491011849957,
        "humidity": 72.42524100593441,
        "rainfall": 1,
        "wind_speed": 7.647266621232558,
        "soil_moisture": 39.868126054105176,
        "ndvi": 0.7773749250035522,
        "soil_temp": 18.140518708849676,
        "soil_humidity": 35.50208445900559
    },
    {
        "date": "2024-08-26 08:53:09.251267",
        "air_temp": 32.8534342215395,
        "humidity": 53.37010139287706,
        "rainfall": 2,
        "wind_speed": 8.862546906458292,
        "soil_moisture": 17.57989006513847,
        "ndvi": 0.592161771521136,
        "soil_temp": 21.98515557028538,
        "soil_humidity": 69.93075904816274
    },
    {
        "date": "2024-08-26 09:53:09.251267",
        "air_temp": 31.34887123476882,
        "humidity": 40.75553421072375,
        "rainfall": 1,
        "wind_speed": 7.523809375392442,
        "soil_moisture": 23.10331507969959,
        "ndvi": 0.702895520840652,
        "soil_temp": 10.543823431622911,
        "soil_humidity": 31.967559609280883
    },
    {
        "date": "2024-08-26 10:53:09.251267",
        "air_temp": 21.83634703395752,
        "humidity": 88.25076847272359,
        "rainfall": 0.5,
        "wind_speed": 7.346810305545599,
        "soil_moisture": 25.60542209700279,
        "ndvi": 0.5151213201384783,
        "soil_temp": 24.760272252945406,
        "soil_humidity": 47.645743974763064
    },
    {
        "date": "2024-08-26 11:53:09.251267",
        "air_temp": 20.188468668625852,
        "humidity": 52.54465228455513,
        "rainfall": 2,
        "wind_speed": 10.518931538029188,
        "soil_moisture": 18.84793407411593,
        "ndvi": 0.32499616811602383,
        "soil_temp": 16.82359904323596,
        "soil_humidity": 48.083740895615364
    },
    {
        "date": "2024-08-26 12:53:09.251267",
        "air_temp": 22.59384816334534,
        "humidity": 73.80131440262738,
        "rainfall": 2,
        "wind_speed": 16.175988890023014,
        "soil_moisture": 10.457945189380894,
        "ndvi": 0.5148130472560866,
        "soil_temp": 15.388467109325845,
        "soil_humidity": 42.02527714326702
    },
    {
        "date": "2024-08-26 13:53:09.251267",
        "air_temp": 26.805898850296153,
        "humidity": 75.33149349393548,
        "rainfall": 2,
        "wind_speed": 7.235366238451317,
        "soil_moisture": 16.6038773259258,
        "ndvi": 0.5330495608625907,
        "soil_temp": 14.390866567596579,
        "soil_humidity": 52.854062628277816
    },
    {
        "date": "2024-08-26 14:53:09.251267",
        "air_temp": 20.361272816457525,
        "humidity": 70.50037085397429,
        "rainfall": 1,
        "wind_speed": 10.485094410754733,
        "soil_moisture": 18.458106530252753,
        "ndvi": 0.645474538528134,
        "soil_temp": 12.615350614763074,
        "soil_humidity": 35.99908096606179
    },
    {
        "date": "2024-08-26 15:53:09.251267",
        "air_temp": 27.482978156982682,
        "humidity": 55.64536971068241,
        "rainfall": 0.5,
        "wind_speed": 13.414546508852016,
        "soil_moisture": 23.307991039545612,
        "ndvi": 0.33192979382440946,
        "soil_temp": 21.87360863395042,
        "soil_humidity": 61.35649522348935
    },
    {
        "date": "2024-08-26 16:53:09.251267",
        "air_temp": 23.18823304382481,
        "humidity": 53.554812688203285,
        "rainfall": 2,
        "wind_speed": 5.388788926073101,
        "soil_moisture": 15.133074352402057,
        "ndvi": 0.4209862292520391,
        "soil_temp": 24.33121247119145,
        "soil_humidity": 43.29790872990553
    },
    {
        "date": "2024-08-26 17:53:09.251267",
        "air_temp": 26.040943617039606,
        "humidity": 69.88341413513515,
        "rainfall": 0,
        "wind_speed": 5.550456098047851,
        "soil_moisture": 36.19763070075018,
        "ndvi": 0.41841648152873984,
        "soil_temp": 16.204671322680973,
        "soil_humidity": 53.65370359194567
    },
    {
        "date": "2024-08-26 18:53:09.251267",
        "air_temp": 23.722530582706334,
        "humidity": 83.30478168058457,
        "rainfall": 0.5,
        "wind_speed": 18.668262540469097,
        "soil_moisture": 31.147333981940083,
        "ndvi": 0.775663757444699,
        "soil_temp": 24.28631042130184,
        "soil_humidity": 58.62556944267837
    },
    {
        "date": "2024-08-26 19:53:09.251267",
        "air_temp": 20.889315190838353,
        "humidity": 87.33668644616978,
        "rainfall": 0,
        "wind_speed": 14.470691466621773,
        "soil_moisture": 12.55601432678986,
        "ndvi": 0.4663669761960314,
        "soil_temp": 22.356964231525513,
        "soil_humidity": 65.56168470760645
    },
    {
        "date": "2024-08-26 20:53:09.251267",
        "air_temp": 33.96906613924313,
        "humidity": 45.29529022018788,
        "rainfall": 0,
        "wind_speed": 11.543733524466177,
        "soil_moisture": 29.502773534197942,
        "ndvi": 0.653056380378888,
        "soil_temp": 17.215766912144773,
        "soil_humidity": 63.431497458088
    },
    {
        "date": "2024-08-26 21:53:09.251267",
        "air_temp": 30.272115883195216,
        "humidity": 47.74143062988599,
        "rainfall": 2,
        "wind_speed": 12.95440108841224,
        "soil_moisture": 29.609694292168612,
        "ndvi": 0.6504868288246635,
        "soil_temp": 20.11039268689199,
        "soil_humidity": 54.33236112414238
    },
    {
        "date": "2024-08-26 22:53:09.251267",
        "air_temp": 17.802263515329052,
        "humidity": 87.23681293915399,
        "rainfall": 2,
        "wind_speed": 0.5859888620683673,
        "soil_moisture": 38.68663520930883,
        "ndvi": 0.3593265108819449,
        "soil_temp": 16.524762687556944,
        "soil_humidity": 45.53572721535548
    },
    {
        "date": "2024-08-26 23:53:09.251267",
        "air_temp": 32.36935951795826,
        "humidity": 76.82676141673963,
        "rainfall": 1,
        "wind_speed": 12.362059311233937,
        "soil_moisture": 10.090626140684837,
        "ndvi": 0.5431218136230394,
        "soil_temp": 18.380851123188286,
        "soil_humidity": 50.128186802998556
    },
    {
        "date": "2024-08-27 00:53:09.251267",
        "air_temp": 24.748623964990273,
        "humidity": 84.14969124148865,
        "rainfall": 1,
        "wind_speed": 10.190177464484037,
        "soil_moisture": 17.437973494174216,
        "ndvi": 0.736581735991189,
        "soil_temp": 10.870695055277558,
        "soil_humidity": 60.7493917351521
    },
    {
        "date": "2024-08-27 01:53:09.251267",
        "air_temp": 32.891044537881825,
        "humidity": 50.13163170676716,
        "rainfall": 1,
        "wind_speed": 10.914993595588713,
        "soil_moisture": 37.833744381259464,
        "ndvi": 0.48511863167416974,
        "soil_temp": 20.443952129508,
        "soil_humidity": 65.10182170237891
    },
    {
        "date": "2024-08-27 02:53:09.251267",
        "air_temp": 30.997105118946305,
        "humidity": 69.3792935554701,
        "rainfall": 0.5,
        "wind_speed": 7.188476536616342,
        "soil_moisture": 37.96401746194849,
        "ndvi": 0.7670705044055277,
        "soil_temp": 10.79236966726205,
        "soil_humidity": 34.15127712706688
    },
    {
        "date": "2024-08-27 03:53:09.251267",
        "air_temp": 23.50427008938467,
        "humidity": 75.05697972087368,
        "rainfall": 1,
        "wind_speed": 1.6294779542588378,
        "soil_moisture": 31.617592581119123,
        "ndvi": 0.32867493759449695,
        "soil_temp": 22.256274203794742,
        "soil_humidity": 45.39425049972214
    },
    {
        "date": "2024-08-27 04:53:09.251267",
        "air_temp": 15.449386166402348,
        "humidity": 74.00559427234863,
        "rainfall": 0,
        "wind_speed": 3.8667846722410437,
        "soil_moisture": 32.9728996314262,
        "ndvi": 0.24449546346441847,
        "soil_temp": 22.374203941908362,
        "soil_humidity": 61.04466393654002
    },
    {
        "date": "2024-08-27 05:53:09.251267",
        "air_temp": 20.37354718769892,
        "humidity": 60.407584543334906,
        "rainfall": 1,
        "wind_speed": 19.014894264253435,
        "soil_moisture": 12.744488737889412,
        "ndvi": 0.7348723238868498,
        "soil_temp": 13.556159313343219,
        "soil_humidity": 47.26607050375844
    },
    {
        "date": "2024-08-27 06:53:09.251267",
        "air_temp": 25.83268429321734,
        "humidity": 40.76974458456542,
        "rainfall": 2,
        "wind_speed": 8.17399124541868,
        "soil_moisture": 25.416275043347007,
        "ndvi": 0.70860871877733,
        "soil_temp": 14.854941715537004,
        "soil_humidity": 43.28911056265135
    },
    {
        "date": "2024-08-27 07:53:09.251267",
        "air_temp": 27.669564396522944,
        "humidity": 69.14630284021315,
        "rainfall": 1,
        "wind_speed": 9.333663116617284,
        "soil_moisture": 16.947489738669983,
        "ndvi": 0.795553925560307,
        "soil_temp": 17.2196556783744,
        "soil_humidity": 46.45290787293078
    },
    {
        "date": "2024-08-27 08:53:09.251267",
        "air_temp": 20.157753708664046,
        "humidity": 52.65507695898044,
        "rainfall": 0.5,
        "wind_speed": 1.0541957970687799,
        "soil_moisture": 15.436541308408518,
        "ndvi": 0.6076360928674557,
        "soil_temp": 18.949943603987222,
        "soil_humidity": 54.11378987145403
    },
    {
        "date": "2024-08-27 09:53:09.251267",
        "air_temp": 17.78712148145648,
        "humidity": 62.51271230714452,
        "rainfall": 0.5,
        "wind_speed": 0.7513245908796118,
        "soil_moisture": 34.309098973699975,
        "ndvi": 0.7620127560002898,
        "soil_temp": 14.310304926129952,
        "soil_humidity": 69.99220576535205
    },
    {
        "date": "2024-08-27 10:53:09.251267",
        "air_temp": 31.69860473598598,
        "humidity": 87.87905075419087,
        "rainfall": 0,
        "wind_speed": 14.343628940935155,
        "soil_moisture": 14.051144100416423,
        "ndvi": 0.6961694150504666,
        "soil_temp": 18.887296069478875,
        "soil_humidity": 49.71969716418127
    },
    {
        "date": "2024-08-27 11:53:09.251267",
        "air_temp": 34.68804361407104,
        "humidity": 59.951765882121734,
        "rainfall": 0.5,
        "wind_speed": 10.761893282422225,
        "soil_moisture": 24.059549109891556,
        "ndvi": 0.7066557998720522,
        "soil_temp": 14.466806098439484,
        "soil_humidity": 38.339778998170424
    },
    {
        "date": "2024-08-27 12:53:09.251267",
        "air_temp": 25.513803646053717,
        "humidity": 81.99008033322133,
        "rainfall": 1,
        "wind_speed": 10.174119684325333,
        "soil_moisture": 38.157347187580996,
        "ndvi": 0.541484942272561,
        "soil_temp": 10.83236081606591,
        "soil_humidity": 36.51386301116379
    },
    {
        "date": "2024-08-27 13:53:09.251267",
        "air_temp": 18.43358571696607,
        "humidity": 49.42703028746256,
        "rainfall": 2,
        "wind_speed": 4.862490608453374,
        "soil_moisture": 15.574961666960277,
        "ndvi": 0.42941875875002655,
        "soil_temp": 10.037913247478635,
        "soil_humidity": 31.69398989905657
    },
    {
        "date": "2024-08-27 14:53:09.251267",
        "air_temp": 20.44614653038738,
        "humidity": 73.62302490125144,
        "rainfall": 0.5,
        "wind_speed": 14.895014632598444,
        "soil_moisture": 39.33980271214703,
        "ndvi": 0.7607348435259691,
        "soil_temp": 11.517265868776429,
        "soil_humidity": 52.352689396732856
    },
    {
        "date": "2024-08-27 15:53:09.251267",
        "air_temp": 15.367813530949334,
        "humidity": 88.85034772194717,
        "rainfall": 0,
        "wind_speed": 3.3647315821698376,
        "soil_moisture": 39.828639572831776,
        "ndvi": 0.4592703053695367,
        "soil_temp": 21.883760502460273,
        "soil_humidity": 51.78288365955337
    },
    {
        "date": "2024-08-27 16:53:09.251267",
        "air_temp": 33.28597613120998,
        "humidity": 45.09465504364684,
        "rainfall": 1,
        "wind_speed": 3.7986948732204007,
        "soil_moisture": 26.668514640472086,
        "ndvi": 0.5232916203498015,
        "soil_temp": 24.421469685750058,
        "soil_humidity": 38.20935707121237
    },
    {
        "date": "2024-08-27 17:53:09.251267",
        "air_temp": 17.355021657802823,
        "humidity": 40.4159971113596,
        "rainfall": 0,
        "wind_speed": 9.21424342696551,
        "soil_moisture": 15.104282205314737,
        "ndvi": 0.4676685842751127,
        "soil_temp": 16.40449298921829,
        "soil_humidity": 57.81317625810268
    },
    {
        "date": "2024-08-27 18:53:09.251267",
        "air_temp": 26.53032951028507,
        "humidity": 61.67913963163072,
        "rainfall": 0.5,
        "wind_speed": 5.725597242238818,
        "soil_moisture": 19.48314663822687,
        "ndvi": 0.5388909883761184,
        "soil_temp": 17.69025930174914,
        "soil_humidity": 59.64428166518723
    },
    {
        "date": "2024-08-27 19:53:09.251267",
        "air_temp": 20.48110441374412,
        "humidity": 44.63127415256836,
        "rainfall": 0.5,
        "wind_speed": 4.949259624662927,
        "soil_moisture": 22.734784980296055,
        "ndvi": 0.48230304739539637,
        "soil_temp": 12.202722536101392,
        "soil_humidity": 40.363928215284446
    },
    {
        "date": "2024-08-27 20:53:09.251267",
        "air_temp": 26.083560050315867,
        "humidity": 77.41921748381156,
        "rainfall": 0,
        "wind_speed": 12.904752891066632,
        "soil_moisture": 19.279726730746688,
        "ndvi": 0.22878053514768554,
        "soil_temp": 18.265470290202355,
        "soil_humidity": 43.76399056118946
    },
    {
        "date": "2024-08-27 21:53:09.251267",
        "air_temp": 28.028407767037287,
        "humidity": 85.72743319242795,
        "rainfall": 1,
        "wind_speed": 13.01943490437089,
        "soil_moisture": 18.419067396295475,
        "ndvi": 0.7292289116046193,
        "soil_temp": 12.169823530104653,
        "soil_humidity": 69.61886689389027
    },
    {
        "date": "2024-08-27 22:53:09.251267",
        "air_temp": 31.594836074144034,
        "humidity": 61.70104863543794,
        "rainfall": 0.5,
        "wind_speed": 16.490341395998584,
        "soil_moisture": 39.16689127649073,
        "ndvi": 0.46663629134946194,
        "soil_temp": 19.16821496798245,
        "soil_humidity": 44.97057871402937
    },
    {
        "date": "2024-08-27 23:53:09.251267",
        "air_temp": 19.128425435212066,
        "humidity": 52.93558210777205,
        "rainfall": 2,
        "wind_speed": 8.354171339459517,
        "soil_moisture": 14.60094482247237,
        "ndvi": 0.5533661891044093,
        "soil_temp": 23.998880013712807,
        "soil_humidity": 60.43206687024407
    },
    {
        "date": "2024-08-28 00:53:09.251267",
        "air_temp": 15.219916573169609,
        "humidity": 61.72016595033049,
        "rainfall": 0.5,
        "wind_speed": 1.4617018205230048,
        "soil_moisture": 31.999837261505867,
        "ndvi": 0.28351268165479226,
        "soil_temp": 12.935478089545594,
        "soil_humidity": 48.1090170651976
    },
    {
        "date": "2024-08-28 01:53:09.251267",
        "air_temp": 17.737712601376145,
        "humidity": 76.17239089250005,
        "rainfall": 1,
        "wind_speed": 5.473327698429154,
        "soil_moisture": 10.012308683175245,
        "ndvi": 0.2020327087895835,
        "soil_temp": 21.46845577085653,
        "soil_humidity": 61.73379311443904
    },
    {
        "date": "2024-08-28 02:53:09.251267",
        "air_temp": 33.0003728369621,
        "humidity": 40.45270807901855,
        "rainfall": 0.5,
        "wind_speed": 19.60010926393634,
        "soil_moisture": 27.885480082071304,
        "ndvi": 0.3471740561861858,
        "soil_temp": 10.35253634227486,
        "soil_humidity": 60.760929551305914
    },
    {
        "date": "2024-08-28 03:53:09.251267",
        "air_temp": 32.47780155125031,
        "humidity": 69.47269947423486,
        "rainfall": 0,
        "wind_speed": 11.925171504654042,
        "soil_moisture": 37.47829011862157,
        "ndvi": 0.6227516192800102,
        "soil_temp": 15.632458619824316,
        "soil_humidity": 56.71024100794142
    },
    {
        "date": "2024-08-28 04:53:09.251267",
        "air_temp": 26.948262043406167,
        "humidity": 70.66458424436895,
        "rainfall": 0.5,
        "wind_speed": 14.194741696020404,
        "soil_moisture": 18.63802282279657,
        "ndvi": 0.2595167180677272,
        "soil_temp": 11.661227617457635,
        "soil_humidity": 64.19113385182317
    },
    {
        "date": "2024-08-28 05:53:09.251267",
        "air_temp": 27.010337208673068,
        "humidity": 71.88441234479677,
        "rainfall": 0.5,
        "wind_speed": 0.14715659250829072,
        "soil_moisture": 15.565779257731576,
        "ndvi": 0.7453324642318808,
        "soil_temp": 15.28713162393549,
        "soil_humidity": 67.5687397951074
    },
    {
        "date": "2024-08-28 06:53:09.251267",
        "air_temp": 28.30073349092511,
        "humidity": 52.10110399904386,
        "rainfall": 1,
        "wind_speed": 10.364114643650417,
        "soil_moisture": 29.550393846352808,
        "ndvi": 0.32901354161371077,
        "soil_temp": 12.603053794583253,
        "soil_humidity": 53.199332818083356
    },
    {
        "date": "2024-08-28 07:53:09.251267",
        "air_temp": 18.507425572468993,
        "humidity": 75.70263616890634,
        "rainfall": 0,
        "wind_speed": 0.7505731506360624,
        "soil_moisture": 31.378042218951794,
        "ndvi": 0.3227561685750655,
        "soil_temp": 15.599574851843311,
        "soil_humidity": 39.49875628890983
    },
    {
        "date": "2024-08-28 08:53:09.251267",
        "air_temp": 33.2882389184992,
        "humidity": 44.56957431658882,
        "rainfall": 1,
        "wind_speed": 11.38460424182896,
        "soil_moisture": 30.64091556855944,
        "ndvi": 0.3089047769728973,
        "soil_temp": 16.262842091612956,
        "soil_humidity": 45.945158465763
    },
    {
        "date": "2024-08-28 09:53:09.251267",
        "air_temp": 23.375410497841465,
        "humidity": 49.96351682334004,
        "rainfall": 0.5,
        "wind_speed": 18.415416992721507,
        "soil_moisture": 19.579506400641222,
        "ndvi": 0.20838902778281887,
        "soil_temp": 14.508866841713775,
        "soil_humidity": 60.33222854053173
    },
    {
        "date": "2024-08-28 10:53:09.251267",
        "air_temp": 22.66277056498995,
        "humidity": 83.87350233272409,
        "rainfall": 2,
        "wind_speed": 19.368382610008158,
        "soil_moisture": 17.566072064779927,
        "ndvi": 0.6630832810869409,
        "soil_temp": 19.849329242096935,
        "soil_humidity": 56.03533940495478
    },
    {
        "date": "2024-08-28 11:53:09.251267",
        "air_temp": 25.37835410565675,
        "humidity": 76.93615753632832,
        "rainfall": 0.5,
        "wind_speed": 10.811053507778484,
        "soil_moisture": 38.67756399886133,
        "ndvi": 0.7250207187471551,
        "soil_temp": 23.782780768883647,
        "soil_humidity": 37.11193354260995
    },
    {
        "date": "2024-08-28 12:53:09.251267",
        "air_temp": 15.9393193355011,
        "humidity": 40.687277700567265,
        "rainfall": 1,
        "wind_speed": 9.164096105044319,
        "soil_moisture": 37.698985248097884,
        "ndvi": 0.5684976215697194,
        "soil_temp": 16.008930611579085,
        "soil_humidity": 32.04958164585699
    },
    {
        "date": "2024-08-28 13:53:09.251267",
        "air_temp": 18.32566737512159,
        "humidity": 52.41881301849001,
        "rainfall": 2,
        "wind_speed": 18.90034188496988,
        "soil_moisture": 23.719498296784607,
        "ndvi": 0.3129199890079174,
        "soil_temp": 12.885147347125322,
        "soil_humidity": 57.31846374168259
    },
    {
        "date": "2024-08-28 14:53:09.251267",
        "air_temp": 29.76067232852741,
        "humidity": 50.720324149658225,
        "rainfall": 1,
        "wind_speed": 13.472196639933902,
        "soil_moisture": 35.38581598463121,
        "ndvi": 0.3711667981645279,
        "soil_temp": 14.06084137293975,
        "soil_humidity": 40.04316096117381
    },
    {
        "date": "2024-08-28 15:53:09.251267",
        "air_temp": 16.65597335850253,
        "humidity": 53.54031640184863,
        "rainfall": 1,
        "wind_speed": 1.2979855907681892,
        "soil_moisture": 23.56970432902888,
        "ndvi": 0.48555601841897805,
        "soil_temp": 22.810012352445533,
        "soil_humidity": 49.986809749162134
    },
    {
        "date": "2024-08-28 16:53:09.251267",
        "air_temp": 27.063042189327764,
        "humidity": 52.377988513994325,
        "rainfall": 0,
        "wind_speed": 9.314511553349952,
        "soil_moisture": 22.731670542175312,
        "ndvi": 0.7726075709739806,
        "soil_temp": 18.57459584905222,
        "soil_humidity": 59.86987090050377
    },
    {
        "date": "2024-08-28 17:53:09.251267",
        "air_temp": 19.90698219362642,
        "humidity": 43.126314787142285,
        "rainfall": 2,
        "wind_speed": 4.804900369286078,
        "soil_moisture": 14.70288842797884,
        "ndvi": 0.34841082309064575,
        "soil_temp": 16.82516548460436,
        "soil_humidity": 52.506671226253324
    },
    {
        "date": "2024-08-28 18:53:09.251267",
        "air_temp": 22.785912280839533,
        "humidity": 62.94702582984102,
        "rainfall": 0.5,
        "wind_speed": 9.396086428973632,
        "soil_moisture": 22.792181694759186,
        "ndvi": 0.6389875423519329,
        "soil_temp": 12.2445508226339,
        "soil_humidity": 33.33210332447186
    },
    {
        "date": "2024-08-28 19:53:09.251267",
        "air_temp": 20.773874735413997,
        "humidity": 76.63667373930826,
        "rainfall": 1,
        "wind_speed": 9.109311440127234,
        "soil_moisture": 26.12250815799421,
        "ndvi": 0.46366893397575226,
        "soil_temp": 16.74590962485673,
        "soil_humidity": 37.42320947134222
    },
    {
        "date": "2024-08-28 20:53:09.251267",
        "air_temp": 22.113454329298982,
        "humidity": 70.33658623587182,
        "rainfall": 0.5,
        "wind_speed": 11.642896056294841,
        "soil_moisture": 10.675129822860594,
        "ndvi": 0.3787084090160131,
        "soil_temp": 14.1773800503608,
        "soil_humidity": 38.77312736123677
    },
    {
        "date": "2024-08-28 21:53:09.251267",
        "air_temp": 29.38091810368491,
        "humidity": 73.643583429904,
        "rainfall": 0,
        "wind_speed": 7.135409806985025,
        "soil_moisture": 23.323390155262192,
        "ndvi": 0.4735495622132683,
        "soil_temp": 24.147446917800238,
        "soil_humidity": 40.314570145793795
    },
    {
        "date": "2024-08-28 22:53:09.251267",
        "air_temp": 20.9424343124635,
        "humidity": 44.05745892681196,
        "rainfall": 0,
        "wind_speed": 3.285408989118561,
        "soil_moisture": 33.44068655185601,
        "ndvi": 0.24272403052473954,
        "soil_temp": 23.26105164123597,
        "soil_humidity": 52.61613567802017
    },
    {
        "date": "2024-08-28 23:53:09.251267",
        "air_temp": 26.328092805937942,
        "humidity": 87.57453550883936,
        "rainfall": 2,
        "wind_speed": 10.074388691799125,
        "soil_moisture": 30.401381691228096,
        "ndvi": 0.6792124225582176,
        "soil_temp": 11.25853812742297,
        "soil_humidity": 50.15863119301574
    },
    {
        "date": "2024-08-29 00:53:09.251267",
        "air_temp": 24.521008043981993,
        "humidity": 81.92459169215124,
        "rainfall": 0,
        "wind_speed": 16.60405768691524,
        "soil_moisture": 29.05470966591805,
        "ndvi": 0.3459811567897803,
        "soil_temp": 17.74980233891864,
        "soil_humidity": 38.12640153456836
    },
    {
        "date": "2024-08-29 01:53:09.251267",
        "air_temp": 28.273423307252962,
        "humidity": 80.25451592478314,
        "rainfall": 0,
        "wind_speed": 13.280244561352045,
        "soil_moisture": 37.28666285311607,
        "ndvi": 0.6352472739157824,
        "soil_temp": 13.375875182720081,
        "soil_humidity": 32.702193738227486
    },
    {
        "date": "2024-08-29 02:53:09.251267",
        "air_temp": 33.73659478649516,
        "humidity": 81.14919025208837,
        "rainfall": 0.5,
        "wind_speed": 6.767357318504317,
        "soil_moisture": 10.5409602947569,
        "ndvi": 0.24100902286891684,
        "soil_temp": 16.161656342864358,
        "soil_humidity": 36.857288178988725
    },
    {
        "date": "2024-08-29 03:53:09.251267",
        "air_temp": 29.651441944205004,
        "humidity": 86.63552540663807,
        "rainfall": 0,
        "wind_speed": 18.710460455177145,
        "soil_moisture": 26.711427585767026,
        "ndvi": 0.7730591336994117,
        "soil_temp": 23.152719973771845,
        "soil_humidity": 69.37525250474414
    },
    {
        "date": "2024-08-29 04:53:09.251267",
        "air_temp": 19.298807571815136,
        "humidity": 67.21269830568488,
        "rainfall": 0,
        "wind_speed": 18.24415142498364,
        "soil_moisture": 33.620465452291214,
        "ndvi": 0.3158089912434192,
        "soil_temp": 17.912717798328174,
        "soil_humidity": 48.74187799165889
    },
    {
        "date": "2024-08-29 05:53:09.251267",
        "air_temp": 15.623662701225694,
        "humidity": 50.0141008933813,
        "rainfall": 1,
        "wind_speed": 4.186967987681975,
        "soil_moisture": 26.24006148103802,
        "ndvi": 0.7296452457242484,
        "soil_temp": 15.013229400561396,
        "soil_humidity": 64.6938201880845
    },
    {
        "date": "2024-08-29 06:53:09.251267",
        "air_temp": 20.2452808859965,
        "humidity": 70.83918039262706,
        "rainfall": 0,
        "wind_speed": 0.7883517024072595,
        "soil_moisture": 19.716866577411373,
        "ndvi": 0.6856839257692607,
        "soil_temp": 13.118325311574957,
        "soil_humidity": 63.95009866675373
    },
    {
        "date": "2024-08-29 07:53:09.251267",
        "air_temp": 26.901558614005097,
        "humidity": 77.14407468339695,
        "rainfall": 1,
        "wind_speed": 14.826174167015244,
        "soil_moisture": 14.087516952759335,
        "ndvi": 0.7807159544488498,
        "soil_temp": 22.00487289109734,
        "soil_humidity": 50.46078229851495
    },
    {
        "date": "2024-08-29 08:53:09.251267",
        "air_temp": 16.028516269685007,
        "humidity": 76.89586966770128,
        "rainfall": 0,
        "wind_speed": 16.552205226983066,
        "soil_moisture": 36.62689215448574,
        "ndvi": 0.4867343965674498,
        "soil_temp": 19.367770230465208,
        "soil_humidity": 58.26750833842174
    },
    {
        "date": "2024-08-29 09:53:09.251267",
        "air_temp": 24.927324944024726,
        "humidity": 66.072451208344,
        "rainfall": 0,
        "wind_speed": 19.357291319027105,
        "soil_moisture": 24.12287177162809,
        "ndvi": 0.6364383763075709,
        "soil_temp": 17.020981906393466,
        "soil_humidity": 37.71008041650849
    },
    {
        "date": "2024-08-29 10:53:09.251267",
        "air_temp": 26.936856978337786,
        "humidity": 43.42293596667891,
        "rainfall": 2,
        "wind_speed": 17.796727881766216,
        "soil_moisture": 36.34345590957104,
        "ndvi": 0.4224263942226705,
        "soil_temp": 13.26226530794467,
        "soil_humidity": 57.53586819707633
    },
    {
        "date": "2024-08-29 11:53:09.251267",
        "air_temp": 21.684877816339217,
        "humidity": 58.555646921506394,
        "rainfall": 2,
        "wind_speed": 12.010695364128296,
        "soil_moisture": 27.57556367299505,
        "ndvi": 0.3106209428118163,
        "soil_temp": 15.013695612356969,
        "soil_humidity": 40.89538317053158
    },
    {
        "date": "2024-08-29 12:53:09.251267",
        "air_temp": 30.418244074916437,
        "humidity": 86.03833042316786,
        "rainfall": 1,
        "wind_speed": 10.601046784247616,
        "soil_moisture": 30.225763498450025,
        "ndvi": 0.46584555976006936,
        "soil_temp": 24.397651401077777,
        "soil_humidity": 63.28248387016603
    },
    {
        "date": "2024-08-29 13:53:09.251267",
        "air_temp": 17.131965062675437,
        "humidity": 69.2224454878713,
        "rainfall": 0,
        "wind_speed": 0.5568210252517258,
        "soil_moisture": 34.1278346313535,
        "ndvi": 0.29944940923307295,
        "soil_temp": 17.801349064087514,
        "soil_humidity": 33.3489653243564
    },
    {
        "date": "2024-08-29 14:53:09.251267",
        "air_temp": 16.502755634716173,
        "humidity": 66.91659716278568,
        "rainfall": 0.5,
        "wind_speed": 4.933128936895557,
        "soil_moisture": 29.77466633800403,
        "ndvi": 0.7892267736718304,
        "soil_temp": 16.010489218648793,
        "soil_humidity": 60.925546915411104
    },
    {
        "date": "2024-08-29 15:53:09.251267",
        "air_temp": 29.563775124072066,
        "humidity": 53.44267571910967,
        "rainfall": 2,
        "wind_speed": 9.192295034658528,
        "soil_moisture": 21.94164522036813,
        "ndvi": 0.2577478946844298,
        "soil_temp": 17.821330635224857,
        "soil_humidity": 33.99747626274478
    },
    {
        "date": "2024-08-29 16:53:09.251267",
        "air_temp": 24.90982632412397,
        "humidity": 58.422445995828944,
        "rainfall": 0.5,
        "wind_speed": 12.570078001671357,
        "soil_moisture": 25.40900151785953,
        "ndvi": 0.5535270340715723,
        "soil_temp": 21.68482758849413,
        "soil_humidity": 65.24756947577237
    },
    {
        "date": "2024-08-29 17:53:09.251267",
        "air_temp": 28.768047928554722,
        "humidity": 84.76730445765847,
        "rainfall": 2,
        "wind_speed": 11.145036666368213,
        "soil_moisture": 34.091365740276395,
        "ndvi": 0.5758783131524337,
        "soil_temp": 15.101634047543032,
        "soil_humidity": 42.16818072540015
    },
    {
        "date": "2024-08-29 18:53:09.251267",
        "air_temp": 23.696546772074925,
        "humidity": 73.3231523763418,
        "rainfall": 2,
        "wind_speed": 14.85271979363381,
        "soil_moisture": 21.209508891189525,
        "ndvi": 0.5228477257244326,
        "soil_temp": 12.680591813989132,
        "soil_humidity": 56.17573459322299
    },
    {
        "date": "2024-08-29 19:53:09.251267",
        "air_temp": 19.928040664782138,
        "humidity": 79.36935133761665,
        "rainfall": 0,
        "wind_speed": 5.595338617740817,
        "soil_moisture": 21.643643703054913,
        "ndvi": 0.5973115314271003,
        "soil_temp": 18.774041775085717,
        "soil_humidity": 49.50009114481119
    },
    {
        "date": "2024-08-29 20:53:09.251267",
        "air_temp": 31.382046353483993,
        "humidity": 62.717271153382754,
        "rainfall": 2,
        "wind_speed": 11.299052153049464,
        "soil_moisture": 12.870703481740033,
        "ndvi": 0.2697084650071346,
        "soil_temp": 19.97806155221601,
        "soil_humidity": 44.65212581049356
    },
    {
        "date": "2024-08-29 21:53:09.251267",
        "air_temp": 30.988317579379583,
        "humidity": 71.5123609728079,
        "rainfall": 0.5,
        "wind_speed": 1.7628448678158426,
        "soil_moisture": 37.43753305665243,
        "ndvi": 0.5330111145231746,
        "soil_temp": 20.690770044378244,
        "soil_humidity": 55.81767332733986
    },
    {
        "date": "2024-08-29 22:53:09.251267",
        "air_temp": 28.893929417088536,
        "humidity": 52.41918043716038,
        "rainfall": 2,
        "wind_speed": 8.27331047166414,
        "soil_moisture": 12.731089448363253,
        "ndvi": 0.372327542676417,
        "soil_temp": 23.229813861491944,
        "soil_humidity": 67.90091922204951
    },
    {
        "date": "2024-08-29 23:53:09.251267",
        "air_temp": 20.44290274459925,
        "humidity": 75.27304151417431,
        "rainfall": 1,
        "wind_speed": 15.00321665909202,
        "soil_moisture": 29.446275323456675,
        "ndvi": 0.7641056379806341,
        "soil_temp": 15.567611810336697,
        "soil_humidity": 31.650789894984875
    },
    {
        "date": "2024-08-30 00:53:09.251267",
        "air_temp": 26.804613337381742,
        "humidity": 61.38006668516687,
        "rainfall": 0,
        "wind_speed": 13.86801706858088,
        "soil_moisture": 22.39583436408393,
        "ndvi": 0.6572429920638355,
        "soil_temp": 11.130323443433376,
        "soil_humidity": 66.75411423532418
    },
    {
        "date": "2024-08-30 01:53:09.251267",
        "air_temp": 22.219477938800537,
        "humidity": 62.127275740737275,
        "rainfall": 0,
        "wind_speed": 19.81285474344358,
        "soil_moisture": 22.16858170766327,
        "ndvi": 0.7835086892958456,
        "soil_temp": 24.375228754464132,
        "soil_humidity": 54.36326620084054
    },
    {
        "date": "2024-08-30 02:53:09.251267",
        "air_temp": 16.831641466532684,
        "humidity": 72.46611622884409,
        "rainfall": 1,
        "wind_speed": 5.5578225197551205,
        "soil_moisture": 30.38655509718052,
        "ndvi": 0.22253364184411373,
        "soil_temp": 16.52517891156213,
        "soil_humidity": 62.292572030762095
    },
    {
        "date": "2024-08-30 03:53:09.251267",
        "air_temp": 33.34627150924486,
        "humidity": 86.81403107344633,
        "rainfall": 1,
        "wind_speed": 9.963785983479582,
        "soil_moisture": 12.012811978896362,
        "ndvi": 0.37107990510842526,
        "soil_temp": 19.348862645096972,
        "soil_humidity": 45.354998137371105
    },
    {
        "date": "2024-08-30 04:53:09.251267",
        "air_temp": 17.736372618379228,
        "humidity": 43.20031733043105,
        "rainfall": 1,
        "wind_speed": 6.590608275479934,
        "soil_moisture": 27.811173488543037,
        "ndvi": 0.3172535519830094,
        "soil_temp": 19.994579846924385,
        "soil_humidity": 48.87371899350455
    },
    {
        "date": "2024-08-30 05:53:09.251267",
        "air_temp": 34.00474707641605,
        "humidity": 81.23716186735038,
        "rainfall": 2,
        "wind_speed": 8.812062281183495,
        "soil_moisture": 33.160953579170645,
        "ndvi": 0.2066927523870983,
        "soil_temp": 18.19770563051805,
        "soil_humidity": 64.75403007204656
    },
    {
        "date": "2024-08-30 06:53:09.251267",
        "air_temp": 23.920115459159113,
        "humidity": 54.61915552060965,
        "rainfall": 0,
        "wind_speed": 17.139002597387762,
        "soil_moisture": 34.846382736597825,
        "ndvi": 0.3453954381477929,
        "soil_temp": 13.078862946044051,
        "soil_humidity": 46.59391879239293
    },
    {
        "date": "2024-08-30 07:53:09.251267",
        "air_temp": 18.702658576772393,
        "humidity": 62.195972725318896,
        "rainfall": 1,
        "wind_speed": 13.684181503769732,
        "soil_moisture": 15.115911681813259,
        "ndvi": 0.6072655901122574,
        "soil_temp": 21.327145751742123,
        "soil_humidity": 51.386382669963396
    },
    {
        "date": "2024-08-30 08:53:09.251267",
        "air_temp": 25.838018947567164,
        "humidity": 41.09566643875045,
        "rainfall": 2,
        "wind_speed": 17.648823826951634,
        "soil_moisture": 36.92257150234521,
        "ndvi": 0.3386533532807412,
        "soil_temp": 22.056279614561227,
        "soil_humidity": 30.807796923373317
    },
    {
        "date": "2024-08-30 09:53:09.251267",
        "air_temp": 32.45891671752817,
        "humidity": 55.05230389171565,
        "rainfall": 1,
        "wind_speed": 4.070222166637729,
        "soil_moisture": 29.684382755423265,
        "ndvi": 0.5054020672895709,
        "soil_temp": 18.2946337902616,
        "soil_humidity": 36.34392023352169
    },
    {
        "date": "2024-08-30 10:53:09.251267",
        "air_temp": 29.644497728191226,
        "humidity": 65.13156213150653,
        "rainfall": 0.5,
        "wind_speed": 17.46722235682876,
        "soil_moisture": 36.45564402462048,
        "ndvi": 0.7326908689789993,
        "soil_temp": 17.328417408772623,
        "soil_humidity": 55.66746031899199
    },
    {
        "date": "2024-08-30 11:53:09.251267",
        "air_temp": 31.131222957228996,
        "humidity": 42.80880386737195,
        "rainfall": 0.5,
        "wind_speed": 1.1952454835676884,
        "soil_moisture": 24.558421513305948,
        "ndvi": 0.6162547522094453,
        "soil_temp": 11.70907274691978,
        "soil_humidity": 58.67907604255508
    },
    {
        "date": "2024-08-30 12:53:09.251267",
        "air_temp": 28.175667334214346,
        "humidity": 64.55478646915826,
        "rainfall": 0,
        "wind_speed": 17.771985608743048,
        "soil_moisture": 26.26100035291606,
        "ndvi": 0.4367774419436192,
        "soil_temp": 11.259274922496758,
        "soil_humidity": 53.30403747137998
    },
    {
        "date": "2024-08-30 13:53:09.251267",
        "air_temp": 28.84553129035705,
        "humidity": 86.35553144292138,
        "rainfall": 2,
        "wind_speed": 6.581475589214558,
        "soil_moisture": 37.57114831086014,
        "ndvi": 0.42348136334726505,
        "soil_temp": 15.09347268345293,
        "soil_humidity": 35.73240145102443
    },
    {
        "date": "2024-08-30 14:53:09.251267",
        "air_temp": 31.983913031306386,
        "humidity": 45.26966090539026,
        "rainfall": 0.5,
        "wind_speed": 6.29175266828133,
        "soil_moisture": 36.997286300640155,
        "ndvi": 0.47344148840509087,
        "soil_temp": 14.662692332760898,
        "soil_humidity": 47.81621714311339
    },
    {
        "date": "2024-08-30 15:53:09.251267",
        "air_temp": 19.993360177183717,
        "humidity": 78.22203640377522,
        "rainfall": 0.5,
        "wind_speed": 10.241771533973278,
        "soil_moisture": 23.44482620107006,
        "ndvi": 0.30622486463594384,
        "soil_temp": 24.304846764449067,
        "soil_humidity": 44.203790654527594
    },
    {
        "date": "2024-08-30 16:53:09.251267",
        "air_temp": 24.788499272862808,
        "humidity": 60.48355266879963,
        "rainfall": 2,
        "wind_speed": 18.813803754464637,
        "soil_moisture": 18.23237894907045,
        "ndvi": 0.5804004604495929,
        "soil_temp": 21.754341136651448,
        "soil_humidity": 49.826453761403535
    },
    {
        "date": "2024-08-30 17:53:09.251267",
        "air_temp": 19.424188836392045,
        "humidity": 72.7586857262169,
        "rainfall": 1,
        "wind_speed": 0.9556272936281074,
        "soil_moisture": 21.795113036927923,
        "ndvi": 0.4184916914627633,
        "soil_temp": 19.8988644288859,
        "soil_humidity": 64.81316138216377
    },
    {
        "date": "2024-08-30 18:53:09.251267",
        "air_temp": 34.75336015993294,
        "humidity": 53.01182989926147,
        "rainfall": 2,
        "wind_speed": 7.044619501414906,
        "soil_moisture": 21.632929855892492,
        "ndvi": 0.7114192095572263,
        "soil_temp": 17.764721335264078,
        "soil_humidity": 61.034457546346076
    },
    {
        "date": "2024-08-30 19:53:09.251267",
        "air_temp": 33.88118679373227,
        "humidity": 47.97461312706791,
        "rainfall": 1,
        "wind_speed": 17.131439773666713,
        "soil_moisture": 18.60600624948382,
        "ndvi": 0.6539315960346899,
        "soil_temp": 12.51051296089977,
        "soil_humidity": 33.359580055566354
    },
    {
        "date": "2024-08-30 20:53:09.251267",
        "air_temp": 15.788536227370118,
        "humidity": 48.02313071356132,
        "rainfall": 0.5,
        "wind_speed": 7.921089548709334,
        "soil_moisture": 18.057388129988997,
        "ndvi": 0.566307898455296,
        "soil_temp": 24.676850082764258,
        "soil_humidity": 65.61236791098257
    },
    {
        "date": "2024-08-30 21:53:09.251267",
        "air_temp": 29.11150345031377,
        "humidity": 43.52459728215723,
        "rainfall": 0.5,
        "wind_speed": 3.98313795194134,
        "soil_moisture": 35.83188809439159,
        "ndvi": 0.41852133358970967,
        "soil_temp": 15.62561047360315,
        "soil_humidity": 48.87068604599294
    },
    {
        "date": "2024-08-30 22:53:09.251267",
        "air_temp": 33.50496634831332,
        "humidity": 49.28233524506215,
        "rainfall": 0.5,
        "wind_speed": 8.495883626628235,
        "soil_moisture": 12.09585762053985,
        "ndvi": 0.3259590990040907,
        "soil_temp": 11.603884036708672,
        "soil_humidity": 59.75448579543993
    },
    {
        "date": "2024-08-30 23:53:09.251267",
        "air_temp": 18.611506902546672,
        "humidity": 73.21096924944841,
        "rainfall": 0,
        "wind_speed": 7.711050029833386,
        "soil_moisture": 30.328335462987823,
        "ndvi": 0.6656911184633738,
        "soil_temp": 17.40698343573619,
        "soil_humidity": 35.60156925057409
    },
    {
        "date": "2024-08-31 00:53:09.251267",
        "air_temp": 26.358904611052587,
        "humidity": 84.08453059217541,
        "rainfall": 0,
        "wind_speed": 18.30832402933407,
        "soil_moisture": 15.184601543693569,
        "ndvi": 0.49184545939465196,
        "soil_temp": 23.10997017419625,
        "soil_humidity": 56.828781028281924
    },
    {
        "date": "2024-08-31 01:53:09.251267",
        "air_temp": 33.30976595176084,
        "humidity": 80.70628427955809,
        "rainfall": 0,
        "wind_speed": 15.238687514861942,
        "soil_moisture": 20.335176801821895,
        "ndvi": 0.3348500229064145,
        "soil_temp": 23.30936502665994,
        "soil_humidity": 39.45304070437832
    },
    {
        "date": "2024-08-31 02:53:09.251267",
        "air_temp": 15.678919571715976,
        "humidity": 74.25671549372422,
        "rainfall": 2,
        "wind_speed": 10.102311114880127,
        "soil_moisture": 11.846291024045106,
        "ndvi": 0.31710476902400375,
        "soil_temp": 10.164488740846709,
        "soil_humidity": 47.53523588563726
    },
    {
        "date": "2024-08-31 03:53:09.251267",
        "air_temp": 28.9484053449368,
        "humidity": 45.521593170555136,
        "rainfall": 0.5,
        "wind_speed": 19.767411334431422,
        "soil_moisture": 18.09920910566386,
        "ndvi": 0.29924054270081546,
        "soil_temp": 23.294436171270995,
        "soil_humidity": 30.674514803284175
    },
    {
        "date": "2024-08-31 04:53:09.251267",
        "air_temp": 20.946980147451015,
        "humidity": 54.45937231479749,
        "rainfall": 0.5,
        "wind_speed": 9.305550625518466,
        "soil_moisture": 33.052632133517264,
        "ndvi": 0.6662967177233571,
        "soil_temp": 14.072263262497877,
        "soil_humidity": 36.06442551721904
    },
    {
        "date": "2024-08-31 05:53:09.251267",
        "air_temp": 33.48792390753061,
        "humidity": 55.490343766123445,
        "rainfall": 2,
        "wind_speed": 1.1647566164055045,
        "soil_moisture": 36.55045973641776,
        "ndvi": 0.3373923111801871,
        "soil_temp": 10.584585459526346,
        "soil_humidity": 33.68321972494479
    },
    {
        "date": "2024-08-31 06:53:09.251267",
        "air_temp": 34.42116490330736,
        "humidity": 52.49784731988106,
        "rainfall": 0,
        "wind_speed": 5.245486436299698,
        "soil_moisture": 16.645814557071013,
        "ndvi": 0.5159853581151186,
        "soil_temp": 22.716854055443115,
        "soil_humidity": 39.58976691753034
    },
    {
        "date": "2024-08-31 07:53:09.251267",
        "air_temp": 33.88532978226868,
        "humidity": 65.75028298548615,
        "rainfall": 2,
        "wind_speed": 8.721409604386253,
        "soil_moisture": 23.906435177767655,
        "ndvi": 0.4709278165838339,
        "soil_temp": 22.958001339823124,
        "soil_humidity": 34.00072360097289
    },
    {
        "date": "2024-08-31 08:53:09.251267",
        "air_temp": 24.484284333149276,
        "humidity": 66.77769074398363,
        "rainfall": 0,
        "wind_speed": 3.138052482922402,
        "soil_moisture": 24.109721438116193,
        "ndvi": 0.5477665395369704,
        "soil_temp": 18.94346619763525,
        "soil_humidity": 57.676401064782475
    },
    {
        "date": "2024-08-31 09:53:09.251267",
        "air_temp": 32.24085301978627,
        "humidity": 57.84429875855551,
        "rainfall": 0.5,
        "wind_speed": 18.95130252648086,
        "soil_moisture": 36.20725917279202,
        "ndvi": 0.7262929402227167,
        "soil_temp": 18.616506359445374,
        "soil_humidity": 31.161596088368775
    },
    {
        "date": "2024-08-31 10:53:09.251267",
        "air_temp": 31.890987970701406,
        "humidity": 57.689457936821796,
        "rainfall": 1,
        "wind_speed": 15.336977956355462,
        "soil_moisture": 32.88397158627416,
        "ndvi": 0.7206484340490384,
        "soil_temp": 22.19680408932129,
        "soil_humidity": 54.70962002499992
    },
    {
        "date": "2024-08-31 11:53:09.251267",
        "air_temp": 21.382009464865114,
        "humidity": 81.42777989530407,
        "rainfall": 0,
        "wind_speed": 5.365744008555453,
        "soil_moisture": 25.392316551041496,
        "ndvi": 0.47064564309491586,
        "soil_temp": 21.794643290654196,
        "soil_humidity": 35.62831323302297
    },
    {
        "date": "2024-08-31 12:53:09.251267",
        "air_temp": 31.57830948301355,
        "humidity": 79.46464241598429,
        "rainfall": 0.5,
        "wind_speed": 11.816090670358317,
        "soil_moisture": 30.122416014153202,
        "ndvi": 0.209219750440834,
        "soil_temp": 23.35158210164056,
        "soil_humidity": 67.80645828711587
    },
    {
        "date": "2024-08-31 13:53:09.251267",
        "air_temp": 15.740152694309852,
        "humidity": 55.38979158403728,
        "rainfall": 0.5,
        "wind_speed": 14.428983142191441,
        "soil_moisture": 35.42065161728101,
        "ndvi": 0.45586628342858193,
        "soil_temp": 18.291211713950553,
        "soil_humidity": 67.32376292439417
    },
    {
        "date": "2024-08-31 14:53:09.251267",
        "air_temp": 26.92539756964106,
        "humidity": 85.68942219366296,
        "rainfall": 1,
        "wind_speed": 18.82557857813596,
        "soil_moisture": 10.286168620766349,
        "ndvi": 0.5637161224544843,
        "soil_temp": 24.51470639099213,
        "soil_humidity": 39.0201241008385
    },
    {
        "date": "2024-08-31 15:53:09.251267",
        "air_temp": 19.600176745754062,
        "humidity": 87.6407508501253,
        "rainfall": 0.5,
        "wind_speed": 17.632706983508214,
        "soil_moisture": 22.086700931501458,
        "ndvi": 0.5346588554588372,
        "soil_temp": 24.942202464000076,
        "soil_humidity": 54.57685192892118
    },
    {
        "date": "2024-08-31 16:53:09.251267",
        "air_temp": 17.411337715545578,
        "humidity": 56.33712710150204,
        "rainfall": 0.5,
        "wind_speed": 10.303844522764274,
        "soil_moisture": 37.217295018312356,
        "ndvi": 0.5908016634801234,
        "soil_temp": 11.74816914997949,
        "soil_humidity": 31.12021277889834
    },
    {
        "date": "2024-08-31 17:53:09.251267",
        "air_temp": 16.539064032584182,
        "humidity": 57.72124743965274,
        "rainfall": 0,
        "wind_speed": 15.493052395860351,
        "soil_moisture": 32.209390925318544,
        "ndvi": 0.2924454394838501,
        "soil_temp": 22.743156393311832,
        "soil_humidity": 67.27154172204696
    },
    {
        "date": "2024-08-31 18:53:09.251267",
        "air_temp": 28.925775517562794,
        "humidity": 65.28170767109793,
        "rainfall": 0,
        "wind_speed": 11.2428950765517,
        "soil_moisture": 26.28524123002224,
        "ndvi": 0.5879210592684396,
        "soil_temp": 17.497333866228836,
        "soil_humidity": 50.91949232766839
    },
    {
        "date": "2024-08-31 19:53:09.251267",
        "air_temp": 21.797499275361325,
        "humidity": 87.05604062668368,
        "rainfall": 1,
        "wind_speed": 11.054077800910802,
        "soil_moisture": 25.889335811054003,
        "ndvi": 0.30959097124626334,
        "soil_temp": 15.813495074497482,
        "soil_humidity": 31.72892605671116
    },
    {
        "date": "2024-08-31 20:53:09.251267",
        "air_temp": 29.49533543057523,
        "humidity": 83.81597165711557,
        "rainfall": 1,
        "wind_speed": 2.294839166763838,
        "soil_moisture": 20.43310292813313,
        "ndvi": 0.6503143884180494,
        "soil_temp": 19.196084648240724,
        "soil_humidity": 43.69999972032549
    },
    {
        "date": "2024-08-31 21:53:09.251267",
        "air_temp": 16.307126815978847,
        "humidity": 45.12839812917949,
        "rainfall": 0.5,
        "wind_speed": 2.5227531603230413,
        "soil_moisture": 25.615616009245436,
        "ndvi": 0.40345256795760803,
        "soil_temp": 10.839483358930105,
        "soil_humidity": 33.07397847444937
    },
    {
        "date": "2024-08-31 22:53:09.251267",
        "air_temp": 21.305806756612206,
        "humidity": 59.63653716357081,
        "rainfall": 0,
        "wind_speed": 10.59067230044915,
        "soil_moisture": 29.6614959839036,
        "ndvi": 0.6259372600288722,
        "soil_temp": 17.5969167815062,
        "soil_humidity": 45.717777574934395
    },
    {
        "date": "2024-08-31 23:53:09.251267",
        "air_temp": 25.789825847506744,
        "humidity": 67.6685594874886,
        "rainfall": 1,
        "wind_speed": 5.377230899580132,
        "soil_moisture": 22.55089115286379,
        "ndvi": 0.22871935934366427,
        "soil_temp": 23.0633585504,
        "soil_humidity": 56.79705368645293
    },
    {
        "date": "2024-09-01 00:53:09.251267",
        "air_temp": 30.814463296779273,
        "humidity": 65.156623627577,
        "rainfall": 1,
        "wind_speed": 0.42073804920937263,
        "soil_moisture": 31.42459439176522,
        "ndvi": 0.4447160153545481,
        "soil_temp": 13.201922742084532,
        "soil_humidity": 48.795596321657584
    },
    {
        "date": "2024-09-01 01:53:09.251267",
        "air_temp": 21.3750500586414,
        "humidity": 49.693153396844636,
        "rainfall": 2,
        "wind_speed": 14.456784078004246,
        "soil_moisture": 35.83696681781157,
        "ndvi": 0.6230207133579997,
        "soil_temp": 16.395848421541377,
        "soil_humidity": 45.348886988778176
    },
    {
        "date": "2024-09-01 02:53:09.251267",
        "air_temp": 27.51782752874018,
        "humidity": 82.94083855840773,
        "rainfall": 1,
        "wind_speed": 0.6497319338032992,
        "soil_moisture": 14.640545445545836,
        "ndvi": 0.3361223097988965,
        "soil_temp": 24.092213315723132,
        "soil_humidity": 34.76628182161859
    },
    {
        "date": "2024-09-01 03:53:09.251267",
        "air_temp": 32.71955496472374,
        "humidity": 73.84703914984186,
        "rainfall": 0.5,
        "wind_speed": 11.90553682208109,
        "soil_moisture": 23.546410684522733,
        "ndvi": 0.6771722268783336,
        "soil_temp": 16.42845756708546,
        "soil_humidity": 39.79756182774625
    },
    {
        "date": "2024-09-01 04:53:09.251267",
        "air_temp": 27.31726376364609,
        "humidity": 81.89541874259018,
        "rainfall": 0,
        "wind_speed": 10.783875882546287,
        "soil_moisture": 22.240602738552433,
        "ndvi": 0.5784678398629592,
        "soil_temp": 15.375514484985974,
        "soil_humidity": 34.005731509534044
    },
    {
        "date": "2024-09-01 05:53:09.251267",
        "air_temp": 19.659189495072674,
        "humidity": 82.93821396747362,
        "rainfall": 1,
        "wind_speed": 19.37629381155609,
        "soil_moisture": 30.623944121491842,
        "ndvi": 0.5525370632898791,
        "soil_temp": 16.608049721850673,
        "soil_humidity": 62.29994926626643
    },
    {
        "date": "2024-09-01 06:53:09.251267",
        "air_temp": 15.48801563113076,
        "humidity": 77.41779268328699,
        "rainfall": 2,
        "wind_speed": 9.684262786952832,
        "soil_moisture": 17.496023957737883,
        "ndvi": 0.4121293125226023,
        "soil_temp": 17.791791497763345,
        "soil_humidity": 35.4095931193889
    },
    {
        "date": "2024-09-01 07:53:09.251267",
        "air_temp": 32.401977478018594,
        "humidity": 61.960530062485866,
        "rainfall": 0,
        "wind_speed": 1.2720287275559472,
        "soil_moisture": 29.20752744856829,
        "ndvi": 0.4894708822286706,
        "soil_temp": 19.47987016197203,
        "soil_humidity": 53.81493466195574
    },
    {
        "date": "2024-09-01 08:53:09.251267",
        "air_temp": 15.425388217007743,
        "humidity": 70.52733775077122,
        "rainfall": 1,
        "wind_speed": 11.033759356129234,
        "soil_moisture": 36.324677251944635,
        "ndvi": 0.558409580586712,
        "soil_temp": 22.010392013750966,
        "soil_humidity": 43.709616543553835
    },
    {
        "date": "2024-09-01 09:53:09.251267",
        "air_temp": 32.49403345368399,
        "humidity": 48.01827030598716,
        "rainfall": 2,
        "wind_speed": 16.82192341232203,
        "soil_moisture": 22.54394799380868,
        "ndvi": 0.48347726834893434,
        "soil_temp": 16.781533086464712,
        "soil_humidity": 39.03913538620734
    },
    {
        "date": "2024-09-01 10:53:09.251267",
        "air_temp": 25.578742680544238,
        "humidity": 73.68227087374538,
        "rainfall": 1,
        "wind_speed": 19.936985685628798,
        "soil_moisture": 37.3304709993766,
        "ndvi": 0.6189879943643362,
        "soil_temp": 22.262214026352105,
        "soil_humidity": 55.57873757968415
    },
    {
        "date": "2024-09-01 11:53:09.251267",
        "air_temp": 33.781353970257925,
        "humidity": 48.96266278696404,
        "rainfall": 0,
        "wind_speed": 8.60686347378145,
        "soil_moisture": 39.79491216800435,
        "ndvi": 0.4602181598641758,
        "soil_temp": 18.00580992028121,
        "soil_humidity": 48.28009237507763
    },
    {
        "date": "2024-09-01 12:53:09.251267",
        "air_temp": 30.975664715473307,
        "humidity": 74.69747952550784,
        "rainfall": 0,
        "wind_speed": 6.40597484993485,
        "soil_moisture": 13.182761498438563,
        "ndvi": 0.3213639090452182,
        "soil_temp": 15.077207655519665,
        "soil_humidity": 46.005818691382366
    },
    {
        "date": "2024-09-01 13:53:09.251267",
        "air_temp": 34.95868221066675,
        "humidity": 51.479908037698436,
        "rainfall": 0,
        "wind_speed": 18.13306593903998,
        "soil_moisture": 16.991595639545757,
        "ndvi": 0.32836026968062837,
        "soil_temp": 19.869262421791213,
        "soil_humidity": 53.714080195688865
    },
    {
        "date": "2024-09-01 14:53:09.251267",
        "air_temp": 22.014236309034203,
        "humidity": 45.877565007510995,
        "rainfall": 0.5,
        "wind_speed": 3.8248425815224274,
        "soil_moisture": 38.511392544462794,
        "ndvi": 0.20348226130576466,
        "soil_temp": 20.76583382115237,
        "soil_humidity": 49.65070513960003
    },
    {
        "date": "2024-09-01 15:53:09.251267",
        "air_temp": 30.34376577862254,
        "humidity": 48.26422450714787,
        "rainfall": 1,
        "wind_speed": 12.308578300658532,
        "soil_moisture": 38.749830594441605,
        "ndvi": 0.3805746293352035,
        "soil_temp": 18.370734634442314,
        "soil_humidity": 42.13936834206243
    },
    {
        "date": "2024-09-01 16:53:09.251267",
        "air_temp": 23.03861827218484,
        "humidity": 40.09960676095351,
        "rainfall": 0.5,
        "wind_speed": 8.114288483618955,
        "soil_moisture": 12.931218261602755,
        "ndvi": 0.6493505842871119,
        "soil_temp": 10.849224597020852,
        "soil_humidity": 52.362451510610065
    },
    {
        "date": "2024-09-01 17:53:09.251267",
        "air_temp": 24.597512406078177,
        "humidity": 75.9369808926765,
        "rainfall": 0,
        "wind_speed": 0.8216472991111057,
        "soil_moisture": 10.4499550113141,
        "ndvi": 0.6764976253025659,
        "soil_temp": 12.548243127412373,
        "soil_humidity": 56.77994306202778
    },
    {
        "date": "2024-09-01 18:53:09.251267",
        "air_temp": 27.5501092643674,
        "humidity": 76.62005245291896,
        "rainfall": 2,
        "wind_speed": 6.945769486113955,
        "soil_moisture": 14.833000697692668,
        "ndvi": 0.32148052525745,
        "soil_temp": 14.279465813353422,
        "soil_humidity": 31.06772634347417
    },
    {
        "date": "2024-09-01 19:53:09.251267",
        "air_temp": 32.47354228372677,
        "humidity": 65.74666749566298,
        "rainfall": 1,
        "wind_speed": 5.304570450053491,
        "soil_moisture": 39.88038350146745,
        "ndvi": 0.7754263981228446,
        "soil_temp": 14.239384089272397,
        "soil_humidity": 48.63486915630702
    },
    {
        "date": "2024-09-01 20:53:09.251267",
        "air_temp": 34.681669383985906,
        "humidity": 48.06814005276918,
        "rainfall": 0.5,
        "wind_speed": 13.721823078869736,
        "soil_moisture": 15.399988498320607,
        "ndvi": 0.5014416760880761,
        "soil_temp": 20.101312003703057,
        "soil_humidity": 32.30733138334175
    },
    {
        "date": "2024-09-01 21:53:09.251267",
        "air_temp": 30.365468277290365,
        "humidity": 44.177716875534486,
        "rainfall": 1,
        "wind_speed": 18.975809953838365,
        "soil_moisture": 25.076428201660693,
        "ndvi": 0.7501872494111017,
        "soil_temp": 10.275229925104489,
        "soil_humidity": 37.45621656272444
    },
    {
        "date": "2024-09-01 22:53:09.251267",
        "air_temp": 23.355335643346677,
        "humidity": 40.95644832186435,
        "rainfall": 2,
        "wind_speed": 5.360943747572755,
        "soil_moisture": 35.45253179291533,
        "ndvi": 0.6240591691169788,
        "soil_temp": 21.335226655742797,
        "soil_humidity": 52.42034936311892
    },
    {
        "date": "2024-09-01 23:53:09.251267",
        "air_temp": 23.427140045541403,
        "humidity": 48.28740884826104,
        "rainfall": 0.5,
        "wind_speed": 16.229318783033634,
        "soil_moisture": 10.61204832064619,
        "ndvi": 0.6334600538134479,
        "soil_temp": 13.980137122103997,
        "soil_humidity": 64.64755661062323
    },
    {
        "date": "2024-09-02 00:53:09.251267",
        "air_temp": 29.75164603177783,
        "humidity": 84.54277674915733,
        "rainfall": 0,
        "wind_speed": 1.1071963387681905,
        "soil_moisture": 28.134669467753785,
        "ndvi": 0.2510261727836427,
        "soil_temp": 14.650327693241536,
        "soil_humidity": 65.31222716753939
    },
    {
        "date": "2024-09-02 01:53:09.251267",
        "air_temp": 19.775542915366046,
        "humidity": 52.07684208508409,
        "rainfall": 0.5,
        "wind_speed": 10.762420200796264,
        "soil_moisture": 18.31076346608873,
        "ndvi": 0.3319946761381585,
        "soil_temp": 17.918647419158866,
        "soil_humidity": 32.79776040554525
    },
    {
        "date": "2024-09-02 02:53:09.251267",
        "air_temp": 17.209482262627894,
        "humidity": 57.71356636086078,
        "rainfall": 1,
        "wind_speed": 1.4110012741978917,
        "soil_moisture": 35.87004749319195,
        "ndvi": 0.2052888999766836,
        "soil_temp": 13.238884014084363,
        "soil_humidity": 46.76420345146826
    },
    {
        "date": "2024-09-02 03:53:09.251267",
        "air_temp": 22.09244315281553,
        "humidity": 45.26727422406666,
        "rainfall": 1,
        "wind_speed": 18.680659822612785,
        "soil_moisture": 18.503834477922375,
        "ndvi": 0.6296765842860426,
        "soil_temp": 23.267069703632913,
        "soil_humidity": 53.320259320182046
    },
    {
        "date": "2024-09-02 04:53:09.251267",
        "air_temp": 20.744779833081637,
        "humidity": 51.118065237408516,
        "rainfall": 1,
        "wind_speed": 15.327025611147803,
        "soil_moisture": 30.602671608457268,
        "ndvi": 0.406662973057106,
        "soil_temp": 11.28053168989848,
        "soil_humidity": 35.882796182013735
    },
    {
        "date": "2024-09-02 05:53:09.251267",
        "air_temp": 20.9261624091198,
        "humidity": 65.96122853901025,
        "rainfall": 1,
        "wind_speed": 13.158998224091846,
        "soil_moisture": 30.226588973421908,
        "ndvi": 0.6719915096458082,
        "soil_temp": 16.665735434515973,
        "soil_humidity": 60.61728866979087
    },
    {
        "date": "2024-09-02 06:53:09.251267",
        "air_temp": 19.672155020998197,
        "humidity": 70.38763415478263,
        "rainfall": 0,
        "wind_speed": 16.477927632493284,
        "soil_moisture": 16.644806465789202,
        "ndvi": 0.4033617319622158,
        "soil_temp": 22.74133404182335,
        "soil_humidity": 59.76941033748955
    },
    {
        "date": "2024-09-02 07:53:09.251267",
        "air_temp": 15.841863792727239,
        "humidity": 52.26761570831055,
        "rainfall": 0.5,
        "wind_speed": 12.950395911403263,
        "soil_moisture": 11.043975996050504,
        "ndvi": 0.6916002513793167,
        "soil_temp": 19.23765841631854,
        "soil_humidity": 66.13364054827179
    },
    {
        "date": "2024-09-02 08:53:09.251267",
        "air_temp": 15.357478694668277,
        "humidity": 42.88660796125586,
        "rainfall": 0,
        "wind_speed": 19.37888794348582,
        "soil_moisture": 22.27748721356192,
        "ndvi": 0.2784189522489878,
        "soil_temp": 14.384942737639502,
        "soil_humidity": 30.33944048333772
    },
    {
        "date": "2024-09-02 09:53:09.251267",
        "air_temp": 34.75444779472063,
        "humidity": 59.55164345636993,
        "rainfall": 0,
        "wind_speed": 14.27915895729543,
        "soil_moisture": 18.67579455897609,
        "ndvi": 0.3040238940545049,
        "soil_temp": 16.31140311664691,
        "soil_humidity": 38.6673697595601
    },
    {
        "date": "2024-09-02 10:53:09.251267",
        "air_temp": 23.555462674717248,
        "humidity": 51.71016854631671,
        "rainfall": 0,
        "wind_speed": 18.493172191644934,
        "soil_moisture": 34.26933248841126,
        "ndvi": 0.4045259237503619,
        "soil_temp": 20.60041570513772,
        "soil_humidity": 63.08746856981287
    },
    {
        "date": "2024-09-02 11:53:09.251267",
        "air_temp": 22.686532943193633,
        "humidity": 50.9776961614901,
        "rainfall": 1,
        "wind_speed": 10.34263784021276,
        "soil_moisture": 34.01092565366036,
        "ndvi": 0.44509243332936405,
        "soil_temp": 24.59891115045849,
        "soil_humidity": 56.823202033077415
    },
    {
        "date": "2024-09-02 12:53:09.251267",
        "air_temp": 28.5929456538614,
        "humidity": 87.99314013567276,
        "rainfall": 1,
        "wind_speed": 2.341288259637253,
        "soil_moisture": 23.710718565778762,
        "ndvi": 0.40922168650578705,
        "soil_temp": 23.397080775691503,
        "soil_humidity": 65.77559414793963
    },
    {
        "date": "2024-09-02 13:53:09.251267",
        "air_temp": 19.365077757301282,
        "humidity": 70.8150242962862,
        "rainfall": 1,
        "wind_speed": 10.563554428460343,
        "soil_moisture": 28.19536675969657,
        "ndvi": 0.6581882195139319,
        "soil_temp": 15.136492125735652,
        "soil_humidity": 32.28718618121066
    },
    {
        "date": "2024-09-02 14:53:09.251267",
        "air_temp": 33.99922367900451,
        "humidity": 67.8439221586048,
        "rainfall": 0.5,
        "wind_speed": 2.537456561261866,
        "soil_moisture": 28.127785479607756,
        "ndvi": 0.2581708355760288,
        "soil_temp": 24.97296818931786,
        "soil_humidity": 53.878558628657714
    },
    {
        "date": "2024-09-02 15:53:09.251267",
        "air_temp": 30.726900288311043,
        "humidity": 60.78875517616609,
        "rainfall": 2,
        "wind_speed": 11.8498695835281,
        "soil_moisture": 34.814744452828705,
        "ndvi": 0.4107302117830929,
        "soil_temp": 10.052569952965873,
        "soil_humidity": 52.76186299574697
    },
    {
        "date": "2024-09-02 16:53:09.251267",
        "air_temp": 16.78822004624519,
        "humidity": 61.44721667150721,
        "rainfall": 1,
        "wind_speed": 9.649861210553258,
        "soil_moisture": 22.750072423695926,
        "ndvi": 0.7023439997092757,
        "soil_temp": 10.414446337126225,
        "soil_humidity": 31.426976654421296
    },
    {
        "date": "2024-09-02 17:53:09.251267",
        "air_temp": 23.35161551569857,
        "humidity": 67.04525019190405,
        "rainfall": 0.5,
        "wind_speed": 12.451507956655634,
        "soil_moisture": 18.263400393715273,
        "ndvi": 0.2400782463573032,
        "soil_temp": 22.35760772756791,
        "soil_humidity": 50.54088307649934
    },
    {
        "date": "2024-09-02 18:53:09.251267",
        "air_temp": 32.58236615124329,
        "humidity": 74.82149035180535,
        "rainfall": 1,
        "wind_speed": 0.20093962109663188,
        "soil_moisture": 17.951047544216422,
        "ndvi": 0.7654061046028147,
        "soil_temp": 12.991940302495781,
        "soil_humidity": 54.38081245239697
    },
    {
        "date": "2024-09-02 19:53:09.251267",
        "air_temp": 33.8946404458282,
        "humidity": 75.10730480909147,
        "rainfall": 1,
        "wind_speed": 3.896809833107282,
        "soil_moisture": 26.085768378466124,
        "ndvi": 0.7917901053632079,
        "soil_temp": 10.911539025943613,
        "soil_humidity": 37.65172696808429
    },
    {
        "date": "2024-09-02 20:53:09.251267",
        "air_temp": 24.348030224997395,
        "humidity": 48.58469538527248,
        "rainfall": 0,
        "wind_speed": 9.826608638242618,
        "soil_moisture": 23.474446081966377,
        "ndvi": 0.2531409042950481,
        "soil_temp": 13.557221098547533,
        "soil_humidity": 40.933183324986516
    },
    {
        "date": "2024-09-02 21:53:09.251267",
        "air_temp": 27.268227784214154,
        "humidity": 65.00563760383135,
        "rainfall": 0,
        "wind_speed": 19.010071231782696,
        "soil_moisture": 23.203623129548085,
        "ndvi": 0.3747635133534422,
        "soil_temp": 16.391502309712905,
        "soil_humidity": 55.61389385190948
    },
    {
        "date": "2024-09-02 22:53:09.251267",
        "air_temp": 18.340678921841505,
        "humidity": 60.5979047517051,
        "rainfall": 0,
        "wind_speed": 7.672180807103168,
        "soil_moisture": 12.734120172691217,
        "ndvi": 0.4234403996212137,
        "soil_temp": 20.958494056501074,
        "soil_humidity": 48.73203790399833
    },
    {
        "date": "2024-09-02 23:53:09.251267",
        "air_temp": 34.82337252273953,
        "humidity": 83.52634831346921,
        "rainfall": 0.5,
        "wind_speed": 8.487440521072694,
        "soil_moisture": 31.55811222070587,
        "ndvi": 0.6658348211916357,
        "soil_temp": 19.365032898268083,
        "soil_humidity": 64.32054630273308
    },
    {
        "date": "2024-09-03 00:53:09.251267",
        "air_temp": 19.63343402766887,
        "humidity": 71.57094606091883,
        "rainfall": 2,
        "wind_speed": 14.293921780132443,
        "soil_moisture": 16.41210063945732,
        "ndvi": 0.5575560872700404,
        "soil_temp": 13.564318263406307,
        "soil_humidity": 43.69527064036753
    },
    {
        "date": "2024-09-03 01:53:09.251267",
        "air_temp": 33.85463548270251,
        "humidity": 66.62520332079909,
        "rainfall": 2,
        "wind_speed": 12.075855145722361,
        "soil_moisture": 15.908608906261641,
        "ndvi": 0.5866194202955405,
        "soil_temp": 17.549056721508766,
        "soil_humidity": 40.666386335528045
    },
    {
        "date": "2024-09-03 02:53:09.251267",
        "air_temp": 27.992932979847367,
        "humidity": 45.76973210894587,
        "rainfall": 0.5,
        "wind_speed": 5.978523001966664,
        "soil_moisture": 22.382749608321802,
        "ndvi": 0.6545058936911037,
        "soil_temp": 23.89321158097853,
        "soil_humidity": 69.39463761379372
    },
    {
        "date": "2024-09-03 03:53:09.251267",
        "air_temp": 27.154735897577183,
        "humidity": 70.28189446617057,
        "rainfall": 1,
        "wind_speed": 8.216327911348555,
        "soil_moisture": 16.833350248534042,
        "ndvi": 0.27078739966221543,
        "soil_temp": 22.225116654756366,
        "soil_humidity": 66.44543080520333
    },
    {
        "date": "2024-09-03 04:53:09.251267",
        "air_temp": 25.25377022033017,
        "humidity": 45.860195284011674,
        "rainfall": 0.5,
        "wind_speed": 4.353995977144649,
        "soil_moisture": 27.515748019240462,
        "ndvi": 0.6999256741461575,
        "soil_temp": 14.8478467724769,
        "soil_humidity": 31.159709799959195
    },
    {
        "date": "2024-09-03 05:53:09.251267",
        "air_temp": 19.61339623435517,
        "humidity": 56.8603287782876,
        "rainfall": 1,
        "wind_speed": 19.320429182604173,
        "soil_moisture": 21.143900180034144,
        "ndvi": 0.6233718825080865,
        "soil_temp": 24.733968420335444,
        "soil_humidity": 60.82914461226264
    },
    {
        "date": "2024-09-03 06:53:09.251267",
        "air_temp": 18.530560640110167,
        "humidity": 47.14209824071659,
        "rainfall": 0.5,
        "wind_speed": 2.6126876311471947,
        "soil_moisture": 23.467351677783704,
        "ndvi": 0.5464050351178222,
        "soil_temp": 20.629306006526967,
        "soil_humidity": 34.41339095300812
    },
    {
        "date": "2024-09-03 07:53:09.251267",
        "air_temp": 19.409724181403508,
        "humidity": 74.60368235121845,
        "rainfall": 0.5,
        "wind_speed": 7.436735691328389,
        "soil_moisture": 26.115079648120865,
        "ndvi": 0.7338412008698341,
        "soil_temp": 10.936740335001726,
        "soil_humidity": 31.099407793174837
    },
    {
        "date": "2024-09-03 08:53:09.251267",
        "air_temp": 18.72876524288509,
        "humidity": 50.31260569180763,
        "rainfall": 0.5,
        "wind_speed": 0.5167794444002238,
        "soil_moisture": 16.106065674454143,
        "ndvi": 0.6201838123302843,
        "soil_temp": 10.52741069932958,
        "soil_humidity": 40.94782063865395
    },
    {
        "date": "2024-09-03 09:53:09.251267",
        "air_temp": 30.591689471335066,
        "humidity": 59.5929686594013,
        "rainfall": 2,
        "wind_speed": 4.4355898386709285,
        "soil_moisture": 14.672548685965893,
        "ndvi": 0.3210199775786153,
        "soil_temp": 12.52611923515045,
        "soil_humidity": 40.77383516244417
    },
    {
        "date": "2024-09-03 10:53:09.251267",
        "air_temp": 22.002505183334602,
        "humidity": 84.78373888093893,
        "rainfall": 0,
        "wind_speed": 15.609422767603098,
        "soil_moisture": 36.41265708620814,
        "ndvi": 0.45784722864540006,
        "soil_temp": 20.9178380207522,
        "soil_humidity": 62.77275049387859
    },
    {
        "date": "2024-09-03 11:53:09.251267",
        "air_temp": 16.156853531267927,
        "humidity": 50.21581916994852,
        "rainfall": 0.5,
        "wind_speed": 4.023504645777589,
        "soil_moisture": 23.011711084942633,
        "ndvi": 0.49642572016144004,
        "soil_temp": 12.756083887636418,
        "soil_humidity": 58.426689837713404
    },
    {
        "date": "2024-09-03 12:53:09.251267",
        "air_temp": 34.38205260281623,
        "humidity": 65.39025319793896,
        "rainfall": 2,
        "wind_speed": 1.601268050579312,
        "soil_moisture": 22.362808288976176,
        "ndvi": 0.2331305760502434,
        "soil_temp": 17.6723015381325,
        "soil_humidity": 69.26437709328057
    },
    {
        "date": "2024-09-03 13:53:09.251267",
        "air_temp": 32.67571769926852,
        "humidity": 60.9635830583011,
        "rainfall": 0,
        "wind_speed": 9.489063596890466,
        "soil_moisture": 11.068364508185244,
        "ndvi": 0.7727825080160335,
        "soil_temp": 11.398901802712476,
        "soil_humidity": 58.46051601247274
    },
    {
        "date": "2024-09-03 14:53:09.251267",
        "air_temp": 33.55504566390426,
        "humidity": 40.90620205228649,
        "rainfall": 0,
        "wind_speed": 0.1993846946903699,
        "soil_moisture": 24.939289077621797,
        "ndvi": 0.27714635647812713,
        "soil_temp": 23.813292367452927,
        "soil_humidity": 46.73022213024191
    },
    {
        "date": "2024-09-03 15:53:09.251267",
        "air_temp": 34.8981564529287,
        "humidity": 79.63790567864064,
        "rainfall": 0.5,
        "wind_speed": 1.6953230885266635,
        "soil_moisture": 26.625091648330002,
        "ndvi": 0.6184767603360362,
        "soil_temp": 15.159409203753514,
        "soil_humidity": 57.957106437808676
    },
    {
        "date": "2024-09-03 16:53:09.251267",
        "air_temp": 18.477904984389745,
        "humidity": 43.452295315729984,
        "rainfall": 1,
        "wind_speed": 16.1525173912595,
        "soil_moisture": 26.765226128639995,
        "ndvi": 0.5037386216545281,
        "soil_temp": 23.621980848409464,
        "soil_humidity": 69.97836268745608
    },
    {
        "date": "2024-09-03 17:53:09.251267",
        "air_temp": 22.924840378053176,
        "humidity": 63.71275104884768,
        "rainfall": 1,
        "wind_speed": 15.429466897192128,
        "soil_moisture": 31.21093507968653,
        "ndvi": 0.21537934993065355,
        "soil_temp": 24.173134628658165,
        "soil_humidity": 58.40567530250112
    },
    {
        "date": "2024-09-03 18:53:09.251267",
        "air_temp": 30.164769514081826,
        "humidity": 68.03391474694749,
        "rainfall": 1,
        "wind_speed": 18.30277640809792,
        "soil_moisture": 15.324455049730354,
        "ndvi": 0.754848485518617,
        "soil_temp": 10.091929709705212,
        "soil_humidity": 60.467254239212735
    },
    {
        "date": "2024-09-03 19:53:09.251267",
        "air_temp": 28.920412361075844,
        "humidity": 71.42132283072935,
        "rainfall": 0.5,
        "wind_speed": 17.09030871821499,
        "soil_moisture": 17.566694026918565,
        "ndvi": 0.7111020852913601,
        "soil_temp": 16.96202780798098,
        "soil_humidity": 67.53460060761037
    },
    {
        "date": "2024-09-03 20:53:09.251267",
        "air_temp": 18.0779181267971,
        "humidity": 74.43158146539378,
        "rainfall": 1,
        "wind_speed": 13.663511264796362,
        "soil_moisture": 34.32191395691238,
        "ndvi": 0.5145547567423014,
        "soil_temp": 10.369840835603393,
        "soil_humidity": 34.546050452467334
    },
    {
        "date": "2024-09-03 21:53:09.251267",
        "air_temp": 31.316662499812356,
        "humidity": 52.663619176135754,
        "rainfall": 2,
        "wind_speed": 1.0466571460132568,
        "soil_moisture": 38.41511850530182,
        "ndvi": 0.3373654016836672,
        "soil_temp": 23.630391494543588,
        "soil_humidity": 58.19137173790907
    },
    {
        "date": "2024-09-03 22:53:09.251267",
        "air_temp": 19.488811436733215,
        "humidity": 40.49893883292247,
        "rainfall": 2,
        "wind_speed": 15.65697235973315,
        "soil_moisture": 14.07798242101962,
        "ndvi": 0.27297373440805656,
        "soil_temp": 17.776113171018636,
        "soil_humidity": 64.03223831869795
    },
    {
        "date": "2024-09-03 23:53:09.251267",
        "air_temp": 19.47635229645246,
        "humidity": 76.17218311774559,
        "rainfall": 2,
        "wind_speed": 16.910216216811207,
        "soil_moisture": 24.929486635156547,
        "ndvi": 0.418035487659276,
        "soil_temp": 11.124160190477452,
        "soil_humidity": 68.17514400883573
    },
    {
        "date": "2024-09-04 00:53:09.251267",
        "air_temp": 25.73948845786827,
        "humidity": 66.78282996737893,
        "rainfall": 2,
        "wind_speed": 15.981642915439089,
        "soil_moisture": 16.425287582187607,
        "ndvi": 0.3532569686276543,
        "soil_temp": 11.654150924148503,
        "soil_humidity": 38.43877158846797
    },
    {
        "date": "2024-09-04 01:53:09.251267",
        "air_temp": 26.858798696834064,
        "humidity": 81.80588516036244,
        "rainfall": 0,
        "wind_speed": 10.169580706160522,
        "soil_moisture": 11.802195050649473,
        "ndvi": 0.5040147206037793,
        "soil_temp": 15.752635367391086,
        "soil_humidity": 50.157160308969765
    },
    {
        "date": "2024-09-04 02:53:09.251267",
        "air_temp": 26.601724156756223,
        "humidity": 81.06941814503472,
        "rainfall": 2,
        "wind_speed": 4.658036772989633,
        "soil_moisture": 19.76510014378406,
        "ndvi": 0.7798455776740574,
        "soil_temp": 24.10351289309334,
        "soil_humidity": 57.22219390577927
    },
    {
        "date": "2024-09-04 03:53:09.251267",
        "air_temp": 16.829736747955096,
        "humidity": 82.16630838939416,
        "rainfall": 1,
        "wind_speed": 16.906599513774534,
        "soil_moisture": 14.310628755526382,
        "ndvi": 0.5449720959377848,
        "soil_temp": 20.393637273521215,
        "soil_humidity": 39.8987091800535
    },
    {
        "date": "2024-09-04 04:53:09.251267",
        "air_temp": 32.549217252607605,
        "humidity": 64.254732998977,
        "rainfall": 0.5,
        "wind_speed": 14.543256194802769,
        "soil_moisture": 31.297992203027388,
        "ndvi": 0.7339586335340589,
        "soil_temp": 23.014282992836986,
        "soil_humidity": 54.01891453001096
    },
    {
        "date": "2024-09-04 05:53:09.251267",
        "air_temp": 20.312000851774123,
        "humidity": 56.68141979184328,
        "rainfall": 1,
        "wind_speed": 19.61522348777703,
        "soil_moisture": 26.011762410143334,
        "ndvi": 0.5905273024974664,
        "soil_temp": 15.496849695099694,
        "soil_humidity": 40.303151299919975
    },
    {
        "date": "2024-09-04 06:53:09.251267",
        "air_temp": 17.590298425656083,
        "humidity": 79.5779101295685,
        "rainfall": 2,
        "wind_speed": 3.1138519551186583,
        "soil_moisture": 14.315034129709327,
        "ndvi": 0.4851479159523203,
        "soil_temp": 10.648205493249975,
        "soil_humidity": 43.33828082126589
    },
    {
        "date": "2024-09-04 07:53:09.251267",
        "air_temp": 32.774961597379544,
        "humidity": 62.564705651462745,
        "rainfall": 0,
        "wind_speed": 4.513291661041102,
        "soil_moisture": 10.277247652165432,
        "ndvi": 0.7127645376400229,
        "soil_temp": 24.614764558066213,
        "soil_humidity": 47.660747860434604
    },
    {
        "date": "2024-09-04 08:53:09.251267",
        "air_temp": 34.11302996459507,
        "humidity": 49.17210982017173,
        "rainfall": 2,
        "wind_speed": 4.235962997630054,
        "soil_moisture": 34.24471147072638,
        "ndvi": 0.48282086610379854,
        "soil_temp": 10.257594859935542,
        "soil_humidity": 51.16760394986268
    },
    {
        "date": "2024-09-04 09:53:09.251267",
        "air_temp": 32.24255234530901,
        "humidity": 82.74870097807371,
        "rainfall": 0.5,
        "wind_speed": 15.850795352426735,
        "soil_moisture": 15.285832184927425,
        "ndvi": 0.7211769023062167,
        "soil_temp": 24.214774796216336,
        "soil_humidity": 47.974634865006024
    },
    {
        "date": "2024-09-04 10:53:09.251267",
        "air_temp": 31.19032149449756,
        "humidity": 84.12799383861736,
        "rainfall": 1,
        "wind_speed": 3.7381459290023633,
        "soil_moisture": 28.18649926662298,
        "ndvi": 0.3749460617454592,
        "soil_temp": 15.530161357737171,
        "soil_humidity": 49.72782079151717
    },
    {
        "date": "2024-09-04 11:53:09.251267",
        "air_temp": 28.104839612780435,
        "humidity": 63.31548780700851,
        "rainfall": 0.5,
        "wind_speed": 1.3331965964661219,
        "soil_moisture": 20.107657392302368,
        "ndvi": 0.2772878442577929,
        "soil_temp": 10.151356075328223,
        "soil_humidity": 41.943550251531704
    },
    {
        "date": "2024-09-04 12:53:09.251267",
        "air_temp": 26.017147412182787,
        "humidity": 43.784986365489374,
        "rainfall": 0.5,
        "wind_speed": 19.971216131712573,
        "soil_moisture": 27.56457814945107,
        "ndvi": 0.713188678617763,
        "soil_temp": 20.337358475867624,
        "soil_humidity": 36.358603760905225
    },
    {
        "date": "2024-09-04 13:53:09.251267",
        "air_temp": 16.739735198228203,
        "humidity": 59.39130449584656,
        "rainfall": 0.5,
        "wind_speed": 0.4963442289029829,
        "soil_moisture": 19.46960880946972,
        "ndvi": 0.5524490982756001,
        "soil_temp": 24.70810456047031,
        "soil_humidity": 31.893999093073578
    },
    {
        "date": "2024-09-04 14:53:09.251267",
        "air_temp": 23.169064261413972,
        "humidity": 80.17688297313018,
        "rainfall": 1,
        "wind_speed": 16.70488120317731,
        "soil_moisture": 12.608186820767624,
        "ndvi": 0.7334554389493761,
        "soil_temp": 24.48438584256389,
        "soil_humidity": 37.83914889966671
    },
    {
        "date": "2024-09-04 15:53:09.251267",
        "air_temp": 22.45377034024628,
        "humidity": 85.08870142731143,
        "rainfall": 2,
        "wind_speed": 7.307336831013056,
        "soil_moisture": 16.68850025673261,
        "ndvi": 0.7626963241954301,
        "soil_temp": 12.414055168036278,
        "soil_humidity": 53.27774566023132
    },
    {
        "date": "2024-09-04 16:53:09.251267",
        "air_temp": 20.195075675206525,
        "humidity": 50.17363712487979,
        "rainfall": 0.5,
        "wind_speed": 16.832309195156306,
        "soil_moisture": 15.641756994372269,
        "ndvi": 0.2321770342228695,
        "soil_temp": 12.718717090992325,
        "soil_humidity": 61.444617912125295
    },
    {
        "date": "2024-09-04 17:53:09.251267",
        "air_temp": 29.468402273771698,
        "humidity": 43.348680601263574,
        "rainfall": 1,
        "wind_speed": 11.0924098242229,
        "soil_moisture": 22.840580573189314,
        "ndvi": 0.5005815811153211,
        "soil_temp": 18.483083719294903,
        "soil_humidity": 36.76990389771578
    },
    {
        "date": "2024-09-04 18:53:09.251267",
        "air_temp": 24.917514701575417,
        "humidity": 83.86752265664194,
        "rainfall": 0,
        "wind_speed": 18.032624589727202,
        "soil_moisture": 29.74851350278472,
        "ndvi": 0.4249787294447176,
        "soil_temp": 19.559572396424866,
        "soil_humidity": 34.34221431090594
    },
    {
        "date": "2024-09-04 19:53:09.251267",
        "air_temp": 16.62092431815296,
        "humidity": 59.47024694894377,
        "rainfall": 1,
        "wind_speed": 18.523859728292532,
        "soil_moisture": 17.914182248390727,
        "ndvi": 0.5755150382420704,
        "soil_temp": 21.091268704987982,
        "soil_humidity": 59.97161442197001
    },
    {
        "date": "2024-09-04 20:53:09.251267",
        "air_temp": 19.403664038996226,
        "humidity": 67.08804821337816,
        "rainfall": 0,
        "wind_speed": 0.18196362561551993,
        "soil_moisture": 10.362098059018539,
        "ndvi": 0.6761547183213942,
        "soil_temp": 12.715755407082213,
        "soil_humidity": 42.20633322149159
    },
    {
        "date": "2024-09-04 21:53:09.251267",
        "air_temp": 28.66517527319192,
        "humidity": 88.40329060253794,
        "rainfall": 1,
        "wind_speed": 1.5082435198557653,
        "soil_moisture": 25.568120927942708,
        "ndvi": 0.36835412958462144,
        "soil_temp": 24.385106301466518,
        "soil_humidity": 68.0599775690941
    },
    {
        "date": "2024-09-04 22:53:09.251267",
        "air_temp": 16.522617189806002,
        "humidity": 43.328236395711144,
        "rainfall": 2,
        "wind_speed": 5.00717459141711,
        "soil_moisture": 28.172784680769162,
        "ndvi": 0.534079030623903,
        "soil_temp": 19.581088844338566,
        "soil_humidity": 58.57271882441492
    },
    {
        "date": "2024-09-04 23:53:09.251267",
        "air_temp": 32.02413828097538,
        "humidity": 72.41590037260478,
        "rainfall": 0.5,
        "wind_speed": 13.386308327270033,
        "soil_moisture": 38.88611482005429,
        "ndvi": 0.6252371479429398,
        "soil_temp": 22.705703783058322,
        "soil_humidity": 43.07024188972123
    },
    {
        "date": "2024-09-05 00:53:09.251267",
        "air_temp": 24.902930540279485,
        "humidity": 43.705271128743355,
        "rainfall": 2,
        "wind_speed": 17.76544393023012,
        "soil_moisture": 27.684124092797404,
        "ndvi": 0.28065864970144044,
        "soil_temp": 18.100985729262575,
        "soil_humidity": 41.5817099117482
    },
    {
        "date": "2024-09-05 01:53:09.251267",
        "air_temp": 24.61173154653296,
        "humidity": 58.77344642332502,
        "rainfall": 1,
        "wind_speed": 9.198839025879256,
        "soil_moisture": 24.135176781344256,
        "ndvi": 0.6884401435707602,
        "soil_temp": 16.21415271384824,
        "soil_humidity": 50.94793545085236
    },
    {
        "date": "2024-09-05 02:53:09.251267",
        "air_temp": 26.84815569319035,
        "humidity": 80.19072725967234,
        "rainfall": 0.5,
        "wind_speed": 12.139462325485308,
        "soil_moisture": 27.202048776931115,
        "ndvi": 0.5636657599117839,
        "soil_temp": 24.657743598924938,
        "soil_humidity": 33.16578673112838
    },
    {
        "date": "2024-09-05 03:53:09.251267",
        "air_temp": 31.493619318502983,
        "humidity": 61.67361163778172,
        "rainfall": 0,
        "wind_speed": 3.769997055911223,
        "soil_moisture": 37.83701889586081,
        "ndvi": 0.7697224622681911,
        "soil_temp": 18.731820562297543,
        "soil_humidity": 34.70706050581196
    },
    {
        "date": "2024-09-05 04:53:09.251267",
        "air_temp": 21.95618415804386,
        "humidity": 89.85911769514752,
        "rainfall": 1,
        "wind_speed": 16.09933667763371,
        "soil_moisture": 34.85134436804938,
        "ndvi": 0.40371735147841603,
        "soil_temp": 23.003103741987683,
        "soil_humidity": 37.0343152526477
    },
    {
        "date": "2024-09-05 05:53:09.251267",
        "air_temp": 28.56032305118127,
        "humidity": 67.9529642473301,
        "rainfall": 0.5,
        "wind_speed": 19.346562227069395,
        "soil_moisture": 19.68936200091424,
        "ndvi": 0.2648875654023192,
        "soil_temp": 24.29938594549435,
        "soil_humidity": 60.988943732182406
    },
    {
        "date": "2024-09-05 06:53:09.251267",
        "air_temp": 26.314639279915824,
        "humidity": 56.058107631797675,
        "rainfall": 0,
        "wind_speed": 5.371978658291949,
        "soil_moisture": 17.117630909714116,
        "ndvi": 0.5039007206221553,
        "soil_temp": 20.13031940512989,
        "soil_humidity": 50.5542943707649
    },
    {
        "date": "2024-09-05 07:53:09.251267",
        "air_temp": 20.340565403388425,
        "humidity": 51.00556629523766,
        "rainfall": 0,
        "wind_speed": 9.896519032010927,
        "soil_moisture": 17.87900837098221,
        "ndvi": 0.25148646302566335,
        "soil_temp": 18.234080177780122,
        "soil_humidity": 30.42268491097772
    },
    {
        "date": "2024-09-05 08:53:09.251267",
        "air_temp": 32.57259972710316,
        "humidity": 57.52602671430809,
        "rainfall": 2,
        "wind_speed": 1.722322210127476,
        "soil_moisture": 25.13263119145639,
        "ndvi": 0.7817159139944041,
        "soil_temp": 10.05266276365976,
        "soil_humidity": 56.94066109888436
    },
    {
        "date": "2024-09-05 09:53:09.251267",
        "air_temp": 30.94852043213856,
        "humidity": 58.62666368797754,
        "rainfall": 1,
        "wind_speed": 11.182733129259368,
        "soil_moisture": 32.32148353685733,
        "ndvi": 0.22321097495431494,
        "soil_temp": 10.60029461108509,
        "soil_humidity": 46.947607146419884
    },
    {
        "date": "2024-09-05 10:53:09.251267",
        "air_temp": 28.169036693168508,
        "humidity": 43.43568388619805,
        "rainfall": 0,
        "wind_speed": 10.055668836933611,
        "soil_moisture": 25.096576215488863,
        "ndvi": 0.6521023406643849,
        "soil_temp": 13.012768240766745,
        "soil_humidity": 50.434094598551916
    },
    {
        "date": "2024-09-05 11:53:09.251267",
        "air_temp": 32.011634581884834,
        "humidity": 58.47870997133866,
        "rainfall": 0.5,
        "wind_speed": 2.888729683101552,
        "soil_moisture": 12.033891874843377,
        "ndvi": 0.5150614388853465,
        "soil_temp": 12.790126477839928,
        "soil_humidity": 38.564649736517424
    },
    {
        "date": "2024-09-05 12:53:09.251267",
        "air_temp": 32.34588401919605,
        "humidity": 63.21192598696125,
        "rainfall": 0,
        "wind_speed": 8.8413242623615,
        "soil_moisture": 31.707421013199426,
        "ndvi": 0.42117239149897323,
        "soil_temp": 13.821381927997681,
        "soil_humidity": 52.07128913576415
    },
    {
        "date": "2024-09-05 13:53:09.251267",
        "air_temp": 29.167259534300698,
        "humidity": 76.13688761194024,
        "rainfall": 0,
        "wind_speed": 6.682093041731266,
        "soil_moisture": 34.17910386012862,
        "ndvi": 0.23950529870519283,
        "soil_temp": 14.574019565464912,
        "soil_humidity": 69.16612959005954
    },
    {
        "date": "2024-09-05 14:53:09.251267",
        "air_temp": 31.74026656727344,
        "humidity": 72.83647423608039,
        "rainfall": 2,
        "wind_speed": 10.436617506103786,
        "soil_moisture": 18.68493595359159,
        "ndvi": 0.5733001545041647,
        "soil_temp": 15.400982403260924,
        "soil_humidity": 32.93778764023046
    },
    {
        "date": "2024-09-05 15:53:09.251267",
        "air_temp": 28.949429233385672,
        "humidity": 75.43828276145771,
        "rainfall": 2,
        "wind_speed": 11.713086708575984,
        "soil_moisture": 29.923772178500514,
        "ndvi": 0.5511321976345732,
        "soil_temp": 16.30806535103315,
        "soil_humidity": 41.35669598657722
    },
    {
        "date": "2024-09-05 16:53:09.251267",
        "air_temp": 28.602815435206008,
        "humidity": 40.418184093842854,
        "rainfall": 0.5,
        "wind_speed": 0.16991157214419017,
        "soil_moisture": 22.797757031631804,
        "ndvi": 0.3685299638405599,
        "soil_temp": 11.48299507684022,
        "soil_humidity": 39.71990371443319
    },
    {
        "date": "2024-09-05 17:53:09.251267",
        "air_temp": 27.372227564303685,
        "humidity": 50.8833699790268,
        "rainfall": 2,
        "wind_speed": 15.220119658991107,
        "soil_moisture": 36.67358067182329,
        "ndvi": 0.7461330208461874,
        "soil_temp": 10.635428979291731,
        "soil_humidity": 39.94197243004719
    },
    {
        "date": "2024-09-05 18:53:09.251267",
        "air_temp": 30.054332791152824,
        "humidity": 73.08421441999283,
        "rainfall": 0,
        "wind_speed": 14.260285837234816,
        "soil_moisture": 35.356766744822195,
        "ndvi": 0.38246600440570216,
        "soil_temp": 20.109166736095354,
        "soil_humidity": 45.14825430261509
    },
    {
        "date": "2024-09-05 19:53:09.251267",
        "air_temp": 18.172102105861473,
        "humidity": 64.19946840898736,
        "rainfall": 0,
        "wind_speed": 7.325727724130444,
        "soil_moisture": 25.762959949289268,
        "ndvi": 0.46983524635526375,
        "soil_temp": 18.060022372646166,
        "soil_humidity": 62.7243913897667
    },
    {
        "date": "2024-09-05 20:53:09.251267",
        "air_temp": 32.617415183978565,
        "humidity": 40.2656737834173,
        "rainfall": 2,
        "wind_speed": 1.0587187113763652,
        "soil_moisture": 30.745166374407834,
        "ndvi": 0.5262574735511935,
        "soil_temp": 16.662163033301795,
        "soil_humidity": 66.5143475985755
    },
    {
        "date": "2024-09-05 21:53:09.251267",
        "air_temp": 32.436870554904644,
        "humidity": 80.22473212159112,
        "rainfall": 0,
        "wind_speed": 15.098650795701008,
        "soil_moisture": 36.50049103066312,
        "ndvi": 0.2601391269057717,
        "soil_temp": 19.516599117525285,
        "soil_humidity": 38.088603498358935
    },
    {
        "date": "2024-09-05 22:53:09.251267",
        "air_temp": 15.584945660691183,
        "humidity": 78.64047866451688,
        "rainfall": 0,
        "wind_speed": 19.10350320956265,
        "soil_moisture": 24.871510302348266,
        "ndvi": 0.7592970315281795,
        "soil_temp": 22.415191108053108,
        "soil_humidity": 51.71799751792451
    },
    {
        "date": "2024-09-05 23:53:09.251267",
        "air_temp": 31.516335011295254,
        "humidity": 67.42095814967813,
        "rainfall": 0.5,
        "wind_speed": 4.803031920440284,
        "soil_moisture": 19.397522214664658,
        "ndvi": 0.4247216914706247,
        "soil_temp": 16.41539363674307,
        "soil_humidity": 55.47595888661921
    },
    {
        "date": "2024-09-06 00:53:09.251267",
        "air_temp": 17.577397349468903,
        "humidity": 43.3231662888136,
        "rainfall": 2,
        "wind_speed": 6.470492274740758,
        "soil_moisture": 32.60998413728744,
        "ndvi": 0.2484675283246062,
        "soil_temp": 19.61679197577969,
        "soil_humidity": 50.8730989745538
    },
    {
        "date": "2024-09-06 01:53:09.251267",
        "air_temp": 21.70237708518284,
        "humidity": 78.32575247662923,
        "rainfall": 0,
        "wind_speed": 3.676257789636299,
        "soil_moisture": 16.601987643540753,
        "ndvi": 0.432374704205198,
        "soil_temp": 24.30358335752738,
        "soil_humidity": 47.36305576787679
    },
    {
        "date": "2024-09-06 02:53:09.251267",
        "air_temp": 29.870165125832155,
        "humidity": 69.1856793865682,
        "rainfall": 2,
        "wind_speed": 11.538722466896793,
        "soil_moisture": 21.655934440181262,
        "ndvi": 0.5601215282555485,
        "soil_temp": 22.59436743792341,
        "soil_humidity": 37.67902023436952
    },
    {
        "date": "2024-09-06 03:53:09.251267",
        "air_temp": 18.215197920966165,
        "humidity": 79.09799885789795,
        "rainfall": 0.5,
        "wind_speed": 3.096827892949099,
        "soil_moisture": 35.97181711320381,
        "ndvi": 0.5081916030927978,
        "soil_temp": 16.39001215753614,
        "soil_humidity": 49.52877436796954
    },
    {
        "date": "2024-09-06 04:53:09.251267",
        "air_temp": 31.359340482381242,
        "humidity": 77.58734961979202,
        "rainfall": 2,
        "wind_speed": 7.123878869227589,
        "soil_moisture": 31.73267020807945,
        "ndvi": 0.6167251844223645,
        "soil_temp": 19.670752567221143,
        "soil_humidity": 46.048593108453154
    },
    {
        "date": "2024-09-06 05:53:09.251267",
        "air_temp": 31.64268355915484,
        "humidity": 80.15010359789186,
        "rainfall": 0,
        "wind_speed": 13.940260278685258,
        "soil_moisture": 22.114330157424977,
        "ndvi": 0.2332903652407464,
        "soil_temp": 12.656063828739097,
        "soil_humidity": 61.82253450009078
    },
    {
        "date": "2024-09-06 06:53:09.251267",
        "air_temp": 25.149354675216724,
        "humidity": 65.90040332023125,
        "rainfall": 2,
        "wind_speed": 11.202319660186967,
        "soil_moisture": 16.44233507225072,
        "ndvi": 0.3707243661632337,
        "soil_temp": 18.40783277342635,
        "soil_humidity": 60.54417436651981
    },
    {
        "date": "2024-09-06 07:53:09.251267",
        "air_temp": 15.127717434336672,
        "humidity": 47.01622590967575,
        "rainfall": 0,
        "wind_speed": 7.729251286558705,
        "soil_moisture": 20.679051724967096,
        "ndvi": 0.6915383461040614,
        "soil_temp": 24.719924367076644,
        "soil_humidity": 35.35994724822683
    },
    {
        "date": "2024-09-06 08:53:09.251267",
        "air_temp": 20.740762663498256,
        "humidity": 73.55787366898443,
        "rainfall": 0,
        "wind_speed": 8.404992036150837,
        "soil_moisture": 18.52312447682299,
        "ndvi": 0.24793621267481653,
        "soil_temp": 17.774726728037834,
        "soil_humidity": 33.540762102522706
    },
    {
        "date": "2024-09-06 09:53:09.251267",
        "air_temp": 27.338538367514843,
        "humidity": 71.02368925259853,
        "rainfall": 0,
        "wind_speed": 11.24221765246459,
        "soil_moisture": 15.282678638315618,
        "ndvi": 0.7132915728175746,
        "soil_temp": 23.91457448785221,
        "soil_humidity": 66.32615772910012
    },
    {
        "date": "2024-09-06 10:53:09.251267",
        "air_temp": 34.62372356054847,
        "humidity": 77.11265935995436,
        "rainfall": 0.5,
        "wind_speed": 0.11729455774322606,
        "soil_moisture": 32.2999874069697,
        "ndvi": 0.25513765524626375,
        "soil_temp": 16.11002380754891,
        "soil_humidity": 51.42033555857991
    },
    {
        "date": "2024-09-06 11:53:09.251267",
        "air_temp": 27.636270540333367,
        "humidity": 48.49405030253676,
        "rainfall": 2,
        "wind_speed": 1.570197369072197,
        "soil_moisture": 17.562096605505147,
        "ndvi": 0.7006725289350177,
        "soil_temp": 24.982672407769467,
        "soil_humidity": 64.31203749130363
    },
    {
        "date": "2024-09-06 12:53:09.251267",
        "air_temp": 20.196071621283195,
        "humidity": 49.74875999501446,
        "rainfall": 0.5,
        "wind_speed": 17.625161355260232,
        "soil_moisture": 23.625024105032914,
        "ndvi": 0.41647304064723606,
        "soil_temp": 18.688300528413368,
        "soil_humidity": 32.326044822656044
    },
    {
        "date": "2024-09-06 13:53:09.251267",
        "air_temp": 27.680114061992228,
        "humidity": 84.52047586096643,
        "rainfall": 2,
        "wind_speed": 1.5575797818258152,
        "soil_moisture": 21.57578340508327,
        "ndvi": 0.5673254379543295,
        "soil_temp": 10.634034913539018,
        "soil_humidity": 51.06062466086521
    },
    {
        "date": "2024-09-06 14:53:09.251267",
        "air_temp": 25.79970759431711,
        "humidity": 77.49888992260354,
        "rainfall": 0.5,
        "wind_speed": 14.136071924210103,
        "soil_moisture": 39.46232135906892,
        "ndvi": 0.2917322693887989,
        "soil_temp": 18.80132195037082,
        "soil_humidity": 51.57706058304946
    },
    {
        "date": "2024-09-06 15:53:09.251267",
        "air_temp": 30.596907903022874,
        "humidity": 85.41142443501614,
        "rainfall": 1,
        "wind_speed": 18.400763282181053,
        "soil_moisture": 21.732767324446616,
        "ndvi": 0.23970123303510854,
        "soil_temp": 14.11859043519395,
        "soil_humidity": 64.428081301686
    },
    {
        "date": "2024-09-06 16:53:09.251267",
        "air_temp": 17.139612776539167,
        "humidity": 77.93576605819604,
        "rainfall": 0,
        "wind_speed": 5.413672086034622,
        "soil_moisture": 12.399474483902644,
        "ndvi": 0.32956292241208107,
        "soil_temp": 10.48721983053842,
        "soil_humidity": 33.9043999291883
    },
    {
        "date": "2024-09-06 17:53:09.251267",
        "air_temp": 30.22055805004056,
        "humidity": 69.8577652995151,
        "rainfall": 0,
        "wind_speed": 7.409828741190285,
        "soil_moisture": 12.69477257716252,
        "ndvi": 0.27671229074521736,
        "soil_temp": 22.312799570419948,
        "soil_humidity": 39.61782254703637
    },
    {
        "date": "2024-09-06 18:53:09.251267",
        "air_temp": 25.825331573522206,
        "humidity": 72.70663685139368,
        "rainfall": 0.5,
        "wind_speed": 11.378428116894327,
        "soil_moisture": 27.722611640591943,
        "ndvi": 0.38744353860777914,
        "soil_temp": 14.135944554711338,
        "soil_humidity": 46.26153255548053
    },
    {
        "date": "2024-09-06 19:53:09.251267",
        "air_temp": 34.25984007717989,
        "humidity": 84.45639619700049,
        "rainfall": 1,
        "wind_speed": 17.634179392771692,
        "soil_moisture": 27.980906552147967,
        "ndvi": 0.5086299479066121,
        "soil_temp": 21.948228310697477,
        "soil_humidity": 64.63365901679768
    },
    {
        "date": "2024-09-06 20:53:09.251267",
        "air_temp": 21.837443320773723,
        "humidity": 68.94033772559703,
        "rainfall": 0,
        "wind_speed": 18.838111745208405,
        "soil_moisture": 22.56276971447965,
        "ndvi": 0.5532556781390058,
        "soil_temp": 14.323263744338004,
        "soil_humidity": 67.55533260282269
    },
    {
        "date": "2024-09-06 21:53:09.251267",
        "air_temp": 27.652437862678962,
        "humidity": 71.61956621641937,
        "rainfall": 2,
        "wind_speed": 14.79126496222536,
        "soil_moisture": 22.955310769360892,
        "ndvi": 0.6739208644278458,
        "soil_temp": 12.914895308106615,
        "soil_humidity": 69.70898478374511
    },
    {
        "date": "2024-09-06 22:53:09.251267",
        "air_temp": 33.64056211020035,
        "humidity": 47.82383580018003,
        "rainfall": 0.5,
        "wind_speed": 1.7323952170938806,
        "soil_moisture": 11.617977485226136,
        "ndvi": 0.5937439359490153,
        "soil_temp": 24.60903361156641,
        "soil_humidity": 68.36773945834086
    },
    {
        "date": "2024-09-06 23:53:09.251267",
        "air_temp": 17.050194559813583,
        "humidity": 63.696061005805134,
        "rainfall": 0,
        "wind_speed": 13.383083110697015,
        "soil_moisture": 25.496395311677865,
        "ndvi": 0.5652549950777319,
        "soil_temp": 15.424679916325292,
        "soil_humidity": 30.237670233774175
    },
    {
        "date": "2024-09-07 00:53:09.251267",
        "air_temp": 33.74456974472902,
        "humidity": 75.81985559790495,
        "rainfall": 0,
        "wind_speed": 8.20607443745324,
        "soil_moisture": 25.14218426141055,
        "ndvi": 0.4470692164914648,
        "soil_temp": 19.020643171647407,
        "soil_humidity": 31.90833365815488
    },
    {
        "date": "2024-09-07 01:53:09.251267",
        "air_temp": 28.757714446016266,
        "humidity": 53.54713491581827,
        "rainfall": 0.5,
        "wind_speed": 9.414019989821231,
        "soil_moisture": 14.486479556708336,
        "ndvi": 0.48428506097608326,
        "soil_temp": 19.149722392217072,
        "soil_humidity": 35.90427276630355
    },
    {
        "date": "2024-09-07 02:53:09.251267",
        "air_temp": 16.356741182103438,
        "humidity": 50.112709026226746,
        "rainfall": 0.5,
        "wind_speed": 0.9829678069035408,
        "soil_moisture": 13.115181583327828,
        "ndvi": 0.4487124976341087,
        "soil_temp": 18.407102232602526,
        "soil_humidity": 43.954483870810925
    },
    {
        "date": "2024-09-07 03:53:09.251267",
        "air_temp": 21.019271338936324,
        "humidity": 55.69134041122078,
        "rainfall": 2,
        "wind_speed": 3.8212427869271637,
        "soil_moisture": 13.707174717984714,
        "ndvi": 0.7052996180368301,
        "soil_temp": 23.72043615281822,
        "soil_humidity": 63.19672123947835
    },
    {
        "date": "2024-09-07 04:53:09.251267",
        "air_temp": 29.16344177290563,
        "humidity": 52.075031399641674,
        "rainfall": 2,
        "wind_speed": 15.810272150672954,
        "soil_moisture": 13.030847394737755,
        "ndvi": 0.33800027152903994,
        "soil_temp": 20.116616070503028,
        "soil_humidity": 65.70624781341922
    },
    {
        "date": "2024-09-07 05:53:09.251267",
        "air_temp": 16.347012029375435,
        "humidity": 50.745631851819795,
        "rainfall": 0,
        "wind_speed": 9.079176660957195,
        "soil_moisture": 28.291245866102795,
        "ndvi": 0.20343255892517878,
        "soil_temp": 16.055995061573952,
        "soil_humidity": 37.47337625178332
    },
    {
        "date": "2024-09-07 06:53:09.251267",
        "air_temp": 26.643409203525465,
        "humidity": 61.24254214340435,
        "rainfall": 0,
        "wind_speed": 6.033244832622977,
        "soil_moisture": 15.894479572087558,
        "ndvi": 0.5907919383357436,
        "soil_temp": 22.93570576786411,
        "soil_humidity": 54.786566064788346
    },
    {
        "date": "2024-09-07 07:53:09.251267",
        "air_temp": 21.917661139059135,
        "humidity": 85.3919256941767,
        "rainfall": 0.5,
        "wind_speed": 7.680381479299163,
        "soil_moisture": 17.542904565623903,
        "ndvi": 0.32147731018329884,
        "soil_temp": 12.715943486739466,
        "soil_humidity": 66.69447940261107
    },
    {
        "date": "2024-09-07 08:53:09.251267",
        "air_temp": 27.41831035534954,
        "humidity": 65.35298233693591,
        "rainfall": 0,
        "wind_speed": 17.087990266513604,
        "soil_moisture": 15.207164324543193,
        "ndvi": 0.7340690817858857,
        "soil_temp": 18.163551364156262,
        "soil_humidity": 48.54926943725283
    },
    {
        "date": "2024-09-07 09:53:09.251267",
        "air_temp": 15.914840676250257,
        "humidity": 49.39601904076615,
        "rainfall": 0,
        "wind_speed": 14.979980348099138,
        "soil_moisture": 38.735059133455096,
        "ndvi": 0.7154277978239938,
        "soil_temp": 24.258255579156348,
        "soil_humidity": 34.14174287811136
    },
    {
        "date": "2024-09-07 10:53:09.251267",
        "air_temp": 32.430736123047524,
        "humidity": 43.8485834690437,
        "rainfall": 0.5,
        "wind_speed": 5.4463079436216555,
        "soil_moisture": 35.638523894555064,
        "ndvi": 0.24603642315692356,
        "soil_temp": 19.380964448206193,
        "soil_humidity": 48.312546003384995
    },
    {
        "date": "2024-09-07 11:53:09.251267",
        "air_temp": 34.46977938354794,
        "humidity": 74.80780725689627,
        "rainfall": 0,
        "wind_speed": 2.350036804247466,
        "soil_moisture": 16.91484512054563,
        "ndvi": 0.2638330432484464,
        "soil_temp": 22.254526757123145,
        "soil_humidity": 45.03179609152229
    },
    {
        "date": "2024-09-07 12:53:09.251267",
        "air_temp": 34.377557105713834,
        "humidity": 59.13993822181939,
        "rainfall": 0,
        "wind_speed": 5.039964432881434,
        "soil_moisture": 19.826988817663782,
        "ndvi": 0.4971029553479998,
        "soil_temp": 19.803410883177893,
        "soil_humidity": 53.106319842640275
    },
    {
        "date": "2024-09-07 13:53:09.251267",
        "air_temp": 29.993036634858495,
        "humidity": 81.09154732926797,
        "rainfall": 1,
        "wind_speed": 18.415129021094675,
        "soil_moisture": 16.296785269149915,
        "ndvi": 0.5655335976616129,
        "soil_temp": 22.590562953068357,
        "soil_humidity": 47.546347376284146
    },
    {
        "date": "2024-09-07 14:53:09.251267",
        "air_temp": 17.60172480261264,
        "humidity": 72.97553843203852,
        "rainfall": 2,
        "wind_speed": 11.631776756995107,
        "soil_moisture": 24.756278783824712,
        "ndvi": 0.36494500248934447,
        "soil_temp": 24.167318197678654,
        "soil_humidity": 30.693837074148718
    },
    {
        "date": "2024-09-07 15:53:09.251267",
        "air_temp": 30.16526391858045,
        "humidity": 79.81207252706842,
        "rainfall": 0.5,
        "wind_speed": 10.669604174895657,
        "soil_moisture": 10.588315974227609,
        "ndvi": 0.6300786300196224,
        "soil_temp": 14.093825912991052,
        "soil_humidity": 69.75990933654685
    },
    {
        "date": "2024-09-07 16:53:09.251267",
        "air_temp": 15.49173832917603,
        "humidity": 53.59730197092244,
        "rainfall": 1,
        "wind_speed": 11.92787199384923,
        "soil_moisture": 30.14104026690387,
        "ndvi": 0.5569496125513229,
        "soil_temp": 12.544916218850783,
        "soil_humidity": 51.74160950922284
    },
    {
        "date": "2024-09-07 17:53:09.251267",
        "air_temp": 15.442471030579945,
        "humidity": 74.6179461230529,
        "rainfall": 2,
        "wind_speed": 7.320565314930305,
        "soil_moisture": 25.69474449452925,
        "ndvi": 0.39489446584913285,
        "soil_temp": 17.2598612549881,
        "soil_humidity": 48.945230219384385
    },
    {
        "date": "2024-09-07 18:53:09.251267",
        "air_temp": 21.472204382990824,
        "humidity": 53.203099234685936,
        "rainfall": 2,
        "wind_speed": 16.142140406390254,
        "soil_moisture": 36.95916784903309,
        "ndvi": 0.21522289282538937,
        "soil_temp": 20.52793640236324,
        "soil_humidity": 68.18469486016215
    },
    {
        "date": "2024-09-07 19:53:09.251267",
        "air_temp": 24.772863808093316,
        "humidity": 86.95342397251457,
        "rainfall": 2,
        "wind_speed": 10.465724986004997,
        "soil_moisture": 14.931780529852425,
        "ndvi": 0.6500901687791107,
        "soil_temp": 13.407868797733242,
        "soil_humidity": 36.23441470348308
    },
    {
        "date": "2024-09-07 20:53:09.251267",
        "air_temp": 30.408148356155863,
        "humidity": 71.81871407666873,
        "rainfall": 1,
        "wind_speed": 2.334958055375911,
        "soil_moisture": 34.12327738183395,
        "ndvi": 0.3288521994950496,
        "soil_temp": 10.061521756452507,
        "soil_humidity": 37.569504101809926
    },
    {
        "date": "2024-09-07 21:53:09.251267",
        "air_temp": 28.665907532130106,
        "humidity": 56.2255900087671,
        "rainfall": 1,
        "wind_speed": 2.581302496581259,
        "soil_moisture": 33.75883043400147,
        "ndvi": 0.7006842460775771,
        "soil_temp": 15.144026737550579,
        "soil_humidity": 62.32058996251134
    },
    {
        "date": "2024-09-07 22:53:09.251267",
        "air_temp": 23.918054127534965,
        "humidity": 53.47559311461363,
        "rainfall": 2,
        "wind_speed": 10.737182930052366,
        "soil_moisture": 13.815435676506608,
        "ndvi": 0.4521741560325532,
        "soil_temp": 24.532890032016827,
        "soil_humidity": 40.43324320100035
    },
    {
        "date": "2024-09-07 23:53:09.251267",
        "air_temp": 20.472533325633318,
        "humidity": 49.54632925358217,
        "rainfall": 0,
        "wind_speed": 18.731404361416782,
        "soil_moisture": 21.956776724323827,
        "ndvi": 0.5462464655790593,
        "soil_temp": 20.4245119090877,
        "soil_humidity": 61.96908425166107
    },
    {
        "date": "2024-09-08 00:53:09.251267",
        "air_temp": 34.94249000315422,
        "humidity": 74.7320479446864,
        "rainfall": 0,
        "wind_speed": 6.167042433523974,
        "soil_moisture": 15.104579234335507,
        "ndvi": 0.7237692382454013,
        "soil_temp": 16.019881659118358,
        "soil_humidity": 40.8613393163401
    },
    {
        "date": "2024-09-08 01:53:09.251267",
        "air_temp": 23.523626044719457,
        "humidity": 50.935780649896365,
        "rainfall": 1,
        "wind_speed": 17.390823377184365,
        "soil_moisture": 17.641615013726465,
        "ndvi": 0.718173800551972,
        "soil_temp": 12.888641460202605,
        "soil_humidity": 31.32750520900006
    },
    {
        "date": "2024-09-08 02:53:09.251267",
        "air_temp": 24.02774048659351,
        "humidity": 69.76516329637445,
        "rainfall": 1,
        "wind_speed": 15.281911731577587,
        "soil_moisture": 17.89738524491633,
        "ndvi": 0.422911745146144,
        "soil_temp": 15.341345106321205,
        "soil_humidity": 50.680588955468636
    },
    {
        "date": "2024-09-08 03:53:09.251267",
        "air_temp": 18.272476423833382,
        "humidity": 53.23175134626787,
        "rainfall": 1,
        "wind_speed": 4.956248858138137,
        "soil_moisture": 26.687091820773183,
        "ndvi": 0.3253333356289059,
        "soil_temp": 12.54759149951872,
        "soil_humidity": 37.86745736507446
    },
    {
        "date": "2024-09-08 04:53:09.251267",
        "air_temp": 30.89619097499859,
        "humidity": 73.09844723969343,
        "rainfall": 2,
        "wind_speed": 0.7268149663248491,
        "soil_moisture": 22.342327952813967,
        "ndvi": 0.6650876466371569,
        "soil_temp": 11.594587319694272,
        "soil_humidity": 60.313851717298846
    },
    {
        "date": "2024-09-08 05:53:09.251267",
        "air_temp": 28.87364451562977,
        "humidity": 80.74698642702697,
        "rainfall": 0,
        "wind_speed": 2.0369453028476547,
        "soil_moisture": 38.431984120189156,
        "ndvi": 0.45984812216127874,
        "soil_temp": 23.601135588588306,
        "soil_humidity": 55.64798532928592
    },
    {
        "date": "2024-09-08 06:53:09.251267",
        "air_temp": 19.41539225577521,
        "humidity": 78.90126215723265,
        "rainfall": 1,
        "wind_speed": 14.569878676948804,
        "soil_moisture": 13.791104258410154,
        "ndvi": 0.31522519613540245,
        "soil_temp": 24.964755075632105,
        "soil_humidity": 66.94410671911672
    },
    {
        "date": "2024-09-08 07:53:09.251267",
        "air_temp": 16.647620912316853,
        "humidity": 78.04256654653909,
        "rainfall": 1,
        "wind_speed": 3.993394760812934,
        "soil_moisture": 39.21076404550597,
        "ndvi": 0.7426552053902902,
        "soil_temp": 12.461716593936854,
        "soil_humidity": 61.319534290629434
    },
    {
        "date": "2024-09-08 08:53:09.251267",
        "air_temp": 28.60998604149426,
        "humidity": 49.3861202846126,
        "rainfall": 0.5,
        "wind_speed": 7.425418410107875,
        "soil_moisture": 16.91915920642792,
        "ndvi": 0.3188625165446525,
        "soil_temp": 10.817838383174196,
        "soil_humidity": 37.231038193629075
    },
    {
        "date": "2024-09-08 09:53:09.251267",
        "air_temp": 28.090224285622703,
        "humidity": 44.419247349225934,
        "rainfall": 2,
        "wind_speed": 10.033983320128497,
        "soil_moisture": 33.18621809145559,
        "ndvi": 0.21548430091473866,
        "soil_temp": 14.945966607193185,
        "soil_humidity": 62.00632946659185
    },
    {
        "date": "2024-09-08 10:53:09.251267",
        "air_temp": 20.465190539964187,
        "humidity": 74.94744142436929,
        "rainfall": 0.5,
        "wind_speed": 14.509363369003257,
        "soil_moisture": 20.16909778518464,
        "ndvi": 0.212167984260452,
        "soil_temp": 16.924887711421228,
        "soil_humidity": 55.66948179256093
    },
    {
        "date": "2024-09-08 11:53:09.251267",
        "air_temp": 34.0172712450082,
        "humidity": 58.41736068971757,
        "rainfall": 0,
        "wind_speed": 2.2646244651825076,
        "soil_moisture": 14.292441862519418,
        "ndvi": 0.5733892138725587,
        "soil_temp": 13.072318706137818,
        "soil_humidity": 56.758065670425
    },
    {
        "date": "2024-09-08 12:53:09.251267",
        "air_temp": 18.021157835618006,
        "humidity": 61.61731132016736,
        "rainfall": 0.5,
        "wind_speed": 8.12732810554923,
        "soil_moisture": 35.7366755573872,
        "ndvi": 0.5970050364305183,
        "soil_temp": 14.521373676978378,
        "soil_humidity": 63.29051543880595
    },
    {
        "date": "2024-09-08 13:53:09.251267",
        "air_temp": 23.64669602085204,
        "humidity": 41.55745463966955,
        "rainfall": 0.5,
        "wind_speed": 9.701458182853909,
        "soil_moisture": 19.265826977093894,
        "ndvi": 0.3866250230341904,
        "soil_temp": 13.013061035374864,
        "soil_humidity": 52.95296276755761
    },
    {
        "date": "2024-09-08 14:53:09.251267",
        "air_temp": 33.872318403351976,
        "humidity": 52.97882246375246,
        "rainfall": 0.5,
        "wind_speed": 10.816541279430794,
        "soil_moisture": 29.765662126805267,
        "ndvi": 0.3920065935482381,
        "soil_temp": 21.613908526486767,
        "soil_humidity": 48.640140574120515
    },
    {
        "date": "2024-09-08 15:53:09.251267",
        "air_temp": 23.394546338522446,
        "humidity": 41.6838201937232,
        "rainfall": 2,
        "wind_speed": 16.367212827904332,
        "soil_moisture": 26.993310552598796,
        "ndvi": 0.4036963490359657,
        "soil_temp": 20.68866914870891,
        "soil_humidity": 55.46564141434912
    },
    {
        "date": "2024-09-08 16:53:09.251267",
        "air_temp": 27.77051895328171,
        "humidity": 83.95928863006239,
        "rainfall": 0,
        "wind_speed": 3.433563900864338,
        "soil_moisture": 24.15268928172623,
        "ndvi": 0.6080951280553273,
        "soil_temp": 19.524272736414726,
        "soil_humidity": 41.91829715833436
    },
    {
        "date": "2024-09-08 17:53:09.251267",
        "air_temp": 22.951887959255885,
        "humidity": 52.16983416092653,
        "rainfall": 0.5,
        "wind_speed": 16.156629036532447,
        "soil_moisture": 24.87458417945617,
        "ndvi": 0.23488027461609717,
        "soil_temp": 15.80826785618807,
        "soil_humidity": 37.303885243483975
    },
    {
        "date": "2024-09-08 18:53:09.251267",
        "air_temp": 20.4843040469734,
        "humidity": 67.8668584786504,
        "rainfall": 2,
        "wind_speed": 13.857568553933044,
        "soil_moisture": 31.967137422768626,
        "ndvi": 0.7574698116147955,
        "soil_temp": 23.635614119713537,
        "soil_humidity": 54.663513522849854
    },
    {
        "date": "2024-09-08 19:53:09.251267",
        "air_temp": 34.67955295919656,
        "humidity": 41.94896603305652,
        "rainfall": 0,
        "wind_speed": 11.117416283979187,
        "soil_moisture": 12.633187423703216,
        "ndvi": 0.3335126265064679,
        "soil_temp": 24.5429881230253,
        "soil_humidity": 39.353934763419105
    },
    {
        "date": "2024-09-08 20:53:09.251267",
        "air_temp": 23.18668012630086,
        "humidity": 73.3423695652829,
        "rainfall": 1,
        "wind_speed": 13.990557408051439,
        "soil_moisture": 30.045419858737155,
        "ndvi": 0.7998039231452554,
        "soil_temp": 12.60458968938118,
        "soil_humidity": 33.19660437386427
    },
    {
        "date": "2024-09-08 21:53:09.251267",
        "air_temp": 32.881984073582686,
        "humidity": 56.151367287704694,
        "rainfall": 0.5,
        "wind_speed": 8.240354779033128,
        "soil_moisture": 36.87436304195736,
        "ndvi": 0.3029136162723122,
        "soil_temp": 13.345189621178172,
        "soil_humidity": 30.022167383410157
    },
    {
        "date": "2024-09-08 22:53:09.251267",
        "air_temp": 19.599092117821723,
        "humidity": 84.89595934788329,
        "rainfall": 0,
        "wind_speed": 2.7679517193624914,
        "soil_moisture": 11.733645910595925,
        "ndvi": 0.6629278743874265,
        "soil_temp": 17.884465420005544,
        "soil_humidity": 54.332776056002686
    },
    {
        "date": "2024-09-08 23:53:09.251267",
        "air_temp": 19.262094080501665,
        "humidity": 84.4059094467018,
        "rainfall": 0.5,
        "wind_speed": 13.315304805307346,
        "soil_moisture": 14.219600177010001,
        "ndvi": 0.33404752695777506,
        "soil_temp": 11.36875853923368,
        "soil_humidity": 44.48595133920947
    },
    {
        "date": "2024-09-09 00:53:09.251267",
        "air_temp": 15.622681657690228,
        "humidity": 56.26453091808739,
        "rainfall": 1,
        "wind_speed": 10.493446133712446,
        "soil_moisture": 31.498958101060943,
        "ndvi": 0.2485080809859524,
        "soil_temp": 16.546725982346693,
        "soil_humidity": 60.092438826938235
    },
    {
        "date": "2024-09-09 01:53:09.251267",
        "air_temp": 28.033336507517703,
        "humidity": 85.0480447845234,
        "rainfall": 0,
        "wind_speed": 12.78749601085551,
        "soil_moisture": 23.118449447338527,
        "ndvi": 0.7249599946103893,
        "soil_temp": 18.726868971665652,
        "soil_humidity": 45.007055932524096
    },
    {
        "date": "2024-09-09 02:53:09.251267",
        "air_temp": 22.370526874475225,
        "humidity": 89.80788367493895,
        "rainfall": 2,
        "wind_speed": 6.759975620569801,
        "soil_moisture": 22.10645862441065,
        "ndvi": 0.7152507555145353,
        "soil_temp": 17.831103567059998,
        "soil_humidity": 49.44013792952907
    },
    {
        "date": "2024-09-09 03:53:09.251267",
        "air_temp": 32.28716499679274,
        "humidity": 81.2707832061974,
        "rainfall": 0,
        "wind_speed": 18.137331546796716,
        "soil_moisture": 14.108999036327075,
        "ndvi": 0.44214223546976084,
        "soil_temp": 14.963628746170391,
        "soil_humidity": 46.2833989126154
    },
    {
        "date": "2024-09-09 04:53:09.251267",
        "air_temp": 24.464198133831143,
        "humidity": 82.24356738776146,
        "rainfall": 2,
        "wind_speed": 11.74739737151154,
        "soil_moisture": 25.203101221533643,
        "ndvi": 0.6214376855242562,
        "soil_temp": 18.1366870911581,
        "soil_humidity": 56.022991644373846
    },
    {
        "date": "2024-09-09 05:53:09.251267",
        "air_temp": 34.363868558294435,
        "humidity": 52.450436805454494,
        "rainfall": 0,
        "wind_speed": 1.7080496858174765,
        "soil_moisture": 13.796359335891317,
        "ndvi": 0.5342395596103504,
        "soil_temp": 24.382014313882717,
        "soil_humidity": 44.245566480598725
    },
    {
        "date": "2024-09-09 06:53:09.251267",
        "air_temp": 18.71051031406242,
        "humidity": 68.83511994288781,
        "rainfall": 2,
        "wind_speed": 8.996079814088574,
        "soil_moisture": 24.298613689316213,
        "ndvi": 0.7117736682042493,
        "soil_temp": 13.09026106437064,
        "soil_humidity": 68.55070599769235
    },
    {
        "date": "2024-09-09 07:53:09.251267",
        "air_temp": 32.37246335911222,
        "humidity": 43.362795698368394,
        "rainfall": 1,
        "wind_speed": 15.127121094872686,
        "soil_moisture": 32.9084589688497,
        "ndvi": 0.3890335815911927,
        "soil_temp": 19.822470375910054,
        "soil_humidity": 67.4458794639412
    },
    {
        "date": "2024-09-09 08:53:09.251267",
        "air_temp": 30.531937055834835,
        "humidity": 44.745896841083486,
        "rainfall": 1,
        "wind_speed": 15.882691234645447,
        "soil_moisture": 17.747930736614904,
        "ndvi": 0.5142034399993911,
        "soil_temp": 10.650950412340778,
        "soil_humidity": 63.92111610629125
    },
    {
        "date": "2024-09-09 09:53:09.251267",
        "air_temp": 30.41843689288104,
        "humidity": 89.94463060166453,
        "rainfall": 1,
        "wind_speed": 9.891941055275176,
        "soil_moisture": 24.423585045391818,
        "ndvi": 0.49122342272273034,
        "soil_temp": 10.996246067221524,
        "soil_humidity": 66.81201082290993
    },
    {
        "date": "2024-09-09 10:53:09.251267",
        "air_temp": 31.895664562337753,
        "humidity": 56.33196247129555,
        "rainfall": 0,
        "wind_speed": 17.89495141662602,
        "soil_moisture": 39.760479081905075,
        "ndvi": 0.4123434957541153,
        "soil_temp": 18.277423363353776,
        "soil_humidity": 47.965587538375644
    },
    {
        "date": "2024-09-09 11:53:09.251267",
        "air_temp": 30.220479818859978,
        "humidity": 77.40919560244231,
        "rainfall": 1,
        "wind_speed": 5.353368743885952,
        "soil_moisture": 30.59951270062112,
        "ndvi": 0.7348730387338234,
        "soil_temp": 11.574087704919242,
        "soil_humidity": 51.457215871579685
    },
    {
        "date": "2024-09-09 12:53:09.251267",
        "air_temp": 27.524406432628307,
        "humidity": 80.33325265134081,
        "rainfall": 0.5,
        "wind_speed": 8.211973944765331,
        "soil_moisture": 35.240703139155954,
        "ndvi": 0.29952675479752133,
        "soil_temp": 10.33520182640286,
        "soil_humidity": 59.68370370232978
    },
    {
        "date": "2024-09-09 13:53:09.251267",
        "air_temp": 17.624897553647752,
        "humidity": 82.90116355036146,
        "rainfall": 1,
        "wind_speed": 17.971938211379403,
        "soil_moisture": 22.445428196816373,
        "ndvi": 0.2897051837124177,
        "soil_temp": 12.572694964720332,
        "soil_humidity": 57.845836997541646
    },
    {
        "date": "2024-09-09 14:53:09.251267",
        "air_temp": 15.650523589825038,
        "humidity": 89.88141487010782,
        "rainfall": 2,
        "wind_speed": 17.92513432943158,
        "soil_moisture": 33.03582769377566,
        "ndvi": 0.5461457211936509,
        "soil_temp": 18.472737564993615,
        "soil_humidity": 65.62714382421883
    },
    {
        "date": "2024-09-09 15:53:09.251267",
        "air_temp": 33.41695695631374,
        "humidity": 52.07482426284339,
        "rainfall": 2,
        "wind_speed": 13.714255376493618,
        "soil_moisture": 11.215275188239655,
        "ndvi": 0.5944965379909652,
        "soil_temp": 20.550114317783986,
        "soil_humidity": 58.65324109026781
    },
    {
        "date": "2024-09-09 16:53:09.251267",
        "air_temp": 27.33300629041476,
        "humidity": 42.017541342984984,
        "rainfall": 2,
        "wind_speed": 17.179610942444402,
        "soil_moisture": 13.68733547626735,
        "ndvi": 0.3167715525947391,
        "soil_temp": 13.0371784621207,
        "soil_humidity": 69.91538832913842
    },
    {
        "date": "2024-09-09 17:53:09.251267",
        "air_temp": 30.930745819523526,
        "humidity": 60.559575899729296,
        "rainfall": 0,
        "wind_speed": 1.7754653391246533,
        "soil_moisture": 16.092117787601865,
        "ndvi": 0.4344304653527745,
        "soil_temp": 20.023891558122617,
        "soil_humidity": 43.94023199326364
    },
    {
        "date": "2024-09-09 18:53:09.251267",
        "air_temp": 24.630447030251002,
        "humidity": 46.504033491447345,
        "rainfall": 0.5,
        "wind_speed": 17.299899524155528,
        "soil_moisture": 22.058557738456585,
        "ndvi": 0.7679383057872464,
        "soil_temp": 12.73714496648844,
        "soil_humidity": 63.31990968957672
    },
    {
        "date": "2024-09-09 19:53:09.251267",
        "air_temp": 17.346163779247888,
        "humidity": 41.12129445810275,
        "rainfall": 1,
        "wind_speed": 6.412398131004535,
        "soil_moisture": 35.3723463497729,
        "ndvi": 0.34797045361440443,
        "soil_temp": 19.201861399468857,
        "soil_humidity": 57.90539291330657
    },
    {
        "date": "2024-09-09 20:53:09.251267",
        "air_temp": 17.50371584405101,
        "humidity": 58.021341357074306,
        "rainfall": 0.5,
        "wind_speed": 3.5666302485643264,
        "soil_moisture": 20.387981523637915,
        "ndvi": 0.6010164212704365,
        "soil_temp": 16.179395198436982,
        "soil_humidity": 57.655457808600744
    },
    {
        "date": "2024-09-09 21:53:09.251267",
        "air_temp": 28.711305744579427,
        "humidity": 79.18691255376183,
        "rainfall": 0.5,
        "wind_speed": 4.787396726840926,
        "soil_moisture": 29.638635805463885,
        "ndvi": 0.6440896362185546,
        "soil_temp": 19.806992640004253,
        "soil_humidity": 43.033423793799955
    },
    {
        "date": "2024-09-09 22:53:09.251267",
        "air_temp": 23.606117897989254,
        "humidity": 68.30903456125354,
        "rainfall": 2,
        "wind_speed": 8.359370160277741,
        "soil_moisture": 31.2794288441045,
        "ndvi": 0.4688772196790958,
        "soil_temp": 24.92579128718871,
        "soil_humidity": 61.424071696920905
    },
    {
        "date": "2024-09-09 23:53:09.251267",
        "air_temp": 19.010494534006735,
        "humidity": 55.638899460517706,
        "rainfall": 2,
        "wind_speed": 5.023840092089344,
        "soil_moisture": 27.58547062265024,
        "ndvi": 0.7625155920557498,
        "soil_temp": 24.06725447848843,
        "soil_humidity": 66.01504089390397
    },
    {
        "date": "2024-09-10 00:53:09.251267",
        "air_temp": 24.83189093482874,
        "humidity": 72.71710027463598,
        "rainfall": 2,
        "wind_speed": 6.41135172032998,
        "soil_moisture": 20.717259045104086,
        "ndvi": 0.6419940411312743,
        "soil_temp": 10.064226367541554,
        "soil_humidity": 38.74193846082252
    },
    {
        "date": "2024-09-10 01:53:09.251267",
        "air_temp": 16.284178741502682,
        "humidity": 51.600909015440536,
        "rainfall": 0,
        "wind_speed": 2.699569721432842,
        "soil_moisture": 27.31160025140633,
        "ndvi": 0.47475474791653877,
        "soil_temp": 23.281581465434584,
        "soil_humidity": 55.01987348466817
    },
    {
        "date": "2024-09-10 02:53:09.251267",
        "air_temp": 26.639428038287974,
        "humidity": 40.71907230360425,
        "rainfall": 0.5,
        "wind_speed": 5.92353531117997,
        "soil_moisture": 20.267967783692384,
        "ndvi": 0.5416011210656564,
        "soil_temp": 10.997361927621498,
        "soil_humidity": 45.685393164111005
    },
    {
        "date": "2024-09-10 03:53:09.251267",
        "air_temp": 20.379868088701954,
        "humidity": 78.21766932182138,
        "rainfall": 0.5,
        "wind_speed": 11.445676290840023,
        "soil_moisture": 12.516212156560362,
        "ndvi": 0.6155680660550604,
        "soil_temp": 24.295698792397673,
        "soil_humidity": 69.58192477179509
    },
    {
        "date": "2024-09-10 04:53:09.251267",
        "air_temp": 30.951182012743764,
        "humidity": 71.18715424320045,
        "rainfall": 0,
        "wind_speed": 8.83707416182128,
        "soil_moisture": 39.784119345580436,
        "ndvi": 0.555314108257531,
        "soil_temp": 14.22738518649527,
        "soil_humidity": 49.15331263326726
    },
    {
        "date": "2024-09-10 05:53:09.251267",
        "air_temp": 21.207239178471475,
        "humidity": 78.115131225048,
        "rainfall": 0,
        "wind_speed": 6.934375224718224,
        "soil_moisture": 13.815473608975328,
        "ndvi": 0.7272185502956456,
        "soil_temp": 15.730522456169064,
        "soil_humidity": 39.13280695187771
    },
    {
        "date": "2024-09-10 06:53:09.251267",
        "air_temp": 24.104402981636024,
        "humidity": 41.94690083438328,
        "rainfall": 0,
        "wind_speed": 14.92904257714228,
        "soil_moisture": 12.17045151508172,
        "ndvi": 0.4141331973682407,
        "soil_temp": 11.374573077635917,
        "soil_humidity": 45.2743050088733
    },
    {
        "date": "2024-09-10 07:53:09.251267",
        "air_temp": 15.232410798162013,
        "humidity": 81.85598139153261,
        "rainfall": 0.5,
        "wind_speed": 17.870199427866357,
        "soil_moisture": 36.35592247621467,
        "ndvi": 0.379931444033483,
        "soil_temp": 11.684091374933644,
        "soil_humidity": 56.15353559266505
    },
    {
        "date": "2024-09-10 08:53:09.251267",
        "air_temp": 16.448937755891176,
        "humidity": 70.97631000181516,
        "rainfall": 0.5,
        "wind_speed": 15.13578445804794,
        "soil_moisture": 19.055039826786853,
        "ndvi": 0.3283033336971586,
        "soil_temp": 20.901108478439795,
        "soil_humidity": 33.04357485398305
    },
    {
        "date": "2024-09-10 09:53:09.251267",
        "air_temp": 22.849871128133696,
        "humidity": 68.169767535632,
        "rainfall": 2,
        "wind_speed": 6.640014042353584,
        "soil_moisture": 29.50890922220523,
        "ndvi": 0.2226697808387571,
        "soil_temp": 24.164983536224376,
        "soil_humidity": 54.144076808849505
    },
    {
        "date": "2024-09-10 10:53:09.251267",
        "air_temp": 24.59877669434247,
        "humidity": 71.23070240572657,
        "rainfall": 2,
        "wind_speed": 14.888843356751075,
        "soil_moisture": 19.870171889866985,
        "ndvi": 0.2258083786280332,
        "soil_temp": 19.161227424314642,
        "soil_humidity": 67.24899036715593
    },
    {
        "date": "2024-09-10 11:53:09.251267",
        "air_temp": 27.000410962386074,
        "humidity": 83.2216623434182,
        "rainfall": 0.5,
        "wind_speed": 3.039603649481024,
        "soil_moisture": 21.743628123834135,
        "ndvi": 0.7220331158655697,
        "soil_temp": 20.737750232902165,
        "soil_humidity": 64.01451150631402
    },
    {
        "date": "2024-09-10 12:53:09.251267",
        "air_temp": 20.833251574186065,
        "humidity": 69.34682532794535,
        "rainfall": 2,
        "wind_speed": 2.6063597618258183,
        "soil_moisture": 17.863389160503896,
        "ndvi": 0.6070358878435445,
        "soil_temp": 21.4295461689573,
        "soil_humidity": 32.706941466319336
    },
    {
        "date": "2024-09-10 13:53:09.251267",
        "air_temp": 28.89963772253085,
        "humidity": 69.04751899440896,
        "rainfall": 0,
        "wind_speed": 6.912540740723179,
        "soil_moisture": 20.291320173790304,
        "ndvi": 0.5963194492618917,
        "soil_temp": 13.942214118294125,
        "soil_humidity": 69.73559514956273
    },
    {
        "date": "2024-09-10 14:53:09.251267",
        "air_temp": 32.202447943784094,
        "humidity": 89.53395785328829,
        "rainfall": 1,
        "wind_speed": 16.15404738088114,
        "soil_moisture": 22.321012017596296,
        "ndvi": 0.5687856913609317,
        "soil_temp": 10.678444463803224,
        "soil_humidity": 57.94907694815517
    },
    {
        "date": "2024-09-10 15:53:09.251267",
        "air_temp": 30.597019777866997,
        "humidity": 77.8365083534284,
        "rainfall": 2,
        "wind_speed": 4.926964944846038,
        "soil_moisture": 31.612482072770288,
        "ndvi": 0.709531486816463,
        "soil_temp": 10.028091158787529,
        "soil_humidity": 36.49123297256095
    },
    {
        "date": "2024-09-10 16:53:09.251267",
        "air_temp": 15.792376506967143,
        "humidity": 62.114690272556764,
        "rainfall": 0.5,
        "wind_speed": 1.2650681779735917,
        "soil_moisture": 25.346085827043755,
        "ndvi": 0.4881601553993765,
        "soil_temp": 15.25973924431028,
        "soil_humidity": 30.49664356677694
    },
    {
        "date": "2024-09-10 17:53:09.251267",
        "air_temp": 24.610138945156578,
        "humidity": 75.36985000164638,
        "rainfall": 2,
        "wind_speed": 2.6341907041271018,
        "soil_moisture": 38.30695651470931,
        "ndvi": 0.7222464971349802,
        "soil_temp": 13.621963422988241,
        "soil_humidity": 36.85130545168605
    },
    {
        "date": "2024-09-10 18:53:09.251267",
        "air_temp": 17.098603568363497,
        "humidity": 59.46152358458943,
        "rainfall": 2,
        "wind_speed": 17.174899370591604,
        "soil_moisture": 11.256411815163265,
        "ndvi": 0.41566701570934356,
        "soil_temp": 10.934765875737785,
        "soil_humidity": 63.52703682742954
    },
    {
        "date": "2024-09-10 19:53:09.251267",
        "air_temp": 19.84090031725055,
        "humidity": 51.44377312417495,
        "rainfall": 2,
        "wind_speed": 12.675665694945753,
        "soil_moisture": 25.092049678960844,
        "ndvi": 0.7560245026034544,
        "soil_temp": 12.692924387405,
        "soil_humidity": 30.86557299780655
    },
    {
        "date": "2024-09-10 20:53:09.251267",
        "air_temp": 34.73325186534293,
        "humidity": 69.84259384438798,
        "rainfall": 1,
        "wind_speed": 5.064023267399533,
        "soil_moisture": 27.526743664951542,
        "ndvi": 0.6191941880487442,
        "soil_temp": 11.867483474019217,
        "soil_humidity": 39.32589214472346
    },
    {
        "date": "2024-09-10 21:53:09.251267",
        "air_temp": 17.849910858030263,
        "humidity": 86.40910490569757,
        "rainfall": 0,
        "wind_speed": 11.105916506563627,
        "soil_moisture": 37.60590958222822,
        "ndvi": 0.3543062816146564,
        "soil_temp": 23.723175709260556,
        "soil_humidity": 60.29222381339501
    },
    {
        "date": "2024-09-10 22:53:09.251267",
        "air_temp": 24.97776306902712,
        "humidity": 86.45697600077314,
        "rainfall": 0.5,
        "wind_speed": 10.551592448507009,
        "soil_moisture": 11.474755708642828,
        "ndvi": 0.5384509530131751,
        "soil_temp": 12.104008201887535,
        "soil_humidity": 53.444421435925406
    },
    {
        "date": "2024-09-10 23:53:09.251267",
        "air_temp": 27.36311468636238,
        "humidity": 57.095263905274514,
        "rainfall": 1,
        "wind_speed": 13.108719568813225,
        "soil_moisture": 27.20498754805648,
        "ndvi": 0.5198183368929534,
        "soil_temp": 18.06472695981519,
        "soil_humidity": 61.30942841440768
    },
    {
        "date": "2024-09-11 00:53:09.251267",
        "air_temp": 29.049299410882078,
        "humidity": 66.38387331108476,
        "rainfall": 0.5,
        "wind_speed": 4.663751088432326,
        "soil_moisture": 35.60889785583871,
        "ndvi": 0.2827392930382004,
        "soil_temp": 18.896589326682466,
        "soil_humidity": 59.84602015307152
    },
    {
        "date": "2024-09-11 01:53:09.251267",
        "air_temp": 26.192973669738283,
        "humidity": 50.58179492849306,
        "rainfall": 0.5,
        "wind_speed": 17.288059743887953,
        "soil_moisture": 17.257831843577097,
        "ndvi": 0.5547457434503685,
        "soil_temp": 18.152341865629506,
        "soil_humidity": 32.02405548487219
    },
    {
        "date": "2024-09-11 02:53:09.251267",
        "air_temp": 15.195416948383674,
        "humidity": 89.78155547494417,
        "rainfall": 0.5,
        "wind_speed": 6.187371241600257,
        "soil_moisture": 36.28877045571812,
        "ndvi": 0.3888713742301748,
        "soil_temp": 11.485846513239819,
        "soil_humidity": 65.70045676376606
    },
    {
        "date": "2024-09-11 03:53:09.251267",
        "air_temp": 21.529226164879837,
        "humidity": 89.05589539339678,
        "rainfall": 1,
        "wind_speed": 13.882633079524737,
        "soil_moisture": 10.571302278228755,
        "ndvi": 0.23140926008576146,
        "soil_temp": 14.781975426258267,
        "soil_humidity": 39.08127337129075
    },
    {
        "date": "2024-09-11 04:53:09.251267",
        "air_temp": 25.35423286769423,
        "humidity": 72.47544648537237,
        "rainfall": 0,
        "wind_speed": 0.3224929274442978,
        "soil_moisture": 31.97363887401189,
        "ndvi": 0.769193694623939,
        "soil_temp": 10.7690549869473,
        "soil_humidity": 52.13569760919055
    },
    {
        "date": "2024-09-11 05:53:09.251267",
        "air_temp": 16.757329982896668,
        "humidity": 80.22070298387368,
        "rainfall": 0.5,
        "wind_speed": 7.453142597882945,
        "soil_moisture": 37.99339663505959,
        "ndvi": 0.5848942214385727,
        "soil_temp": 15.93504871144474,
        "soil_humidity": 55.77152626111115
    },
    {
        "date": "2024-09-11 06:53:09.251267",
        "air_temp": 22.012538624183616,
        "humidity": 75.755008064185,
        "rainfall": 0,
        "wind_speed": 7.034196359176166,
        "soil_moisture": 29.715929849201867,
        "ndvi": 0.7261513591225084,
        "soil_temp": 12.82898396907388,
        "soil_humidity": 36.745128435403814
    },
    {
        "date": "2024-09-11 07:53:09.251267",
        "air_temp": 15.66406217582733,
        "humidity": 69.6579109519734,
        "rainfall": 0,
        "wind_speed": 10.86968857166029,
        "soil_moisture": 23.17087731941344,
        "ndvi": 0.6056336030079519,
        "soil_temp": 18.424487999265697,
        "soil_humidity": 69.04925026803897
    },
    {
        "date": "2024-09-11 08:53:09.251267",
        "air_temp": 16.571569943100414,
        "humidity": 42.667399849444884,
        "rainfall": 2,
        "wind_speed": 1.45472523554393,
        "soil_moisture": 11.34643049418154,
        "ndvi": 0.4141902152840678,
        "soil_temp": 20.392125907736506,
        "soil_humidity": 49.52623824349236
    },
    {
        "date": "2024-09-11 09:53:09.251267",
        "air_temp": 22.93846552403192,
        "humidity": 62.73821712763305,
        "rainfall": 1,
        "wind_speed": 15.802627474522167,
        "soil_moisture": 37.60341439967886,
        "ndvi": 0.5076128433972869,
        "soil_temp": 17.981797903070596,
        "soil_humidity": 32.30570782389574
    },
    {
        "date": "2024-09-11 10:53:09.251267",
        "air_temp": 17.654315080861206,
        "humidity": 73.73931510837053,
        "rainfall": 1,
        "wind_speed": 4.355616035351371,
        "soil_moisture": 24.108886680747244,
        "ndvi": 0.6054480562083063,
        "soil_temp": 16.513550449170936,
        "soil_humidity": 67.49048179111361
    },
    {
        "date": "2024-09-11 11:53:09.251267",
        "air_temp": 26.35081696523116,
        "humidity": 73.87658069418424,
        "rainfall": 2,
        "wind_speed": 13.540756297640204,
        "soil_moisture": 10.86990233007844,
        "ndvi": 0.7761513951208627,
        "soil_temp": 15.810001312850385,
        "soil_humidity": 53.45071372773072
    },
    {
        "date": "2024-09-11 12:53:09.251267",
        "air_temp": 28.789299382745313,
        "humidity": 58.65613092111918,
        "rainfall": 2,
        "wind_speed": 16.064242464881865,
        "soil_moisture": 30.694632139862858,
        "ndvi": 0.35220167547301795,
        "soil_temp": 22.625376521455056,
        "soil_humidity": 57.967727919255054
    },
    {
        "date": "2024-09-11 13:53:09.251267",
        "air_temp": 31.011733982181656,
        "humidity": 87.08459933290152,
        "rainfall": 0,
        "wind_speed": 11.237539124830263,
        "soil_moisture": 24.455117607287747,
        "ndvi": 0.7249862351744707,
        "soil_temp": 13.290520975979051,
        "soil_humidity": 51.007031597368965
    },
    {
        "date": "2024-09-11 14:53:09.251267",
        "air_temp": 19.0030048848962,
        "humidity": 48.36785161183905,
        "rainfall": 0.5,
        "wind_speed": 14.81766413443971,
        "soil_moisture": 13.730359523272321,
        "ndvi": 0.7544466831765617,
        "soil_temp": 21.013275092933032,
        "soil_humidity": 63.3895181910787
    },
    {
        "date": "2024-09-11 15:53:09.251267",
        "air_temp": 18.349651645181396,
        "humidity": 65.01797231077803,
        "rainfall": 1,
        "wind_speed": 13.237206244300111,
        "soil_moisture": 16.428154279307787,
        "ndvi": 0.602249308795374,
        "soil_temp": 21.362234871101784,
        "soil_humidity": 31.325338487728438
    },
    {
        "date": "2024-09-11 16:53:09.251267",
        "air_temp": 17.091356806688005,
        "humidity": 74.54419882754114,
        "rainfall": 0.5,
        "wind_speed": 7.110595943040153,
        "soil_moisture": 11.934720413675388,
        "ndvi": 0.7587599764408028,
        "soil_temp": 16.288000109061016,
        "soil_humidity": 40.73513461108591
    },
    {
        "date": "2024-09-11 17:53:09.251267",
        "air_temp": 27.728604990872732,
        "humidity": 74.85679029459047,
        "rainfall": 0.5,
        "wind_speed": 16.177170780671307,
        "soil_moisture": 27.228806191257206,
        "ndvi": 0.43872715248642113,
        "soil_temp": 14.45950898838459,
        "soil_humidity": 48.78593425610769
    },
    {
        "date": "2024-09-11 18:53:09.251267",
        "air_temp": 29.129514529738024,
        "humidity": 72.43194783844078,
        "rainfall": 2,
        "wind_speed": 5.894027397710559,
        "soil_moisture": 28.660884377560276,
        "ndvi": 0.7718097056242657,
        "soil_temp": 17.058122290689628,
        "soil_humidity": 33.0641446525484
    },
    {
        "date": "2024-09-11 19:53:09.251267",
        "air_temp": 15.63172289651284,
        "humidity": 53.76441067259301,
        "rainfall": 0.5,
        "wind_speed": 11.424258355030343,
        "soil_moisture": 25.17226895066333,
        "ndvi": 0.621669726299585,
        "soil_temp": 11.398208888390478,
        "soil_humidity": 58.23626677306633
    },
    {
        "date": "2024-09-11 20:53:09.251267",
        "air_temp": 33.7242449248738,
        "humidity": 47.8103284343311,
        "rainfall": 0,
        "wind_speed": 14.90795478283079,
        "soil_moisture": 34.51306399007845,
        "ndvi": 0.22520346045128714,
        "soil_temp": 10.688950483436349,
        "soil_humidity": 45.74653656222563
    },
    {
        "date": "2024-09-11 21:53:09.251267",
        "air_temp": 16.03942567302945,
        "humidity": 71.79701634133554,
        "rainfall": 2,
        "wind_speed": 7.5791181962501515,
        "soil_moisture": 35.83026463924041,
        "ndvi": 0.7441236990914317,
        "soil_temp": 11.674047991778377,
        "soil_humidity": 40.90671961072576
    },
    {
        "date": "2024-09-11 22:53:09.251267",
        "air_temp": 25.82592670602141,
        "humidity": 69.96510580697189,
        "rainfall": 0.5,
        "wind_speed": 6.055352152348501,
        "soil_moisture": 28.4687319004938,
        "ndvi": 0.4906836854199156,
        "soil_temp": 12.807175471933393,
        "soil_humidity": 30.135153872336492
    },
    {
        "date": "2024-09-11 23:53:09.251267",
        "air_temp": 29.18121038901833,
        "humidity": 48.96780051446205,
        "rainfall": 2,
        "wind_speed": 9.186920203456454,
        "soil_moisture": 10.382694391222916,
        "ndvi": 0.4761961908666998,
        "soil_temp": 17.562652305070646,
        "soil_humidity": 58.79321246999815
    },
    {
        "date": "2024-09-12 00:53:09.251267",
        "air_temp": 32.41938247492171,
        "humidity": 75.25695727852818,
        "rainfall": 0,
        "wind_speed": 16.35926988698307,
        "soil_moisture": 30.856889672432068,
        "ndvi": 0.5398799635877807,
        "soil_temp": 22.737049542416834,
        "soil_humidity": 63.35237235491447
    },
    {
        "date": "2024-09-12 01:53:09.251267",
        "air_temp": 29.281738642648556,
        "humidity": 62.75859958668282,
        "rainfall": 0,
        "wind_speed": 18.598435262913295,
        "soil_moisture": 22.539976507610547,
        "ndvi": 0.28963072806273676,
        "soil_temp": 13.534634979995305,
        "soil_humidity": 45.80162142725994
    },
    {
        "date": "2024-09-12 02:53:09.251267",
        "air_temp": 31.034561661395838,
        "humidity": 73.38802958015765,
        "rainfall": 0.5,
        "wind_speed": 10.901668257197803,
        "soil_moisture": 26.96672449679161,
        "ndvi": 0.48149962914043926,
        "soil_temp": 24.02743016120167,
        "soil_humidity": 52.56871908133462
    },
    {
        "date": "2024-09-12 03:53:09.251267",
        "air_temp": 21.78900385085612,
        "humidity": 81.86835398850754,
        "rainfall": 2,
        "wind_speed": 13.229324106473037,
        "soil_moisture": 29.472418060566532,
        "ndvi": 0.6986763300306476,
        "soil_temp": 24.323618634025053,
        "soil_humidity": 39.107465037183935
    },
    {
        "date": "2024-09-12 04:53:09.251267",
        "air_temp": 31.296502274930244,
        "humidity": 48.48642142884616,
        "rainfall": 0.5,
        "wind_speed": 18.065472444778283,
        "soil_moisture": 20.507098429812302,
        "ndvi": 0.2092894754825092,
        "soil_temp": 24.38356907267808,
        "soil_humidity": 54.887905851205325
    },
    {
        "date": "2024-09-12 05:53:09.251267",
        "air_temp": 16.6022969276935,
        "humidity": 40.957243399841836,
        "rainfall": 0.5,
        "wind_speed": 1.9709370282588479,
        "soil_moisture": 37.51295515515383,
        "ndvi": 0.36375029619462285,
        "soil_temp": 22.563441020144857,
        "soil_humidity": 30.48549833260802
    },
    {
        "date": "2024-09-12 06:53:09.251267",
        "air_temp": 32.89633312121055,
        "humidity": 78.95512931978934,
        "rainfall": 2,
        "wind_speed": 4.641571557323301,
        "soil_moisture": 14.019246002493436,
        "ndvi": 0.20147275186630653,
        "soil_temp": 15.457375611965809,
        "soil_humidity": 49.545755883950335
    },
    {
        "date": "2024-09-12 07:53:09.251267",
        "air_temp": 25.951847523074726,
        "humidity": 70.4848712576308,
        "rainfall": 0.5,
        "wind_speed": 14.178000125031648,
        "soil_moisture": 30.145354218431123,
        "ndvi": 0.44942678548990905,
        "soil_temp": 11.090258665039347,
        "soil_humidity": 55.76821927362769
    },
    {
        "date": "2024-09-12 08:53:09.251267",
        "air_temp": 31.345955399249874,
        "humidity": 74.98197848110608,
        "rainfall": 0,
        "wind_speed": 16.474889238245993,
        "soil_moisture": 10.130787398697413,
        "ndvi": 0.6369893062962408,
        "soil_temp": 18.532280976497425,
        "soil_humidity": 66.72767092918936
    },
    {
        "date": "2024-09-12 09:53:09.251267",
        "air_temp": 24.046365690366002,
        "humidity": 81.90774874943213,
        "rainfall": 2,
        "wind_speed": 17.140580566611558,
        "soil_moisture": 37.739533162155425,
        "ndvi": 0.6792630974687772,
        "soil_temp": 18.380221659058506,
        "soil_humidity": 45.25343268489137
    },
    {
        "date": "2024-09-12 10:53:09.251267",
        "air_temp": 27.871553903930497,
        "humidity": 80.14054674122502,
        "rainfall": 2,
        "wind_speed": 8.302143537098651,
        "soil_moisture": 13.375123997142744,
        "ndvi": 0.7671138566762041,
        "soil_temp": 22.74963650664393,
        "soil_humidity": 60.60760811924119
    },
    {
        "date": "2024-09-12 11:53:09.251267",
        "air_temp": 25.528053218722263,
        "humidity": 88.04600734644123,
        "rainfall": 2,
        "wind_speed": 1.7867980458373367,
        "soil_moisture": 12.84652690442724,
        "ndvi": 0.565102767782472,
        "soil_temp": 24.939865764191577,
        "soil_humidity": 53.907481197740026
    },
    {
        "date": "2024-09-12 12:53:09.251267",
        "air_temp": 29.631790435106637,
        "humidity": 66.7967539426059,
        "rainfall": 2,
        "wind_speed": 8.755629545992399,
        "soil_moisture": 19.990228991567328,
        "ndvi": 0.3527761199917373,
        "soil_temp": 17.622359598402213,
        "soil_humidity": 67.08048451700321
    },
    {
        "date": "2024-09-12 13:53:09.251267",
        "air_temp": 16.63259964061179,
        "humidity": 64.42478163299526,
        "rainfall": 0.5,
        "wind_speed": 4.614691804141076,
        "soil_moisture": 21.206988581172418,
        "ndvi": 0.7544887694820195,
        "soil_temp": 15.178060465596765,
        "soil_humidity": 45.935107026377224
    },
    {
        "date": "2024-09-12 14:53:09.251267",
        "air_temp": 16.207041679811276,
        "humidity": 60.094359994536504,
        "rainfall": 0.5,
        "wind_speed": 5.823929099932483,
        "soil_moisture": 16.42900216968636,
        "ndvi": 0.6651894523282258,
        "soil_temp": 12.788525673344004,
        "soil_humidity": 42.76381359491952
    },
    {
        "date": "2024-09-12 15:53:09.251267",
        "air_temp": 19.94206468020293,
        "humidity": 47.68455214221023,
        "rainfall": 0,
        "wind_speed": 15.445351136750116,
        "soil_moisture": 27.55364982781374,
        "ndvi": 0.5446570898018868,
        "soil_temp": 10.071997372260562,
        "soil_humidity": 65.48567994776576
    },
    {
        "date": "2024-09-12 16:53:09.251267",
        "air_temp": 18.190893602263767,
        "humidity": 68.63036164410113,
        "rainfall": 1,
        "wind_speed": 1.5105417205177374,
        "soil_moisture": 23.287422941258907,
        "ndvi": 0.6173841294313617,
        "soil_temp": 17.636705387333187,
        "soil_humidity": 31.969776056331565
    },
    {
        "date": "2024-09-12 17:53:09.251267",
        "air_temp": 32.435671331844034,
        "humidity": 53.85062621629894,
        "rainfall": 1,
        "wind_speed": 16.55162434961404,
        "soil_moisture": 11.025267671232623,
        "ndvi": 0.7545898130044575,
        "soil_temp": 18.332780000538936,
        "soil_humidity": 35.45541571734707
    },
    {
        "date": "2024-09-12 18:53:09.251267",
        "air_temp": 19.384279747160885,
        "humidity": 86.06537561064877,
        "rainfall": 0,
        "wind_speed": 8.553381716483381,
        "soil_moisture": 19.589561867659018,
        "ndvi": 0.6274364170837531,
        "soil_temp": 22.37407719726096,
        "soil_humidity": 33.29617738336178
    },
    {
        "date": "2024-09-12 19:53:09.251267",
        "air_temp": 34.51730511638263,
        "humidity": 69.15954021385488,
        "rainfall": 1,
        "wind_speed": 11.9212786220119,
        "soil_moisture": 34.312987407195116,
        "ndvi": 0.42102470455084046,
        "soil_temp": 17.56865451839201,
        "soil_humidity": 63.7243982630561
    },
    {
        "date": "2024-09-12 20:53:09.251267",
        "air_temp": 21.737915835422136,
        "humidity": 69.64200902540551,
        "rainfall": 0,
        "wind_speed": 1.79304462471358,
        "soil_moisture": 30.69104604585606,
        "ndvi": 0.6278169213734581,
        "soil_temp": 23.747025229990847,
        "soil_humidity": 30.424296934812777
    },
    {
        "date": "2024-09-12 21:53:09.251267",
        "air_temp": 18.642358313773986,
        "humidity": 57.7462330438505,
        "rainfall": 0.5,
        "wind_speed": 6.747181567145592,
        "soil_moisture": 31.746125607396415,
        "ndvi": 0.22416689764123274,
        "soil_temp": 19.421252148571533,
        "soil_humidity": 64.46628621988192
    },
    {
        "date": "2024-09-12 22:53:09.251267",
        "air_temp": 30.79397014284958,
        "humidity": 42.599467598885376,
        "rainfall": 2,
        "wind_speed": 1.7940183802969156,
        "soil_moisture": 30.943507722505707,
        "ndvi": 0.4318493370983488,
        "soil_temp": 22.605043573316248,
        "soil_humidity": 48.05071181485067
    },
    {
        "date": "2024-09-12 23:53:09.251267",
        "air_temp": 28.17415551001752,
        "humidity": 41.58718826864943,
        "rainfall": 2,
        "wind_speed": 19.230874457622193,
        "soil_moisture": 19.329190641491,
        "ndvi": 0.4437938191073476,
        "soil_temp": 12.224526022116207,
        "soil_humidity": 30.161011154158828
    },
    {
        "date": "2024-09-13 00:53:09.251267",
        "air_temp": 24.96391432906278,
        "humidity": 61.16675720765987,
        "rainfall": 0.5,
        "wind_speed": 16.14523298652501,
        "soil_moisture": 12.989917662303277,
        "ndvi": 0.3001029406525008,
        "soil_temp": 22.83004195453472,
        "soil_humidity": 46.06580708848168
    },
    {
        "date": "2024-09-13 01:53:09.251267",
        "air_temp": 26.107271018752627,
        "humidity": 44.22826890486189,
        "rainfall": 2,
        "wind_speed": 9.192655087129204,
        "soil_moisture": 18.17401552099288,
        "ndvi": 0.21385128833106212,
        "soil_temp": 20.127157816657725,
        "soil_humidity": 37.472033641801424
    },
    {
        "date": "2024-09-13 02:53:09.251267",
        "air_temp": 29.384035565445277,
        "humidity": 70.34234777700695,
        "rainfall": 2,
        "wind_speed": 4.472050966473422,
        "soil_moisture": 34.95355900555478,
        "ndvi": 0.2800287419826457,
        "soil_temp": 12.553495486574958,
        "soil_humidity": 54.97601949888652
    },
    {
        "date": "2024-09-13 03:53:09.251267",
        "air_temp": 19.569094826625975,
        "humidity": 84.04865028198287,
        "rainfall": 1,
        "wind_speed": 18.669102104997044,
        "soil_moisture": 39.414123933492135,
        "ndvi": 0.5927728974782331,
        "soil_temp": 12.846453372190163,
        "soil_humidity": 57.17435159686586
    },
    {
        "date": "2024-09-13 04:53:09.251267",
        "air_temp": 34.92667832113484,
        "humidity": 84.14574082593612,
        "rainfall": 0.5,
        "wind_speed": 6.891931914539436,
        "soil_moisture": 24.81541044224684,
        "ndvi": 0.6813732898182125,
        "soil_temp": 23.89750928566332,
        "soil_humidity": 49.50110281619999
    },
    {
        "date": "2024-09-13 05:53:09.251267",
        "air_temp": 34.49586324293566,
        "humidity": 72.95227050661163,
        "rainfall": 1,
        "wind_speed": 3.219688633747495,
        "soil_moisture": 10.291010647691266,
        "ndvi": 0.7929220956361847,
        "soil_temp": 10.483230406755593,
        "soil_humidity": 31.6207810175868
    },
    {
        "date": "2024-09-13 06:53:09.251267",
        "air_temp": 28.00651372693874,
        "humidity": 50.56159157364655,
        "rainfall": 1,
        "wind_speed": 11.263287769079097,
        "soil_moisture": 14.201344364026872,
        "ndvi": 0.4078181018650966,
        "soil_temp": 16.93802414943559,
        "soil_humidity": 56.24515217468807
    },
    {
        "date": "2024-09-13 07:53:09.251267",
        "air_temp": 18.990849018582903,
        "humidity": 83.14984156794361,
        "rainfall": 2,
        "wind_speed": 18.87548240689632,
        "soil_moisture": 19.662417259487974,
        "ndvi": 0.5596275333566612,
        "soil_temp": 14.702129349011523,
        "soil_humidity": 63.68155134422085
    },
    {
        "date": "2024-09-13 08:53:09.251267",
        "air_temp": 28.604564848625827,
        "humidity": 84.3046717656604,
        "rainfall": 2,
        "wind_speed": 0.0766058100064515,
        "soil_moisture": 27.35756045778849,
        "ndvi": 0.7622869800890733,
        "soil_temp": 19.97616159997642,
        "soil_humidity": 58.81297109305606
    },
    {
        "date": "2024-09-13 09:53:09.251267",
        "air_temp": 16.443968179583518,
        "humidity": 49.83267924764793,
        "rainfall": 0.5,
        "wind_speed": 4.762496865079974,
        "soil_moisture": 12.252809374159419,
        "ndvi": 0.30022506880289157,
        "soil_temp": 22.59444081353488,
        "soil_humidity": 63.19831675821274
    },
    {
        "date": "2024-09-13 10:53:09.251267",
        "air_temp": 15.613050044116122,
        "humidity": 76.87130428853386,
        "rainfall": 2,
        "wind_speed": 18.76895280820815,
        "soil_moisture": 38.30071896129645,
        "ndvi": 0.6305873518056457,
        "soil_temp": 15.405671823348543,
        "soil_humidity": 38.0203809420355
    },
    {
        "date": "2024-09-13 11:53:09.251267",
        "air_temp": 20.153657770224275,
        "humidity": 54.32597744635615,
        "rainfall": 2,
        "wind_speed": 3.421719337143241,
        "soil_moisture": 20.95251019853202,
        "ndvi": 0.652987239889498,
        "soil_temp": 16.403531309842556,
        "soil_humidity": 65.5555175862593
    },
    {
        "date": "2024-09-13 12:53:09.251267",
        "air_temp": 24.252459134786328,
        "humidity": 80.13201561764649,
        "rainfall": 0.5,
        "wind_speed": 2.264869032796397,
        "soil_moisture": 37.377526537744686,
        "ndvi": 0.25338532152160304,
        "soil_temp": 24.728766767521215,
        "soil_humidity": 42.88773802591851
    },
    {
        "date": "2024-09-13 13:53:09.251267",
        "air_temp": 32.36545010816761,
        "humidity": 89.86189499518014,
        "rainfall": 2,
        "wind_speed": 11.40374431599609,
        "soil_moisture": 33.41565408271065,
        "ndvi": 0.7167496311098391,
        "soil_temp": 21.75874764518804,
        "soil_humidity": 30.536192570079642
    },
    {
        "date": "2024-09-13 14:53:09.251267",
        "air_temp": 29.54338139532616,
        "humidity": 41.50130026646964,
        "rainfall": 1,
        "wind_speed": 17.236876465896596,
        "soil_moisture": 32.66311817546743,
        "ndvi": 0.6878630282665064,
        "soil_temp": 23.45806196397687,
        "soil_humidity": 63.73566585316283
    },
    {
        "date": "2024-09-13 15:53:09.251267",
        "air_temp": 29.85413042399962,
        "humidity": 84.86829328954659,
        "rainfall": 0.5,
        "wind_speed": 9.542196353878523,
        "soil_moisture": 28.20158739346696,
        "ndvi": 0.22004180394646067,
        "soil_temp": 16.581433309774447,
        "soil_humidity": 67.67285075858908
    },
    {
        "date": "2024-09-13 16:53:09.251267",
        "air_temp": 23.50986668896151,
        "humidity": 71.1315266799329,
        "rainfall": 2,
        "wind_speed": 19.891285133690495,
        "soil_moisture": 28.80351451155534,
        "ndvi": 0.2546506489280309,
        "soil_temp": 13.511143059761729,
        "soil_humidity": 56.222327896693955
    },
    {
        "date": "2024-09-13 17:53:09.251267",
        "air_temp": 21.91869985093926,
        "humidity": 88.66913095824106,
        "rainfall": 0,
        "wind_speed": 13.583433990759858,
        "soil_moisture": 29.140097056487445,
        "ndvi": 0.2294761363351114,
        "soil_temp": 16.177951980796777,
        "soil_humidity": 39.257465218536986
    },
    {
        "date": "2024-09-13 18:53:09.251267",
        "air_temp": 22.420775259692054,
        "humidity": 63.25047587632138,
        "rainfall": 0.5,
        "wind_speed": 1.4814981442089548,
        "soil_moisture": 28.861641610767464,
        "ndvi": 0.40084611907961737,
        "soil_temp": 20.741378767472703,
        "soil_humidity": 40.069778571301214
    },
    {
        "date": "2024-09-13 19:53:09.251267",
        "air_temp": 34.752991274721154,
        "humidity": 82.36939652856869,
        "rainfall": 0.5,
        "wind_speed": 19.856642633639506,
        "soil_moisture": 26.611846941950837,
        "ndvi": 0.5055733944079557,
        "soil_temp": 11.64145607253969,
        "soil_humidity": 48.66371493742478
    },
    {
        "date": "2024-09-13 20:53:09.251267",
        "air_temp": 15.80218382824965,
        "humidity": 43.11970342991386,
        "rainfall": 0,
        "wind_speed": 8.157884735603808,
        "soil_moisture": 39.592420554486665,
        "ndvi": 0.5194086367727773,
        "soil_temp": 14.057036584247633,
        "soil_humidity": 65.91324318405678
    },
    {
        "date": "2024-09-13 21:53:09.251267",
        "air_temp": 32.34062992244897,
        "humidity": 56.76395250391479,
        "rainfall": 2,
        "wind_speed": 5.748050676909651,
        "soil_moisture": 31.968717022770882,
        "ndvi": 0.36060005586166566,
        "soil_temp": 10.699538506923856,
        "soil_humidity": 35.98390624590233
    },
    {
        "date": "2024-09-13 22:53:09.251267",
        "air_temp": 26.57350817144787,
        "humidity": 43.3508268875605,
        "rainfall": 2,
        "wind_speed": 11.157583671557711,
        "soil_moisture": 33.308391651775636,
        "ndvi": 0.7469581319361647,
        "soil_temp": 16.485216075071975,
        "soil_humidity": 63.038946783339476
    },
    {
        "date": "2024-09-13 23:53:09.251267",
        "air_temp": 23.77230838379181,
        "humidity": 88.76715667395354,
        "rainfall": 0.5,
        "wind_speed": 6.086151836417287,
        "soil_moisture": 36.17388445251022,
        "ndvi": 0.420957673923148,
        "soil_temp": 22.4116860396942,
        "soil_humidity": 41.65211234820602
    },
    {
        "date": "2024-09-14 00:53:09.251267",
        "air_temp": 29.50515320830273,
        "humidity": 80.84869536359184,
        "rainfall": 0.5,
        "wind_speed": 1.7291501424030464,
        "soil_moisture": 16.469192881775477,
        "ndvi": 0.22733009357921152,
        "soil_temp": 10.369955324470162,
        "soil_humidity": 62.34344677716392
    },
    {
        "date": "2024-09-14 01:53:09.251267",
        "air_temp": 24.733378828494057,
        "humidity": 82.62737106229852,
        "rainfall": 2,
        "wind_speed": 0.028566861583534386,
        "soil_moisture": 12.514124772851822,
        "ndvi": 0.3801362669248318,
        "soil_temp": 20.12985290210749,
        "soil_humidity": 66.81084790406155
    },
    {
        "date": "2024-09-14 02:53:09.251267",
        "air_temp": 32.468464761633214,
        "humidity": 86.89826157169472,
        "rainfall": 2,
        "wind_speed": 8.991667058405246,
        "soil_moisture": 24.48683309795796,
        "ndvi": 0.5186656535264933,
        "soil_temp": 23.987438117706095,
        "soil_humidity": 47.09975487977281
    },
    {
        "date": "2024-09-14 03:53:09.251267",
        "air_temp": 33.01403728022517,
        "humidity": 44.25517430598927,
        "rainfall": 1,
        "wind_speed": 16.737268444531846,
        "soil_moisture": 25.422420868578193,
        "ndvi": 0.4449330935529393,
        "soil_temp": 13.553917922667734,
        "soil_humidity": 44.60202616422123
    },
    {
        "date": "2024-09-14 04:53:09.251267",
        "air_temp": 23.434418537469107,
        "humidity": 59.279879625666666,
        "rainfall": 0.5,
        "wind_speed": 11.44630371260153,
        "soil_moisture": 34.87545339849753,
        "ndvi": 0.36430551145955553,
        "soil_temp": 20.40452261091472,
        "soil_humidity": 62.08885275885207
    },
    {
        "date": "2024-09-14 05:53:09.251267",
        "air_temp": 20.536555944701032,
        "humidity": 43.551744452200104,
        "rainfall": 0.5,
        "wind_speed": 19.984145978868817,
        "soil_moisture": 23.31350737847007,
        "ndvi": 0.27879394985859046,
        "soil_temp": 24.626528516503004,
        "soil_humidity": 51.17931529517576
    },
    {
        "date": "2024-09-14 06:53:09.251267",
        "air_temp": 26.847006571867244,
        "humidity": 50.53965507750143,
        "rainfall": 0,
        "wind_speed": 5.736932912943999,
        "soil_moisture": 27.12559076632938,
        "ndvi": 0.691498700057738,
        "soil_temp": 24.434170902286947,
        "soil_humidity": 57.37796193852788
    },
    {
        "date": "2024-09-14 07:53:09.251267",
        "air_temp": 33.24726691233382,
        "humidity": 51.468288851883614,
        "rainfall": 1,
        "wind_speed": 5.609101295201107,
        "soil_moisture": 25.812128155403748,
        "ndvi": 0.7976871452745151,
        "soil_temp": 13.52393789543715,
        "soil_humidity": 54.91316947844718
    },
    {
        "date": "2024-09-14 08:53:09.251267",
        "air_temp": 19.21324378011333,
        "humidity": 63.455360552378934,
        "rainfall": 2,
        "wind_speed": 3.194989204678884,
        "soil_moisture": 11.14608351272173,
        "ndvi": 0.3977252801143293,
        "soil_temp": 11.504965798181031,
        "soil_humidity": 36.11555382579185
    },
    {
        "date": "2024-09-14 09:53:09.251267",
        "air_temp": 27.459331671269656,
        "humidity": 53.42810837268213,
        "rainfall": 1,
        "wind_speed": 3.2812717653067036,
        "soil_moisture": 28.45644689790742,
        "ndvi": 0.4289726265059624,
        "soil_temp": 12.224238068438739,
        "soil_humidity": 63.791614411582465
    },
    {
        "date": "2024-09-14 10:53:09.251267",
        "air_temp": 27.63120440185041,
        "humidity": 45.048905340888055,
        "rainfall": 2,
        "wind_speed": 5.703731920200957,
        "soil_moisture": 34.84129857322757,
        "ndvi": 0.5708225148061743,
        "soil_temp": 20.530535027232133,
        "soil_humidity": 37.83100445652545
    },
    {
        "date": "2024-09-14 11:53:09.251267",
        "air_temp": 29.66226044830562,
        "humidity": 48.37455445470539,
        "rainfall": 2,
        "wind_speed": 15.856800905470543,
        "soil_moisture": 14.725016442274114,
        "ndvi": 0.631064884841325,
        "soil_temp": 20.886383131799136,
        "soil_humidity": 67.29128576283122
    },
    {
        "date": "2024-09-14 12:53:09.251267",
        "air_temp": 17.631353702545198,
        "humidity": 47.363778177604544,
        "rainfall": 1,
        "wind_speed": 18.11513581272896,
        "soil_moisture": 27.829952017797872,
        "ndvi": 0.4352177208821548,
        "soil_temp": 20.113548424422987,
        "soil_humidity": 40.02285165688591
    },
    {
        "date": "2024-09-14 13:53:09.251267",
        "air_temp": 29.31649929364177,
        "humidity": 88.65383989561491,
        "rainfall": 0.5,
        "wind_speed": 19.501743394153625,
        "soil_moisture": 27.80831633339243,
        "ndvi": 0.7429577101890892,
        "soil_temp": 15.04117581613357,
        "soil_humidity": 59.355238930949675
    },
    {
        "date": "2024-09-14 14:53:09.251267",
        "air_temp": 33.18065041331282,
        "humidity": 77.96462534592368,
        "rainfall": 2,
        "wind_speed": 4.645793081929053,
        "soil_moisture": 14.925548921690762,
        "ndvi": 0.536002393537351,
        "soil_temp": 12.546654096371832,
        "soil_humidity": 41.38621573974368
    },
    {
        "date": "2024-09-14 15:53:09.251267",
        "air_temp": 18.593662177404838,
        "humidity": 88.3880732634781,
        "rainfall": 1,
        "wind_speed": 17.625950912281834,
        "soil_moisture": 13.70390721192208,
        "ndvi": 0.47023404584355866,
        "soil_temp": 18.711416379283797,
        "soil_humidity": 42.71141634347455
    },
    {
        "date": "2024-09-14 16:53:09.251267",
        "air_temp": 19.750866498477553,
        "humidity": 61.987024325395424,
        "rainfall": 0,
        "wind_speed": 6.565101785546741,
        "soil_moisture": 39.40155828929454,
        "ndvi": 0.5880036518959432,
        "soil_temp": 17.255206686368,
        "soil_humidity": 42.40007411129221
    },
    {
        "date": "2024-09-14 17:53:09.251267",
        "air_temp": 34.42790188083279,
        "humidity": 53.918424083132834,
        "rainfall": 1,
        "wind_speed": 2.2568108903376882,
        "soil_moisture": 21.811575089591127,
        "ndvi": 0.771682308949504,
        "soil_temp": 20.932512206834552,
        "soil_humidity": 59.91569016104333
    },
    {
        "date": "2024-09-14 18:53:09.251267",
        "air_temp": 18.619539054189794,
        "humidity": 79.91465986045932,
        "rainfall": 0,
        "wind_speed": 17.400498510403313,
        "soil_moisture": 16.82265186170588,
        "ndvi": 0.6767313766530234,
        "soil_temp": 16.620401180284322,
        "soil_humidity": 52.272604718775725
    },
    {
        "date": "2024-09-14 19:53:09.251267",
        "air_temp": 32.08770186739159,
        "humidity": 56.31269590205734,
        "rainfall": 2,
        "wind_speed": 12.722112692872372,
        "soil_moisture": 15.139736333631248,
        "ndvi": 0.2240078783210746,
        "soil_temp": 15.079595420297025,
        "soil_humidity": 65.31809823389989
    },
    {
        "date": "2024-09-14 20:53:09.251267",
        "air_temp": 24.845557128960696,
        "humidity": 54.96916868526741,
        "rainfall": 1,
        "wind_speed": 3.2946179216212346,
        "soil_moisture": 30.028051407497053,
        "ndvi": 0.39938594644397585,
        "soil_temp": 14.402178660385216,
        "soil_humidity": 42.29490876816179
    },
    {
        "date": "2024-09-14 21:53:09.251267",
        "air_temp": 19.94462148806354,
        "humidity": 51.62529961039156,
        "rainfall": 1,
        "wind_speed": 8.596625150260051,
        "soil_moisture": 28.283358919818365,
        "ndvi": 0.48232681097073365,
        "soil_temp": 13.172054800746198,
        "soil_humidity": 57.67970088226977
    },
    {
        "date": "2024-09-14 22:53:09.251267",
        "air_temp": 32.41499802545023,
        "humidity": 46.48394891121177,
        "rainfall": 0,
        "wind_speed": 5.745153897541353,
        "soil_moisture": 29.306563689622525,
        "ndvi": 0.3887105433254632,
        "soil_temp": 13.98619564279992,
        "soil_humidity": 60.11794398652442
    },
    {
        "date": "2024-09-14 23:53:09.251267",
        "air_temp": 23.90610510005331,
        "humidity": 52.8231530508383,
        "rainfall": 1,
        "wind_speed": 14.73589137420394,
        "soil_moisture": 12.640180825343567,
        "ndvi": 0.620112536498274,
        "soil_temp": 11.422456582661521,
        "soil_humidity": 36.05928452119833
    },
    {
        "date": "2024-09-15 00:53:09.251267",
        "air_temp": 25.296347078593804,
        "humidity": 57.76598742369438,
        "rainfall": 1,
        "wind_speed": 9.06832461248536,
        "soil_moisture": 36.80603750495311,
        "ndvi": 0.6957614899175487,
        "soil_temp": 15.202855163933343,
        "soil_humidity": 46.023574292792446
    },
    {
        "date": "2024-09-15 01:53:09.251267",
        "air_temp": 22.18466738799531,
        "humidity": 73.69971040728652,
        "rainfall": 2,
        "wind_speed": 19.7855989021277,
        "soil_moisture": 21.67799190427145,
        "ndvi": 0.638807057111163,
        "soil_temp": 10.548547185444374,
        "soil_humidity": 49.312699774016444
    },
    {
        "date": "2024-09-15 02:53:09.251267",
        "air_temp": 26.85901702869855,
        "humidity": 43.12824211097258,
        "rainfall": 1,
        "wind_speed": 1.0948996451919024,
        "soil_moisture": 19.40108219291074,
        "ndvi": 0.7935999720969653,
        "soil_temp": 12.796207707494903,
        "soil_humidity": 44.22749559577497
    },
    {
        "date": "2024-09-15 03:53:09.251267",
        "air_temp": 18.270477451700454,
        "humidity": 50.52534698326715,
        "rainfall": 0.5,
        "wind_speed": 7.1003053227249335,
        "soil_moisture": 23.870843417432177,
        "ndvi": 0.3635285887358175,
        "soil_temp": 21.201556669291435,
        "soil_humidity": 42.884503838765525
    },
    {
        "date": "2024-09-15 04:53:09.251267",
        "air_temp": 22.821630733035214,
        "humidity": 80.45118201458382,
        "rainfall": 0,
        "wind_speed": 9.638003342199548,
        "soil_moisture": 21.701981972428847,
        "ndvi": 0.7249936970146529,
        "soil_temp": 14.88203483014934,
        "soil_humidity": 57.456795759905674
    },
    {
        "date": "2024-09-15 05:53:09.251267",
        "air_temp": 34.38824644670575,
        "humidity": 47.346096179188535,
        "rainfall": 1,
        "wind_speed": 10.062671094966396,
        "soil_moisture": 35.90731009417438,
        "ndvi": 0.6599735991046918,
        "soil_temp": 20.02355525466016,
        "soil_humidity": 38.63465590637954
    },
    {
        "date": "2024-09-15 06:53:09.251267",
        "air_temp": 20.16266865402255,
        "humidity": 57.13225732612608,
        "rainfall": 0,
        "wind_speed": 6.3187164477127356,
        "soil_moisture": 37.87782925593676,
        "ndvi": 0.4173072545348877,
        "soil_temp": 20.75326263395916,
        "soil_humidity": 58.375629810045154
    },
    {
        "date": "2024-09-15 07:53:09.251267",
        "air_temp": 28.134733290825842,
        "humidity": 83.23294609290701,
        "rainfall": 0,
        "wind_speed": 14.584804981951361,
        "soil_moisture": 19.103596493488187,
        "ndvi": 0.6404747261941812,
        "soil_temp": 18.717780140981702,
        "soil_humidity": 67.40176785562923
    },
    {
        "date": "2024-09-15 08:53:09.251267",
        "air_temp": 21.503801284493896,
        "humidity": 47.74626555717213,
        "rainfall": 0,
        "wind_speed": 17.494941469779494,
        "soil_moisture": 35.91807422379864,
        "ndvi": 0.2624455738509482,
        "soil_temp": 24.380466120480975,
        "soil_humidity": 52.607900847110045
    },
    {
        "date": "2024-09-15 09:53:09.251267",
        "air_temp": 30.46946251373201,
        "humidity": 44.13684178090949,
        "rainfall": 1,
        "wind_speed": 18.77438603955726,
        "soil_moisture": 33.445693885992014,
        "ndvi": 0.6736000894043315,
        "soil_temp": 10.321597999346698,
        "soil_humidity": 54.457369469578964
    },
    {
        "date": "2024-09-15 10:53:09.251267",
        "air_temp": 17.617473214366996,
        "humidity": 64.22689975400054,
        "rainfall": 1,
        "wind_speed": 16.101867535990525,
        "soil_moisture": 37.92423017034285,
        "ndvi": 0.5193983615522617,
        "soil_temp": 21.07978975882213,
        "soil_humidity": 32.75730808496356
    },
    {
        "date": "2024-09-15 11:53:09.251267",
        "air_temp": 34.39642090157089,
        "humidity": 55.12097556392682,
        "rainfall": 0,
        "wind_speed": 17.011603946131988,
        "soil_moisture": 17.372509365576633,
        "ndvi": 0.3753741036255519,
        "soil_temp": 24.38179965910612,
        "soil_humidity": 64.57907614471313
    },
    {
        "date": "2024-09-15 12:53:09.251267",
        "air_temp": 24.075790827672783,
        "humidity": 68.1704182241927,
        "rainfall": 0.5,
        "wind_speed": 11.01090601239991,
        "soil_moisture": 27.308774503629373,
        "ndvi": 0.5934663810705081,
        "soil_temp": 22.93407594029409,
        "soil_humidity": 41.41791148799462
    },
    {
        "date": "2024-09-15 13:53:09.251267",
        "air_temp": 19.72100926692928,
        "humidity": 80.19024452417375,
        "rainfall": 0,
        "wind_speed": 16.51776452853629,
        "soil_moisture": 18.236337542213338,
        "ndvi": 0.5849719992286455,
        "soil_temp": 12.5183903770752,
        "soil_humidity": 54.60204662114259
    },
    {
        "date": "2024-09-15 14:53:09.251267",
        "air_temp": 16.469934946600254,
        "humidity": 46.857424407637154,
        "rainfall": 0.5,
        "wind_speed": 6.070078182959044,
        "soil_moisture": 34.72283471453123,
        "ndvi": 0.32569173363175985,
        "soil_temp": 14.90230459377598,
        "soil_humidity": 69.1644867897921
    },
    {
        "date": "2024-09-15 15:53:09.251267",
        "air_temp": 18.395158101750816,
        "humidity": 69.03495422771425,
        "rainfall": 0,
        "wind_speed": 7.969575242184288,
        "soil_moisture": 10.159725717131927,
        "ndvi": 0.21823844888691568,
        "soil_temp": 12.58677759814698,
        "soil_humidity": 53.82010402291231
    },
    {
        "date": "2024-09-15 16:53:09.251267",
        "air_temp": 25.395478971120355,
        "humidity": 65.2754380194932,
        "rainfall": 2,
        "wind_speed": 6.338260276370886,
        "soil_moisture": 26.944574886591884,
        "ndvi": 0.46017355214100436,
        "soil_temp": 19.019625457220563,
        "soil_humidity": 58.9883794985751
    },
    {
        "date": "2024-09-15 17:53:09.251267",
        "air_temp": 21.740063528625726,
        "humidity": 47.20393930065998,
        "rainfall": 0,
        "wind_speed": 9.347746708958509,
        "soil_moisture": 20.17610293815754,
        "ndvi": 0.5417071216066528,
        "soil_temp": 20.682819729692383,
        "soil_humidity": 47.81278472895008
    },
    {
        "date": "2024-09-15 18:53:09.251267",
        "air_temp": 31.57766731765219,
        "humidity": 71.19992427644912,
        "rainfall": 0,
        "wind_speed": 5.711592324772727,
        "soil_moisture": 19.680511212334608,
        "ndvi": 0.7259631670223592,
        "soil_temp": 14.756106540445874,
        "soil_humidity": 32.4146337406023
    },
    {
        "date": "2024-09-15 19:53:09.251267",
        "air_temp": 23.617750473236118,
        "humidity": 53.68202089303858,
        "rainfall": 1,
        "wind_speed": 19.232228845088937,
        "soil_moisture": 29.347189715947508,
        "ndvi": 0.7569300773885568,
        "soil_temp": 18.09259860976443,
        "soil_humidity": 61.90397397959154
    },
    {
        "date": "2024-09-15 20:53:09.251267",
        "air_temp": 19.9742854517526,
        "humidity": 64.39171592720062,
        "rainfall": 0.5,
        "wind_speed": 6.2568925601133625,
        "soil_moisture": 35.54453347941982,
        "ndvi": 0.51728313089406,
        "soil_temp": 20.363808551067926,
        "soil_humidity": 31.399953650559425
    },
    {
        "date": "2024-09-15 21:53:09.251267",
        "air_temp": 27.342899732080603,
        "humidity": 44.11361621385309,
        "rainfall": 2,
        "wind_speed": 1.9608907622187965,
        "soil_moisture": 28.275861741799787,
        "ndvi": 0.652282090261127,
        "soil_temp": 16.320097249165727,
        "soil_humidity": 58.02631315551416
    },
    {
        "date": "2024-09-15 22:53:09.251267",
        "air_temp": 29.135544337708914,
        "humidity": 62.979202860681156,
        "rainfall": 0.5,
        "wind_speed": 10.536343321008268,
        "soil_moisture": 35.59518935940167,
        "ndvi": 0.4635819633967982,
        "soil_temp": 22.81023295683189,
        "soil_humidity": 30.916862925985477
    },
    {
        "date": "2024-09-15 23:53:09.251267",
        "air_temp": 18.340838158018997,
        "humidity": 55.323173402141016,
        "rainfall": 2,
        "wind_speed": 1.1419815689035273,
        "soil_moisture": 17.937913356430897,
        "ndvi": 0.6476492939437359,
        "soil_temp": 21.573249029317257,
        "soil_humidity": 64.49220946306681
    },
    {
        "date": "2024-09-16 00:53:09.251267",
        "air_temp": 18.35238432576635,
        "humidity": 81.11906055926772,
        "rainfall": 0,
        "wind_speed": 2.2108633261716104,
        "soil_moisture": 25.585055642949435,
        "ndvi": 0.3228809149635002,
        "soil_temp": 10.96998315775856,
        "soil_humidity": 36.305441433319224
    },
    {
        "date": "2024-09-16 01:53:09.251267",
        "air_temp": 15.733428538670859,
        "humidity": 42.84893634986842,
        "rainfall": 1,
        "wind_speed": 4.387787783924926,
        "soil_moisture": 23.516096172012283,
        "ndvi": 0.4226388408821189,
        "soil_temp": 19.316879486362435,
        "soil_humidity": 50.57085981770204
    },
    {
        "date": "2024-09-16 02:53:09.251267",
        "air_temp": 29.72804030131281,
        "humidity": 60.930363745916495,
        "rainfall": 1,
        "wind_speed": 9.287004997642834,
        "soil_moisture": 10.349346985092886,
        "ndvi": 0.5805367992881689,
        "soil_temp": 13.529883264396243,
        "soil_humidity": 53.88602817932589
    },
    {
        "date": "2024-09-16 03:53:09.251267",
        "air_temp": 28.276090552436102,
        "humidity": 62.9215712065719,
        "rainfall": 0.5,
        "wind_speed": 8.665018646180558,
        "soil_moisture": 26.704364294222202,
        "ndvi": 0.2108947793642473,
        "soil_temp": 18.36277397000913,
        "soil_humidity": 48.301816294936486
    },
    {
        "date": "2024-09-16 04:53:09.251267",
        "air_temp": 24.492617514996276,
        "humidity": 76.25030381495594,
        "rainfall": 1,
        "wind_speed": 15.993837240055823,
        "soil_moisture": 29.845729724385247,
        "ndvi": 0.6334767862033726,
        "soil_temp": 24.353326639494494,
        "soil_humidity": 43.63053957395549
    },
    {
        "date": "2024-09-16 05:53:09.251267",
        "air_temp": 31.883408979383944,
        "humidity": 68.71437875451055,
        "rainfall": 0.5,
        "wind_speed": 14.789493077682268,
        "soil_moisture": 27.757432132572102,
        "ndvi": 0.7013213007609,
        "soil_temp": 23.185930068283753,
        "soil_humidity": 48.71257184723887
    },
    {
        "date": "2024-09-16 06:53:09.251267",
        "air_temp": 31.113403059001065,
        "humidity": 73.36061685970905,
        "rainfall": 0.5,
        "wind_speed": 8.526365879814026,
        "soil_moisture": 32.088396963502646,
        "ndvi": 0.4394849966563709,
        "soil_temp": 16.067746046726974,
        "soil_humidity": 42.456630155246344
    },
    {
        "date": "2024-09-16 07:53:09.251267",
        "air_temp": 26.707087287935042,
        "humidity": 78.8524642825878,
        "rainfall": 2,
        "wind_speed": 6.674084151512599,
        "soil_moisture": 27.140185853592314,
        "ndvi": 0.4687654503062986,
        "soil_temp": 14.3727078751653,
        "soil_humidity": 47.524893883234526
    },
    {
        "date": "2024-09-16 08:53:09.251267",
        "air_temp": 32.36542561026518,
        "humidity": 83.11014868290944,
        "rainfall": 0.5,
        "wind_speed": 6.329375681229972,
        "soil_moisture": 12.002507645522709,
        "ndvi": 0.7107693273299187,
        "soil_temp": 13.516086166123884,
        "soil_humidity": 44.356519639921224
    },
    {
        "date": "2024-09-16 09:53:09.251267",
        "air_temp": 19.116824200735365,
        "humidity": 55.693774836123524,
        "rainfall": 2,
        "wind_speed": 15.47990174588018,
        "soil_moisture": 13.493365722541343,
        "ndvi": 0.6653927811956202,
        "soil_temp": 17.987723046347803,
        "soil_humidity": 52.64819294028581
    },
    {
        "date": "2024-09-16 10:53:09.251267",
        "air_temp": 17.23839238795456,
        "humidity": 66.90113526819515,
        "rainfall": 1,
        "wind_speed": 1.0414481244253948,
        "soil_moisture": 27.89423732631857,
        "ndvi": 0.37288969342002504,
        "soil_temp": 11.713726798870844,
        "soil_humidity": 39.51696648979934
    },
    {
        "date": "2024-09-16 11:53:09.251267",
        "air_temp": 20.394992230339792,
        "humidity": 82.00540392196,
        "rainfall": 0.5,
        "wind_speed": 0.2564329902121165,
        "soil_moisture": 18.249375759209205,
        "ndvi": 0.3943856639369234,
        "soil_temp": 18.239125121923728,
        "soil_humidity": 69.54626219656049
    },
    {
        "date": "2024-09-16 12:53:09.251267",
        "air_temp": 16.141737121786296,
        "humidity": 89.47255654825283,
        "rainfall": 0.5,
        "wind_speed": 15.910887838532961,
        "soil_moisture": 12.389353871042974,
        "ndvi": 0.6073269394343581,
        "soil_temp": 10.02000459236188,
        "soil_humidity": 50.03479813528662
    },
    {
        "date": "2024-09-16 13:53:09.251267",
        "air_temp": 25.62339056002064,
        "humidity": 84.47596555218816,
        "rainfall": 0,
        "wind_speed": 9.542927519780985,
        "soil_moisture": 22.888082303456404,
        "ndvi": 0.5379188554688967,
        "soil_temp": 14.857744461187671,
        "soil_humidity": 37.76648309658039
    },
    {
        "date": "2024-09-16 14:53:09.251267",
        "air_temp": 33.73211384589923,
        "humidity": 58.57336313121884,
        "rainfall": 0.5,
        "wind_speed": 2.6176227710715194,
        "soil_moisture": 26.629677942509662,
        "ndvi": 0.2973377426578982,
        "soil_temp": 13.299014707458719,
        "soil_humidity": 49.0826699826909
    },
    {
        "date": "2024-09-16 15:53:09.251267",
        "air_temp": 15.786870813370193,
        "humidity": 49.75832586093252,
        "rainfall": 0.5,
        "wind_speed": 4.711318691890025,
        "soil_moisture": 19.862379650488197,
        "ndvi": 0.38595794553749985,
        "soil_temp": 24.568281849411733,
        "soil_humidity": 64.65660518099297
    },
    {
        "date": "2024-09-16 16:53:09.251267",
        "air_temp": 17.442198280200536,
        "humidity": 64.46962687032944,
        "rainfall": 0,
        "wind_speed": 18.65007906194765,
        "soil_moisture": 31.61301106677334,
        "ndvi": 0.7399899661271925,
        "soil_temp": 20.908634793549684,
        "soil_humidity": 30.872271724975825
    },
    {
        "date": "2024-09-16 17:53:09.251267",
        "air_temp": 24.043980565668704,
        "humidity": 77.0820725918021,
        "rainfall": 0,
        "wind_speed": 17.19005951249796,
        "soil_moisture": 29.31311824303734,
        "ndvi": 0.6129046620333581,
        "soil_temp": 15.244800706933566,
        "soil_humidity": 57.18216476429156
    },
    {
        "date": "2024-09-16 18:53:09.251267",
        "air_temp": 33.6775003505581,
        "humidity": 64.62845353766488,
        "rainfall": 1,
        "wind_speed": 14.195701004479451,
        "soil_moisture": 19.98455083275016,
        "ndvi": 0.7915845906113117,
        "soil_temp": 11.392676297959309,
        "soil_humidity": 51.918711871757125
    },
    {
        "date": "2024-09-16 19:53:09.251267",
        "air_temp": 21.32312209953501,
        "humidity": 64.15904316568343,
        "rainfall": 0.5,
        "wind_speed": 9.644504606746532,
        "soil_moisture": 32.646175867256815,
        "ndvi": 0.6602075179884617,
        "soil_temp": 17.75200892035936,
        "soil_humidity": 36.78231977480542
    },
    {
        "date": "2024-09-16 20:53:09.251267",
        "air_temp": 25.144696173767848,
        "humidity": 81.91152105409003,
        "rainfall": 1,
        "wind_speed": 10.406472523827714,
        "soil_moisture": 29.460851275991065,
        "ndvi": 0.7651846592861542,
        "soil_temp": 13.201718219890635,
        "soil_humidity": 62.69905757219683
    },
    {
        "date": "2024-09-16 21:53:09.251267",
        "air_temp": 15.831457181007842,
        "humidity": 58.07018895335932,
        "rainfall": 1,
        "wind_speed": 19.504890705679173,
        "soil_moisture": 29.12336136153706,
        "ndvi": 0.32971314432228527,
        "soil_temp": 18.046443188475365,
        "soil_humidity": 44.75538489496023
    },
    {
        "date": "2024-09-16 22:53:09.251267",
        "air_temp": 17.966864019241775,
        "humidity": 82.98973374058228,
        "rainfall": 0,
        "wind_speed": 19.31540887768574,
        "soil_moisture": 26.60283102361204,
        "ndvi": 0.6628909248901556,
        "soil_temp": 22.530435312683387,
        "soil_humidity": 41.7520811526203
    },
    {
        "date": "2024-09-16 23:53:09.251267",
        "air_temp": 34.73260245917694,
        "humidity": 60.34024004758618,
        "rainfall": 2,
        "wind_speed": 4.305380915716686,
        "soil_moisture": 10.341690042656584,
        "ndvi": 0.21213051208867847,
        "soil_temp": 19.23688828152078,
        "soil_humidity": 63.12500767427604
    },
    {
        "date": "2024-09-17 00:53:09.251267",
        "air_temp": 34.30237392872091,
        "humidity": 56.41165528391697,
        "rainfall": 1,
        "wind_speed": 16.222091326106362,
        "soil_moisture": 30.895215504306673,
        "ndvi": 0.6024560130269204,
        "soil_temp": 19.50682629317503,
        "soil_humidity": 55.541001847666585
    },
    {
        "date": "2024-09-17 01:53:09.251267",
        "air_temp": 15.098799618688192,
        "humidity": 62.69949555998495,
        "rainfall": 2,
        "wind_speed": 3.944853323340336,
        "soil_moisture": 21.281253733274696,
        "ndvi": 0.48156936687214486,
        "soil_temp": 18.11545433594217,
        "soil_humidity": 65.93036334699298
    },
    {
        "date": "2024-09-17 02:53:09.251267",
        "air_temp": 34.03623570846478,
        "humidity": 78.12234793374341,
        "rainfall": 0.5,
        "wind_speed": 4.270749557679028,
        "soil_moisture": 38.27829130475966,
        "ndvi": 0.40805604315204125,
        "soil_temp": 23.908984489471138,
        "soil_humidity": 55.19534814684798
    },
    {
        "date": "2024-09-17 03:53:09.251267",
        "air_temp": 27.78239875631001,
        "humidity": 46.30010139319971,
        "rainfall": 0,
        "wind_speed": 13.521860683291536,
        "soil_moisture": 34.53954313231616,
        "ndvi": 0.7174426113758536,
        "soil_temp": 23.97854026630905,
        "soil_humidity": 59.632706317284615
    },
    {
        "date": "2024-09-17 04:53:09.251267",
        "air_temp": 32.35836589040044,
        "humidity": 49.82096079360087,
        "rainfall": 0.5,
        "wind_speed": 8.100021971750968,
        "soil_moisture": 27.630834085939366,
        "ndvi": 0.7125606155945186,
        "soil_temp": 17.69084801610328,
        "soil_humidity": 63.45783585038451
    },
    {
        "date": "2024-09-17 05:53:09.251267",
        "air_temp": 24.09479711267718,
        "humidity": 87.5722412831546,
        "rainfall": 0.5,
        "wind_speed": 18.630888142130402,
        "soil_moisture": 17.565459454578082,
        "ndvi": 0.21338102293918615,
        "soil_temp": 14.586392307152567,
        "soil_humidity": 32.18958543639748
    },
    {
        "date": "2024-09-17 06:53:09.251267",
        "air_temp": 25.311920571585006,
        "humidity": 48.77458136906787,
        "rainfall": 0,
        "wind_speed": 1.7801946979201766,
        "soil_moisture": 16.111521798329996,
        "ndvi": 0.5657030734696586,
        "soil_temp": 16.89380508819166,
        "soil_humidity": 61.04195835477521
    },
    {
        "date": "2024-09-17 07:53:09.251267",
        "air_temp": 24.776931605138405,
        "humidity": 68.38636589655273,
        "rainfall": 0.5,
        "wind_speed": 10.230512816189862,
        "soil_moisture": 20.211545130326474,
        "ndvi": 0.4206993345925739,
        "soil_temp": 12.798368682934235,
        "soil_humidity": 54.54825927398307
    },
    {
        "date": "2024-09-17 08:53:09.251267",
        "air_temp": 28.337285150922085,
        "humidity": 68.96572333309689,
        "rainfall": 0,
        "wind_speed": 18.370430608862424,
        "soil_moisture": 24.297038307400555,
        "ndvi": 0.44939660090026956,
        "soil_temp": 19.54516472977134,
        "soil_humidity": 32.78031186060773
    },
    {
        "date": "2024-09-17 09:53:09.251267",
        "air_temp": 17.793025095126257,
        "humidity": 64.48122310251225,
        "rainfall": 2,
        "wind_speed": 18.082273209590102,
        "soil_moisture": 18.881965301168414,
        "ndvi": 0.6992244435312657,
        "soil_temp": 17.5013850367335,
        "soil_humidity": 50.3431168747486
    },
    {
        "date": "2024-09-17 10:53:09.251267",
        "air_temp": 15.599471797453559,
        "humidity": 72.23726242884092,
        "rainfall": 0.5,
        "wind_speed": 3.1305842683148244,
        "soil_moisture": 25.447884861774483,
        "ndvi": 0.385903862916607,
        "soil_temp": 11.549507167199199,
        "soil_humidity": 33.29832311935816
    },
    {
        "date": "2024-09-17 11:53:09.251267",
        "air_temp": 21.158598831823817,
        "humidity": 51.49133628988092,
        "rainfall": 0,
        "wind_speed": 7.332754001929129,
        "soil_moisture": 14.961112765395578,
        "ndvi": 0.7658292863825722,
        "soil_temp": 12.96471671679423,
        "soil_humidity": 59.4378880689289
    },
    {
        "date": "2024-09-17 12:53:09.251267",
        "air_temp": 29.093615254732935,
        "humidity": 67.63311338883742,
        "rainfall": 1,
        "wind_speed": 12.382428000933924,
        "soil_moisture": 39.331384384765,
        "ndvi": 0.3077802958232845,
        "soil_temp": 17.194479342992903,
        "soil_humidity": 51.86006855591532
    },
    {
        "date": "2024-09-17 13:53:09.251267",
        "air_temp": 19.037069042458967,
        "humidity": 58.608662762344856,
        "rainfall": 0.5,
        "wind_speed": 3.1878976954280436,
        "soil_moisture": 13.237736648949816,
        "ndvi": 0.5976334540209067,
        "soil_temp": 23.313834860782876,
        "soil_humidity": 68.14294149649189
    },
    {
        "date": "2024-09-17 14:53:09.251267",
        "air_temp": 28.468648666498346,
        "humidity": 73.08336298145946,
        "rainfall": 2,
        "wind_speed": 19.360443440914775,
        "soil_moisture": 37.95832418963116,
        "ndvi": 0.6032630402082588,
        "soil_temp": 18.49586080946646,
        "soil_humidity": 37.325414862870794
    },
    {
        "date": "2024-09-17 15:53:09.251267",
        "air_temp": 34.398240922145405,
        "humidity": 47.06117716865747,
        "rainfall": 0,
        "wind_speed": 18.294986574553207,
        "soil_moisture": 37.40734034208084,
        "ndvi": 0.35131709685675094,
        "soil_temp": 20.223025003127063,
        "soil_humidity": 54.24133810081514
    },
    {
        "date": "2024-09-17 16:53:09.251267",
        "air_temp": 16.878014315788366,
        "humidity": 68.54387084197381,
        "rainfall": 0.5,
        "wind_speed": 3.3390035806893836,
        "soil_moisture": 25.76081445458494,
        "ndvi": 0.39485036538032126,
        "soil_temp": 15.085428246158646,
        "soil_humidity": 38.463285382819656
    },
    {
        "date": "2024-09-17 17:53:09.251267",
        "air_temp": 28.452042364502525,
        "humidity": 49.263517769166185,
        "rainfall": 0.5,
        "wind_speed": 2.4231092252197084,
        "soil_moisture": 31.747308155082532,
        "ndvi": 0.7008790955479642,
        "soil_temp": 18.571170895663514,
        "soil_humidity": 54.562599389095936
    },
    {
        "date": "2024-09-17 18:53:09.251267",
        "air_temp": 23.875004386091423,
        "humidity": 53.93215315575182,
        "rainfall": 0.5,
        "wind_speed": 1.708169656165306,
        "soil_moisture": 23.081448939177633,
        "ndvi": 0.6570831529729715,
        "soil_temp": 16.710697339571013,
        "soil_humidity": 58.90598809443426
    },
    {
        "date": "2024-09-17 19:53:09.251267",
        "air_temp": 32.362845087550106,
        "humidity": 50.93549001617916,
        "rainfall": 2,
        "wind_speed": 18.188873280732622,
        "soil_moisture": 28.901037326273144,
        "ndvi": 0.39745752818885577,
        "soil_temp": 19.88694868934741,
        "soil_humidity": 58.89217317318155
    },
    {
        "date": "2024-09-17 20:53:09.251267",
        "air_temp": 18.542995788947717,
        "humidity": 49.14188899344086,
        "rainfall": 0,
        "wind_speed": 3.8724761959092158,
        "soil_moisture": 20.21727812263356,
        "ndvi": 0.5203739281609604,
        "soil_temp": 10.758633491185156,
        "soil_humidity": 62.602959627304394
    },
    {
        "date": "2024-09-17 21:53:09.251267",
        "air_temp": 28.85251904452329,
        "humidity": 81.28554757803334,
        "rainfall": 1,
        "wind_speed": 8.325698570887095,
        "soil_moisture": 19.03477059356336,
        "ndvi": 0.23700817775185729,
        "soil_temp": 17.399503243584387,
        "soil_humidity": 64.14200911833267
    },
    {
        "date": "2024-09-17 22:53:09.251267",
        "air_temp": 31.762305792962206,
        "humidity": 54.28537838304452,
        "rainfall": 1,
        "wind_speed": 8.977027313040061,
        "soil_moisture": 27.34689358508541,
        "ndvi": 0.32524752943917756,
        "soil_temp": 14.439889377159504,
        "soil_humidity": 33.09086905068708
    },
    {
        "date": "2024-09-17 23:53:09.251267",
        "air_temp": 33.892284389480466,
        "humidity": 86.35618909322247,
        "rainfall": 1,
        "wind_speed": 11.98022088427135,
        "soil_moisture": 24.94389022551941,
        "ndvi": 0.49149233531623954,
        "soil_temp": 14.892775919168638,
        "soil_humidity": 43.73068628289616
    },
    {
        "date": "2024-09-18 00:53:09.251267",
        "air_temp": 28.664960565792917,
        "humidity": 88.49632626725955,
        "rainfall": 0,
        "wind_speed": 1.4347068749484992,
        "soil_moisture": 27.386091074499433,
        "ndvi": 0.251584241167527,
        "soil_temp": 24.413761473370922,
        "soil_humidity": 57.484900814392226
    },
    {
        "date": "2024-09-18 01:53:09.251267",
        "air_temp": 24.94349528016855,
        "humidity": 68.56302349685487,
        "rainfall": 1,
        "wind_speed": 4.5351723480103185,
        "soil_moisture": 18.59981743861069,
        "ndvi": 0.7059994129351626,
        "soil_temp": 12.740422190670742,
        "soil_humidity": 49.94345149564849
    },
    {
        "date": "2024-09-18 02:53:09.251267",
        "air_temp": 27.356944804024277,
        "humidity": 47.15005613328632,
        "rainfall": 2,
        "wind_speed": 18.62980773837852,
        "soil_moisture": 21.00873495450986,
        "ndvi": 0.6308037260645888,
        "soil_temp": 14.668678788678953,
        "soil_humidity": 37.62703787019166
    },
    {
        "date": "2024-09-18 03:53:09.251267",
        "air_temp": 32.37809968957436,
        "humidity": 58.727412168979065,
        "rainfall": 0,
        "wind_speed": 18.16820214537139,
        "soil_moisture": 12.51693881886728,
        "ndvi": 0.7661802023890969,
        "soil_temp": 17.648388635963077,
        "soil_humidity": 52.10838280666792
    },
    {
        "date": "2024-09-18 04:53:09.251267",
        "air_temp": 26.412194932776593,
        "humidity": 79.8866131674107,
        "rainfall": 0.5,
        "wind_speed": 15.625812948113843,
        "soil_moisture": 11.568291168166171,
        "ndvi": 0.6160801435784165,
        "soil_temp": 22.945404222244356,
        "soil_humidity": 46.29742611064575
    },
    {
        "date": "2024-09-18 05:53:09.251267",
        "air_temp": 15.607741193936738,
        "humidity": 58.36953643485352,
        "rainfall": 2,
        "wind_speed": 0.3880249123643087,
        "soil_moisture": 17.603713890190487,
        "ndvi": 0.7642408233573115,
        "soil_temp": 16.250051248095556,
        "soil_humidity": 45.6015311061722
    },
    {
        "date": "2024-09-18 06:53:09.251267",
        "air_temp": 33.61897391013956,
        "humidity": 44.350860915139435,
        "rainfall": 0.5,
        "wind_speed": 6.540135369922043,
        "soil_moisture": 12.227405779916372,
        "ndvi": 0.532707533414579,
        "soil_temp": 21.157979213322566,
        "soil_humidity": 62.390148697426554
    },
    {
        "date": "2024-09-18 07:53:09.251267",
        "air_temp": 28.790535020631225,
        "humidity": 67.8392455464094,
        "rainfall": 1,
        "wind_speed": 12.559174706858157,
        "soil_moisture": 13.113241259042221,
        "ndvi": 0.5601311666371818,
        "soil_temp": 22.845611473222036,
        "soil_humidity": 46.30393335328725
    },
    {
        "date": "2024-09-18 08:53:09.251267",
        "air_temp": 28.53026771554493,
        "humidity": 82.25604257480174,
        "rainfall": 2,
        "wind_speed": 5.503804235469514,
        "soil_moisture": 34.38785216181812,
        "ndvi": 0.7861826327757566,
        "soil_temp": 14.650245337327734,
        "soil_humidity": 31.058180219216965
    },
    {
        "date": "2024-09-18 09:53:09.251267",
        "air_temp": 19.31350304795434,
        "humidity": 79.79631935717512,
        "rainfall": 0,
        "wind_speed": 18.191733901075548,
        "soil_moisture": 11.166210910500679,
        "ndvi": 0.5852774881185563,
        "soil_temp": 15.89125275308065,
        "soil_humidity": 32.32253355564365
    },
    {
        "date": "2024-09-18 10:53:09.251267",
        "air_temp": 28.177709404653143,
        "humidity": 48.75615700651766,
        "rainfall": 2,
        "wind_speed": 12.677789236648838,
        "soil_moisture": 29.76828381804228,
        "ndvi": 0.21847194131644798,
        "soil_temp": 15.348343355325344,
        "soil_humidity": 64.24976166766481
    },
    {
        "date": "2024-09-18 11:53:09.251267",
        "air_temp": 22.877288113083168,
        "humidity": 73.63823072062334,
        "rainfall": 0.5,
        "wind_speed": 1.438134346328912,
        "soil_moisture": 18.176732464462013,
        "ndvi": 0.634281665978314,
        "soil_temp": 22.492837129268814,
        "soil_humidity": 68.42403240050085
    },
    {
        "date": "2024-09-18 12:53:09.251267",
        "air_temp": 28.02465954082984,
        "humidity": 51.025213171782354,
        "rainfall": 0,
        "wind_speed": 18.18011177671406,
        "soil_moisture": 36.146370421243795,
        "ndvi": 0.7432812152824495,
        "soil_temp": 10.665202929304018,
        "soil_humidity": 57.536373458228304
    },
    {
        "date": "2024-09-18 13:53:09.251267",
        "air_temp": 17.131860606159815,
        "humidity": 50.91037672760861,
        "rainfall": 2,
        "wind_speed": 19.15533316352445,
        "soil_moisture": 24.549768537324987,
        "ndvi": 0.6773481498765961,
        "soil_temp": 14.6263163324302,
        "soil_humidity": 44.574731764003204
    },
    {
        "date": "2024-09-18 14:53:09.251267",
        "air_temp": 28.156906092060723,
        "humidity": 83.70358866525926,
        "rainfall": 2,
        "wind_speed": 1.5542483678345098,
        "soil_moisture": 19.167293779834772,
        "ndvi": 0.5502999635752537,
        "soil_temp": 20.19112836392695,
        "soil_humidity": 67.5440126317891
    },
    {
        "date": "2024-09-18 15:53:09.251267",
        "air_temp": 34.98827451541333,
        "humidity": 52.48344763701151,
        "rainfall": 2,
        "wind_speed": 10.647204697226298,
        "soil_moisture": 25.529168070683035,
        "ndvi": 0.6027821078561099,
        "soil_temp": 18.48165249348014,
        "soil_humidity": 56.51212345502943
    },
    {
        "date": "2024-09-18 16:53:09.251267",
        "air_temp": 15.96424077721112,
        "humidity": 53.16236732590056,
        "rainfall": 2,
        "wind_speed": 6.067285644283409,
        "soil_moisture": 21.458740571358064,
        "ndvi": 0.7941150735510032,
        "soil_temp": 16.544340389405413,
        "soil_humidity": 63.77334196797351
    },
    {
        "date": "2024-09-18 17:53:09.251267",
        "air_temp": 34.54348368442635,
        "humidity": 40.03266953806498,
        "rainfall": 0,
        "wind_speed": 7.856781529628048,
        "soil_moisture": 26.929031940094905,
        "ndvi": 0.254397253105603,
        "soil_temp": 18.099223408320768,
        "soil_humidity": 60.634092161480375
    },
    {
        "date": "2024-09-18 18:53:09.251267",
        "air_temp": 23.13815921445741,
        "humidity": 83.53345170627588,
        "rainfall": 0.5,
        "wind_speed": 3.593024359106838,
        "soil_moisture": 13.435986049790447,
        "ndvi": 0.6003468971799437,
        "soil_temp": 12.963768787424774,
        "soil_humidity": 45.1655726309037
    },
    {
        "date": "2024-09-18 19:53:09.251267",
        "air_temp": 32.415069006745185,
        "humidity": 79.62974066045673,
        "rainfall": 2,
        "wind_speed": 11.56144204024825,
        "soil_moisture": 39.90971508437653,
        "ndvi": 0.37003861674235633,
        "soil_temp": 16.460749324120155,
        "soil_humidity": 49.14808803403142
    },
    {
        "date": "2024-09-18 20:53:09.251267",
        "air_temp": 30.64770968116547,
        "humidity": 71.3664467545339,
        "rainfall": 0,
        "wind_speed": 19.159587064977813,
        "soil_moisture": 22.766676330901525,
        "ndvi": 0.5840542379517021,
        "soil_temp": 17.525544762645236,
        "soil_humidity": 37.44528617472398
    },
    {
        "date": "2024-09-18 21:53:09.251267",
        "air_temp": 26.340325219733355,
        "humidity": 77.51328819694211,
        "rainfall": 0.5,
        "wind_speed": 10.06145200543218,
        "soil_moisture": 20.44691919122106,
        "ndvi": 0.5645741455303633,
        "soil_temp": 23.517836829319023,
        "soil_humidity": 41.680564017629365
    },
    {
        "date": "2024-09-18 22:53:09.251267",
        "air_temp": 29.768984185026532,
        "humidity": 47.60242837122459,
        "rainfall": 2,
        "wind_speed": 1.6582916278896231,
        "soil_moisture": 31.306295449487948,
        "ndvi": 0.29207409926489725,
        "soil_temp": 13.912527442059645,
        "soil_humidity": 61.12502645146087
    },
    {
        "date": "2024-09-18 23:53:09.251267",
        "air_temp": 32.57031122602696,
        "humidity": 62.91617972937877,
        "rainfall": 0.5,
        "wind_speed": 12.400026669548168,
        "soil_moisture": 20.916982194859976,
        "ndvi": 0.32404798744414354,
        "soil_temp": 23.017427532473988,
        "soil_humidity": 65.75913567532433
    },
    {
        "date": "2024-09-19 00:53:09.251267",
        "air_temp": 23.082806433020657,
        "humidity": 57.56096936743904,
        "rainfall": 0.5,
        "wind_speed": 6.804459889025141,
        "soil_moisture": 34.99822915086273,
        "ndvi": 0.5729777468259282,
        "soil_temp": 19.66362101238973,
        "soil_humidity": 58.97841693005647
    },
    {
        "date": "2024-09-19 01:53:09.251267",
        "air_temp": 21.540663231264773,
        "humidity": 44.68882631285082,
        "rainfall": 0,
        "wind_speed": 17.118503791825958,
        "soil_moisture": 23.628121730812758,
        "ndvi": 0.2769379683822645,
        "soil_temp": 10.305112466014014,
        "soil_humidity": 48.60694786626553
    },
    {
        "date": "2024-09-19 02:53:09.251267",
        "air_temp": 28.351867711271694,
        "humidity": 64.3065242607462,
        "rainfall": 0.5,
        "wind_speed": 8.693562966329404,
        "soil_moisture": 31.586752397963046,
        "ndvi": 0.35910852507819374,
        "soil_temp": 20.869470053490904,
        "soil_humidity": 33.4603707575899
    },
    {
        "date": "2024-09-19 03:53:09.251267",
        "air_temp": 31.15691883882517,
        "humidity": 86.03698251713178,
        "rainfall": 0,
        "wind_speed": 3.856792178844912,
        "soil_moisture": 13.223596493256597,
        "ndvi": 0.7011473286428016,
        "soil_temp": 10.533278163235826,
        "soil_humidity": 32.27791794793793
    },
    {
        "date": "2024-09-19 04:53:09.251267",
        "air_temp": 30.245702695532316,
        "humidity": 42.00295595253473,
        "rainfall": 2,
        "wind_speed": 3.822112453822899,
        "soil_moisture": 35.38357556665335,
        "ndvi": 0.3962549036009832,
        "soil_temp": 24.960716902207196,
        "soil_humidity": 44.973022584514155
    },
    {
        "date": "2024-09-19 05:53:09.251267",
        "air_temp": 30.956272977172688,
        "humidity": 54.573045674689695,
        "rainfall": 0.5,
        "wind_speed": 15.375283614423207,
        "soil_moisture": 32.0715396033433,
        "ndvi": 0.4928360937617978,
        "soil_temp": 23.089550315367966,
        "soil_humidity": 48.55286875070652
    },
    {
        "date": "2024-09-19 06:53:09.251267",
        "air_temp": 23.71166629625866,
        "humidity": 50.37704681028517,
        "rainfall": 0.5,
        "wind_speed": 5.311751966147198,
        "soil_moisture": 16.35150335385897,
        "ndvi": 0.7646842010875043,
        "soil_temp": 14.460156213100685,
        "soil_humidity": 54.36482488853187
    },
    {
        "date": "2024-09-19 07:53:09.251267",
        "air_temp": 31.35668432350043,
        "humidity": 51.907332683284814,
        "rainfall": 1,
        "wind_speed": 15.34466788318446,
        "soil_moisture": 14.358580292373599,
        "ndvi": 0.3362894533021678,
        "soil_temp": 13.861168452950581,
        "soil_humidity": 30.25958085547989
    },
    {
        "date": "2024-09-19 08:53:09.251267",
        "air_temp": 17.404181107412168,
        "humidity": 85.42172759396877,
        "rainfall": 2,
        "wind_speed": 4.117746215331987,
        "soil_moisture": 19.48877046176873,
        "ndvi": 0.4337756104148063,
        "soil_temp": 15.721616231584132,
        "soil_humidity": 69.58891561865225
    },
    {
        "date": "2024-09-19 09:53:09.251267",
        "air_temp": 25.88978195921699,
        "humidity": 63.40345271171451,
        "rainfall": 2,
        "wind_speed": 6.433727374026188,
        "soil_moisture": 39.282117580957134,
        "ndvi": 0.5638746653261275,
        "soil_temp": 15.342504479716645,
        "soil_humidity": 44.55755533616171
    },
    {
        "date": "2024-09-19 10:53:09.251267",
        "air_temp": 15.115173209962432,
        "humidity": 63.31497064699094,
        "rainfall": 1,
        "wind_speed": 9.309739448793987,
        "soil_moisture": 17.70494723701176,
        "ndvi": 0.6532033577023835,
        "soil_temp": 15.585067275780716,
        "soil_humidity": 63.92378655204486
    },
    {
        "date": "2024-09-19 11:53:09.251267",
        "air_temp": 21.491716598005404,
        "humidity": 78.02878212276411,
        "rainfall": 1,
        "wind_speed": 18.863916993869015,
        "soil_moisture": 23.683816268399,
        "ndvi": 0.7409920320276231,
        "soil_temp": 14.774148191792207,
        "soil_humidity": 51.398668226616174
    },
    {
        "date": "2024-09-19 12:53:09.251267",
        "air_temp": 22.32923069742796,
        "humidity": 47.72461020024629,
        "rainfall": 0,
        "wind_speed": 18.63462849507649,
        "soil_moisture": 10.900606771520089,
        "ndvi": 0.7492837925441092,
        "soil_temp": 19.317001102724973,
        "soil_humidity": 35.21757818133386
    },
    {
        "date": "2024-09-19 13:53:09.251267",
        "air_temp": 22.923453832185107,
        "humidity": 64.36326770500254,
        "rainfall": 0,
        "wind_speed": 4.137437508445756,
        "soil_moisture": 21.916825886986977,
        "ndvi": 0.6659840446235739,
        "soil_temp": 12.46395495580936,
        "soil_humidity": 61.90262210138087
    },
    {
        "date": "2024-09-19 14:53:09.251267",
        "air_temp": 28.909344133797244,
        "humidity": 61.499260706052986,
        "rainfall": 0.5,
        "wind_speed": 7.164380824861456,
        "soil_moisture": 27.139600462993084,
        "ndvi": 0.3369985019559863,
        "soil_temp": 22.6897193866902,
        "soil_humidity": 58.393082996795044
    },
    {
        "date": "2024-09-19 15:53:09.251267",
        "air_temp": 22.771162025406916,
        "humidity": 69.8455345273328,
        "rainfall": 0.5,
        "wind_speed": 2.9879877690754464,
        "soil_moisture": 38.59373837523896,
        "ndvi": 0.2148855980255395,
        "soil_temp": 22.186037260469732,
        "soil_humidity": 51.097443397169116
    },
    {
        "date": "2024-09-19 16:53:09.251267",
        "air_temp": 23.973872452450614,
        "humidity": 89.97788516252193,
        "rainfall": 0.5,
        "wind_speed": 10.199701586269942,
        "soil_moisture": 25.502081348201934,
        "ndvi": 0.43026435821337433,
        "soil_temp": 17.147248117829058,
        "soil_humidity": 50.5821236533512
    },
    {
        "date": "2024-09-19 17:53:09.251267",
        "air_temp": 19.75088261748655,
        "humidity": 78.47244470410234,
        "rainfall": 0.5,
        "wind_speed": 9.21165101389333,
        "soil_moisture": 34.573219785330636,
        "ndvi": 0.284929310592694,
        "soil_temp": 22.790949479178217,
        "soil_humidity": 33.49918423003712
    },
    {
        "date": "2024-09-19 18:53:09.251267",
        "air_temp": 22.4650358325868,
        "humidity": 59.893285150871364,
        "rainfall": 0,
        "wind_speed": 18.015203249014647,
        "soil_moisture": 26.413829277067016,
        "ndvi": 0.6262325884629789,
        "soil_temp": 13.7071760504544,
        "soil_humidity": 56.233957942954966
    },
    {
        "date": "2024-09-19 19:53:09.251267",
        "air_temp": 19.545392545263027,
        "humidity": 81.37765261370234,
        "rainfall": 2,
        "wind_speed": 18.600107555821452,
        "soil_moisture": 11.223550403065543,
        "ndvi": 0.5966783520954302,
        "soil_temp": 10.263481835559505,
        "soil_humidity": 66.44980623017199
    },
    {
        "date": "2024-09-19 20:53:09.251267",
        "air_temp": 16.46391847536887,
        "humidity": 48.53541816562401,
        "rainfall": 0.5,
        "wind_speed": 7.671347820145842,
        "soil_moisture": 19.45024825569322,
        "ndvi": 0.2263829849728729,
        "soil_temp": 19.288118875447346,
        "soil_humidity": 33.729796768854335
    },
    {
        "date": "2024-09-19 21:53:09.251267",
        "air_temp": 27.06897186762756,
        "humidity": 41.523184315448006,
        "rainfall": 2,
        "wind_speed": 9.83475886037979,
        "soil_moisture": 27.266485234933096,
        "ndvi": 0.7019562674785707,
        "soil_temp": 16.868641104470733,
        "soil_humidity": 40.5404580259562
    },
    {
        "date": "2024-09-19 22:53:09.251267",
        "air_temp": 28.364255971100327,
        "humidity": 50.222418623213656,
        "rainfall": 0.5,
        "wind_speed": 17.924724982922662,
        "soil_moisture": 38.51781897793373,
        "ndvi": 0.3492459597747094,
        "soil_temp": 18.50746819443806,
        "soil_humidity": 66.09231893768396
    },
    {
        "date": "2024-09-19 23:53:09.251267",
        "air_temp": 27.389806920192292,
        "humidity": 57.02057780571298,
        "rainfall": 1,
        "wind_speed": 16.13415615490123,
        "soil_moisture": 35.773910792536995,
        "ndvi": 0.45933624529462536,
        "soil_temp": 23.490091135276046,
        "soil_humidity": 41.373756392791734
    },
    {
        "date": "2024-09-20 00:53:09.251267",
        "air_temp": 24.26988087601145,
        "humidity": 65.529037872978,
        "rainfall": 0.5,
        "wind_speed": 0.07312622689298554,
        "soil_moisture": 16.850668425365427,
        "ndvi": 0.6007602412734455,
        "soil_temp": 24.548883603489152,
        "soil_humidity": 56.15774688719705
    },
    {
        "date": "2024-09-20 01:53:09.251267",
        "air_temp": 22.595715603584594,
        "humidity": 70.75645702706,
        "rainfall": 0.5,
        "wind_speed": 6.4011912836716505,
        "soil_moisture": 16.603294900574905,
        "ndvi": 0.45765127211135204,
        "soil_temp": 19.516797096334045,
        "soil_humidity": 51.39272659772577
    },
    {
        "date": "2024-09-20 02:53:09.251267",
        "air_temp": 32.26667299143651,
        "humidity": 85.53028586072108,
        "rainfall": 0.5,
        "wind_speed": 8.435484838722518,
        "soil_moisture": 16.035189013417025,
        "ndvi": 0.4647726816799661,
        "soil_temp": 21.460833847786574,
        "soil_humidity": 53.85310420015448
    },
    {
        "date": "2024-09-20 03:53:09.251267",
        "air_temp": 25.38163570272436,
        "humidity": 65.49270047752289,
        "rainfall": 0,
        "wind_speed": 4.594325673664635,
        "soil_moisture": 37.674966889075094,
        "ndvi": 0.23937679720144422,
        "soil_temp": 11.257126502307464,
        "soil_humidity": 46.63155606309012
    },
    {
        "date": "2024-09-20 04:53:09.251267",
        "air_temp": 24.58363755259462,
        "humidity": 65.06378223128647,
        "rainfall": 1,
        "wind_speed": 3.991432317918775,
        "soil_moisture": 35.57998459305162,
        "ndvi": 0.44086612955600235,
        "soil_temp": 12.410733773372584,
        "soil_humidity": 41.99965882214845
    },
    {
        "date": "2024-09-20 05:53:09.251267",
        "air_temp": 15.512841316128714,
        "humidity": 42.51215049114911,
        "rainfall": 0.5,
        "wind_speed": 0.7556901319135934,
        "soil_moisture": 33.24650296758846,
        "ndvi": 0.6589087359319902,
        "soil_temp": 20.998072074294647,
        "soil_humidity": 31.94133802918676
    },
    {
        "date": "2024-09-20 06:53:09.251267",
        "air_temp": 21.824956552523126,
        "humidity": 41.74557763281119,
        "rainfall": 0.5,
        "wind_speed": 12.86739450139521,
        "soil_moisture": 21.782053426726826,
        "ndvi": 0.7491058023119588,
        "soil_temp": 16.47048184582003,
        "soil_humidity": 59.77123757176656
    },
    {
        "date": "2024-09-20 07:53:09.251267",
        "air_temp": 22.60391237571714,
        "humidity": 67.5579980958186,
        "rainfall": 2,
        "wind_speed": 5.2502058096633135,
        "soil_moisture": 25.131421128963005,
        "ndvi": 0.4480982822380231,
        "soil_temp": 16.689479056066084,
        "soil_humidity": 59.84373848776907
    },
    {
        "date": "2024-09-20 08:53:09.251267",
        "air_temp": 22.976455617938008,
        "humidity": 61.90893066241723,
        "rainfall": 1,
        "wind_speed": 1.0469435686262618,
        "soil_moisture": 23.929952343854584,
        "ndvi": 0.4541514961274474,
        "soil_temp": 14.62911522453155,
        "soil_humidity": 56.829029603148925
    },
    {
        "date": "2024-09-20 09:53:09.251267",
        "air_temp": 26.603447384546648,
        "humidity": 81.95901974901493,
        "rainfall": 0.5,
        "wind_speed": 4.619586210561273,
        "soil_moisture": 17.60194322678118,
        "ndvi": 0.6215762637941913,
        "soil_temp": 19.110345752166157,
        "soil_humidity": 55.74535152478482
    },
    {
        "date": "2024-09-20 10:53:09.251267",
        "air_temp": 25.67205093453757,
        "humidity": 48.033979235592255,
        "rainfall": 2,
        "wind_speed": 16.03827905672253,
        "soil_moisture": 25.150841384463007,
        "ndvi": 0.7656632006631661,
        "soil_temp": 12.77747568124304,
        "soil_humidity": 39.74868528670105
    },
    {
        "date": "2024-09-20 11:53:09.251267",
        "air_temp": 27.158101855868892,
        "humidity": 41.24858310585248,
        "rainfall": 2,
        "wind_speed": 17.029783632930933,
        "soil_moisture": 10.914896435582634,
        "ndvi": 0.36023656414361616,
        "soil_temp": 19.403744189708434,
        "soil_humidity": 30.89379735065165
    },
    {
        "date": "2024-09-20 12:53:09.251267",
        "air_temp": 30.297665230842565,
        "humidity": 62.45193839650629,
        "rainfall": 0,
        "wind_speed": 9.661324903866053,
        "soil_moisture": 13.954311204923775,
        "ndvi": 0.4640865239227622,
        "soil_temp": 12.606982857028594,
        "soil_humidity": 62.348615875417586
    },
    {
        "date": "2024-09-20 13:53:09.251267",
        "air_temp": 31.25971477432187,
        "humidity": 51.873927837612875,
        "rainfall": 2,
        "wind_speed": 8.321413408173724,
        "soil_moisture": 36.597687885068396,
        "ndvi": 0.6775839461313808,
        "soil_temp": 10.957390520645705,
        "soil_humidity": 49.645541427833074
    },
    {
        "date": "2024-09-20 14:53:09.251267",
        "air_temp": 29.362461524529888,
        "humidity": 42.480718666941875,
        "rainfall": 0.5,
        "wind_speed": 19.557073870035634,
        "soil_moisture": 32.5503930109427,
        "ndvi": 0.5658161416244232,
        "soil_temp": 14.955365330485915,
        "soil_humidity": 42.27352522810473
    },
    {
        "date": "2024-09-20 15:53:09.251267",
        "air_temp": 34.110473919098695,
        "humidity": 76.22991434238145,
        "rainfall": 0,
        "wind_speed": 4.011293191761398,
        "soil_moisture": 22.68605227177261,
        "ndvi": 0.26635180637837286,
        "soil_temp": 13.467105130147715,
        "soil_humidity": 34.0772762712228
    },
    {
        "date": "2024-09-20 16:53:09.251267",
        "air_temp": 15.364651653527233,
        "humidity": 45.579649997862376,
        "rainfall": 0,
        "wind_speed": 4.30718565709217,
        "soil_moisture": 25.514510868516318,
        "ndvi": 0.5766396413373596,
        "soil_temp": 23.334874578344987,
        "soil_humidity": 32.50886363605554
    },
    {
        "date": "2024-09-20 17:53:09.251267",
        "air_temp": 18.915559713670923,
        "humidity": 70.41597500038858,
        "rainfall": 0.5,
        "wind_speed": 5.8373435407279946,
        "soil_moisture": 21.099966578799325,
        "ndvi": 0.3120477707583962,
        "soil_temp": 10.824301808029398,
        "soil_humidity": 43.954668871807094
    },
    {
        "date": "2024-09-20 18:53:09.251267",
        "air_temp": 15.151257499562842,
        "humidity": 54.052487508344456,
        "rainfall": 1,
        "wind_speed": 3.4551463656217463,
        "soil_moisture": 32.223396014820175,
        "ndvi": 0.5061295604470426,
        "soil_temp": 12.649205388611843,
        "soil_humidity": 62.18948259067762
    },
    {
        "date": "2024-09-20 19:53:09.251267",
        "air_temp": 27.949494283092058,
        "humidity": 48.674802517751104,
        "rainfall": 2,
        "wind_speed": 7.587311964083732,
        "soil_moisture": 33.52561537696239,
        "ndvi": 0.5174619605962631,
        "soil_temp": 18.230794083745742,
        "soil_humidity": 66.74894997335322
    },
    {
        "date": "2024-09-20 20:53:09.251267",
        "air_temp": 32.96061097899974,
        "humidity": 58.987576145965875,
        "rainfall": 0,
        "wind_speed": 18.216684539795075,
        "soil_moisture": 32.89855570609477,
        "ndvi": 0.725722017334991,
        "soil_temp": 17.216007862712104,
        "soil_humidity": 65.87005458452259
    },
    {
        "date": "2024-09-20 21:53:09.251267",
        "air_temp": 19.869645931525987,
        "humidity": 80.07228881114769,
        "rainfall": 1,
        "wind_speed": 12.306956812571485,
        "soil_moisture": 31.67835174503349,
        "ndvi": 0.47749656440746296,
        "soil_temp": 10.687780275620524,
        "soil_humidity": 66.8000333899862
    },
    {
        "date": "2024-09-20 22:53:09.251267",
        "air_temp": 33.54069089592613,
        "humidity": 59.604678845228634,
        "rainfall": 0.5,
        "wind_speed": 8.238930972245273,
        "soil_moisture": 16.135130160783874,
        "ndvi": 0.36766545334153655,
        "soil_temp": 10.108786420227167,
        "soil_humidity": 66.30901372517371
    },
    {
        "date": "2024-09-20 23:53:09.251267",
        "air_temp": 16.205347805791217,
        "humidity": 77.53848246055279,
        "rainfall": 2,
        "wind_speed": 18.688215891424296,
        "soil_moisture": 20.139099377214364,
        "ndvi": 0.338579808929619,
        "soil_temp": 24.821019129417248,
        "soil_humidity": 51.08899006401583
    },
    {
        "date": "2024-09-21 00:53:09.251267",
        "air_temp": 33.68872053611028,
        "humidity": 46.27431483995753,
        "rainfall": 2,
        "wind_speed": 0.9692846495164464,
        "soil_moisture": 24.095363049510247,
        "ndvi": 0.7245511260106017,
        "soil_temp": 19.882520505382573,
        "soil_humidity": 38.77662554227615
    },
    {
        "date": "2024-09-21 01:53:09.251267",
        "air_temp": 22.03245374479269,
        "humidity": 78.63857098325396,
        "rainfall": 0,
        "wind_speed": 4.205523558037227,
        "soil_moisture": 19.92378974282967,
        "ndvi": 0.3026065126509371,
        "soil_temp": 15.481091963523353,
        "soil_humidity": 61.92186331347281
    },
    {
        "date": "2024-09-21 02:53:09.251267",
        "air_temp": 17.02841656898433,
        "humidity": 51.84666624533243,
        "rainfall": 2,
        "wind_speed": 11.31777210203518,
        "soil_moisture": 32.648206004749866,
        "ndvi": 0.4555211365298863,
        "soil_temp": 17.05648371494862,
        "soil_humidity": 38.54851899410316
    },
    {
        "date": "2024-09-21 03:53:09.251267",
        "air_temp": 24.717435183726515,
        "humidity": 73.86965649444363,
        "rainfall": 2,
        "wind_speed": 12.311052463863582,
        "soil_moisture": 19.88060572749864,
        "ndvi": 0.31230386895616824,
        "soil_temp": 13.998251806814304,
        "soil_humidity": 40.688291070262764
    },
    {
        "date": "2024-09-21 04:53:09.251267",
        "air_temp": 20.13553135441016,
        "humidity": 68.28907774204981,
        "rainfall": 2,
        "wind_speed": 0.7911501312522629,
        "soil_moisture": 14.146962603560116,
        "ndvi": 0.6639824240686253,
        "soil_temp": 15.173106755398146,
        "soil_humidity": 39.33017287290194
    },
    {
        "date": "2024-09-21 05:53:09.251267",
        "air_temp": 20.697458039238455,
        "humidity": 86.45723586058438,
        "rainfall": 2,
        "wind_speed": 8.060775158142643,
        "soil_moisture": 21.971213561449122,
        "ndvi": 0.48462358398477146,
        "soil_temp": 19.73566700902321,
        "soil_humidity": 50.599296147832234
    },
    {
        "date": "2024-09-21 06:53:09.251267",
        "air_temp": 21.14579929852573,
        "humidity": 59.3661258987657,
        "rainfall": 0.5,
        "wind_speed": 12.983079296617454,
        "soil_moisture": 39.39743298347141,
        "ndvi": 0.41068621433408725,
        "soil_temp": 23.24217698301056,
        "soil_humidity": 42.87310032676319
    },
    {
        "date": "2024-09-21 07:53:09.251267",
        "air_temp": 31.060517956653268,
        "humidity": 43.30825944172202,
        "rainfall": 1,
        "wind_speed": 13.961410928068032,
        "soil_moisture": 16.776442388007275,
        "ndvi": 0.41665978437588014,
        "soil_temp": 12.761950346217773,
        "soil_humidity": 36.166241985013656
    },
    {
        "date": "2024-09-21 08:53:09.251267",
        "air_temp": 25.78322553350509,
        "humidity": 40.95777427756264,
        "rainfall": 0.5,
        "wind_speed": 12.762798050325387,
        "soil_moisture": 28.36475976829687,
        "ndvi": 0.7113285240337877,
        "soil_temp": 22.60542258077978,
        "soil_humidity": 48.12693647575529
    },
    {
        "date": "2024-09-21 09:53:09.251267",
        "air_temp": 21.22615398312007,
        "humidity": 81.34292804863765,
        "rainfall": 0.5,
        "wind_speed": 6.4112644872184905,
        "soil_moisture": 22.98919149519119,
        "ndvi": 0.5322643725766629,
        "soil_temp": 11.823349442774036,
        "soil_humidity": 64.95628955140668
    },
    {
        "date": "2024-09-21 10:53:09.251267",
        "air_temp": 27.206675666218366,
        "humidity": 66.23678188307161,
        "rainfall": 0,
        "wind_speed": 1.8704024719581902,
        "soil_moisture": 12.1307370294798,
        "ndvi": 0.6307207106787582,
        "soil_temp": 13.224883353825868,
        "soil_humidity": 52.822114197998886
    },
    {
        "date": "2024-09-21 11:53:09.251267",
        "air_temp": 29.32301348446387,
        "humidity": 78.76220259174747,
        "rainfall": 2,
        "wind_speed": 8.542248928386682,
        "soil_moisture": 24.636298179941782,
        "ndvi": 0.7382231097504108,
        "soil_temp": 10.55762932074469,
        "soil_humidity": 65.13342423550348
    },
    {
        "date": "2024-09-21 12:53:09.251267",
        "air_temp": 20.452480014670677,
        "humidity": 51.72128000057573,
        "rainfall": 0.5,
        "wind_speed": 2.5750887473611095,
        "soil_moisture": 31.064929859449375,
        "ndvi": 0.7410450056243854,
        "soil_temp": 12.112177931394992,
        "soil_humidity": 37.834887403165496
    },
    {
        "date": "2024-09-21 13:53:09.251267",
        "air_temp": 23.270982030507028,
        "humidity": 57.254187275661685,
        "rainfall": 2,
        "wind_speed": 18.95247317041334,
        "soil_moisture": 25.396599274834564,
        "ndvi": 0.3971054326027179,
        "soil_temp": 23.75954718196076,
        "soil_humidity": 36.67895494690216
    },
    {
        "date": "2024-09-21 14:53:09.251267",
        "air_temp": 17.43772186673895,
        "humidity": 41.50519361056595,
        "rainfall": 0.5,
        "wind_speed": 9.503417320452565,
        "soil_moisture": 36.163181912397434,
        "ndvi": 0.7714707578878313,
        "soil_temp": 16.891581199275677,
        "soil_humidity": 53.02308502793681
    },
    {
        "date": "2024-09-21 15:53:09.251267",
        "air_temp": 18.622986990693605,
        "humidity": 88.07461622870561,
        "rainfall": 0.5,
        "wind_speed": 6.345183487137351,
        "soil_moisture": 18.66811049972748,
        "ndvi": 0.2149255292087633,
        "soil_temp": 11.164987926675304,
        "soil_humidity": 48.53315606185092
    },
    {
        "date": "2024-09-21 16:53:09.251267",
        "air_temp": 28.622357079299654,
        "humidity": 73.39339533504068,
        "rainfall": 0,
        "wind_speed": 15.326081422378001,
        "soil_moisture": 12.587475139118947,
        "ndvi": 0.42610133660969945,
        "soil_temp": 24.39590257058442,
        "soil_humidity": 56.40690566587828
    },
    {
        "date": "2024-09-21 17:53:09.251267",
        "air_temp": 18.62876695391254,
        "humidity": 86.66025438796531,
        "rainfall": 1,
        "wind_speed": 9.958971851645277,
        "soil_moisture": 38.09696834974018,
        "ndvi": 0.7374793145053051,
        "soil_temp": 23.18488461637975,
        "soil_humidity": 61.78507164107126
    },
    {
        "date": "2024-09-21 18:53:09.251267",
        "air_temp": 25.503267673341202,
        "humidity": 53.274820371453615,
        "rainfall": 1,
        "wind_speed": 6.200680480249002,
        "soil_moisture": 21.34589048584657,
        "ndvi": 0.3647821278901898,
        "soil_temp": 21.320237813022118,
        "soil_humidity": 60.05229182608706
    },
    {
        "date": "2024-09-21 19:53:09.251267",
        "air_temp": 29.180925234448654,
        "humidity": 70.57318056128304,
        "rainfall": 0.5,
        "wind_speed": 16.239354847816035,
        "soil_moisture": 27.292166543609,
        "ndvi": 0.36384887019279105,
        "soil_temp": 24.002091879213367,
        "soil_humidity": 67.69044694634245
    },
    {
        "date": "2024-09-21 20:53:09.251267",
        "air_temp": 17.137538461894877,
        "humidity": 73.92499841463189,
        "rainfall": 0,
        "wind_speed": 17.34304302002244,
        "soil_moisture": 33.76009378390588,
        "ndvi": 0.38157337104913325,
        "soil_temp": 13.301941312536462,
        "soil_humidity": 45.93025636628046
    },
    {
        "date": "2024-09-21 21:53:09.251267",
        "air_temp": 26.346244392578072,
        "humidity": 55.87748501287942,
        "rainfall": 2,
        "wind_speed": 13.46016332577354,
        "soil_moisture": 19.729409916267418,
        "ndvi": 0.5673880309081534,
        "soil_temp": 22.545539395151295,
        "soil_humidity": 38.25546968992723
    },
    {
        "date": "2024-09-21 22:53:09.251267",
        "air_temp": 20.131255674027276,
        "humidity": 82.40546175078151,
        "rainfall": 2,
        "wind_speed": 0.9392884481533459,
        "soil_moisture": 31.484936145328334,
        "ndvi": 0.4629996653686123,
        "soil_temp": 20.349867945314593,
        "soil_humidity": 52.03650559791543
    },
    {
        "date": "2024-09-21 23:53:09.251267",
        "air_temp": 34.2585375047516,
        "humidity": 87.35807314501238,
        "rainfall": 0.5,
        "wind_speed": 11.326457603330642,
        "soil_moisture": 14.06256549453484,
        "ndvi": 0.6360409018120057,
        "soil_temp": 23.046388743515326,
        "soil_humidity": 34.429724807647425
    },
    {
        "date": "2024-09-22 00:53:09.251267",
        "air_temp": 24.670912934398494,
        "humidity": 84.24275588480022,
        "rainfall": 1,
        "wind_speed": 15.265898239945631,
        "soil_moisture": 18.73881241616516,
        "ndvi": 0.7369463855387997,
        "soil_temp": 21.953673486139447,
        "soil_humidity": 41.55404493326526
    },
    {
        "date": "2024-09-22 01:53:09.251267",
        "air_temp": 31.119850995503164,
        "humidity": 76.95334879696863,
        "rainfall": 0,
        "wind_speed": 2.7300602416716435,
        "soil_moisture": 14.341319084095598,
        "ndvi": 0.6045196249657164,
        "soil_temp": 15.197425947652375,
        "soil_humidity": 38.31552221052578
    },
    {
        "date": "2024-09-22 02:53:09.251267",
        "air_temp": 26.004530843125234,
        "humidity": 53.8732339862477,
        "rainfall": 1,
        "wind_speed": 4.573415027692067,
        "soil_moisture": 34.336916752040736,
        "ndvi": 0.32268003211854535,
        "soil_temp": 21.615978055258836,
        "soil_humidity": 45.86254867188323
    },
    {
        "date": "2024-09-22 03:53:09.251267",
        "air_temp": 15.868250657096382,
        "humidity": 54.080802105281585,
        "rainfall": 2,
        "wind_speed": 17.649902101362727,
        "soil_moisture": 16.679477056803563,
        "ndvi": 0.7596091414163013,
        "soil_temp": 13.936844544773354,
        "soil_humidity": 34.448663000088615
    },
    {
        "date": "2024-09-22 04:53:09.251267",
        "air_temp": 27.663027511880447,
        "humidity": 88.1680160223019,
        "rainfall": 1,
        "wind_speed": 0.40243443706148074,
        "soil_moisture": 31.522106662156308,
        "ndvi": 0.35869572400848715,
        "soil_temp": 14.928882445420822,
        "soil_humidity": 31.273669781923914
    },
    {
        "date": "2024-09-22 05:53:09.251267",
        "air_temp": 34.028066844133136,
        "humidity": 40.5142247967846,
        "rainfall": 2,
        "wind_speed": 15.05454275651952,
        "soil_moisture": 38.29895191736958,
        "ndvi": 0.2899365771968949,
        "soil_temp": 11.618585758344642,
        "soil_humidity": 56.27176018520127
    },
    {
        "date": "2024-09-22 06:53:09.251267",
        "air_temp": 27.032236402926625,
        "humidity": 75.80249866210723,
        "rainfall": 0,
        "wind_speed": 9.475465850288458,
        "soil_moisture": 25.83316627206631,
        "ndvi": 0.2539803673165766,
        "soil_temp": 24.02614923970703,
        "soil_humidity": 54.409959131592196
    },
    {
        "date": "2024-09-22 07:53:09.251267",
        "air_temp": 31.383777188535802,
        "humidity": 75.31230812776312,
        "rainfall": 0,
        "wind_speed": 8.935443176792138,
        "soil_moisture": 26.20087576782063,
        "ndvi": 0.5380302443996994,
        "soil_temp": 14.365050451004223,
        "soil_humidity": 58.334357441546445
    },
    {
        "date": "2024-09-22 08:53:09.251267",
        "air_temp": 32.68412926696816,
        "humidity": 71.13447704748987,
        "rainfall": 0.5,
        "wind_speed": 0.1419306498977435,
        "soil_moisture": 23.83100704293436,
        "ndvi": 0.2682574774883911,
        "soil_temp": 17.80933697757178,
        "soil_humidity": 56.41545761085376
    },
    {
        "date": "2024-09-22 09:53:09.251267",
        "air_temp": 19.56159543897865,
        "humidity": 89.50544230434329,
        "rainfall": 0.5,
        "wind_speed": 16.387964619329857,
        "soil_moisture": 19.776704301809282,
        "ndvi": 0.28326553046757846,
        "soil_temp": 15.86999254699289,
        "soil_humidity": 34.57178724569534
    },
    {
        "date": "2024-09-22 10:53:09.251267",
        "air_temp": 19.240896798164535,
        "humidity": 55.59397178818626,
        "rainfall": 1,
        "wind_speed": 7.196498012731329,
        "soil_moisture": 24.269650927747513,
        "ndvi": 0.380018288357298,
        "soil_temp": 24.398921808752522,
        "soil_humidity": 41.64315525502585
    },
    {
        "date": "2024-09-22 11:53:09.251267",
        "air_temp": 27.219619777019837,
        "humidity": 56.99859318966241,
        "rainfall": 0,
        "wind_speed": 10.815312626010858,
        "soil_moisture": 28.084225968002784,
        "ndvi": 0.36899180581067836,
        "soil_temp": 15.025737564077236,
        "soil_humidity": 35.181064396014094
    },
    {
        "date": "2024-09-22 12:53:09.251267",
        "air_temp": 23.220569396134096,
        "humidity": 43.946970147327775,
        "rainfall": 0,
        "wind_speed": 13.546013726713188,
        "soil_moisture": 39.579747165119784,
        "ndvi": 0.5625437061992855,
        "soil_temp": 21.678187321340335,
        "soil_humidity": 63.718896081942525
    },
    {
        "date": "2024-09-22 13:53:09.251267",
        "air_temp": 31.797226057334406,
        "humidity": 62.13990682718688,
        "rainfall": 0.5,
        "wind_speed": 14.052591528758317,
        "soil_moisture": 38.132936779546725,
        "ndvi": 0.690047167683609,
        "soil_temp": 22.831242319467698,
        "soil_humidity": 59.87614621422093
    },
    {
        "date": "2024-09-22 14:53:09.251267",
        "air_temp": 33.00046246796022,
        "humidity": 53.05242631174241,
        "rainfall": 2,
        "wind_speed": 19.119601176521446,
        "soil_moisture": 35.86458874879365,
        "ndvi": 0.6568097937217355,
        "soil_temp": 16.53409746221449,
        "soil_humidity": 38.0610801167297
    },
    {
        "date": "2024-09-22 15:53:09.251267",
        "air_temp": 22.068427586054362,
        "humidity": 57.16933510888221,
        "rainfall": 1,
        "wind_speed": 10.626799064732182,
        "soil_moisture": 36.673916071145754,
        "ndvi": 0.3574906728298861,
        "soil_temp": 15.672577216793968,
        "soil_humidity": 43.65236961959574
    },
    {
        "date": "2024-09-22 16:53:09.251267",
        "air_temp": 19.73741117948964,
        "humidity": 81.72858951278138,
        "rainfall": 1,
        "wind_speed": 7.9943910194885515,
        "soil_moisture": 24.035475498761286,
        "ndvi": 0.347186298791588,
        "soil_temp": 21.21618731398942,
        "soil_humidity": 68.66459969056856
    },
    {
        "date": "2024-09-22 17:53:09.251267",
        "air_temp": 30.610510304877238,
        "humidity": 86.77422567637478,
        "rainfall": 0.5,
        "wind_speed": 19.074072529834158,
        "soil_moisture": 29.723329815323943,
        "ndvi": 0.7794749180641807,
        "soil_temp": 23.693439704593512,
        "soil_humidity": 53.766374315186056
    },
    {
        "date": "2024-09-22 18:53:09.251267",
        "air_temp": 20.49612071096272,
        "humidity": 49.286653600259285,
        "rainfall": 0,
        "wind_speed": 12.842204700510422,
        "soil_moisture": 36.852001688086425,
        "ndvi": 0.2049417449551761,
        "soil_temp": 21.323975996083576,
        "soil_humidity": 62.04622389204629
    },
    {
        "date": "2024-09-22 19:53:09.251267",
        "air_temp": 31.45228637027357,
        "humidity": 58.625446577673294,
        "rainfall": 0.5,
        "wind_speed": 5.963007125531112,
        "soil_moisture": 21.788522949740592,
        "ndvi": 0.7278323198361296,
        "soil_temp": 13.406895483877985,
        "soil_humidity": 40.583641390749165
    },
    {
        "date": "2024-09-22 20:53:09.251267",
        "air_temp": 23.474765072975256,
        "humidity": 86.4315281057697,
        "rainfall": 0.5,
        "wind_speed": 2.529284908071423,
        "soil_moisture": 38.85287435036406,
        "ndvi": 0.319837111473443,
        "soil_temp": 23.32461621156558,
        "soil_humidity": 38.402116807172206
    },
    {
        "date": "2024-09-22 21:53:09.251267",
        "air_temp": 28.35099798582941,
        "humidity": 43.09042303446113,
        "rainfall": 0.5,
        "wind_speed": 16.851090029303627,
        "soil_moisture": 22.47358186207549,
        "ndvi": 0.7683705938538608,
        "soil_temp": 15.813725011552496,
        "soil_humidity": 61.35667715386194
    },
    {
        "date": "2024-09-22 22:53:09.251267",
        "air_temp": 16.910706276693364,
        "humidity": 44.618026405568926,
        "rainfall": 0.5,
        "wind_speed": 7.243632055883031,
        "soil_moisture": 28.03247996179647,
        "ndvi": 0.40538437089651186,
        "soil_temp": 18.643441176669015,
        "soil_humidity": 57.434898209257604
    },
    {
        "date": "2024-09-22 23:53:09.251267",
        "air_temp": 27.477186493531462,
        "humidity": 48.13954946031147,
        "rainfall": 2,
        "wind_speed": 13.209394427288624,
        "soil_moisture": 31.294141398171156,
        "ndvi": 0.46421106433595144,
        "soil_temp": 22.471534289390668,
        "soil_humidity": 57.23911716847055
    },
    {
        "date": "2024-09-23 00:53:09.251267",
        "air_temp": 24.035353574744583,
        "humidity": 69.76064170634373,
        "rainfall": 2,
        "wind_speed": 9.416328387317963,
        "soil_moisture": 38.51776039227815,
        "ndvi": 0.6242723683666287,
        "soil_temp": 12.825921511007383,
        "soil_humidity": 52.10538327316776
    },
    {
        "date": "2024-09-23 01:53:09.251267",
        "air_temp": 26.732169263509483,
        "humidity": 47.52361355029987,
        "rainfall": 0,
        "wind_speed": 3.978595595900767,
        "soil_moisture": 17.33848822156798,
        "ndvi": 0.5526014308839562,
        "soil_temp": 21.767652035163294,
        "soil_humidity": 52.543133711061955
    },
    {
        "date": "2024-09-23 02:53:09.251267",
        "air_temp": 18.36028415489727,
        "humidity": 88.40381907253668,
        "rainfall": 0.5,
        "wind_speed": 12.033732172409302,
        "soil_moisture": 32.38392934715927,
        "ndvi": 0.39076359782409376,
        "soil_temp": 18.942441968660162,
        "soil_humidity": 59.42790021225015
    },
    {
        "date": "2024-09-23 03:53:09.251267",
        "air_temp": 29.73747489888364,
        "humidity": 62.33572636292602,
        "rainfall": 2,
        "wind_speed": 1.2136613790136463,
        "soil_moisture": 25.624467584322467,
        "ndvi": 0.5322985495206942,
        "soil_temp": 23.400124627414122,
        "soil_humidity": 40.800099244847814
    },
    {
        "date": "2024-09-23 04:53:09.251267",
        "air_temp": 32.25594155086457,
        "humidity": 65.07589801750832,
        "rainfall": 0,
        "wind_speed": 8.266849197423845,
        "soil_moisture": 18.056714218619234,
        "ndvi": 0.6384241306177318,
        "soil_temp": 12.579556027653144,
        "soil_humidity": 44.110738634278235
    },
    {
        "date": "2024-09-23 05:53:09.251267",
        "air_temp": 19.334796001815512,
        "humidity": 52.332930790270325,
        "rainfall": 0.5,
        "wind_speed": 10.932298793273421,
        "soil_moisture": 33.29820519389172,
        "ndvi": 0.22540096754149527,
        "soil_temp": 16.310233555613927,
        "soil_humidity": 64.8654708208022
    },
    {
        "date": "2024-09-23 06:53:09.251267",
        "air_temp": 16.914291110403234,
        "humidity": 63.548012621736675,
        "rainfall": 1,
        "wind_speed": 8.72093948237158,
        "soil_moisture": 26.566668405215395,
        "ndvi": 0.20671958315342248,
        "soil_temp": 22.49264639300256,
        "soil_humidity": 42.35460804048631
    },
    {
        "date": "2024-09-23 07:53:09.251267",
        "air_temp": 15.472771720926728,
        "humidity": 73.09568163185057,
        "rainfall": 2,
        "wind_speed": 14.973443505019816,
        "soil_moisture": 29.829214619490273,
        "ndvi": 0.47484016344373486,
        "soil_temp": 10.288475551851402,
        "soil_humidity": 51.85238285517196
    },
    {
        "date": "2024-09-23 08:53:09.251267",
        "air_temp": 27.83943000886532,
        "humidity": 77.55885684770215,
        "rainfall": 0,
        "wind_speed": 16.55849909090625,
        "soil_moisture": 19.68703205733703,
        "ndvi": 0.2791019706821085,
        "soil_temp": 11.683020501484643,
        "soil_humidity": 58.10557856026736
    },
    {
        "date": "2024-09-23 09:53:09.251267",
        "air_temp": 27.141880721775614,
        "humidity": 77.69340413549565,
        "rainfall": 1,
        "wind_speed": 15.42486148066356,
        "soil_moisture": 24.43821855482815,
        "ndvi": 0.5986065748788287,
        "soil_temp": 24.85847819135102,
        "soil_humidity": 69.99699307332706
    },
    {
        "date": "2024-09-23 10:53:09.251267",
        "air_temp": 25.9339482542814,
        "humidity": 68.89455563470442,
        "rainfall": 0.5,
        "wind_speed": 0.7762589562360422,
        "soil_moisture": 28.520627529642013,
        "ndvi": 0.32843147373646053,
        "soil_temp": 14.205912678855258,
        "soil_humidity": 47.41165325981328
    },
    {
        "date": "2024-09-23 11:53:09.251267",
        "air_temp": 19.63894192231951,
        "humidity": 85.21798874969286,
        "rainfall": 0.5,
        "wind_speed": 3.882575408919444,
        "soil_moisture": 22.14732442809532,
        "ndvi": 0.45971066407740263,
        "soil_temp": 24.6331479058593,
        "soil_humidity": 47.123715064997754
    },
    {
        "date": "2024-09-23 12:53:09.251267",
        "air_temp": 22.81811991811029,
        "humidity": 80.87039236530546,
        "rainfall": 0.5,
        "wind_speed": 10.732150185275486,
        "soil_moisture": 15.951176195431039,
        "ndvi": 0.3186025621245614,
        "soil_temp": 12.737973265130108,
        "soil_humidity": 63.7035351773467
    },
    {
        "date": "2024-09-23 13:53:09.251267",
        "air_temp": 26.88952670372346,
        "humidity": 77.85240573493127,
        "rainfall": 2,
        "wind_speed": 18.696440808876027,
        "soil_moisture": 38.849214769459124,
        "ndvi": 0.5290901514494748,
        "soil_temp": 15.416416645110035,
        "soil_humidity": 62.22012445039453
    },
    {
        "date": "2024-09-23 14:53:09.251267",
        "air_temp": 24.93533717718339,
        "humidity": 42.78027608402303,
        "rainfall": 0,
        "wind_speed": 16.69913550104234,
        "soil_moisture": 25.344026201278524,
        "ndvi": 0.2696320756307414,
        "soil_temp": 21.29304166755356,
        "soil_humidity": 59.24303526938194
    },
    {
        "date": "2024-09-23 15:53:09.251267",
        "air_temp": 34.755710404297744,
        "humidity": 40.343898110982465,
        "rainfall": 1,
        "wind_speed": 16.850690167036035,
        "soil_moisture": 32.017611108631016,
        "ndvi": 0.21386411167248817,
        "soil_temp": 19.866676108572676,
        "soil_humidity": 48.52918549636115
    },
    {
        "date": "2024-09-23 16:53:09.251267",
        "air_temp": 17.72879505113466,
        "humidity": 50.62236385977365,
        "rainfall": 0,
        "wind_speed": 6.064163875193498,
        "soil_moisture": 22.331415806681743,
        "ndvi": 0.23067898286401833,
        "soil_temp": 18.716835998935835,
        "soil_humidity": 42.957564806428636
    },
    {
        "date": "2024-09-23 17:53:09.251267",
        "air_temp": 28.902891080951875,
        "humidity": 73.17617969567262,
        "rainfall": 2,
        "wind_speed": 8.588957941574051,
        "soil_moisture": 20.653952920771488,
        "ndvi": 0.7725550649091861,
        "soil_temp": 17.948487637075115,
        "soil_humidity": 54.349219303393944
    },
    {
        "date": "2024-09-23 18:53:09.251267",
        "air_temp": 23.086375362085843,
        "humidity": 60.55987444412729,
        "rainfall": 0,
        "wind_speed": 9.414118946318748,
        "soil_moisture": 22.21492895401375,
        "ndvi": 0.2853717311665615,
        "soil_temp": 22.651606654971104,
        "soil_humidity": 65.04893172526565
    },
    {
        "date": "2024-09-23 19:53:09.251267",
        "air_temp": 23.563992298512296,
        "humidity": 60.120312964965585,
        "rainfall": 2,
        "wind_speed": 3.1474316669982083,
        "soil_moisture": 22.275899313569493,
        "ndvi": 0.6250869518821012,
        "soil_temp": 13.524228442500892,
        "soil_humidity": 65.35109832834345
    },
    {
        "date": "2024-09-23 20:53:09.251267",
        "air_temp": 29.351955313163526,
        "humidity": 84.2293444114807,
        "rainfall": 0.5,
        "wind_speed": 0.6213626048891263,
        "soil_moisture": 26.325403478258607,
        "ndvi": 0.24075572253369576,
        "soil_temp": 16.637063904902774,
        "soil_humidity": 34.33993380281754
    },
    {
        "date": "2024-09-23 21:53:09.251267",
        "air_temp": 28.84872302648739,
        "humidity": 84.77814240659194,
        "rainfall": 1,
        "wind_speed": 18.938057989210336,
        "soil_moisture": 26.304096099440894,
        "ndvi": 0.2648962113411093,
        "soil_temp": 12.331784382346859,
        "soil_humidity": 47.13401135252603
    },
    {
        "date": "2024-09-23 22:53:09.251267",
        "air_temp": 34.825119874043764,
        "humidity": 85.45876320267928,
        "rainfall": 0.5,
        "wind_speed": 4.814251713270135,
        "soil_moisture": 24.91461447648615,
        "ndvi": 0.5053257508456008,
        "soil_temp": 18.939123935960566,
        "soil_humidity": 48.58887824794998
    },
    {
        "date": "2024-09-23 23:53:09.251267",
        "air_temp": 17.567885789082503,
        "humidity": 55.69390382575136,
        "rainfall": 0,
        "wind_speed": 4.798978074472626,
        "soil_moisture": 14.043573547523142,
        "ndvi": 0.47892255809260625,
        "soil_temp": 13.758166364069803,
        "soil_humidity": 69.10461470095012
    },
    {
        "date": "2024-09-24 00:53:09.251267",
        "air_temp": 17.082192987683328,
        "humidity": 74.5527906369631,
        "rainfall": 0.5,
        "wind_speed": 1.6675810753751907,
        "soil_moisture": 24.882264355015934,
        "ndvi": 0.7709481856639218,
        "soil_temp": 15.007630965150788,
        "soil_humidity": 57.119920609703385
    },
    {
        "date": "2024-09-24 01:53:09.251267",
        "air_temp": 29.48677634257482,
        "humidity": 53.58165906371848,
        "rainfall": 1,
        "wind_speed": 18.57015503887557,
        "soil_moisture": 15.073117012619544,
        "ndvi": 0.7669012718124426,
        "soil_temp": 17.068760749352922,
        "soil_humidity": 46.28985669048282
    },
    {
        "date": "2024-09-24 02:53:09.251267",
        "air_temp": 26.56773834012818,
        "humidity": 49.53049165372249,
        "rainfall": 0.5,
        "wind_speed": 11.59474229879544,
        "soil_moisture": 13.784073588872955,
        "ndvi": 0.6677651855254791,
        "soil_temp": 10.401558785546918,
        "soil_humidity": 31.04231543942372
    },
    {
        "date": "2024-09-24 03:53:09.251267",
        "air_temp": 20.48321333119612,
        "humidity": 49.23162286736846,
        "rainfall": 1,
        "wind_speed": 13.340737142507077,
        "soil_moisture": 37.979371520235034,
        "ndvi": 0.5612610980764099,
        "soil_temp": 11.379069038812565,
        "soil_humidity": 52.61540640396784
    },
    {
        "date": "2024-09-24 04:53:09.251267",
        "air_temp": 16.588387379779732,
        "humidity": 57.105381043179705,
        "rainfall": 1,
        "wind_speed": 18.23153774526105,
        "soil_moisture": 28.148500394431128,
        "ndvi": 0.4729761904358067,
        "soil_temp": 20.288938310623294,
        "soil_humidity": 33.843039025534246
    },
    {
        "date": "2024-09-24 05:53:09.251267",
        "air_temp": 16.71316499192861,
        "humidity": 61.497880988991085,
        "rainfall": 0.5,
        "wind_speed": 6.9124033780157905,
        "soil_moisture": 29.78470174373753,
        "ndvi": 0.41370712059705267,
        "soil_temp": 16.43454971076261,
        "soil_humidity": 63.37799829391052
    },
    {
        "date": "2024-09-24 06:53:09.251267",
        "air_temp": 32.883817493282365,
        "humidity": 81.54745855275343,
        "rainfall": 2,
        "wind_speed": 12.430146222761682,
        "soil_moisture": 22.053810110000914,
        "ndvi": 0.7682525281853334,
        "soil_temp": 22.97185332999843,
        "soil_humidity": 60.10074642281448
    },
    {
        "date": "2024-09-24 07:53:09.251267",
        "air_temp": 18.83734649437486,
        "humidity": 45.994554673635605,
        "rainfall": 0,
        "wind_speed": 0.7759890945777492,
        "soil_moisture": 37.26942675744593,
        "ndvi": 0.5617452868548114,
        "soil_temp": 20.42008005466299,
        "soil_humidity": 48.420366396327154
    },
    {
        "date": "2024-09-24 08:53:09.251267",
        "air_temp": 21.467431242310415,
        "humidity": 76.74629236974471,
        "rainfall": 2,
        "wind_speed": 3.7354505646225866,
        "soil_moisture": 29.137914687389966,
        "ndvi": 0.5474435166115967,
        "soil_temp": 10.121514124709837,
        "soil_humidity": 47.16843118166799
    },
    {
        "date": "2024-09-24 09:53:09.251267",
        "air_temp": 19.533128044539758,
        "humidity": 66.57274570946527,
        "rainfall": 2,
        "wind_speed": 16.62491610179885,
        "soil_moisture": 33.07509608570763,
        "ndvi": 0.339140673061349,
        "soil_temp": 19.250684961507023,
        "soil_humidity": 65.89355862275075
    },
    {
        "date": "2024-09-24 10:53:09.251267",
        "air_temp": 22.09992611663875,
        "humidity": 54.39726713379962,
        "rainfall": 0.5,
        "wind_speed": 15.335367126766714,
        "soil_moisture": 21.056874070831416,
        "ndvi": 0.22801869073106784,
        "soil_temp": 11.405872990895746,
        "soil_humidity": 41.53833401672514
    },
    {
        "date": "2024-09-24 11:53:09.251267",
        "air_temp": 16.38847685492187,
        "humidity": 64.64097922664124,
        "rainfall": 0,
        "wind_speed": 7.012853829142937,
        "soil_moisture": 38.80035642457376,
        "ndvi": 0.7671131478557267,
        "soil_temp": 21.71399769685741,
        "soil_humidity": 39.96079848427083
    },
    {
        "date": "2024-09-24 12:53:09.251267",
        "air_temp": 25.38119581816541,
        "humidity": 54.99710348885113,
        "rainfall": 0,
        "wind_speed": 7.53621255548425,
        "soil_moisture": 34.613838704749455,
        "ndvi": 0.3882411553067924,
        "soil_temp": 24.87261532417753,
        "soil_humidity": 67.56789731303049
    },
    {
        "date": "2024-09-24 13:53:09.251267",
        "air_temp": 16.3522512718443,
        "humidity": 69.8040977619153,
        "rainfall": 2,
        "wind_speed": 10.671088671071589,
        "soil_moisture": 35.618453890436314,
        "ndvi": 0.46555341399467526,
        "soil_temp": 17.967981913622673,
        "soil_humidity": 62.369833470139305
    },
    {
        "date": "2024-09-24 14:53:09.251267",
        "air_temp": 31.007130156447232,
        "humidity": 61.692196142762214,
        "rainfall": 0.5,
        "wind_speed": 0.004820093702690986,
        "soil_moisture": 15.16582802277263,
        "ndvi": 0.26878396615142414,
        "soil_temp": 20.241692502911956,
        "soil_humidity": 62.82502372268779
    },
    {
        "date": "2024-09-24 15:53:09.251267",
        "air_temp": 19.67424163820866,
        "humidity": 48.22027645394394,
        "rainfall": 0,
        "wind_speed": 4.824886720760557,
        "soil_moisture": 18.128896236136583,
        "ndvi": 0.7021545979455752,
        "soil_temp": 13.559712413421273,
        "soil_humidity": 37.80775257103702
    },
    {
        "date": "2024-09-24 16:53:09.251267",
        "air_temp": 25.800238292090214,
        "humidity": 45.829153727784835,
        "rainfall": 2,
        "wind_speed": 4.164636172164289,
        "soil_moisture": 18.568797990507314,
        "ndvi": 0.21657909971355838,
        "soil_temp": 16.563966156306904,
        "soil_humidity": 35.73183965723737
    },
    {
        "date": "2024-09-24 17:53:09.251267",
        "air_temp": 32.60158175122916,
        "humidity": 67.33478410360651,
        "rainfall": 0,
        "wind_speed": 5.009658934995269,
        "soil_moisture": 18.127276697406135,
        "ndvi": 0.700394457259458,
        "soil_temp": 22.841429983545755,
        "soil_humidity": 31.041181992685395
    },
    {
        "date": "2024-09-24 18:53:09.251267",
        "air_temp": 28.01754738094388,
        "humidity": 85.12065166430517,
        "rainfall": 0.5,
        "wind_speed": 16.118116850668827,
        "soil_moisture": 35.662244863128635,
        "ndvi": 0.2841710076727801,
        "soil_temp": 12.511344216193768,
        "soil_humidity": 39.4816603567472
    },
    {
        "date": "2024-09-24 19:53:09.251267",
        "air_temp": 25.659155731026708,
        "humidity": 57.19788432788428,
        "rainfall": 0.5,
        "wind_speed": 5.593477130928863,
        "soil_moisture": 10.956543856257763,
        "ndvi": 0.7319384675786913,
        "soil_temp": 13.983034084206432,
        "soil_humidity": 60.96761966857299
    },
    {
        "date": "2024-09-24 20:53:09.251267",
        "air_temp": 21.486675062307512,
        "humidity": 76.65000536113115,
        "rainfall": 0.5,
        "wind_speed": 3.830426441002963,
        "soil_moisture": 12.28299966942323,
        "ndvi": 0.6378635824016945,
        "soil_temp": 16.681505129290453,
        "soil_humidity": 39.60153675056599
    },
    {
        "date": "2024-09-24 21:53:09.251267",
        "air_temp": 21.660038261163958,
        "humidity": 72.93306774698834,
        "rainfall": 0,
        "wind_speed": 10.085269215860631,
        "soil_moisture": 18.21744611889711,
        "ndvi": 0.2988021575043831,
        "soil_temp": 20.68373337766021,
        "soil_humidity": 59.16413134379505
    },
    {
        "date": "2024-09-24 22:53:09.251267",
        "air_temp": 28.3897391039638,
        "humidity": 86.57774571547405,
        "rainfall": 1,
        "wind_speed": 17.31290072745464,
        "soil_moisture": 15.996189239483309,
        "ndvi": 0.5394907957156809,
        "soil_temp": 15.638259321935108,
        "soil_humidity": 40.07633061469686
    },
    {
        "date": "2024-09-24 23:53:09.251267",
        "air_temp": 34.88278722442335,
        "humidity": 81.04905086495705,
        "rainfall": 0,
        "wind_speed": 4.8269940767499975,
        "soil_moisture": 16.95578841648545,
        "ndvi": 0.3875937886131317,
        "soil_temp": 23.915984196745057,
        "soil_humidity": 45.81169991802687
    },
    {
        "date": "2024-09-25 00:53:09.251267",
        "air_temp": 28.236783796987574,
        "humidity": 68.33026294527315,
        "rainfall": 1,
        "wind_speed": 1.5707248558559739,
        "soil_moisture": 33.938295677599,
        "ndvi": 0.3069398593469501,
        "soil_temp": 11.416855769030787,
        "soil_humidity": 69.32649159645634
    },
    {
        "date": "2024-09-25 01:53:09.251267",
        "air_temp": 26.1556683474005,
        "humidity": 72.85152491170537,
        "rainfall": 0.5,
        "wind_speed": 7.128094716993294,
        "soil_moisture": 26.893797816761406,
        "ndvi": 0.7155093196466651,
        "soil_temp": 15.562062963172712,
        "soil_humidity": 39.792469251365425
    },
    {
        "date": "2024-09-25 02:53:09.251267",
        "air_temp": 29.61301020478492,
        "humidity": 84.91932332920175,
        "rainfall": 2,
        "wind_speed": 14.953036491299557,
        "soil_moisture": 10.17311983878904,
        "ndvi": 0.4421136599043377,
        "soil_temp": 20.63431185142633,
        "soil_humidity": 45.05482509193071
    },
    {
        "date": "2024-09-25 03:53:09.251267",
        "air_temp": 24.30411224567436,
        "humidity": 59.975939229346736,
        "rainfall": 1,
        "wind_speed": 11.013810893895892,
        "soil_moisture": 22.87968297112586,
        "ndvi": 0.43912622861922956,
        "soil_temp": 13.389801484922387,
        "soil_humidity": 42.04348721860855
    },
    {
        "date": "2024-09-25 04:53:09.251267",
        "air_temp": 16.202846852015703,
        "humidity": 56.33973529288887,
        "rainfall": 0.5,
        "wind_speed": 16.33586739148695,
        "soil_moisture": 22.236547805510767,
        "ndvi": 0.30193200488397204,
        "soil_temp": 19.846197013445092,
        "soil_humidity": 34.384999453200095
    },
    {
        "date": "2024-09-25 05:53:09.251267",
        "air_temp": 26.245936317558197,
        "humidity": 40.540554181759816,
        "rainfall": 0.5,
        "wind_speed": 19.19184295218468,
        "soil_moisture": 13.418358083336306,
        "ndvi": 0.4298394056514673,
        "soil_temp": 12.463254373410189,
        "soil_humidity": 49.595765685233
    },
    {
        "date": "2024-09-25 06:53:09.251267",
        "air_temp": 34.1525056901076,
        "humidity": 81.32755370957321,
        "rainfall": 1,
        "wind_speed": 15.85324953503234,
        "soil_moisture": 30.284253658043713,
        "ndvi": 0.7229701077973101,
        "soil_temp": 10.839704272925296,
        "soil_humidity": 45.14762653332009
    },
    {
        "date": "2024-09-25 07:53:09.251267",
        "air_temp": 18.50605884715319,
        "humidity": 80.04903502016825,
        "rainfall": 2,
        "wind_speed": 12.076061576875068,
        "soil_moisture": 31.06291565138473,
        "ndvi": 0.4178772262937816,
        "soil_temp": 11.815235751229732,
        "soil_humidity": 57.795483432291206
    },
    {
        "date": "2024-09-25 08:53:09.251267",
        "air_temp": 28.800097816413313,
        "humidity": 45.22272590500757,
        "rainfall": 0.5,
        "wind_speed": 9.86683176104761,
        "soil_moisture": 31.62679657156293,
        "ndvi": 0.770962137499873,
        "soil_temp": 23.949771747012022,
        "soil_humidity": 62.70378187806185
    },
    {
        "date": "2024-09-25 09:53:09.251267",
        "air_temp": 19.018673773181128,
        "humidity": 68.84286905663501,
        "rainfall": 1,
        "wind_speed": 0.8013817269653334,
        "soil_moisture": 31.854128366757763,
        "ndvi": 0.7677853395250325,
        "soil_temp": 18.276263244093272,
        "soil_humidity": 51.76998857982554
    },
    {
        "date": "2024-09-25 10:53:09.251267",
        "air_temp": 25.716553689461627,
        "humidity": 63.19908490834437,
        "rainfall": 2,
        "wind_speed": 19.686439576280826,
        "soil_moisture": 10.001584807968904,
        "ndvi": 0.2869391955844076,
        "soil_temp": 21.945186632194734,
        "soil_humidity": 31.287856883493475
    },
    {
        "date": "2024-09-25 11:53:09.251267",
        "air_temp": 16.93352899293439,
        "humidity": 45.935454775455575,
        "rainfall": 2,
        "wind_speed": 7.66962245150085,
        "soil_moisture": 38.61762411540167,
        "ndvi": 0.30638675211358696,
        "soil_temp": 14.989817513575769,
        "soil_humidity": 54.756894249388864
    },
    {
        "date": "2024-09-25 12:53:09.251267",
        "air_temp": 24.007418725163703,
        "humidity": 89.02841135303618,
        "rainfall": 0.5,
        "wind_speed": 3.044564278337336,
        "soil_moisture": 34.42626301163989,
        "ndvi": 0.7176044421991337,
        "soil_temp": 18.889879718281698,
        "soil_humidity": 32.21562920083757
    },
    {
        "date": "2024-09-25 13:53:09.251267",
        "air_temp": 30.12326657790492,
        "humidity": 50.73479437316777,
        "rainfall": 0,
        "wind_speed": 13.334177107929134,
        "soil_moisture": 37.11945089767856,
        "ndvi": 0.43565655135483156,
        "soil_temp": 17.424282005574874,
        "soil_humidity": 54.654252943961
    },
    {
        "date": "2024-09-25 14:53:09.251267",
        "air_temp": 21.951430484779642,
        "humidity": 43.36170900452806,
        "rainfall": 0,
        "wind_speed": 5.685765781909186,
        "soil_moisture": 10.469298859223024,
        "ndvi": 0.20262438936980562,
        "soil_temp": 24.18606939103441,
        "soil_humidity": 57.40119797076754
    },
    {
        "date": "2024-09-25 15:53:09.251267",
        "air_temp": 28.29823448974436,
        "humidity": 69.74236897509547,
        "rainfall": 0.5,
        "wind_speed": 11.715565418620953,
        "soil_moisture": 38.01576387357218,
        "ndvi": 0.36432309182322264,
        "soil_temp": 21.213619540811656,
        "soil_humidity": 37.91070510470074
    },
    {
        "date": "2024-09-25 16:53:09.251267",
        "air_temp": 30.908999215794584,
        "humidity": 76.94333385665163,
        "rainfall": 2,
        "wind_speed": 11.735358156891824,
        "soil_moisture": 34.29365586064314,
        "ndvi": 0.558577395917336,
        "soil_temp": 20.958139472448686,
        "soil_humidity": 62.77384594887084
    },
    {
        "date": "2024-09-25 17:53:09.251267",
        "air_temp": 33.54355638958906,
        "humidity": 41.622172302359346,
        "rainfall": 0.5,
        "wind_speed": 8.914596418057384,
        "soil_moisture": 35.11657428876096,
        "ndvi": 0.6795595795759809,
        "soil_temp": 10.695299041514193,
        "soil_humidity": 66.44340526296195
    },
    {
        "date": "2024-09-25 18:53:09.251267",
        "air_temp": 19.69284163710907,
        "humidity": 72.93588193635111,
        "rainfall": 1,
        "wind_speed": 16.72237231090085,
        "soil_moisture": 12.798621541659056,
        "ndvi": 0.28003317025595575,
        "soil_temp": 18.245045035055366,
        "soil_humidity": 62.52347856455279
    },
    {
        "date": "2024-09-25 19:53:09.251267",
        "air_temp": 22.986318316186164,
        "humidity": 66.61459379757272,
        "rainfall": 0,
        "wind_speed": 5.29934123110886,
        "soil_moisture": 32.163340867557935,
        "ndvi": 0.2663248186500412,
        "soil_temp": 17.004871808527223,
        "soil_humidity": 42.20436760848329
    },
    {
        "date": "2024-09-25 20:53:09.251267",
        "air_temp": 18.04832026660835,
        "humidity": 45.16325044375242,
        "rainfall": 1,
        "wind_speed": 8.972651832354684,
        "soil_moisture": 38.79750068926273,
        "ndvi": 0.753255592143399,
        "soil_temp": 23.917633999092917,
        "soil_humidity": 53.46572049782537
    },
    {
        "date": "2024-09-25 21:53:09.251267",
        "air_temp": 34.84967004316751,
        "humidity": 48.626193847318106,
        "rainfall": 0.5,
        "wind_speed": 10.75384257181943,
        "soil_moisture": 30.356420242544818,
        "ndvi": 0.6506890206935496,
        "soil_temp": 13.860129822842984,
        "soil_humidity": 66.5926059676491
    },
    {
        "date": "2024-09-25 22:53:09.251267",
        "air_temp": 33.54001935848591,
        "humidity": 68.39871486580402,
        "rainfall": 0.5,
        "wind_speed": 13.272494415345992,
        "soil_moisture": 27.116842138738193,
        "ndvi": 0.5538677604668472,
        "soil_temp": 14.047501799366415,
        "soil_humidity": 42.826589048913995
    },
    {
        "date": "2024-09-25 23:53:09.251267",
        "air_temp": 25.79914260561119,
        "humidity": 54.81788067566442,
        "rainfall": 2,
        "wind_speed": 15.67943701011179,
        "soil_moisture": 38.36889033246991,
        "ndvi": 0.6615120739975568,
        "soil_temp": 16.170193733222504,
        "soil_humidity": 62.801112890617
    },
    {
        "date": "2024-09-26 00:53:09.251267",
        "air_temp": 31.840665887528424,
        "humidity": 86.9157864325658,
        "rainfall": 2,
        "wind_speed": 7.896145657565121,
        "soil_moisture": 23.28811365203308,
        "ndvi": 0.4573126167839831,
        "soil_temp": 13.650545701095641,
        "soil_humidity": 30.379005250467863
    },
    {
        "date": "2024-09-26 01:53:09.251267",
        "air_temp": 25.41915954544084,
        "humidity": 80.93701147420254,
        "rainfall": 2,
        "wind_speed": 12.92093888193192,
        "soil_moisture": 27.97880698931436,
        "ndvi": 0.6550734483854761,
        "soil_temp": 11.937758767131243,
        "soil_humidity": 30.145868111387053
    },
    {
        "date": "2024-09-26 02:53:09.251267",
        "air_temp": 27.471713760055195,
        "humidity": 89.17836032568314,
        "rainfall": 1,
        "wind_speed": 3.0122190613703825,
        "soil_moisture": 24.290148804830984,
        "ndvi": 0.4230817947647215,
        "soil_temp": 22.86122269976201,
        "soil_humidity": 60.388466413558696
    },
    {
        "date": "2024-09-26 03:53:09.251267",
        "air_temp": 16.78248864232033,
        "humidity": 52.9835419475207,
        "rainfall": 2,
        "wind_speed": 18.65296201084957,
        "soil_moisture": 37.985386640808976,
        "ndvi": 0.34645674349556144,
        "soil_temp": 18.37414786605874,
        "soil_humidity": 60.457903285084846
    },
    {
        "date": "2024-09-26 04:53:09.251267",
        "air_temp": 30.10540840993616,
        "humidity": 88.48618163989886,
        "rainfall": 0.5,
        "wind_speed": 7.66117612842007,
        "soil_moisture": 12.282541802965218,
        "ndvi": 0.6374080527757813,
        "soil_temp": 22.9204407843485,
        "soil_humidity": 61.55908751409175
    },
    {
        "date": "2024-09-26 05:53:09.251267",
        "air_temp": 17.554269672704695,
        "humidity": 61.56169348957789,
        "rainfall": 2,
        "wind_speed": 14.590428439528768,
        "soil_moisture": 25.033591024760447,
        "ndvi": 0.45180337529436104,
        "soil_temp": 22.46702148105544,
        "soil_humidity": 67.2010735155265
    },
    {
        "date": "2024-09-26 06:53:09.251267",
        "air_temp": 31.521352605050566,
        "humidity": 57.37593976568773,
        "rainfall": 0,
        "wind_speed": 0.40682163764355295,
        "soil_moisture": 30.60857324156315,
        "ndvi": 0.788826262595717,
        "soil_temp": 14.230635321845067,
        "soil_humidity": 34.71625247281137
    },
    {
        "date": "2024-09-26 07:53:09.251267",
        "air_temp": 30.640561753407717,
        "humidity": 42.51747634846766,
        "rainfall": 2,
        "wind_speed": 4.10374285809614,
        "soil_moisture": 33.757196152667106,
        "ndvi": 0.40969110938486064,
        "soil_temp": 18.21606166486411,
        "soil_humidity": 39.27939939934753
    },
    {
        "date": "2024-09-26 08:53:09.251267",
        "air_temp": 29.174893954859527,
        "humidity": 42.63826289298013,
        "rainfall": 1,
        "wind_speed": 9.741650886471561,
        "soil_moisture": 27.49055942332409,
        "ndvi": 0.7530364523186397,
        "soil_temp": 11.248265541101057,
        "soil_humidity": 66.14899681495231
    },
    {
        "date": "2024-09-26 09:53:09.251267",
        "air_temp": 15.723207610495242,
        "humidity": 74.60056279733274,
        "rainfall": 0,
        "wind_speed": 17.56504935464693,
        "soil_moisture": 37.26753232364362,
        "ndvi": 0.7597775291402478,
        "soil_temp": 23.728778260461375,
        "soil_humidity": 37.08944791089414
    },
    {
        "date": "2024-09-26 10:53:09.251267",
        "air_temp": 21.06256711890657,
        "humidity": 62.896449721701394,
        "rainfall": 0,
        "wind_speed": 10.545102999013542,
        "soil_moisture": 38.25974340651615,
        "ndvi": 0.3451117682388477,
        "soil_temp": 16.063940725977297,
        "soil_humidity": 57.28917070306336
    },
    {
        "date": "2024-09-26 11:53:09.251267",
        "air_temp": 20.262251398216858,
        "humidity": 51.32608130180529,
        "rainfall": 0,
        "wind_speed": 18.59740964587758,
        "soil_moisture": 11.929576695807505,
        "ndvi": 0.6789327089528081,
        "soil_temp": 15.90514275833532,
        "soil_humidity": 51.21852000163261
    },
    {
        "date": "2024-09-26 12:53:09.251267",
        "air_temp": 22.20272814943038,
        "humidity": 70.69851531626163,
        "rainfall": 1,
        "wind_speed": 9.675937931655927,
        "soil_moisture": 22.07111618624123,
        "ndvi": 0.45984470308673914,
        "soil_temp": 14.541330559269298,
        "soil_humidity": 33.32774707406029
    },
    {
        "date": "2024-09-26 13:53:09.251267",
        "air_temp": 16.752854935276773,
        "humidity": 52.642905912505555,
        "rainfall": 0.5,
        "wind_speed": 9.808981934718998,
        "soil_moisture": 10.703770651634633,
        "ndvi": 0.750514836338209,
        "soil_temp": 16.909956170660557,
        "soil_humidity": 41.41054647766131
    },
    {
        "date": "2024-09-26 14:53:09.251267",
        "air_temp": 33.73915646062126,
        "humidity": 68.88158771722112,
        "rainfall": 2,
        "wind_speed": 2.3949357700106377,
        "soil_moisture": 19.882499721233287,
        "ndvi": 0.47007456358114336,
        "soil_temp": 13.77639079818172,
        "soil_humidity": 50.81488865763872
    },
    {
        "date": "2024-09-26 15:53:09.251267",
        "air_temp": 26.076044814247524,
        "humidity": 57.960437349134516,
        "rainfall": 0,
        "wind_speed": 16.053124883044397,
        "soil_moisture": 26.106046539118065,
        "ndvi": 0.6465822304024198,
        "soil_temp": 15.622784091952068,
        "soil_humidity": 64.09400466735336
    },
    {
        "date": "2024-09-26 16:53:09.251267",
        "air_temp": 21.11048621316047,
        "humidity": 81.17761133059095,
        "rainfall": 0,
        "wind_speed": 4.93930824934125,
        "soil_moisture": 37.323847915298465,
        "ndvi": 0.48018044549827876,
        "soil_temp": 21.999205728773227,
        "soil_humidity": 58.30153958458695
    },
    {
        "date": "2024-09-26 17:53:09.251267",
        "air_temp": 22.939630341485667,
        "humidity": 81.03480803833897,
        "rainfall": 0,
        "wind_speed": 17.9922497874463,
        "soil_moisture": 35.39662967396208,
        "ndvi": 0.33668045342771374,
        "soil_temp": 22.513832553777576,
        "soil_humidity": 54.87532645819732
    },
    {
        "date": "2024-09-26 18:53:09.251267",
        "air_temp": 23.944050810096712,
        "humidity": 63.867519906120066,
        "rainfall": 0.5,
        "wind_speed": 18.216484406607922,
        "soil_moisture": 13.824991800159571,
        "ndvi": 0.3196100773668118,
        "soil_temp": 14.562737164427741,
        "soil_humidity": 49.84131559765659
    },
    {
        "date": "2024-09-26 19:53:09.251267",
        "air_temp": 27.011886667212345,
        "humidity": 57.54095546675078,
        "rainfall": 0.5,
        "wind_speed": 18.866202237482995,
        "soil_moisture": 12.790911242226871,
        "ndvi": 0.4552587165538074,
        "soil_temp": 17.03521645829182,
        "soil_humidity": 41.94735935256537
    },
    {
        "date": "2024-09-26 20:53:09.251267",
        "air_temp": 25.313588553929954,
        "humidity": 58.12798982795473,
        "rainfall": 1,
        "wind_speed": 10.4046097820618,
        "soil_moisture": 27.67802016270001,
        "ndvi": 0.653296794208529,
        "soil_temp": 23.107296644200783,
        "soil_humidity": 34.76979645277045
    },
    {
        "date": "2024-09-26 21:53:09.251267",
        "air_temp": 33.38783946428835,
        "humidity": 80.2857262834109,
        "rainfall": 0.5,
        "wind_speed": 13.541875695672887,
        "soil_moisture": 25.96353917376348,
        "ndvi": 0.5630428911084602,
        "soil_temp": 10.790099977445458,
        "soil_humidity": 46.36304138264382
    },
    {
        "date": "2024-09-26 22:53:09.251267",
        "air_temp": 24.939269658116604,
        "humidity": 56.4089741398806,
        "rainfall": 2,
        "wind_speed": 15.57031760123327,
        "soil_moisture": 17.991329242900715,
        "ndvi": 0.613177573940326,
        "soil_temp": 23.33717442993678,
        "soil_humidity": 31.1407397564517
    },
    {
        "date": "2024-09-26 23:53:09.251267",
        "air_temp": 34.84316029646625,
        "humidity": 50.4529686853423,
        "rainfall": 1,
        "wind_speed": 2.620292373009312,
        "soil_moisture": 36.983592536615056,
        "ndvi": 0.3693958104391655,
        "soil_temp": 13.287765184784975,
        "soil_humidity": 38.44223557067402
    },
    {
        "date": "2024-09-27 00:53:09.251267",
        "air_temp": 32.028499154901915,
        "humidity": 44.234417093765344,
        "rainfall": 1,
        "wind_speed": 12.019143899949945,
        "soil_moisture": 37.82099228384661,
        "ndvi": 0.48886120757456336,
        "soil_temp": 20.17808866321564,
        "soil_humidity": 47.29933503490639
    },
    {
        "date": "2024-09-27 01:53:09.251267",
        "air_temp": 19.170210287146716,
        "humidity": 63.334656044220424,
        "rainfall": 2,
        "wind_speed": 0.6859580260662335,
        "soil_moisture": 22.07834315020488,
        "ndvi": 0.2266371589815962,
        "soil_temp": 18.738275445769006,
        "soil_humidity": 46.7442074268766
    },
    {
        "date": "2024-09-27 02:53:09.251267",
        "air_temp": 33.61190429287832,
        "humidity": 64.10660494599209,
        "rainfall": 2,
        "wind_speed": 9.951321667774518,
        "soil_moisture": 36.70907671340561,
        "ndvi": 0.6905194495748725,
        "soil_temp": 18.29411122283878,
        "soil_humidity": 31.771817239879695
    },
    {
        "date": "2024-09-27 03:53:09.251267",
        "air_temp": 17.32732795770952,
        "humidity": 82.04543552014536,
        "rainfall": 2,
        "wind_speed": 3.096853632404548,
        "soil_moisture": 21.518466827889178,
        "ndvi": 0.7939352973439249,
        "soil_temp": 22.890403371292322,
        "soil_humidity": 53.56240614101165
    },
    {
        "date": "2024-09-27 04:53:09.251267",
        "air_temp": 31.348994169823833,
        "humidity": 51.0619348223032,
        "rainfall": 1,
        "wind_speed": 3.658458519241694,
        "soil_moisture": 32.104504954891006,
        "ndvi": 0.44412743757480255,
        "soil_temp": 11.523582154669919,
        "soil_humidity": 51.98737023412945
    },
    {
        "date": "2024-09-27 05:53:09.251267",
        "air_temp": 22.6124658695316,
        "humidity": 59.04956368342,
        "rainfall": 1,
        "wind_speed": 7.30095133385203,
        "soil_moisture": 24.278266526431725,
        "ndvi": 0.206578191878926,
        "soil_temp": 15.20542037988454,
        "soil_humidity": 33.124172160836515
    },
    {
        "date": "2024-09-27 06:53:09.251267",
        "air_temp": 32.55948640890139,
        "humidity": 80.42028694468455,
        "rainfall": 1,
        "wind_speed": 8.64874333433006,
        "soil_moisture": 13.67749469521549,
        "ndvi": 0.30187558373056844,
        "soil_temp": 18.073791195171484,
        "soil_humidity": 67.72652209841758
    },
    {
        "date": "2024-09-27 07:53:09.251267",
        "air_temp": 32.361133802149006,
        "humidity": 81.18118053744868,
        "rainfall": 2,
        "wind_speed": 12.463448490643746,
        "soil_moisture": 37.838026037110644,
        "ndvi": 0.5915161266502513,
        "soil_temp": 22.169332809190596,
        "soil_humidity": 33.9753111580344
    },
    {
        "date": "2024-09-27 08:53:09.251267",
        "air_temp": 31.118508002352424,
        "humidity": 59.23055379893841,
        "rainfall": 2,
        "wind_speed": 1.4885683687703133,
        "soil_moisture": 23.622897801326097,
        "ndvi": 0.5672475909223156,
        "soil_temp": 13.246646251175072,
        "soil_humidity": 64.19336604949345
    },
    {
        "date": "2024-09-27 09:53:09.251267",
        "air_temp": 30.800608726141228,
        "humidity": 62.95620116433405,
        "rainfall": 0,
        "wind_speed": 10.073835224748892,
        "soil_moisture": 32.03461120967211,
        "ndvi": 0.6727188317513264,
        "soil_temp": 22.043947620614144,
        "soil_humidity": 30.01238927660024
    },
    {
        "date": "2024-09-27 10:53:09.251267",
        "air_temp": 21.093582781960816,
        "humidity": 55.16429454246415,
        "rainfall": 0.5,
        "wind_speed": 3.6525026050620224,
        "soil_moisture": 21.015214432938762,
        "ndvi": 0.35233040657664094,
        "soil_temp": 15.994577237409036,
        "soil_humidity": 65.29124380181533
    },
    {
        "date": "2024-09-27 11:53:09.251267",
        "air_temp": 16.618385661025044,
        "humidity": 86.6277439209656,
        "rainfall": 0,
        "wind_speed": 11.479503500634838,
        "soil_moisture": 10.566426357287039,
        "ndvi": 0.6581791493282805,
        "soil_temp": 12.833707846505432,
        "soil_humidity": 30.788789288172502
    },
    {
        "date": "2024-09-27 12:53:09.251267",
        "air_temp": 23.05960357530323,
        "humidity": 45.932188287079015,
        "rainfall": 2,
        "wind_speed": 12.74809224522367,
        "soil_moisture": 13.015910760755077,
        "ndvi": 0.6127904129142019,
        "soil_temp": 21.384796606652237,
        "soil_humidity": 60.31248127568464
    },
    {
        "date": "2024-09-27 13:53:09.251267",
        "air_temp": 18.470490299730173,
        "humidity": 86.68747115007349,
        "rainfall": 1,
        "wind_speed": 11.134185131057077,
        "soil_moisture": 24.886584384697734,
        "ndvi": 0.393688698628306,
        "soil_temp": 11.363483522118218,
        "soil_humidity": 44.46763245201012
    },
    {
        "date": "2024-09-27 14:53:09.251267",
        "air_temp": 28.899021778049235,
        "humidity": 74.19332259176716,
        "rainfall": 2,
        "wind_speed": 14.765910209566153,
        "soil_moisture": 30.698930713790872,
        "ndvi": 0.5272928888341772,
        "soil_temp": 17.721510611131645,
        "soil_humidity": 59.52867200545279
    },
    {
        "date": "2024-09-27 15:53:09.251267",
        "air_temp": 21.92199453075571,
        "humidity": 66.57860763433656,
        "rainfall": 0.5,
        "wind_speed": 6.7121848848180266,
        "soil_moisture": 20.04205979737837,
        "ndvi": 0.28836428509899603,
        "soil_temp": 10.958136757928326,
        "soil_humidity": 46.069808216999135
    },
    {
        "date": "2024-09-27 16:53:09.251267",
        "air_temp": 34.5122040171983,
        "humidity": 41.632953039224795,
        "rainfall": 0,
        "wind_speed": 15.302131832662617,
        "soil_moisture": 30.86465377532951,
        "ndvi": 0.4715387020613496,
        "soil_temp": 15.346267827366812,
        "soil_humidity": 32.83631689327367
    },
    {
        "date": "2024-09-27 17:53:09.251267",
        "air_temp": 27.81944154690664,
        "humidity": 63.77980959110042,
        "rainfall": 0,
        "wind_speed": 8.655341634163873,
        "soil_moisture": 30.959067682958683,
        "ndvi": 0.7287184037333847,
        "soil_temp": 19.016151985095888,
        "soil_humidity": 60.70026781581456
    },
    {
        "date": "2024-09-27 18:53:09.251267",
        "air_temp": 31.449611272687218,
        "humidity": 60.38740525013661,
        "rainfall": 1,
        "wind_speed": 9.683689966451936,
        "soil_moisture": 31.118077219834927,
        "ndvi": 0.45271482789523454,
        "soil_temp": 18.3417331223082,
        "soil_humidity": 32.8501074128546
    },
    {
        "date": "2024-09-27 19:53:09.251267",
        "air_temp": 17.65049345483697,
        "humidity": 48.060940598756126,
        "rainfall": 0,
        "wind_speed": 12.96204254522143,
        "soil_moisture": 24.775655620615254,
        "ndvi": 0.7037400238852534,
        "soil_temp": 16.06983126857,
        "soil_humidity": 66.88481743844872
    },
    {
        "date": "2024-09-27 20:53:09.251267",
        "air_temp": 32.24028964545245,
        "humidity": 72.82048017503286,
        "rainfall": 0,
        "wind_speed": 0.3671676799844792,
        "soil_moisture": 37.44658450364512,
        "ndvi": 0.5541520191558167,
        "soil_temp": 16.435719909581735,
        "soil_humidity": 32.03896143166646
    },
    {
        "date": "2024-09-27 21:53:09.251267",
        "air_temp": 33.455143810267884,
        "humidity": 88.55754536841428,
        "rainfall": 2,
        "wind_speed": 13.079884428404966,
        "soil_moisture": 24.67589553941847,
        "ndvi": 0.3151260278614141,
        "soil_temp": 13.401543300261498,
        "soil_humidity": 49.173011110860614
    },
    {
        "date": "2024-09-27 22:53:09.251267",
        "air_temp": 24.741238376378718,
        "humidity": 68.10944055322625,
        "rainfall": 0,
        "wind_speed": 12.371702981442308,
        "soil_moisture": 23.507955804899147,
        "ndvi": 0.5486637468187379,
        "soil_temp": 16.838238826361543,
        "soil_humidity": 58.92137723403182
    },
    {
        "date": "2024-09-27 23:53:09.251267",
        "air_temp": 27.12505875501955,
        "humidity": 75.73766382219729,
        "rainfall": 0,
        "wind_speed": 6.196942335065598,
        "soil_moisture": 15.98142457031881,
        "ndvi": 0.5035899974942382,
        "soil_temp": 24.298822835812516,
        "soil_humidity": 45.729858591681506
    },
    {
        "date": "2024-09-28 00:53:09.251267",
        "air_temp": 30.29619601805991,
        "humidity": 43.42252262830084,
        "rainfall": 0,
        "wind_speed": 1.7158856979897896,
        "soil_moisture": 19.229652620690096,
        "ndvi": 0.4391848011178304,
        "soil_temp": 22.756793766756857,
        "soil_humidity": 38.68933898369739
    },
    {
        "date": "2024-09-28 01:53:09.251267",
        "air_temp": 18.496772545208252,
        "humidity": 60.883349790849735,
        "rainfall": 1,
        "wind_speed": 1.8187089982608229,
        "soil_moisture": 11.2700347755491,
        "ndvi": 0.2717782637096422,
        "soil_temp": 12.178493036145158,
        "soil_humidity": 60.363638455841084
    },
    {
        "date": "2024-09-28 02:53:09.251267",
        "air_temp": 25.051321529268847,
        "humidity": 45.81792425822479,
        "rainfall": 1,
        "wind_speed": 18.464474939704573,
        "soil_moisture": 29.006058808485108,
        "ndvi": 0.4500251983083379,
        "soil_temp": 10.446711809813815,
        "soil_humidity": 61.37178819154906
    },
    {
        "date": "2024-09-28 03:53:09.251267",
        "air_temp": 22.973260555446263,
        "humidity": 70.63979177682552,
        "rainfall": 2,
        "wind_speed": 13.785586424516742,
        "soil_moisture": 17.456072317812787,
        "ndvi": 0.2436574257437763,
        "soil_temp": 24.00286469074946,
        "soil_humidity": 66.90779935817469
    },
    {
        "date": "2024-09-28 04:53:09.251267",
        "air_temp": 17.92747984324498,
        "humidity": 86.92410791070773,
        "rainfall": 0,
        "wind_speed": 2.542052275414808,
        "soil_moisture": 19.124657634563953,
        "ndvi": 0.2592614308392508,
        "soil_temp": 17.901246248211216,
        "soil_humidity": 45.04660441724311
    },
    {
        "date": "2024-09-28 05:53:09.251267",
        "air_temp": 22.350688445830627,
        "humidity": 73.08888662358193,
        "rainfall": 1,
        "wind_speed": 7.134463386178127,
        "soil_moisture": 33.12443564992964,
        "ndvi": 0.44954991975962966,
        "soil_temp": 18.067567347239617,
        "soil_humidity": 50.25006937989308
    },
    {
        "date": "2024-09-28 06:53:09.251267",
        "air_temp": 16.363446187709872,
        "humidity": 43.84015241572108,
        "rainfall": 1,
        "wind_speed": 11.849659878031929,
        "soil_moisture": 19.816635494843617,
        "ndvi": 0.3832288891810782,
        "soil_temp": 21.710769935597835,
        "soil_humidity": 44.52513771384288
    },
    {
        "date": "2024-09-28 07:53:09.251267",
        "air_temp": 15.51623813898974,
        "humidity": 57.733310249254146,
        "rainfall": 2,
        "wind_speed": 16.711434101812973,
        "soil_moisture": 17.265876760394264,
        "ndvi": 0.7553265955173343,
        "soil_temp": 14.720334608069898,
        "soil_humidity": 40.28327287405473
    },
    {
        "date": "2024-09-28 08:53:09.251267",
        "air_temp": 17.703325783096457,
        "humidity": 67.53316897204257,
        "rainfall": 0.5,
        "wind_speed": 0.8732185742601706,
        "soil_moisture": 15.071732474011172,
        "ndvi": 0.42542348322816237,
        "soil_temp": 17.22861353254003,
        "soil_humidity": 60.041318461504346
    },
    {
        "date": "2024-09-28 09:53:09.251267",
        "air_temp": 34.26230223178348,
        "humidity": 60.16038780666261,
        "rainfall": 0,
        "wind_speed": 4.732662629400178,
        "soil_moisture": 33.245752065458674,
        "ndvi": 0.6547148732432602,
        "soil_temp": 24.529943697201062,
        "soil_humidity": 43.58607380067822
    },
    {
        "date": "2024-09-28 10:53:09.251267",
        "air_temp": 25.99059071790034,
        "humidity": 81.68220052375861,
        "rainfall": 1,
        "wind_speed": 17.7949798059076,
        "soil_moisture": 13.891187739700808,
        "ndvi": 0.5181485158197456,
        "soil_temp": 20.08223030398611,
        "soil_humidity": 47.12597767969408
    },
    {
        "date": "2024-09-28 11:53:09.251267",
        "air_temp": 34.3164432230644,
        "humidity": 80.73355023549945,
        "rainfall": 2,
        "wind_speed": 0.1833110036763297,
        "soil_moisture": 23.612612807510608,
        "ndvi": 0.6224514011854014,
        "soil_temp": 12.306175479936789,
        "soil_humidity": 46.14694630496111
    },
    {
        "date": "2024-09-28 12:53:09.251267",
        "air_temp": 23.649957566602637,
        "humidity": 70.59155100516108,
        "rainfall": 2,
        "wind_speed": 4.027982055877748,
        "soil_moisture": 25.20790348231583,
        "ndvi": 0.35606958980923953,
        "soil_temp": 11.998401219765299,
        "soil_humidity": 58.506449025869834
    },
    {
        "date": "2024-09-28 13:53:09.251267",
        "air_temp": 21.23632266170519,
        "humidity": 58.67289725663527,
        "rainfall": 2,
        "wind_speed": 19.18048351945932,
        "soil_moisture": 30.621697380571437,
        "ndvi": 0.7651429837029975,
        "soil_temp": 13.06397137499508,
        "soil_humidity": 66.7118250444272
    },
    {
        "date": "2024-09-28 14:53:09.251267",
        "air_temp": 25.122837256804882,
        "humidity": 52.7668186881622,
        "rainfall": 1,
        "wind_speed": 12.268036930443422,
        "soil_moisture": 28.998504469989864,
        "ndvi": 0.5180530981486544,
        "soil_temp": 21.500675116310763,
        "soil_humidity": 56.67024245229509
    },
    {
        "date": "2024-09-28 15:53:09.251267",
        "air_temp": 23.790233772658205,
        "humidity": 45.293715910656466,
        "rainfall": 0,
        "wind_speed": 5.233427332750129,
        "soil_moisture": 34.32162018835524,
        "ndvi": 0.6987944427711499,
        "soil_temp": 14.43065505760924,
        "soil_humidity": 48.8633038437693
    },
    {
        "date": "2024-09-28 16:53:09.251267",
        "air_temp": 17.11329368523488,
        "humidity": 57.7448841743277,
        "rainfall": 0.5,
        "wind_speed": 1.3398218879169521,
        "soil_moisture": 11.342543017787145,
        "ndvi": 0.4190274160856681,
        "soil_temp": 10.320832700126196,
        "soil_humidity": 61.16820458955534
    },
    {
        "date": "2024-09-28 17:53:09.251267",
        "air_temp": 27.816526293804124,
        "humidity": 60.658625860385975,
        "rainfall": 0,
        "wind_speed": 0.5638268089444431,
        "soil_moisture": 18.893221743381915,
        "ndvi": 0.26012680943031474,
        "soil_temp": 24.16261545258493,
        "soil_humidity": 55.6388529648855
    },
    {
        "date": "2024-09-28 18:53:09.251267",
        "air_temp": 19.320763650191992,
        "humidity": 73.79114625520367,
        "rainfall": 2,
        "wind_speed": 4.894901592738545,
        "soil_moisture": 18.40402356326139,
        "ndvi": 0.6042269886325093,
        "soil_temp": 12.54570124592366,
        "soil_humidity": 32.42441428471254
    },
    {
        "date": "2024-09-28 19:53:09.251267",
        "air_temp": 27.39175909795659,
        "humidity": 72.90799121487868,
        "rainfall": 2,
        "wind_speed": 17.62971639177821,
        "soil_moisture": 14.380507167763247,
        "ndvi": 0.2046557890024547,
        "soil_temp": 10.14337279222103,
        "soil_humidity": 33.82654345675367
    },
    {
        "date": "2024-09-28 20:53:09.251267",
        "air_temp": 28.004021847963067,
        "humidity": 43.50540698206285,
        "rainfall": 0.5,
        "wind_speed": 8.318273085950189,
        "soil_moisture": 18.675347615693294,
        "ndvi": 0.48517578593930505,
        "soil_temp": 13.556974035749844,
        "soil_humidity": 31.516677762945335
    },
    {
        "date": "2024-09-28 21:53:09.251267",
        "air_temp": 18.04049706257296,
        "humidity": 59.76296577155789,
        "rainfall": 0,
        "wind_speed": 14.40666738331986,
        "soil_moisture": 19.292959424287105,
        "ndvi": 0.3156811415998756,
        "soil_temp": 13.525431806772005,
        "soil_humidity": 48.27265304825279
    },
    {
        "date": "2024-09-28 22:53:09.251267",
        "air_temp": 16.226992542213363,
        "humidity": 49.0889433608681,
        "rainfall": 2,
        "wind_speed": 18.365700707599157,
        "soil_moisture": 25.65653385782435,
        "ndvi": 0.5764253026612174,
        "soil_temp": 16.330078762685844,
        "soil_humidity": 63.03413737355859
    },
    {
        "date": "2024-09-28 23:53:09.251267",
        "air_temp": 30.615231721368797,
        "humidity": 47.840635265840284,
        "rainfall": 2,
        "wind_speed": 8.160809232056332,
        "soil_moisture": 29.91113436778768,
        "ndvi": 0.7876519238827113,
        "soil_temp": 23.805847472500552,
        "soil_humidity": 42.523326774054084
    },
    {
        "date": "2024-09-29 00:53:09.251267",
        "air_temp": 24.196008475386403,
        "humidity": 81.36510748686229,
        "rainfall": 0,
        "wind_speed": 3.825547420747941,
        "soil_moisture": 21.43301540835442,
        "ndvi": 0.4786417437923876,
        "soil_temp": 23.18777376668647,
        "soil_humidity": 45.36640732369926
    },
    {
        "date": "2024-09-29 01:53:09.251267",
        "air_temp": 16.163275910109515,
        "humidity": 42.095760835812875,
        "rainfall": 2,
        "wind_speed": 10.34235861713839,
        "soil_moisture": 31.584106939401437,
        "ndvi": 0.6904989539154001,
        "soil_temp": 16.853418324736417,
        "soil_humidity": 66.37908433471563
    },
    {
        "date": "2024-09-29 02:53:09.251267",
        "air_temp": 34.89732634339534,
        "humidity": 60.959143795106066,
        "rainfall": 0,
        "wind_speed": 18.161673741197088,
        "soil_moisture": 20.0849601573874,
        "ndvi": 0.5046705121706727,
        "soil_temp": 16.163498574877096,
        "soil_humidity": 63.476137812666195
    },
    {
        "date": "2024-09-29 03:53:09.251267",
        "air_temp": 16.155611219927334,
        "humidity": 48.41018278832591,
        "rainfall": 2,
        "wind_speed": 16.087488282754013,
        "soil_moisture": 18.70851031944281,
        "ndvi": 0.4975040186624145,
        "soil_temp": 18.325136297122768,
        "soil_humidity": 56.6019244953024
    },
    {
        "date": "2024-09-29 04:53:09.251267",
        "air_temp": 28.900704577171062,
        "humidity": 83.9665107839237,
        "rainfall": 0,
        "wind_speed": 1.3165968672630757,
        "soil_moisture": 26.23564167571073,
        "ndvi": 0.7582540959072668,
        "soil_temp": 13.651810179576486,
        "soil_humidity": 40.672151283690056
    },
    {
        "date": "2024-09-29 05:53:09.251267",
        "air_temp": 34.67357850526827,
        "humidity": 67.85202566375176,
        "rainfall": 0.5,
        "wind_speed": 13.851456001551636,
        "soil_moisture": 10.711490809173718,
        "ndvi": 0.7339795212480089,
        "soil_temp": 10.782774879495442,
        "soil_humidity": 34.68165930753774
    },
    {
        "date": "2024-09-29 06:53:09.251267",
        "air_temp": 19.783602084318513,
        "humidity": 51.569031818842404,
        "rainfall": 2,
        "wind_speed": 11.437950449179075,
        "soil_moisture": 12.39367370902001,
        "ndvi": 0.6714802791127847,
        "soil_temp": 24.0883620425681,
        "soil_humidity": 30.642352907204547
    },
    {
        "date": "2024-09-29 07:53:09.251267",
        "air_temp": 17.844987373743923,
        "humidity": 65.12136435831883,
        "rainfall": 0.5,
        "wind_speed": 18.14926352692851,
        "soil_moisture": 22.53908630759581,
        "ndvi": 0.3777534519900705,
        "soil_temp": 12.800484773157592,
        "soil_humidity": 34.280426904176835
    },
    {
        "date": "2024-09-29 08:53:09.251267",
        "air_temp": 17.427698788448577,
        "humidity": 76.5743179851558,
        "rainfall": 2,
        "wind_speed": 2.4496283370059446,
        "soil_moisture": 38.28738134175269,
        "ndvi": 0.5270050494343643,
        "soil_temp": 12.976010938685747,
        "soil_humidity": 41.70385774495771
    },
    {
        "date": "2024-09-29 09:53:09.251267",
        "air_temp": 21.06550295135191,
        "humidity": 87.9059004459717,
        "rainfall": 2,
        "wind_speed": 10.680722264093133,
        "soil_moisture": 31.64535339530271,
        "ndvi": 0.334338339983411,
        "soil_temp": 17.086904932809215,
        "soil_humidity": 52.07160254317983
    },
    {
        "date": "2024-09-29 10:53:09.251267",
        "air_temp": 17.020916220212932,
        "humidity": 51.02230021227095,
        "rainfall": 1,
        "wind_speed": 17.589253870237847,
        "soil_moisture": 12.643797085016732,
        "ndvi": 0.46635001882792393,
        "soil_temp": 17.37420017260496,
        "soil_humidity": 32.560003310095475
    },
    {
        "date": "2024-09-29 11:53:09.251267",
        "air_temp": 28.84322683388264,
        "humidity": 84.30908860646726,
        "rainfall": 0,
        "wind_speed": 8.20047573483486,
        "soil_moisture": 35.38954038342621,
        "ndvi": 0.4905278394558744,
        "soil_temp": 10.27651314551113,
        "soil_humidity": 35.44911113273796
    },
    {
        "date": "2024-09-29 12:53:09.251267",
        "air_temp": 16.245835962601255,
        "humidity": 86.71520570837451,
        "rainfall": 0.5,
        "wind_speed": 9.63989835465734,
        "soil_moisture": 31.96902311824543,
        "ndvi": 0.6989840241015987,
        "soil_temp": 12.21932108160956,
        "soil_humidity": 37.24322809231764
    },
    {
        "date": "2024-09-29 13:53:09.251267",
        "air_temp": 25.188442562995796,
        "humidity": 85.82102722660332,
        "rainfall": 0.5,
        "wind_speed": 1.4092135533391659,
        "soil_moisture": 28.328395771079595,
        "ndvi": 0.737617019194996,
        "soil_temp": 15.672322278411126,
        "soil_humidity": 55.27928371933225
    },
    {
        "date": "2024-09-29 14:53:09.251267",
        "air_temp": 34.93393707705954,
        "humidity": 71.7707178430955,
        "rainfall": 0.5,
        "wind_speed": 5.5628728089782165,
        "soil_moisture": 34.60878881827532,
        "ndvi": 0.44877203743788674,
        "soil_temp": 20.255960402658086,
        "soil_humidity": 54.631591959204975
    },
    {
        "date": "2024-09-29 15:53:09.251267",
        "air_temp": 31.279405390120377,
        "humidity": 71.49103272173538,
        "rainfall": 0,
        "wind_speed": 6.490132983378947,
        "soil_moisture": 22.123295432288714,
        "ndvi": 0.21062266704394234,
        "soil_temp": 13.426276852479038,
        "soil_humidity": 52.03062905488612
    },
    {
        "date": "2024-09-29 16:53:09.251267",
        "air_temp": 27.304388764620217,
        "humidity": 60.160531659191136,
        "rainfall": 1,
        "wind_speed": 18.902448974897737,
        "soil_moisture": 16.645031525615583,
        "ndvi": 0.7170643038736129,
        "soil_temp": 16.34521669362644,
        "soil_humidity": 40.020056987837584
    },
    {
        "date": "2024-09-29 17:53:09.251267",
        "air_temp": 21.125072415732234,
        "humidity": 77.6198941151894,
        "rainfall": 0.5,
        "wind_speed": 5.653347210823181,
        "soil_moisture": 25.36070167326278,
        "ndvi": 0.3045672071008164,
        "soil_temp": 18.360175970747,
        "soil_humidity": 44.84478764874928
    },
    {
        "date": "2024-09-29 18:53:09.251267",
        "air_temp": 27.47791672583277,
        "humidity": 66.56470091754659,
        "rainfall": 0,
        "wind_speed": 9.21737470539304,
        "soil_moisture": 26.465252667804215,
        "ndvi": 0.5731291783342362,
        "soil_temp": 23.32888741934938,
        "soil_humidity": 33.86758865350972
    },
    {
        "date": "2024-09-29 19:53:09.251267",
        "air_temp": 25.540829278233034,
        "humidity": 73.87190356141473,
        "rainfall": 2,
        "wind_speed": 13.426088471285595,
        "soil_moisture": 26.14979116029736,
        "ndvi": 0.21498254026047872,
        "soil_temp": 14.89105753490685,
        "soil_humidity": 39.058424150322196
    },
    {
        "date": "2024-09-29 20:53:09.251267",
        "air_temp": 23.52166767780743,
        "humidity": 61.42289519243977,
        "rainfall": 0.5,
        "wind_speed": 3.243667448157548,
        "soil_moisture": 26.95221374440316,
        "ndvi": 0.4397038851563386,
        "soil_temp": 12.003373987952253,
        "soil_humidity": 65.90820905890322
    },
    {
        "date": "2024-09-29 21:53:09.251267",
        "air_temp": 17.614207534803988,
        "humidity": 76.57482320215695,
        "rainfall": 0.5,
        "wind_speed": 9.72884774283749,
        "soil_moisture": 38.46114754717543,
        "ndvi": 0.7915761812692099,
        "soil_temp": 17.339083388464125,
        "soil_humidity": 69.18766985743314
    },
    {
        "date": "2024-09-29 22:53:09.251267",
        "air_temp": 32.732084304809334,
        "humidity": 81.22357588022746,
        "rainfall": 1,
        "wind_speed": 14.770780789518152,
        "soil_moisture": 37.067368692886944,
        "ndvi": 0.2604161606622819,
        "soil_temp": 21.200824025260932,
        "soil_humidity": 55.906383425611786
    },
    {
        "date": "2024-09-29 23:53:09.251267",
        "air_temp": 23.99569297642645,
        "humidity": 47.32181283014081,
        "rainfall": 0.5,
        "wind_speed": 17.33710426054132,
        "soil_moisture": 36.27151109676818,
        "ndvi": 0.45421589119978545,
        "soil_temp": 11.647896355791001,
        "soil_humidity": 66.67293168093681
    },
    {
        "date": "2024-09-30 00:53:09.251267",
        "air_temp": 18.8924502364153,
        "humidity": 81.6464857787114,
        "rainfall": 0.5,
        "wind_speed": 12.525185493248534,
        "soil_moisture": 34.04250432725881,
        "ndvi": 0.38428997011397137,
        "soil_temp": 16.370252375454406,
        "soil_humidity": 55.709676016057
    },
    {
        "date": "2024-09-30 01:53:09.251267",
        "air_temp": 22.35518701754579,
        "humidity": 67.01482658951537,
        "rainfall": 2,
        "wind_speed": 13.381070306338959,
        "soil_moisture": 38.30284321795856,
        "ndvi": 0.7430528740329587,
        "soil_temp": 10.266777118937252,
        "soil_humidity": 31.327859444218795
    },
    {
        "date": "2024-09-30 02:53:09.251267",
        "air_temp": 23.282595050578543,
        "humidity": 82.23329545451122,
        "rainfall": 0,
        "wind_speed": 2.525669451691379,
        "soil_moisture": 12.954839250382097,
        "ndvi": 0.7216040647444131,
        "soil_temp": 10.503387724708768,
        "soil_humidity": 69.87050339945637
    },
    {
        "date": "2024-09-30 03:53:09.251267",
        "air_temp": 31.550757911337485,
        "humidity": 61.56508531705073,
        "rainfall": 0,
        "wind_speed": 18.915529944926945,
        "soil_moisture": 17.953697843372183,
        "ndvi": 0.34261925618373734,
        "soil_temp": 17.244300448249984,
        "soil_humidity": 33.95809206746139
    },
    {
        "date": "2024-09-30 04:53:09.251267",
        "air_temp": 29.672287611228043,
        "humidity": 58.95202426915175,
        "rainfall": 1,
        "wind_speed": 2.651481549689647,
        "soil_moisture": 24.851678369637547,
        "ndvi": 0.24871267223025872,
        "soil_temp": 14.472959472263941,
        "soil_humidity": 57.305672057802425
    },
    {
        "date": "2024-09-30 05:53:09.251267",
        "air_temp": 30.386097872826795,
        "humidity": 85.75303662586111,
        "rainfall": 2,
        "wind_speed": 15.493458743635966,
        "soil_moisture": 35.39603479370216,
        "ndvi": 0.5762830364366706,
        "soil_temp": 22.67660948508118,
        "soil_humidity": 40.1134481229916
    },
    {
        "date": "2024-09-30 06:53:09.251267",
        "air_temp": 15.220625288572943,
        "humidity": 52.55846337742007,
        "rainfall": 0,
        "wind_speed": 5.296326435566376,
        "soil_moisture": 25.154971345681975,
        "ndvi": 0.4018212271302259,
        "soil_temp": 19.032314591896814,
        "soil_humidity": 45.6792445162784
    },
    {
        "date": "2024-09-30 07:53:09.251267",
        "air_temp": 23.32307996359523,
        "humidity": 82.21061626430716,
        "rainfall": 2,
        "wind_speed": 18.67186287402503,
        "soil_moisture": 32.44104218024766,
        "ndvi": 0.6032506343636379,
        "soil_temp": 16.808994426144857,
        "soil_humidity": 55.83736739203753
    },
    {
        "date": "2024-09-30 08:53:09.251267",
        "air_temp": 24.626883032706473,
        "humidity": 64.2010286254702,
        "rainfall": 0,
        "wind_speed": 14.39919078738959,
        "soil_moisture": 14.168797157467488,
        "ndvi": 0.21927185364068869,
        "soil_temp": 16.119030565814445,
        "soil_humidity": 47.45949397230828
    },
    {
        "date": "2024-09-30 09:53:09.251267",
        "air_temp": 15.383845536461134,
        "humidity": 65.70477212756299,
        "rainfall": 2,
        "wind_speed": 15.076274259567379,
        "soil_moisture": 17.572414389296807,
        "ndvi": 0.28003954110649365,
        "soil_temp": 17.45868220284345,
        "soil_humidity": 40.021842357245134
    },
    {
        "date": "2024-09-30 10:53:09.251267",
        "air_temp": 20.196264174900683,
        "humidity": 55.4234140744742,
        "rainfall": 1,
        "wind_speed": 4.381183461197667,
        "soil_moisture": 31.953282851130172,
        "ndvi": 0.6118233396635862,
        "soil_temp": 10.60658246818992,
        "soil_humidity": 43.37784747513299
    },
    {
        "date": "2024-09-30 11:53:09.251267",
        "air_temp": 30.2057964624427,
        "humidity": 68.65211003095078,
        "rainfall": 0.5,
        "wind_speed": 8.860799532703217,
        "soil_moisture": 14.942020266631722,
        "ndvi": 0.3274223931814987,
        "soil_temp": 23.3919003463099,
        "soil_humidity": 43.66192467692706
    },
    {
        "date": "2024-09-30 12:53:09.251267",
        "air_temp": 17.74219411457998,
        "humidity": 56.260559813810566,
        "rainfall": 1,
        "wind_speed": 12.356263891785728,
        "soil_moisture": 20.5406780396478,
        "ndvi": 0.7145526054574409,
        "soil_temp": 24.974547336009373,
        "soil_humidity": 68.37295618032854
    },
    {
        "date": "2024-09-30 13:53:09.251267",
        "air_temp": 25.706202629051834,
        "humidity": 41.96347071208262,
        "rainfall": 0,
        "wind_speed": 15.399472364132018,
        "soil_moisture": 17.945987734407144,
        "ndvi": 0.23515453262608127,
        "soil_temp": 12.72230687288048,
        "soil_humidity": 61.94452063693804
    },
    {
        "date": "2024-09-30 14:53:09.251267",
        "air_temp": 19.30403744034475,
        "humidity": 53.5999302672178,
        "rainfall": 1,
        "wind_speed": 2.0791875182477915,
        "soil_moisture": 20.495850826674356,
        "ndvi": 0.3292891896713448,
        "soil_temp": 11.805744471619295,
        "soil_humidity": 48.341950859176
    },
    {
        "date": "2024-09-30 15:53:09.251267",
        "air_temp": 15.242415492877866,
        "humidity": 40.31895217032953,
        "rainfall": 0.5,
        "wind_speed": 19.247306329090566,
        "soil_moisture": 31.24972147233911,
        "ndvi": 0.7991361490910414,
        "soil_temp": 12.153661951728928,
        "soil_humidity": 37.99283681365456
    },
    {
        "date": "2024-09-30 16:53:09.251267",
        "air_temp": 19.82402915199083,
        "humidity": 88.91773935284283,
        "rainfall": 1,
        "wind_speed": 17.80441341109112,
        "soil_moisture": 21.03541543428403,
        "ndvi": 0.4598456615520756,
        "soil_temp": 19.915405873515507,
        "soil_humidity": 62.44064441050624
    },
    {
        "date": "2024-09-30 17:53:09.251267",
        "air_temp": 34.517475098201075,
        "humidity": 88.28736049098737,
        "rainfall": 0,
        "wind_speed": 5.406971809911687,
        "soil_moisture": 26.657899678238206,
        "ndvi": 0.6217793981267862,
        "soil_temp": 19.92161257676162,
        "soil_humidity": 36.28729886433494
    },
    {
        "date": "2024-09-30 18:53:09.251267",
        "air_temp": 31.03074223765979,
        "humidity": 59.75776487361525,
        "rainfall": 0,
        "wind_speed": 16.45520486064664,
        "soil_moisture": 12.99795631163229,
        "ndvi": 0.3095978293142082,
        "soil_temp": 22.718862434176167,
        "soil_humidity": 38.41848868568014
    },
    {
        "date": "2024-09-30 19:53:09.251267",
        "air_temp": 34.19153288642067,
        "humidity": 76.4185427169823,
        "rainfall": 1,
        "wind_speed": 10.366478460796468,
        "soil_moisture": 34.15053079703883,
        "ndvi": 0.5659212409913227,
        "soil_temp": 21.2773958263938,
        "soil_humidity": 44.640535673606045
    },
    {
        "date": "2024-09-30 20:53:09.251267",
        "air_temp": 24.757080878527248,
        "humidity": 57.29030492052125,
        "rainfall": 0.5,
        "wind_speed": 9.236129631240374,
        "soil_moisture": 21.096658769443746,
        "ndvi": 0.6356636410216512,
        "soil_temp": 16.745345100540096,
        "soil_humidity": 65.21392783407961
    },
    {
        "date": "2024-09-30 21:53:09.251267",
        "air_temp": 17.19472397355007,
        "humidity": 73.5546578542988,
        "rainfall": 1,
        "wind_speed": 6.288682116228108,
        "soil_moisture": 29.456024771000273,
        "ndvi": 0.38868598151990635,
        "soil_temp": 19.192830423479784,
        "soil_humidity": 35.27146674536441
    },
    {
        "date": "2024-09-30 22:53:09.251267",
        "air_temp": 25.95918977947386,
        "humidity": 80.27150215229895,
        "rainfall": 0.5,
        "wind_speed": 11.61937173263185,
        "soil_moisture": 31.76168804985498,
        "ndvi": 0.6973382671855268,
        "soil_temp": 20.324270055520117,
        "soil_humidity": 34.88936771418104
    },
    {
        "date": "2024-09-30 23:53:09.251267",
        "air_temp": 24.08754669119922,
        "humidity": 87.33781223461204,
        "rainfall": 2,
        "wind_speed": 14.597782354071956,
        "soil_moisture": 16.289827663351012,
        "ndvi": 0.5620505470833701,
        "soil_temp": 17.69104705427838,
        "soil_humidity": 49.75899953221064
    },
    {
        "date": "2024-10-01 00:53:09.251267",
        "air_temp": 31.88714165479232,
        "humidity": 60.010613792483596,
        "rainfall": 0.5,
        "wind_speed": 8.218658099255165,
        "soil_moisture": 36.5402495486005,
        "ndvi": 0.4334738057690182,
        "soil_temp": 17.134764766774552,
        "soil_humidity": 41.05642164374699
    },
    {
        "date": "2024-10-01 01:53:09.251267",
        "air_temp": 16.961651615684893,
        "humidity": 79.16489914780239,
        "rainfall": 2,
        "wind_speed": 12.584383860165842,
        "soil_moisture": 28.486727548208588,
        "ndvi": 0.5345854360058953,
        "soil_temp": 19.676461684639797,
        "soil_humidity": 52.74911766592639
    },
    {
        "date": "2024-10-01 02:53:09.251267",
        "air_temp": 24.764822740435562,
        "humidity": 53.29642253160594,
        "rainfall": 1,
        "wind_speed": 13.986258423106076,
        "soil_moisture": 22.861754252544443,
        "ndvi": 0.5460565084615546,
        "soil_temp": 18.362118234634806,
        "soil_humidity": 48.06546738732894
    },
    {
        "date": "2024-10-01 03:53:09.251267",
        "air_temp": 18.000973293879685,
        "humidity": 89.51661518968243,
        "rainfall": 0.5,
        "wind_speed": 14.222436340305718,
        "soil_moisture": 15.470055507346586,
        "ndvi": 0.29932117561631966,
        "soil_temp": 19.659134580038653,
        "soil_humidity": 47.39836425404867
    },
    {
        "date": "2024-10-01 04:53:09.251267",
        "air_temp": 21.4935185378012,
        "humidity": 41.288479938217556,
        "rainfall": 2,
        "wind_speed": 1.039321962623605,
        "soil_moisture": 38.2690281370362,
        "ndvi": 0.44163942192017974,
        "soil_temp": 19.19282160945046,
        "soil_humidity": 34.47891881947973
    },
    {
        "date": "2024-10-01 05:53:09.251267",
        "air_temp": 29.747141547755994,
        "humidity": 70.17644032505605,
        "rainfall": 2,
        "wind_speed": 17.194956113701338,
        "soil_moisture": 32.65204612394875,
        "ndvi": 0.4060583528116959,
        "soil_temp": 20.787948407704818,
        "soil_humidity": 35.641987609993606
    },
    {
        "date": "2024-10-01 06:53:09.251267",
        "air_temp": 24.52036258737533,
        "humidity": 72.97529908256635,
        "rainfall": 2,
        "wind_speed": 9.165066295300958,
        "soil_moisture": 16.240736881997467,
        "ndvi": 0.44054163354747566,
        "soil_temp": 19.64205263174314,
        "soil_humidity": 62.843143097874005
    },
    {
        "date": "2024-10-01 07:53:09.251267",
        "air_temp": 22.517765702915387,
        "humidity": 74.40227785370644,
        "rainfall": 0,
        "wind_speed": 5.248321633924289,
        "soil_moisture": 20.131323241726733,
        "ndvi": 0.6566663530911548,
        "soil_temp": 17.79613729655901,
        "soil_humidity": 65.43530950286979
    },
    {
        "date": "2024-10-01 08:53:09.251267",
        "air_temp": 22.889525571400707,
        "humidity": 46.01937893945445,
        "rainfall": 1,
        "wind_speed": 4.835290149593632,
        "soil_moisture": 34.2902277426422,
        "ndvi": 0.48952304448000367,
        "soil_temp": 20.2269521692658,
        "soil_humidity": 39.38734131541001
    },
    {
        "date": "2024-10-01 09:53:09.251267",
        "air_temp": 24.188935368119733,
        "humidity": 86.92648917365652,
        "rainfall": 2,
        "wind_speed": 9.66028225687901,
        "soil_moisture": 30.87032989646017,
        "ndvi": 0.7641896919435587,
        "soil_temp": 16.74222947778142,
        "soil_humidity": 34.53842006099076
    },
    {
        "date": "2024-10-01 10:53:09.251267",
        "air_temp": 30.700330876741138,
        "humidity": 49.059163952504136,
        "rainfall": 0.5,
        "wind_speed": 17.736630789379184,
        "soil_moisture": 32.97352878147173,
        "ndvi": 0.37620584801506174,
        "soil_temp": 13.625068389589016,
        "soil_humidity": 32.13666687738042
    },
    {
        "date": "2024-10-01 11:53:09.251267",
        "air_temp": 32.84169377880469,
        "humidity": 71.13767016199205,
        "rainfall": 2,
        "wind_speed": 7.5669516336529385,
        "soil_moisture": 23.23603306638797,
        "ndvi": 0.44942214697095745,
        "soil_temp": 10.021273302008732,
        "soil_humidity": 54.33190454967587
    },
    {
        "date": "2024-10-01 12:53:09.251267",
        "air_temp": 34.10669373007856,
        "humidity": 51.133803956154246,
        "rainfall": 0,
        "wind_speed": 14.994514935670866,
        "soil_moisture": 31.494287879265006,
        "ndvi": 0.6721740862874249,
        "soil_temp": 18.399462207187646,
        "soil_humidity": 58.61412850000822
    },
    {
        "date": "2024-10-01 13:53:09.251267",
        "air_temp": 30.7380677251059,
        "humidity": 55.360820851063544,
        "rainfall": 1,
        "wind_speed": 1.936374619744372,
        "soil_moisture": 21.594811685917414,
        "ndvi": 0.5276147559924502,
        "soil_temp": 18.859828399129615,
        "soil_humidity": 44.91239956812822
    },
    {
        "date": "2024-10-01 14:53:09.251267",
        "air_temp": 21.308137914960255,
        "humidity": 67.32292708640622,
        "rainfall": 0,
        "wind_speed": 9.521134043021235,
        "soil_moisture": 16.337887508145894,
        "ndvi": 0.5282168878546687,
        "soil_temp": 24.580734471635715,
        "soil_humidity": 57.187034699625585
    },
    {
        "date": "2024-10-01 15:53:09.251267",
        "air_temp": 28.762694121730842,
        "humidity": 60.85967092822882,
        "rainfall": 0,
        "wind_speed": 19.833722807854713,
        "soil_moisture": 28.9803110452408,
        "ndvi": 0.23200409780311013,
        "soil_temp": 15.422175011414133,
        "soil_humidity": 58.518352622381045
    },
    {
        "date": "2024-10-01 16:53:09.251267",
        "air_temp": 23.75206248110784,
        "humidity": 48.01128004882531,
        "rainfall": 0,
        "wind_speed": 15.40875605584674,
        "soil_moisture": 39.280417767963826,
        "ndvi": 0.43915979763107355,
        "soil_temp": 15.911998642424676,
        "soil_humidity": 67.41369125587622
    },
    {
        "date": "2024-10-01 17:53:09.251267",
        "air_temp": 20.09341244092102,
        "humidity": 48.546820121042416,
        "rainfall": 0.5,
        "wind_speed": 4.21056976881226,
        "soil_moisture": 19.31802914185989,
        "ndvi": 0.3624402394530395,
        "soil_temp": 13.026993720132854,
        "soil_humidity": 32.59494551340429
    },
    {
        "date": "2024-10-01 18:53:09.251267",
        "air_temp": 31.817431588518453,
        "humidity": 60.907239408139986,
        "rainfall": 1,
        "wind_speed": 10.028829473958215,
        "soil_moisture": 23.82908776637619,
        "ndvi": 0.7996010736986694,
        "soil_temp": 11.05801442855983,
        "soil_humidity": 37.69166892924319
    },
    {
        "date": "2024-10-01 19:53:09.251267",
        "air_temp": 15.7685269792465,
        "humidity": 77.86414891377504,
        "rainfall": 1,
        "wind_speed": 4.6724952729713305,
        "soil_moisture": 30.22004113209561,
        "ndvi": 0.38501304237938616,
        "soil_temp": 21.592067392926108,
        "soil_humidity": 32.74103950387953
    },
    {
        "date": "2024-10-01 20:53:09.251267",
        "air_temp": 33.03523984270847,
        "humidity": 84.89823234238943,
        "rainfall": 0.5,
        "wind_speed": 8.191218545250186,
        "soil_moisture": 26.245188463674637,
        "ndvi": 0.7306979208763182,
        "soil_temp": 13.745215965089876,
        "soil_humidity": 64.71975733344442
    },
    {
        "date": "2024-10-01 21:53:09.251267",
        "air_temp": 24.22954929249468,
        "humidity": 44.20638311296914,
        "rainfall": 1,
        "wind_speed": 15.601680474699295,
        "soil_moisture": 35.302833871271474,
        "ndvi": 0.6057402410242865,
        "soil_temp": 19.13357291545057,
        "soil_humidity": 61.703486401342744
    },
    {
        "date": "2024-10-01 22:53:09.251267",
        "air_temp": 27.74402953616561,
        "humidity": 59.65519537797005,
        "rainfall": 0,
        "wind_speed": 15.424496305808074,
        "soil_moisture": 32.85228119408548,
        "ndvi": 0.4045498455152543,
        "soil_temp": 20.316595533182223,
        "soil_humidity": 52.985682824873564
    },
    {
        "date": "2024-10-01 23:53:09.251267",
        "air_temp": 28.187078444115656,
        "humidity": 45.01227818614383,
        "rainfall": 1,
        "wind_speed": 4.562007558047703,
        "soil_moisture": 11.748916115870712,
        "ndvi": 0.5261206270244743,
        "soil_temp": 17.068550309374714,
        "soil_humidity": 33.34653640204733
    },
    {
        "date": "2024-10-02 00:53:09.251267",
        "air_temp": 32.90235481413073,
        "humidity": 40.82962403162989,
        "rainfall": 0.5,
        "wind_speed": 18.444466363097202,
        "soil_moisture": 39.95263701771322,
        "ndvi": 0.7043215279695163,
        "soil_temp": 23.145281695407874,
        "soil_humidity": 40.932494346148786
    },
    {
        "date": "2024-10-02 01:53:09.251267",
        "air_temp": 27.733393643344147,
        "humidity": 73.08593413219782,
        "rainfall": 0,
        "wind_speed": 11.851363655296536,
        "soil_moisture": 32.57207361158402,
        "ndvi": 0.7300209788185472,
        "soil_temp": 16.65692261469593,
        "soil_humidity": 41.90879392234068
    },
    {
        "date": "2024-10-02 02:53:09.251267",
        "air_temp": 27.278671695201236,
        "humidity": 70.12247209832938,
        "rainfall": 0.5,
        "wind_speed": 7.59848581924029,
        "soil_moisture": 35.917099488823396,
        "ndvi": 0.29623632500368713,
        "soil_temp": 14.273629345626476,
        "soil_humidity": 52.429727840616685
    },
    {
        "date": "2024-10-02 03:53:09.251267",
        "air_temp": 16.3330408112138,
        "humidity": 48.15095569074431,
        "rainfall": 2,
        "wind_speed": 10.038711615617213,
        "soil_moisture": 29.535931362860392,
        "ndvi": 0.4037438847249018,
        "soil_temp": 23.440232844424337,
        "soil_humidity": 40.32175576146862
    },
    {
        "date": "2024-10-02 04:53:09.251267",
        "air_temp": 25.36816043890408,
        "humidity": 51.690641654653206,
        "rainfall": 0,
        "wind_speed": 12.101425068754875,
        "soil_moisture": 35.688553841402864,
        "ndvi": 0.40113390812814304,
        "soil_temp": 22.923464263749345,
        "soil_humidity": 32.918394556518194
    },
    {
        "date": "2024-10-02 05:53:09.251267",
        "air_temp": 18.003380016340834,
        "humidity": 41.18538768222592,
        "rainfall": 0,
        "wind_speed": 11.197810636915708,
        "soil_moisture": 13.371489247861282,
        "ndvi": 0.22522687764556681,
        "soil_temp": 12.460599206146195,
        "soil_humidity": 59.23049207413197
    },
    {
        "date": "2024-10-02 06:53:09.251267",
        "air_temp": 29.748675374429713,
        "humidity": 81.74736028472019,
        "rainfall": 1,
        "wind_speed": 9.728100710723563,
        "soil_moisture": 20.432135066779466,
        "ndvi": 0.558942655911138,
        "soil_temp": 10.389415275308814,
        "soil_humidity": 41.96320846621646
    },
    {
        "date": "2024-10-02 07:53:09.251267",
        "air_temp": 25.244438416253487,
        "humidity": 88.73452980242757,
        "rainfall": 0,
        "wind_speed": 10.098656081788002,
        "soil_moisture": 36.29262830813478,
        "ndvi": 0.7128920317953129,
        "soil_temp": 11.34978334524431,
        "soil_humidity": 48.09496708093202
    },
    {
        "date": "2024-10-02 08:53:09.251267",
        "air_temp": 28.604555584167066,
        "humidity": 46.77297683973454,
        "rainfall": 0.5,
        "wind_speed": 3.5282382085577235,
        "soil_moisture": 16.049656338006766,
        "ndvi": 0.4289446326953107,
        "soil_temp": 14.298210978307257,
        "soil_humidity": 35.10666539929221
    },
    {
        "date": "2024-10-02 09:53:09.251267",
        "air_temp": 15.833458012047686,
        "humidity": 51.573760688264905,
        "rainfall": 1,
        "wind_speed": 16.25465808609359,
        "soil_moisture": 12.347262957400682,
        "ndvi": 0.6440215669905063,
        "soil_temp": 24.583897026125662,
        "soil_humidity": 62.10193213578319
    },
    {
        "date": "2024-10-02 10:53:09.251267",
        "air_temp": 16.695840320916066,
        "humidity": 83.4204761973467,
        "rainfall": 2,
        "wind_speed": 10.835206398820308,
        "soil_moisture": 33.64356819835657,
        "ndvi": 0.576620248160444,
        "soil_temp": 18.377742297412375,
        "soil_humidity": 52.621275076962306
    },
    {
        "date": "2024-10-02 11:53:09.251267",
        "air_temp": 29.326467761253646,
        "humidity": 86.32318989780924,
        "rainfall": 0.5,
        "wind_speed": 2.621597234094062,
        "soil_moisture": 35.06458466929888,
        "ndvi": 0.4253892412958005,
        "soil_temp": 15.598925980439033,
        "soil_humidity": 66.06342504839452
    },
    {
        "date": "2024-10-02 12:53:09.251267",
        "air_temp": 16.441686719973468,
        "humidity": 60.98136914034052,
        "rainfall": 2,
        "wind_speed": 15.323142031655559,
        "soil_moisture": 14.879566545697019,
        "ndvi": 0.2846358796953783,
        "soil_temp": 23.330741419191195,
        "soil_humidity": 49.81886252261333
    },
    {
        "date": "2024-10-02 13:53:09.251267",
        "air_temp": 16.42513455530517,
        "humidity": 42.53112361345371,
        "rainfall": 2,
        "wind_speed": 18.648879567547016,
        "soil_moisture": 35.63037948348146,
        "ndvi": 0.6255943116655804,
        "soil_temp": 16.797044972177495,
        "soil_humidity": 67.65702687271519
    },
    {
        "date": "2024-10-02 14:53:09.251267",
        "air_temp": 15.242169504697749,
        "humidity": 41.96959168464097,
        "rainfall": 2,
        "wind_speed": 18.94613798473924,
        "soil_moisture": 30.603033867915087,
        "ndvi": 0.7127391013647444,
        "soil_temp": 14.728579426015317,
        "soil_humidity": 65.67472817525304
    },
    {
        "date": "2024-10-02 15:53:09.251267",
        "air_temp": 34.1300279659896,
        "humidity": 68.69749330345053,
        "rainfall": 0,
        "wind_speed": 7.380354648223455,
        "soil_moisture": 25.602480496497517,
        "ndvi": 0.6115126954408951,
        "soil_temp": 24.11829293118486,
        "soil_humidity": 53.05839425836936
    },
    {
        "date": "2024-10-02 16:53:09.251267",
        "air_temp": 29.75016718918014,
        "humidity": 59.644755444658955,
        "rainfall": 0.5,
        "wind_speed": 2.7103374712150807,
        "soil_moisture": 34.90750578344155,
        "ndvi": 0.21072709042089086,
        "soil_temp": 22.74276499289017,
        "soil_humidity": 67.35573752599453
    },
    {
        "date": "2024-10-02 17:53:09.251267",
        "air_temp": 22.065028170041284,
        "humidity": 41.43262137888273,
        "rainfall": 0.5,
        "wind_speed": 10.367992532661303,
        "soil_moisture": 22.577477479568973,
        "ndvi": 0.32973973663459477,
        "soil_temp": 22.655025631291615,
        "soil_humidity": 42.92354989178209
    },
    {
        "date": "2024-10-02 18:53:09.251267",
        "air_temp": 20.930711625753105,
        "humidity": 69.16261961562785,
        "rainfall": 0,
        "wind_speed": 2.2521845869844137,
        "soil_moisture": 15.819818686701916,
        "ndvi": 0.24168799093011917,
        "soil_temp": 17.684271145753197,
        "soil_humidity": 44.62568650816846
    },
    {
        "date": "2024-10-02 19:53:09.251267",
        "air_temp": 21.994064594916992,
        "humidity": 40.565126829451124,
        "rainfall": 1,
        "wind_speed": 18.679748370252344,
        "soil_moisture": 18.54558029831841,
        "ndvi": 0.5422316597457362,
        "soil_temp": 21.841575982231824,
        "soil_humidity": 31.81911805224148
    },
    {
        "date": "2024-10-02 20:53:09.251267",
        "air_temp": 30.493070623623638,
        "humidity": 79.36859790746365,
        "rainfall": 2,
        "wind_speed": 3.67159254710292,
        "soil_moisture": 25.154920548799446,
        "ndvi": 0.6538866073042475,
        "soil_temp": 12.690300388968279,
        "soil_humidity": 53.568036270554124
    },
    {
        "date": "2024-10-02 21:53:09.251267",
        "air_temp": 28.227412221408287,
        "humidity": 55.321641135168576,
        "rainfall": 0.5,
        "wind_speed": 5.055317199023282,
        "soil_moisture": 25.939923780195272,
        "ndvi": 0.2663475122411957,
        "soil_temp": 18.598344363343656,
        "soil_humidity": 58.876958797157336
    },
    {
        "date": "2024-10-02 22:53:09.251267",
        "air_temp": 18.70391135734936,
        "humidity": 42.012900150461604,
        "rainfall": 0,
        "wind_speed": 8.132730537699409,
        "soil_moisture": 34.16742479323701,
        "ndvi": 0.7030026923560313,
        "soil_temp": 24.757688842713186,
        "soil_humidity": 60.94076760968413
    },
    {
        "date": "2024-10-02 23:53:09.251267",
        "air_temp": 18.482186706681688,
        "humidity": 69.41761104915767,
        "rainfall": 2,
        "wind_speed": 7.663289371182516,
        "soil_moisture": 33.74058170957004,
        "ndvi": 0.5333053870372899,
        "soil_temp": 14.364376457769424,
        "soil_humidity": 35.99265144184413
    },
    {
        "date": "2024-10-03 00:53:09.251267",
        "air_temp": 16.967912994267948,
        "humidity": 59.88422072165274,
        "rainfall": 1,
        "wind_speed": 15.89325784795778,
        "soil_moisture": 27.440996716137246,
        "ndvi": 0.613981407285035,
        "soil_temp": 21.91528493871513,
        "soil_humidity": 47.76636289250001
    },
    {
        "date": "2024-10-03 01:53:09.251267",
        "air_temp": 28.206054394026765,
        "humidity": 88.69002066785748,
        "rainfall": 1,
        "wind_speed": 9.126336411019247,
        "soil_moisture": 33.42356450096429,
        "ndvi": 0.7828411091651879,
        "soil_temp": 17.35717610812101,
        "soil_humidity": 37.07452848375662
    },
    {
        "date": "2024-10-03 02:53:09.251267",
        "air_temp": 30.28745324443472,
        "humidity": 67.2099331251851,
        "rainfall": 2,
        "wind_speed": 3.415365200066738,
        "soil_moisture": 15.75320374881013,
        "ndvi": 0.2502085929486184,
        "soil_temp": 10.59708524833131,
        "soil_humidity": 56.485093954169955
    },
    {
        "date": "2024-10-03 03:53:09.251267",
        "air_temp": 20.30092856650008,
        "humidity": 53.76195905185041,
        "rainfall": 1,
        "wind_speed": 5.344197909721482,
        "soil_moisture": 11.50193581279883,
        "ndvi": 0.7407344137866736,
        "soil_temp": 21.44529096723501,
        "soil_humidity": 36.31785489687073
    },
    {
        "date": "2024-10-03 04:53:09.251267",
        "air_temp": 15.418899220618256,
        "humidity": 75.47194323169873,
        "rainfall": 0.5,
        "wind_speed": 10.18226770375044,
        "soil_moisture": 36.209014679041005,
        "ndvi": 0.2756616175924456,
        "soil_temp": 12.528537165881692,
        "soil_humidity": 66.166547358243
    },
    {
        "date": "2024-10-03 05:53:09.251267",
        "air_temp": 16.643433349528546,
        "humidity": 53.57460012713557,
        "rainfall": 0.5,
        "wind_speed": 2.949078348522698,
        "soil_moisture": 12.266634812883087,
        "ndvi": 0.4357752839820094,
        "soil_temp": 19.472103506991253,
        "soil_humidity": 39.22538582645653
    },
    {
        "date": "2024-10-03 06:53:09.251267",
        "air_temp": 34.357200696900605,
        "humidity": 85.21216457037826,
        "rainfall": 0.5,
        "wind_speed": 12.233113512920282,
        "soil_moisture": 17.40065289231992,
        "ndvi": 0.7108632177986687,
        "soil_temp": 11.284962910704593,
        "soil_humidity": 41.656090651529794
    },
    {
        "date": "2024-10-03 07:53:09.251267",
        "air_temp": 20.908895549548404,
        "humidity": 58.73609852536882,
        "rainfall": 1,
        "wind_speed": 11.314946131022918,
        "soil_moisture": 30.859568488731536,
        "ndvi": 0.7437525420427211,
        "soil_temp": 24.20301096243947,
        "soil_humidity": 64.03625288603999
    },
    {
        "date": "2024-10-03 08:53:09.251267",
        "air_temp": 30.384462956762672,
        "humidity": 67.49342813881026,
        "rainfall": 0.5,
        "wind_speed": 14.294046327645153,
        "soil_moisture": 22.283438128819274,
        "ndvi": 0.5038230041270872,
        "soil_temp": 14.375858307705414,
        "soil_humidity": 48.33870440582895
    },
    {
        "date": "2024-10-03 09:53:09.251267",
        "air_temp": 27.493271377085275,
        "humidity": 42.52865519121144,
        "rainfall": 0,
        "wind_speed": 18.754092307587353,
        "soil_moisture": 36.28941909746526,
        "ndvi": 0.5564627829652551,
        "soil_temp": 13.396970375160924,
        "soil_humidity": 30.959837832002922
    },
    {
        "date": "2024-10-03 10:53:09.251267",
        "air_temp": 22.638792793057917,
        "humidity": 61.30606189761154,
        "rainfall": 0.5,
        "wind_speed": 18.245091222023113,
        "soil_moisture": 18.939393839868085,
        "ndvi": 0.6248884007591344,
        "soil_temp": 11.937046060995014,
        "soil_humidity": 49.783450048105834
    },
    {
        "date": "2024-10-03 11:53:09.251267",
        "air_temp": 19.113745243911104,
        "humidity": 81.61817698304975,
        "rainfall": 0.5,
        "wind_speed": 18.921512361376628,
        "soil_moisture": 10.356140400080779,
        "ndvi": 0.6996552010716286,
        "soil_temp": 24.19003290008572,
        "soil_humidity": 33.38173341520492
    },
    {
        "date": "2024-10-03 12:53:09.251267",
        "air_temp": 17.42772836318813,
        "humidity": 80.2824402681671,
        "rainfall": 1,
        "wind_speed": 4.894311940474019,
        "soil_moisture": 19.019669216822322,
        "ndvi": 0.27290670172259973,
        "soil_temp": 20.678556136619576,
        "soil_humidity": 69.41378874749242
    },
    {
        "date": "2024-10-03 13:53:09.251267",
        "air_temp": 27.300259360777332,
        "humidity": 51.215927942676316,
        "rainfall": 0.5,
        "wind_speed": 2.6313061327870924,
        "soil_moisture": 27.34225147554495,
        "ndvi": 0.6860415849375366,
        "soil_temp": 21.124913330771328,
        "soil_humidity": 53.524462818224094
    },
    {
        "date": "2024-10-03 14:53:09.251267",
        "air_temp": 30.49267561275576,
        "humidity": 51.303953550528135,
        "rainfall": 2,
        "wind_speed": 6.04553400992897,
        "soil_moisture": 21.278151262098802,
        "ndvi": 0.4887656903069701,
        "soil_temp": 17.49106842070367,
        "soil_humidity": 40.26130470443914
    },
    {
        "date": "2024-10-03 15:53:09.251267",
        "air_temp": 27.878085070822614,
        "humidity": 80.86526561066184,
        "rainfall": 0,
        "wind_speed": 17.90170345532629,
        "soil_moisture": 26.046182672762658,
        "ndvi": 0.47037405506490143,
        "soil_temp": 17.120916779057254,
        "soil_humidity": 52.54870718190471
    },
    {
        "date": "2024-10-03 16:53:09.251267",
        "air_temp": 25.606042664983164,
        "humidity": 86.51992100846627,
        "rainfall": 1,
        "wind_speed": 19.441069492469765,
        "soil_moisture": 36.29794356505056,
        "ndvi": 0.454339851198199,
        "soil_temp": 11.650318644872149,
        "soil_humidity": 33.074684135270196
    },
    {
        "date": "2024-10-03 17:53:09.251267",
        "air_temp": 15.839024468632726,
        "humidity": 44.768046063650175,
        "rainfall": 0,
        "wind_speed": 17.18649902140952,
        "soil_moisture": 37.37455162691106,
        "ndvi": 0.5055269511476257,
        "soil_temp": 18.39761643375988,
        "soil_humidity": 61.99565217075268
    },
    {
        "date": "2024-10-03 18:53:09.251267",
        "air_temp": 34.36977555188768,
        "humidity": 62.50293197131126,
        "rainfall": 2,
        "wind_speed": 2.200354422550872,
        "soil_moisture": 17.979221939623365,
        "ndvi": 0.7670303320367622,
        "soil_temp": 13.703747426631857,
        "soil_humidity": 67.01312215456655
    },
    {
        "date": "2024-10-03 19:53:09.251267",
        "air_temp": 30.97428383309149,
        "humidity": 56.87271394821145,
        "rainfall": 1,
        "wind_speed": 18.948559961195862,
        "soil_moisture": 23.864214198097024,
        "ndvi": 0.528922263675291,
        "soil_temp": 24.657179992006085,
        "soil_humidity": 64.97777552824638
    },
    {
        "date": "2024-10-03 20:53:09.251267",
        "air_temp": 20.856440734019294,
        "humidity": 83.53442277287198,
        "rainfall": 1,
        "wind_speed": 8.46716054701572,
        "soil_moisture": 26.474320457243536,
        "ndvi": 0.7719065229578836,
        "soil_temp": 21.94871410022721,
        "soil_humidity": 43.67030409194251
    },
    {
        "date": "2024-10-03 21:53:09.251267",
        "air_temp": 34.599406587177015,
        "humidity": 44.176245734000936,
        "rainfall": 0.5,
        "wind_speed": 0.18620581524707358,
        "soil_moisture": 38.47337370664741,
        "ndvi": 0.3081740783693844,
        "soil_temp": 16.194880902893313,
        "soil_humidity": 43.03072478573474
    },
    {
        "date": "2024-10-03 22:53:09.251267",
        "air_temp": 27.037631792726444,
        "humidity": 50.55629712790187,
        "rainfall": 0,
        "wind_speed": 8.847874814963872,
        "soil_moisture": 39.95851957493154,
        "ndvi": 0.6344815872995799,
        "soil_temp": 12.270118880372996,
        "soil_humidity": 31.403504448652
    },
    {
        "date": "2024-10-03 23:53:09.251267",
        "air_temp": 26.64845316414493,
        "humidity": 77.62341687932471,
        "rainfall": 2,
        "wind_speed": 0.9213833935093518,
        "soil_moisture": 17.0622174652458,
        "ndvi": 0.6873437231652484,
        "soil_temp": 21.77997536708176,
        "soil_humidity": 56.05601434803667
    },
    {
        "date": "2024-10-04 00:53:09.251267",
        "air_temp": 29.961463588590643,
        "humidity": 42.56469244906078,
        "rainfall": 2,
        "wind_speed": 10.87755842322235,
        "soil_moisture": 31.809396140374464,
        "ndvi": 0.6074772988009172,
        "soil_temp": 11.83080650621974,
        "soil_humidity": 51.3961913675918
    },
    {
        "date": "2024-10-04 01:53:09.251267",
        "air_temp": 31.23539576180275,
        "humidity": 64.62676903571446,
        "rainfall": 1,
        "wind_speed": 6.771080548741026,
        "soil_moisture": 21.340931866995852,
        "ndvi": 0.5641533681359243,
        "soil_temp": 12.840006302456057,
        "soil_humidity": 48.373854853093654
    },
    {
        "date": "2024-10-04 02:53:09.251267",
        "air_temp": 28.129572146630053,
        "humidity": 62.105351733986,
        "rainfall": 0.5,
        "wind_speed": 9.468698671880976,
        "soil_moisture": 10.243245906586397,
        "ndvi": 0.5388876686517095,
        "soil_temp": 13.172807766163412,
        "soil_humidity": 66.30523813338829
    },
    {
        "date": "2024-10-04 03:53:09.251267",
        "air_temp": 17.561914927324,
        "humidity": 56.72005894943743,
        "rainfall": 1,
        "wind_speed": 12.26340721486234,
        "soil_moisture": 10.27757567307707,
        "ndvi": 0.7165582895472176,
        "soil_temp": 18.353359574096416,
        "soil_humidity": 36.715156043313854
    },
    {
        "date": "2024-10-04 04:53:09.251267",
        "air_temp": 21.765350119889966,
        "humidity": 59.72861576594619,
        "rainfall": 1,
        "wind_speed": 17.376021916356407,
        "soil_moisture": 23.81698022686767,
        "ndvi": 0.6929879582987002,
        "soil_temp": 21.805849809855495,
        "soil_humidity": 30.38665066760729
    },
    {
        "date": "2024-10-04 05:53:09.251267",
        "air_temp": 33.561672806520995,
        "humidity": 66.49702934651165,
        "rainfall": 1,
        "wind_speed": 13.238031529438572,
        "soil_moisture": 24.65004739013004,
        "ndvi": 0.2733732327083597,
        "soil_temp": 14.714723180870383,
        "soil_humidity": 32.93265808483062
    },
    {
        "date": "2024-10-04 06:53:09.251267",
        "air_temp": 19.49231223687704,
        "humidity": 48.06836791941916,
        "rainfall": 1,
        "wind_speed": 8.680977369563614,
        "soil_moisture": 10.67709089720328,
        "ndvi": 0.709152594218527,
        "soil_temp": 11.270047214129342,
        "soil_humidity": 38.934857513094755
    },
    {
        "date": "2024-10-04 07:53:09.251267",
        "air_temp": 22.443340476750265,
        "humidity": 68.59979391417964,
        "rainfall": 2,
        "wind_speed": 16.388557073407302,
        "soil_moisture": 30.607822503229073,
        "ndvi": 0.44844117166426556,
        "soil_temp": 16.968076952866287,
        "soil_humidity": 31.33142197053828
    },
    {
        "date": "2024-10-04 08:53:09.251267",
        "air_temp": 23.64153765888392,
        "humidity": 80.27161646499931,
        "rainfall": 2,
        "wind_speed": 18.111642751462643,
        "soil_moisture": 18.820101657848593,
        "ndvi": 0.3522180979974865,
        "soil_temp": 21.221653617399035,
        "soil_humidity": 50.11374842572542
    },
    {
        "date": "2024-10-04 09:53:09.251267",
        "air_temp": 23.788099793889046,
        "humidity": 78.00804648950796,
        "rainfall": 1,
        "wind_speed": 2.3951993286439444,
        "soil_moisture": 26.79679304607848,
        "ndvi": 0.7218611408083555,
        "soil_temp": 13.324957646310825,
        "soil_humidity": 42.56361820934312
    },
    {
        "date": "2024-10-04 10:53:09.251267",
        "air_temp": 27.25879164989051,
        "humidity": 47.69499523468868,
        "rainfall": 1,
        "wind_speed": 10.638659551055829,
        "soil_moisture": 19.027572194962563,
        "ndvi": 0.459214879780245,
        "soil_temp": 13.298868220590613,
        "soil_humidity": 37.21932032272531
    },
    {
        "date": "2024-10-04 11:53:09.251267",
        "air_temp": 33.861516754894154,
        "humidity": 47.462473487073694,
        "rainfall": 0.5,
        "wind_speed": 5.690239194848883,
        "soil_moisture": 15.014029446079215,
        "ndvi": 0.68501881410609,
        "soil_temp": 24.208405410200662,
        "soil_humidity": 60.00336299794648
    },
    {
        "date": "2024-10-04 12:53:09.251267",
        "air_temp": 19.8138542366501,
        "humidity": 53.40871838724567,
        "rainfall": 1,
        "wind_speed": 0.941223957538948,
        "soil_moisture": 22.215212157966256,
        "ndvi": 0.35804216717350673,
        "soil_temp": 24.31230590678767,
        "soil_humidity": 38.1780530493012
    },
    {
        "date": "2024-10-04 13:53:09.251267",
        "air_temp": 17.43002752601836,
        "humidity": 58.05373631705186,
        "rainfall": 2,
        "wind_speed": 13.370933902279706,
        "soil_moisture": 16.968200943715022,
        "ndvi": 0.2023575928533307,
        "soil_temp": 24.856985980923646,
        "soil_humidity": 57.50420403975173
    },
    {
        "date": "2024-10-04 14:53:09.251267",
        "air_temp": 18.949409797198747,
        "humidity": 60.42277907042413,
        "rainfall": 0,
        "wind_speed": 17.255415549184512,
        "soil_moisture": 26.04402930757504,
        "ndvi": 0.4972755157417667,
        "soil_temp": 22.1148940236217,
        "soil_humidity": 43.97059623390555
    },
    {
        "date": "2024-10-04 15:53:09.251267",
        "air_temp": 32.73849800418539,
        "humidity": 73.98486084166726,
        "rainfall": 2,
        "wind_speed": 3.258053089547881,
        "soil_moisture": 15.964855908594398,
        "ndvi": 0.26013695398573056,
        "soil_temp": 12.822762537519184,
        "soil_humidity": 50.47311042650218
    },
    {
        "date": "2024-10-04 16:53:09.251267",
        "air_temp": 27.91621625310412,
        "humidity": 42.83402160829177,
        "rainfall": 0.5,
        "wind_speed": 16.24827315247801,
        "soil_moisture": 25.252179299338813,
        "ndvi": 0.6526255733149459,
        "soil_temp": 13.17972281198892,
        "soil_humidity": 67.35468159405279
    },
    {
        "date": "2024-10-04 17:53:09.251267",
        "air_temp": 20.7181357847832,
        "humidity": 41.73363521130332,
        "rainfall": 2,
        "wind_speed": 17.067914670738833,
        "soil_moisture": 14.721355446066028,
        "ndvi": 0.3656450816416462,
        "soil_temp": 15.10337862283588,
        "soil_humidity": 31.494065392632514
    },
    {
        "date": "2024-10-04 18:53:09.251267",
        "air_temp": 31.318938779372345,
        "humidity": 59.59552814719545,
        "rainfall": 0,
        "wind_speed": 18.28680885332967,
        "soil_moisture": 30.12924128457355,
        "ndvi": 0.4661873879620873,
        "soil_temp": 14.771987970002362,
        "soil_humidity": 50.01058029909848
    },
    {
        "date": "2024-10-04 19:53:09.251267",
        "air_temp": 32.22739992726872,
        "humidity": 74.85818460689902,
        "rainfall": 1,
        "wind_speed": 5.305701781721048,
        "soil_moisture": 32.61813053324272,
        "ndvi": 0.25222013905493257,
        "soil_temp": 24.347916236170136,
        "soil_humidity": 64.17691841310473
    },
    {
        "date": "2024-10-04 20:53:09.251267",
        "air_temp": 31.930286773805562,
        "humidity": 49.67176714782413,
        "rainfall": 0,
        "wind_speed": 18.079061303797786,
        "soil_moisture": 13.311827027498014,
        "ndvi": 0.37199554906835164,
        "soil_temp": 21.836109743225403,
        "soil_humidity": 53.51896993481394
    },
    {
        "date": "2024-10-04 21:53:09.251267",
        "air_temp": 33.378530672791086,
        "humidity": 72.0752240628126,
        "rainfall": 1,
        "wind_speed": 6.413255094728645,
        "soil_moisture": 24.034072361120174,
        "ndvi": 0.6688941459551432,
        "soil_temp": 20.86013188242331,
        "soil_humidity": 41.074820678606265
    },
    {
        "date": "2024-10-04 22:53:09.251267",
        "air_temp": 20.04482040346723,
        "humidity": 52.991406897014976,
        "rainfall": 1,
        "wind_speed": 11.046760269110056,
        "soil_moisture": 12.126151019646208,
        "ndvi": 0.2909527471413917,
        "soil_temp": 12.789448360871978,
        "soil_humidity": 45.907245405801376
    },
    {
        "date": "2024-10-04 23:53:09.251267",
        "air_temp": 30.100838574925568,
        "humidity": 84.30430544067582,
        "rainfall": 2,
        "wind_speed": 1.0101494166456426,
        "soil_moisture": 27.94547336626007,
        "ndvi": 0.5882957366761745,
        "soil_temp": 23.642883390434946,
        "soil_humidity": 57.210625149142984
    },
    {
        "date": "2024-10-05 00:53:09.251267",
        "air_temp": 24.2107899454828,
        "humidity": 84.78449721333082,
        "rainfall": 2,
        "wind_speed": 0.8776217673748721,
        "soil_moisture": 17.93212893788936,
        "ndvi": 0.7504555116827007,
        "soil_temp": 22.41805569259261,
        "soil_humidity": 65.63247577255211
    },
    {
        "date": "2024-10-05 01:53:09.251267",
        "air_temp": 31.83997105780123,
        "humidity": 54.86436021406632,
        "rainfall": 2,
        "wind_speed": 14.407464254644058,
        "soil_moisture": 25.828733705161234,
        "ndvi": 0.6079020558546322,
        "soil_temp": 12.454627619918856,
        "soil_humidity": 31.642654949864465
    },
    {
        "date": "2024-10-05 02:53:09.251267",
        "air_temp": 29.569813543232264,
        "humidity": 51.49968769816203,
        "rainfall": 0.5,
        "wind_speed": 8.88959955955582,
        "soil_moisture": 25.523830281528735,
        "ndvi": 0.6866130135753361,
        "soil_temp": 23.067554979414588,
        "soil_humidity": 32.861552875615054
    },
    {
        "date": "2024-10-05 03:53:09.251267",
        "air_temp": 30.528948929994492,
        "humidity": 60.565198603311565,
        "rainfall": 2,
        "wind_speed": 5.125847507384352,
        "soil_moisture": 37.73063038581401,
        "ndvi": 0.2653829775823495,
        "soil_temp": 14.083713045557069,
        "soil_humidity": 43.0020609443835
    },
    {
        "date": "2024-10-05 04:53:09.251267",
        "air_temp": 28.12323675362143,
        "humidity": 52.026579259007434,
        "rainfall": 0.5,
        "wind_speed": 3.808269920498204,
        "soil_moisture": 26.472496677061688,
        "ndvi": 0.3504390956012327,
        "soil_temp": 11.213046187481591,
        "soil_humidity": 39.31118737749916
    },
    {
        "date": "2024-10-05 05:53:09.251267",
        "air_temp": 18.548575389394138,
        "humidity": 73.61919216458517,
        "rainfall": 2,
        "wind_speed": 13.399069817184362,
        "soil_moisture": 27.489954391549468,
        "ndvi": 0.7367233113443548,
        "soil_temp": 19.852334597713153,
        "soil_humidity": 40.59723544889624
    },
    {
        "date": "2024-10-05 06:53:09.251267",
        "air_temp": 25.900538377938766,
        "humidity": 81.30323422741428,
        "rainfall": 1,
        "wind_speed": 0.008030880506797455,
        "soil_moisture": 22.04959377293141,
        "ndvi": 0.6290633681708536,
        "soil_temp": 19.807069890764957,
        "soil_humidity": 38.850799945035234
    },
    {
        "date": "2024-10-05 07:53:09.251267",
        "air_temp": 34.69339479049016,
        "humidity": 73.65460654556085,
        "rainfall": 0.5,
        "wind_speed": 16.117823383937086,
        "soil_moisture": 38.472388095484504,
        "ndvi": 0.5837322958263867,
        "soil_temp": 11.423980143484272,
        "soil_humidity": 37.83300195420631
    },
    {
        "date": "2024-10-05 08:53:09.251267",
        "air_temp": 33.74776132994238,
        "humidity": 81.21752236246748,
        "rainfall": 0,
        "wind_speed": 1.5849921225985764,
        "soil_moisture": 33.31557728318829,
        "ndvi": 0.59826004167302,
        "soil_temp": 14.782777134048736,
        "soil_humidity": 35.22495867477167
    },
    {
        "date": "2024-10-05 09:53:09.251267",
        "air_temp": 15.863474715940233,
        "humidity": 59.84960811037371,
        "rainfall": 0.5,
        "wind_speed": 3.829792298162389,
        "soil_moisture": 18.562158709085246,
        "ndvi": 0.4485203325827861,
        "soil_temp": 24.89221693383308,
        "soil_humidity": 67.35816527180953
    },
    {
        "date": "2024-10-05 10:53:09.251267",
        "air_temp": 18.296296314588744,
        "humidity": 47.815848535443045,
        "rainfall": 0.5,
        "wind_speed": 7.724727905981792,
        "soil_moisture": 35.32628757328696,
        "ndvi": 0.22673397897059194,
        "soil_temp": 20.176400005131054,
        "soil_humidity": 35.76757855624612
    },
    {
        "date": "2024-10-05 11:53:09.251267",
        "air_temp": 17.63457546909124,
        "humidity": 76.89754736987962,
        "rainfall": 0,
        "wind_speed": 9.697174794322072,
        "soil_moisture": 22.9718908055527,
        "ndvi": 0.6706649252424375,
        "soil_temp": 20.95112749904994,
        "soil_humidity": 38.17615099062145
    },
    {
        "date": "2024-10-05 12:53:09.251267",
        "air_temp": 29.51959819970591,
        "humidity": 58.02372525707769,
        "rainfall": 0.5,
        "wind_speed": 7.099902316278836,
        "soil_moisture": 34.86001430600782,
        "ndvi": 0.6072279417292124,
        "soil_temp": 13.193759284064585,
        "soil_humidity": 39.85062895923143
    },
    {
        "date": "2024-10-05 13:53:09.251267",
        "air_temp": 31.3557065971389,
        "humidity": 73.56354126145663,
        "rainfall": 1,
        "wind_speed": 6.414243299685878,
        "soil_moisture": 34.68664204880506,
        "ndvi": 0.48368267846763485,
        "soil_temp": 11.181731065058715,
        "soil_humidity": 67.32059492521682
    },
    {
        "date": "2024-10-05 14:53:09.251267",
        "air_temp": 19.270227326213437,
        "humidity": 53.53219709908828,
        "rainfall": 2,
        "wind_speed": 19.278320241463767,
        "soil_moisture": 15.666105602885631,
        "ndvi": 0.7398557692209546,
        "soil_temp": 14.153525346114066,
        "soil_humidity": 60.22856534404339
    },
    {
        "date": "2024-10-05 15:53:09.251267",
        "air_temp": 25.11705385903658,
        "humidity": 44.06148174033363,
        "rainfall": 0,
        "wind_speed": 12.831593624867885,
        "soil_moisture": 31.034233941507306,
        "ndvi": 0.40169579899105934,
        "soil_temp": 13.73347577789788,
        "soil_humidity": 61.587977212620586
    },
    {
        "date": "2024-10-05 16:53:09.251267",
        "air_temp": 31.814060560224,
        "humidity": 89.62908977438818,
        "rainfall": 2,
        "wind_speed": 0.46865910734776417,
        "soil_moisture": 18.71928907919593,
        "ndvi": 0.3765930465387416,
        "soil_temp": 13.518161511220796,
        "soil_humidity": 34.19033038153992
    },
    {
        "date": "2024-10-05 17:53:09.251267",
        "air_temp": 29.65603089532501,
        "humidity": 47.81007539652997,
        "rainfall": 1,
        "wind_speed": 8.603518168886264,
        "soil_moisture": 18.924780099780044,
        "ndvi": 0.7236777950476658,
        "soil_temp": 12.891314828305592,
        "soil_humidity": 63.452395000626865
    },
    {
        "date": "2024-10-05 18:53:09.251267",
        "air_temp": 25.844744109836967,
        "humidity": 89.42104969817404,
        "rainfall": 0,
        "wind_speed": 17.500173994879404,
        "soil_moisture": 18.89044230641742,
        "ndvi": 0.6781925928553914,
        "soil_temp": 18.621335359831065,
        "soil_humidity": 46.327723272696716
    },
    {
        "date": "2024-10-05 19:53:09.251267",
        "air_temp": 26.806953809175457,
        "humidity": 88.86399662832235,
        "rainfall": 2,
        "wind_speed": 6.029123728213359,
        "soil_moisture": 31.767047426798445,
        "ndvi": 0.5089878863890069,
        "soil_temp": 14.921552137627327,
        "soil_humidity": 40.51292104849555
    },
    {
        "date": "2024-10-05 20:53:09.251267",
        "air_temp": 25.1672109383256,
        "humidity": 79.69090519540198,
        "rainfall": 2,
        "wind_speed": 1.8911268954877647,
        "soil_moisture": 16.89478125377608,
        "ndvi": 0.27844084367471,
        "soil_temp": 22.305175225712958,
        "soil_humidity": 53.03000334081242
    },
    {
        "date": "2024-10-05 21:53:09.251267",
        "air_temp": 20.950969026376413,
        "humidity": 72.97114810409988,
        "rainfall": 1,
        "wind_speed": 15.167695431798355,
        "soil_moisture": 10.027175057382706,
        "ndvi": 0.3290428828790315,
        "soil_temp": 12.75921126771076,
        "soil_humidity": 62.887316232669356
    },
    {
        "date": "2024-10-05 22:53:09.251267",
        "air_temp": 26.300439857180322,
        "humidity": 68.89035251255423,
        "rainfall": 2,
        "wind_speed": 12.120276638721625,
        "soil_moisture": 31.457105604478993,
        "ndvi": 0.593736794245284,
        "soil_temp": 16.969406063028167,
        "soil_humidity": 61.14608604299637
    },
    {
        "date": "2024-10-05 23:53:09.251267",
        "air_temp": 28.777706041001814,
        "humidity": 83.30507726889174,
        "rainfall": 1,
        "wind_speed": 11.406250156530078,
        "soil_moisture": 15.135542315053758,
        "ndvi": 0.43102474270424707,
        "soil_temp": 10.204867546391199,
        "soil_humidity": 65.59989930324528
    },
    {
        "date": "2024-10-06 00:53:09.251267",
        "air_temp": 32.46645831721615,
        "humidity": 54.4719760809744,
        "rainfall": 0.5,
        "wind_speed": 1.0715311921481585,
        "soil_moisture": 13.13447185923504,
        "ndvi": 0.7018839149995828,
        "soil_temp": 17.22523483682788,
        "soil_humidity": 67.12528744061775
    },
    {
        "date": "2024-10-06 01:53:09.251267",
        "air_temp": 27.72582707958496,
        "humidity": 63.38406062424385,
        "rainfall": 1,
        "wind_speed": 2.8099801959103465,
        "soil_moisture": 17.125483617821804,
        "ndvi": 0.7699730055993226,
        "soil_temp": 10.837573313147116,
        "soil_humidity": 39.524882857109986
    },
    {
        "date": "2024-10-06 02:53:09.251267",
        "air_temp": 30.22243074086316,
        "humidity": 70.9694984683251,
        "rainfall": 0.5,
        "wind_speed": 12.450987924262275,
        "soil_moisture": 21.397015669681117,
        "ndvi": 0.683382155164951,
        "soil_temp": 24.665852678089657,
        "soil_humidity": 46.95431576677087
    },
    {
        "date": "2024-10-06 03:53:09.251267",
        "air_temp": 18.20143269909146,
        "humidity": 60.559523793474554,
        "rainfall": 0,
        "wind_speed": 4.156476829176716,
        "soil_moisture": 24.27718982419602,
        "ndvi": 0.42652725379733436,
        "soil_temp": 21.858881929756194,
        "soil_humidity": 30.5851415682028
    },
    {
        "date": "2024-10-06 04:53:09.251267",
        "air_temp": 24.231149488395797,
        "humidity": 61.3743227045134,
        "rainfall": 2,
        "wind_speed": 10.081401594968487,
        "soil_moisture": 11.337101221378925,
        "ndvi": 0.6732118575388546,
        "soil_temp": 17.35167214954683,
        "soil_humidity": 65.1728663706024
    },
    {
        "date": "2024-10-06 05:53:09.251267",
        "air_temp": 15.186632396541865,
        "humidity": 56.51423337510422,
        "rainfall": 1,
        "wind_speed": 10.89763372058787,
        "soil_moisture": 39.117199978820814,
        "ndvi": 0.4108650040824101,
        "soil_temp": 11.298810372690253,
        "soil_humidity": 36.182886058990704
    },
    {
        "date": "2024-10-06 06:53:09.251267",
        "air_temp": 19.93357729043246,
        "humidity": 68.2115908342268,
        "rainfall": 1,
        "wind_speed": 5.670143805418788,
        "soil_moisture": 31.481891602586742,
        "ndvi": 0.3002600526593804,
        "soil_temp": 18.886681611856215,
        "soil_humidity": 64.45899249370777
    },
    {
        "date": "2024-10-06 07:53:09.251267",
        "air_temp": 29.529234302260097,
        "humidity": 82.5287264007558,
        "rainfall": 2,
        "wind_speed": 18.968503110829857,
        "soil_moisture": 19.695821959730083,
        "ndvi": 0.22003592291951446,
        "soil_temp": 21.38145626052816,
        "soil_humidity": 43.17549287946385
    },
    {
        "date": "2024-10-06 08:53:09.251267",
        "air_temp": 34.83619902698727,
        "humidity": 50.076418900315645,
        "rainfall": 2,
        "wind_speed": 16.8492821367115,
        "soil_moisture": 19.560917718561917,
        "ndvi": 0.4314300276566121,
        "soil_temp": 12.86075879507118,
        "soil_humidity": 44.72534359924553
    },
    {
        "date": "2024-10-06 09:53:09.251267",
        "air_temp": 16.983561991744317,
        "humidity": 86.72165193767208,
        "rainfall": 0,
        "wind_speed": 14.44747015265597,
        "soil_moisture": 23.45627648105378,
        "ndvi": 0.7421355822183693,
        "soil_temp": 18.523125111812362,
        "soil_humidity": 31.565290179354374
    },
    {
        "date": "2024-10-06 10:53:09.251267",
        "air_temp": 23.029886317225767,
        "humidity": 74.45438268741148,
        "rainfall": 0,
        "wind_speed": 1.5598773281881817,
        "soil_moisture": 28.78734468289099,
        "ndvi": 0.34339195265340594,
        "soil_temp": 22.21316347574302,
        "soil_humidity": 43.07515159061278
    },
    {
        "date": "2024-10-06 11:53:09.251267",
        "air_temp": 31.00141937398319,
        "humidity": 81.1636606340015,
        "rainfall": 1,
        "wind_speed": 2.122600386180389,
        "soil_moisture": 14.391191997669033,
        "ndvi": 0.3239078989578704,
        "soil_temp": 21.523716664694746,
        "soil_humidity": 65.53454731205056
    },
    {
        "date": "2024-10-06 12:53:09.251267",
        "air_temp": 19.080712692577976,
        "humidity": 67.80953463150058,
        "rainfall": 0,
        "wind_speed": 9.855626917299114,
        "soil_moisture": 15.66789441294857,
        "ndvi": 0.7818924711446242,
        "soil_temp": 22.044880268007294,
        "soil_humidity": 31.021896044864164
    },
    {
        "date": "2024-10-06 13:53:09.251267",
        "air_temp": 26.101698986045726,
        "humidity": 78.97583507120316,
        "rainfall": 0.5,
        "wind_speed": 3.2142669838197135,
        "soil_moisture": 35.78303748500653,
        "ndvi": 0.21500246771035528,
        "soil_temp": 23.189851373045798,
        "soil_humidity": 46.25309892186901
    },
    {
        "date": "2024-10-06 14:53:09.251267",
        "air_temp": 29.661425920593494,
        "humidity": 40.81001986697336,
        "rainfall": 0,
        "wind_speed": 19.56530927058948,
        "soil_moisture": 17.687647716672178,
        "ndvi": 0.6714692902362527,
        "soil_temp": 24.065231532392055,
        "soil_humidity": 31.255985192509645
    },
    {
        "date": "2024-10-06 15:53:09.251267",
        "air_temp": 27.31970900520358,
        "humidity": 80.91902123897677,
        "rainfall": 0,
        "wind_speed": 3.170404680548189,
        "soil_moisture": 21.405849939255326,
        "ndvi": 0.39282197344452224,
        "soil_temp": 16.08281732805304,
        "soil_humidity": 44.836937004362596
    },
    {
        "date": "2024-10-06 16:53:09.251267",
        "air_temp": 18.760494692308324,
        "humidity": 42.006942954268325,
        "rainfall": 1,
        "wind_speed": 9.74935670927948,
        "soil_moisture": 36.13815552850351,
        "ndvi": 0.2224927741217956,
        "soil_temp": 18.53153653784316,
        "soil_humidity": 36.90562563121165
    },
    {
        "date": "2024-10-06 17:53:09.251267",
        "air_temp": 22.107691373714786,
        "humidity": 84.49566881814312,
        "rainfall": 0.5,
        "wind_speed": 7.282421136830108,
        "soil_moisture": 29.894434881994677,
        "ndvi": 0.7506815241257503,
        "soil_temp": 21.859892890684165,
        "soil_humidity": 40.08873573073488
    },
    {
        "date": "2024-10-06 18:53:09.251267",
        "air_temp": 30.675835689707945,
        "humidity": 89.59813736193217,
        "rainfall": 0,
        "wind_speed": 12.787728429081959,
        "soil_moisture": 20.251356380250648,
        "ndvi": 0.2837853981257502,
        "soil_temp": 20.45709720918675,
        "soil_humidity": 65.10393492645676
    },
    {
        "date": "2024-10-06 19:53:09.251267",
        "air_temp": 26.084530282640333,
        "humidity": 54.70337310883889,
        "rainfall": 0.5,
        "wind_speed": 2.5807281777884983,
        "soil_moisture": 30.510986371738625,
        "ndvi": 0.5310675702354173,
        "soil_temp": 24.93280342396568,
        "soil_humidity": 47.441968824875644
    },
    {
        "date": "2024-10-06 20:53:09.251267",
        "air_temp": 15.104592270858255,
        "humidity": 50.51592781428968,
        "rainfall": 0.5,
        "wind_speed": 8.606328560847817,
        "soil_moisture": 10.590496461442411,
        "ndvi": 0.36443252445772867,
        "soil_temp": 10.205164766569663,
        "soil_humidity": 40.951919656076484
    },
    {
        "date": "2024-10-06 21:53:09.251267",
        "air_temp": 30.219815200561875,
        "humidity": 78.26816819290204,
        "rainfall": 0.5,
        "wind_speed": 5.494100656142873,
        "soil_moisture": 32.06927980034096,
        "ndvi": 0.23911568131081462,
        "soil_temp": 15.32699822973686,
        "soil_humidity": 53.57353511802191
    },
    {
        "date": "2024-10-06 22:53:09.251267",
        "air_temp": 15.706227098804781,
        "humidity": 52.651317046012664,
        "rainfall": 2,
        "wind_speed": 17.75503049343376,
        "soil_moisture": 24.610481215487184,
        "ndvi": 0.22995349139802745,
        "soil_temp": 14.215418894053037,
        "soil_humidity": 35.684278393303295
    },
    {
        "date": "2024-10-06 23:53:09.251267",
        "air_temp": 29.914675654624645,
        "humidity": 83.27811915861017,
        "rainfall": 2,
        "wind_speed": 0.8117142426939017,
        "soil_moisture": 34.395464418533834,
        "ndvi": 0.2693340193018033,
        "soil_temp": 13.93473775248302,
        "soil_humidity": 40.37566429683423
    },
    {
        "date": "2024-10-07 00:53:09.251267",
        "air_temp": 19.04961120330475,
        "humidity": 45.142129607873834,
        "rainfall": 2,
        "wind_speed": 18.274549880129186,
        "soil_moisture": 38.63912956019405,
        "ndvi": 0.24282020833362805,
        "soil_temp": 13.337946737483286,
        "soil_humidity": 51.84991870411554
    },
    {
        "date": "2024-10-07 01:53:09.251267",
        "air_temp": 34.161469602387605,
        "humidity": 46.29775981896791,
        "rainfall": 2,
        "wind_speed": 16.65988277106001,
        "soil_moisture": 37.77365648483705,
        "ndvi": 0.6563348693119538,
        "soil_temp": 20.636857612228436,
        "soil_humidity": 39.500921673922754
    },
    {
        "date": "2024-10-07 02:53:09.251267",
        "air_temp": 22.35881502604169,
        "humidity": 88.95757775503077,
        "rainfall": 1,
        "wind_speed": 5.951414328816309,
        "soil_moisture": 37.277951303660224,
        "ndvi": 0.49133972137005755,
        "soil_temp": 11.271392099129828,
        "soil_humidity": 57.16510949603493
    },
    {
        "date": "2024-10-07 03:53:09.251267",
        "air_temp": 21.538632326761583,
        "humidity": 73.69194491350024,
        "rainfall": 0,
        "wind_speed": 15.785813093542094,
        "soil_moisture": 25.365098678016615,
        "ndvi": 0.4057352548093879,
        "soil_temp": 21.124132509150073,
        "soil_humidity": 49.6596520903809
    },
    {
        "date": "2024-10-07 04:53:09.251267",
        "air_temp": 17.977761006648894,
        "humidity": 82.34436122144764,
        "rainfall": 2,
        "wind_speed": 0.6242712067749512,
        "soil_moisture": 20.80426342416814,
        "ndvi": 0.45102134607920435,
        "soil_temp": 20.401387630244102,
        "soil_humidity": 60.879562810187636
    },
    {
        "date": "2024-10-07 05:53:09.251267",
        "air_temp": 21.112084309804295,
        "humidity": 56.2164688675759,
        "rainfall": 1,
        "wind_speed": 19.33731100586787,
        "soil_moisture": 23.951059741920183,
        "ndvi": 0.7573680413435742,
        "soil_temp": 13.762061987513446,
        "soil_humidity": 67.92978142889874
    },
    {
        "date": "2024-10-07 06:53:09.251267",
        "air_temp": 32.53301523656401,
        "humidity": 73.82418747603022,
        "rainfall": 0,
        "wind_speed": 10.531898851795788,
        "soil_moisture": 30.696880099400822,
        "ndvi": 0.7464510876428885,
        "soil_temp": 13.792506747477418,
        "soil_humidity": 34.63219557389631
    },
    {
        "date": "2024-10-07 07:53:09.251267",
        "air_temp": 34.926686752544946,
        "humidity": 69.71209136542986,
        "rainfall": 2,
        "wind_speed": 6.065620521308905,
        "soil_moisture": 36.5199333061356,
        "ndvi": 0.6294058880735462,
        "soil_temp": 19.432908780167534,
        "soil_humidity": 43.38660039938179
    },
    {
        "date": "2024-10-07 08:53:09.251267",
        "air_temp": 22.366190610136968,
        "humidity": 70.15750600614734,
        "rainfall": 0,
        "wind_speed": 7.2516642518935415,
        "soil_moisture": 16.209589090003586,
        "ndvi": 0.7308094264530127,
        "soil_temp": 21.43256658225517,
        "soil_humidity": 42.781962515136264
    },
    {
        "date": "2024-10-07 09:53:09.251267",
        "air_temp": 23.97221261909085,
        "humidity": 74.12662297688607,
        "rainfall": 2,
        "wind_speed": 1.3281070692099473,
        "soil_moisture": 37.54579475385109,
        "ndvi": 0.3917888165525749,
        "soil_temp": 23.147284196310483,
        "soil_humidity": 36.04731677349017
    },
    {
        "date": "2024-10-07 10:53:09.251267",
        "air_temp": 29.441418764054742,
        "humidity": 68.76795865645494,
        "rainfall": 1,
        "wind_speed": 19.786990280840783,
        "soil_moisture": 34.45540695909668,
        "ndvi": 0.42866559560496975,
        "soil_temp": 22.782093457817137,
        "soil_humidity": 62.412125493338834
    },
    {
        "date": "2024-10-07 11:53:09.251267",
        "air_temp": 32.723915609301486,
        "humidity": 61.4531184651333,
        "rainfall": 0.5,
        "wind_speed": 0.7858389030138491,
        "soil_moisture": 39.36568045534704,
        "ndvi": 0.5682273091125369,
        "soil_temp": 24.473244912457798,
        "soil_humidity": 60.3951481615516
    },
    {
        "date": "2024-10-07 12:53:09.251267",
        "air_temp": 26.860886683735682,
        "humidity": 53.79613471902734,
        "rainfall": 0.5,
        "wind_speed": 13.098142462957181,
        "soil_moisture": 36.407553198450614,
        "ndvi": 0.5741755683783586,
        "soil_temp": 18.720773430047668,
        "soil_humidity": 49.377018833375956
    },
    {
        "date": "2024-10-07 13:53:09.251267",
        "air_temp": 22.830513963556587,
        "humidity": 78.4290665456601,
        "rainfall": 0,
        "wind_speed": 8.854718554129166,
        "soil_moisture": 18.3981198907572,
        "ndvi": 0.48901990280740465,
        "soil_temp": 23.629833420105392,
        "soil_humidity": 51.510831111784384
    },
    {
        "date": "2024-10-07 14:53:09.251267",
        "air_temp": 23.252436816972477,
        "humidity": 51.313621500159314,
        "rainfall": 1,
        "wind_speed": 18.978324363774302,
        "soil_moisture": 13.178677340321432,
        "ndvi": 0.440394709062772,
        "soil_temp": 22.127699098906163,
        "soil_humidity": 32.38176552009985
    },
    {
        "date": "2024-10-07 15:53:09.251267",
        "air_temp": 28.912362929936137,
        "humidity": 74.61771530772072,
        "rainfall": 0.5,
        "wind_speed": 4.919994806135904,
        "soil_moisture": 13.367232159747658,
        "ndvi": 0.7917081869652172,
        "soil_temp": 23.420014364710205,
        "soil_humidity": 52.220294093081826
    },
    {
        "date": "2024-10-07 16:53:09.251267",
        "air_temp": 15.064365272085574,
        "humidity": 51.66664113246633,
        "rainfall": 2,
        "wind_speed": 10.641736478760226,
        "soil_moisture": 18.73669955734828,
        "ndvi": 0.6926741756706347,
        "soil_temp": 10.1743990192805,
        "soil_humidity": 61.65366096078546
    },
    {
        "date": "2024-10-07 17:53:09.251267",
        "air_temp": 27.391786749196815,
        "humidity": 71.2665084977227,
        "rainfall": 0.5,
        "wind_speed": 9.64712417165506,
        "soil_moisture": 28.42551171953618,
        "ndvi": 0.4609881187328272,
        "soil_temp": 24.488544877987167,
        "soil_humidity": 52.95310294335429
    },
    {
        "date": "2024-10-07 18:53:09.251267",
        "air_temp": 22.109860208827147,
        "humidity": 77.35391335645144,
        "rainfall": 0,
        "wind_speed": 14.057328881002027,
        "soil_moisture": 34.129609782915175,
        "ndvi": 0.6363754863437412,
        "soil_temp": 17.102952442697173,
        "soil_humidity": 40.61225480481676
    },
    {
        "date": "2024-10-07 19:53:09.251267",
        "air_temp": 30.883946625541686,
        "humidity": 50.93569444532696,
        "rainfall": 0,
        "wind_speed": 1.3602046390484102,
        "soil_moisture": 36.97750920944694,
        "ndvi": 0.3617370159514757,
        "soil_temp": 21.972744843622642,
        "soil_humidity": 56.701930893373486
    },
    {
        "date": "2024-10-07 20:53:09.251267",
        "air_temp": 16.85981279646264,
        "humidity": 42.997165043065415,
        "rainfall": 1,
        "wind_speed": 3.875782600467097,
        "soil_moisture": 28.712601531076984,
        "ndvi": 0.26010159574239794,
        "soil_temp": 11.908061790066713,
        "soil_humidity": 44.45953719860991
    },
    {
        "date": "2024-10-07 21:53:09.251267",
        "air_temp": 26.76404533896781,
        "humidity": 46.548687149708705,
        "rainfall": 0,
        "wind_speed": 4.291296401856997,
        "soil_moisture": 10.168939382441613,
        "ndvi": 0.4114586153316444,
        "soil_temp": 15.939116437209611,
        "soil_humidity": 51.79493137142941
    },
    {
        "date": "2024-10-07 22:53:09.251267",
        "air_temp": 24.619457797722475,
        "humidity": 70.29984836355128,
        "rainfall": 0.5,
        "wind_speed": 14.763015096833794,
        "soil_moisture": 15.589503295556005,
        "ndvi": 0.44178368007403657,
        "soil_temp": 14.071909945314864,
        "soil_humidity": 49.50261453115699
    },
    {
        "date": "2024-10-07 23:53:09.251267",
        "air_temp": 27.846510518104548,
        "humidity": 82.47232031128678,
        "rainfall": 0.5,
        "wind_speed": 3.7827739265348015,
        "soil_moisture": 12.991786115601037,
        "ndvi": 0.2099222747364025,
        "soil_temp": 14.374881557514055,
        "soil_humidity": 45.247000038607204
    },
    {
        "date": "2024-10-08 00:53:09.251267",
        "air_temp": 16.297071889659367,
        "humidity": 42.24984106177122,
        "rainfall": 0.5,
        "wind_speed": 11.469835277819534,
        "soil_moisture": 20.8997342670703,
        "ndvi": 0.6888752009815378,
        "soil_temp": 11.848834299263226,
        "soil_humidity": 58.755119215765404
    },
    {
        "date": "2024-10-08 01:53:09.251267",
        "air_temp": 26.599675748562625,
        "humidity": 76.70756295208231,
        "rainfall": 0.5,
        "wind_speed": 4.304876410019074,
        "soil_moisture": 25.040529809012376,
        "ndvi": 0.41551604143350895,
        "soil_temp": 22.086457488145104,
        "soil_humidity": 65.35504447943526
    },
    {
        "date": "2024-10-08 02:53:09.251267",
        "air_temp": 26.229691759190473,
        "humidity": 57.065932297502606,
        "rainfall": 1,
        "wind_speed": 17.232499001679567,
        "soil_moisture": 11.301915357356826,
        "ndvi": 0.5482508994680884,
        "soil_temp": 10.138634718716173,
        "soil_humidity": 30.208500739076996
    },
    {
        "date": "2024-10-08 03:53:09.251267",
        "air_temp": 26.213201888323418,
        "humidity": 63.92641849597612,
        "rainfall": 2,
        "wind_speed": 18.84370908979277,
        "soil_moisture": 20.45433927386966,
        "ndvi": 0.2443779123625736,
        "soil_temp": 14.687581854778038,
        "soil_humidity": 67.3446607692147
    },
    {
        "date": "2024-10-08 04:53:09.251267",
        "air_temp": 27.069753331929096,
        "humidity": 86.44297749227829,
        "rainfall": 2,
        "wind_speed": 10.369859498844809,
        "soil_moisture": 15.375390238759678,
        "ndvi": 0.30499809725035043,
        "soil_temp": 12.616158148246225,
        "soil_humidity": 50.460605443329
    },
    {
        "date": "2024-10-08 05:53:09.251267",
        "air_temp": 28.529358744527244,
        "humidity": 56.59857523679227,
        "rainfall": 1,
        "wind_speed": 7.046177684183565,
        "soil_moisture": 29.412104987766217,
        "ndvi": 0.733816235196536,
        "soil_temp": 18.835584109125108,
        "soil_humidity": 58.02927521453061
    },
    {
        "date": "2024-10-08 06:53:09.251267",
        "air_temp": 31.099779993615396,
        "humidity": 63.2668466969743,
        "rainfall": 0,
        "wind_speed": 4.681155500349982,
        "soil_moisture": 23.9281710797302,
        "ndvi": 0.5652125167296805,
        "soil_temp": 13.813786039218158,
        "soil_humidity": 64.22498264703509
    },
    {
        "date": "2024-10-08 07:53:09.251267",
        "air_temp": 20.396414402564286,
        "humidity": 40.68412073803405,
        "rainfall": 2,
        "wind_speed": 0.9956200304608753,
        "soil_moisture": 24.859529408017263,
        "ndvi": 0.7701704301022914,
        "soil_temp": 13.884976817921494,
        "soil_humidity": 48.6841476925708
    },
    {
        "date": "2024-10-08 08:53:09.251267",
        "air_temp": 31.500988203601242,
        "humidity": 44.079936000241915,
        "rainfall": 0,
        "wind_speed": 5.370001523949908,
        "soil_moisture": 22.83075364100383,
        "ndvi": 0.7992500206347903,
        "soil_temp": 17.325072159929782,
        "soil_humidity": 63.39896293459561
    },
    {
        "date": "2024-10-08 09:53:09.251267",
        "air_temp": 24.96511364526907,
        "humidity": 52.9295795567392,
        "rainfall": 2,
        "wind_speed": 13.084715598906358,
        "soil_moisture": 38.68059131459701,
        "ndvi": 0.42311606919985667,
        "soil_temp": 19.790335590113884,
        "soil_humidity": 37.104792778208086
    },
    {
        "date": "2024-10-08 10:53:09.251267",
        "air_temp": 16.541165582686624,
        "humidity": 41.392612041053724,
        "rainfall": 0,
        "wind_speed": 10.672338626184217,
        "soil_moisture": 16.442053753646896,
        "ndvi": 0.37928183844996477,
        "soil_temp": 11.819570568477454,
        "soil_humidity": 36.36670137919258
    },
    {
        "date": "2024-10-08 11:53:09.251267",
        "air_temp": 16.171018584879583,
        "humidity": 71.56876630241254,
        "rainfall": 1,
        "wind_speed": 18.880825565618547,
        "soil_moisture": 25.499927795316232,
        "ndvi": 0.7150136330954582,
        "soil_temp": 12.632671786982442,
        "soil_humidity": 56.29885475424614
    },
    {
        "date": "2024-10-08 12:53:09.251267",
        "air_temp": 21.684766373921214,
        "humidity": 61.319917840081125,
        "rainfall": 0.5,
        "wind_speed": 7.925545249834012,
        "soil_moisture": 32.08052105096008,
        "ndvi": 0.7991668562224725,
        "soil_temp": 13.707621168917765,
        "soil_humidity": 64.80572022206948
    },
    {
        "date": "2024-10-08 13:53:09.251267",
        "air_temp": 30.69793954222935,
        "humidity": 67.4072601364011,
        "rainfall": 0,
        "wind_speed": 13.87726684656789,
        "soil_moisture": 26.705332394036876,
        "ndvi": 0.5777847405394745,
        "soil_temp": 24.606223211769407,
        "soil_humidity": 65.69704710144157
    },
    {
        "date": "2024-10-08 14:53:09.251267",
        "air_temp": 29.153618694818313,
        "humidity": 48.732359294816476,
        "rainfall": 0.5,
        "wind_speed": 9.786454949502428,
        "soil_moisture": 37.87062657150403,
        "ndvi": 0.6770844335069135,
        "soil_temp": 15.957287499143806,
        "soil_humidity": 55.74961833508986
    },
    {
        "date": "2024-10-08 15:53:09.251267",
        "air_temp": 30.77229929344813,
        "humidity": 54.796601471830805,
        "rainfall": 0,
        "wind_speed": 10.251524318484233,
        "soil_moisture": 21.339050669238524,
        "ndvi": 0.6690475152891648,
        "soil_temp": 18.984851359465395,
        "soil_humidity": 51.0195692083727
    },
    {
        "date": "2024-10-08 16:53:09.251267",
        "air_temp": 25.345381128832187,
        "humidity": 73.19122374263625,
        "rainfall": 0,
        "wind_speed": 5.35548629208235,
        "soil_moisture": 23.91027417319534,
        "ndvi": 0.41320382493172825,
        "soil_temp": 13.623142190739667,
        "soil_humidity": 43.25572350786065
    },
    {
        "date": "2024-10-08 17:53:09.251267",
        "air_temp": 23.80397995910981,
        "humidity": 88.26513618151999,
        "rainfall": 0,
        "wind_speed": 9.099015536839994,
        "soil_moisture": 12.655669456441853,
        "ndvi": 0.5445622281542524,
        "soil_temp": 17.35689287736232,
        "soil_humidity": 69.20001662397326
    },
    {
        "date": "2024-10-08 18:53:09.251267",
        "air_temp": 17.94905053412568,
        "humidity": 42.52083868011046,
        "rainfall": 0,
        "wind_speed": 9.415699940254145,
        "soil_moisture": 28.269973331557853,
        "ndvi": 0.6998341647585282,
        "soil_temp": 11.111787615789371,
        "soil_humidity": 42.787827466650015
    },
    {
        "date": "2024-10-08 19:53:09.251267",
        "air_temp": 21.56385507218623,
        "humidity": 84.5192160427633,
        "rainfall": 0,
        "wind_speed": 14.132850434978678,
        "soil_moisture": 18.64635905344318,
        "ndvi": 0.6128683257566334,
        "soil_temp": 10.521132188834905,
        "soil_humidity": 59.76744428926373
    },
    {
        "date": "2024-10-08 20:53:09.251267",
        "air_temp": 23.680387323477504,
        "humidity": 68.84406006556043,
        "rainfall": 0.5,
        "wind_speed": 18.81279998372228,
        "soil_moisture": 14.809271185467065,
        "ndvi": 0.21269828838922297,
        "soil_temp": 17.0310029023219,
        "soil_humidity": 51.27927199620003
    },
    {
        "date": "2024-10-08 21:53:09.251267",
        "air_temp": 16.772008593592346,
        "humidity": 68.19597833778118,
        "rainfall": 0,
        "wind_speed": 6.586425549650086,
        "soil_moisture": 26.33593878713524,
        "ndvi": 0.4020234013385201,
        "soil_temp": 15.97988931277618,
        "soil_humidity": 54.34049370101387
    },
    {
        "date": "2024-10-08 22:53:09.251267",
        "air_temp": 19.412239054224223,
        "humidity": 65.01392676038802,
        "rainfall": 0.5,
        "wind_speed": 0.8440093716259689,
        "soil_moisture": 25.96930790139732,
        "ndvi": 0.2866854179567632,
        "soil_temp": 12.053204795377415,
        "soil_humidity": 45.73922202354505
    },
    {
        "date": "2024-10-08 23:53:09.251267",
        "air_temp": 26.964505881274775,
        "humidity": 43.46970429520917,
        "rainfall": 1,
        "wind_speed": 9.925902510012794,
        "soil_moisture": 12.276149236721427,
        "ndvi": 0.3397833272468235,
        "soil_temp": 20.314021433811007,
        "soil_humidity": 68.59292501888991
    },
    {
        "date": "2024-10-09 00:53:09.251267",
        "air_temp": 29.713262284783795,
        "humidity": 44.493842586808036,
        "rainfall": 2,
        "wind_speed": 10.889226642983372,
        "soil_moisture": 30.946463604834182,
        "ndvi": 0.7432936864947954,
        "soil_temp": 24.893552363488965,
        "soil_humidity": 52.86729304397085
    },
    {
        "date": "2024-10-09 01:53:09.251267",
        "air_temp": 34.966950227858995,
        "humidity": 70.0456002829543,
        "rainfall": 0,
        "wind_speed": 3.368811022638305,
        "soil_moisture": 28.713165719525588,
        "ndvi": 0.2221359733464609,
        "soil_temp": 15.934702317950688,
        "soil_humidity": 67.5115052183516
    },
    {
        "date": "2024-10-09 02:53:09.251267",
        "air_temp": 33.66226662687116,
        "humidity": 57.047928571777916,
        "rainfall": 2,
        "wind_speed": 15.209292125521333,
        "soil_moisture": 15.670518482394916,
        "ndvi": 0.33188042717715427,
        "soil_temp": 19.36837953876092,
        "soil_humidity": 30.303656646956565
    },
    {
        "date": "2024-10-09 03:53:09.251267",
        "air_temp": 27.85130399070383,
        "humidity": 85.86340332888403,
        "rainfall": 0.5,
        "wind_speed": 19.698582168829834,
        "soil_moisture": 24.083045864621315,
        "ndvi": 0.38574161134293894,
        "soil_temp": 24.444639135467284,
        "soil_humidity": 52.90682332764484
    },
    {
        "date": "2024-10-09 04:53:09.251267",
        "air_temp": 23.42496106588144,
        "humidity": 60.32952686797361,
        "rainfall": 1,
        "wind_speed": 18.915494340169218,
        "soil_moisture": 31.6096874943568,
        "ndvi": 0.570130443179837,
        "soil_temp": 22.47302458670313,
        "soil_humidity": 58.68747558901086
    },
    {
        "date": "2024-10-09 05:53:09.251267",
        "air_temp": 27.723547294164653,
        "humidity": 47.164072935132054,
        "rainfall": 1,
        "wind_speed": 3.9421207764730437,
        "soil_moisture": 25.883072216238297,
        "ndvi": 0.5204610140515193,
        "soil_temp": 16.953283349540285,
        "soil_humidity": 59.80320684648872
    },
    {
        "date": "2024-10-09 06:53:09.251267",
        "air_temp": 30.71303236160464,
        "humidity": 75.7359416751018,
        "rainfall": 1,
        "wind_speed": 17.507264798015214,
        "soil_moisture": 21.864672285507105,
        "ndvi": 0.33096168971203166,
        "soil_temp": 21.06234226689879,
        "soil_humidity": 66.19907527126725
    },
    {
        "date": "2024-10-09 07:53:09.251267",
        "air_temp": 17.366723818467747,
        "humidity": 54.667671072050496,
        "rainfall": 2,
        "wind_speed": 14.089872728233694,
        "soil_moisture": 19.670153742428308,
        "ndvi": 0.2985446403338927,
        "soil_temp": 11.92202560375786,
        "soil_humidity": 30.17606436015145
    },
    {
        "date": "2024-10-09 08:53:09.251267",
        "air_temp": 23.198097793925765,
        "humidity": 66.27351536779466,
        "rainfall": 0.5,
        "wind_speed": 9.076931276138232,
        "soil_moisture": 28.31181599932161,
        "ndvi": 0.3420788207576713,
        "soil_temp": 17.681557831956987,
        "soil_humidity": 30.01320637037906
    },
    {
        "date": "2024-10-09 09:53:09.251267",
        "air_temp": 31.796045712712345,
        "humidity": 74.8916392752585,
        "rainfall": 0,
        "wind_speed": 10.812219886494582,
        "soil_moisture": 38.17326094930722,
        "ndvi": 0.5015177760209935,
        "soil_temp": 14.18590415769905,
        "soil_humidity": 48.89053843180861
    },
    {
        "date": "2024-10-09 10:53:09.251267",
        "air_temp": 22.67665903866088,
        "humidity": 85.01320556010353,
        "rainfall": 0,
        "wind_speed": 16.99200349964916,
        "soil_moisture": 30.1523452143245,
        "ndvi": 0.3627066714267221,
        "soil_temp": 12.417455318442416,
        "soil_humidity": 31.171748200214825
    },
    {
        "date": "2024-10-09 11:53:09.251267",
        "air_temp": 26.437445424543732,
        "humidity": 79.6095597400838,
        "rainfall": 0.5,
        "wind_speed": 9.602698792767463,
        "soil_moisture": 12.242377109089464,
        "ndvi": 0.7685615009646334,
        "soil_temp": 16.394213593818023,
        "soil_humidity": 68.98130214649498
    },
    {
        "date": "2024-10-09 12:53:09.251267",
        "air_temp": 26.755387217243964,
        "humidity": 73.8180533936362,
        "rainfall": 2,
        "wind_speed": 7.464147382102537,
        "soil_moisture": 23.820549204464015,
        "ndvi": 0.4783191328573086,
        "soil_temp": 11.965218316504217,
        "soil_humidity": 38.88260698165568
    },
    {
        "date": "2024-10-09 13:53:09.251267",
        "air_temp": 18.689525062672786,
        "humidity": 73.98165911975718,
        "rainfall": 0,
        "wind_speed": 9.864949860519715,
        "soil_moisture": 36.72472394531883,
        "ndvi": 0.23527760992678226,
        "soil_temp": 20.484776622617282,
        "soil_humidity": 48.90658917459379
    },
    {
        "date": "2024-10-09 14:53:09.251267",
        "air_temp": 22.244708833045767,
        "humidity": 87.29623220160433,
        "rainfall": 0.5,
        "wind_speed": 11.582596080700982,
        "soil_moisture": 12.833872774032606,
        "ndvi": 0.63755984350198,
        "soil_temp": 16.843600660800032,
        "soil_humidity": 32.445505200488945
    },
    {
        "date": "2024-10-09 15:53:09.251267",
        "air_temp": 21.690225774838645,
        "humidity": 54.7912048748268,
        "rainfall": 0,
        "wind_speed": 3.781553075566977,
        "soil_moisture": 39.6662713641263,
        "ndvi": 0.3973071729253938,
        "soil_temp": 17.967169488469956,
        "soil_humidity": 50.88193664685034
    },
    {
        "date": "2024-10-09 16:53:09.251267",
        "air_temp": 15.523934170641295,
        "humidity": 40.05560470980937,
        "rainfall": 1,
        "wind_speed": 18.018154605339813,
        "soil_moisture": 38.472081809362635,
        "ndvi": 0.5920933592349487,
        "soil_temp": 12.195639080022355,
        "soil_humidity": 45.19824500589634
    },
    {
        "date": "2024-10-09 17:53:09.251267",
        "air_temp": 15.483835277827104,
        "humidity": 53.59772796990993,
        "rainfall": 0,
        "wind_speed": 13.483738976592276,
        "soil_moisture": 12.348234421896276,
        "ndvi": 0.36775662231393924,
        "soil_temp": 12.096574916402417,
        "soil_humidity": 31.78640115508895
    },
    {
        "date": "2024-10-09 18:53:09.251267",
        "air_temp": 31.63394087351547,
        "humidity": 50.8945689813372,
        "rainfall": 2,
        "wind_speed": 12.665378346749778,
        "soil_moisture": 38.002399613486645,
        "ndvi": 0.5424462450399921,
        "soil_temp": 18.75123152890186,
        "soil_humidity": 43.404970667848985
    },
    {
        "date": "2024-10-09 19:53:09.251267",
        "air_temp": 20.46141619945625,
        "humidity": 73.0917292556999,
        "rainfall": 0.5,
        "wind_speed": 1.9899245236127627,
        "soil_moisture": 20.89397770778387,
        "ndvi": 0.6632915028844788,
        "soil_temp": 24.775146217441694,
        "soil_humidity": 45.657440808914224
    },
    {
        "date": "2024-10-09 20:53:09.251267",
        "air_temp": 25.36157532477631,
        "humidity": 71.68557998827646,
        "rainfall": 2,
        "wind_speed": 12.083478763451836,
        "soil_moisture": 35.34127501227587,
        "ndvi": 0.7568052450647325,
        "soil_temp": 11.443758748565884,
        "soil_humidity": 69.5968596509976
    },
    {
        "date": "2024-10-09 21:53:09.251267",
        "air_temp": 20.97451147177546,
        "humidity": 69.67306913523619,
        "rainfall": 0.5,
        "wind_speed": 2.4192132447698467,
        "soil_moisture": 22.551500131398146,
        "ndvi": 0.3566762866598463,
        "soil_temp": 14.285971428486718,
        "soil_humidity": 38.315434876775015
    },
    {
        "date": "2024-10-09 22:53:09.251267",
        "air_temp": 33.813584902306815,
        "humidity": 40.812172160472684,
        "rainfall": 1,
        "wind_speed": 1.573953503907981,
        "soil_moisture": 23.225653381075475,
        "ndvi": 0.4009141705993887,
        "soil_temp": 23.041886748068315,
        "soil_humidity": 51.79118461480399
    },
    {
        "date": "2024-10-09 23:53:09.251267",
        "air_temp": 20.185935177315475,
        "humidity": 76.43890272253238,
        "rainfall": 0.5,
        "wind_speed": 10.546217912847865,
        "soil_moisture": 29.027292729326277,
        "ndvi": 0.4785598521715639,
        "soil_temp": 11.084080183828554,
        "soil_humidity": 69.49262196794515
    },
    {
        "date": "2024-10-10 00:53:09.251267",
        "air_temp": 23.593136254778702,
        "humidity": 56.17652062427457,
        "rainfall": 0,
        "wind_speed": 8.055710680929899,
        "soil_moisture": 33.2343225782795,
        "ndvi": 0.7563049026415933,
        "soil_temp": 23.13828582050427,
        "soil_humidity": 61.680768317605406
    },
    {
        "date": "2024-10-10 01:53:09.251267",
        "air_temp": 32.45460500969204,
        "humidity": 73.25279945033157,
        "rainfall": 0,
        "wind_speed": 11.776205403124465,
        "soil_moisture": 11.01367452844746,
        "ndvi": 0.7408321140863943,
        "soil_temp": 17.864289750621268,
        "soil_humidity": 69.73470003528357
    },
    {
        "date": "2024-10-10 02:53:09.251267",
        "air_temp": 31.838671335836175,
        "humidity": 67.82892142510767,
        "rainfall": 1,
        "wind_speed": 1.7844433611447719,
        "soil_moisture": 27.79467986581403,
        "ndvi": 0.25392696806038284,
        "soil_temp": 16.124001608896386,
        "soil_humidity": 68.73193258640677
    },
    {
        "date": "2024-10-10 03:53:09.251267",
        "air_temp": 18.722028356925545,
        "humidity": 57.13654627139536,
        "rainfall": 0.5,
        "wind_speed": 8.619661256945168,
        "soil_moisture": 15.60198982292113,
        "ndvi": 0.27915789545228653,
        "soil_temp": 14.645208465271839,
        "soil_humidity": 49.581581683766174
    },
    {
        "date": "2024-10-10 04:53:09.251267",
        "air_temp": 31.05286619591421,
        "humidity": 46.72881335520539,
        "rainfall": 1,
        "wind_speed": 3.4957343009031594,
        "soil_moisture": 20.37848553589609,
        "ndvi": 0.497305506398383,
        "soil_temp": 14.456757371917629,
        "soil_humidity": 57.855033994822634
    },
    {
        "date": "2024-10-10 05:53:09.251267",
        "air_temp": 24.163737734757852,
        "humidity": 44.720944883024046,
        "rainfall": 0,
        "wind_speed": 19.74200171848233,
        "soil_moisture": 20.439059984656495,
        "ndvi": 0.26397156368325414,
        "soil_temp": 14.587038736742182,
        "soil_humidity": 34.41772507764605
    },
    {
        "date": "2024-10-10 06:53:09.251267",
        "air_temp": 24.659377441707104,
        "humidity": 81.57591665561118,
        "rainfall": 2,
        "wind_speed": 11.223004248703488,
        "soil_moisture": 24.07181020663461,
        "ndvi": 0.4073485240092185,
        "soil_temp": 23.272046214402934,
        "soil_humidity": 47.64595300187207
    },
    {
        "date": "2024-10-10 07:53:09.251267",
        "air_temp": 17.669599448318877,
        "humidity": 85.9198771040779,
        "rainfall": 1,
        "wind_speed": 13.748737218817347,
        "soil_moisture": 20.024092356285202,
        "ndvi": 0.2890312269304866,
        "soil_temp": 20.856928452621784,
        "soil_humidity": 62.3607416906679
    },
    {
        "date": "2024-10-10 08:53:09.251267",
        "air_temp": 16.612030275142494,
        "humidity": 72.48940233821497,
        "rainfall": 2,
        "wind_speed": 6.508365613652156,
        "soil_moisture": 13.220833553520032,
        "ndvi": 0.5816242130791316,
        "soil_temp": 20.04584511746726,
        "soil_humidity": 50.213412839085734
    },
    {
        "date": "2024-10-10 09:53:09.251267",
        "air_temp": 29.558786139475302,
        "humidity": 45.17170574302417,
        "rainfall": 2,
        "wind_speed": 1.894778295586883,
        "soil_moisture": 14.368862155763544,
        "ndvi": 0.7696972590434972,
        "soil_temp": 21.981237234280012,
        "soil_humidity": 58.10796131268928
    },
    {
        "date": "2024-10-10 10:53:09.251267",
        "air_temp": 24.929223047105996,
        "humidity": 60.101360844412035,
        "rainfall": 0.5,
        "wind_speed": 19.517417255670733,
        "soil_moisture": 11.00313956683293,
        "ndvi": 0.7980402621766289,
        "soil_temp": 13.856719945062174,
        "soil_humidity": 59.83005319937108
    },
    {
        "date": "2024-10-10 11:53:09.251267",
        "air_temp": 23.737014052303593,
        "humidity": 76.44696000525786,
        "rainfall": 1,
        "wind_speed": 5.7251807076636645,
        "soil_moisture": 24.10747255986263,
        "ndvi": 0.42514467949814894,
        "soil_temp": 19.270642181226336,
        "soil_humidity": 40.13876564971575
    },
    {
        "date": "2024-10-10 12:53:09.251267",
        "air_temp": 29.590164573047936,
        "humidity": 78.99104420050341,
        "rainfall": 2,
        "wind_speed": 8.476163703471485,
        "soil_moisture": 38.63343084558966,
        "ndvi": 0.5594075822687674,
        "soil_temp": 16.900993584369264,
        "soil_humidity": 65.02797491770262
    },
    {
        "date": "2024-10-10 13:53:09.251267",
        "air_temp": 30.310257979822197,
        "humidity": 45.91093111455443,
        "rainfall": 2,
        "wind_speed": 12.9972784340515,
        "soil_moisture": 13.470256709443497,
        "ndvi": 0.7137605921144488,
        "soil_temp": 21.7366245280029,
        "soil_humidity": 48.39289723636716
    },
    {
        "date": "2024-10-10 14:53:09.251267",
        "air_temp": 18.17816335338707,
        "humidity": 40.00153594226912,
        "rainfall": 0.5,
        "wind_speed": 0.4939459537222257,
        "soil_moisture": 38.38793479009246,
        "ndvi": 0.27047724367783044,
        "soil_temp": 14.638841657099283,
        "soil_humidity": 38.40740414486175
    },
    {
        "date": "2024-10-10 15:53:09.251267",
        "air_temp": 27.204502989552825,
        "humidity": 75.60685229403025,
        "rainfall": 0.5,
        "wind_speed": 16.205182610345737,
        "soil_moisture": 32.00084544196281,
        "ndvi": 0.613738646631179,
        "soil_temp": 19.647679253048437,
        "soil_humidity": 64.61663527153775
    },
    {
        "date": "2024-10-10 16:53:09.251267",
        "air_temp": 17.70708164555371,
        "humidity": 57.829808799310285,
        "rainfall": 0,
        "wind_speed": 10.731563800572406,
        "soil_moisture": 29.124964088024896,
        "ndvi": 0.44530619703504104,
        "soil_temp": 23.233186177259704,
        "soil_humidity": 46.71275224425015
    },
    {
        "date": "2024-10-10 17:53:09.251267",
        "air_temp": 30.027501720580915,
        "humidity": 52.72406658091301,
        "rainfall": 2,
        "wind_speed": 5.564343741376443,
        "soil_moisture": 29.35833265970689,
        "ndvi": 0.32368769616809845,
        "soil_temp": 16.032257142923932,
        "soil_humidity": 60.012497535188785
    },
    {
        "date": "2024-10-10 18:53:09.251267",
        "air_temp": 28.139103125342793,
        "humidity": 40.644873489476275,
        "rainfall": 0,
        "wind_speed": 1.232159705849385,
        "soil_moisture": 34.801829451888466,
        "ndvi": 0.44165217124846523,
        "soil_temp": 10.999331310511922,
        "soil_humidity": 51.92132288886371
    },
    {
        "date": "2024-10-10 19:53:09.251267",
        "air_temp": 34.13229242166916,
        "humidity": 67.01497033807499,
        "rainfall": 2,
        "wind_speed": 3.2499683094888288,
        "soil_moisture": 13.207595931394641,
        "ndvi": 0.24557617217895014,
        "soil_temp": 17.74076233512689,
        "soil_humidity": 45.274870089380016
    },
    {
        "date": "2024-10-10 20:53:09.251267",
        "air_temp": 16.379160327128425,
        "humidity": 82.55753677129908,
        "rainfall": 0,
        "wind_speed": 17.900677177068737,
        "soil_moisture": 25.142629706966254,
        "ndvi": 0.6172442891535835,
        "soil_temp": 15.189587317105389,
        "soil_humidity": 62.39505660154278
    },
    {
        "date": "2024-10-10 21:53:09.251267",
        "air_temp": 16.141094423025088,
        "humidity": 87.8815823136294,
        "rainfall": 0.5,
        "wind_speed": 13.72048447871782,
        "soil_moisture": 30.912734147822757,
        "ndvi": 0.35164560124907185,
        "soil_temp": 20.083380708207624,
        "soil_humidity": 53.37879726578452
    },
    {
        "date": "2024-10-10 22:53:09.251267",
        "air_temp": 20.643741493864002,
        "humidity": 68.28724753066446,
        "rainfall": 1,
        "wind_speed": 4.998034432737796,
        "soil_moisture": 27.60461335220691,
        "ndvi": 0.38684338702692683,
        "soil_temp": 24.26549387122909,
        "soil_humidity": 45.62248409708127
    },
    {
        "date": "2024-10-10 23:53:09.251267",
        "air_temp": 20.23411367471812,
        "humidity": 65.7230663869947,
        "rainfall": 0.5,
        "wind_speed": 1.3120152989005907,
        "soil_moisture": 34.98337213069884,
        "ndvi": 0.27264877894487766,
        "soil_temp": 17.284124769952705,
        "soil_humidity": 34.383914813405084
    },
    {
        "date": "2024-10-11 00:53:09.251267",
        "air_temp": 19.939575981439997,
        "humidity": 44.252819274462055,
        "rainfall": 0,
        "wind_speed": 13.812306247732726,
        "soil_moisture": 12.168151270340545,
        "ndvi": 0.718795468333935,
        "soil_temp": 16.99285345233301,
        "soil_humidity": 65.81781255066832
    },
    {
        "date": "2024-10-11 01:53:09.251267",
        "air_temp": 33.12509161042073,
        "humidity": 67.44364007712998,
        "rainfall": 0,
        "wind_speed": 11.448154519355302,
        "soil_moisture": 30.72103118497498,
        "ndvi": 0.49606656756964324,
        "soil_temp": 19.818772002871903,
        "soil_humidity": 31.418783413666663
    },
    {
        "date": "2024-10-11 02:53:09.251267",
        "air_temp": 19.990923996990123,
        "humidity": 58.99895394826973,
        "rainfall": 1,
        "wind_speed": 8.090390489164701,
        "soil_moisture": 26.02083191154153,
        "ndvi": 0.5432881266718402,
        "soil_temp": 11.552462553903515,
        "soil_humidity": 64.52749393472226
    },
    {
        "date": "2024-10-11 03:53:09.251267",
        "air_temp": 20.43899452257262,
        "humidity": 70.35676404417885,
        "rainfall": 2,
        "wind_speed": 7.272228983801277,
        "soil_moisture": 21.374825919398305,
        "ndvi": 0.5416596098569129,
        "soil_temp": 13.80259283387522,
        "soil_humidity": 44.39846099532622
    },
    {
        "date": "2024-10-11 04:53:09.251267",
        "air_temp": 30.187965248359276,
        "humidity": 59.43631739382734,
        "rainfall": 0,
        "wind_speed": 4.336285518582166,
        "soil_moisture": 20.208249467020927,
        "ndvi": 0.4462958687248051,
        "soil_temp": 23.78468988250598,
        "soil_humidity": 55.57355054005623
    },
    {
        "date": "2024-10-11 05:53:09.251267",
        "air_temp": 23.9947968490108,
        "humidity": 52.01495617251834,
        "rainfall": 0,
        "wind_speed": 13.392225130750353,
        "soil_moisture": 28.624978983225787,
        "ndvi": 0.3155179492628837,
        "soil_temp": 20.049139200456494,
        "soil_humidity": 66.88798895342741
    },
    {
        "date": "2024-10-11 06:53:09.251267",
        "air_temp": 30.534211139103625,
        "humidity": 44.748263761418826,
        "rainfall": 0,
        "wind_speed": 19.41336055587741,
        "soil_moisture": 36.729861239327946,
        "ndvi": 0.64707964909904,
        "soil_temp": 10.201660659751244,
        "soil_humidity": 36.06826462015594
    },
    {
        "date": "2024-10-11 07:53:09.251267",
        "air_temp": 16.307323151287704,
        "humidity": 55.74592222944479,
        "rainfall": 1,
        "wind_speed": 3.514304916736517,
        "soil_moisture": 24.88527778303346,
        "ndvi": 0.27623375921460464,
        "soil_temp": 13.069382088385298,
        "soil_humidity": 52.67801902555241
    },
    {
        "date": "2024-10-11 08:53:09.251267",
        "air_temp": 24.751423873467687,
        "humidity": 44.832241714387614,
        "rainfall": 0.5,
        "wind_speed": 11.942332400310068,
        "soil_moisture": 34.7416949223076,
        "ndvi": 0.2623242741672863,
        "soil_temp": 20.242558990433487,
        "soil_humidity": 60.57731449480768
    },
    {
        "date": "2024-10-11 09:53:09.251267",
        "air_temp": 15.672272003665652,
        "humidity": 48.8448475411992,
        "rainfall": 0,
        "wind_speed": 8.483701114919524,
        "soil_moisture": 22.064691455319025,
        "ndvi": 0.626087022557902,
        "soil_temp": 21.465988038438613,
        "soil_humidity": 60.3033533087623
    },
    {
        "date": "2024-10-11 10:53:09.251267",
        "air_temp": 16.25306406910709,
        "humidity": 89.3668079289773,
        "rainfall": 1,
        "wind_speed": 8.933774434893298,
        "soil_moisture": 13.071833883795296,
        "ndvi": 0.3253022122711903,
        "soil_temp": 22.59789359011063,
        "soil_humidity": 51.02527658398016
    },
    {
        "date": "2024-10-11 11:53:09.251267",
        "air_temp": 33.12874906688822,
        "humidity": 62.20517784533093,
        "rainfall": 0,
        "wind_speed": 5.082945602556288,
        "soil_moisture": 11.845172093759157,
        "ndvi": 0.38406365081169946,
        "soil_temp": 14.382652050730673,
        "soil_humidity": 54.989578396258324
    },
    {
        "date": "2024-10-11 12:53:09.251267",
        "air_temp": 17.784907422351903,
        "humidity": 66.59358832525083,
        "rainfall": 2,
        "wind_speed": 16.50269751322838,
        "soil_moisture": 11.540031139710328,
        "ndvi": 0.4851364802010442,
        "soil_temp": 23.965428944163442,
        "soil_humidity": 60.30107976531378
    },
    {
        "date": "2024-10-11 13:53:09.251267",
        "air_temp": 25.64841364550434,
        "humidity": 83.68092643686077,
        "rainfall": 1,
        "wind_speed": 9.705112082944822,
        "soil_moisture": 21.4045042587301,
        "ndvi": 0.46961079003772777,
        "soil_temp": 18.294203403668384,
        "soil_humidity": 63.64110551029647
    },
    {
        "date": "2024-10-11 14:53:09.251267",
        "air_temp": 23.221912052014133,
        "humidity": 89.77844502453439,
        "rainfall": 0,
        "wind_speed": 10.868117043965281,
        "soil_moisture": 20.117132525075263,
        "ndvi": 0.6251474750389996,
        "soil_temp": 20.448085335818394,
        "soil_humidity": 60.472523875034554
    },
    {
        "date": "2024-10-11 15:53:09.251267",
        "air_temp": 21.94686652517685,
        "humidity": 69.13572246723407,
        "rainfall": 1,
        "wind_speed": 6.095218255946515,
        "soil_moisture": 31.538110708677085,
        "ndvi": 0.5740590760660167,
        "soil_temp": 18.95108294169862,
        "soil_humidity": 44.16019772724971
    },
    {
        "date": "2024-10-11 16:53:09.251267",
        "air_temp": 32.99666691374545,
        "humidity": 80.62369105220859,
        "rainfall": 0,
        "wind_speed": 3.636169313338995,
        "soil_moisture": 32.28464504754598,
        "ndvi": 0.5866376973691194,
        "soil_temp": 11.841100820119307,
        "soil_humidity": 69.86905604230932
    },
    {
        "date": "2024-10-11 17:53:09.251267",
        "air_temp": 15.43646793550979,
        "humidity": 56.34705121683376,
        "rainfall": 0.5,
        "wind_speed": 1.7182077640564097,
        "soil_moisture": 11.525619065012195,
        "ndvi": 0.6284325983932745,
        "soil_temp": 24.446719890773196,
        "soil_humidity": 47.380107748772176
    },
    {
        "date": "2024-10-11 18:53:09.251267",
        "air_temp": 28.275793723511782,
        "humidity": 55.28719787236456,
        "rainfall": 0,
        "wind_speed": 14.282382818498318,
        "soil_moisture": 16.633697886670447,
        "ndvi": 0.3755676364540792,
        "soil_temp": 11.84313369841229,
        "soil_humidity": 30.978588110357336
    },
    {
        "date": "2024-10-11 19:53:09.251267",
        "air_temp": 34.26788868427101,
        "humidity": 60.135186049728006,
        "rainfall": 1,
        "wind_speed": 3.9246856337571123,
        "soil_moisture": 18.227855504766993,
        "ndvi": 0.39339998708028423,
        "soil_temp": 13.823229346167754,
        "soil_humidity": 44.50467806341848
    },
    {
        "date": "2024-10-11 20:53:09.251267",
        "air_temp": 26.20336366923684,
        "humidity": 73.62536313368246,
        "rainfall": 2,
        "wind_speed": 13.801507083775954,
        "soil_moisture": 26.428359524284282,
        "ndvi": 0.4627986487373325,
        "soil_temp": 22.2044182033447,
        "soil_humidity": 35.11131818295373
    },
    {
        "date": "2024-10-11 21:53:09.251267",
        "air_temp": 33.73644924066184,
        "humidity": 74.10958446275494,
        "rainfall": 2,
        "wind_speed": 7.8075289209390775,
        "soil_moisture": 14.335456717208508,
        "ndvi": 0.3844773038201157,
        "soil_temp": 19.27895442251652,
        "soil_humidity": 34.857704629135
    },
    {
        "date": "2024-10-11 22:53:09.251267",
        "air_temp": 16.045157585720474,
        "humidity": 55.76197173247254,
        "rainfall": 2,
        "wind_speed": 8.315496019793029,
        "soil_moisture": 14.01823658200001,
        "ndvi": 0.7909627213825181,
        "soil_temp": 22.572237480645356,
        "soil_humidity": 41.261401604275775
    },
    {
        "date": "2024-10-11 23:53:09.251267",
        "air_temp": 23.375866381483227,
        "humidity": 46.66049620683143,
        "rainfall": 0,
        "wind_speed": 9.383549921855433,
        "soil_moisture": 38.31366667623874,
        "ndvi": 0.6884311558528579,
        "soil_temp": 17.736042825610674,
        "soil_humidity": 32.135019767090434
    },
    {
        "date": "2024-10-12 00:53:09.251267",
        "air_temp": 20.203155819241022,
        "humidity": 71.61981282327542,
        "rainfall": 0.5,
        "wind_speed": 7.354170162550863,
        "soil_moisture": 34.12608721893343,
        "ndvi": 0.29879281295361776,
        "soil_temp": 15.654898530499523,
        "soil_humidity": 57.54048753638252
    },
    {
        "date": "2024-10-12 01:53:09.251267",
        "air_temp": 29.616419299614595,
        "humidity": 46.41629310943823,
        "rainfall": 0,
        "wind_speed": 2.0123995444518017,
        "soil_moisture": 15.419647062735109,
        "ndvi": 0.20335649352912172,
        "soil_temp": 23.827682493216773,
        "soil_humidity": 32.9595553680762
    },
    {
        "date": "2024-10-12 02:53:09.251267",
        "air_temp": 34.62594180994493,
        "humidity": 68.92462541146651,
        "rainfall": 2,
        "wind_speed": 6.192636200056915,
        "soil_moisture": 22.19703423716473,
        "ndvi": 0.22566996094836073,
        "soil_temp": 10.543784762721923,
        "soil_humidity": 38.13158221743825
    },
    {
        "date": "2024-10-12 03:53:09.251267",
        "air_temp": 20.130601248884957,
        "humidity": 74.67110640281544,
        "rainfall": 1,
        "wind_speed": 13.274687213878769,
        "soil_moisture": 20.668659977726968,
        "ndvi": 0.34643807062575516,
        "soil_temp": 22.84195330529569,
        "soil_humidity": 33.715988579764776
    },
    {
        "date": "2024-10-12 04:53:09.251267",
        "air_temp": 28.08349202948149,
        "humidity": 75.03057481972257,
        "rainfall": 2,
        "wind_speed": 13.31568802937742,
        "soil_moisture": 33.73012222196222,
        "ndvi": 0.26857477150211395,
        "soil_temp": 13.462266777816724,
        "soil_humidity": 32.52670923612124
    },
    {
        "date": "2024-10-12 05:53:09.251267",
        "air_temp": 18.961952655194803,
        "humidity": 77.67360553297983,
        "rainfall": 1,
        "wind_speed": 5.724486144799887,
        "soil_moisture": 25.30638749434366,
        "ndvi": 0.5200028689355434,
        "soil_temp": 22.731044916309514,
        "soil_humidity": 40.71006662158167
    },
    {
        "date": "2024-10-12 06:53:09.251267",
        "air_temp": 26.306605091432196,
        "humidity": 83.64297107828322,
        "rainfall": 0.5,
        "wind_speed": 9.20205253115376,
        "soil_moisture": 13.835492109804012,
        "ndvi": 0.7147971336364152,
        "soil_temp": 12.087949201253284,
        "soil_humidity": 52.8759587064148
    },
    {
        "date": "2024-10-12 07:53:09.251267",
        "air_temp": 24.278649733766724,
        "humidity": 64.99794959823942,
        "rainfall": 1,
        "wind_speed": 3.8689445602387496,
        "soil_moisture": 32.16976189468731,
        "ndvi": 0.6420501325479365,
        "soil_temp": 18.70328445535811,
        "soil_humidity": 68.58742225787367
    },
    {
        "date": "2024-10-12 08:53:09.251267",
        "air_temp": 34.44010659263074,
        "humidity": 76.51245947647857,
        "rainfall": 0,
        "wind_speed": 4.2074416305967155,
        "soil_moisture": 21.906639346747568,
        "ndvi": 0.32936543784931427,
        "soil_temp": 23.32161878517208,
        "soil_humidity": 65.02226617398425
    },
    {
        "date": "2024-10-12 09:53:09.251267",
        "air_temp": 27.170545453929332,
        "humidity": 70.95863676829184,
        "rainfall": 0,
        "wind_speed": 0.46094176813214816,
        "soil_moisture": 17.88772742896441,
        "ndvi": 0.4395795536154716,
        "soil_temp": 17.103094703483357,
        "soil_humidity": 65.037357017813
    },
    {
        "date": "2024-10-12 10:53:09.251267",
        "air_temp": 21.990127441736377,
        "humidity": 49.33199698988413,
        "rainfall": 0,
        "wind_speed": 17.934930998154893,
        "soil_moisture": 21.264958660621605,
        "ndvi": 0.5047365257187666,
        "soil_temp": 21.198500107050894,
        "soil_humidity": 37.0426814415063
    },
    {
        "date": "2024-10-12 11:53:09.251267",
        "air_temp": 17.281915697758667,
        "humidity": 41.28448224347041,
        "rainfall": 1,
        "wind_speed": 4.214133301498125,
        "soil_moisture": 24.932696908206893,
        "ndvi": 0.6861235097020013,
        "soil_temp": 22.070074267833377,
        "soil_humidity": 40.5962877399128
    },
    {
        "date": "2024-10-12 12:53:09.251267",
        "air_temp": 18.024936574698692,
        "humidity": 54.22706928384669,
        "rainfall": 0,
        "wind_speed": 4.550677538729266,
        "soil_moisture": 20.293465492510283,
        "ndvi": 0.703472961863699,
        "soil_temp": 21.047413130751686,
        "soil_humidity": 33.05573267181223
    },
    {
        "date": "2024-10-12 13:53:09.251267",
        "air_temp": 19.5063385990247,
        "humidity": 62.15168531688097,
        "rainfall": 0,
        "wind_speed": 5.592028652902636,
        "soil_moisture": 17.257173177752733,
        "ndvi": 0.7458309871446658,
        "soil_temp": 23.879946053118974,
        "soil_humidity": 61.1387335966074
    },
    {
        "date": "2024-10-12 14:53:09.251267",
        "air_temp": 20.019333226805962,
        "humidity": 70.8552972214328,
        "rainfall": 0,
        "wind_speed": 2.54816143390858,
        "soil_moisture": 21.95506086411941,
        "ndvi": 0.5055471503017251,
        "soil_temp": 16.70251345779893,
        "soil_humidity": 32.79265205942248
    },
    {
        "date": "2024-10-12 15:53:09.251267",
        "air_temp": 32.01232115682276,
        "humidity": 82.49796652516027,
        "rainfall": 0.5,
        "wind_speed": 12.785257663627256,
        "soil_moisture": 10.575187322496948,
        "ndvi": 0.6165648478615178,
        "soil_temp": 12.596881587477338,
        "soil_humidity": 52.26782683283413
    },
    {
        "date": "2024-10-12 16:53:09.251267",
        "air_temp": 26.224455765650497,
        "humidity": 49.81228583530828,
        "rainfall": 1,
        "wind_speed": 1.5028590635517314,
        "soil_moisture": 33.174935382889416,
        "ndvi": 0.2670802227403623,
        "soil_temp": 10.717165955537197,
        "soil_humidity": 39.91502289295803
    },
    {
        "date": "2024-10-12 17:53:09.251267",
        "air_temp": 25.467818227487328,
        "humidity": 46.277489494923856,
        "rainfall": 1,
        "wind_speed": 2.6840485265270053,
        "soil_moisture": 15.128346807027574,
        "ndvi": 0.5391733759723893,
        "soil_temp": 13.696898044562577,
        "soil_humidity": 39.73782536114979
    },
    {
        "date": "2024-10-12 18:53:09.251267",
        "air_temp": 17.29537743738435,
        "humidity": 88.17399737371638,
        "rainfall": 2,
        "wind_speed": 12.895134531801858,
        "soil_moisture": 11.317138408919117,
        "ndvi": 0.3492150856826811,
        "soil_temp": 12.14796255820363,
        "soil_humidity": 69.6290022099042
    },
    {
        "date": "2024-10-12 19:53:09.251267",
        "air_temp": 32.2027938669887,
        "humidity": 45.402227968820355,
        "rainfall": 1,
        "wind_speed": 6.793212409628855,
        "soil_moisture": 29.610960787152376,
        "ndvi": 0.4590301310159667,
        "soil_temp": 18.36923112151721,
        "soil_humidity": 60.271192279141495
    },
    {
        "date": "2024-10-12 20:53:09.251267",
        "air_temp": 29.456286064169674,
        "humidity": 63.924553505359455,
        "rainfall": 0.5,
        "wind_speed": 14.158956534023782,
        "soil_moisture": 33.21808386471017,
        "ndvi": 0.5804303049840317,
        "soil_temp": 12.774180058175979,
        "soil_humidity": 63.477574331642
    },
    {
        "date": "2024-10-12 21:53:09.251267",
        "air_temp": 16.353367238000033,
        "humidity": 69.27818643966509,
        "rainfall": 1,
        "wind_speed": 11.148494893402841,
        "soil_moisture": 34.414873947785296,
        "ndvi": 0.3541915627122687,
        "soil_temp": 10.584005609340569,
        "soil_humidity": 52.87930741376051
    },
    {
        "date": "2024-10-12 22:53:09.251267",
        "air_temp": 29.156701944595373,
        "humidity": 67.06872104537192,
        "rainfall": 0.5,
        "wind_speed": 5.120423461230262,
        "soil_moisture": 24.805275479818,
        "ndvi": 0.23131103467805667,
        "soil_temp": 10.539847433030017,
        "soil_humidity": 64.84057149574997
    },
    {
        "date": "2024-10-12 23:53:09.251267",
        "air_temp": 25.870764346852923,
        "humidity": 44.30160785164716,
        "rainfall": 0.5,
        "wind_speed": 13.016559009786562,
        "soil_moisture": 16.16104554069956,
        "ndvi": 0.20614031384525452,
        "soil_temp": 22.473542475472925,
        "soil_humidity": 55.81962156665412
    },
    {
        "date": "2024-10-13 00:53:09.251267",
        "air_temp": 16.634506914935564,
        "humidity": 42.85134570018097,
        "rainfall": 1,
        "wind_speed": 2.325473605857613,
        "soil_moisture": 32.1168303174948,
        "ndvi": 0.21329572132671293,
        "soil_temp": 18.871748971645374,
        "soil_humidity": 67.84694819185381
    },
    {
        "date": "2024-10-13 01:53:09.251267",
        "air_temp": 24.16601283057313,
        "humidity": 45.274171373051786,
        "rainfall": 2,
        "wind_speed": 10.728207661600608,
        "soil_moisture": 30.991392314630115,
        "ndvi": 0.20500984250826182,
        "soil_temp": 15.371279528400674,
        "soil_humidity": 41.25354344182547
    },
    {
        "date": "2024-10-13 02:53:09.251267",
        "air_temp": 24.69392574260094,
        "humidity": 69.27938419363062,
        "rainfall": 0,
        "wind_speed": 8.742962100900693,
        "soil_moisture": 17.388122023095196,
        "ndvi": 0.2662130339347253,
        "soil_temp": 23.413420641103706,
        "soil_humidity": 53.8127818313158
    },
    {
        "date": "2024-10-13 03:53:09.251267",
        "air_temp": 18.315490803265515,
        "humidity": 67.21724438171606,
        "rainfall": 0,
        "wind_speed": 5.357471656348816,
        "soil_moisture": 37.10223267588379,
        "ndvi": 0.34369322874207797,
        "soil_temp": 11.052949171115705,
        "soil_humidity": 57.605253917475686
    },
    {
        "date": "2024-10-13 04:53:09.251267",
        "air_temp": 33.913962940005646,
        "humidity": 51.68703960183721,
        "rainfall": 1,
        "wind_speed": 12.080099954790002,
        "soil_moisture": 13.13114374248487,
        "ndvi": 0.33273290850937953,
        "soil_temp": 24.704831090917388,
        "soil_humidity": 45.717492201112265
    },
    {
        "date": "2024-10-13 05:53:09.251267",
        "air_temp": 31.99950742961324,
        "humidity": 71.88870709227957,
        "rainfall": 1,
        "wind_speed": 17.42667184680565,
        "soil_moisture": 39.48064718773977,
        "ndvi": 0.7920779565079381,
        "soil_temp": 19.244376853816917,
        "soil_humidity": 65.18831929616212
    },
    {
        "date": "2024-10-13 06:53:09.251267",
        "air_temp": 28.380446746582166,
        "humidity": 81.00337510278247,
        "rainfall": 0.5,
        "wind_speed": 19.978093995261535,
        "soil_moisture": 35.80743432932394,
        "ndvi": 0.383603663125366,
        "soil_temp": 12.751963809532727,
        "soil_humidity": 69.19212652416618
    },
    {
        "date": "2024-10-13 07:53:09.251267",
        "air_temp": 24.245911220012143,
        "humidity": 42.07894799232969,
        "rainfall": 1,
        "wind_speed": 12.15525537486849,
        "soil_moisture": 30.636959364372256,
        "ndvi": 0.2764524700156395,
        "soil_temp": 20.18603648465453,
        "soil_humidity": 49.75077965490647
    },
    {
        "date": "2024-10-13 08:53:09.251267",
        "air_temp": 23.23531083169017,
        "humidity": 64.90649717740212,
        "rainfall": 0.5,
        "wind_speed": 8.094117486583404,
        "soil_moisture": 38.162496648394985,
        "ndvi": 0.5273375265006548,
        "soil_temp": 21.45071889675344,
        "soil_humidity": 45.62562619217356
    },
    {
        "date": "2024-10-13 09:53:09.251267",
        "air_temp": 28.019469343019082,
        "humidity": 74.46552060513451,
        "rainfall": 2,
        "wind_speed": 4.5041420043240326,
        "soil_moisture": 31.669335049711233,
        "ndvi": 0.7147744732385051,
        "soil_temp": 16.440141750561615,
        "soil_humidity": 48.87751714225835
    },
    {
        "date": "2024-10-13 10:53:09.251267",
        "air_temp": 25.90863738813811,
        "humidity": 52.61559635348436,
        "rainfall": 1,
        "wind_speed": 5.141921787902235,
        "soil_moisture": 15.713709910901379,
        "ndvi": 0.6068362260726052,
        "soil_temp": 22.92448909368321,
        "soil_humidity": 64.10946982927726
    },
    {
        "date": "2024-10-13 11:53:09.251267",
        "air_temp": 16.24546211763771,
        "humidity": 55.38854526465745,
        "rainfall": 0,
        "wind_speed": 9.589099562995314,
        "soil_moisture": 23.26468414338435,
        "ndvi": 0.3776492534812702,
        "soil_temp": 12.260986109447055,
        "soil_humidity": 55.8187370207267
    },
    {
        "date": "2024-10-13 12:53:09.251267",
        "air_temp": 25.250052975406632,
        "humidity": 70.6818951494692,
        "rainfall": 0,
        "wind_speed": 7.3455540256141845,
        "soil_moisture": 12.145090729949061,
        "ndvi": 0.5369696958748731,
        "soil_temp": 14.182563753664125,
        "soil_humidity": 31.790894900723803
    },
    {
        "date": "2024-10-13 13:53:09.251267",
        "air_temp": 31.12807234465679,
        "humidity": 84.89676467218685,
        "rainfall": 1,
        "wind_speed": 18.276290452498884,
        "soil_moisture": 21.689722668643462,
        "ndvi": 0.6289135845202456,
        "soil_temp": 11.402385423759846,
        "soil_humidity": 51.68679641782859
    },
    {
        "date": "2024-10-13 14:53:09.251267",
        "air_temp": 24.184797551939162,
        "humidity": 80.49622101514497,
        "rainfall": 0.5,
        "wind_speed": 7.365909353114191,
        "soil_moisture": 32.248090592116256,
        "ndvi": 0.6368626954206456,
        "soil_temp": 13.163167609110282,
        "soil_humidity": 30.692004909638793
    },
    {
        "date": "2024-10-13 15:53:09.251267",
        "air_temp": 16.03913155108537,
        "humidity": 69.15651095497918,
        "rainfall": 0,
        "wind_speed": 7.468891822908709,
        "soil_moisture": 21.59330052792452,
        "ndvi": 0.5999230731786134,
        "soil_temp": 13.580932178667176,
        "soil_humidity": 61.14141484013622
    },
    {
        "date": "2024-10-13 16:53:09.251267",
        "air_temp": 30.725563987680662,
        "humidity": 76.51108588786246,
        "rainfall": 0.5,
        "wind_speed": 7.67069024867954,
        "soil_moisture": 19.908825264044022,
        "ndvi": 0.7224862548653708,
        "soil_temp": 23.764497863825653,
        "soil_humidity": 43.13249094169855
    },
    {
        "date": "2024-10-13 17:53:09.251267",
        "air_temp": 19.027275642719488,
        "humidity": 58.24112351038993,
        "rainfall": 1,
        "wind_speed": 16.732404207353163,
        "soil_moisture": 27.860671675309355,
        "ndvi": 0.7220632750064626,
        "soil_temp": 11.171645331267342,
        "soil_humidity": 48.88029928469317
    },
    {
        "date": "2024-10-13 18:53:09.251267",
        "air_temp": 20.172416697760774,
        "humidity": 72.0482764049379,
        "rainfall": 1,
        "wind_speed": 13.015766416938037,
        "soil_moisture": 36.16613586996685,
        "ndvi": 0.5473619356442021,
        "soil_temp": 16.41122680281564,
        "soil_humidity": 61.94712423630528
    },
    {
        "date": "2024-10-13 19:53:09.251267",
        "air_temp": 18.294127068645917,
        "humidity": 63.30907955637785,
        "rainfall": 0,
        "wind_speed": 12.283377006377854,
        "soil_moisture": 11.427495776383372,
        "ndvi": 0.45791382146672743,
        "soil_temp": 17.985980308403693,
        "soil_humidity": 31.857952691430462
    },
    {
        "date": "2024-10-13 20:53:09.251267",
        "air_temp": 21.60430129742995,
        "humidity": 49.49680682287804,
        "rainfall": 1,
        "wind_speed": 6.6828604476772435,
        "soil_moisture": 21.564113040992062,
        "ndvi": 0.58494217733906,
        "soil_temp": 23.480395807032412,
        "soil_humidity": 36.34166142366442
    },
    {
        "date": "2024-10-13 21:53:09.251267",
        "air_temp": 30.1350301137727,
        "humidity": 75.08691369386287,
        "rainfall": 2,
        "wind_speed": 16.35808353664879,
        "soil_moisture": 14.747222254457881,
        "ndvi": 0.690927200822278,
        "soil_temp": 24.965443279918205,
        "soil_humidity": 45.918415057804594
    },
    {
        "date": "2024-10-13 22:53:09.251267",
        "air_temp": 25.387717190078746,
        "humidity": 67.82323186744111,
        "rainfall": 2,
        "wind_speed": 0.7672772271140227,
        "soil_moisture": 30.86062752195609,
        "ndvi": 0.20339126290471085,
        "soil_temp": 21.300821723797537,
        "soil_humidity": 39.197006541822425
    },
    {
        "date": "2024-10-13 23:53:09.251267",
        "air_temp": 19.09762548301425,
        "humidity": 57.93107909767957,
        "rainfall": 0,
        "wind_speed": 17.415943446762263,
        "soil_moisture": 13.171736812934046,
        "ndvi": 0.7951982054089108,
        "soil_temp": 24.561864858535444,
        "soil_humidity": 43.516057031002525
    },
    {
        "date": "2024-10-14 00:53:09.251267",
        "air_temp": 32.556601563040815,
        "humidity": 85.56695950393154,
        "rainfall": 2,
        "wind_speed": 10.254997995878592,
        "soil_moisture": 29.944232757407335,
        "ndvi": 0.3097362633975196,
        "soil_temp": 21.806516988038105,
        "soil_humidity": 69.92698951569861
    },
    {
        "date": "2024-10-14 01:53:09.251267",
        "air_temp": 32.591637099030585,
        "humidity": 41.046705891336664,
        "rainfall": 0.5,
        "wind_speed": 7.965004697268256,
        "soil_moisture": 18.585426123937218,
        "ndvi": 0.4821493913959858,
        "soil_temp": 24.44803961457125,
        "soil_humidity": 63.01657750295243
    },
    {
        "date": "2024-10-14 02:53:09.251267",
        "air_temp": 32.41156850092014,
        "humidity": 55.788657562474796,
        "rainfall": 0,
        "wind_speed": 9.943345614353714,
        "soil_moisture": 15.96750441211674,
        "ndvi": 0.7232178409057202,
        "soil_temp": 20.609060889166827,
        "soil_humidity": 65.35607259840434
    },
    {
        "date": "2024-10-14 03:53:09.251267",
        "air_temp": 19.775924227630785,
        "humidity": 42.84406988888233,
        "rainfall": 0,
        "wind_speed": 13.348310610676622,
        "soil_moisture": 38.09057673474956,
        "ndvi": 0.33502423942651965,
        "soil_temp": 11.196451381766066,
        "soil_humidity": 67.48218534263101
    },
    {
        "date": "2024-10-14 04:53:09.251267",
        "air_temp": 24.02478688361976,
        "humidity": 78.32636581108136,
        "rainfall": 0.5,
        "wind_speed": 0.2553085303579472,
        "soil_moisture": 10.555737302378676,
        "ndvi": 0.48607713434023,
        "soil_temp": 16.028802804890148,
        "soil_humidity": 43.63743521256782
    },
    {
        "date": "2024-10-14 05:53:09.251267",
        "air_temp": 34.6997931228175,
        "humidity": 75.10537270568963,
        "rainfall": 2,
        "wind_speed": 17.431037129164956,
        "soil_moisture": 23.133318156687068,
        "ndvi": 0.27954930810870754,
        "soil_temp": 19.698959257037053,
        "soil_humidity": 41.88307831185891
    },
    {
        "date": "2024-10-14 06:53:09.251267",
        "air_temp": 30.440249526199807,
        "humidity": 56.56761093681585,
        "rainfall": 2,
        "wind_speed": 8.934790506227856,
        "soil_moisture": 10.648809773853813,
        "ndvi": 0.4370457952329156,
        "soil_temp": 15.260777641813764,
        "soil_humidity": 39.362499702883
    },
    {
        "date": "2024-10-14 07:53:09.251267",
        "air_temp": 15.543348424511196,
        "humidity": 73.78314511035364,
        "rainfall": 2,
        "wind_speed": 6.859330394001564,
        "soil_moisture": 24.507791443129868,
        "ndvi": 0.30628127287580154,
        "soil_temp": 14.10075877170668,
        "soil_humidity": 63.204676452324904
    },
    {
        "date": "2024-10-14 08:53:09.251267",
        "air_temp": 16.30409182520984,
        "humidity": 59.79686130979749,
        "rainfall": 0,
        "wind_speed": 2.764911312681142,
        "soil_moisture": 25.031421333832547,
        "ndvi": 0.41817664926269427,
        "soil_temp": 18.660989006996367,
        "soil_humidity": 57.1820744914074
    },
    {
        "date": "2024-10-14 09:53:09.251267",
        "air_temp": 24.278627536246656,
        "humidity": 77.81268957112172,
        "rainfall": 0,
        "wind_speed": 8.789307487168843,
        "soil_moisture": 19.64987414184241,
        "ndvi": 0.42407853007563323,
        "soil_temp": 24.002528150536524,
        "soil_humidity": 39.44687929931509
    },
    {
        "date": "2024-10-14 10:53:09.251267",
        "air_temp": 33.1844041172312,
        "humidity": 62.70918313683531,
        "rainfall": 2,
        "wind_speed": 9.926455425687577,
        "soil_moisture": 13.96485332575071,
        "ndvi": 0.7959024737589997,
        "soil_temp": 10.524728102742303,
        "soil_humidity": 45.07975316841819
    },
    {
        "date": "2024-10-14 11:53:09.251267",
        "air_temp": 25.774035959054284,
        "humidity": 60.61398639158328,
        "rainfall": 1,
        "wind_speed": 8.072933889869557,
        "soil_moisture": 21.29162613203426,
        "ndvi": 0.7968878175822633,
        "soil_temp": 21.538468053121566,
        "soil_humidity": 50.26847055731193
    },
    {
        "date": "2024-10-14 12:53:09.251267",
        "air_temp": 24.956250160685805,
        "humidity": 86.73649577441505,
        "rainfall": 2,
        "wind_speed": 13.573201876537942,
        "soil_moisture": 30.167888197976982,
        "ndvi": 0.26887176719636746,
        "soil_temp": 20.840909170669406,
        "soil_humidity": 51.12560229095015
    },
    {
        "date": "2024-10-14 13:53:09.251267",
        "air_temp": 17.10947399913261,
        "humidity": 52.543954994535014,
        "rainfall": 0.5,
        "wind_speed": 8.41793057518452,
        "soil_moisture": 33.471874259333774,
        "ndvi": 0.6355812675970085,
        "soil_temp": 24.459301380824307,
        "soil_humidity": 65.54910914206859
    },
    {
        "date": "2024-10-14 14:53:09.251267",
        "air_temp": 28.135602094485883,
        "humidity": 46.02236699982827,
        "rainfall": 0,
        "wind_speed": 19.228351556931468,
        "soil_moisture": 25.92251807164427,
        "ndvi": 0.2310472878014607,
        "soil_temp": 23.960668334988153,
        "soil_humidity": 45.22264531788865
    },
    {
        "date": "2024-10-14 15:53:09.251267",
        "air_temp": 31.44206321200451,
        "humidity": 69.2271556629729,
        "rainfall": 0.5,
        "wind_speed": 11.985550356471837,
        "soil_moisture": 27.69503183319267,
        "ndvi": 0.2181152092023132,
        "soil_temp": 21.43348843066289,
        "soil_humidity": 66.34096122002049
    },
    {
        "date": "2024-10-14 16:53:09.251267",
        "air_temp": 22.608400150151247,
        "humidity": 88.46609061420895,
        "rainfall": 0.5,
        "wind_speed": 16.14955871303818,
        "soil_moisture": 16.01849113373958,
        "ndvi": 0.2632003365469786,
        "soil_temp": 17.80613147023898,
        "soil_humidity": 47.654702412791586
    },
    {
        "date": "2024-10-14 17:53:09.251267",
        "air_temp": 30.51223701850655,
        "humidity": 58.89196068635933,
        "rainfall": 0,
        "wind_speed": 2.187118062189577,
        "soil_moisture": 18.619909996829485,
        "ndvi": 0.7792913942047606,
        "soil_temp": 13.064013298502797,
        "soil_humidity": 59.85490699169415
    },
    {
        "date": "2024-10-14 18:53:09.251267",
        "air_temp": 34.289533078016234,
        "humidity": 43.10183818143908,
        "rainfall": 1,
        "wind_speed": 7.9458258589181145,
        "soil_moisture": 14.856868301570753,
        "ndvi": 0.7645181287368186,
        "soil_temp": 18.178535774883613,
        "soil_humidity": 67.21017279940224
    },
    {
        "date": "2024-10-14 19:53:09.251267",
        "air_temp": 19.07532918255378,
        "humidity": 56.96526336547837,
        "rainfall": 2,
        "wind_speed": 6.736954028943054,
        "soil_moisture": 17.47886050013834,
        "ndvi": 0.3163566921684083,
        "soil_temp": 21.480279908794806,
        "soil_humidity": 66.30302815853065
    },
    {
        "date": "2024-10-14 20:53:09.251267",
        "air_temp": 25.466592623894826,
        "humidity": 65.29658271597792,
        "rainfall": 0.5,
        "wind_speed": 11.389647762731837,
        "soil_moisture": 16.746603406765953,
        "ndvi": 0.2970034013322269,
        "soil_temp": 23.435038189568395,
        "soil_humidity": 32.27811469182725
    },
    {
        "date": "2024-10-14 21:53:09.251267",
        "air_temp": 20.74275916169151,
        "humidity": 48.09615033161069,
        "rainfall": 0,
        "wind_speed": 17.222270401228513,
        "soil_moisture": 25.58346359173713,
        "ndvi": 0.34432105190956824,
        "soil_temp": 21.845680138158237,
        "soil_humidity": 38.22817179906279
    },
    {
        "date": "2024-10-14 22:53:09.251267",
        "air_temp": 30.857088380676785,
        "humidity": 72.9051109028246,
        "rainfall": 0.5,
        "wind_speed": 1.5667643552401334,
        "soil_moisture": 26.880662673721883,
        "ndvi": 0.5093281964450853,
        "soil_temp": 12.92900402786581,
        "soil_humidity": 49.076217021291
    },
    {
        "date": "2024-10-14 23:53:09.251267",
        "air_temp": 26.55186731762315,
        "humidity": 89.88746945051003,
        "rainfall": 2,
        "wind_speed": 1.4514959608942157,
        "soil_moisture": 38.34842262464514,
        "ndvi": 0.541822779221246,
        "soil_temp": 18.99529471342089,
        "soil_humidity": 47.26217159812429
    },
    {
        "date": "2024-10-15 00:53:09.251267",
        "air_temp": 27.691648351006073,
        "humidity": 62.58697379907219,
        "rainfall": 1,
        "wind_speed": 17.00635416315198,
        "soil_moisture": 15.867119413045693,
        "ndvi": 0.3329560582636685,
        "soil_temp": 14.982601892970354,
        "soil_humidity": 55.63566659348828
    },
    {
        "date": "2024-10-15 01:53:09.251267",
        "air_temp": 30.958283192317882,
        "humidity": 57.73642783651063,
        "rainfall": 1,
        "wind_speed": 4.257001720767148,
        "soil_moisture": 29.25027130484488,
        "ndvi": 0.6539736728539671,
        "soil_temp": 10.138085010859644,
        "soil_humidity": 67.78836203301648
    },
    {
        "date": "2024-10-15 02:53:09.251267",
        "air_temp": 22.9194094532491,
        "humidity": 60.03520834172333,
        "rainfall": 1,
        "wind_speed": 13.375065088996275,
        "soil_moisture": 34.517778069625685,
        "ndvi": 0.3857319016415905,
        "soil_temp": 13.003314036829991,
        "soil_humidity": 34.49618845111795
    },
    {
        "date": "2024-10-15 03:53:09.251267",
        "air_temp": 33.301801368140865,
        "humidity": 45.75730891976006,
        "rainfall": 0,
        "wind_speed": 10.857562647216103,
        "soil_moisture": 26.807243913491575,
        "ndvi": 0.7532787953176463,
        "soil_temp": 11.698309749001739,
        "soil_humidity": 48.07434660536962
    },
    {
        "date": "2024-10-15 04:53:09.251267",
        "air_temp": 25.6605773449228,
        "humidity": 84.16368007131119,
        "rainfall": 0,
        "wind_speed": 16.657871897744588,
        "soil_moisture": 17.65010957503227,
        "ndvi": 0.5940336238740385,
        "soil_temp": 22.640786853092028,
        "soil_humidity": 37.696441515485574
    },
    {
        "date": "2024-10-15 05:53:09.251267",
        "air_temp": 18.159096456706962,
        "humidity": 60.7412827760676,
        "rainfall": 1,
        "wind_speed": 2.5017781967280706,
        "soil_moisture": 17.876506900600006,
        "ndvi": 0.46181071658216705,
        "soil_temp": 13.877593782217179,
        "soil_humidity": 65.7643039359179
    },
    {
        "date": "2024-10-15 06:53:09.251267",
        "air_temp": 28.917982363376048,
        "humidity": 59.36372057124207,
        "rainfall": 2,
        "wind_speed": 2.5956593144522255,
        "soil_moisture": 11.177591592129998,
        "ndvi": 0.6739472010217074,
        "soil_temp": 14.270469533313332,
        "soil_humidity": 68.5832074320661
    },
    {
        "date": "2024-10-15 07:53:09.251267",
        "air_temp": 30.86522700876509,
        "humidity": 72.98451364652371,
        "rainfall": 2,
        "wind_speed": 11.13636720556615,
        "soil_moisture": 19.16332679645445,
        "ndvi": 0.690748775627999,
        "soil_temp": 24.39904857698326,
        "soil_humidity": 49.148067275614004
    },
    {
        "date": "2024-10-15 08:53:09.251267",
        "air_temp": 21.335233541350533,
        "humidity": 62.10167104655618,
        "rainfall": 0,
        "wind_speed": 18.92757306716742,
        "soil_moisture": 32.338110370101404,
        "ndvi": 0.3742889633653662,
        "soil_temp": 15.686300845732783,
        "soil_humidity": 34.16041190912563
    },
    {
        "date": "2024-10-15 09:53:09.251267",
        "air_temp": 32.14358513997506,
        "humidity": 72.41167344039229,
        "rainfall": 1,
        "wind_speed": 8.299955167415085,
        "soil_moisture": 11.425532716213311,
        "ndvi": 0.4038287263016507,
        "soil_temp": 10.143784602165919,
        "soil_humidity": 61.41178666890693
    },
    {
        "date": "2024-10-15 10:53:09.251267",
        "air_temp": 33.122865095315326,
        "humidity": 43.07231450653938,
        "rainfall": 2,
        "wind_speed": 6.538842005093899,
        "soil_moisture": 16.57885847514492,
        "ndvi": 0.7615730922371622,
        "soil_temp": 14.257692225192049,
        "soil_humidity": 57.88565401969596
    },
    {
        "date": "2024-10-15 11:53:09.251267",
        "air_temp": 20.538089747556178,
        "humidity": 80.68616718611136,
        "rainfall": 2,
        "wind_speed": 12.133786463957716,
        "soil_moisture": 31.18697495595346,
        "ndvi": 0.29884462301796316,
        "soil_temp": 11.789493709027187,
        "soil_humidity": 44.18683306441242
    },
    {
        "date": "2024-10-15 12:53:09.251267",
        "air_temp": 34.67042945430444,
        "humidity": 87.02519764397013,
        "rainfall": 2,
        "wind_speed": 18.608972010900622,
        "soil_moisture": 33.4795468846863,
        "ndvi": 0.22529025017776053,
        "soil_temp": 11.54212842603781,
        "soil_humidity": 65.49629895875853
    },
    {
        "date": "2024-10-15 13:53:09.251267",
        "air_temp": 17.81423056097517,
        "humidity": 72.4382693079693,
        "rainfall": 0,
        "wind_speed": 16.738556656892474,
        "soil_moisture": 23.65955230185785,
        "ndvi": 0.47243319356572955,
        "soil_temp": 11.162309352036242,
        "soil_humidity": 69.0332755913083
    },
    {
        "date": "2024-10-15 14:53:09.251267",
        "air_temp": 19.0403133680498,
        "humidity": 87.72829644294055,
        "rainfall": 1,
        "wind_speed": 19.909672214664397,
        "soil_moisture": 30.942230154359464,
        "ndvi": 0.6807259401461774,
        "soil_temp": 18.898892052630657,
        "soil_humidity": 52.314153965207815
    },
    {
        "date": "2024-10-15 15:53:09.251267",
        "air_temp": 18.684496784760523,
        "humidity": 47.571615718498165,
        "rainfall": 0.5,
        "wind_speed": 0.4513716640475418,
        "soil_moisture": 21.63856505964676,
        "ndvi": 0.6630008637864475,
        "soil_temp": 12.84021585444788,
        "soil_humidity": 52.629116453771694
    },
    {
        "date": "2024-10-15 16:53:09.251267",
        "air_temp": 32.879794200157406,
        "humidity": 63.87080947401768,
        "rainfall": 1,
        "wind_speed": 2.2571628942552446,
        "soil_moisture": 14.797397350723825,
        "ndvi": 0.7517183773476996,
        "soil_temp": 15.418641910615158,
        "soil_humidity": 41.90054020455111
    },
    {
        "date": "2024-10-15 17:53:09.251267",
        "air_temp": 28.085851046499236,
        "humidity": 70.09033669727236,
        "rainfall": 1,
        "wind_speed": 12.18935526733244,
        "soil_moisture": 21.944484805687196,
        "ndvi": 0.5199203986214243,
        "soil_temp": 22.93026574195025,
        "soil_humidity": 53.380241439332565
    },
    {
        "date": "2024-10-15 18:53:09.251267",
        "air_temp": 18.04208566444546,
        "humidity": 69.08683237105933,
        "rainfall": 0,
        "wind_speed": 16.411528786676996,
        "soil_moisture": 27.399034606094286,
        "ndvi": 0.26003880293992,
        "soil_temp": 16.365072096944136,
        "soil_humidity": 47.58569282099386
    },
    {
        "date": "2024-10-15 19:53:09.251267",
        "air_temp": 23.806468367366982,
        "humidity": 60.07308895445216,
        "rainfall": 2,
        "wind_speed": 5.704886846135331,
        "soil_moisture": 38.44159845558671,
        "ndvi": 0.5532133846016097,
        "soil_temp": 23.735370250774565,
        "soil_humidity": 42.08764107712207
    },
    {
        "date": "2024-10-15 20:53:09.251267",
        "air_temp": 27.30596050139604,
        "humidity": 56.90422046831307,
        "rainfall": 2,
        "wind_speed": 4.784221760684375,
        "soil_moisture": 12.062528562660319,
        "ndvi": 0.6670373686119271,
        "soil_temp": 17.469070800934094,
        "soil_humidity": 32.68740786095605
    },
    {
        "date": "2024-10-15 21:53:09.251267",
        "air_temp": 16.669281699787668,
        "humidity": 46.34120112752094,
        "rainfall": 0,
        "wind_speed": 15.739515987624017,
        "soil_moisture": 25.221822815348816,
        "ndvi": 0.25599687216189587,
        "soil_temp": 15.982549222596337,
        "soil_humidity": 40.108314388364995
    },
    {
        "date": "2024-10-15 22:53:09.251267",
        "air_temp": 32.648327368146774,
        "humidity": 59.58712252811391,
        "rainfall": 0,
        "wind_speed": 1.1276370770646316,
        "soil_moisture": 19.867593346321733,
        "ndvi": 0.3035944406585539,
        "soil_temp": 21.25583390636052,
        "soil_humidity": 37.91418598710658
    },
    {
        "date": "2024-10-15 23:53:09.251267",
        "air_temp": 31.072070708830704,
        "humidity": 48.13304075527426,
        "rainfall": 0,
        "wind_speed": 7.387652951651702,
        "soil_moisture": 25.249350828300834,
        "ndvi": 0.20737439669340255,
        "soil_temp": 11.097626481520031,
        "soil_humidity": 51.68167938215795
    },
    {
        "date": "2024-10-16 00:53:09.251267",
        "air_temp": 25.104135471532764,
        "humidity": 76.71828382461092,
        "rainfall": 0,
        "wind_speed": 10.116184451846594,
        "soil_moisture": 10.71926342129595,
        "ndvi": 0.5561915476680863,
        "soil_temp": 11.342175744736261,
        "soil_humidity": 54.06680087054376
    },
    {
        "date": "2024-10-16 01:53:09.251267",
        "air_temp": 34.34253230518341,
        "humidity": 50.4415467747295,
        "rainfall": 0,
        "wind_speed": 11.206973487006469,
        "soil_moisture": 19.39076064104744,
        "ndvi": 0.7320406351675428,
        "soil_temp": 19.77960570884441,
        "soil_humidity": 54.34286897224066
    },
    {
        "date": "2024-10-16 02:53:09.251267",
        "air_temp": 23.35521958550199,
        "humidity": 42.928265264226695,
        "rainfall": 0.5,
        "wind_speed": 14.572303135855538,
        "soil_moisture": 34.59604442581015,
        "ndvi": 0.6023979896012598,
        "soil_temp": 17.304110698588723,
        "soil_humidity": 45.52724004182716
    },
    {
        "date": "2024-10-16 03:53:09.251267",
        "air_temp": 34.68220638072995,
        "humidity": 67.61830689086172,
        "rainfall": 1,
        "wind_speed": 7.096914468613164,
        "soil_moisture": 26.618252015639126,
        "ndvi": 0.7892240525545893,
        "soil_temp": 21.856228754788155,
        "soil_humidity": 46.84918934741984
    },
    {
        "date": "2024-10-16 04:53:09.251267",
        "air_temp": 28.35839997607254,
        "humidity": 69.76148663597007,
        "rainfall": 1,
        "wind_speed": 12.253686893492144,
        "soil_moisture": 23.51747478448352,
        "ndvi": 0.23782019149985695,
        "soil_temp": 23.74406256015297,
        "soil_humidity": 63.3695371856256
    },
    {
        "date": "2024-10-16 05:53:09.251267",
        "air_temp": 27.69342546507883,
        "humidity": 83.59565306402527,
        "rainfall": 1,
        "wind_speed": 11.707508455819127,
        "soil_moisture": 18.565752151460188,
        "ndvi": 0.7418006510908979,
        "soil_temp": 15.825503165073213,
        "soil_humidity": 49.0493125406865
    },
    {
        "date": "2024-10-16 06:53:09.251267",
        "air_temp": 18.3190979059157,
        "humidity": 68.8490806208841,
        "rainfall": 2,
        "wind_speed": 10.952322013074943,
        "soil_moisture": 18.941275683740677,
        "ndvi": 0.6884409724320085,
        "soil_temp": 18.946150398769696,
        "soil_humidity": 69.5109332854849
    },
    {
        "date": "2024-10-16 07:53:09.251267",
        "air_temp": 32.63855516609288,
        "humidity": 57.27404268617229,
        "rainfall": 0.5,
        "wind_speed": 12.809665862844245,
        "soil_moisture": 21.49304300771753,
        "ndvi": 0.388544912299703,
        "soil_temp": 17.51044941692965,
        "soil_humidity": 47.584689200354596
    },
    {
        "date": "2024-10-16 08:53:09.251267",
        "air_temp": 23.549793758412303,
        "humidity": 80.15059407631603,
        "rainfall": 1,
        "wind_speed": 5.188983421361526,
        "soil_moisture": 35.06712129893681,
        "ndvi": 0.7841159311023511,
        "soil_temp": 24.724159834636495,
        "soil_humidity": 55.98323778471356
    },
    {
        "date": "2024-10-16 09:53:09.251267",
        "air_temp": 18.24466811564959,
        "humidity": 67.01394465937105,
        "rainfall": 2,
        "wind_speed": 1.6929750716925795,
        "soil_moisture": 39.01639620424805,
        "ndvi": 0.37276264890284905,
        "soil_temp": 22.466266110348926,
        "soil_humidity": 32.014519959735836
    },
    {
        "date": "2024-10-16 10:53:09.251267",
        "air_temp": 15.25215039951518,
        "humidity": 43.96401423155959,
        "rainfall": 0.5,
        "wind_speed": 2.3854334407948685,
        "soil_moisture": 23.159661440511236,
        "ndvi": 0.7335828342969313,
        "soil_temp": 23.6760656241603,
        "soil_humidity": 56.13359697675243
    },
    {
        "date": "2024-10-16 11:53:09.251267",
        "air_temp": 26.19511367276765,
        "humidity": 68.29252801566233,
        "rainfall": 0.5,
        "wind_speed": 4.491647653078021,
        "soil_moisture": 21.800585162432853,
        "ndvi": 0.7284491462445137,
        "soil_temp": 13.815101313664062,
        "soil_humidity": 64.05705577660024
    },
    {
        "date": "2024-10-16 12:53:09.251267",
        "air_temp": 25.547992442453527,
        "humidity": 85.40092727108312,
        "rainfall": 2,
        "wind_speed": 10.003958710455706,
        "soil_moisture": 35.396523274595886,
        "ndvi": 0.6545945699617836,
        "soil_temp": 21.587896347438914,
        "soil_humidity": 67.69831354133186
    },
    {
        "date": "2024-10-16 13:53:09.251267",
        "air_temp": 29.387072447383172,
        "humidity": 59.81563127491103,
        "rainfall": 0.5,
        "wind_speed": 16.808855010920404,
        "soil_moisture": 22.807159619089106,
        "ndvi": 0.5380822842877112,
        "soil_temp": 13.982098374964734,
        "soil_humidity": 69.89282446559082
    },
    {
        "date": "2024-10-16 14:53:09.251267",
        "air_temp": 32.80516106035604,
        "humidity": 54.81629009499,
        "rainfall": 2,
        "wind_speed": 14.025866841767682,
        "soil_moisture": 26.560545168869535,
        "ndvi": 0.27200164977013286,
        "soil_temp": 13.131138001425848,
        "soil_humidity": 41.287145825969326
    },
    {
        "date": "2024-10-16 15:53:09.251267",
        "air_temp": 16.58813169286059,
        "humidity": 47.173472850901,
        "rainfall": 1,
        "wind_speed": 15.780812084040942,
        "soil_moisture": 16.928249937962335,
        "ndvi": 0.519183040697899,
        "soil_temp": 24.16399239483326,
        "soil_humidity": 53.014802593028676
    },
    {
        "date": "2024-10-16 16:53:09.251267",
        "air_temp": 29.629929806807247,
        "humidity": 47.572276096767865,
        "rainfall": 2,
        "wind_speed": 7.019512478521827,
        "soil_moisture": 32.305076073372256,
        "ndvi": 0.41407775339296077,
        "soil_temp": 10.289566250767999,
        "soil_humidity": 48.0287194212859
    },
    {
        "date": "2024-10-16 17:53:09.251267",
        "air_temp": 18.748238881951643,
        "humidity": 61.63857421709095,
        "rainfall": 0.5,
        "wind_speed": 8.093074832971004,
        "soil_moisture": 25.959649548123455,
        "ndvi": 0.6753302198932112,
        "soil_temp": 24.480830554951446,
        "soil_humidity": 52.55269922300684
    },
    {
        "date": "2024-10-16 18:53:09.251267",
        "air_temp": 32.16354106314328,
        "humidity": 69.78399120807647,
        "rainfall": 1,
        "wind_speed": 7.8736377198122565,
        "soil_moisture": 34.271036507210255,
        "ndvi": 0.654877523898107,
        "soil_temp": 12.238928454832093,
        "soil_humidity": 36.298118287593866
    },
    {
        "date": "2024-10-16 19:53:09.251267",
        "air_temp": 31.38127027723312,
        "humidity": 44.03077025848009,
        "rainfall": 1,
        "wind_speed": 3.8374636880900304,
        "soil_moisture": 33.42198190086009,
        "ndvi": 0.7463675044235631,
        "soil_temp": 22.291571226899023,
        "soil_humidity": 64.24598290383766
    },
    {
        "date": "2024-10-16 20:53:09.251267",
        "air_temp": 25.815875099710887,
        "humidity": 86.97842945125828,
        "rainfall": 0,
        "wind_speed": 1.2243331628714405,
        "soil_moisture": 16.450143048100855,
        "ndvi": 0.20889809745902474,
        "soil_temp": 14.138838296021547,
        "soil_humidity": 66.3827577337835
    },
    {
        "date": "2024-10-16 21:53:09.251267",
        "air_temp": 29.20485597246623,
        "humidity": 77.75668087225979,
        "rainfall": 0,
        "wind_speed": 11.818321856544536,
        "soil_moisture": 31.469066516732873,
        "ndvi": 0.6854619300938277,
        "soil_temp": 15.260534911460912,
        "soil_humidity": 47.9864665245169
    },
    {
        "date": "2024-10-16 22:53:09.251267",
        "air_temp": 21.287002263674967,
        "humidity": 69.33119591418313,
        "rainfall": 0.5,
        "wind_speed": 19.784333668411723,
        "soil_moisture": 34.00163223854114,
        "ndvi": 0.7630772318748014,
        "soil_temp": 13.251365669829774,
        "soil_humidity": 41.21719446294307
    },
    {
        "date": "2024-10-16 23:53:09.251267",
        "air_temp": 24.42335797865629,
        "humidity": 81.42193354694953,
        "rainfall": 2,
        "wind_speed": 3.3365940990052567,
        "soil_moisture": 20.383217223471853,
        "ndvi": 0.6735802694648901,
        "soil_temp": 18.782710637216194,
        "soil_humidity": 35.63333710612049
    },
    {
        "date": "2024-10-17 00:53:09.251267",
        "air_temp": 31.432737954209433,
        "humidity": 44.00933784060567,
        "rainfall": 2,
        "wind_speed": 0.9336963979854751,
        "soil_moisture": 15.178273387068236,
        "ndvi": 0.27811667592222006,
        "soil_temp": 15.22143321261811,
        "soil_humidity": 57.35059299111218
    },
    {
        "date": "2024-10-17 01:53:09.251267",
        "air_temp": 24.185303943464277,
        "humidity": 63.841943886656225,
        "rainfall": 0.5,
        "wind_speed": 15.590633636478659,
        "soil_moisture": 10.078964667387645,
        "ndvi": 0.24876746290453267,
        "soil_temp": 19.86089559241995,
        "soil_humidity": 42.489273584829405
    },
    {
        "date": "2024-10-17 02:53:09.251267",
        "air_temp": 22.155965306508836,
        "humidity": 71.50465634308317,
        "rainfall": 1,
        "wind_speed": 5.051720133694484,
        "soil_moisture": 23.527109592794446,
        "ndvi": 0.2125071438846205,
        "soil_temp": 10.23470976913099,
        "soil_humidity": 66.38154279653554
    },
    {
        "date": "2024-10-17 03:53:09.251267",
        "air_temp": 24.884248945006,
        "humidity": 81.43264530591446,
        "rainfall": 0.5,
        "wind_speed": 16.59287610705857,
        "soil_moisture": 20.57298194616525,
        "ndvi": 0.5772471354968427,
        "soil_temp": 10.965623252725594,
        "soil_humidity": 56.8404989937948
    },
    {
        "date": "2024-10-17 04:53:09.251267",
        "air_temp": 31.56498504874437,
        "humidity": 79.17347800246338,
        "rainfall": 0.5,
        "wind_speed": 14.078733340690308,
        "soil_moisture": 26.647295014762268,
        "ndvi": 0.5778035911159325,
        "soil_temp": 11.49491729096873,
        "soil_humidity": 55.82741300696963
    },
    {
        "date": "2024-10-17 05:53:09.251267",
        "air_temp": 21.704160947417456,
        "humidity": 53.85346248566998,
        "rainfall": 1,
        "wind_speed": 18.91347074101609,
        "soil_moisture": 15.641232777663573,
        "ndvi": 0.7921482285309274,
        "soil_temp": 16.284905279653465,
        "soil_humidity": 52.37608463592963
    },
    {
        "date": "2024-10-17 06:53:09.251267",
        "air_temp": 18.475231862714875,
        "humidity": 87.0440084779602,
        "rainfall": 2,
        "wind_speed": 8.178544715133441,
        "soil_moisture": 35.78996642999425,
        "ndvi": 0.4388259044624575,
        "soil_temp": 22.22194258989964,
        "soil_humidity": 31.831053577149024
    },
    {
        "date": "2024-10-17 07:53:09.251267",
        "air_temp": 29.240280406615614,
        "humidity": 46.21729207154352,
        "rainfall": 0.5,
        "wind_speed": 13.239396789012684,
        "soil_moisture": 17.732656468843288,
        "ndvi": 0.39914377093849807,
        "soil_temp": 23.32266865567574,
        "soil_humidity": 67.27689742742348
    },
    {
        "date": "2024-10-17 08:53:09.251267",
        "air_temp": 31.519564254161637,
        "humidity": 83.75668093105762,
        "rainfall": 2,
        "wind_speed": 9.297095616265414,
        "soil_moisture": 16.7928881526295,
        "ndvi": 0.5746549563106356,
        "soil_temp": 13.672161100913511,
        "soil_humidity": 55.1174997319784
    },
    {
        "date": "2024-10-17 09:53:09.251267",
        "air_temp": 17.012749570470667,
        "humidity": 88.54701248023093,
        "rainfall": 0.5,
        "wind_speed": 12.61177394617087,
        "soil_moisture": 24.42142169453068,
        "ndvi": 0.6953652035045564,
        "soil_temp": 18.61554586358521,
        "soil_humidity": 69.76247933532441
    },
    {
        "date": "2024-10-17 10:53:09.251267",
        "air_temp": 19.797482255118894,
        "humidity": 48.85767616113128,
        "rainfall": 1,
        "wind_speed": 8.991978120154746,
        "soil_moisture": 24.965530712328228,
        "ndvi": 0.7620643697138012,
        "soil_temp": 21.525474890389006,
        "soil_humidity": 51.49425619091548
    },
    {
        "date": "2024-10-17 11:53:09.251267",
        "air_temp": 17.839439795219878,
        "humidity": 76.10330321018927,
        "rainfall": 1,
        "wind_speed": 3.7726449284814967,
        "soil_moisture": 16.452556863552786,
        "ndvi": 0.6818744997074233,
        "soil_temp": 14.33244144879413,
        "soil_humidity": 58.404435676248525
    },
    {
        "date": "2024-10-17 12:53:09.251267",
        "air_temp": 21.95882823164333,
        "humidity": 42.01427593889992,
        "rainfall": 0,
        "wind_speed": 10.157948320067618,
        "soil_moisture": 18.15582180177747,
        "ndvi": 0.45600438836948287,
        "soil_temp": 16.1351160510441,
        "soil_humidity": 32.248382314648886
    },
    {
        "date": "2024-10-17 13:53:09.251267",
        "air_temp": 24.007019578108252,
        "humidity": 60.27114125695775,
        "rainfall": 1,
        "wind_speed": 10.571684028748846,
        "soil_moisture": 15.091157174657347,
        "ndvi": 0.5809485411872386,
        "soil_temp": 17.85961304028571,
        "soil_humidity": 55.26944707293644
    },
    {
        "date": "2024-10-17 14:53:09.251267",
        "air_temp": 29.976526739266127,
        "humidity": 65.8141649598837,
        "rainfall": 0,
        "wind_speed": 16.21207696520322,
        "soil_moisture": 31.553805349695004,
        "ndvi": 0.7137795960296702,
        "soil_temp": 12.023122696120716,
        "soil_humidity": 60.585984463614295
    }
]