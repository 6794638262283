import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useDeviceInstallationStationApi() {
  const callApi = useApiCall();




  const deviceInstallationStationList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.LIST, data, onSuccess, onError);
  };
  const deviceInstallationStationDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.DETAILS, data, onSuccess, onError);
  };
  const deviceInstallationStationSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.SAVE, data, onSuccess, onError);
  };
  const deviceInstallationStationUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.UPDATE, data, onSuccess, onError);
  };
  const deviceInstallationStationChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.CHANGE_STATUS, data, onSuccess, onError);
  };
  const deviceInstallationStationDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.DELETE, data, onSuccess, onError);
  };



  const getUnAssignedStationDevice = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.UN_ASSIGNED_STATION_DEVICE, data, onSuccess, onError);
  };
  const getAssignedStationDevice = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.ASSIGNED_STATION_DEVICE, data, onSuccess, onError);
  };
  const saveStationDevice = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.SAVE_STATION_DEVICE, data, onSuccess, onError);
  };


  
  const getStationCropAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.STATION_CROP_ALL, data, onSuccess, onError);
  };
  const getAssignedStationCrop = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.ASSIGNED_STATION_CROP, data, onSuccess, onError);
  };
  const saveStationCrop = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.SAVE_STATION_CROP, data, onSuccess, onError);
  };
  const saveStationImages = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.SAVE_STATION_IMAGES, data, onSuccess, onError);
  };


  return {
    deviceInstallationStationList,
    deviceInstallationStationDetails,
    deviceInstallationStationSave,
    deviceInstallationStationUpdate,
    deviceInstallationStationChangeStatus,
    deviceInstallationStationDelete,
    saveStationImages,
    getUnAssignedStationDevice,
    getAssignedStationDevice,
    saveStationDevice,

    getStationCropAll,
    getAssignedStationCrop,
    saveStationCrop,



  };
}
