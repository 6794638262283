import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { CiEdit } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import { useEventApi } from "../../_common/hooks/api/eventApiHook";
import { replaceUrlParams, showDate } from "../../_common/functions";
import { URLS } from "../../_config";
import TitleBar from "../../components/TopBar/TitleBar";
import parse from "html-react-parser";
import DetailsElement from "../../components/detailsElement";

const EventsDetails = () => {
  const urlParams: any = useParams();
  const eventID: any = urlParams.eventID;
  const eventApi = useEventApi();
  const [eventDetials, setEventdetails] = useState<any>(null);
  const [eventimage, setEventimages] = useState<string | "/images/no_image.jpg">("/images/no_image.jpg");

  const navigate: any = useNavigate();

  const getEventData = () => {
    eventApi.getEventDetails(
      {
        urlParams: { eventID: eventID },
      },
      (message: string, resp: any) => {
        //  console.log(resp);
        let eventdetails = resp.data?.event?.[0];
        setEventdetails(eventdetails);
        if (eventdetails.eventPicture) {
          setEventimages(eventdetails.eventPicture);
        }
      },
      (message: string) => { }
    );
  };

  useEffect(() => {
    if (eventID) {
      getEventData()
    }

  }, [eventID])

  return (
    <React.Fragment>
      <Header></Header>
      <LeftPanel></LeftPanel>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

            <TitleBar title={`Event Details`}
              breadcrumbs={{
                'Events': URLS.EVENTS_LIST,
                'Details': '#',
              }} />

            <div className="row">
              <div className="col-sm-3">
                <div className="white-box-card profile-card" data-aos="fade-up">
                  <div className="profile-avatar">
                    <img src={eventimage} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-sm-9">
                <div className="white-box-card mb-4 h-100 " data-aos="fade-up">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="card-heading mb-4">Event Details</h3>
                      <div className="row">
                        <div className="col-sm-6">
                          <DetailsElement title={'Event Name'} value={eventDetials?.eventName}></DetailsElement>
                        </div>

                        <div className="col-sm-6">
                          <DetailsElement title={'Event Type'} value={eventDetials?.eventTypeName}></DetailsElement>
                        </div>

                        <div className="col-sm-6">
                          <h5 className="xsm-text light-text mb-0">DATE</h5>
                          <p>{showDate(eventDetials?.eventDateTime, 'DD-MM-YYYY')}</p>
                        </div>
                        <div className="col-sm-6">
                          <h5 className="xsm-text light-text mb-0">TIME</h5>
                          <p>{showDate(eventDetials?.eventDateTime, "hh:mm A")}</p>
                        </div>
                        <div className="col-sm-6">
                          <h5 className="xsm-text light-text mb-0">LOCATION</h5>
                          <p>{eventDetials?.venueLocation}</p>
                        </div>
                        <div className="col-sm-6">
                          <DetailsElement title={'Organized By'} >
                            {
                              eventDetials?.eventOrganizerList && eventDetials?.eventOrganizerList.length > 0 ? eventDetials?.eventOrganizerList?.map((data: any) => (
                                <p>{data.organizerName}</p>
                              )) : <p>--</p>
                            }
                          </DetailsElement>

                        </div>
                        <div className="col-sm-6">
                          <DetailsElement title={'Special Highlights'} value={eventDetials?.specialHighlight}></DetailsElement>
                        </div>

                        <div className="col-sm-12">
                          <DetailsElement title={'DESCRIPTION'}>
                            <p>{eventDetials?.eventDescription ? parse(eventDetials?.eventDescription) : ''}</p>
                          </DetailsElement>
                        </div>

                      </div>

                      <hr />

                      <h3 className="card-heading mb-4">Event Area</h3>
                      <div className="row">


                        <div className="col-sm-6">
                          <DetailsElement title={'District Name'} >
                            {
                              eventDetials && eventDetials.length > 0 ? eventDetials?.eventBroadcastDistrictsList?.map((data: any) => (
                                <p>{data.districtName}</p>
                              )) : <p>--</p>
                            }
                          </DetailsElement>
                        </div>
                        <div className="col-sm-6">
                          <DetailsElement title={'Block Name'} >
                            {
                              eventDetials && eventDetials.length > 0 ? eventDetials?.eventBroadcastBlockList?.map((data: any) => (
                                <p>{data.blockName}</p>
                              )) : <p>--</p>
                            }
                          </DetailsElement>
                        </div>
                        <div className="col-sm-6">
                          <DetailsElement title={'GPU Name'} >
                            {
                              eventDetials && eventDetials.length > 0 ? eventDetials?.eventBroadcastGPUList?.map((data: any) => (
                                <p>{data.gpuName}</p>
                              )) : <p>--</p>
                            }
                          </DetailsElement>
                        </div>
                        <div className="col-sm-6">
                          <DetailsElement title={'Ward Name'} >
                            {
                              eventDetials && eventDetials.length > 0 ? eventDetials?.eventBroadcastWardList?.map((data: any) => (
                                <p>{data?.wardName}</p>
                              )) : <p>--</p>
                            }
                          </DetailsElement>
                        </div>

                      </div>
                    </div>
                  </div>
                  <button className="btn btn-primary d-flex gap-2 align-items-center rounded-pill-btn edit-btn">
                    <CiEdit onClick={() => {
                      navigate(replaceUrlParams(URLS.EVENTS_EDIT, { eventID: eventID }))
                    }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </React.Fragment>
  );
};

export default EventsDetails;
