import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import Footer from "../../../components/footer/Footer";
import ChartSoilTemperature from "./ChartSoilTemperature";
import ChartSoilMoisture from "./ChartSoilMoisture";
import ChartSoilSalinity from "./ChartSoilSalinity";
import ChartSoilPH from "./ChartSoilPH";
import ChartSoilEC from "./ChartSoilEC";



const IotInstallationStation = () => {
    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'IOT Installation Stations'} breadcrumbs={{
                            'Reports': '#',
                            'IOT Installation Stations': '#',
                        }} />

                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-4">
                                <div className="blue-box h-100">
                                    <div className="blue-box-head">
                                        <div className="icon-head">
                                            <img src="/images/w1.svg" alt="" />
                                        </div>
                                        <div className="head-name">
                                            <h2>Pakyong</h2>
                                            <h3>Mostly Clear</h3>
                                        </div>
                                        <div className="head-temp">23<sup>o</sup>C</div>
                                    </div>
                                    <p className="fetch-time">All Weather conditions fetched <span>10 mins</span> ago</p>
                                    <div className="blue-box-body">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="blue-small-box">
                                                    <h2><img src="/images/humidity.svg" alt="" />Humidity</h2>
                                                    <h3>60%</h3>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="blue-small-box">
                                                    <h2><img src="/images/rainfall.svg" alt="" />Rainfall</h2>
                                                    <h3>1.8</h3>
                                                    <h4>mm</h4>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="blue-small-box">
                                                    <h2><img src="/images/co2.png" alt="" />Co2 Emission</h2>
                                                    <h3>727</h3>
                                                    <h4>ppm</h4>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="blue-small-box mb-0">
                                                    <h2><img src="/images/wind-speed.png" alt="" />Wind Speed</h2>
                                                    <h3>19.75</h3>
                                                    <h4>Km/h</h4>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="blue-small-box mb-0">
                                                    <h2><img src="/images/wind-direction.png" alt="" />Wind Direction</h2>
                                                    <div className="demo-img"><img src="/images/wind-demo.png" alt="" /></div>
                                                    <h5>44.20<sup>o</sup>NE</h5>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="blue-small-box mb-0">
                                                    <h2><img src="/images/solar-radiation.png" alt="" />Solar Radiation</h2>
                                                    <h3>2.4</h3>
                                                    <h4>KWh/m<sup>2</sup></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-5">
                                <div className="dark-green-box">
                                    <h2>Pakyong Temperature &amp; Humidity</h2>
                                    <div className="demo-graph">
                                        <img src="/images/g1.jpg" alt="" />
                                    </div>
                                    <h2>Pakyong Wind Statistics</h2>
                                    <div className="demo-graph mb-0">
                                        <img src="/images/g2.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="white-form-box h-100 aos-init aos-animate" data-aos="fade-up">
                                    <div className="form-group float-label-wrap">
                                        <label htmlFor="" className="float-label">Select Weather Station</label>
                                        <select name="" id="" className="form-select">
                                            <option value="">Weather Station 1</option>
                                            <option value="" >Weather Station 2</option>
                                            <option value="" >Weather Station 3</option>
                                            <option value="" >Weather Station 3</option>
                                        </select>
                                    </div>
                                    <div className="btn-wrap">
                                        <button className="btn btn-blue w-100">Submit</button>
                                    </div>
                                    <div className="map-wrap">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3546.6146646853263!2d88.60985244068111!3d27.262612101968436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e6a5df1a0bb237%3A0x3790b73ba2b0adc9!2sAho%2C%20Sikkim%20737135!5e0!3m2!1sen!2sin!4v1702735809166!5m2!1sen!2sin" width="100%" height="100%" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="white-box mt-4">

                                    <div className="white-graph-box-head mb-3">
                                        <h2 className="box-title mb-0">Pakyong Land Images</h2>
                                        <span className="tip-btn right-bar-toggle"><img src="/images/tip-icon.png" alt="" /> Tips <FaArrowRight className="ms-2" /></span>
                                    </div>
                                    <div className="img-gal-wrap">
                                        <div className="img-scroll">
                                            <img src="/images/crops/c1.jpeg" alt="" />
                                            <img src="/images/crops/c2.jpeg" alt="" />
                                            <img src="/images/crops/c3.jpeg" alt="" />
                                            <img src="/images/crops/c4.jpeg" alt="" />
                                            <img src="/images/crops/c5.jpeg" alt="" />
                                            <img src="/images/crops/c6.jpeg" alt="" />
                                            <img src="/images/crops/c7.jpeg" alt="" />
                                            <img src="/images/crops/c8.jpeg" alt="" />
                                            <img src="/images/crops/c9.jpeg" alt="" />
                                            <img src="/images/crops/c10.jpeg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <ChartSoilTemperature />
                            </div>
                            <div className="col-sm-4">
                                <ChartSoilMoisture />
                            </div>
                            <div className="col-sm-4">
                                <ChartSoilSalinity />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <ChartSoilPH />
                            </div>
                            <div className="col-sm-6">
                                <ChartSoilEC />
                            </div>
                        </div>




                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default IotInstallationStation;