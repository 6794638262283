import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import TitleBar from "../../components/TopBar/TitleBar";
import { useReportApi } from "../../_common/hooks/api/reportApiHook";
import TableLoader from "../../components/tableLoader";
import Icon from "../../components/icon";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import toast from "react-hot-toast";
import SearchWithLocation from "../../components/search/SearchWithLocation";

const FarmersContactLists = () => {
    const reportAPI = useReportApi();
    const [farmers, setFarmers] = useState<any[] | null>(null);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;
    const [search, setSearch] = useState<string>('');
    const [searchDistricts, setSearchDistricts] = useState([]);
    const [searchBlocks, setSearchBlocks] = useState([]);
    const [searchGpus, setSearchGpus] = useState([]);
    const [searchWards, setSearchWards] = useState([]);

    const getFarmerList = () => {
        reportAPI.getFarmerContactList({
            PageIndex: page,
            PageSize: limit,
            SearchText: search,
            districtIDs: searchDistricts.join(),
            BlockIDs: searchBlocks.join(),
            WardIDs: searchWards.join(),
            GPUIDs: searchGpus.join()
        }, (message: any, resp: any) => {
            console.log('Farmers =>', resp.data.farmerRpt)
            setFarmers(resp.data.farmerRpt)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setFarmers([])
        })
    }

    const downloadFarmersExcel = async () => {
        reportAPI.getFarmerContactList({
            SearchText: search,
            districtIDs: searchDistricts.join(),
            BlockIDs: searchBlocks.join(),
            WardIDs: searchWards.join(),
            GPUIDs: searchGpus.join()
        }, (message: any, resp: any) => {
            exportReport(resp.data.farmerRpt)
        }, (message: any) => {
        })
    }

    const exportReport = (dataList: any) => {
        if (dataList) {
            let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let fileExt = '.xlsx';
            let data: any = [];
            dataList?.map((farm: any) => {
                data.push({
                    "Farmer Name": farm.farmerName,
                    "Phone Number": farm.phoneNumber,
                    "Email": farm.email,
                })
            })
            let ws: any = XLSX.utils.json_to_sheet(data)
            let wb: any = { Sheets: { 'Farmers': ws }, SheetNames: ['Farmers'] }
            let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            let _data: any = new Blob([excelBuffer], { type: fileType })
            FileSaver.saveAs(_data, 'FarmersContactList' + fileExt)
        } else {
            toast.error('Not enough data to export!!!')
        }
    }

    useEffect(() => {
        setFarmers(null)
        getFarmerList();
    }, [page, search, searchBlocks, searchWards, searchGpus])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Farmers Contact Report`}
                            breadcrumbs={{
                                'Reports': '#',
                                'Farmers Contact Report': '#',
                            }} />


                        <div className="events">

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                        <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                            <h2 className="card-heading mb-4">Farmers Contact Lists</h2>
                                            <div className="d-flex align-items-center gap-2 ">

                                                <div className="dropdown">
                                                    <button className="btn btn-outline-secondary dropdown-toggle" onClick={downloadFarmersExcel}  >
                                                        <Icon name="download" size={18} /> Export
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                        <SearchWithLocation
                                            setSearch={setSearch}
                                            setDistricts={setSearchDistricts}
                                            setBlocks={setSearchBlocks}
                                            setGpus={setSearchGpus}
                                            setWards={setSearchWards}
                                        />


                                        <div className="table-wrapper">
                                            <table className="table gen-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Phone no</th>
                                                        <th scope="col">Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        farmers?.map((data, index) => (
                                                            <tr key={`farmer-${data.farmerID}`}>
                                                                <td>{index + 1 + ((page - 1) * limit)}</td>
                                                                <td>{data?.farmerName}</td>
                                                                <td>{data?.phoneNumber ? data?.phoneNumber : '--'}</td>
                                                                <td>{data?.email ? data?.email : '--'}</td>
                                                            </tr>
                                                        ))
                                                    }


                                                </tbody>
                                            </table>
                                            <TableLoader data={farmers} />
                                        </div>
                                        {farmers ? <Pagination total={total} page={page} setPage={(n: number) => {
                                            setPage(n)
                                            setFarmers(null)
                                        }} pageLimit={limit} /> : null}
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default FarmersContactLists;