import React, { useEffect, useState } from "react";
import Search from "../../components/search/Search";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { GoPlus } from "react-icons/go";
import { useDeviceApi } from "../../_common/hooks/api/deviceApiHook";
import { Link } from "react-router-dom";
import Icon from "../../components/icon";
import { replaceUrlParams } from "../../_common/functions";
import { URLS } from "../../_config";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import TableLoader from "../../components/tableLoader";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import TitleBar from "../../components/TopBar/TitleBar";


const DeviceUnitList = () => {
    const user: any = useUser()
    const permissions: any = useUserPermissions();
    const deviceapi = useDeviceApi();

    const [devices, setDevices] = useState<any>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;

    const getAllDevicestypeUnit = () => {
        deviceapi.deviceUnitList({
            urlParams: {
                PageIndex: page,
                PageSize: limit,
                SearchText: search
            }
        }, (message: any, resp: any) => {
            setDevices(resp.data.deviceTemplateParam)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setDevices([])
            setTotal(0)
        })
    }

    const changeDeviceStatus = (devices: any) => {
        return new Promise((resolve, reject) => {
            deviceapi.changeDeviceStatus({
                "deviceTemplateID": devices.deviceTemplateID,
                "updatedBy": user?.userID,
                "workingStatus": (devices?.workingStatus == 'Enabled') ? 0 : 1,
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => {
                reject(message)
            })
        })
    }

    const devicesStatushandler = (devices: any) => {
        Swal.fire({
            title: 'Confirm Change Status',
            text: `Are you sure you want to change this device status?`,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, Change it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    changeDeviceStatus(devices), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        getAllDevicestypeUnit();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }

    const deleteDevice = (devices: any) => {
        return new Promise((resolve, reject) => {
            deviceapi.deleteDevice({
                "deviceTemplateID": devices.deviceTemplateID,
                "updatedBy": 1,
                "status": true
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => {
                reject(message)
            })
        })
    }

    const deleteDeviceshandler = (devices: any) => {
        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this Device?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteDevice(devices), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        getAllDevicestypeUnit();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }



    useEffect(() => {
        if (devices == null) {
            getAllDevicestypeUnit();
        }
    }, [devices])
    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'Device Category Unit'} breadcrumbs={{
                            'IoT Device': '#',
                            'Device Category Unit': '#',
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 aos-init aos-animate" data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Device Category List</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            <Search setSearch={(str: string) => {
                                                setSearch(str)
                                                setDevices(null)
                                            }}></Search>
                                            {permissions.menus['IoT Device'].write &&
                                                <Link to={URLS.DEVICE_CATEGORY_UNIT.ADD} className="btn btn-primary d-flex align-items-center gap-2 "><GoPlus /> Add New</Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Device Name</th>
                                                    <th scope="col">Sensor Key</th>
                                                    <th scope="col">Sensor Unit</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col" style={{ width: 150 }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    devices?.map((data: any, i: number) => (
                                                        <tr key={`device-${data.deviceTemplateID}`}>
                                                            <td>{i + 1}</td>
                                                            <td>{data.name}</td>
                                                            <td>{data.deviceType}</td>
                                                            <td>{data.description}</td>
                                                            <td>
                                                                {
                                                                    (data.isActive) ?
                                                                        <span className="badge text-success"><Icon name="checked" /> </span>
                                                                        :
                                                                        <span className="badge text-danger" ><Icon name="times" />  </span>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="action-btns">
                                                                    {permissions.menus['IoT Device'].write &&
                                                                        <Link to={replaceUrlParams(URLS.DEVICE_CATEGORY.EDIT, { deviceTemplateID: data.deviceTemplateID })}><Icon name="edit" size={18} /></Link>
                                                                    }

                                                                    {permissions.menus['IoT Device'].delete &&
                                                                        <Icon name="delete" size={18} onClick={() => { deleteDeviceshandler(data) }} />
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <TableLoader data={devices} />
                                    </div>
                                    {devices ? <Pagination total={total} page={page} setPage={(n: number) => {
                                        setPage(n)
                                        setDevices(null)
                                    }} pageLimit={limit} /> : null}

                                </div>

                            </div>
                        </div>


                    </div>
                </div>


                <Footer></Footer>
            </div >
        </React.Fragment >
    )
}

export default DeviceUnitList;