import * as Yup from "yup";
export const LoginvalidationSchema = Yup.object().shape({
  username: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string().required("Password is required")
});

export const ForgetpassvalidationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  resetPasswordURL: Yup.string().required("Reset pass url is required")

});

export const ResetnvalidationSchema = Yup.object().shape({
  newPassword: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters, 1 uppercase, 1 lowercase, 1 number & 1 special character.")
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&%|]).{8,32}$/,
      'Password must meet the criteria: at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character.'),
  confirmNewPassword: Yup.string().required("Confirm password is required").min(8, "Confirm Password must be at least 8 characters").oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
});

export const EmployeevalidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  adaharno: Yup.string().required("Adahar no is required"),
  father_name: Yup.string().required("Father / Husband Name is required"),
  phone: Yup.string().required("Phone no is required"),
  department: Yup.string().required("Department is required"),
  designation: Yup.string().required("Designation is required"),
  mode_appinment: Yup.string().required("Mode Of Appointment is required"),
  employee_id: Yup.string().required("Employee id is required"),
  qualification: Yup.string().required("Qualification is required"),
  personalqulification: Yup.string().required("Personal Qualification is required"),
  district: Yup.string().required("District is required"),
  gpu: Yup.string().required("Gpu is required"),
  ward: Yup.string().required("Ward is required"),



});

export const CropcategoryvalidationSchema = Yup.object().shape({

  cropCategoryName: Yup.string().required("Categroy is required")
});

export const ManualNotificationValidationSchema = Yup.object().shape({
  subject: Yup.string().required("Subject is required"),
  notificationMessage: Yup.string().required("Description is required"),
  // blockIDs: Yup.string().required("Blocks is required"),
  // gpuiDs: Yup.string().required("Gpus is required"),
  // wardIDs: Yup.string().required("Wards is required"),
});