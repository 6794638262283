import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";

import { URLS } from "../../_config";
import { Link, useParams } from "react-router-dom";
import { useEventApi } from "../../_common/hooks/api/eventApiHook";
import { getSelectedValue, replaceUrlParams, showDate } from "../../_common/functions";
import Icon from "../../components/icon";
import TitleBar from "../../components/TopBar/TitleBar";
import ImportExcelModal from "../../modals/importExcel.modal";
import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";
import TableLoader from "../../components/tableLoader";
import Pagination from "../../components/pagination/Pagination";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import toast from "react-hot-toast";


const EventAttendee = () => {
    const eventapi = useEventApi();
    const urlParams: any = useParams();
    const eventID: any = urlParams.eventID;
    const [events, setEvents] = useState<any[] | null>(null);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [importModal, setImportModal] = useState<boolean>(false);
    const limit = 20;


    const districts: any = useDistricts();
    const wards: any = useWards();
    const gpus: any = useGpus();
    const blocks: any = useBlocks();
    const [stateData, setStateData] = useState<boolean>(false);


    const uploadEventAttendee = (data: any) => {
        return new Promise((resolve, reject) => {
            eventapi.uploadEventAttendee({ eventFarmerList: data }, (message: any, resp: any) => { resolve(resp) }, reject)
        })
    }

    useEffect(() => {
        if (districts?.length > 0 && blocks?.length > 0 && gpus?.length > 0 && wards?.length > 0) {
            setStateData(true)
        }
    }, [districts, blocks, gpus, wards])

    const getEventFarmerImportAll = () => {
        eventapi.getEventFarmerImportAll(
            {
                urlParams: { EventID: eventID },

                PageIndex: page,
                PageSize: limit,
            }, (message: any, resp: any) => {
                console.log('Events =>', resp.data.event)
                setEvents(resp.data.event)
                setTotal(resp.totalRecords)
            }, (message: any) => {
                setEvents([])
            })
    }

    useEffect(() => {
        getEventFarmerImportAll()
    }, [page])

    const downloadFarmersExcel = async () => {
        eventapi.getEventFarmerImportAll({
            urlParams: { EventID: eventID },

            PageIndex: 1,
            PageSize: 100000,
        }, (message: any, resp: any) => {
            exportReport(resp.data.event)
        }, (message: any) => {
        })
    }

    const exportReport = (dataList: any) => {
        if (dataList) {
            let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let fileExt = '.xlsx';
            let data: any = [];
            dataList?.map((farm: any) => {
                data.push({
                    "Farmer Name": farm.farmerName,
                    "Farmer Code": farm.farmerCode,
                    "Contact Number": farm.contactNumber,
                    "Location": `${farm?.districtName}, ${farm?.blockName}, ${farm?.gpuName}, ${farm?.wardName}`,
                    "Kishan Parichay Patra": `${farm?.kishanParichayPatra}`                    
                })
            })
            let ws: any = XLSX.utils.json_to_sheet(data)
            let wb: any = { Sheets: { 'Attendee': ws }, SheetNames: ['Attendee'] }
            let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            let _data: any = new Blob([excelBuffer], { type: fileType })
            FileSaver.saveAs(_data, 'AttendeeList' + fileExt)
        } else {
            toast.error('Not enough data to export!!!')
        }
    }


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`Event Attendee`}
                            breadcrumbs={{
                                'Events': URLS.EVENTS_LIST,
                                'Event Attendee': '#',
                            }} />

                        <div className="events">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="white-box-card mb-4 h-100 " data-aos="fade-up">
                                        <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                            <h2 className="card-heading mb-0">Upload Attendee</h2>
                                            <div className="d-flex align-items-center gap-2 ">
                                                <Button onClick={() => { setImportModal(true) }} disabled={districts.length == 0 || blocks.length == 0 || gpus.length == 0 || wards.length == 0} ><Icon name="upload" size={18} /> Upload</Button>

                                                <button className="btn btn-outline-secondary" onClick={downloadFarmersExcel}  >
                                                    <Icon name="download" size={18} /> Export
                                                </button>
                                            </div>


                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table gen-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Farmer Name</th>
                                                        <th scope="col">Code</th>
                                                        <th scope="col">Contact Number</th>
                                                        <th scope="col">Aadhaar Number</th>
                                                        <th scope="col">Location</th> 
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        events?.map((data: any, index) => (
                                                            <tr key={`events${data?.eventFarmerID}`}>
                                                                <td>{index + 1 + ((page - 1) * limit)}</td>

                                                                <td>{data?.farmerName}</td>
                                                                <td>{data?.farmerCode}</td>
                                                                <td>{data?.contactNumber}</td>
                                                                <td>{data?.adharNumber}</td>
                                                                <td>{data?.districtName}, {data?.blockName}, {data?.gpuName}, {data?.wardName}</td>
 
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            <TableLoader data={events} />
                                        </div>
                                        {events ? <Pagination total={total} page={page} setPage={(n: number) => {
                                            setPage(n)
                                            setEvents(null)
                                        }} pageLimit={limit} /> : null}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {stateData && <ImportExcelModal
                    show={importModal}
                    onClose={setImportModal}
                    sampleName="attendee"
                    dataTypes={[
                        { title: 'Farmer Name', type: 'string', required: true, apiKey: 'farmerName' },
                        { title: 'Code', type: 'string', apiKey: 'farmerCode' },
                        { title: 'Contact Number', type: 'number', apiKey: 'contactNumber', min: 10, max: 10 },
                        { title: 'District Name', type: 'option', apiKey: 'districtName', options: districts.map((dist: any) => { return dist.districtName }) },
                        { title: 'Block Name', type: 'option', apiKey: 'blockName', relativeOptions: blocks.map((block: any) => { return { name: block.blockName, parent: getSelectedValue(block.fkDistrictID, 'districtId', districts).districtName } }), relWith: 'District Name' },
                        { title: 'Gpu Name', type: 'option', apiKey: 'gpuName', relativeOptions: gpus.map((gpu: any) => { return { name: gpu.cpuName, parent: getSelectedValue(gpu.fkBlockID, 'blockID', blocks).blockName } }), relWith: 'Block Name' },
                        { title: 'Ward Name', type: 'option', apiKey: 'wardName', relativeOptions: wards.map((ward: any) => { return { name: ward.wardName, parent: getSelectedValue(ward.fkgpuid, 'gpiid', gpus).cpuName } }), relWith: 'Gpu Name' },
                        { title: 'Aadhaar Number', type: 'number', apiKey: 'adharNumber', min: 12, max: 12, required: true },
                        { title: 'Kishan Parichay Patra', type: 'string', apiKey: 'kishanParichayPatra' },
                    ]}
                    upload={uploadEventAttendee}
                    params={{
                        fkEventID: eventID
                    }}
                />}


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default EventAttendee;