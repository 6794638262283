import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { useMasterApi } from "../../_common/hooks/api/masterApiHook";
import { useGroupApi } from "../../_common/hooks/api/groupApiHook";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Error from "../../components/formFieldError";
import { useEmployeeApi } from "../../_common/hooks/api/employeeApiHook";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URLS } from "../../_config";
import { useUser } from "../../_common/hooks/authHook";
import { getRelativeListWithMultiSelected, getSelectedValue } from "../../_common/functions";
import Loader from "../../components/loader";
import TitleBar from "../../components/TopBar/TitleBar";
import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";

const TrainingGroupAdd = () => {
    const user: any = useUser();
    const masterApi = useMasterApi();
    const employeeApi = useEmployeeApi();
    const groupApi = useGroupApi();
    const navigate: any = useNavigate();
    const params: any = useParams();

    const { control, formState: { errors }, setValue, handleSubmit, watch, reset } = useForm();

    
    const districts:any = useDistricts();
    const wards:any = useWards();
    const gpus:any = useGpus();
    const blocks:any = useBlocks();
    const [stateData, setStateData] = useState<boolean>(false);

    const [employees, setEmployees] = useState<any[] | null>(null)
    const [groupID, setGroupID] = useState<any>(params.groupID)
    const [dataReset, setDataReset] = useState<boolean>(false)

    const [filteredBlocks, setFilteredBlocks] = useState<any[]>([])
    const [filteredGpus, setFilteredGpus] = useState<any[]>([])
    const [filteredWards, setFilteredWards] = useState<any[]>([])

    const districtWatch = watch('district');
    const blockWatch = watch('block');
    const gpuWatch = watch('gpu');
    const wardWatch = watch('ward');


    const getAllEmployees = () => {
        employeeApi.getAllActiveEmployee({ PageIndex: 1, PageSize: 999 }, (message: any, resp: any) => {
            setEmployees(resp.data.employeeList.map((emp: any) => {
                emp.label = emp.displayName
                emp.value = emp.userID
                return emp;
            }))
        }, (message: any) => { })
    }

    const saveGroup = (data: any) => {
        return new Promise((resolve, reject) => {
            let params: any = {
                groupName: data.name,
                refGroupID: data.id,
                description: '',
                addedBy: user.userID,
                groupDistrictsList: (data?.district) ? data?.district?.map((data: any) => {
                    return { districtId: data.districtId }
                }) : [],
                groupBlockList: (data?.block) ? data?.block?.map((data: any) => {
                    return { blockID: data.blockID }
                }) : [],
                groupGPUList: (data?.gpu) ? data?.gpu?.map((data: any) => {
                    return { gpuid: data.gpiid }
                }) : [],
                groupWardList: (data?.ward) ? data?.ward?.map((data: any) => {
                    return { wardID: data.wardID }
                }) : [],
                groupUsersList: data?.employee?.map((data: any) => {
                    return { userID: data.userID }
                })
            }

            if (groupID) {
                params.groupID = groupID;
                groupApi.updateGroup(params, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })
            } else {
                groupApi.saveGroup(params, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })
            }
        })

    }

    const saveGroupHandler = (data: any) => {
        toast.promise(
            saveGroup(data), {
            loading: groupID ? 'Updating...' : 'Adding...',
            success: (msg: any) => {
                navigate(URLS.TRAINING_GROUP)
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const getGruopDetails = () => {
        groupApi.getGruopDetails({ urlParams: { GroupID: groupID } }, (message: any, resp: any) => {
            let group: any = resp.data.group[0];
            let resetValues = {
                id: group.refGroupID,
                name: group.groupName,
                district: getSelectedValue(group.groupDistrictsList.map((dist: any) => { return dist.districtId }), 'value', districts ? districts : []),
                block: getSelectedValue(group.groupBlockList.map((dist: any) => { return dist.blockID }), 'value', blocks ? blocks : []),
                gpu: getSelectedValue(group.groupGPUList.map((dist: any) => { return dist.gpuid }), 'value', gpus ? gpus : []),
                ward: getSelectedValue(group.groupWardList.map((dist: any) => { return dist.wardID }), 'value', wards ? wards : []),
                employee: getSelectedValue(group.groupUsersList.map((dist: any) => { return dist.userID }), 'value', employees ? employees : []),
            }
            reset(resetValues);
            setDataReset(true)

        }, (message: any) => {
            //----
        })

    }

    useEffect(() => {
        if (groupID && districts && wards && blocks && gpus && employees) {
            setStateData(true)
            getGruopDetails()
        }
    }, [groupID, districts, wards, blocks, gpus, employees])


    useEffect(() => {
        getAllEmployees()
    }, [])


    useEffect(() => {
        let { list, selected } = getRelativeListWithMultiSelected(districtWatch, blocks, blockWatch, 'districtId', 'fkDistrictID', 'blockID')
        setFilteredBlocks(list);
        setValue('block', selected)
    }, [districtWatch])

    useEffect(() => {
        let { list, selected } = getRelativeListWithMultiSelected(blockWatch, gpus, gpuWatch, 'blockID', 'fkBlockID', 'gpiid')
        setFilteredGpus(list);
        setValue('gpu', selected)
    }, [blockWatch])

    useEffect(() => {
        let { list, selected } = getRelativeListWithMultiSelected(gpuWatch, wards, wardWatch, 'gpiid', 'fkgpuid', 'wardID')
        setFilteredWards(list);
        setValue('ward', selected)
    }, [gpuWatch])

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`${groupID ? 'Edit' : 'Add'} Training Group`} breadcrumbs={{
                            'List': URLS.TRAINING_GROUP,
                            [groupID ? 'Edit' : 'Add']: '#'
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " data-aos="fade-up">

                                    {groupID && !dataReset ? <Loader /> :

                                        <form className="row" onSubmit={handleSubmit(saveGroupHandler)}>
                                            <div className="col-sm-12">
                                                <h3 className="card-heading mb-4 ">  Training Group</h3>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="mb-3">
                                                            <label>Group ID<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="id"
                                                                rules={{ required: 'Group ID is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control"
                                                                        placeholder="Enter Group ID" />
                                                                )}
                                                            />
                                                            <Error error={errors['id']} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="mb-3">
                                                            <label>Group Name<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="name"
                                                                rules={{ required: 'Group Name is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control"
                                                                        placeholder="Enter Group Name" />
                                                                )}
                                                            />
                                                            <Error error={errors['name']} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="mb-3">
                                                            <label>Choose Group Members</label>

                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="employee"
                                                                rules={{ required: 'Employee is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={employees ? employees : []}
                                                                        value={value}
                                                                        isMulti={true}
                                                                    />
                                                                )}
                                                            />
                                                            <Error error={errors['employee']} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 mb-3 ">
                                                        <h3 className="card-heading mb-4">Location</h3>
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <label>District</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="district"
                                                                    // rules={{ required: 'District is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={districts ? districts : []}
                                                                            value={value}
                                                                            isMulti={true}
                                                                        />
                                                                    )}
                                                                />
                                                                <Error error={errors['district']} />
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <label>Block</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="block"
                                                                    // rules={{ required: 'Block is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={filteredBlocks ? filteredBlocks : []}
                                                                            value={value}
                                                                            isMulti={true}
                                                                        />
                                                                    )}
                                                                />
                                                                <Error error={errors['block']} />
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <label>GPU</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="gpu"
                                                                    // rules={{ required: 'GPU ID is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={filteredGpus ? filteredGpus : []}
                                                                            value={value}
                                                                            isMulti={true}
                                                                        />
                                                                    )}
                                                                />
                                                                <Error error={errors['gpu']} />
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <label>Ward</label>
                                                                <Controller
                                                                    defaultValue={""}
                                                                    control={control}
                                                                    name="ward"
                                                                    // rules={{ required: 'Ward ID is required' }}
                                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                        <Select
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            options={filteredWards ? filteredWards : []}
                                                                            value={value}
                                                                            isMulti={true}
                                                                        />
                                                                    )}
                                                                />
                                                                <Error error={errors['ward']} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                            <div className="mt-3 d-flex gap-2 justify-content-end ">

                                                <Link to={URLS.TRAINING_GROUP} className="btn btn-default">Cancel</Link>
                                                <button type="submit" className="btn btn-primary ">{groupID ? 'Update' : 'Save'}</button>
                                            </div>
                                        </form>
                                    }



                                </div>

                            </div>
                        </div>


                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default TrainingGroupAdd;


/* 


{
  "groupName": "Qwerty",
  "refGroupID": "123",
  "description": "",
  "addedBy": 1,
  "groupDistrictsList": [
    {
      "districtId": 1
    },
    {
      "districtId": 2
    }
  ],
  "groupBlockList": [
    {
      "blockID": 2
    },
    {
      "blockID": 1
    }
  ],
  "groupGPUList": [
    {
      "gpuid": 1
    },
    {
      "gpuid": 2
    }
  ],
  "groupWardList": [
    {
      "wardID": 1
    },
    {
      "wardID": 2
    }
  ],
  "groupUsersList": [
    {
      "userID": 1
    }
  ],
  "groupID": 1
}

*/