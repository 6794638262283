import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';



export function* deviceInstallationStationList(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.LIST, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceInstallationStationDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.DETAILS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceInstallationStationSave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.SAVE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceInstallationStationUpdate(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceInstallationStationChangeStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.CHANGE_STATUS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceInstallationStationDelete(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.DELETE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.DELETE, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}




export function* getUnAssignedStationDevice(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.UN_ASSIGNED_STATION_DEVICE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getAssignedStationDevice(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.ASSIGNED_STATION_DEVICE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* saveStationDevice(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.SAVE_STATION_DEVICE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}




export function* getStationCropAll(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.STATION_CROP_ALL, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getAssignedStationCrop(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.ASSIGNED_STATION_CROP, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* saveStationCrop(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.SAVE_STATION_CROP, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* saveStationImages(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE_INSTALLATION_STATION.SAVE_STATION_IMAGES, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
