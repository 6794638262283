import React , { useEffect, useState } from "react";
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import Button from 'react-bootstrap/Button';
import { LuPlus } from "react-icons/lu";
import { Controller, useForm } from "react-hook-form";
import { CiEdit } from "react-icons/ci";
import { IoCameraOutline } from "react-icons/io5";
import { useUserApi } from "../../_common/hooks/api/userApiHook";
import { useNavigate,Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { yupResolver } from "@hookform/resolvers/yup";
import {LoginFormValues} from "../../_common/form-props";
import {LoginvalidationSchema} from "../../_common/form-validation";
import { URLS } from "../../_config";



const Logout = () => {

    const userApi = useUserApi();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const {showLoader,hideLoader} = useAppLoader()

  const logout = () => {
    userApi.logout({ }, (msg: any, resp: any) => {
        navigate(URLS.LOGIN);
    }, (msg: any) => { });
}
useEffect(()=>{
    logout();

},[])

    return (
        <React.Fragment>
          
        </React.Fragment>
    )
}

export default Logout;