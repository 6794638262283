import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';
// const API_BASE_URL = process.env.REACT_APP_API_URL;




export function* getAllDevice(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.GET_DEVICES, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getAllDeviceWorkingStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DEVICES_WORKING_STATUS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            if (resp.data.data.deviceWorkingStatus) {
                resp.data.data.deviceWorkingStatus.map((data: any) => {

                    data.label = data.deviceWorkingStatusName;
                    data.value = data.deviceWorkingStatusID;
                    return data;
                })
            }

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getDeviceDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.GET_DEVICES_DETAILS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateDevice(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.UPDATE_DEVICES, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* saveDevice(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.SAVE_DEVICES, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* changeDeviceStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.CHANGE_DEVICES_STATUS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteDevice(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DELETE_DEVICES, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.DELETE, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



//=============== Device Type Section ======================//
export function* getAllDevicetype(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.GET_DEVICES_TEPLATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            if (resp.data.data.deviceTemplate) {
                resp.data.data.deviceTemplate.map((data: any) => {

                    data.label = data.deviceName;
                    data.value = data.deviceTemplateID;
                    return data;
                })
            }

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceCategoryDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DEVICES_TEMPLATE_DETAILS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceCategorySave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DEVICES_TEMPLATE_SAVE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceCategoryUpdate(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DEVICES_TEMPLATE_UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceCategoryChangeStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DEVICES_TEMPLATE_CHANGE_STATUS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deviceCategoryDelete(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DEVICES_TEMPLATE_DELETE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.DELETE, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deviceParamTypeAll(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DEVICES_PARAM_TYPE_ALL, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        } 
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deviceUnitMasterAll(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DEVICES_UNIT_MASTER_ALL, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        } 
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deviceParamMasterAll(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.DEVICES_PARAM_MASTER_ALL, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        } 
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



//=============== Device Type Unit Section ======================//

export function* deviceUnitList(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.CATEGORY_UNIT.LIST, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceUnitDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.CATEGORY_UNIT.DETAILS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceUnitSave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.CATEGORY_UNIT.SAVE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceUnitUpdate(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.CATEGORY_UNIT.UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceUnitChangeStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.CATEGORY_UNIT.CHANGE_STATUS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deviceUnitDelete(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.DEVICE.CATEGORY_UNIT.DELETE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.DELETE, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}




