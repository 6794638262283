import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import "./UserDropdown.scss";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useUserApi } from "../../_common/hooks/api/userApiHook";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../_common/hooks/authHook";
import { Link } from "react-router-dom";
import { URLS } from "../../_config";
const UserDropdown = () => {
    const userApi = useUserApi();
    const navigate= useNavigate();
    let userdata: any = useUser();
    let userimage: any = '/images/users/no-image.jpg';
    if (userdata?.avatar) {
        userimage = userdata?.avatar;
    }


    const logout = () => {
        userApi.logout({ }, (msg: any, resp: any) => {
            navigate(URLS.LOGIN);
        }, (msg: any) => { });
    }
    const navigateprofile = () =>{
        navigate(URLS.PROFILE);

    }


    return (
        <Dropdown>
            <Dropdown.Toggle variant="outline-default" id="dropdown-basic">
                <div className="user-avatar"><img className="rounded-circle header-profile-user" src={userimage} alt="Header Avatar" /></div>
                {userdata?.displayName}
                <MdKeyboardArrowDown />
            </Dropdown.Toggle>

            <Dropdown.Menu>
          <Dropdown.Item onClick={navigateprofile} >Profile</Dropdown.Item>
                <Dropdown.Item href="#" onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default UserDropdown;