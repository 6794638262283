import { useEffect, useState } from "react"
import { useBlocks, useDistricts, useGpus, useWards } from "../_common/hooks/masterHook";
import { useMasterApi } from "../_common/hooks/api/masterApiHook";


export default function LoadStateData() {
    const districts:any = useDistricts();
    const wards:any = useWards();
    const gpus:any = useGpus();
    const blocks:any = useBlocks();

    const masterApi = useMasterApi();

    const getDistrict = () => {
        masterApi.getAllDistrict({ urlParams: { PageIndex: 1, PageSize: 999 } },(message: any, resp: any) => { }, (message: any) => { });
    };

    const getBlocks = () => {
        masterApi.getAllBlocks({ urlParams: { PageIndex: 1, PageSize: 999 } }, (message: any, resp: any) => { }, (message: any) => { });
    };
    const getGpus = () => {
        masterApi.getAllGpu({ urlParams: { PageIndex: 1, PageSize: 999 }, }, (message: any, resp: any) => { }, (message: any) => { });
    };

    const getwards = () => {
        masterApi.getAllward({ urlParams: { PageIndex: 1, PageSize: 999 }, }, (message: any, resp: any) => { }, (message: any) => { });
    };

    useEffect(()=>{
        if(districts == null){getDistrict()}
        if(blocks == null){getBlocks()}
        if(gpus == null){getGpus()}
        if(wards == null){getwards()}
    },[districts,blocks,gpus,wards])


    return (null)
}



