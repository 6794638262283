import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Loader from '../components/loader';
import { Controller, useForm } from 'react-hook-form';
import FormTextInput from '../_common/form-elements/textinput/formTextInput';
import Error from '../components/formFieldError';
import { useUserApi } from '../_common/hooks/api/userApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import toast from 'react-hot-toast';
import { useUser } from '../_common/hooks/authHook';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../_config';


interface props {
  show: boolean,
  data: any,
  onClose: (status: boolean) => void
}

export default function ResetPasswordModal({ show, onClose }: props) {
  const userApi = useUserApi();
  const user: any = useUser();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader()
  const {
    control,
    reset,
    formState: { errors },
    handleSubmit, watch
  } = useForm();
  const handleClose = () => {
    onClose(false);
    reset();
  };

  const ForgetpassHandler = (data: any) => {
    showLoader();
    userApi.restprofilePassword({
      newPassword: data.newPassword,
      oldPassword: data.oldPassword,
      confirmNewPassword: data.confirmNewPassword,
      userID: user.userID,
      email: user.Email
    }, (msg: any, resp: any) => {
      hideLoader();
      toast.success(msg);
      //  logout();
      navigate(URLS.LOGOUT);
      onClose(false);

    }, (msg: any) => {
      hideLoader();
      toast.error(msg);

    })

  }


  return (
    <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <form autoComplete="off" onSubmit={handleSubmit(ForgetpassHandler)}>
        <Modal.Body>

          <div className="row">
            <div className="col-sm-12">
              <div className="mb-3">
                <label>Old Password<sup className="text-danger">*</sup></label>
                <Controller
                  defaultValue={""}
                  control={control}
                  rules={{
                    required: 'Old Password is required', minLength: { value: 8, message: "Old password must be 8 charecter" },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
                      message: 'Password must meet the criteria: at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character.'
                    }
                  }}
                  name="oldPassword"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <FormTextInput
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      type="password"
                      placeholder="Enter old Password"

                    />
                  )}
                />

                <Error error={errors['oldPassword']} />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="mb-3">
                <label>New Password<sup className="text-danger">*</sup></label>
                <Controller
                  defaultValue={""}
                  control={control}
                  rules={{
                    required: 'New Password is required', minLength: { value: 8, message: "New password must be 8 charecter" },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
                      message: 'Password must meet the criteria: at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character.'
                    }
                  }}
                  name="newPassword"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <FormTextInput
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      type="password"
                      placeholder="Enter New Password"

                    />
                  )}
                />

                <Error error={errors['newPassword']} />
              </div>
            </div>



            <div className="col-sm-12">
              <div className="mb-3">
                <label>Confirm New Password<sup className="text-danger">*</sup></label>
                <Controller
                  defaultValue={""}
                  control={control}
                  rules={{
                    validate: (value) => value === watch("newPassword") || 'Passwords and Confirm password do not match'
                  }}
                  name="confirmNewPassword"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <FormTextInput
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      type="password"
                      placeholder="Enter confirm new Password"

                    />
                  )}
                />

                <Error error={errors['confirmNewPassword']} />
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Reset Password</Button>
          <Button variant="secondary" onClick={handleClose}> Close </Button>

        </Modal.Footer>
      </form>
    </Modal>
  )
}