import React from "react";
import './OverlayLoader.scss';

interface props {
    chartsLoading: boolean
}

const OverlayLoader = ({ chartsLoading }: props) => {
    return (
        <>
            {chartsLoading &&
                <div className='overlay-loader'>
                    <div className="spinner-border text-dark font-normal" role="status"></div>
                </div>
            }
        </>
    )
}

export default OverlayLoader;