import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Footer from "../../../components/footer/Footer";
import FormTextInput from "../../../_common/form-elements/textinput/formTextInput";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import { CropcatFormValues } from "../../../_common/form-props";
import { CropcategoryvalidationSchema } from "../../../_common/form-validation";
import toast from "react-hot-toast";
import { URLS } from "../../../_config";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import { useUser } from "../../../_common/hooks/authHook";
import TitleBar from "../../../components/TopBar/TitleBar";
import Loader from "../../../components/loader";
import Error from "../../../components/formFieldError";

const CropCategoryForm = () => {
    const params: any = useParams();
    const navigate: any = useNavigate();
    const [dataID, setDataID] = useState<any>(params.id)
    const [dataReset, setDataReset] = useState<boolean>(false)
    const collateralApi = useCollatralApi()
    const user: any = useUser()

    const { control, formState: { errors }, handleSubmit, reset } = useForm<CropcatFormValues>({
        resolver: yupResolver(CropcategoryvalidationSchema),
        defaultValues: {
            cropCategoryName: "",
        },
    });


    const getCropCategoryDetails = () => {
        collateralApi.cropCategoryDetails({ urlParams: { CropCategoryID: dataID } }, (message: any, resp: any) => {
            let dataDetail: any = resp.data.cropCategory[0];
            let resetValues: any = {
                cropCategoryName: dataDetail.cropCategoryName,
            }
            console.log(resetValues);
            reset(resetValues);
            setDataReset(true)

        }, (message: any) => {
            //----
        })

    }
    useEffect(() => {
        if (dataID) {
            getCropCategoryDetails()
        }
    }, [dataID])

    const saveData = (data: any) => {
        return new Promise((resolve, reject) => {
            let params: any = {
                "cropCategoryName": data.cropCategoryName,
                "addedBy": user?.userID,
                "status": true,
                "isActive": true,
            }
            if (dataID) {
                params.cropCategoryID = dataID;
                collateralApi.cropCategoryUpdate(params, resolve, reject)
            } else {
                collateralApi.cropCategorySave(params, resolve, reject)
            }
        })
    }

    const saveCropCategoryHandler = (data: any) => {
        toast.promise(
            saveData(data), {
            loading: dataID ? 'Updating...' : 'Adding...',
            success: (msg: any) => {
                navigate(URLS.COLLATERAL.CROP_CATEGORY.LIST)
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`${dataID ? 'Edit' : 'Add'} Crop Category`}
                            breadcrumbs={{
                                'Collateral': '#',
                                'Crop Category': URLS.COLLATERAL.CROP_CATEGORY.LIST,
                                [dataID ? 'Edit' : 'Add']: '#',
                            }} />

                        {dataID && !dataReset ? <Loader /> :
                            <form className="row" onSubmit={handleSubmit(saveCropCategoryHandler)}>

                                <div className="row" data-aos="fade-up">
                                    <div className="col-sm-12">
                                        <div className="white-box-card mb-4 ">

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="mb-2">
                                                        <label> Category Name<sup className="text-danger">*</sup></label>
                                                        <Controller
                                                            defaultValue={""}
                                                            control={control}
                                                            name="cropCategoryName"
                                                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                <FormTextInput
                                                                    name={name}
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    value={value}
                                                                    inputRef={ref}
                                                                    type="text"

                                                                    placeholder="Enter Category Name"
                                                                    error={errors.cropCategoryName} />
                                                            )} />
                                                             <Error error={errors["cropCategoryName"]} />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">

                                                    <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                        <Link className="btn btn-default" to={URLS.COLLATERAL.CROP_CATEGORY.LIST}>Cancel</Link>
                                                        <button className="btn btn-primary ">{(dataID) ? 'Update' : 'Save'}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default CropCategoryForm;