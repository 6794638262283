import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { GoPlus } from "react-icons/go";
import { URLS } from "../../_config";
import { Link, useNavigate } from "react-router-dom";
import { useEventApi } from "../../_common/hooks/api/eventApiHook";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { replaceUrlParams, showDate } from "../../_common/functions";
import Icon from "../../components/icon";
import TableLoader from "../../components/tableLoader";
import { Form } from "react-bootstrap";
import TitleBar from "../../components/TopBar/TitleBar";
import Status from "../../components/status";
import moment from "moment";
import SearchWithLocation from "../../components/search/SearchWithLocation";


const EventsList = () => {
    const eventapi = useEventApi();
    const [events, setEvents] = useState<any[] | null>(null);
    const [search, setSearch] = useState<string>('');
    const [eventTypeID, setEventTypeID] = useState<any>(null);
    const [eventStatus, setEventStatus] = useState<any>(null);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;

    const navigate = useNavigate();

    const [eventtypes, setEventtype] = useState<any[]>([]);

    const [searchDistricts, setSearchDistricts] = useState([]);
    const [searchBlocks, setSearchBlocks] = useState([]);
    const [searchGpus, setSearchGpus] = useState([]);
    const [searchWards, setSearchWards] = useState([]);

    const evenStatus: any = [{
        "value": 'upcoming',
        "label": "Upcoming",
    },
    {
        "value": 'completed',
        "label": "Completed",
    }
    ];
    const getEvents = () => {
        eventapi.getAllEvents({
            PageIndex: page,
            PageSize: limit,
            SearchText: search,
            districtIDs: searchDistricts.join(),
            BlockIDs: searchBlocks.join(),
            WardIDs: searchWards.join(),
            GPUIDs: searchGpus.join(),
            eventTypeID: eventTypeID,
            eventStatus: eventStatus,
            utcTime: moment().utc().format('YYYY-MM-DD HH:mm:ss')
            // utcTime: showDate(new Date(), 'YYYY-MM-DD HH:mm:ss')
        }, (message: any, resp: any) => {
            console.log('Events =>', resp.data.event)
            setEvents(resp.data.event)
            setTotal(resp?.totalRecords)
        }, (message: any) => {
            setEvents([])
        })
    }
    const getEvettype = () => {
        eventapi.getAllEventtypes(
            {},
            (message: any, resp: any) => {
                setEventtype(resp.data.eventType);
            },
            (message: any) => {
                setEventtype([]);
            }
        );
    };

    const deleteEvent = (event: any) => {
        return new Promise((resolve, reject) => {
            eventapi.deleteEvent({
                "eventID": event.eventID,
                "deletedBy": 1,
                "status": true
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => {
                reject(message)
            })
        })
    }

    const deleteEventhandler = (event: any) => {
        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this event?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteEvent(event), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        getEvents();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }
    const changeStatus = (status: boolean, event: any) => {
        return new Promise((resolve, reject) => {
            eventapi.eventChangeStatus({
                "eventID": event.eventID,
                "updatedBy": 1,
                "status": status
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => { reject(message) })

        })

    }
    const changeStatusHandler = (el: any, event: any) => {
        let status = el.checked;
        Swal.fire({
            title: 'Confirm Status Change',
            text: `Are you sure you want to ${status ? 'active' : 'inactive'} this event?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${status ? 'Active' : 'Inactive'} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    changeStatus(status, event), {
                    loading: 'Status changing...',
                    success: (msg: any) => {
                        getEvents();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            } else {
                el.checked = !el.checked;

            }
        });
    }

    useEffect(() => {
        getEvents();
    }, [searchBlocks, searchWards, searchGpus])

    useEffect(() => {
        if (events == null) {
            getEvents();
        }
    }, [events])

    useEffect(() => {
        getEvettype();
    }, [])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`Events`}
                            breadcrumbs={{
                                'Events': '#',
                            }} />

                        <div className="events">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="white-box-card mb-4 h-100 " data-aos="fade-up">
                                        <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                            <h2 className="card-heading mb-4">Event List</h2>
                                            <div className="d-flex align-items-center gap-2 ">
                                                <Link to={URLS.EVENTS_ADD} className="btn btn-primary d-flex align-items-center gap-2 " > <GoPlus /> Add New</Link>
                                            </div>
                                        </div>


                                        <SearchWithLocation
                                            pageName="events"
                                            setSearch={setSearch}
                                            setDistricts={setSearchDistricts}
                                            setBlocks={setSearchBlocks}
                                            setGpus={setSearchGpus}
                                            setWards={setSearchWards}

                                            eventtypes={eventtypes}
                                            evenStatus={evenStatus}
                                            setEventTypeID={setEventTypeID}
                                            setEventStatus={setEventStatus}

                                        />


                                        <div className="table-wrapper">
                                            <table className="table gen-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Event Time</th>
                                                        <th scope="col">Location</th>
                                                        {/* <th scope="col">Organized By</th> */}
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        events?.map(data => (
                                                            <tr key={data.eventID}>
                                                                <td>
                                                                    <div className="table-pic">
                                                                        <Link to={replaceUrlParams(URLS.EVENTS_DETAILS, { eventID: data.eventID })}> <img src={data.eventPicture} alt="" /></Link>
                                                                    </div>
                                                                </td>
                                                                <td>{data.eventName}</td>
                                                                <td>{data.eventTypeName}</td>
                                                                <td>{showDate(data.eventDateTime, 'DD-MM-YYYY')} {showDate(data.eventDateTime, "hh:mm A")}</td>
                                                                {/* <td>{utcToLocalDateTime(data.eventDateTime)} - {utcToLocalDateTime(data.eventDateTime, "hh:mm A")}</td> */}
                                                                <td>{data.venueLocation}</td>
                                                                {/* <td>{data.specialHighlight}</td> */}
                                                                <td>{(new Date(showDate(data.eventDateTime, "DD MMM, YYYY hh:mm A")) > new Date()) ?
                                                                    <Status status="Upcoming" />
                                                                    :
                                                                    <Status status="Completed" />
                                                                }
                                                                </td>
                                                                <td>
                                                                    <div className="action-btns">
                                                                        <Form.Check // prettier-ignore
                                                                            type="switch"
                                                                            id={`status-switch-${data.eventID}`}
                                                                            label=""
                                                                            onChange={(e) => {
                                                                                changeStatusHandler(e.target, data)
                                                                            }}
                                                                            defaultChecked={data.isActive}
                                                                        />
                                                                        <Link to={replaceUrlParams(URLS.EVENTS_EDIT, { eventID: data.eventID })}><Icon name="edit" size={18} /></Link>

                                                                        <Link to={replaceUrlParams(URLS.EVENTS_DETAILS, { eventID: data.eventID })}><Icon name="view" size={18} /></Link>
                                                                        <Icon name="delete" size={18} onClick={() => { deleteEventhandler(data) }} />
                                                                        <Icon name="add-person" size={18} onClick={() => { navigate(replaceUrlParams(URLS.EVENT_ATTENDEE, { eventID: data.eventID })) }} />

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }




                                                </tbody>
                                            </table>
                                            <TableLoader data={events} />
                                        </div>
                                        {events ? <Pagination total={total} page={page} setPage={(n: number) => {
                                            setPage(n)
                                            setEvents(null)
                                        }} pageLimit={limit} /> : null}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default EventsList;