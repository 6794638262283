import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import TitleBar from "../../components/TopBar/TitleBar";
import { useFarmsApi } from "../../_common/hooks/api/farmApiHook";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import TableLoader from "../../components/tableLoader";
import { Link } from "react-router-dom";
import { getSelectedValue, replaceUrlParams } from "../../_common/functions";
import { URLS } from "../../_config";
import Icon from "../../components/icon";
import Status from "../../components/status";
import { Button, Form } from "react-bootstrap";
import { ConfirmAlert } from "../../_common/confirmAlert";
import ImportExcelModal from "../../modals/importExcel.modal";
import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";
import CropProductionMethodsMap from "./CropProductionMethodsMap";
import SearchWithLocation from "../../components/search/SearchWithLocation";
import AddFieldModal from "../map/modals/addField.modal";
import { MAPAPI_BASE_URL } from "../../_config/site_urls";

const FarmList = () => {

    const farmsApi = useFarmsApi()
    const [dataList, setDataList] = useState<any>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;
    const user: any = useUser()
    const permissions: any = useUserPermissions();

    const [importModal, setImportModal] = useState<boolean>(false)

    const districts: any = useDistricts();
    const wards: any = useWards();
    const gpus: any = useGpus();
    const blocks: any = useBlocks();
    const [stateData, setStateData] = useState<boolean>(false);

    const [searchDistricts, setSearchDistricts] = useState([]);
    const [searchBlocks, setSearchBlocks] = useState([]);
    const [searchGpus, setSearchGpus] = useState([]);
    const [searchWards, setSearchWards] = useState([]);

    const [khasraNumber, setKhasraNumber] = useState<string>('');
    const [porchaNumber, setPorchaNumber] = useState<string>('');

    const [fields, setFields] = useState<any[] | null>(null);
    const [addField, setAddField] = useState<any>(null);

    const [cropProduction, setCropProduction] = useState<any>(null);
    const handleClose = () => setCropProduction(null);

    const getFarmList = () => {
        farmsApi.farmList({
            PageIndex: page,
            PageSize: limit,
            SearchText: search,
            districtIDs: searchDistricts.join(),
            BlockIDs: searchBlocks.join(),
            WardIDs: searchWards.join(),
            GPUIDs: searchGpus.join(),

            khasraNumber: khasraNumber,
            porchaNumber: porchaNumber,
        }, (message: any, resp: any) => {
            getFields().then((fields: any) => {
                resp.data.farm.map((farm: any) => {
                    fields.map((field: any) => {
                        if(field.farm_id == farm.farmID){
                            farm.map = field;
                        }
                    })
                    return farm;
                });
                setDataList(resp.data.farm)
                setTotal(resp.totalRecords);
            });
            
        }, (message: any) => {
            setDataList([])
            setTotal(0)
        })
    }

    const getFields = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(MAPAPI_BASE_URL + "/fields");
                const data = await response.json();
                setFields(data);
                resolve(data);
                //hideLoader();
            } catch (error) {
                //hideLoader();
                console.error("Error fetching data:", error);
                reject(error);
            }
        });

    };


    useEffect(() => {
        // if(dataList != null){
        setDataList(null)
        getFarmList();
        // }

    }, [page, search, searchBlocks, searchWards, searchGpus])


    const dataItemChangeStatus = (params: any) => {
        return new Promise((resolve, reject) => {
            farmsApi.farmChangeStatus(params, resolve, reject)
        })
    }

    const dataItemChangeStatusHandler = (el: any, farm: any) => {
        ConfirmAlert.changeStatus('Farm', dataItemChangeStatus, {
            "farmID": farm.farmID,
            "updatedBy": user?.userID,
            "status": el.checked,
            "isDeleted": false,
        }).then(() => {
            getFarmList();
        }).catch(() => {
            el.checked = !el.checked
        })
    }

    const uploadFarms = (data: any) => {
        return new Promise((resolve, reject) => {
            farmsApi.importFarms({ farmImportList: data }, (message: any, resp: any) => { resolve(resp) }, reject)
        })
    }

    useEffect(() => {
        if (districts?.length > 0 && blocks?.length > 0 && gpus?.length > 0 && wards?.length > 0) {
            setStateData(true)
            // getFarmList();
        }
    }, [districts, blocks, gpus, wards])





    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    
                    <div className="container-fluid">

                        <TitleBar title={`Farms`}
                            breadcrumbs={{
                                'Farms': '#',
                            }} />


                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " >
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Farm List</h2>
                                        <div className="flex align-items-center gap-2 ">
                                            <button className="flex items-center gap-1 btn-primary p-2 px-3 rounded" onClick={() => { setImportModal(true) }} disabled={!stateData} ><Icon name="upload" size={18} /> Upload</button>
                                        </div>
                                    </div>


                                    <SearchWithLocation
                                        pageName='farms'
                                        setSearch={setSearch}
                                        setDistricts={setSearchDistricts}
                                        setBlocks={setSearchBlocks}
                                        setGpus={setSearchGpus}
                                        setWards={setSearchWards}

                                        setKhasraNumber={setKhasraNumber}
                                        setPorchaNumber={setPorchaNumber}
                                    />




                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Owner’s Name</th>
                                                    <th scope="col">Owner’s Aadhaar</th>
                                                    <th scope="col">Khasra Number</th>
                                                    <th scope="col">Porcha Number</th>
                                                    <th scope="col">Farm size (Acres)</th>
                                                    <th scope="col">Cultivation Area (Hectare)</th>
                                                    {/* <th scope="col">Ownership Type</th> */}
                                                    {/* <th scope="col">Land Type</th>
                                                    <th scope="col">Location</th> */}
                                                    <th scope="col">Assignment</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    fields && dataList && dataList.length > 0 &&
                                                    dataList.map((itemData: any, index: number) => (
                                                        <tr key={itemData?.farmID}>
                                                            <td>{itemData.map?<Link to={replaceUrlParams(URLS.FARMS.WEATHER, { id: itemData.map._id })}>{itemData.ownerName}</Link>:itemData.ownerName}</td>
                                                            <td>{itemData?.ownersAdharNumber ? itemData?.ownersAdharNumber : '--'}</td>
                                                            <td>{itemData.khasraNumber}</td>
                                                            <td>{itemData.porchaNumber}</td>
                                                            <td>{itemData.farmSize}</td>
                                                            <td>{itemData.cultivationArea}</td>
                                                            {/* <td>{itemData.ownershipTypeName}</td> */}
                                                            {/* <td>{itemData.landTypeName}</td> */}
                                                            {/* <td>
                                                                {`${itemData.districtName}, ${itemData.wardName}, ${itemData.blockName}, ${itemData.gpuName}`}
                                                            </td> */}
                                                            <td>
                                                                <div className="d-flex gap-2 align-items-center">
                                                                    <span className="btn btn-success right-bar-toggle" onClick={() => { setCropProduction(itemData) }}> Crop Production</span>
                                                                </div>
                                                            </td>
                                                            <td> <Status status={itemData.isActive} /></td>

                                                            <td>
                                                                <div className="action-btns flex gap-3 items-center">
                                                                    {/* <a href="#"><GrDownload /></a> */}
                                                                    {
                                                                        permissions.menus['Farms'].write &&
                                                                        <>
                                                                            <Form.Check // prettier-ignore
                                                                                type="switch"
                                                                                id={`status-switch-${itemData.farmID}`}
                                                                                label=""
                                                                                onChange={(e) => { dataItemChangeStatusHandler(e.target, itemData) }}
                                                                                defaultChecked={itemData.isActive}
                                                                            />

                                                                        </>
                                                                    }
                                                                    <Link to={replaceUrlParams(URLS.FARMS.DETAILS, { id: itemData.farmID })}><Icon name="view" size={18} /></Link>
                                                                    

                                                                    <button onClick={()=>{setAddField(itemData)}}><Icon name='field-map' size={18} /></button>

                                                                    {itemData.map && <Link to={replaceUrlParams(URLS.SENSORS, { farmID: itemData.farmID })}><Icon name="settings" size={18} /></Link>}

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <TableLoader data={dataList} />
                                        {
                                            dataList && <Pagination total={total} page={page} setPage={(n: number) => {
                                                setPage(n)
                                                setDataList(null)
                                            }} pageLimit={limit} />
                                        }
                                    </div>
                                    <AddFieldModal open={addField != null} field={addField} update={getFarmList} close={() => { setAddField(null) }} />
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                {stateData && <ImportExcelModal
                    headerTitle="Import Farms"
                    show={importModal}
                    onClose={setImportModal}
                    sampleName="Farms"
                    dataTypes={[
                        { title: 'khasraNumber', type: 'string', apiKey: 'khasraNumber', required: true },
                        { title: 'khatiyanNumber', type: 'string', apiKey: 'khatiyanNumber', required: true },
                        { title: 'porchaNumber', type: 'string', apiKey: 'porchaNumber' },
                        { title: 'porchaImage', type: 'img', apiKey: 'porchaImage' },
                        { title: 'District Name', type: 'option', apiKey: 'districtName', options: districts.map((dist: any) => { return dist.districtName }), required: true },
                        { title: 'Block Name', type: 'option', apiKey: 'blockName', relativeOptions: blocks.map((block: any) => { return { name: block.blockName, parent: getSelectedValue(block.fkDistrictID, 'districtId', districts).districtName } }), relWith: 'District Name', required: true },
                        { title: 'Gpu Name', type: 'option', apiKey: 'gpuName', relativeOptions: gpus.map((gpu: any) => { return { name: gpu.cpuName, parent: getSelectedValue(gpu.fkBlockID, 'blockID', blocks).blockName } }), relWith: 'Block Name', required: true },
                        { title: 'Ward Name', type: 'option', apiKey: 'wardName', relativeOptions: wards.map((ward: any) => { return { name: ward.wardName, parent: getSelectedValue(ward.fkgpuid, 'gpiid', gpus).cpuName } }), relWith: 'Gpu Name', required: true },
                        { title: 'landType', type: 'option', apiKey: 'landType', options: ['arable land (including cropland and fallows)', 'land under permanent crops', 'pastures and hayfields'] },
                        { title: 'farmSize', type: 'number', apiKey: 'farmSize', required: true },
                        { title: 'cultivationArea', type: 'number', apiKey: 'cultivationArea', required: true },
                        { title: 'ownerName', type: 'string', apiKey: 'ownerName', required: true },
                        { title: 'ownersPhoneNumber', type: 'number', apiKey: 'ownersPhoneNumber', min: 10, max: 10, required: true },
                        { title: 'ownersAdharNumber', type: 'number', apiKey: 'ownersAdharNumber', min: 12, max: 12, required: true },
                        { title: 'ownershipType', type: 'option', apiKey: 'ownershipType', options: ['Individual ownership', 'Joint ownership', 'Property ownership by nomination'] },
                    ]}
                    upload={uploadFarms}
                    params={{}}
                    onUploadComplete={getFarmList}
                />}

                <Footer></Footer>
            </div>
            <CropProductionMethodsMap show={cropProduction != null} handleClose={handleClose} crop={cropProduction} />
        </React.Fragment>
    )
}

export default FarmList;