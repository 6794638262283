import { useEffect, useState } from "react"

interface props {
error:any
}
export default function Error({error}: props) {
    
    const [msg,setMsg] = useState<string|null>(null)
    useEffect(()=>{
        if(error){
            if(error.type){ setMsg(error.type)}
            if(error.message){ setMsg(error.message)}
        } else {
            setMsg(null)
        }
    },[error])

    return (
        <>
          {msg ? (
            <p className="text-danger mt-2">
              <span>{msg}</span>
            </p>
          ) : null}
        </>
      )

}