import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import "./Notification.scss";
import { CiBellOn } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";

const Notification = () => {
    return (
        <Dropdown className="d-flex d-none">
            <Dropdown.Toggle variant="default" id="dropdown-basic">
                <CiBellOn />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                
                    <div className="dropdown-item">
                        <div className="text-reset">
                            <div className="row align-items-center notification-header">
                                <div className="col">
                                    <h6 className="m-0" key="t-notifications"> Notifications </h6>
                                </div>
                                <div className="col-auto">
                                    <a href="/notifications" className="small" key="t-view-all"> View All</a>
                                </div>
                            </div>
                            <div className="simplebar-content-wrapper" style={{ maxHeight: 200, overflow: "auto" }}>
                                <div className="notification-row">
                                    <h6 className="mt-0 mb-1" key="t-your-order">Rainfall distribution</h6>
                                    <div className="font-size-12 text-muted">
                                        <p className="mb-1" key="t-grammer">(from 0830 hours IST of yesterday to 0830 hours IST of today): at many places over</p>
                                        <p className="mb-0"><CiClock2 /> <span key="t-min-ago">3 min ago</span></p>
                                    </div>
                                </div>
                                <div className="notification-row">
                                    <h6 className="mt-0 mb-1" key="t-your-order">Rainfall distribution</h6>
                                    <div className="font-size-12 text-muted">
                                        <p className="mb-1" key="t-grammer">(from 0830 hours IST of yesterday to 0830 hours IST of today): at many places over</p>
                                        <p className="mb-0"><CiClock2 /> <span key="t-min-ago">3 min ago</span></p>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2 border-top">
                                <a className="btn btn-sm btn-link font-size-14 btn-block text-center" href="/notifications">
                                    <i className="mdi mdi-arrow-right-circle mr-1"></i> <span key="t-view-more">View More..</span>
                                </a>
                            </div>
                        </div>
                    </div>
                
            </Dropdown.Menu>
        </Dropdown>
    )
}


export default Notification;