import React from 'react'
//import { useAppLoaderSelector } from '../hooks/selectors/loaderSelector';

import './appLoader.scss';

import { useAppLoaderSelector } from '../hooks/loaderSelector';
import Loader from '../../components/loader';

function AppLoader() {
    const show = useAppLoaderSelector();
 
    return show ? <div className="loader">
        <div className="app-loader-container">
            <Loader />
            
           
        </div>
    </div> : null;
}

export default AppLoader