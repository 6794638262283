import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import { replaceUrlParams } from "../../../_common/functions";
import Icon from "../../../components/icon";
import { URLS } from "../../../_config";
import Footer from "../../../components/footer/Footer";
import TitleBar from "../../../components/TopBar/TitleBar";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import parse from 'html-react-parser';

const CultivationMethodsView = () => {
    const params: any = useParams();
    const [cropID, setCropID] = useState<any>(params.id)

    const collateralApi = useCollatralApi()
    const [cultivationMethodsData, setCultivationMethodsData] = useState<any>({})
    const [cropDetails, setCropDetails] = useState<any>(null)


    const getCropDetails = () => {
        collateralApi.cropDetails({ urlParams: { CropID: cropID } },
            (message: any, resp: any) => {
                setCropDetails(resp.data.crop[0])
            }, (message: any) => { })
    }


    const getCropCultivationMethods = () => {
        collateralApi.cropCultivationMethods({
            urlParams: { CropID: cropID }
        }, (message: any, resp: any) => {
            setCultivationMethodsData(resp.data.cropCultivationMethod)
        }, (message: any) => {

        })
    }


    useEffect(() => {
        getCropCultivationMethods();
        getCropDetails()
    }, [cropID])



    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Cultivation Methods`}
                            breadcrumbs={{
                                'Collateral': '#',
                                'Farming Methods': URLS.COLLATERAL.CROP.LIST,
                                'Cultivation Methods': '#',
                            }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100" data-aos="fade-up">

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="d-flex justify-content-between ">
                                                <h3 className="card-heading mb-4 ">Cultivation Methods of {cropDetails?.cropName}</h3>
                                                <div className="d-flex justify-content-end position-relative ">
                                                    <Link to={replaceUrlParams(URLS.COLLATERAL.CROP.CULTIVATION_METHODS_EDIT, { id: cropID })} className="btn btn-primary d-flex gap-2 align-items-center rounded-pill-btn edit-btn right-0 bottom-0">
                                                        <Icon name="edit" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <div className="mb-3">
                                                        <h6>Crop name</h6>
                                                        <p>{cropDetails?.cropName}</p>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="row">

                                                {
                                                    cultivationMethodsData && cultivationMethodsData.length > 0 &&
                                                    cultivationMethodsData.map((item: any) => (
                                                        <div key={`questionID${item?.questionID}`}>
                                                            <div className="col-sm-12" >
                                                                <div className="mb-3">
                                                                    <h6>{item?.question}</h6>
                                                                    <div>{parse(item?.answer ? item?.answer : '--')}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <hr className="divider-line" />
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default CultivationMethodsView;