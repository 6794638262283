import React from "react"; 
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel"; 


const DroneImagery = () => {
    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">Drone Imagery</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Administrator</a></li>
                                            <li className="breadcrumb-item active">Drone Imagery</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-form-box" data-aos="fade-up">
                                    <div className="row">
                                        <div className="col-sm-10">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group float-label-wrap mb-0">
                                                        <label htmlFor="" className="float-label">Select District</label>
                                                        <select name="" id="" className="form-select">
                                                            <option>Select...</option>
                                                            <option value="Pakyong">Pakyong</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group float-label-wrap mb-0">
                                                        <label htmlFor="" className="float-label">Select Block</label>
                                                        <select name="" id="" className="form-select">
                                                            <option value="">Select...</option>
                                                            <option value="Pakyong">Pakyong</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group float-label-wrap mb-0">
                                                        <label htmlFor="" className="float-label">Select GPU</label>
                                                        <select name="" id="" className="form-select">
                                                            <option value="">Select...</option>
                                                            <option value="Aho Yamgpam">Aho Yamgpam</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group float-label-wrap mb-0">
                                                        <label htmlFor="" className="float-label">Select Ward</label>
                                                        <select name="" id="" className="form-select">
                                                            <option value="">Select...</option>
                                                            <option value="Mali Gaon" >Mali Gaon</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="two-btn-wrap">
                                                <button className="btn btn-grey">Reset</button>
                                                <button className="btn btn-blue">Submit</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm-10">
                                <div className="white-graph-box mt-5">
                                    <div className="white-graph-box-head">
                                        <h2>Drone Imagery</h2>
                                        
                                    </div>
                                    <div className="drone-image">
                                        <img src="/images/map.webp" />
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-2">
                                <div className="white-graph-box mt-5">
                                    <div className="white-graph-box-head">
                                        <h2>Advisory Tips</h2>
                                    </div>
                                    <p>The health of 80% of the crops is satisfactory. However, the portion of the farm highlighted in red has been identified as experiencing water and nutrition stress. We kindly request further investigation into this area and recommend the application of additional water and nutritional fertilizer to address the identified concerns.</p>
                                </div>
                            </div>
                        </div>





                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default DroneImagery;