import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useNotificationApi() {
  const callApi = useApiCall();


  const getNotificationList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NOTIFICATION.MANUAL_NOTIFICATION, data, onSuccess, onError);
  };

  const getNotificationDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NOTIFICATION.NOTIFICATION_DETAILS, data, onSuccess, onError);
  };

  const notificationStore = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NOTIFICATION.NOTIFICATION_BROADCAST, data, onSuccess, onError);
  };

  const inviteFramerNotification = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NOTIFICATION.INVITE_FARMERS_STORE, data, onSuccess, onError);
  };

  return {
    getNotificationList, getNotificationDetails, notificationStore, inviteFramerNotification
  };
}
