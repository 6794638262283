import React, { useEffect, useState } from "react";
import { useMasterApi } from "../../../_common/hooks/api/masterApiHook";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import Search from "../../../components/search/Search";
import { Link } from "react-router-dom";
import { URLS } from "../../../_config";
import { replaceUrlParams } from "../../../_common/functions";
import TableLoader from "../../../components/tableLoader";
import Footer from "../../../components/footer/Footer";
import Icon from "../../../components/icon";
import { GoPlus } from "react-icons/go";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { useUser } from "../../../_common/hooks/authHook";

const RoleList = () => {
    const masterApi = useMasterApi();
    const [roleList, setRoleList] = useState<any>(null)
    const user: any = useUser()

    const getAllRoles = () => {
        masterApi.getAllRoles({ urlParams: { PageIndex: 1, PageSize: 100 } }, (message: any, resp: any) => {
            setRoleList(resp.data?.role)
        }, (message: any) => {
            setRoleList([])
        })
    }

    useEffect(() => {
        getAllRoles();
    }, [])

    const deleteItem = (item: any) => {
        return new Promise((resolve, reject) => {
            masterApi.roleDelete({
                "roleId": item.roleId,
                "updatedBy": user?.userID,
                "isDeleted": true
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => {
                reject(message)
            })
        })
    }
    const deleteItemHandler = (item: any) => {
        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this role?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteItem(item), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        getAllRoles();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'Role MANAGEMENT'} breadcrumbs={{
                            'Settings': '#',
                            'Role List': '#'
                        }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100" data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Role List</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            {/* <Search setSearch={() => { }}></Search> */}
                                            <Link to={URLS.ROLE.ADD} className="btn btn-primary d-flex align-items-center gap-2 "><GoPlus /> Add New</Link>
                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col" style={{ width: 150 }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    roleList && roleList?.map((item: any, index: number) => (
                                                        <tr key={`role_${index}`}>
                                                            <td>{item?.title}</td>
                                                            <td>
                                                                <div className="action-btns">
                                                                    <Link to={replaceUrlParams(URLS.ROLE.PERMISSION, { id: item.roleId })}><Icon name='shield-lock-outline' /></Link>

                                                                    {
                                                                        (!item?.isFixedRole) && <>
                                                                            <Link to={replaceUrlParams(URLS.ROLE.EDIT, { id: item.roleId })}><Icon name='edit' /></Link>
                                                                            <Icon name="delete" onClick={() => { deleteItemHandler(item) }} />
                                                                        </>
                                                                    }


                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }


                                            </tbody>
                                        </table>
                                        <TableLoader data={roleList} />
                                    </div>
                                    {/* <Pagination></Pagination> */}
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default RoleList;