import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Transition } from "@headlessui/react";
import { API_BASE_URL } from "../_config";
import Select from 'react-select';
import toast from "react-hot-toast";
import Icon from "../components/icon";
import { useAppLoader } from "../../../_common/hooks/common/appLoaderHook";
import { useParams } from "react-router-dom";
interface AddEditSensorModalProps {
    open: boolean;
    close: () => void;
    sensor?: any;
}

interface FormData {
    month: any;
    normal: string;
    warning: string;
    critical: string;
    time: string;
}

const SensorConfigModal = ({ open, close, sensor }: AddEditSensorModalProps) => {
    const params = useParams();
    const [isOpen, setIsOpen] = useState(open);
    const { control, register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm<FormData>();

    const {showLoader, hideLoader} = useAppLoader();

    const months: string[] = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const [configData, setConfigData] = useState<any[] | null>(null);


    const monthWatch = watch("month");  // you can watch individual input by pass the name of the input

    const closeModal = () => {
        setIsOpen(false);
        close();
    };


    
    const updateSensorConfig = async (time: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(API_BASE_URL + `/sensors/settings`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        event_threshold_seconds:time
                    })
                });
                const result = await response.json();
                resolve(result);
            } catch (error) {
                reject(error);
                console.error("Error updating sensor config:", error);
            }
        });
    };


    const onSubmit = (data: FormData) => {
        showLoader();
        const saveSensorDetails = async (data: any) => {
            try {
            // Make API call to save sensor details
            const response = await fetch(API_BASE_URL + `/sensors/${params.farmID}/${sensor._id}/config`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            console.log(result);
            closeModal();
            hideLoader();
            toast.success("Sensor details saved successfully");
            } catch (error) {
                hideLoader();
                toast.error("Error saving sensor details");
                console.error("Error saving sensor details:", error);
            }
        };

        saveSensorDetails([{
            "month": data.month.value,
            "normal": data.normal,
            "warning": data.warning,
            "critical": data.critical,
            "alert_threshold_seconds": parseInt(data.time) 
          }]);
    };

    const getSensorConfig = async (sensorId: string) => {
        try {
            // Make API call to fetch sensor details using sensorId
            const response = await fetch(API_BASE_URL + `/sensors/${params.farmID}/${sensorId}/config`);
            const data = await response.json();
            let __data:any = {};
            months.forEach((month, index) => {
                let shortMonthName = month.substring(0,3).toLowerCase();
                __data[shortMonthName] = data.filter((d:any) => d.month === shortMonthName)[0];
            });
            setConfigData(__data);

            console.log(__data)

            let config = months[(new Date).getMonth()].substring(0,3).toLowerCase();
            if(config){
                setValue("month", { value: config, label: months[(new Date).getMonth()] });
                setValue("warning", __data[config].warning);
                setValue("critical", __data[config].critical);
                setValue("normal", __data[config].normal);
                setValue("time", __data[config].alert_threshold_seconds);
            }
            /* setValue("month", data.month);
            setValue("warning", data.warning);
            setValue("critical", data.critical);
            setValue("normal", data.normal); */
        } catch (error) {
            console.error("Error fetching sensor details:", error);
        }
    };

    useEffect(() => {
        if (sensor) {
            setIsOpen(true);
            getSensorConfig(sensor._id);
        }
    }, [sensor]);

    useEffect(() => {
        if (monthWatch && configData) {
            setValue("warning", configData[monthWatch.value]?.warning);
            setValue("critical", configData[monthWatch.value]?.critical);
            setValue("normal", configData[monthWatch.value]?.normal);
        }
    }, [monthWatch]);


    useEffect(() => {
        console.log('Config data ====> ', configData);
    }, [configData]);
    useEffect(() => {
        console.log('monthWatch ====> ', monthWatch);
    }, [monthWatch]);


    return (
        <>
            <Transition show={isOpen} as={React.Fragment}>
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <Transition.Child
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                    </Transition.Child>
                    <Transition.Child
                        enter="transition ease-out duration-300 transform"
                        enterFrom="scale-50"
                        enterTo="scale-100"
                        leave="transition ease-in duration-300 transform"
                        leaveFrom="scale-100"
                        leaveTo="scale-50"
                    >
                        <div className="relative bg-white rounded-lg w-96 p-6">
                            <div className="absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    className="text-gray-500 hover:text-gray-700"
                                    onClick={closeModal}
                                >
                                    <svg
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <h2 className="text-xl font-semibold mb-4">
                                {sensor ? `Edit ${sensor.name}` : "Add Sensor"}
                            </h2>
                            <form onSubmit={handleSubmit(onSubmit)}>




                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Month</label>
                                    <Controller
                                        control={control}
                                        name="month"
                                        render={({ field }) => (
                                            <Select
                                                value={field.value}
                                                onChange={field.onChange}
                                                options={months.map((month, index) => ({ value: month.substring(0,3).toLowerCase(), label: month }))}
                                            />
                                        )}
                                    />
                                    {errors.month && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>
                                        {configData?.[monthWatch.value]?.monthly_forecast?
                                <div className="flex gap-2 mt-2 mb-2">
                                    <Icon name="info" size={20} className="text-indigo-400" />
                                    <span className="text-xs">
                                        Based on the predictive model forecast the suggested value range would be:&nbsp;
                                        <span className="text-blue-500 font-bold">{configData?.[monthWatch.value].monthly_forecast?.average_lower_bound.toFixed(2)}</span>&nbsp;to&nbsp;
                                        <span className="text-blue-500 font-bold">{configData?.[monthWatch.value].monthly_forecast?.average_upper_bound.toFixed(2)}</span>

                                    </span>
                                </div>: null}

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Normal</label>
                                    <input
                                        type="text"
                                        {...register("normal", { required: true })}
                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                    />
                                    {errors.normal && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Warning</label>
                                    <input
                                        type="text"
                                        {...register("warning", { required: true })}
                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                    />
                                    {errors.warning && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Critical</label>
                                    <input
                                        type="text"
                                        {...register("critical", { required: true })}
                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                    />
                                    {errors.critical && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Time threshold <span className="text-gray-400">(sec)</span></label>
                                    <input
                                        type="text"
                                        {...register("time", { required: true })}
                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                    />
                                    {errors.time && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>

                                <button
                                    type="submit"
                                    className="btn btn-sm btn-primary"
                                >
                                    Submit
                                </button>
                            </form>

                        </div>
                    </Transition.Child>
                </div>
            </Transition>
        </>
    );
};

export default SensorConfigModal;




