export const monthsOptions = [
    { label: "Jan", value: "Jan" },
    { label: "Feb", value: "Feb" },
    { label: "Mar", value: "Mar" },
    { label: "Apr", value: "Apr" },
    { label: "May", value: "May" },
    { label: "Jun", value: "Jun" },
    { label: "Jul", value: "Jul" },
    { label: "Aug", value: "Aug" },
    { label: "Sep", value: "Sep" },
    { label: "Oct", value: "Oct" },
    { label: "Nov", value: "Nov" },
    { label: "Dec", value: "Dec" },
]

export const quarterOptions = [
    { label: 'First Quarter', value: 'Q1' },
    { label: 'Second Quarter', value: 'Q2' },
    { label: 'Third Quarter', value: 'Q3' },
    { label: 'Fourth Quarter', value: 'Q4' },
]

export const monthQuarterOptions = [...quarterOptions, ...monthsOptions]

export const accntListFilterStausData = [
    { label: 'Active', value: 1 },
    { label: 'Expired', value: 0 }
]

export const openCloseFilterData = [
    { label: "Active", value: "Activated" },
    { label: "Expired", value: "Expired" }
]

export const quoteStatusData = [
    { label: "Draft", value: "Draft" },
    { label: "Needs Review", value: "Needs Review" },
    { label: "In Review", value: "In Review" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Presented", value: "Presented" },
    { label: "Accepted", value: "Accepted" },
    { label: "Denied", value: "Denied" },
]

export const leadStatusData = [
    { label: "New", value: "New" },
    { label: "Contacted", value: "Contacted" },
    { label: "Nurturing", value: "Nurturing" },
    { label: "Qualified", value: "Qualified" },
    { label: "Unqualified", value: "Unqualified" },
]

export const contractFilterStatusData = [
    { label: "Activated", value: "Activated" },
    { label: "Expired", value: "Expired" },
    { label: "Renewed", value: "Renewed" },
    { label: "Churned", value: "Churned" },
]

export const employeesData = [
    {
        label: '0-20 employees',
        value: '0-20 employees'
    },
    {
        label: '21-50 employees',
        value: '21-50 employees'
    },
    {
        label: '51-100 employees',
        value: '51-100 employees'
    },
    {
        label: '101-250 employees',
        value: '101-250 employees'
    },
    {
        label: '251-500 employees',
        value: '251-500 employees'
    },
    {
        label: '501-1000 employees',
        value: '501-1000 employees'
    },
    {
        label: '1001-5000 employees',
        value: '1001-5000 employees'
    },
    {
        label: '5001-10000 employees',
        value: '5001-10000 employees'
    },
    {
        label: '10000+ employees',
        value: '10000+ employees'
    },
]

export const countriesData = [
    {
        label: 'IND',
        value: 'IND'
    },
    {
        label: 'USA',
        value: 'USA'
    },
    {
        label: 'CAN',
        value: 'CAN'
    },
    {
        label: 'UK',
        value: 'UK'
    },
]

export const industriesData = [
    { value: 'Agriculture', label: 'Agriculture' },
    { value: 'Apparel', label: 'Apparel' },
    { value: 'Banking', label: 'Banking' },
    { value: 'Biotechnology', label: 'Biotechnology' },
    { value: 'Chemicals', label: 'Chemicals' },
    { value: 'Communications', label: 'Communications' },
    { value: 'Construction', label: 'Construction' },
    { value: 'Consulting', label: 'Consulting' },
    { value: 'Education', label: 'Education' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Energy', label: 'Energy' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Environmental', label: 'Environmental' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Food & Beverage', label: 'Food & Beverage' },
    { value: 'Government', label: 'Government' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Hospitality', label: 'Hospitality' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'Machinery', label: 'Machinery' },
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'Media', label: 'Media' },
    { value: 'Not For Profit', label: 'Not For Profit' },
    { value: 'Other', label: 'Other' },
    { value: 'Recreation', label: 'Recreation' },
    { value: 'Retail', label: 'Retail' },
    { value: 'Shipping', label: 'Shipping' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Telecommunications', label: 'Telecommunications' },
    { value: 'Transportation', label: 'Transportation' },
    { value: 'Utilities', label: 'Utilities' },
];

export const typesData = [
    {
        label: "Analyst",
        value: "Analyst"
    },
    {
        label: "Competitor",
        value: "Competitor"
    },
    {
        label: "Customer",
        value: "Customer"
    },
    {
        label: "Integrator",
        value: "Integrator"
    },
    {
        label: "Investor",
        value: "Investor"
    },
    {
        label: "Partner",
        value: "Partner"
    },
    {
        label: "Press",
        value: "Press"
    },
    {
        label: "Prospect",
        value: "Prospect"
    },
    {
        label: "Reseller",
        value: "Reseller"
    },
    {
        label: "Other",
        value: "Other"
    },
]

// export const salutationData = [
//     { value: 'Mr.', label: 'Mr.' },
//     { value: 'Ms.', label: 'Ms.' },
//     { value: 'Mrs.', label: 'Mrs.' },
//     { value: 'Dr.', label: 'Dr.' },
//     { value: 'Prof.', label: 'Prof.' },
//     { value: 'Mx.', label: 'Mx.' },
// ]

export const salutationData = [
        { value: 'Option 1', label: 'Option 1' },
        { value: 'Option 2', label: 'Option 2' },
        { value: 'Option 3', label: 'Option 3' },
        { value: 'Option 4', label: 'Option 4' },
        { value: 'Option 5', label: 'Option 5' },
        { value: 'Option 6', label: 'Option 6' },
    ]

export const stageData = [
    {
        label: "Prospecting",
        value: "Prospecting"
    },
    {
        label: "Qualification",
        value: "Qualification"
    },
    {
        label: "Needs Analysis",
        value: "Needs Analysis"
    },
    {
        label: "Value Proposition",
        value: "Value Proposition"
    },
    {
        label: "Decision Makers",
        value: "Decision Makers"
    },
    {
        label: "Perception Analysis",
        value: "Perception Analysis"
    },
    {
        label: "Proposal/Price Quote",
        value: "Proposal/Price Quote"
    },
    {
        label: "Negotiation/Review",
        value: "Negotiation/Review"
    },
    {
        label: "Close Won",
        value: "Close Won"
    },
    {
        label: "Close Lost",
        value: "Close Lost"
    }
]

export const configTypeData = [
    { label: "Required", value: "Required" },
    { label: "Allowed", value: "Allowed" },
    { label: "Disabled", value: "Disabled" },
]

export const subscriptionTypeData = [
    { label: "Renewble", value: "Renewble" },
    { label: "One-time", value: "One-time" },
    { label: "Renewable/Evergreen", value: "Renewable/Evergreen" },
    { label: "Evergreen", value: "Evergreen" },
]

export const subscriptionPricingData = [
    { label: "Fixed Price", value: "Fixed Price" },
    { label: "Percent Of Total", value: "Percent Of Total" },
]

export const billType = [
    { label: "Annual", value: "Annual" },
    { label: "Bi-annual", value: "Bi-annual" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Upfront", value: "Upfront" },
    { label: "Daily", value: "Daily" },
]

export const contractStatusData = [
    { label: "In Approval Process", value: "In Approval Process" },
    { label: "Activated", value: "Activated" },
    { label: "Expired", value: "Expired" },
]

export const yesNoOptions = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
]

export const taxForOption = [
    { label: "Quote", value: "Quote" },
    { label: "Product", value: "Product" }
]

export const licenceOptions = [
    {
        label: "Manager",
        value: "Manager"
    },
    {
        label: "Admin",
        value: "Admin"
    },
    {
        label: "User",
        value: "User"
    }
]

export const targetFieldOptions = [
    {
        label: "Amount",
        value: "Amount"
    },
    {
        label: "Dicount",
        value: "Dicount"
    },
    {
        label: "Product discount",
        value: "Product discount"
    }
]

export const operatorOptions = [
    {
        label: "equals",
        value: "equals"
    },
    {
        label: "not equals",
        value: "not equals"
    },
    {
        label: "less than",
        value: "less than"
    },
    {
        label: "less or equals",
        value: "less or equals"
    },
    {
        label: "greater than",
        value: "greater than"
    },
    {
        label: "greater or equals",
        value: "greater or equals"
    },
]


export const termTypeOptionsStatic = [
    { label: "Month", value: "month" },
    { label: "Day", value: "day" }
]
export const importDataOptionsStatic = [
    { label: "Lead", value: "Lead" },
    { label: "Account", value: "Account" }
]


export const yearOptionsStatic = () => {
    var currentYear = new Date().getFullYear()
    var i = currentYear + 15;

    var yearOptions = [];
    for (i; i >= currentYear - 10; i--) {
        yearOptions.push({ label: i, value: i })
    }
    return yearOptions;
}