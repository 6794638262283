import React, { useEffect, useState } from 'react';
import Icon from '../../components/icon';
import { DATA } from '../../_config';
import moment from 'moment';
import Select from 'react-select';
import ForecastTemperatureModal from '../../modals/forecastTemperature.modal';
import LeftPanel from '../../../../components/leftPanel/LeftPanel';
import Header from '../../../../components/header/Header';
import RightPanel from '../../components/rightPannel/rightPanel';
import { useAppLoader } from '../../../../_common/hooks/common/appLoaderHook';
import { MAPAPI_BASE_URL } from '../../../../_config/site_urls';
import { useParams } from 'react-router-dom';
import { URLS } from '../../../../_config';
import { replaceUrlParams } from '../../../../_common/functions';
import TitleBar from '../../../../components/TopBar/TitleBar';
import Loader from '../../../../components/loader';


const Forecast: React.FC = () => {
    const params: any = useParams();

    const [data, setData] = useState(DATA);
    const [graphData, setGraphData] = useState<any>(null);
    const [detailsView, setDetailsView] = useState<any>(null);
    const { showLoader, hideLoader } = useAppLoader();
    const [forecastTempModal, setForecastTempModal] = useState(false);
    const [forecastTempSelected, setForecastTempSelected] = useState<any>(null);
    const [fields, setFields] = useState<any[] | null>(null);
    const [field, setField] = useState<any>(null);
    const [forecast, setForecast] = useState<any>(null);
    const [forecastHourly, setForecastHourly] = useState<any>(null);

    const getForecast = async () => {
        //showLoader();
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/fields/${params.id}/forecast?${new URLSearchParams({
                from_date: moment().add(1, 'days').format('YYYY-MM-DD'),
                to_date: moment().add(10, 'days').format('YYYY-MM-DD')
            })}`);

            const data = await response.json();
            let _forecast: any = {};
            data.forEach((d: any) => {
                let dt = moment(d.date).format('YYYY-MM-DD');
                if (!_forecast[dt]) {
                    _forecast[dt] = [];
                }
                _forecast[dt].push(d);
            });
            console.log('forecast--->', _forecast);
            setForecast(_forecast);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    };

    const getForecastHourly = async () => {
        //showLoader();
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/fields/${params.id}/forecast?${new URLSearchParams({
                from_date: moment().add(1, 'days').format('YYYY-MM-DD'),
                to_date: moment().add(10, 'days').format('YYYY-MM-DD'),
                forecast_type: 'hourly'
            })}`);

            const data = await response.json();
            let _forecast: any = {};
            data.forEach((d: any) => {

                let dt = moment(d.date).format('YYYY-MM-DD');

                if (!_forecast[dt]) {
                    _forecast[dt] = {};
                }
                let hour: any = moment(d.date).format('HH');
                //console.log('dt--->', dt, hour);
                //console.log('dt--->', d.crop_id);
                if (!_forecast[dt][hour]) {
                    _forecast[dt][hour] = {};
                }
                let _value = { ...d, [d.crop_id]: { is_lcl: d.is_lcl, is_ucl: d.is_ucl } };
                if (_forecast[dt][hour][d.sensor_name]) {
                    console.log('prev sensor name found so data merge')
                    _value = { ..._value, [_forecast[dt][hour][d.sensor_name].crop_id]: { is_lcl: _forecast[dt][hour][d.sensor_name].is_lcl, is_ucl: _forecast[dt][hour][d.sensor_name].is_ucl } }
                }
                _forecast[dt][hour][d.sensor_name] = _value;

            });
            //console.log('forecast hourly--->', _forecast);
            setForecastHourly(_forecast);
            hideLoader();
        } catch (error) {
            hideLoader();
            //console.error("Error fetching data:", error);
        }
    };




    const getFields = async () => {
        //showLoader();
        try {
            const response = await fetch(MAPAPI_BASE_URL + "/fields");
            const data = await response.json();
            setFields(data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    };
    const getFieldDeatils = async () => {
        setForecast(null);
        setDetailsView(null);
        //showLoader();
        try {
            const response = await fetch(MAPAPI_BASE_URL + "/fields/" + params.id);
            const data = await response.json();
            setField(data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    }

    const getAverage = (_data: any, key: string, int: boolean = true) => {
        let total = 0;
        _data.forEach((d: any) => {
            total += d[key];
        });
        if (int) {
            return parseInt((total / _data.length).toFixed(0));
        } else {
            return parseFloat((total / _data.length).toFixed(2));
        }
    }
    const getMaxTemp = (_data: any) => {
        return getAverage(_data, 'air_temp')
    }
    const getMinTemp = (_data: any) => {
        return getAverage(_data, 'air_temp') - 10;
    }

    const getForecastValue = (value: string, key: string, data: any) => {
        var row: any = null
        data.forEach((d: any) => {
            if (d.sensor_name == key) {
                row = d;
            }
        });
        if (row == null) {
            return '-';
        }
        return row[value].toFixed(2);
    }



    useEffect(() => {
        getFields();
    }, []);

    useEffect(() => {
        getFieldDeatils();
    }, [params.id]);

    useEffect(() => {
        if(field){
            getForecast();
            getForecastHourly();
        }
    }, [field]);


    return (
        <div className='h-screen'>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content pb-0">
                    <div className="container-fluid">
                        <TitleBar title={`Forecast — ${field?.name}`} breadcrumbs={{ 'Forecast': '#', }} />
                        <div className="white-box-card mb-1 h-100 p-0 overflow-hidden" data-aos="fade-up">
                            <div className="flex w-full h-full st-height">
                                <div className='flex-grow flex flex-col st-height'>
                                    {detailsView == null &&
                                        <div className='flex justify-between px-5'>
                                            {/* <div className='flex gap-2 p-2'>
                                            <div className='p-3 rounded-md bg-blue-500 text-gray-50'>
                                                <p className='flex'>
                                                    <sup className='mr-1 font-semibold text-sm '>Now</sup>
                                                    <span className='text-3xl font-bold'>28<sup>o</sup>C</span>
                                                </p>
                                            </div>
                                            <div className='p-3 rounded-md bg-slate-200 '>
                                                <label className='block text-gray-500'>Precipitation</label>
                                                <p className='flex text-gray-700 font-semibold'>0 mm</p>
                                            </div>

                                            <div className='p-3 rounded-md bg-slate-200 '>
                                                <label className='block text-gray-500'>(Rel.) Himidity</label>
                                                <p className='flex text-gray-700 font-semibold'>46%</p>
                                            </div>

                                            <div className='p-3 rounded-md bg-slate-200 '>
                                                <label className='block text-gray-500'>Clouds</label>
                                                <p className='flex text-gray-700 font-semibold'>2%</p>
                                            </div>

                                            <div className='p-3 rounded-md bg-slate-200 '>
                                                <label className='block text-gray-500'>Wind</label>
                                                <p className='flex text-gray-700 font-semibold items-center'>
                                                    1 m/s
                                                    <Icon name='wind-direction' size={20} className=' rotate-90' />
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <label className='text-xs text-slate-500'>Plot</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isClearable={false}
                                                //value={indexType}
                                                isSearchable={false}
                                                //onChange={setIndexType}
                                                name="color"
                                                options={[]}
                                            />
                                        </div> */}
                                        </div>
                                    }
                                    {detailsView == null &&
                                        <div className='bg-slate-100 flex justify-between p-0 items-center'>
                                            {/* <div className='flex gap-2 items-center'>
                                            <Icon name='risk' size={24} className='text-red-600' />
                                            <p className='m-0'>6 Risk(-s) detected</p>
                                        </div> */}

                                            {/* <div className='bg-slate-100 rounded p-2'>
                                            <Icon name='knob-settings' size={24} className='text-gray-600' />
                                        </div> */}
                                        </div>
                                    }

                                    <div className=''>
                                        {forecast && <React.Fragment>
                                            {Object.keys(forecast).map((dt: any, i: any) => (
                                                <div key={`data-${i}`}>
                                                    <div className={`flex justify-between items-center pl-4 pr-6 bg-slate-100 mb-1 ${detailsView && detailsView != dt ? 'hidden' : ''}`} >
                                                        <div className='flex items-center py-2'>
                                                            {
                                                                detailsView && <div className='w-[60px]'>
                                                                    <Icon name='back' size={24} className={`text-gray-600 cursor-pointer ${detailsView ? '' : 'hidden'}`} onClick={() => { setDetailsView(null) }} />
                                                                </div>
                                                            }

                                                            <div className='w-[120px] flex justify-center'>
                                                                <div className='flex flex-col items-center justify-center bg-white rounded-md overflow-hidden shadow-md'>
                                                                    <p className='text-xs font-semibold text-slate-50 m-0 bg-green-500 w-100 text-center px-1'>{moment(dt).format('MMM')}</p>
                                                                    <p className='text-3xl px-2 font-semibold text-slate-700 m-0 min-w-[60px]  text-center'>{moment(dt).format('DD')}</p>
                                                                    <p className='font-size-11 font-semibold text-gray-400  border-t border-grey-400 m-0 bg-white w-100 text-center px-1'>{moment(dt).format('ddd')}</p>
                                                                </div>
                                                            </div>
                                                            <div className='w-[120px]'>
                                                                <label className='text-sm text-slate-600  font-semibold'>Soil EC</label>
                                                                <p className='m-0 text-lg font-semibold text-slate-900'>
                                                                    {getForecastValue('average_prediction', 'Soil EC', forecast[dt])}
                                                                </p>
                                                                <p className='font-size-11 font-semibold text-slate-500 m-0'>{getForecastValue('average_lower_bound', 'Soil EC', forecast[dt])} &mdash; {getForecastValue('average_upper_bound', 'Soil EC', forecast[dt])}</p>
                                                            </div>
                                                            <div className='w-[120px]'>
                                                                <label className='text-sm text-slate-600 font-semibold'>Soil pH</label>
                                                                <p className='m-0 text-lg font-semibold text-slate-900'>
                                                                    {getForecastValue('average_prediction', 'Soil pH', forecast[dt])}
                                                                </p>
                                                                <p className='font-size-11 font-semibold text-slate-500 m-0'>{getForecastValue('average_lower_bound', 'Soil pH', forecast[dt])} / {getForecastValue('average_upper_bound', 'Soil pH', forecast[dt])}</p>
                                                            </div>
                                                            <div className='w-[120px]'>
                                                                <label className='text-sm text-slate-600 font-semibold'>Soil Moisture</label>
                                                                <p className='m-0 text-lg font-semibold text-slate-900'>
                                                                    {getForecastValue('average_prediction', 'Soil Moisture', forecast[dt])}%
                                                                </p>
                                                                <p className='font-size-11 font-semibold text-slate-500 m-0'>{getForecastValue('average_lower_bound', 'Soil Moisture', forecast[dt])}% / {getForecastValue('average_upper_bound', 'Soil Moisture', forecast[dt])}%</p>
                                                            </div>
                                                            <div className='w-[120px]'>
                                                                <label className='text-sm text-slate-600 font-semibold'>Soil Salinity</label>
                                                                <p className='m-0 text-lg font-semibold text-slate-900'>
                                                                    {getForecastValue('average_prediction', 'Soil Salinity', forecast[dt])}<span className='text-xs'><sup>ppm</sup></span>
                                                                </p>
                                                                <p className='font-size-11 font-semibold text-slate-500 m-0'>{getForecastValue('average_lower_bound', 'Soil Salinity', forecast[dt])}<sup>ppm</sup> / {getForecastValue('average_upper_bound', 'Soil Salinity', forecast[dt])}<sup>ppm</sup></p>
                                                            </div>
                                                            <div className='w-[140px]'>
                                                                <label className='text-sm text-slate-600 font-semibold'>Soil Temperature</label>
                                                                <p className='m-0 text-lg font-semibold text-slate-900'>
                                                                    {getForecastValue('average_prediction', 'Soil Temperature', forecast[dt])}<sup>o</sup>
                                                                </p>
                                                                <p className='font-size-11 font-semibold text-slate-500 m-0'>{getForecastValue('average_lower_bound', 'Soil Temperature', forecast[dt])}<sup>o</sup> / {getForecastValue('average_upper_bound', 'Soil Temperature', forecast[dt])}<sup>o</sup></p>
                                                            </div>
                                                            <div className='w-[140px]'>
                                                                <label className='text-sm text-slate-600 font-semibold'>Wind Speed</label>
                                                                <p className='m-0 text-lg font-semibold text-slate-900'>
                                                                    {getForecastValue('average_prediction', 'Wind speed', forecast[dt])} <span className='font-size-12'>km/h</span>
                                                                </p>
                                                                <p className='font-size-11 font-semibold text-slate-500 m-0'>{getForecastValue('average_lower_bound', 'Wind speed', forecast[dt])}km/h  / {getForecastValue('average_upper_bound', 'Wind speed', forecast[dt])}km/h</p>
                                                            </div>
                                                            <div className='w-[140px]'>
                                                                <label className='text-sm text-slate-600 font-semibold'>Wind Direction</label>
                                                                <p className='m-0 text-lg font-semibold text-slate-900'>
                                                                    {getForecastValue('average_prediction', 'Wind Direction', forecast[dt])} <span className='text-xs'>deg<sup>o</sup></span>
                                                                </p>
                                                                <p className='font-size-11 font-semibold text-slate-500 m-0'>{getForecastValue('average_lower_bound', 'Wind Direction', forecast[dt])}<sup>o</sup> / {getForecastValue('average_upper_bound', 'Wind Direction', forecast[dt])}<sup>o</sup></p>
                                                            </div>
                                                            {/* <div className='w-[140px]'>
                                                                <label className='text-sm text-slate-600 font-semibold'>Solar Radiation</label>
                                                                <p className='m-0 text-lg font-semibold text-slate-900'>
                                                                    {getForecastValue('average_prediction', 'Solar Radiation', forecast[dt])} <span className='text-xs'>w/m<sup>2</sup></span>
                                                                </p>
                                                                <p className='font-size-11 font-semibold text-slate-500 m-0'>{getForecastValue('average_lower_bound', 'Soil Temperature', forecast[dt])}<sup>o</sup> / {getForecastValue('average_upper_bound', 'Soil Temperature', forecast[dt])}<sup>o</sup></p>
                                                            </div> */}


                                                            {/* <div className='w-[190px]'>
                                                            <label className='text-sm text-slate-400'>Spraying time</label>
                                                            <div className='flex items-center gap-1 pt-1 mb-1'>
                                                                {forecast[dt].map((d: any, i: any) => (
                                                                    <div key={`forecast[dt]${i}`} className={`w-[3px] h-[20px] ${d.air_temp > 30 ? 'bg-red-600' : (d.air_temp > 26 ? 'bg-yellow-400' : 'bg-green-500')}`}></div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className='w-[190px]'>
                                                            <label className='text-sm text-slate-400'>Tillage time</label>
                                                            <div className='flex items-center gap-1 pt-1 mb-1'>
                                                                {forecast[dt].map((d: any, i: any) => (
                                                                    <div key={`Tillage time${i}`} className={`w-[3px] h-[20px] ${d.air_temp > 30 ? 'bg-red-600' : (d.air_temp > 26 ? 'bg-yellow-400' : 'bg-green-500')}`}></div>
                                                                ))}

                                                            </div>
                                                        </div> */}
                                                        </div>
                                                        <div className='flex items-center gap-3'>
                                                            {
                                                                detailsView && <Icon name='arrow-left' size={24} className={`text-gray-600 cursor-pointer disabled ${detailsView ? '' : 'hidden'}`} onClick={() => {
                                                                    if (Object.keys(forecast).indexOf(dt) != 0) {
                                                                        let i = Object.keys(forecast).indexOf(dt);
                                                                        let prev = Object.keys(forecast)[i - 1];
                                                                        setDetailsView(prev);
                                                                    }
                                                                }} />
                                                            }

                                                            <Icon name='arrow-right' size={24} className='text-gray-600 cursor-pointer' onClick={() => {
                                                                if (detailsView) {
                                                                    if (Object.keys(forecast).indexOf(detailsView) != Object.keys(forecast).length - 1) {
                                                                        let i = Object.keys(forecast).indexOf(detailsView);
                                                                        let next = Object.keys(forecast)[i + 1];
                                                                        if (next) {
                                                                            setDetailsView(next);
                                                                        }
                                                                    }
                                                                } else {
                                                                    setDetailsView(dt);
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                    {detailsView == dt && <React.Fragment>
                                                        {/* <div className='flex gap-2 items-center p-3 bg-slate-100'>
                                                        <Icon name='risk' size={24} className='text-red-600' />
                                                        <p className='m-0'>2 Risk(-s) detected</p>
                                                    </div> */}

                                                        {
                                                            forecastTempModal &&
                                                            <ForecastTemperatureModal open={forecastTempModal} alert={forecastTempSelected} close={() => { setForecastTempModal(false); setForecastTempSelected(null) }} />
                                                        }

                                                        <div className="relative overflow-x-auto">
                                                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                                    <tr>
                                                                        <th scope="col" className="px-6 py-3 text-center">
                                                                            Time
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-center">
                                                                            Soil EC
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-center">
                                                                            Soil pH
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-center">
                                                                            Soil Moisture
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-center">
                                                                            Soil Salinity
                                                                        </th>

                                                                        <th scope="col" className="px-6 py-3 text-center">
                                                                            Soil Temperature
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-center">
                                                                            Wind Speed
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3 text-center">
                                                                            Wind Direction
                                                                        </th>
                                                                        {/* <th scope="col" className="px-6 py-3 text-center">
                                                                            Solar Radiation
                                                                        </th> */}

                                                                        {/* <th scope="col" className="px-6 py-3 text-center">
                                                                        Spraying Time
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3 text-center">
                                                                        Tillage Time
                                                                    </th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(forecastHourly[dt]).sort((a: any, b: any) => a - b).map((h: any, ind: any) => (
                                                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={`det-t-${ind}`}>
                                                                            <th scope="row" className="px-6 py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                                {h}:00
                                                                            </th>
                                                                            <td className="px-6 py-4 text-center">
                                                                                <span className={`rounded px-2 py-1 text-gray-800 font-semibold cursor-pointer ${forecastHourly[dt][h]['Soil EC']?.is_ucl ? 'bg-red-200' : ((forecastHourly[dt][h]['Soil EC']?.is_lcl ? 'bg-blue-200' : ''))}`}>
                                                                                    {forecastHourly[dt][h]['Soil EC']?.average_prediction.toFixed(2)}
                                                                                </span>
                                                                            </td>
                                                                            <td className="px-6 py-4 text-center">
                                                                                <span className={`rounded px-2 py-1 text-gray-800 font-semibold cursor-pointer ${forecastHourly[dt][h]['Soil pH']?.is_ucl ? 'bg-red-200' : ((forecastHourly[dt][h]['Soil pH']?.is_lcl ? 'bg-blue-200' : ''))}`}>
                                                                                    {forecastHourly[dt][h]['Soil pH']?.average_prediction.toFixed(2)}
                                                                                </span>
                                                                            </td>
                                                                            <td className="px-6 py-4 text-center">
                                                                                <span className={`rounded px-2 py-1 text-gray-800 font-semibold cursor-pointer ${forecastHourly[dt][h]['Soil Moisture']?.is_ucl ? 'bg-red-200' : ((forecastHourly[dt][h]['Soil Moisture']?.is_lcl ? 'bg-blue-200' : ''))}`}>
                                                                                    {forecastHourly[dt][h]['Soil Moisture']?.average_prediction.toFixed(2)}
                                                                                </span>
                                                                            </td>
                                                                            <td className="px-6 py-4 text-center">
                                                                                <span className={`rounded px-2 py-1 text-gray-800 font-semibold cursor-pointer ${forecastHourly[dt][h]['Soil Salinity']?.is_ucl ? 'bg-red-200' : ((forecastHourly[dt][h]['Soil Salinity']?.is_lcl ? 'bg-blue-200' : ''))}`}>
                                                                                    {forecastHourly[dt][h]['Soil Salinity']?.average_prediction.toFixed(2)}
                                                                                </span>
                                                                            </td>
                                                                            <td className="px-6 py-4 text-center">
                                                                                <span className={`rounded px-2 py-1 text-gray-800 font-semibold cursor-pointer ${forecastHourly[dt][h]['Soil Temperature']?.is_ucl ? 'bg-red-200' : ((forecastHourly[dt][h]['Soil Temperature']?.is_lcl ? 'bg-blue-200' : ''))}`}>
                                                                                    {forecastHourly[dt][h]['Soil Temperature']?.average_prediction.toFixed(2)}
                                                                                </span>
                                                                            </td>
                                                                            <td className="px-6 py-4 text-center">
                                                                                <span className={`rounded px-2 py-1 text-gray-800 font-semibold cursor-pointer ${forecastHourly[dt][h]['Wind speed']?.is_ucl ? 'bg-red-200' : ((forecastHourly[dt][h]['Wind speed']?.is_lcl ? 'bg-blue-200' : ''))}`}>
                                                                                    {forecastHourly[dt][h]['Wind speed']?.average_prediction.toFixed(2)}
                                                                                </span>
                                                                            </td>
                                                                            <td className="px-6 py-4 text-center">
                                                                                <span className={`rounded px-2 py-1 text-gray-800 font-semibold cursor-pointer ${forecastHourly[dt][h]['Wind Direction']?.is_ucl ? 'bg-red-200' : ((forecastHourly[dt][h]['Wind Direction']?.is_lcl ? 'bg-blue-200' : ''))}`}>
                                                                                    {forecastHourly[dt][h]['Wind Direction']?.average_prediction.toFixed(2)}
                                                                                </span>
                                                                            </td>
                                                                            {/* <td className="px-6 py-4 text-center">
                                                                                <span className={`rounded px-2 py-1 text-gray-800 font-semibold cursor-pointer ${forecastHourly[dt][h]['Solar Radiation']?.is_ucl ? 'bg-red-200' : ((forecastHourly[dt][h]['Solar Radiation']?.is_lcl ? 'bg-blue-200' : ''))}`}>
                                                                                    {forecastHourly[dt][h]['Solar Radiation']?.average_prediction.toFixed(2)}
                                                                                </span>
                                                                            </td> */}

                                                                            {/* <td className="px-6 py-4 text-center">
                                                                            <div className={`w-[60px] h-[6px] mx-auto rounded-sm ${d.air_temp > 30 ? 'bg-red-600' : (d.air_temp > 26 ? 'bg-yellow-400' : 'bg-green-500')}`}></div>
                                                                        </td>
                                                                        <td className="px-6 py-4 text-center">
                                                                            <div className={`w-[60px] h-[6px] mx-auto rounded-sm ${d.air_temp > 30 ? 'bg-red-600' : (d.air_temp > 26 ? 'bg-yellow-400' : 'bg-green-500')}`}></div>
                                                                        </td> */}
                                                                        </tr>
                                                                    ))}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </React.Fragment>}


                                                </div>
                                            ))}
                                        </React.Fragment>
                                        }
                                        {forecast && Object.keys(forecast).length == 0 &&
                                            <div className='flex justify-center items-center h-100'>
                                                <p className='text-gray-500 text-xl mt-5'>No data found</p>
                                            </div>
                                        }

                                        {forecast == null && 
                                        <div className='mt-5'><Loader /></div>
                                        }


                                    </div>

                                </div>
                                <RightPanel url={URLS.FARMS.FORECAST} page='forecast' fields={fields} field={field} backUrl={replaceUrlParams(URLS.FARMS.WEATHER, { id: params.id })} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Forecast;