import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { GoPlus } from "react-icons/go";
import { useEmployeeApi } from "../../_common/hooks/api/employeeApiHook";
import Icon from "../../components/icon";
import { Link } from "react-router-dom";
import { URLS } from "../../_config";
import { replaceUrlParams } from "../../_common/functions";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import TableLoader from "../../components/tableLoader";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import Status from "../../components/status";
import { Form } from "react-bootstrap";
import TitleBar from "../../components/TopBar/TitleBar";
import SearchWithLocation from "../../components/search/SearchWithLocation";


const EmployeeList = () => {
    const permissions: any = useUserPermissions();

    const employeeApi = useEmployeeApi();
    const [employees, setEmployees] = useState<any[] | null>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;
    let userdata: any = useUser();


    const [searchDistricts, setSearchDistricts] = useState([]);
    const [searchBlocks, setSearchBlocks] = useState([]);
    const [searchGpus, setSearchGpus] = useState([]);
    const [searchWards, setSearchWards] = useState([]);


    const getEmployees = () => {
        employeeApi.getAllEmployee({
            PageIndex: page,
            PageSize: limit,
            SearchText: search,
            districtIDs: searchDistricts.join(),
            BlockIDs: searchBlocks.join(),
            WardIDs: searchWards.join(),
            GPUIDs: searchGpus.join()
        }, (message: any, resp: any) => {
            console.log('employees =>', resp.data.employeeList)
            setEmployees(resp.data.employeeList)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setEmployees([])
        })
    }

    const deleteEmployee = (employee: any) => {
        return new Promise((resolve, reject) => {
            employeeApi.deleteEmployee({
                "userID": employee.userID,
                "updatedBy": 1,
                "status": true
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => {
                reject(message)
            })
        })
    }

    const changeStatus = (status: boolean, employee: any) => {
        return new Promise((resolve, reject) => {
            employeeApi.updateEmployeeStatus({
                "userID": employee.userID,
                "updatedBy": 1,
                "status": status
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => { reject(message) })

        })

    }
    const changeStatusHandler = (el: any, user: any) => {
        let status = el.checked;
        Swal.fire({
            title: 'Confirm Status Change',
            text: `Are you sure you want to ${status ? 'active' : 'inactive'} this employee?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${status ? 'Active' : 'Inactive'} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    changeStatus(status, user), {
                    loading: 'Status changing...',
                    success: (msg: any) => {
                        getEmployees();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            } else {
                el.checked = !el.checked;

            }
        });
    }

    const deleteEmployeehandler = (employee: any) => {
        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this employee?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60864d',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteEmployee(employee), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        getEmployees();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }


    useEffect(() => {
        setEmployees(null)
        getEmployees();
    }, [page, search, searchDistricts, searchBlocks, searchWards, searchGpus])



    return (
        <React.Fragment>
            <Header ></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`Employee`}
                            breadcrumbs={{
                                'Employee': '#',
                            }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " data-aos="fade-up" >
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Employee List</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            {/* <Search setSearch={(str: string) => {
                                                setSearch(str)
                                                setEmployees(null)
                                            }}></Search> */}
                                            {permissions.menus.Employee.write &&
                                                <Link to={URLS.EMPLOYEE_ADD} className="btn btn-primary"><GoPlus /> Add New</Link>
                                            }

                                        </div>
                                    </div>


                                    <SearchWithLocation
                                        setSearch={setSearch}
                                        setDistricts={setSearchDistricts}
                                        setBlocks={setSearchBlocks}
                                        setGpus={setSearchGpus}
                                        setWards={setSearchWards}
                                    />



                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Emp. ID</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Department</th>
                                                    <th scope="col">Designation</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {
                                                    employees?.map(data => (
                                                        <tr key={data.fkRefEmployeeID}>
                                                            <td>{data.fkRefEmployeeID}</td>
                                                            <td>{data.displayName}</td>
                                                            <td>{data.department ? data.department : '--'}</td>
                                                            <td>{data.designation ? data.designation : '--'}</td>
                                                            <td className="text-truncate" title={data?.email} style={{ maxWidth: 200 }}>{data.email ? data.email : '--'}</td>
                                                            <td>{data.phone}</td>
                                                            <td>
                                                                <Status status={data.isActive} />
                                                            </td>
                                                            <td>
                                                                <div className="action-btns">
                                                                    {permissions.menus.Employee.write &&
                                                                        <>
                                                                            {(userdata.userID != data.userID) && <Form.Check // prettier-ignore
                                                                                type="switch"
                                                                                id={`status-switch-${data.userID}`}
                                                                                label=""
                                                                                onChange={(e) => {
                                                                                    changeStatusHandler(e.target, data)
                                                                                }}
                                                                                defaultChecked={data.isActive}
                                                                            />
                                                                            }

                                                                            <Link to={replaceUrlParams(URLS.EMPLOYEE_EDIT, { employeeID: data.userID })}><Icon name="edit" size={18} /></Link>
                                                                        </>

                                                                    }

                                                                    <Link to={replaceUrlParams(URLS.EMPLOYEE_DETAILS, { employeeID: data.userID })}><Icon name="view" size={18} /></Link>
                                                                    {/* <Link to={replaceUrlParams(URLS.EMPLOYEE_PERMISSION, { employeeID: data.userID })}><Icon name="user-lock" size={18}/></Link> */}
                                                                    {permissions.menus.Employee.delete && (userdata.userID != data.userID) &&
                                                                        <Icon name="delete" size={18} onClick={() => { deleteEmployeehandler(data) }} />
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <td></td>


                                            </tbody>
                                        </table>
                                        <TableLoader data={employees} />
                                    </div>

                                    {employees ? <Pagination total={total} page={page} setPage={(n: number) => {
                                        setPage(n)
                                        setEmployees(null)
                                    }} pageLimit={limit} /> : null}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default EmployeeList;