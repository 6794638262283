import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StateExtended } from '../interfaces/StateExtended'

export function useDistricts() {
  return useSelector((state: StateExtended) => state.master.districts)
}

export function useBlocks() {
  return useSelector((state: StateExtended) => state.master.blocks)
}

export function useGpus() {
  return useSelector((state: StateExtended) => state.master.gpus)
}

export function useWards() {
  return useSelector((state: StateExtended) => state.master.wards)
}

