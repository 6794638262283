import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';
// const API_BASE_URL = process.env.REACT_APP_API_URL;




export function* getAllEvents(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.GET_EVENTS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            if (resp.data.data.departments) {
                resp.data.data.departments.map((data: any) => {
                    data.label = data.department;
                    data.value = data.departmentID;
                    return data;
                })
            }
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllEventtypes(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.GET_EVENTS_TYPES, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            if (resp.data.data.eventType) {
                resp.data.data.eventType.map((data: any) => {
                    data.label = data.eventTypeName;
                    data.value = data.eventTypeID;
                    return data;
                })
            }
           
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getEventDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.GET_EVENT_DETAILS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateEvent(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.UPDATE_EVENT, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* saveEvent(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.SAVE_EVENT, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteEvent(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.DELETE_EVENT, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.DELETE, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* changeStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.CHANGE_STATUS_EVENT, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getAllEventorganiser(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.GET_EVENTS_ORGANISER, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            if (resp.data.data.organizer) {
                resp.data.data.organizer.map((data: any) => {
                    data.label = data.organizerName;
                    data.value = data.organizerID;
                    return data;
                })
            }
           
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* uploadEventAttendee(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.UPLOAD_EVENT_ATTENDEE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        if (resp.status == 200 && resp.data) {
            if (resp.data.data.organizer) {
                resp.data.data.organizer.map((data: any) => {
                    data.label = data.organizerName;
                    data.value = data.organizerID;
                    return data;
                })
            }
           
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* getEventFarmerImportAll(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EVENT.GET_EVENT_FARMER_IMPORT_ALL, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



