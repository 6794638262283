import React, { useState } from "react";
import DashboardImgPreview from "../../modals/dashboardImgPreview.modal";
import TableLoader from "../../components/tableLoader";

interface props {
    currentWeather: any,
    areaPictures: any
}

const LandImages = ({ currentWeather, areaPictures }: props) => {
    const [uploadImageModal, setUploadImageModal] = useState<boolean>(false);
    const [selectImgIndex, setSelectImgIndex] = useState<number>(0);

    return (
        <React.Fragment>
            <div className="col-12">
                <div className="white-box mt-4">

                    <div className="white-graph-box-head mb-3">
                        <h2 className="box-title mb-0">{currentWeather?.locationName} Land Images</h2>
                        {/* <span className="tip-btn right-bar-toggle"><img src="/images/tip-icon.png" alt="" /> Tips <FaArrowRight className="ms-2" /></span> */}
                    </div>
                    <div className="img-gal-wrap ">
                        <div className="img-scroll">
                            {areaPictures?.map((picture: any, i: number) => (
                                <img src={picture.locationImageUrl}
                                    onClick={() => { setUploadImageModal(true); setSelectImgIndex(i) }}
                                    alt={`${currentWeather?.locationName} - ${i + 1}`} key={`land-image-${i}`} />
                            ))}


                            <TableLoader data={areaPictures} />
                        </div>
                    </div>
                </div>
            </div>

            {
                uploadImageModal && <DashboardImgPreview show={uploadImageModal}
                    onClose={setUploadImageModal}
                    images={areaPictures}
                    selectImgIndex={selectImgIndex}
                />
            }

        </React.Fragment>
    )

}

export default LandImages;