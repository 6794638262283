import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useUser } from "../../_common/hooks/authHook";
import { getSelectedValue, toastHandeler } from "../../_common/functions";
import Loader from "../../components/loader";
import Select from "react-select";
import { useDeviceInstallationStationApi } from "../../_common/hooks/api/deviceInstallationStationHook";
import Error from "../../components/formFieldError";
import { useFarmsApi } from "../../_common/hooks/api/farmApiHook";
import Icon from "../../components/icon";

interface props {
    show: any
    handleClose: any
    crop: any
}

const CropProductionMethodsMap = ({ show, handleClose, crop }: props) => {
    const farmsApi = useFarmsApi()
    const user: any = useUser();
    const deviceInstallationStationAPI = useDeviceInstallationStationApi();
    const [cropProductionList, setCropProductionList] = useState<any[] | null>(null);
    const [cropSelectedList, setCropSelectedList] = useState<any[] | null>(null);

    const { control, reset, formState: { errors }, handleSubmit, setValue, } = useForm();

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormProvider)
        name: "crops", // unique name for your Field Array
    });


    const getStationCropAll = () => {
        return new Promise((resolve, reject) => {
            deviceInstallationStationAPI.getStationCropAll({
                urlParams: { FarmID: crop?.FarmID }
            }, (message: any, resp: any) => {
                const tempArr = resp.data.stationCrop;
                tempArr && tempArr?.map((tempItem: any) => {
                    tempItem.label = tempItem.cropName
                    tempItem.value = tempItem.cropID
                })
                setCropProductionList(tempArr)
                // reset(tempArr);
                resolve(message)
            }, reject)
        })
    }

    const getFarmsCropMappingList = () => {
        remove();
        setCropSelectedList(null)
        return new Promise((resolve, reject) => {
            farmsApi.getFarmsCropMappingList({
                FarmID: crop.farmID
            }, (message: any, resp: any) => {
                if (resp.data.farmsCropList && resp.data.farmsCropList.length > 0) {
                    setCropSelectedList(resp.data.farmsCropList)

                    resp.data.farmsCropList.map((cropItem: any) => {
                        append({ cropData: getSelectedValue(cropItem.cropID, 'value', cropProductionList ? cropProductionList : []), cropArea: cropItem.cropArea })
                    })
                } else {
                    append({ cropData: null, cropArea: '' })
                    setCropSelectedList([])
                }

                resolve(message)
            }, reject)
        })
    }


    const saveData = (params: any) => {
        return new Promise((resolve: any, reject: any) => {
            farmsApi.farmCropsMappingSave(params, resolve, reject)
        })
    }


    const onSubmitHandler = (data: any) => {
        toastHandeler(saveData, {
            "farmID": crop.farmID,
            addedBy: user.userID,
            "cropsList": data.crops.map((item: any) => { return { cropID: item?.cropData?.value, cropArea: item.cropArea } })
        }, 'Updating...').then(() => {
            getFarmsCropMappingList()
        })
    }


    useEffect(() => {
        getStationCropAll()
    }, [])


    useEffect(() => {
        if (show) {
            getFarmsCropMappingList()
        } else {
            remove()
        }
    }, [show])

    console.log('errors', errors)


    return (
        <Offcanvas show={show} onHide={handleClose} placement={"end"} className="farming-method-offcanvas">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Crop Production Map</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {cropSelectedList ?

                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="row col-md-12">
                            {fields.map((fieldRow, index) => (
                                <div className="row" key={`${fieldRow?.id}`}>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label>Crop Name</label>
                                            <Controller
                                                defaultValue={""}
                                                control={control}
                                                name={`crops.${index}.cropData`}
                                                rules={{ required: "Crop name is required" }}
                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                    <>
                                                        <Select
                                                            name={name}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            options={cropProductionList ? cropProductionList : []}
                                                            value={value}
                                                        />
                                                        <Error error={(errors as any)?.crops?.[index]?.cropData} />
                                                    </>
                                                )} />
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="mb-3">
                                            <label>Crop Area</label>

                                            <Controller
                                                defaultValue={""}
                                                control={control}
                                                name={`crops.${index}.cropArea`}
                                                rules={{ required: "Crop area is required" }}
                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                    <>
                                                        <input type="text" onChange={onChange} value={value} className="form-control" placeholder="Crop Area" />
                                                        <Error error={(errors as any)?.crops?.[index]?.cropArea} />
                                                    </>
                                                )} />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <div className="mt-4">
                                            <Icon name="delete" onClick={() => remove(index)} className="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            ))}



                        </div>
                        <div className="d-flex gap-2">
                            <Button type="button" onClick={() => append({ cropData: null, cropArea: "" })} >
                                <Icon name="plus" size={14} /> Add More
                            </Button>
                            <Button type="submit">Save</Button>
                        </div>
                    </form>

                    :
                    <Loader />}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default CropProductionMethodsMap;
