import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';



export function* cropCategoryList(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_CATEGORY.LIST, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropCategoryDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_CATEGORY.DETAILS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropCategorySave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_CATEGORY.SAVE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropCategoryUpdate(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_CATEGORY.UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropCategoryChangeStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_CATEGORY.CHANGE_STATUS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropCategoryDelete(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_CATEGORY.DELETE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.DELETE, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* cropList(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.LIST, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.DETAILS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropSave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.SAVE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropUpdate(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropChangeStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.CHANGE_STATUS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropDelete(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.DELETE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.DELETE, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* cropFileUpload(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.FILE_UPLOAD, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* cropCultivationMethods(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.CULTIVATION_METHODS_VIEW, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* updateCropCultivationMethods(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.CULTIVATION_METHODS_UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* cropProtectionMethods(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.PROTECTION_METHODS_VIEW, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* updateCropProtectionMethods(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP.PROTECTION_METHODS_UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* cropProtectionMethodList(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_PROTECTION_METHOD.LIST, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropProtectionMethodDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_PROTECTION_METHOD.DETAILS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropProtectionMethodSave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_PROTECTION_METHOD.SAVE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropProtectionMethodUpdate(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_PROTECTION_METHOD.UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropProtectionMethodChangeStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_PROTECTION_METHOD.CHANGE_STATUS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cropProtectionMethodDelete(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.COLLATERAL.CROP_PROTECTION_METHOD.DELETE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.DELETE, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}






