import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StateExtended } from '../interfaces/StateExtended'

export function useAuthStatus() {
  const user = useSelector((state: StateExtended) => state.user)

  const authChecker = () => {
    return (user && user.isAuthenticated && user.user) ? true : false
  }

  const [isAuthenticated, setAuthenticated] = useState(authChecker())

  useEffect(() => {
    setAuthenticated(authChecker())
  }, [user]);

  return isAuthenticated
}

export function useUser() {
  const user = useSelector((state: StateExtended) => state.user)
  return user.user
}

export function useUserPermissions() {
  const user:any = useSelector((state: StateExtended) => state.user)
  return user.permissions
}

