import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useUser } from "../../_common/hooks/authHook";
import { getSelectedValue, toastHandeler } from "../../_common/functions";
import Loader from "../../components/loader";
import Select from "react-select";
import { useDeviceInstallationStationApi } from "../../_common/hooks/api/deviceInstallationStationHook";
import Error from "../../components/formFieldError";

interface props {
    show: any
    handleClose: any
    crop: any
}
const IotDeviceMethods = ({ show, handleClose, crop }: props) => {
    const user: any = useUser();
    const deviceInstallationStationAPI = useDeviceInstallationStationApi();
    const [cropProductionList, setCropProductionList] = useState<any[] | null>(null);

    const { control, reset, formState: { errors }, handleSubmit, setValue, } = useForm();

    const getUnAssignedStationDevice = () => {
        return new Promise((resolve, reject) => {
            deviceInstallationStationAPI.getUnAssignedStationDevice({
                urlParams: { InstallationStationID: crop.installationStationID }
            }, (message: any, resp: any) => {
                const tempArr = resp.data.stationDevice;
                tempArr && tempArr?.map((tempItem: any) => {
                    tempItem.label = tempItem.name
                    tempItem.value = tempItem.deviceId
                })
                setCropProductionList(tempArr)
                reset(tempArr);
                resolve(message)
            }, reject)
        })
    }

    const getAssignedStationDevice = () => {
        return new Promise((resolve, reject) => {
            deviceInstallationStationAPI.getAssignedStationDevice({
                urlParams: { InstallationStationID: crop.installationStationID }
            }, (message: any, resp: any) => {
                const tempArr = resp.data.stationDevice;

                let resetValues = {
                    deviceIDs: getSelectedValue(
                        tempArr.map((item: any) => {
                            return item.deviceId;
                        }), 'value', cropProductionList ? cropProductionList : []),
                }
                reset(resetValues);
                resolve(message)
            }, reject)
        })
    }


    const saveData = (params: any) => {
        return new Promise((resolve: any, reject: any) => {
            deviceInstallationStationAPI.saveStationDevice(params, resolve, reject)
        })
    }


    const onSubmitHandler = (data: any) => {
        let selected: any = []
        data.deviceIDs.map((item: any) => {
            selected.push({ deviceID: item.value })
        })

        toastHandeler(saveData, {
            installationStationID: crop.installationStationID,
            addedBy: user.userID,
            deviceList: selected
        }, 'Updating...').then(() => {
            getUnAssignedStationDevice();
            getAssignedStationDevice()
        })
    }




    useEffect(() => {
        if (show) {
            getUnAssignedStationDevice()
        }
        return () => {
            setCropProductionList(null)
        }
    }, [show])


    useEffect(() => {
        if (cropProductionList && cropProductionList?.length > 0) {
            getAssignedStationDevice()
        }
    }, [cropProductionList])


    return (
        <Offcanvas show={show} onHide={handleClose} placement={"end"} className="farming-method-offcanvas">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>IOT Device  </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {cropProductionList ?

                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="row col-md-12">

                            <div className="col-sm-12">
                                <div className="mb-3">
                                    <label>Station Device</label>
                                    <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="deviceIDs"
                                        // rules={{ required: 'Station Device is required' }}
                                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                            <Select
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                options={cropProductionList ? cropProductionList : []}
                                                value={value}
                                                isMulti={true}
                                            />
                                        )}
                                    />
                                    <Error error={errors['deviceIDs']} />
                                </div>
                            </div>

                        </div>
                        <div className="text-align-right">
                            <Button type="submit">Save</Button>
                        </div>
                    </form>

                    :
                    <Loader />}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default IotDeviceMethods;
