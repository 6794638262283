import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import { replaceUrlParams, toastHandeler } from "../../../_common/functions";
import Icon from "../../../components/icon";
import { URLS } from "../../../_config";
import Footer from "../../../components/footer/Footer";
import TitleBar from "../../../components/TopBar/TitleBar";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import { Controller, useForm } from "react-hook-form";
import  Editor from "react-simple-wysiwyg";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useUser } from "../../../_common/hooks/authHook";

const CultivationMethods = () => {
    const params: any = useParams();
    const cropID  = params.id;
    const user:any  = useUser();
    const collateralApi = useCollatralApi()
    const [cultivationMethodsData, setCultivationMethodsData] = useState<any>({})
    const [cropDetails, setCropDetails] = useState<any>(null)

    const { control, reset, formState: { errors }, handleSubmit, setValue, } = useForm();

    const navigate = useNavigate();


    const getCropDetails = () => {
        collateralApi.cropDetails({ urlParams: { CropID: cropID } },
            (message: any, resp: any) => {
                setCropDetails(resp.data.crop[0])
            }, (message: any) => { })
    }


    const getCropCultivationMethods = () => {
        collateralApi.cropCultivationMethods({
            urlParams: { CropID: cropID }
        }, (message: any, resp: any) => {
            setCultivationMethodsData(resp.data.cropCultivationMethod)
            let prevData:any = {};
            resp.data.cropCultivationMethod.map((method:any)=>{
                prevData[`answer-${method.questionID}`] = method.answer
            })

            reset(prevData)
        }, (message: any) => {

        })
    }

    const saveData = (params:any)=>{
        return new Promise((resolve:any,reject:any)=>{
            collateralApi.saveCultivationMethods(params,resolve,reject)
        })
    }


    const onSubmit = (data: any) => {
        console.log(data);


        toastHandeler(saveData, {
            cropID: cropID,
            addedBy: user.userID,
            cultivationMethodList: Object.keys(data).map((key:any)=>{
                return {
                    questionID: key.replace('answer-',''),
                    answer: data[key]
                }
            })
        }, 'Updating...').then(()=>{
            navigate(replaceUrlParams(URLS.COLLATERAL.CROP.CULTIVATION_METHODS_VIEW, { id: cropID }))
        })
    }


    useEffect(() => {
        getCropCultivationMethods();
        getCropDetails()
    }, [cropID])

    return (



        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">


                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`Cultivation Methods`}
                            breadcrumbs={{
                                'Collateral': '#',
                                'Farming Methods': URLS.COLLATERAL.CROP.LIST,
                                'Cultivation Methods': '#',
                            }} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 ">

                                    <div className="row" data-aos="fade-up">
                                        <div className="col-sm-12">
                                            <div className="d-flex justify-content-between ">
                                                <h3 className="card-heading mb-4 ">Cultivation Methods of {cropDetails?.cropName}</h3>
                                                <div className="d-flex justify-content-end position-relative ">
                                                    <Link to={replaceUrlParams(URLS.COLLATERAL.CROP.CULTIVATION_METHODS_VIEW, { id: cropID })} className="btn btn-primary d-flex gap-2 align-items-center rounded-pill-btn edit-btn right-0 bottom-0">
                                                        <Icon name="view" size={16} />
                                                    </Link>
                                                </div>
                                            </div>


                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="row">
                                                    {
                                                        cultivationMethodsData && cultivationMethodsData.length > 0 &&
                                                        cultivationMethodsData.map((item: any) => (
                                                            <div key={`questionID${item?.questionID}`}>
                                                                <div className="col-sm-12">
                                                                    <div className="mb-3">
                                                                        <label>{item?.question}</label>
                                                                        <div>
                                                                            {/* <textarea name="" ></textarea> */}
                                                          
                                                                            <Controller
                                                                                defaultValue=""
                                                                                control={control}
                                                                                name={`answer-${item?.questionID}`}
                                                                                rules={{ required: false }}
                                                                                render={({ field: { onChange, value, ref } }) => (
                                                                                    <Editor value={value} onChange={onChange} style={{minHeight:'172px'}} />
                                                                                )}
                                                                            />
                                                                            
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))
                                                    }


                                                </div>
                                                    
                                                <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                    <Link to={replaceUrlParams(URLS.COLLATERAL.CROP.CULTIVATION_METHODS_VIEW, { id: cropID })} className="btn btn-default">Back</Link>
                                                    <Button type="submit">Save</Button>
                                                </div>
                                            </form>

                                            
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
              

                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default CultivationMethods;