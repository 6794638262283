/* 
    this component use for breadcrumps and page title 
*/

import React, { HtmlHTMLAttributes } from 'react'
import { Link } from 'react-router-dom';
import { URLS } from '../_config';

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    title: any;
    value?: any;
    icon?: any;
}
const DetailsElement: React.FC<props> = ({
    children,
    title,
    value,
    icon,
    ...args
}) => {

    return (
        <div style={icon ? { paddingLeft: '26px', position: 'relative' } : {}}>
            {icon && <span className='light-text' style={{ left: '0', top: '-3px', position: 'absolute' }}> {icon}</span>}

            <h5 className="xsm-text light-text mb-0 text-uppercase">{title}</h5>
            {value ? <p>{value}</p> : children}
        </div>
    )
}

export default DetailsElement;
