import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import TitleBar from "../../components/TopBar/TitleBar";
import { Link, useNavigate } from "react-router-dom";
import { URLS } from "../../_config";
import toast from "react-hot-toast";
import { useUser } from "../../_common/hooks/authHook";
import { Controller, useForm } from "react-hook-form";
import Error from "../../components/formFieldError";
import Editor from "react-simple-wysiwyg";
import FormTextInput from "../../_common/form-elements/textinput/formTextInput";
import { useNotificationApi } from "../../_common/hooks/api/notificationApiHook";
import Select from "react-select";
import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";
import { getRelativeListWithMultiSelected } from "../../_common/functions";
import Footer from "../../components/footer/Footer";

const NotificationBroadcast = () => {
    const notificationApi = useNotificationApi()
    const user: any = useUser()
    const navigate: any = useNavigate();
    const {
        control,
        reset,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
    } = useForm();

    const [submitBtnDisable, setSubmitBtnDisable] = useState<boolean>(false);

    const [filteredBlocks, setFilteredBlocks] = useState<any[]>([]);
    const [filteredGpus, setFilteredGpus] = useState<any[] | null>(null)
    const [filteredWards, setFilteredWards] = useState<any[] | null>(null)

    const districts: any = useDistricts();
    const blocks: any = useBlocks();
    const gpus: any = useGpus();
    const wards: any = useWards();

    const districtWatch = watch("district");
    const blockWatch = watch("block");
    const gpuWatch = watch("gpu");
    const wardWatch = watch("ward");


    const saveData = (data: any) => {
        return new Promise((resolve, reject) => {
            let params: any = {
                "subject": data.subject,
                "notificationMessage": data.notificationMessage,
                "addedBy": user?.userID,
                "senderID": user?.userID,
                "senderName": user?.displayName,
                "districtIDs": data.district ? data.district.map((dist: any) => { return dist.districtId }).join() : '',
                "blockIDs": data.block ? data.block.map((dist: any) => { return dist.blockID }).join() : '',
                "gpuiDs": data.gpu ? data.gpu.map((dist: any) => { return dist.gpiid }).join() : '',
                "wardIDs": data.ward ? data.ward.map((dist: any) => { return dist.wardID }).join() : '',
            }
            console.log('params', params)
            notificationApi.notificationStore(params, resolve, reject)
        })
    }

    const saveNotificationHandler = (data: any) => {
        setSubmitBtnDisable(true)
        toast.promise(
            saveData(data), {
            loading: 'Updating...',
            success: (msg: any) => {
                navigate(URLS.NOTIFICATION.MANUAL_NOTIFICATION)
                return <b>{msg}</b>
            },
            error: (msg: any) => {
                setSubmitBtnDisable(false);
                return <b>{msg}</b>
            },
        });
    }

    useEffect(() => {
        let { list, selected } = getRelativeListWithMultiSelected(
            districtWatch,
            blocks,
            blockWatch,
            "districtId",
            "fkDistrictID",
            "blockID"
        );
        setFilteredBlocks(list);
        setValue("block", selected);
    }, [districtWatch]);

    useEffect(() => {
        let { list, selected } = getRelativeListWithMultiSelected(
            blockWatch,
            gpus,
            gpuWatch,
            "blockID",
            "fkBlockID",
            "gpiid"
        );
        setFilteredGpus(list);
        setValue("gpu", selected);
    }, [blockWatch]);

    useEffect(() => {
        let { list, selected } = getRelativeListWithMultiSelected(
            gpuWatch,
            wards,
            wardWatch,
            "gpiid",
            "fkgpuid",
            "wardID"
        );
        setFilteredWards(list);
        setValue("ward", selected);
    }, [gpuWatch]);

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Notification Broadcast`}
                            breadcrumbs={{
                                'Notification': URLS.NOTIFICATION.MANUAL_NOTIFICATION,
                                'Broadcast': '#',
                            }} />

                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 ">
                                    <form className="row" action="" onSubmit={handleSubmit(saveNotificationHandler)}>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h3 className="card-heading mb-4 ">Notification Broadcast</h3>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group float-label-wrap mb-3">
                                                            <label className="float-label">Notification Title</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="subject"
                                                                rules={{ required: "Notification Title is required" }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <FormTextInput
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        inputRef={ref}
                                                                        type="text"
                                                                        placeholder="Enter Title"
                                                                        error={errors.subject} />
                                                                )} />
                                                            <Error error={errors["subject"]} />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className="form-group float-label-wrap mb-0">
                                                            <label htmlFor="" className="float-label">Select District</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="district"
                                                                rules={{ required: "District is required" }}
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={districts ? districts : []}
                                                                        value={value}
                                                                        isMulti={true}
                                                                    />
                                                                )} />
                                                            <Error error={errors["district"]} />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className="form-group float-label-wrap mb-0">
                                                            <label htmlFor="" className="float-label">Select Block</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="block"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={filteredBlocks ? filteredBlocks : []}
                                                                        value={value}
                                                                        isMulti={true}
                                                                    />
                                                                )} />
                                                            <Error error={errors["block"]} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group float-label-wrap mb-0">
                                                            <label htmlFor="" className="float-label">Select GPU</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="gpu"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={filteredGpus != null ? filteredGpus : gpus}
                                                                        value={value}
                                                                        isMulti={true}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group float-label-wrap mb-0">
                                                            <label htmlFor="" className="float-label">Select Ward</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="ward"
                                                                render={({
                                                                    field: { onChange, onBlur, value, name, ref },
                                                                }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={filteredWards != null ? filteredWards : wards}
                                                                        value={value}
                                                                        isMulti={true}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mt-2">
                                                        <label>Notification Message</label>
                                                        <Controller
                                                            defaultValue={""}
                                                            control={control}
                                                            name="notificationMessage"
                                                            rules={{ required: "Notification Message is required" }}
                                                            render={({
                                                                field: { onChange, onBlur, value, name, ref },
                                                            }) => (
                                                                <Editor value={value} onChange={onChange} />
                                                            )}
                                                        />

                                                        <Error error={errors["notificationMessage"]} />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="mt-3 d-flex gap-2 justify-content-end ">
                                                <Link to={URLS.NOTIFICATION.MANUAL_NOTIFICATION} className="btn btn-default">Cancel</Link>
                                                <button type="submit" className="btn btn-primary" disabled={submitBtnDisable}>Send</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default NotificationBroadcast;