import moment from 'moment';
import React, { PureComponent, useEffect, useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, Line, ComposedChart, Rectangle, Legend } from 'recharts';
import { _moistureEmoji } from '../../_common/functions';
import OverlayLoader from '../../components/loader/OverlayLoader';
interface props {
    data: any
}
const ChartSoilMoisture = ({ data }: props) => {
    class CustomizedXAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={56} y={0} dy={10} fontSize={10} textAnchor="end" fill="#222222" transform="rotate(50)">
                        {payload.value}
                    </text>
                </g>
            );
        }
    }
    class CustomizedYAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={0} textAnchor="end" fontSize={11} fill="#222222" transform="rotate(0)">
                        {payload.value.toFixed(2)}
                        {/* {(intervalType === 'day') ? payload.value.substring(0, 3) : payload.value} */}
                    </text>
                </g>
            );
        }
    }
    const [avg, setAvg] = useState<any>(0)

    useEffect(() => {
        if (data?.objDataLst) {
            let _avg: any = 0;
            data?.objDataLst.map((d: any) => {
                _avg += parseFloat(d.value)
                d['Avg Moisture'] = d.value;
                d['Min Moisture'] = d.minValue;
                d['Max Moisture'] = d.maxValue;
                d['name_2'] = moment(d.name).format('DD-MM-YYYY');
                return d
            });
            setAvg((_avg / data.objDataLst.length).toFixed(2))
        }
    }, [data])

    const renderLegend = (props: any) => {
        const { payload } = props;
        return (
            <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ gap: '0 10px' }}>
                {
                    payload.map((entry: any, index: any) => {
                        let lable = entry.value;
                        let icon: any = '';
                        switch (entry.value) {
                            case 'Avg Moisture':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 21H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z"></path></svg>
                                lable = 'Avg'
                                break;
                            case 'Max Moisture':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z"></path></svg>
                                lable = 'Max'
                                break;
                            case 'Min Moisture':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M112 476h160v72H112zm320 0h160v72H432zm320 0h160v72H752z"></path></svg>
                                lable = 'Min'
                                break;
                            default:
                                lable = entry.value
                        }
                        return (
                            <div className="d-flex justify-content-center align-items-center gap-1" key={`item-${index}`}>
                                {icon}
                                <p className="m-0 text-nowrap font-size-11 font-weight-medium" style={{ color: '#555b64' }}>{lable}</p>
                            </div>
                        )
                    })
                }
            </div>
        );
    }


    return (
        <React.Fragment>

            <div className="white-graph-box mt-5">
                <div className="white-graph-box-head">
                    <h2 className='d-flex align-item-center'>Soil Moisture: {(avg >= 0) ?
                        <span className='d-flex align-item-center'>
                            {avg} %
                            <img src={_moistureEmoji(avg)} alt="" width={30} />
                        </span> : <em>No data available</em>}
                    </h2>
                </div>


                <div className="demo-graph-wrap position-relative" style={{ height: '250px' }}>
                    <OverlayLoader chartsLoading={data?.chartsLoading} />

                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart width={730} height={250} data={data?.objDataLst}
                            margin={{ top: 5, right: 25, left: 0, bottom: 30, }}>

                            <XAxis dataKey="name_2" tick={<CustomizedXAxisTick />} />
                            <YAxis domain={['dataMin', 'dataMax ']} tick={<CustomizedYAxisTick />} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip
                                contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                            />
                            <defs>
                                <linearGradient id="soilMoist" x1="0%" x2="0%" y1="0%" y2="100%">
                                    <stop offset="0%" stopColor="#76A9FA" />
                                    <stop offset="100%" stopColor="#A4CAFE" />
                                </linearGradient>
                            </defs>

                            <Bar dataKey="Avg Moisture" fill="url(#soilMoist)" stackId={'a'} radius={5} activeBar={<Rectangle radius={5} />} />
                            <Line type="monotone" dataKey="Max Moisture" stroke="#1A56DB" strokeWidth={3} dot={false} activeDot={{ r: 4 }} />
                            <Line type="monotone" dataKey="Min Moisture" stroke="#1C64F2" strokeWidth={2} strokeDasharray="5 5" dot={false} activeDot={{ r: 4 }} />
                            <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                        </ComposedChart>
                    </ResponsiveContainer>

                </div>
            </div>
        </React.Fragment>
    )

}

export default ChartSoilMoisture;