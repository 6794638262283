import React, { PureComponent } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ChartSoilPH = () => {
    const data = [
        {
            name: 'Page A',
            // uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            // uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            // uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            // uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            // uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            // uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            // uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <React.Fragment>
            <div className="white-graph-box mt-5">
                <div className="white-graph-box-head">
                    <h2>Soil pH<span>7.5</span> <em>Alkaline Soil</em></h2>
                </div>
                <div className="device-name-graph"><img src="/images/device-icon.svg" alt="" /> Device Name</div>
                {/* <div className="demo-graph-wrap">
                    <img src="/images/g6.jpg" alt="" />
                </div> */}
                <div className="demo-graph-wrap" style={{ height: '250px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 10,
                                left: 0,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="pv" fill="#6e84ea" activeBar={<Rectangle fill="#6e84ea" stroke="blue" />} />
                            {/* <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} /> */}
                        </BarChart>
                    </ResponsiveContainer>
                </div>

            </div>
        </React.Fragment>
    )

}

export default ChartSoilPH;