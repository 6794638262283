import React from "react";
import { Link } from "react-router-dom";
import { URLS } from "../_config";
import Icon from "../components/icon";

const Page404 = () => {
    return (
        <React.Fragment>
            <div className="error-content">
                <div className="container-fluid text-center">
                    <div className="error-text">404</div>
                    <Link to={URLS.DASHBOARD} className="btn btn-primary">
                        <Icon name="home" size={16} /> &nbsp;  Back to Home
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Page404;