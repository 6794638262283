import moment from 'moment';
import React, { PureComponent, useEffect, useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Area, Legend } from 'recharts';
import { _phEmoji, _phText } from '../../_common/functions';
import OverlayLoader from '../../components/loader/OverlayLoader';
interface props {
    data: any
}
const ChartSoilPH = ({ data }: props) => {
    class CustomizedXAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={56} y={0} dy={10} fontSize={10} textAnchor="end" fill="#222222" transform="rotate(50)">
                        {payload.value}
                    </text>
                </g>
            );
        }
    }

    class CustomizedYAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={0} textAnchor="end" fontSize={11} fill="#222222" transform="rotate(0)">
                        {payload.value.toFixed(2)}
                    </text>
                </g>
            );
        }
    }
    const [avg, setAvg] = useState<any>(0)

    useEffect(() => {
        if (data?.objDataLst) {
            let _avg: any = 0;
            data?.objDataLst.map((d: any) => {
                _avg += parseFloat(d.value)
                d['pH'] = d.value;
                d['Min pH'] = d.minValue;
                d['Max pH'] = d.maxValue;
                d['name_2'] = moment(d.name).format('DD-MM-YYYY');
                return d
            });
            setAvg((_avg / data.objDataLst.length).toFixed(2))
        }
    }, [data])

    const renderLegend = (props: any) => {
        const { payload } = props;
        return (
            <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ gap: '0 10px' }}>
                {
                    payload.map((entry: any, index: any) => {
                        let lable = entry.value;
                        let icon: any = '';
                        switch (entry.value) {
                            case 'pH':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M20 18a1 1 0 0 1 .117 1.993l-.117 .007h-16a1 1 0 0 1 -.117 -1.993l.117 -.007h16z"></path><path d="M15.22 5.375a1 1 0 0 1 1.393 -.165l.094 .083l4 4a1 1 0 0 1 .284 .576l.009 .131v5a1 1 0 0 1 -.883 .993l-.117 .007h-16.022l-.11 -.009l-.11 -.02l-.107 -.034l-.105 -.046l-.1 -.059l-.094 -.07l-.06 -.055l-.072 -.082l-.064 -.089l-.054 -.096l-.016 -.035l-.04 -.103l-.027 -.106l-.015 -.108l-.004 -.11l.009 -.11l.019 -.105c.01 -.04 .022 -.077 .035 -.112l.046 -.105l.059 -.1l4 -6a1 1 0 0 1 1.165 -.39l.114 .05l3.277 1.638l3.495 -4.369z"></path></svg>
                                lable = 'pH'
                                break;
                            case 'Max pH':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z"></path></svg>
                                lable = 'Max'
                                break;
                            case 'Min pH':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M112 476h160v72H112zm320 0h160v72H432zm320 0h160v72H752z"></path></svg>
                                lable = 'Min'
                                break;
                            default:
                                lable = entry.value
                        }
                        return (
                            <div className="d-flex justify-content-center align-items-center gap-1" key={`item-${index}`}>
                                {icon}
                                <p className="m-0 text-nowrap font-size-11 font-weight-medium" style={{ color: '#555b64' }}>{lable}</p>
                            </div>
                        )
                    })
                }
            </div>
        );
    }



    return (
        <React.Fragment>
            <div className="white-graph-box mt-5">
                <div className="white-graph-box-head">
                    <h2 className='d-flex align-item-center'>Soil pH:  {(avg >= 0) ?
                        <span className='d-flex align-item-center'>
                            {avg} &nbsp;
                            <> {_phText(avg)}</>
                            <img src={_phEmoji(avg)} alt="" width={30} />
                        </span>
                        : <em>No data available</em>}
                    </h2>
                </div>


                <div className="demo-graph-wrap position-relative" style={{ height: '250px' }}>
                    <OverlayLoader chartsLoading={data?.chartsLoading} />

                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={300}
                            data={data?.objDataLst}
                            margin={{ top: 5, right: 25, left: 0, bottom: 30, }}
                        >
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#b1f2b2" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#d7f8d9" stopOpacity={0.5} />
                                </linearGradient>
                            </defs>

                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name_2" tick={<CustomizedXAxisTick />} />
                            <YAxis domain={['dataMin', 'dataMax ']} tick={<CustomizedYAxisTick />} />
                            <Tooltip
                                contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                            />

                            <Area type="monotone" dataKey="pH" stroke="#91d87a" fillOpacity={1} fill="url(#colorUv)" strokeWidth={3} />
                            <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                        </ComposedChart>
                    </ResponsiveContainer>

                </div>

            </div>
        </React.Fragment>
    )

}

export default ChartSoilPH;