import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import { URLS } from "../../../_config";
import Loader from "../../../components/loader";
import { replaceUrlParams, showDate } from "../../../_common/functions";
import Footer from "../../../components/footer/Footer";
import Icon from "../../../components/icon";
import DetailsElement from "../../../components/detailsElement";

const CropDetails = () => {
    const collateralApi = useCollatralApi();
    const params: any = useParams();
    const [dataID, setDataID] = useState<any>(params.id)
    const [cropDetails, setCropDetails] = useState<any>(null)

    const getCropDetails = () => {
        collateralApi.cropDetails({ urlParams: { CropID: dataID } },
            (message: any, resp: any) => {
                setCropDetails(resp.data.crop[0])
            }, (message: any) => { })
    }

    useEffect(() => {
        getCropDetails();
    }, [dataID])

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title="Farming Method Details" breadcrumbs={{
                            'Collateral': '#',
                            'Farming Methods': URLS.COLLATERAL.CROP.LIST,
                            [cropDetails ? cropDetails.cropName : '']: '#',
                        }} />

                        {
                            (cropDetails == null) ? <Loader /> :

                                <div className="row" data-aos="fade-up">
                                    <div className="col-sm-3">

                                        <div className="white-box-card profile-card  ">
                                            <div className="profile-avatar mb-0">
                                                <img src={cropDetails?.cropImage} alt={cropDetails?.cropName} />
                                            </div>
                                            <h4 className="mt-2">{cropDetails?.cropName}</h4>

                                        </div>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="white-box-card mb-4 h-100 ">

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="card-heading mb-4">Information</h3>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <DetailsElement title={'crop name'} value={cropDetails?.cropName} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <DetailsElement title={'category name'} value={cropDetails?.cropCategoryName} />
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <DetailsElement title={'Created Date'} value={showDate(cropDetails?.addedDate)} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <DetailsElement title={'DESCRIPTION'} value={cropDetails?.cropDescription} />
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            <Link to={replaceUrlParams(URLS.COLLATERAL.CROP.EDIT, { id: cropDetails?.cropID })} className="btn btn-primary d-flex gap-2 align-items-center rounded-pill-btn edit-btn"><Icon name="edit" /></Link>
                                        </div>

                                    </div>
                                </div>
                        }

                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default CropDetails;