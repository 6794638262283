import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { useUserApi } from "../../_common/hooks/api/userApiHook";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetFormValues } from "../../_common/form-props";
import { ResetnvalidationSchema } from "../../_common/form-validation";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { URLS } from "../../_config";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";


const ResetPassword = () => {
    const userApi = useUserApi();
    const navigate = useNavigate();
    const location: any = useLocation();
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showrePassword, setShowrePassword] = useState<boolean>(false)
    //const [searchParams] = useSearchParams();
    const urlParams: any = useParams();
    const token: any = urlParams.tokenID;
    //console.log("token id",token);

    const { showLoader, hideLoader } = useAppLoader()
    const { control, formState: { errors }, handleSubmit, } = useForm<ResetFormValues>({
        resolver: yupResolver(ResetnvalidationSchema),
        defaultValues: {
            newPassword: "",
            confirmNewPassword: "",
        },
    });
    const fullyear = new Date().getFullYear();
    const resetpass = (data: any) => {
        showLoader();

        userApi.resetpass({
            newPassword: data.newPassword,
            confirmNewPassword: data.confirmNewPassword,
            token: token
        }, (msg: any, resp: any) => {
            hideLoader();
            toast.success(msg);
            navigate(URLS.LOGIN);

        }, (msg: any) => {
            hideLoader();
            toast.error(msg);
            //  console.log(msg);
        })
    }

    useEffect(() => {
        hideLoader();
    }, [])

    return (
        <React.Fragment>
            <div className="login-bg">
                <div className="login-transp-bg">
                    <div className="login-panel-box">
                        <div className="login-logo"><img src="./images/logo.png" alt="" /></div>
                        <div className="d-flex">
                            <div className="login-flds-panel">
                                <div className="login-heading">
                                    <h1>Reset Password</h1>

                                </div>
                                <form autoComplete="off" onSubmit={handleSubmit(resetpass)}>
                                    <div className="login-flds-row mb-2">
                                        <label>Password</label>
                                        <Controller
                                            name="newPassword"
                                            control={control}
                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                <>
                                                    <div className="passwithicon">
                                                        <input
                                                            type={!showPassword ? "password" : "text"}
                                                            className={"form-control " + (errors?.newPassword ? ' border-danger' : '')}
                                                            id="newPassword"
                                                            autoComplete="new-password"
                                                            name={name}
                                                            onChange={onChange}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            placeholder="Enter password"
                                                        />
                                                        <span>
                                                            {!showPassword ?
                                                                <AiOutlineEyeInvisible onClick={() => setShowPassword(!showPassword)} />
                                                                :
                                                                <AiOutlineEye onClick={() => setShowPassword(!showPassword)} />
                                                            }
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        />


                                        {errors && errors.newPassword &&
                                            <span className='text-danger'>{errors?.newPassword?.message}</span>
                                        }
                                    </div>

                                    <div className="login-flds-row mb-2">
                                        <label>Confirm Password</label>
                                        <Controller
                                            name="confirmNewPassword"
                                            control={control}
                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                <>
                                                    <div className="passwithicon">
                                                        <input
                                                            type={!showrePassword ? "password" : "text"}
                                                            className={"form-control " + (errors?.confirmNewPassword ? ' border-danger' : '')}
                                                            id="confirmNewPassword"
                                                            autoComplete="new-password1"
                                                            name={name}
                                                            onChange={onChange}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            placeholder="Enter confirm password"
                                                        />
                                                        <span>
                                                            {!showrePassword ?
                                                                <AiOutlineEyeInvisible onClick={() => setShowrePassword(!showrePassword)} />
                                                                :
                                                                <AiOutlineEye onClick={() => setShowrePassword(!showrePassword)} />
                                                            }
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        />


                                        {errors && errors.newPassword &&
                                            <span className='text-danger'>{errors?.confirmNewPassword?.message}</span>
                                        }
                                    </div>
                                    <p>Remember It ?   <Link to={URLS.LOGIN} className="font-weight-medium text-primary" > Sign In here</Link> </p>
                                    <div className="d-flex justify-content-center pt-4">
                                        <Button className="btn btn-primary custom-btn-lg" type="submit">Submit</Button>
                                    </div>
                                </form>
                            </div>

                            <div className="login-pic">
                                <img src="/images/loginImg4.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <p className="copyright">
                    {fullyear} © Precision Farming </p>
            </div>
        </React.Fragment>
    )
}

export default ResetPassword;