import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import { useDeviceApi } from "../../_common/hooks/api/deviceApiHook";
import { useNavigate, useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { URLS } from "../../_config";
import Select from "react-select";
import Error from "../../components/formFieldError";
import { getSelectedValue } from "../../_common/functions";
import { Link } from "react-router-dom";
import Loader from "../../components/loader";
import TitleBar from "../../components/TopBar/TitleBar";

const DeviceAdd = () => {
    const deviceapi = useDeviceApi();
    const [devicetypes, setDevicestype] = useState<any[] | null>(null);
    const [deviceworkingstatus, setDeviceworkingsatus] = useState<any[] | null>(null)
    const [dataReset, setDataReset] = useState<boolean>(false)
    const navigate: any = useNavigate();

    const params: any = useParams();

    const { control, formState: { errors }, handleSubmit, reset } = useForm();
    const [DeviceID, setDeviceID] = useState<any>(params.deviceID)
    const getDivicetype = () => {
        deviceapi.getAllDevicestype({ urlParams: { PageIndex: 1, PageSize: 999 }, ActiveDeviceTemplate: 1 }, (message: any, resp: any) => {
            setDevicestype(resp.data.deviceTemplate)
        }, (message: any) => { })
    }

    const getDiviceworkingstus = () => {
        deviceapi.getAllDevicesatus({}, (message: any, resp: any) => {
            setDeviceworkingsatus(resp.data.deviceWorkingStatus)
        }, (message: any) => { })
    }

    const saveDevice = (data: any) => {
        return new Promise((resolve, reject) => {
            let time = new Date().getTime();
            let params: any = {
                "fkDeviceTemplateID": data.fkDeviceTemplateID.deviceTemplateID,
                "name": data.name,
                "deviceUUID": data.deviceUUID,
                "fkWorkingStatusValue": data.fkWorkingStatusValue.value,
                "mqttUsername": data.mqttUsername,
                "mqttPassword": data.mqttPassword,
                "mqttSubTopic": data.mqttSubTopic,
                "mqttPubTopic": data.mqttPubTopic,
                "description": data.description,
                "mqttClientID": data.mqttClientID,
                "mqttHost": data.mqttHost,
                "addedBy": 1
            }

            if (DeviceID) {
                params.deviceId = DeviceID;
                deviceapi.updateDevice(params, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })
            } else {
                params.deviceSerialNumber = "DIV_" + time;
                // params.deviceUUID = "DIV_" + time;

                deviceapi.saveDevice(params, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })
            }
        })
    }


    const saveDeviceHandler = (data: any) => {
        toast.promise(
            saveDevice(data), {
            loading: DeviceID ? 'Updating...' : 'Adding...',
            success: (msg: any) => {
                navigate(URLS.DEVICE_LIST)
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const getDeviceDetails = () => {
        deviceapi.getDeviceDetails({ urlParams: { DeviceId: DeviceID } }, (message: any, resp: any) => {
            let devicedetail: any = resp.data.device[0];

            let resetValues = {
                fkDeviceTemplateID: getSelectedValue(devicedetail?.fkDeviceTemplateID, "value", devicetypes ? devicetypes : []),
                fkWorkingStatusValue: getSelectedValue(devicedetail?.fkWorkingStatusValue, "value", deviceworkingstatus ? deviceworkingstatus : []),
                description: devicedetail.description,
                name: devicedetail.name,
                deviceSerialNumber: devicedetail.deviceSerialNumber,
                deviceUUID: devicedetail.deviceUUID,
                mqttUsername: devicedetail.mqttUsername,
                mqttPassword: devicedetail.mqttPassword,
                mqttSubTopic: devicedetail.mqttSubTopic,
                mqttPubTopic: devicedetail.mqttPubTopic,
                mqttClientID: devicedetail.mqttClientID,
                mqttHost: devicedetail.mqttHost,

            }
            console.log(resetValues);
            reset(resetValues);
            setDataReset(true)

        }, (message: any) => {
            //----
        })

    }
    useEffect(() => {
        if (DeviceID && devicetypes && deviceworkingstatus) {
            getDeviceDetails()
        }
    }, [devicetypes, deviceworkingstatus])

    useEffect(() => {
        getDivicetype();
        getDiviceworkingstus();
    }, [])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={`${(DeviceID) ? 'Edit' : 'Add'} Device`} breadcrumbs={{
                            'IoT Device': '#',
                            'List': URLS.DEVICE_LIST,
                            [(DeviceID) ? 'Edit' : 'Add']: '#',
                        }} />

                        {DeviceID && !dataReset ? <Loader /> :
                            <form className="row" onSubmit={handleSubmit(saveDeviceHandler)}>
                                <div className="col-sm-12">
                                    <div className="white-box-card mb-4 h-100" data-aos="fade-up">

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h3 className="card-heading mb-4 ">Device Details</h3>
                                                <div className="row">

                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Category<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="fkDeviceTemplateID"
                                                                rules={{ required: 'Device Category is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={devicetypes ? devicetypes : []}
                                                                        value={value}

                                                                    />
                                                                )}
                                                            />
                                                            <Error error={errors['fkDeviceTemplateID']} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Name<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="name"
                                                                rules={{ required: 'Name is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['name']} />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Description</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="description"
                                                                rules={{}}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['description']} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Serial Number<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="deviceSerialNumber"
                                                                rules={{ required: 'Device Serial is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['deviceSerialNumber']} />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device ID<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="deviceUUID"
                                                                rules={{ required: 'Device ID is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['deviceUUID']} />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Mqtt Username<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="mqttUsername"
                                                                rules={{ required: 'Mqtt Username is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['mqttUsername']} />
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Mqtt Password<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="mqttPassword"
                                                                rules={{ required: 'Device Mqtt Password is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="password" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['mqttPassword']} />
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Mqtt Sub Topic<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="mqttSubTopic"
                                                                rules={{ required: 'Device Mqtt Sub Topic is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['mqttSubTopic']} />
                                                        </div>
                                                    </div>



                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device MqttPubTopic<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="mqttPubTopic"
                                                                rules={{ required: 'Device Mqtt Pub Topic is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['mqttPubTopic']} />
                                                        </div>
                                                    </div>



                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Mqtt Client ID<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="mqttClientID"
                                                                rules={{ required: 'Device Mqtt Client ID is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['mqttClientID']} />
                                                        </div>
                                                    </div>




                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Device Mqtt Host<sup className="text-danger">*</sup></label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="mqttHost"
                                                                rules={{ required: 'Device Mqtt Host is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" />
                                                                )}
                                                            />
                                                            <Error error={errors['mqttHost']} />
                                                        </div>
                                                    </div>



                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Working Status</label>
                                                            <Controller
                                                                defaultValue={""}
                                                                control={control}
                                                                name="fkWorkingStatusValue"
                                                                rules={{ required: 'Assignment Status is required' }}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <Select
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        options={deviceworkingstatus ? deviceworkingstatus : []}
                                                                        value={value}

                                                                    />
                                                                )}
                                                            />
                                                            <Error error={errors['workingStatus']} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mt-3 d-flex gap-2 justify-content-end ">
                                            <Link to={URLS.DEVICE_LIST} className="btn btn-default">Cancel</Link>
                                            <button type="submit" className="btn btn-primary ">{DeviceID ? 'Update' : 'Save'}</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        }



                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default DeviceAdd;