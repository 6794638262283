import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { MAPAPI_BASE_URL } from '../../../../_config/site_urls';
import { useAppLoader } from '../../../../_common/hooks/common/appLoaderHook';
import LeftPanel from '../../../../components/leftPanel/LeftPanel';
import Header from '../../../../components/header/Header';


import TitleBar from '../../../../components/TopBar/TitleBar';
import Icon from '../../components/icon';
import ForecastTemperatureModal from '../../modals/forecastTemperature.modal';
import { useFarmsApi } from '../../../../_common/hooks/api/farmApiHook';
import Select from 'react-select';
import Loader from '../../../../components/loader';
import TableLoader from '../../../../components/tableLoader';



const Alerts: React.FC = () => {
    const { showLoader, hideLoader } = useAppLoader();
    const farmsApi = useFarmsApi();
    const [fields, setFields] = useState<any[] | null>(null);
    const [alerts, setAlerts] = useState<any>(null);
    const [modelAlerts, setModelAlerts] = useState<any>(null);
    const [ruleAlerts, setRuleAlerts] = useState<any>(null);
    const [allAlerts, setAllAlerts] = useState<any>(null);
    const [showAlert, setShowAlert] = useState<any>(null);
    const [farm, setFarm] = useState<any>(null);
    const [selectedField, setSelectedField] = useState<any>('');

    const getFields = async () => {
        showLoader();
        try {
            const response = await fetch(MAPAPI_BASE_URL + "/fields");
            const data = await response.json();
            setFields(data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    };

    const getFieldCropAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/alerts`);
            const data: any = await response.json();
            setAlerts(data.map((d: any) => {
                d.type = 'crop';
                return d;
            }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getFieldModelAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/model-alerts`);
            const data: any = await response.json();
            setModelAlerts(data.map((d: any) => {
                d.type = 'model';
                return d;
            }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getFieldRuleAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/rule-alerts`);
            const data: any = await response.json();
            setRuleAlerts(data.map((d: any) => {
                d.type = 'rule';
                return d;
            }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        getFields();
        getFieldCropAlerts();
        getFieldModelAlerts();
        getFieldRuleAlerts();
    }, []);

    useEffect(() => {
        if (alerts && modelAlerts && ruleAlerts) {
            let allAlerts: any = [];
            allAlerts = allAlerts.concat(alerts);
            allAlerts = allAlerts.concat(modelAlerts);
            allAlerts = allAlerts.concat(ruleAlerts);
            setAllAlerts(allAlerts.sort((a: any, b: any) => {
                return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
            }));
        }
    }, [alerts, modelAlerts, ruleAlerts]);

    const getFarmDetails = () => {
        farmsApi.farmDetails({ urlParams: { FarmID: showAlert.field.farm_id } }, (message: any, resp: any) => {
            let farm: any = resp.data?.farm[0];
            setFarm(farm);
            hideLoader();
        }, (message: any) => {
            setFarm(null)
        })
    }


    useEffect(() => {
        if (showAlert) {
            showLoader();
            getFarmDetails();
        }
    }, [showAlert]);

    useEffect(() => {
        console.log('selectedField ---> ', selectedField);
        if (alerts && modelAlerts && ruleAlerts) {
            if (selectedField) {
                let allAlerts: any = [];
                allAlerts = allAlerts.concat(alerts);
                allAlerts = allAlerts.concat(modelAlerts);
                allAlerts = allAlerts.concat(ruleAlerts);
                allAlerts = allAlerts.filter((alert: any) => alert.field._id == selectedField);
                setAllAlerts(allAlerts.sort((a: any, b: any) => {
                    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
                }));
            } else {
                let allAlerts: any = [];
                allAlerts = allAlerts.concat(alerts);
                allAlerts = allAlerts.concat(modelAlerts);
                allAlerts = allAlerts.concat(ruleAlerts);
                setAllAlerts(allAlerts.sort((a: any, b: any) => {
                    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
                }));
            }
        }
    }, [selectedField]);


    return (
        <div className='h-screen'>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content pb-0">
                    <div className="container-fluid">
                        <TitleBar title={`Alerts`} breadcrumbs={{ 'Alerts': '#', }} />
                        <div className="white-box-card mb-1 h-100 p-0 overflow-hidden" data-aos="fade-up">
                            <div className="flex flex-col w-full h-full st-height p-4">
                                <div className='mb-2 pb-2 border-b border-gray-400'>
                                    <Select
                                        className='w-[300px] mb-2'
                                        options={fields?.map((field: any) => ({ value: field._id, label: field.name }))}
                                        onChange={(selectedOption: any) => {
                                            if (selectedOption) {
                                                setSelectedField(selectedOption.value);
                                            } else {
                                                setSelectedField('');
                                            }
                                        }}
                                        placeholder='Filter by Location'
                                        isClearable={true}
                                    />
                                </div>
                                <div className="table-wrapper flex-shrink-0 flex-grow">
                                    <table className="table gen-table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Time</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Recommendation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            {allAlerts && allAlerts.length > 0 && allAlerts.map((alert: any, i: number) => (
                                                <tr key={`alert-${i}-${alert.timestamp}}`} >
                                                    <td>
                                                        <div className={`w-[32px] h-[32px] rounded flex justify-center items-center
                                                            ${alert.type == 'crop' ? (alert.is_ucl ? 'bg-orange-200' : 'bg-blue-200') : (alert.type == 'model') ? 'bg-violet-300' : (alert.alert_type == 'warning') ? 'bg-blue-200' : 'bg-red-200'} 
                                                        `}>
                                                            {alert.type == 'crop' && <Icon name='crop' size={24} className={alert.is_ucl ? 'text-orange-500' : 'text-blue-500'} />}
                                                            {alert.type == 'model' && <Icon name='ai' size={24} className='text-violet-600' />}
                                                            {alert.type == 'rule' && <Icon name='alertSq' size={24} className={alert.alert_type == 'warning' ? 'text-blue-500' : 'text-red-500'} />}
                                                        </div>
                                                    </td>
                                                    <td>{alert.field.name}</td>
                                                    <td>
                                                       
                                                            <div className='flex flex-col items-center justify-center bg-white rounded-md overflow-hidden shadow-sm'>
                                                                <p className='font-size-11 font-semibold text-slate-50 m-0 bg-green-400 w-100 text-center px-1'>{moment(alert.timestamp).format('MMM')}</p>
                                                                <p className='text-lg px-1 font-semibold text-slate-700 m-0 min-w-[48px] text-center'>{moment(alert.timestamp).format('DD')}</p>
                                                                <p className='font-size-11 font-semibold text-gray-400  border-t border-grey-400 m-0 bg-white w-100 text-center px-1'>{moment(alert.timestamp).format('ddd')}</p>
                                                            </div>
                                                       
                                                        
                                                        
                                                        
                                                        </td>
                                                        <td className='whitespace-nowrap'>{moment(alert.timestamp).format('hh:mm A')}</td>
                                                    <td className=''>{alert.message}</td>
                                                    <td>{alert.is_read?<p className='m-0 text-green-600 font-semibold rounded-lg bg-green-200 py-1 px-2'>Published</p>:null}</td>
                                                    <td>{alert.type == 'crop' && !alert.is_read?
                                                    <div className='flex items-center font-semibold text-xs bg-yellow-400 py-1 px-2 rounded-lg text-white' onClick={() => { setShowAlert(alert) }}>
                                                    <Icon name='bulb' size={24} className='mr-1' />
                                                    Recommendation
                                                    </div>
                                                    :null}</td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                    <TableLoader data={allAlerts} />
                                    {/* {
                                        dataList && <Pagination total={total} page={page} setPage={(n: number) => {
                                            setPage(n)
                                            setDataList(null)
                                        }} pageLimit={limit} />
                                    } */}
                                </div>
                               
                            </div>
                        </div>
                        <ForecastTemperatureModal open={showAlert != null && farm != null} close={() => { setShowAlert(null); setFarm(null) }} alert={showAlert} farm={farm} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alerts;