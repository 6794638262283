import React, { useEffect, useState } from "react";
import './Pagination.scss'; 
import Icon from "../icon";

interface props {
    total?:number
    page?:number
    pageLimit?:number
    setPage?:(n:number)=>void
}


export function Pagination ({total=0,page=1,pageLimit=10,setPage=(a)=>{}}:props){

    const [pages, setPages] = useState<any>(0);


    useEffect(()=>{
        setPages(Math.ceil(total/pageLimit))
    },[total])


    return(
        <React.Fragment>
            {pages > 1 && (
                <div className="pagination">
                    <ul>
                        {pages > 1 && (
                            <li className={`${page== 1?'disabled':''}`} onClick={()=>{setPage(page-1)}}>
                                <span><Icon name="arrow-left" /></span>
                            </li>
                        )}

                        {Array.from(Array(pages).keys()).map((e,i)=>(
                            <li key={`page-${i}`} className={`${page == (i+1)?'active':''}`} onClick={()=>{setPage(i+1)}}><span>{i+1}</span></li>
                        ))}

                        {pages > 1 && (
                            <li className={`${page== pages?'disabled':''}`}  onClick={()=>{setPage(page+1)}}>
                                <span><Icon name="arrow-right" /></span>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </React.Fragment>
        
    );
}

export default Pagination;