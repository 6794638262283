import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { URLS } from '../../_config'
import Swal from 'sweetalert2'
import { useEmployeeApi } from '../../_common/hooks/api/employeeApiHook'
import LeftPanel from '../../components/leftPanel/LeftPanel'
import TitleBar from '../../components/TopBar/TitleBar'

export const EmployeeRolePermission = () => {

    const { control, register, handleSubmit, reset, getValues, formState: { errors, isValid }, } = useForm()
    const navigate = useNavigate()

    const employeeApi = useEmployeeApi();
    const params: any = useParams();
    const [empId, setEmpId] = useState<any>(params?.employeeID);

    const [roles, setRoles] = useState<Array<any>>([]);
    const [empDetails, setEmpDetails] = useState<any>({});

    // /api/v{version}/Employee/getEmployeeByID/{EmployeeID}
    const getEmployeeDetails = () => {
        employeeApi.getEmployeeDetails({ urlParams: { employeeID: empId } }, (message: any, resp: any) => {
            setEmpDetails(resp.data?.employeeList[0]);
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    console.log('empDetails---------------', empDetails)


    useEffect(() => {
        getEmployeeDetails()
    }, [empId]);



    const submitData = (data: any) => {
        return new Promise((resolve, reject) => {

            console.log('datadata', data)
            data.FKCompanyID = data.FKCompanyID
            data.FKTenantID = data.FKTenantID


            setTimeout(() => {
                let isPrimary = data.isPrimary;
                let fkRoleID = data.fkRoleID;
                let roleArr: any = [];

                if (fkRoleID.length > 0) {
                    fkRoleID.map((idRole: any) => {
                        let roleData = {
                            fkUserID: data.fkUserID,
                            fkRoleID: idRole,
                            fkCompanyID: data.FKCompanyID,
                            fkTenantID: data.FKTenantID,
                            isPrimary: (isPrimary == idRole) ? true : false,
                            // addedBy: userInfo?.userID,
                        }
                        roleArr.push(roleData);
                    })

                }


                if (!fkRoleID.includes(isPrimary)) { // If Primary Role not selected in checkbox
                    let roleData = {
                        fkUserID: data.fkUserID,
                        fkRoleID: isPrimary,
                        fkCompanyID: data.FKCompanyID,
                        fkTenantID: data.FKTenantID,
                        isPrimary: true,
                        // addedBy: userInfo?.userID,
                    }
                    roleArr.push(roleData);
                }

                // employeeApi.userRoleMap(roleArr, (message: any, resp: any) => {
                //     console.log('respresp', resp)
                //     resolve(message)
                // }, (message: any) => {
                //     reject(message)
                // }) 

            }, 1000)
            //resolve('success')
        })
    }

    const onSubmit = (data: any) => {
        if (data.isPrimary == undefined) {
            Swal.fire({
                title: 'Select Primary Role',
                icon: 'warning',
                confirmButtonColor: '#B32F84',
                confirmButtonText: `OK`
            })
        } else {

            toast.promise(
                submitData(data), {
                loading: 'Saving...',
                success: (msg: any) => {
                    // reset();
                    getEmployeeDetails();
                    navigate(URLS.EMPLOYEE_LIST);
                    return <b>{msg}</b>
                },
                error: (msg: any) => { return <b>{msg}</b> },
            });
        }
    }


    return (

        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">


                        <TitleBar title={'Employee Role Permission'} breadcrumbs={{
                            'Employee List': URLS.EMPLOYEE_LIST,
                            'Role Permission': '#'
                        }} />



                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Employee Role Permission</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                        </div>
                                    </div>




                                    <div className="form-area">
                                        <table className='table'>
                                            <tbody>
                                                <tr>
                                                    {/* <th>Emp. ID</th>
                                                    <td> <img src={`${empDetails?.avatar}`} alt="" /> </td> */}

                                                    <th>Name</th>
                                                    <td colSpan={3}> {`${empDetails?.firstName} ${empDetails?.lastName}`} </td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td> {`${(empDetails?.email) ? empDetails?.email : '--'}`} </td>

                                                    <th>Phone</th>
                                                    <td> {`${(empDetails?.phone) ? empDetails?.phone : '--'}`} </td>
                                                </tr>
                                                <tr>
                                                    <th>Department</th>
                                                    <td> {`${(empDetails?.department) ? empDetails?.department : '--'}`} </td>

                                                    <th>Designation</th>
                                                    <td> {`${(empDetails?.designation) ? empDetails?.designation : '--'}`} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr />

                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.EMPLOYEE_LIST} className={`btn btn-info d-flex align-items-center gap-2`} >Back</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>

    )
}
