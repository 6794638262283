import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useMasterApi() {
  const callApi = useApiCall();


  const getAllDepartment = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.GET_DEPARTMENTS, data, onSuccess, onError);
  };

  const getAllDesignation = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.GET_DESIGNATIONS, data, onSuccess, onError);
  };
  const getAllDistrict = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.GET_DISTRICTS, data, onSuccess, onError);
  };
  const getAllGpu = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.GET_GPUS, data, onSuccess, onError);
  };
  const getAllward = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.GET_WARDS, data, onSuccess, onError);
  };
  const getAllBlocks = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.GET_BLOCKS, data, onSuccess, onError);
  };


  const getAllRoles = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.ROLE.LIST, data, onSuccess, onError);
  }
  const getRoleDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.ROLE.DETAILS, data, onSuccess, onError);
  }
  const roleSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.ROLE.SAVE, data, onSuccess, onError);
  }
  const roleUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.ROLE.UPDATE, data, onSuccess, onError);
  }
  const roleDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.ROLE.DELETE, data, onSuccess, onError);
  }
  const getRolePermission = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.ROLE.PERMISSION, data, onSuccess, onError);
  };
  const rolePermissionSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.ROLE.PERMISSION_SAVE, data, onSuccess, onError);
  };




  const getAllEmailSettings = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.SETTINGS.EMAIL_SETTINGS.LIST, data, onSuccess, onError);
  }
  const getEmailSettingsDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.SETTINGS.EMAIL_SETTINGS.DETAILS, data, onSuccess, onError);
  }
  const emailSettingsUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.SETTINGS.EMAIL_SETTINGS.UPDATE, data, onSuccess, onError);
  }


  return {
    getAllDepartment,
    getAllDesignation,
    getAllDistrict,
    getAllGpu,
    getAllward,
    getAllBlocks,

    getAllRoles,
    getRoleDetails,
    roleSave,
    roleUpdate,
    roleDelete,
    getRolePermission,
    rolePermissionSave,

    getAllEmailSettings, getEmailSettingsDetails, emailSettingsUpdate
  };
}

