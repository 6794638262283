import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';



export function* farmList(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.LIST, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* farmDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.DETAILS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* farmSave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.SAVE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* farmUpdate(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* farmChangeStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.CHANGE_STATUS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* importFarms(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.IMPORT_FARMS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* farmDelete(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.DELETE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.DELETE, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getFarmsCropMappingList(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.FARMS_CROP_MAPPING_LIST, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* farmCropsMappingSave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.FARMS_CROP_MAPPING_SAVE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* getFieldsHistory(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FARMS.FIELDS_HISTORY, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* getForecastSensorRangeChecklists(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.FORECAST.SENSOR_RANGE_CHECKLIST, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}





