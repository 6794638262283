import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import './css/dashboardImgModal.css';

interface props {
    show: boolean,
    onClose: (status: boolean) => void,
    images: any,
    selectImgIndex: number
}

export default function DashboardImgPreview({ show, onClose, images, selectImgIndex }: props) {
    const handleClose = () => { onClose(false) };

    const [currentIndex, setCurrentIndex] = useState(selectImgIndex);

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + 1 === images.length ? 0 : prevIndex + 1
        );
    };
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
        );
    };
    const handleDotClick = (index: any) => {
        setCurrentIndex(index);
    };


    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton><Modal.Title>Image Preview</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="carousel">
                            <img
                                key={currentIndex}
                                src={images[currentIndex]?.locationImageUrl}
                                width={'100%'} 
                                alt=''
                                style={{height: '70vh', objectFit: 'contain'}}
                            />

                            <div className="slide_direction">
                                <div className="left" onClick={handlePrevious}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="20"
                                        viewBox="0 96 960 960"
                                        width="20"
                                    >
                                        <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" fill='#ffffff' />
                                    </svg>
                                </div>
                                <div className="right" onClick={handleNext}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="20"
                                        viewBox="0 96 960 960"
                                        width="20"
                                    >
                                        <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" fill='#fff' />
                                    </svg>
                                </div>
                            </div>
                            <div className="indicator">
                                {images && images.map((_data: any, index: any) =>
                                    <div
                                        key={index}
                                        className={`dot ${currentIndex === index ? "active" : ""}`}
                                        onClick={() => handleDotClick(index)}
                                    ></div>
                                )}
                            </div>
                        </div>

                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}