import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';
// const API_BASE_URL = process.env.REACT_APP_API_URL;


export function* addEmployee(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EMPLOYEE.ADD_EMPLOYEE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
        // if (resp.status == 200 && resp.data) {
        //     action && action.callbackSuccess && action.callbackSuccess(resp.data);
        // } else {
        //     action && action.callbackError && action.callbackError(resp.data);
        // }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateEmployee(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EMPLOYEE.UPDATE_EMPLOYEE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
        // if (resp.status == 200 && resp.data) {
        //     action && action.callbackSuccess && action.callbackSuccess(resp.data);
        // } else {
        //     action && action.callbackError && action.callbackError(resp.data);
        // }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllEmployee(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EMPLOYEE.GET_EMPLOYEE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllActiveEmployee(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EMPLOYEE.GET_ACTIVE_EMPLOYEE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateEmployeeStatus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EMPLOYEE.UPDATE_EMPLOYEE_STATUS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getEmployeeDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EMPLOYEE.GET_EMPLOYEE_DETAILS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* EmployeeDelete(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.EMPLOYEE.DELETE_EMPLOYEE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}








