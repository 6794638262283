import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Transition } from "@headlessui/react";
import { API_BASE_URL } from "../_config";
import Select from 'react-select';
import moment from "moment";
import toast from "react-hot-toast";
import { GoogleMap, Marker } from "@react-google-maps/api";
//import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { useAppLoader } from "../../../_common/hooks/common/appLoaderHook";
import { Button, Modal } from 'react-bootstrap'
import { CiCirclePlus } from "react-icons/ci";
interface AddFieldModalProps {
    open: boolean;
    close: () => void;
    update: () => void;
    field?: any;
}

interface FormData {
    crops: any;
    fieldName: any;
    area: any;
    areaUnit: any;
    plots: any
    sowingDate: any;
    harvestingDate: any;
    devices: any;
    latitude: any;
    longitude: any;
}

const AddFieldModal = ({ open, close, field, update }: AddFieldModalProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { showLoader, hideLoader } = useAppLoader();
    const { control, register, handleSubmit, setValue, reset, watch, formState: { errors, isValid } } = useForm<FormData>({ mode: 'onBlur' });
    const [devices, setDevices] = useState<any[] | null>(null);
    const [crops, setCrops] = useState<any[] | null>(null);

    const [map, setMap] = useState<any>(null);
    const [isloaded, setIsloaded] = useState<boolean>(false);
    const [latLng, setLatLng] = useState({ lat: (field && field.location) ? field.location?.latitude : 27.3516407, lng: (field && field.location) ? field.location?.longitude : 88.6065026 });
    const [placeService, setPlaceService] = useState<any>(null);
    const [placeRequest, setPlaceRequest] = useState<any>({
        query: 'sikkim',
        fields: ['name', 'geometry'],
    });

    const [fieldLatLng, setFieldLatLng] = useState<any | null>(null);

    const mapRef: any = useRef(null);
    const mapboxRef: any = useRef();
    const [roundedArea, setRoundedArea] = useState<any>('');
    const [coordinates, setCoordinates] = useState<any>(null);
    const containerStyle = {
        width: "100%",
        height: "100%",
    };

    const closeModal = () => {
        close();
        reset();
        setRoundedArea(0);
        mapRef.current = null;
        mapboxRef.current = null;
    }

    const onLoad = React.useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new (window as any).google.maps.LatLngBounds(latLng);
        map.fitBounds(bounds);
        setTimeout(() => {
            map.setZoom(16);
        }, 1000);

        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    const initializeAutocomplete = () => {
        setIsloaded(true);
    };

    const loadGoogleMap = async () => {
        if ((window as any).google) {
            initializeAutocomplete();
        } else {
            const script = document.createElement("script");
            script.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=maps,places,geometry&sensor=false`;
            script.async = true;
            script.defer = true;

            script.onload = () => {
                initializeAutocomplete();
            };
            document.head.appendChild(script);
        }
    };

    const updateMarker = (lat: any, lng: any) => {
        //console.log("lat", lat, "lng", lng);
        setLatLng({
            lat: lat,
            lng: lng,
        });
        map.panTo({
            lat: lat,
            lng: lng,
        });
        if (mapRef.current) {
            mapRef.current.setCenter([lng, lat]);
            mapRef.current.setZoom(16);
        }
        /* setValue("latitude", lat);
        setValue("longitude", lng); */
    };

    useEffect(() => {
        if (map) {
            setPlaceService(new (window as any).google.maps.places.PlacesService(map));
        }
    }, [map]);

    useEffect(() => {
        if (fieldLatLng) {
            setValue("latitude", fieldLatLng.lat);
            setValue("longitude", fieldLatLng.lng);
        }
    }, [fieldLatLng]);


    useEffect(() => {
        if (map && placeRequest && placeService) {
            if (field && placeRequest.query === 'field') {
                if(field.location){
                    updateMarker(field.location.latitude, field.location.longitude);
                }
            } else {
                placeService.findPlaceFromQuery(placeRequest, function (results: any, status: any) {
                    if (status === (window as any).google.maps.places.PlacesServiceStatus.OK) {
                        updateMarker(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                    }
                });
            }
        } else {
            console.log("map not loaded");
        }
    }, [placeRequest, placeService, map]);


    const getCrops = async () => {
        try {
            const response = await fetch(API_BASE_URL + "/crops");
            const data = await response.json();
            setCrops(data.map((crop: any) => ({ ...crop, value: crop.name, label: crop.name })));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getDevices = async () => {
        showLoader();
        try {
            const response = await fetch(API_BASE_URL + "/devices");
            const data = await response.json();
            setDevices(data.map((season: any) => ({ ...season, value: season.name, label: season.name })));
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    const onSubmit = async (data: FormData) => {
        showLoader();
        let params: any = {
            "name": data.fieldName,
            farm_id: field.farmID.toString(),
            "crop_ids": data.crops.map((crop: any) => crop._id),
            "device_ids": data.devices.map((device: any) => device._id),
            "area": data.area.toString(),
            "area_unit": data.areaUnit,
            plots: [],
            "location": {
                "latitude": data.latitude,
                "longitude": data.longitude
            },
            "sowing_date": data.sowingDate ? moment(data.sowingDate).format('YYYY-MM-DD') : '',
            "harvesting_date": data.harvestingDate ? moment(data.harvestingDate).format('YYYY-MM-DD') : '',
            "boundary": coordinates.map((coord: any) => {
                return {
                    "latitude": coord[1],
                    "longitude": coord[0]
                }
            })
        }


        let API: string = API_BASE_URL + "/fields";

        if (field?.map) {
            API += `/${field._id}`;
           /*  params.plots = [
                {
                    "name": "plot 1",
                    "area": data.area.toString(),
                    "area_unit": data.areaUnit,
                    "location": {
                        "latitude": data.latitude,
                        "longitude": data.longitude
                    },
                    "boundary": coordinates.map((coord: any) => {
                        return {
                            "latitude": coord[1],
                            "longitude": coord[0]
                        }
                    })
                }
            ]; */
        }

        try {
            // Make API call to save sensor details
            const response = await fetch(API, {
                method: field.map ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }).then(async (response) => {
                if (response.ok) {
                    const result = await response.json();
                    toast.success('Field saved successfully');
                    closeModal();
                    update();
                } else {
                    const result = await response.json();
                    if (typeof result.detail === 'string') {
                        toast.error(result.detail);
                    } else if (typeof result.detail === 'object') {
                        toast.error(result.detail[0].msg);
                    } else {
                        toast.error('Something went wrong');
                    }
                }
                hideLoader();
            }).catch((error) => {
                console.log("Error saving sensor details:", error);
                hideLoader();
            });
        } catch (error) {
            console.log("Error saving sensor details:", error);
            hideLoader();
        }
    };

    useEffect(() => {
        console.log('precision field ----------------->', field);
        if (field && devices && crops && map && isloaded) {
            console.log(field, devices, crops, map, isloaded);
            setValue("fieldName", field.ownerName);
            setValue("sowingDate", moment(field.sowing_date).format('YYYY-MM-DD'));
            setValue("harvestingDate", moment(field.harvesting_date).format('YYYY-MM-DD'));
            setValue("area", field.farmSize);
            setValue("areaUnit", 'Acres');
            /* setValue("crops", crops.filter((crop) => {crop._id === field.crop?._id}));
            setValue("devices", devices.find(season => season._id === field.season?._id)); */
            setPlaceRequest({
                query: 'field',
                fields: ['name', 'geometry'],
            });
        }
    }, [field, crops, devices, map, isloaded]);

    useEffect(() => {
        setRoundedArea(0);
        loadGoogleMap();
        getDevices();
        getCrops();
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const drawField = (name: string, data: any) => {
        console.log("Draw Field", data);
        mapRef.current.addSource(name, {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    // These coordinates outline Maine.
                    coordinates: [
                        data.map((coord: any) => {
                            return [coord.longitude, coord.latitude];
                        })
                    ]
                }
            }
        });

        mapRef.current.addLayer({
            id: name,
            type: 'fill',
            source: name,
            layout: {},
            paint: {
                'fill-color': '#fbbf24',
                'fill-opacity': 0.5
            }
        });

        mapRef.current.addLayer({
            id: 'outline',
            type: 'line',
            source: name,
            layout: {},
            paint: {
                'line-color': '#f59e0b',
                'line-width': 1
            }
        });
    }


    const getPolygonArea = (data: any) => {
        let cords: any = data.features[0].geometry.coordinates[0];
        setCoordinates(cords);
        console.log("Coordinates", cords);
        const center = turf.center(data);
        const [lng, lat] = center.geometry.coordinates;
        console.log("Center Latitude:", lat, "Center Longitude:", lng);
        setValue("latitude", lat);
        setValue("longitude", lng);
        const area = turf.area(data);
        setValue('area', Math.round(area * 100) / 100);
        setValue('areaUnit', 'sq.m');
    };

    useEffect(() => {
        console.log("mapRef ------------------>", mapRef.current);
        if (mapboxRef.current && mapRef.current == null) {
            console.log("mapboxRef", mapboxRef.current);
            mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_KEY}`;



            mapRef.current = new mapboxgl.Map({
                container: mapboxRef.current,
                style: 'mapbox://styles/rajsekhardas/cm2edf6w200tt01pi34159jq8', //mapbox://styles/mapbox/dark-v11
                center: [-74.5, 40],//[88.32393090000001, 27.3516407],
                zoom: 20
            });
            mapRef.current.on('load', () => {
                const draw = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: field ? {} : {},//{ polygon: true, trash: true }
                    defaultMode: 'draw_polygon'//(field && field.boundary)?'simple_select':'draw_polygon'
                });
                mapRef.current.addControl(draw);
                mapRef.current.on('draw.create', updateArea);
                mapRef.current.on('draw.delete', updateArea);
                mapRef.current.on('draw.update', updateArea);

                function updateArea(e: any) {
                    const data = draw.getAll();

                    if (data.features.length > 0) {

                        getPolygonArea(data);
                        const area = turf.area(data);
                        setRoundedArea(Math.round(area * 100) / 100);
                    } else {
                        setRoundedArea(0);
                        //if (e.type !== 'draw.delete') alert('Click the map to draw a polygon.');
                    }
                }

                if (field && field.boundary) {
                    drawField(field._id.toString(), field.boundary);
                }

            });


        }
    }, [mapboxRef.current, isOpen]);

    return (
        <Modal show={open} onHide={close} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>{(field?.map)?'Edit':'Create'} Farm Boundary</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="relative bg-white rounded-lg max-h-screen overflow-auto min-h-[550px]">
                    
                    <div className="flex gap-3">
                        <div className="flex-grow">
                            <div className="mb-2">
                                <label className="block text-sm font-medium text-gray-700">search</label>
                                <input
                                    type="text"
                                    onKeyUp={(e: any) => {
                                        if (e.target.value.length > 3) {
                                            setPlaceRequest({
                                                query: e.target.value,
                                                fields: ['name', 'geometry'],
                                            });
                                        }
                                    }}
                                    className="border border-gray-300  rounded-md p-2 mb-2 w-full m-0 disabled"
                                />
                            </div>
                            <div className="w-full h-[100%] max-h-[500px]" ref={mapboxRef}></div>
                        </div>

                        <div className="w-[320px] flex-shrink-0">
                            {/* <div className="calculation-box" >
                                        <p style={{}}>Click the map to draw a polygon.</p>
                                        <div id="calculated-area">
                                            {roundedArea && (
                                                <>
                                                    <p style={{}}>
                                                        <strong>{roundedArea}</strong>
                                                    </p>
                                                    <p style={{}}>square meters</p>
                                                </>
                                            )}
                                        </div>
                                    </div> */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="grid grid-cols-2 gap-3">
                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-700">Field Name</label>
                                        <input
                                            type="text"
                                            {...register("fieldName", { required: true })}
                                            className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                        />
                                        {errors.fieldName && <p className="text-red-600 text-sm">This field is required</p>}
                                    </div>

                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-700">Crop</label>
                                        <Controller
                                            control={control}
                                            name="crops"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    isMulti={true}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    options={crops ? crops : []}
                                                />
                                            )}
                                        />
                                        {errors.crops && <p className="text-red-600 text-sm">This field is required</p>}
                                    </div>

                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-700">Area</label>
                                        <input
                                            type="text"
                                            {...register("area", { required: true })}
                                            className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                        />
                                        {errors.area && <p className="text-red-600 text-sm">This field is required</p>}
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-700">Area Unit</label>
                                        <input
                                            type="text"
                                            {...register("areaUnit", { required: false })}
                                            className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-700">Devices</label>
                                        <Controller
                                            control={control}
                                            name="devices"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    isMulti={true}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    options={devices ? devices : []}
                                                />
                                            )}
                                        />
                                        {errors.devices && <p className="text-red-600 text-sm">This field is required</p>}
                                    </div>
                                    <div></div>

                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-700">Sowing Date</label>
                                        <input {...register("sowingDate", { required: true })} type="date" className="border border-gray-300 rounded-md p-2 m-0 w-full" />
                                        {errors.sowingDate && <p className="text-red-600 text-sm">This field is required</p>}
                                    </div>

                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-700">Harvesting Date</label>
                                        <input {...register("harvestingDate", { required: true })} type="date" className="border border-gray-300 rounded-md p-2 m-0 w-full" />
                                        {errors.harvestingDate && <p className="text-red-600 text-sm">This field is required</p>}
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-400">Latitude</label>
                                        <input
                                            type="text"
                                            {...register("latitude", { required: false })}
                                            className="border border-gray-300 bg-gray-200 rounded-md p-2 mb-2 w-full m-0 disabled"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-400">Longitude</label>
                                        <input
                                            type="text"
                                            {...register("longitude", { required: false })}
                                            className="border border-gray-300 bg-gray-200 rounded-md p-2 mb-2 w-full m-0 disabled"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                {/* <div className="mb-2">
                                            <label className="block text-sm font-medium text-gray-700">search</label>
                                            <input
                                                type="text"
                                                onKeyUp={(e: any) => {
                                                    if (e.target.value.length > 3) {
                                                        setPlaceRequest({
                                                            query: e.target.value,
                                                            fields: ['name', 'geometry'],
                                                        });
                                                    }
                                                }}
                                                className="border border-gray-300  rounded-md p-2 mb-2 w-full m-0 disabled"

                                            />
                                        </div> */}

                                <div className="w-0 h-[0px]">

                                    {isloaded && <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={latLng}
                                        zoom={16}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                    >
                                        <Marker
                                            position={latLng}
                                            draggable={true}
                                            onDragEnd={(data: any) => {
                                                updateMarker(data.latLng.lat(), data.latLng.lng());
                                            }}
                                        />
                                    </GoogleMap>}
                                </div>

                                <button type="submit" className="btn btn-sm btn-primary" >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    );
};

export default AddFieldModal;