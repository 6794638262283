import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

interface props {
    show: boolean,
    data: any,
    onClose: (status: boolean) => void,
}

export default function LocationModal({ show, onClose, data }: props) {
    const handleClose = () => { onClose(false) };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className='col-md-12'>
                        <p className="m-2"><b>District: </b>
                            {data?.groupDistrictsList?.map((dist: any) => { return dist.districtName }).join(', ')}
                        </p>
                        <p className="m-2"><b>Block: </b>
                            {data?.groupBlockList?.map((block: any) => { return block.blockName }).join(', ')}
                        </p>
                        <p className="m-2"><b>GPU: </b>
                            {data?.groupGPUList?.map((gpu: any) => { return gpu.gpuName }).join(', ')}
                        </p>
                        <p className="m-2"><b>Ward: </b>
                            {data?.groupWardList?.map((ward: any) => { return ward.wardName }).join(', ')}
                        </p>
                    </div>
                </div>

            </Modal.Body> 
        </Modal>
    )
}