import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';
// const API_BASE_URL = process.env.REACT_APP_API_URL;




export function* getAllDepartments(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.GET_DEPARTMENTS, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            if (resp.data.data.departments) {
                resp.data.data.departments.map((data: any) => {
                    data.label = data.department;
                    data.value = data.departmentID;
                    return data;
                })
            }
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllDesignations(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.GET_DESIGNATIONS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            if (resp.data.data.designations) {
                resp.data.data.designations.map((data: any) => {
                    data.label = data.designation;
                    data.value = data.designationID;
                    return data;
                })
            }
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllDistricts(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.GET_DISTRICTS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            if (resp.data.data.district) {
                resp.data.data.district.map((data: any) => {
                    data.label = data.districtName;
                    data.value = data.districtId;
                    return data;
                })
                yield put({
                    type: ACTIONS.MASTER.DISTRICTS,
                    payload: {
                        districts:resp.data.data.district
                    }
                })
            }
            
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllGpus(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.GET_GPUS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            if (resp.data.data.gpu) {
                resp.data.data.gpu.map((data: any) => {
                    data.label = data.cpuName;
                    data.value = data.gpiid;
                    return data;
                })
                yield put({
                    type: ACTIONS.MASTER.GPUS,
                    payload: {
                        gpus:resp.data.data.gpu
                    }
                })
            }
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllWards(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.GET_WARDS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            if (resp.data.data.ward) {
                resp.data.data.ward.map((data: any) => {
                    data.label = data.wardName;
                    data.value = data.wardID;
                    return data;
                })
                yield put({
                    type: ACTIONS.MASTER.WARDS,
                    payload: {
                        wards:resp.data.data.ward
                    }
                })
            }
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllBlocks(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.GET_BLOCKS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            if (resp.data.data.block) {
                resp.data.data.block.map((data: any) => {
                    data.label = data.blockName;
                    data.value = data.blockID;
                    return data;
                })
                yield put({
                    type: ACTIONS.MASTER.BLOCKS,
                    payload: {
                        blocks:resp.data.data.block
                    }
                })
            }
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
 


export function* getAllRoles(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.ROLE.LIST, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
          
            if (resp.data.data.role) {
                resp.data.data.role.map((data: any) => {
                    data.label = data.title;
                    data.value = data.roleId;
                    return data;
                })
            }
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getRoleDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.ROLE.DETAILS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* roleSave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.ROLE.SAVE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.POST, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* roleUpdate(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.ROLE.UPDATE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* roleDelete(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.ROLE.DELETE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.DELETE, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getRolePermission(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.ROLE.PERMISSION, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* rolePermissionSave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.ROLE.PERMISSION_SAVE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.POST, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}




export function* getAllEmailSettings(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.SETTINGS.EMAIL_SETTINGS.LIST, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) { 
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getEmailSettingsDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.SETTINGS.EMAIL_SETTINGS.DETAILS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {
            action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* emailSettingsSave(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.MASTER.SETTINGS.EMAIL_SETTINGS.UPDATE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.PUT, API, data, true);
        if (resp.status == 200 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}





