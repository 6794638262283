import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import Editor from "react-simple-wysiwyg";
import { CiCirclePlus } from "react-icons/ci";
import { IoCameraOutline } from "react-icons/io5";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormTextInput from "../../_common/form-elements/textinput/formTextInput";
import Error from "../../components/formFieldError";
import { URLS } from "../../_config";
import Select from "react-select";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useMasterApi } from "../../_common/hooks/api/masterApiHook";
import { useEventApi } from "../../_common/hooks/api/eventApiHook";
import { getRelativeListWithMultiSelected, getSelectedValue, showDate } from "../../_common/functions";
import toast from "react-hot-toast";
import moment from "moment";
import TitleBar from "../../components/TopBar/TitleBar";
import { useBlocks, useDistricts, useGpus, useWards } from "../../_common/hooks/masterHook";


const EventsAdd = () => {
	const navigate = useNavigate();
	const urlParams: any = useParams();
	const masterApi = useMasterApi();
	const eventApi = useEventApi();

	const eventID: any = urlParams.eventID;

	const [map, setMap] = useState<any>(null);
	const [isloaded, setIsloaded] = useState<boolean>(false);
	const [eventtypes, setEventtype] = useState<any[]>([]);
	const [eventorganisers, setEventorganiser] = useState<any[]>([]);
	const [eventimage, setEventimages] = useState<string | "/images/no_image.jpg">("/images/no_image.jpg");
	

	const districts:any = useDistricts();
    const wards:any = useWards();
    const gpus:any = useGpus();
    const blocks:any = useBlocks();
    const [stateData, setStateData] = useState<boolean>(false);


	const [filteredBlocks, setFilteredBlocks] = useState<any[]>([]);
	const [filteredGpus, setFilteredGpus] = useState<any[]>([]);
	const [filteredWards, setFilteredWards] = useState<any[]>([]);
	
	const [latLng, setLatLng] = useState({ lat: 27.3516407, lng: 88.32393090000001 });

	const { control, reset, formState: { errors }, handleSubmit, setValue, watch, } = useForm();



	const districtWatch = watch("district");
	const blockWatch = watch("block");
	const gpuWatch = watch("gpu");
	const wardWatch = watch("ward");

	const locationRef: any = useRef();
	const eventPicRef: any = useRef();


	const containerStyle = {
		width: "100%",
		height: "180px",
	};

	const center = {
		lat: 27.3516407,
		lng: 88.32393090000001,
	};

	const onLoad = React.useCallback(function callback(map: any) {
		// This is just an example of getting and using the map instance!!! don't just blindly copy!
		const bounds = new (window as any).google.maps.LatLngBounds(center);
		map.fitBounds(bounds);
		console.log(map);
		setTimeout(() => {
			map.setZoom(8);
		}, 1000);

		setMap(map);
	}, []);

	const onUnmount = React.useCallback(function callback(map: any) {
		setMap(null);
	}, []);


	const initializeAutocomplete = () => {
		setIsloaded(true);
		if (locationRef.current) {
			const autocomplete = new (window as any).google.maps.places.Autocomplete(
				locationRef.current,
				{
					types: ["geocode"],
					componentRestrictions: { country: "in" },
				}
			);

			autocomplete.addListener("place_changed", () => {
				let place = autocomplete.getPlace();
				console.log("selected place", place);

				if (place.geometry) {
					setLatLng({
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng(),
					});
					// Call any additional functions or update state as needed
					// For example: GetAddress(place.geometry.location.lat(), place.geometry.location.lng());
				
					setValue("location", place?.formatted_address);
				}
			});

			getEvettype();
			getEvetorganiser()
		}
	};

	const getAddress = (lat: any, lng: any) => {
		const latlng = new (window as any).google.maps.LatLng(lat, lng);
		const geocoder = new (window as any).google.maps.Geocoder();
		geocoder.geocode({ latLng: latlng }, (results: any, status: any) => {
			if (status === (window as any).google.maps.GeocoderStatus.OK) {
				if (results[0]) {
					// Do something with the address
					console.log(results[0].formatted_address);
					setValue("location", results[0].formatted_address);
					locationRef.current.value = results[0].formatted_address;
				}
			}
		});
	};

	const handleEventPicChange = (event: any) => {
		// Access the selected file(s) from event.target.files
		const selectedFile = event.target.files[0];

		// Do something with the selected file, e.g., display its name

		if (selectedFile) {
			const reader = new FileReader();

			reader.onloadend = () => {
				// Base64 string representation of the selected file
				const base64String = reader.result as string | null;

				if (base64String !== null) {
					setEventimages(base64String);
				}

				// You can use the base64String as needed (e.g., set it in the state)
			};

			// Read the selected file as a Data URL (Base64)
			reader.readAsDataURL(selectedFile);
		}
	};



	const getEvettype = () => {
		eventApi.getAllEventtypes(
			{},
			(message: any, resp: any) => {
				setEventtype(resp.data.eventType);
			},
			(message: any) => {
				setEventtype([]);
			}
		);
	};

	const getEvetorganiser = () => {
		eventApi.getAllEventorganiser(
			{},
			(message: any, resp: any) => {
				setEventorganiser(resp.data.organizer);
			},
			(message: any) => {
				setEventorganiser([]);
			}
		);
	};



	const getEventData = () => {
		eventApi.getEventDetails({ urlParams: { eventID: eventID } }, (message: string, resp: any) => {
			let eventdetails = resp.data?.event?.[0];
			setLatLng({
				lat: eventdetails.lat,
				lng: eventdetails.lng,
			});
			reset({
				eventName: eventdetails.eventName,
				eventype: getSelectedValue(eventdetails.fkEventTypeID, 'value', eventtypes),
				description: eventdetails.eventDescription,
				specialHighlight: eventdetails.specialHighlight,
				eventDate: moment(eventdetails.eventDateTime, 'MM/DD/YYYY hh:mm:ss A').format('YYYY-MM-DD'),
				eventTime: moment(eventdetails.eventDateTime, 'MM/DD/YYYY hh:mm:ss A').format('HH:mm'),
				venueName: eventdetails.venueName,
				location: eventdetails.venueLocation,
				specialhighlight: eventdetails.specialHighlight,
				organizer: getSelectedValue(
					eventdetails.eventOrganizerList.map((org: any) => {
						return org.organizerID;
					}),
					"value",
					eventorganisers ? eventorganisers : []
				),
				district: getSelectedValue(
					eventdetails.eventBroadcastDistrictsList.map((dist: any) => {
						return dist.districtId;
					}),
					"value",
					districts ? districts : []
				),
				block: getSelectedValue(
					eventdetails.eventBroadcastBlockList.map((dist: any) => {
						return dist.blockID;
					}),
					"value",
					blocks ? blocks : []
				),
				gpu: getSelectedValue(
					eventdetails.eventBroadcastGPUList.map((dist: any) => {
						return dist.gpuid;
					}),
					"value",
					gpus ? gpus : []
				),
				ward: getSelectedValue(
					eventdetails.eventBroadcastWardList.map((dist: any) => {
						return dist.wardID;
					}),
					"value",
					wards ? wards : []
				),
				//role:
			});
			if (eventdetails.eventPicture) {
				setEventimages(eventdetails.eventPicture);
				setValue('eventPic', eventdetails.eventPicture)
			}
		}, (message: string) => { });
	};

	const addEventHandler = (data: any) => {
	
		
		if (eventimage == "/images/no_image.jpg") {
			toast.error("Event image is required");
			return false;
		}
		let param: any = {
			eventName: data.eventName,
			fkEventTypeID: data?.eventype?.eventTypeID,
			eventDescription: data.description,
			specialHighlight: data.specialhighlight,
			eventDateTime: data.eventDate + " " + data.eventTime,
			venueName: data.venueName,
			venueLocation: data.location,
			lat: latLng.lat,
			lng: latLng.lng,
			eventPicture: eventimage,
			eventOrganizerList: Array.isArray(data?.organizer)
				? data?.organizer?.map((data: any) => {
					return { organizerID: data.organizerID };
				})
				: [],

			eventBroadcastDistrictsList: Array.isArray(data?.district)
				? data?.district?.map((data: any) => {
					return { districtId: data.districtId };
				})
				: [],
			eventBroadcastBlockList: Array.isArray(data?.block)
				? data?.block?.map((data: any) => {
					return { blockID: data.blockID };
				})
				: [],
			eventBroadcastGPUList: Array.isArray(data?.gpu)
				? data?.gpu?.map((data: any) => {
					return { gpuid: data.gpiid };
				})
				: [],

			eventBroadcastWardList: Array.isArray(data?.ward)
				? data?.ward?.map((data: any) => {
					return { wardID: data.wardID };
				})
				: [],
		};

		const addEvent = (param: any) => {
			return new Promise((resolve, reject) => {
				console.log("params", param);
				if (eventID) {
					param.eventID = eventID;
					eventApi.updateEvent(
						param,
						(message: any, resp: any) => {
							resolve(message);
						},
						(message: any) => {
							reject(message);
						}
					);

					setTimeout(() => {
						resolve("Event Update  successfully.");
					}, 2000);
				} else {
					eventApi.saveEvent(
						param,
						(message: any, resp: any) => {
							resolve(message);
						},
						(message: any) => {
							reject(message);
						}
					);

					setTimeout(() => {
						resolve("Event added successfully.");
					}, 2000);
				}
			});
		};
		toast.promise(addEvent(param), {
			loading: eventID ? "Updating..." : "Adding...",
			success: (msg: any) => {
				navigate(URLS.EVENTS_LIST);
				return <b>{msg}</b>;
			},
			error: (msg: any) => {
				return <b>{msg}</b>;
			},
		});
	};

	useEffect(() => {
		if (locationRef.current) {
			if ((window as any).google) {
				initializeAutocomplete();
			} else {
				const script = document.createElement("script");
				script.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=maps,places,geometry&sensor=false`;
				script.async = true;
				script.defer = true;

				script.onload = () => {
					initializeAutocomplete();
				};
				document.head.appendChild(script);
			}
		}

		return () => {
			// setMap(null);
		};
	}, []);

	useEffect(() => {
		if (map) {
			//  console.log(latLng);
			const bounds = new (window as any).google.maps.LatLngBounds(latLng);
			map.fitBounds(bounds);

			setMap(map);
			map.setZoom(12);
		}

	}, [latLng]);

	useEffect(() => {
		let { list, selected } = getRelativeListWithMultiSelected(
			districtWatch,
			blocks,
			blockWatch,
			"districtId",
			"fkDistrictID",
			"blockID"
		);
		setFilteredBlocks(list);
		setValue("block", selected);
	}, [districtWatch]);

	useEffect(() => {
		let { list, selected } = getRelativeListWithMultiSelected(
			blockWatch,
			gpus,
			gpuWatch,
			"blockID",
			"fkBlockID",
			"gpiid"
		);
		setFilteredGpus(list);
		setValue("gpu", selected);
	}, [blockWatch]);

	useEffect(() => {
		let { list, selected } = getRelativeListWithMultiSelected(
			gpuWatch,
			wards,
			wardWatch,
			"gpiid",
			"fkgpuid",
			"wardID"
		);
		setFilteredWards(list);
		setValue("ward", selected);
	}, [gpuWatch]);

	useEffect(() => {
		if (eventID && districts && blocks && gpus && wards && eventtypes.length > 0 && eventorganisers.length > 0) {
			getEventData();
		}
	}, [eventID, districts, blocks, eventtypes, eventorganisers, gpus, wards]);

	return (
		<React.Fragment>
			<Header></Header>
			<LeftPanel></LeftPanel>
			<div className="main-content">
				<div className="page-content">
					<div className="container-fluid"> 

						<TitleBar title={`${(eventID) ? 'Edit' : 'Add'} Event`}
							breadcrumbs={{
								'Events': URLS.EVENTS_LIST,
								'Form': '#',
							}} />

						<div className="row">
							<div className="col-sm-3">
								<div className="white-box-card profile-card" data-aos="fade-up">
									<div className="profile-avatar">
										<img src={eventimage} alt="" />
										<div className="upload-pic-btn">
											<CiCirclePlus />
											<Controller
												defaultValue={""}
												control={control}
												name="eventPic"
												render={({
													field: { onChange, onBlur, value, name, ref },
												}) => (
													<input
														accept="image/*"
														type="file"
														ref={eventPicRef}
														onChange={(e) => {
															handleEventPicChange(e);
														}}
													/>
												)}
											/>
										</div>
										<div className="cam-icon">
											<IoCameraOutline />
										</div>
									</div>
								</div>
								<Error error={errors["eventPic"]} />
							</div>
							<div className="col-sm-9">
								<div className="white-box-card mb-4 h-100 " data-aos="fade-up">
									<form onSubmit={handleSubmit(addEventHandler)}>
										<div className="row">
											<div className="col-sm-12">
												<h3 className="card-heading mb-4 ">Event Details</h3>
												<div className="row">
													<div className="col-sm-6">
														<div className="mb-3">
															<label>Event Name<sup className="text-danger">*</sup></label>
															<Controller
																defaultValue={""}
																control={control}
																rules={{ required: "Event name is required" }}
																name="eventName"
																render={({
																	field: { onChange, onBlur, value, name, ref },
																}) => (
																	<FormTextInput
																		name={name}
																		onChange={onChange}
																		onBlur={onBlur}
																		value={value}
																		inputRef={ref}
																		type="text"
																		placeholder="Enter Event name"
																	/>
																)}
															/>

															<Error error={errors["eventName"]} />
														</div>
													</div>

													<div className="col-sm-6">
														<div className="mb-3">
															<label>Event type</label>
															<Controller
																defaultValue={""}
																control={control}
																name="eventype"
																render={({
																	field: { onChange, onBlur, value, name, ref },
																}) => (
																	<Select
																		name={name}
																		onChange={onChange}
																		onBlur={onBlur}
																		options={eventtypes ? eventtypes : []}
																		value={value}
																		placeholder="Enter Event type"
																	/>
																)}
															/>
															<Error error={errors["eventype"]} />
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-sm-12">
														<div className="mb-3">
															<label>Description</label>

															<Controller
																defaultValue={""}
																control={control}
																name="description"
																render={({
																	field: { onChange, onBlur, value, name, ref },
																}) => (
																	<Editor value={value} onChange={onChange} />
																)}
															/>

															<Error error={errors["description"]} />
														</div>
													</div>
												</div>
											</div>
											<div className="col-sm-12">
												<div className="row">
													<div className="col-sm-6">
														<div className="mb-3">
															<label>Date<sup className="text-danger">*</sup></label>
															<Controller
																defaultValue={""}
																control={control}
																rules={{ required: "Event Date is required" }}
																name="eventDate"
																render={({
																	field: { onChange, onBlur, value, name, ref },
																}) => (
																	<FormTextInput
																		name={name}
																		onChange={onChange}
																		onBlur={onBlur}
																		value={value}
																		inputRef={ref}
																		type="date"
																		min={eventID?null:showDate((new Date()).getTime(), 'YYYY-MM-DD')}
																		placeholder="Enter Date"
																	/>
																)}
															/>

															<Error error={errors["eventDate"]} />
														</div>
													</div>
													<div className="col-sm-6">
														<div className="mb-3">
															<label>Time<sup className="text-danger">*</sup></label>
															<Controller
																defaultValue={""}
																control={control}
																rules={{ required: "Event Time is required" }}
																name="eventTime"
																render={({
																	field: { onChange, onBlur, value, name, ref },
																}) => (
																	<FormTextInput
																		name={name}
																		onChange={onChange}
																		onBlur={onBlur}
																		value={value}
																		inputRef={ref}
																		type="time"
																		placeholder="Enter Time"
																	/>
																)}
															/>

															<Error error={errors["eventTime"]} />
														</div>
													</div>

													<div className="col-sm-12">
														<div className="mb-3">
															<label>Location<sup className="text-danger">*</sup></label>
															<Controller
																defaultValue={""}
																control={control}
																rules={{ required: "Location is required" }}
																name="location"
																render={({
																	field: { onChange, onBlur, value, name, ref },
																}) => (
																	<FormTextInput
																		name={name}
																		onChange={onChange}
																		onBlur={onBlur}
																		value={value}
																		inputRef={locationRef}
																		type="text"
																		placeholder="Enter Location"
																	/>
																)}
															/>

															<Error error={errors["location"]} />
														</div>
													</div>

													<div className="col-sm-12">
														<div className="mb-3">
															{isloaded ? (
																<GoogleMap
																	mapContainerStyle={containerStyle}
																	center={center}
																	zoom={12}
																	onLoad={onLoad}
																	onUnmount={onUnmount}
																>
																	<Marker
																		position={latLng}
																		draggable={true}
																		onDragEnd={(data: any) => {
																			// console.log("Markerdat", data);

																			setLatLng({
																				lat: data.latLng.lat(),
																				lng: data.latLng.lng(),
																			});
																			map.panTo({
																				lat: data.latLng.lat(),
																				lng: data.latLng.lng(),
																			});
																			setTimeout(() => {
																				if (map.zoom < 16) map.setZoom(16);
																			}, 400);

																			getAddress(
																				data.latLng.lat(),
																				data.latLng.lng()
																			);
																		}}
																	/>
																	{/* Child components, such as markers, info windows, etc. */}
																	<></>
																</GoogleMap>
															) : (
																""
															)}
														</div>
													</div>
													<div className="col-sm-6">
														<div className="mb-3">
															<label>Special Highlights<sup className="text-danger">*</sup></label>
															<Controller
																defaultValue={""}
																control={control}
																rules={{
																	required: "Special Highlights is required",
																}}
																name="specialhighlight"
																render={({
																	field: { onChange, onBlur, value, name, ref },
																}) => (
																	<FormTextInput
																		name={name}
																		onChange={onChange}
																		onBlur={onBlur}
																		value={value}
																		inputRef={ref}
																		type="text"
																		placeholder="Enter Special Highlights"
																	/>
																)}
															/>

															<Error error={errors["specialhighlight"]} />
														</div>
													</div>
													<div className="col-sm-6">
														<div className="mb-3">
															<label>Venue Name<sup className="text-danger">*</sup></label>
															<Controller
																defaultValue={""}
																control={control}
																name="venueName"
																rules={{
																	required: "Venue name is required",
																}}
																render={({
																	field: { onChange, onBlur, value, name, ref },
																}) => (
																	<FormTextInput
																		name={name}
																		onChange={onChange}
																		onBlur={onBlur}
																		value={value}
																		inputRef={ref}
																		type="text"
																		placeholder="Enter Venue name"
																	/>
																)}
															/>

															<Error error={errors["venueName"]} />
														</div>
													</div>
													<div className="col-sm-6">
														<div className="mb-3">
															<label>Organizer</label>
															<Controller
																defaultValue={""}
																control={control}
																name="organizer"
																render={({
																	field: {
																		onChange,
																		onBlur,
																		value,
																		name,
																		ref,
																	},
																}) => (
																	<Select
																		name={name}
																		onChange={onChange}
																		onBlur={onBlur}
																		options={eventorganisers ? eventorganisers : []}
																		value={value}
																		isMulti={true}
																		placeholder="Enter Organizer"
																	/>
																)}
															/>
															<Error error={errors["organizer"]} />
														</div>
													</div>

													<div className="col-12">
														<div className="my-4">
															<hr className="divider" />
														</div>
														<div className="col-sm-12">
															<h3 className="card-heading mb-4">Area</h3>
															<div className="row">
																<div className="col-sm-6">
																	<div className="mb-3">
																		<label>District</label>
																		<Controller
																			defaultValue={""}
																			control={control}
																			name="district"
																			render={({
																				field: {
																					onChange,
																					onBlur,
																					value,
																					name,
																					ref,
																				},
																			}) => (
																				<Select
																					name={name}
																					onChange={onChange}
																					onBlur={onBlur}
																					options={districts ? districts : []}
																					value={value}
																					isMulti={true} 
																				/>
																			)}
																		/>
																		<Error error={errors["district"]} />
																	</div>
																</div>
																<div className="col-sm-6">
																	<div className="mb-3">
																		<label>Block</label>
																		<Controller
																			defaultValue={""}
																			control={control}
																			name="block"
																			// rules={{ required: 'Block is required' }}
																			render={({
																				field: {
																					onChange,
																					onBlur,
																					value,
																					name,
																					ref,
																				},
																			}) => (
																				<Select
																					name={name}
																					onChange={onChange}
																					onBlur={onBlur}
																					options={
																						filteredBlocks ? filteredBlocks : []
																					}
																					value={value}
																					isMulti={true}
																				/>
																			)}
																		/>
																		<Error error={errors["block"]} />
																	</div>
																</div>
																<div className="col-sm-6">
																	<label>GPU</label>
																	<Controller
																		defaultValue={""}
																		control={control}
																		name="gpu"
																		render={({
																			field: {
																				onChange,
																				onBlur,
																				value,
																				name,
																				ref,
																			},
																		}) => (
																			<Select
																				name={name}
																				onChange={onChange}
																				onBlur={onBlur}
																				isMulti={true}
																				options={
																					filteredGpus ? filteredGpus : []
																				}
																				value={value} 
																			/>
																		)}
																	/>
																	<Error error={errors["gpu"]} />
																</div>
																<div className="col-sm-6">
																	<label>Ward</label>
																	<Controller
																		defaultValue={""}
																		control={control}
																		name="ward"
																		render={({
																			field: {
																				onChange,
																				onBlur,
																				value,
																				name,
																				ref,
																			},
																		}) => (
																			<Select
																				name={name}
																				onChange={onChange}
																				onBlur={onBlur}
																				options={
																					filteredWards ? filteredWards : []
																				}
																				value={value}
																				isMulti={true}  
																			/>
																		)}
																	/>
																	<Error error={errors["ward"]} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="mt-3 d-flex gap-2 justify-content-end ">
											<Link to={URLS.EVENTS_LIST} className="btn btn-default">
												Cancel
											</Link>
											<Button type="submit">Save</Button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Footer></Footer>
			</div>
		</React.Fragment>
	);
};

export default EventsAdd;
