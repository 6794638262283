import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config';

// import * as commonSaga from './commonSaga';
 import * as userSaga from './userSaga';
 import * as employeeSaga from './employeeSaga';
 import * as masterSaga from './masterSaga';
 import * as groupSaga from './groupSaga';
 import * as deviceSaga from './deviceSaga';
 import * as eventSaga from './eventSaga';
 import * as collateralSaga from './collateralSaga';
 import * as farmerSaga from './farmerSaga';
 import * as farmSaga from './farmSaga';
 import * as deviceInstallationStationSaga from './deviceInstallationStationSaga';
 import * as dashboardSaga from './dashboardSaga';
 import * as reportSaga from './reportSaga';
 import * as notificationSaga from './notificationSaga';



export function* sagas() {
  yield all([
    takeEvery(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeEvery(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeEvery(SAGA_ACTIONS.EMPLOYEE.ADD_EMPLOYEE, employeeSaga.addEmployee),
    takeEvery(SAGA_ACTIONS.EMPLOYEE.UPDATE_EMPLOYEE, employeeSaga.updateEmployee),
    takeEvery(SAGA_ACTIONS.EMPLOYEE.GET_EMPLOYEE, employeeSaga.getAllEmployee),
    takeEvery(SAGA_ACTIONS.EMPLOYEE.GET_ACTIVE_EMPLOYEE, employeeSaga.getAllActiveEmployee),
    takeEvery(SAGA_ACTIONS.EMPLOYEE.GET_EMPLOYEE_DETAILS, employeeSaga.getEmployeeDetails),

    takeEvery(SAGA_ACTIONS.EMPLOYEE.UPDATE_EMPLOYEE_STATUS, employeeSaga.updateEmployeeStatus),
    takeEvery(SAGA_ACTIONS.EMPLOYEE.DELETE_EMPLOYEE, employeeSaga.EmployeeDelete),

    takeEvery(SAGA_ACTIONS.MASTER.GET_DEPARTMENTS, masterSaga.getAllDepartments),
    takeEvery(SAGA_ACTIONS.MASTER.GET_DESIGNATIONS, masterSaga.getAllDesignations),
    takeEvery(SAGA_ACTIONS.MASTER.GET_DISTRICTS, masterSaga.getAllDistricts),
    takeEvery(SAGA_ACTIONS.MASTER.GET_GPUS, masterSaga.getAllGpus),
    takeEvery(SAGA_ACTIONS.MASTER.GET_WARDS, masterSaga.getAllWards), 
    takeEvery(SAGA_ACTIONS.MASTER.GET_BLOCKS, masterSaga.getAllBlocks),
    takeEvery(SAGA_ACTIONS.GROUP.GET_GROUPS, groupSaga.getAllGroups),
    takeEvery(SAGA_ACTIONS.GROUP.GET_GROUP_DETAILS, groupSaga.getGruopDetails),
    takeEvery(SAGA_ACTIONS.GROUP.SAVE_GROUP, groupSaga.saveGroup),
    takeEvery(SAGA_ACTIONS.GROUP.UPDATE_GROUP, groupSaga.updateGroup),
    takeEvery(SAGA_ACTIONS.GROUP.DELETE_GROUP, groupSaga.deleteGroup),
    takeEvery(SAGA_ACTIONS.GROUP.CHANGE_STATUS_GROUP, groupSaga.changeStatus),

    takeLatest(SAGA_ACTIONS.MASTER.ROLE.LIST, masterSaga.getAllRoles),
    takeLatest(SAGA_ACTIONS.MASTER.ROLE.DETAILS, masterSaga.getRoleDetails),
    takeLatest(SAGA_ACTIONS.MASTER.ROLE.SAVE, masterSaga.roleSave),
    takeLatest(SAGA_ACTIONS.MASTER.ROLE.UPDATE, masterSaga.roleUpdate),
    takeLatest(SAGA_ACTIONS.MASTER.ROLE.DELETE, masterSaga.roleDelete),

    takeLatest(SAGA_ACTIONS.MASTER.ROLE.PERMISSION, masterSaga.getRolePermission), 
    takeLatest(SAGA_ACTIONS.MASTER.ROLE.PERMISSION_SAVE, masterSaga.rolePermissionSave),

    takeLatest(SAGA_ACTIONS.MASTER.SETTINGS.EMAIL_SETTINGS.LIST, masterSaga.getAllEmailSettings),
    takeLatest(SAGA_ACTIONS.MASTER.SETTINGS.EMAIL_SETTINGS.DETAILS, masterSaga.getEmailSettingsDetails),
    takeLatest(SAGA_ACTIONS.MASTER.SETTINGS.EMAIL_SETTINGS.UPDATE, masterSaga.emailSettingsSave),
    

    takeEvery(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.LIST, deviceSaga.deviceUnitList),
    takeEvery(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.DELETE, deviceSaga.deviceUnitDetails),
    takeEvery(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.SAVE, deviceSaga.deviceUnitSave),
    takeEvery(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.UPDATE, deviceSaga.deviceUnitUpdate),
    takeEvery(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.CHANGE_STATUS, deviceSaga.deviceUnitChangeStatus),
    takeEvery(SAGA_ACTIONS.DEVICE.CATEGORY_UNIT.DELETE, deviceSaga.deviceUnitDelete),


    takeEvery(SAGA_ACTIONS.DEVICE.GET_DEVICES_TEPLATE, deviceSaga.getAllDevicetype),
    takeEvery(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_DETAILS, deviceSaga.deviceCategoryDetails),
    takeEvery(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_SAVE, deviceSaga.deviceCategorySave),
    takeEvery(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_UPDATE, deviceSaga.deviceCategoryUpdate),
    takeEvery(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_CHANGE_STATUS, deviceSaga.deviceCategoryChangeStatus),
    takeEvery(SAGA_ACTIONS.DEVICE.DEVICES_TEMPLATE_DELETE, deviceSaga.deviceCategoryDelete),
    takeEvery(SAGA_ACTIONS.DEVICE.DEVICES_PARAM_TYPE_ALL, deviceSaga.deviceParamTypeAll),
    takeEvery(SAGA_ACTIONS.DEVICE.DEVICES_UNIT_MASTER_ALL, deviceSaga.deviceUnitMasterAll),
    takeEvery(SAGA_ACTIONS.DEVICE.DEVICES_PARAM_MASTER_ALL, deviceSaga.deviceParamMasterAll),

    takeEvery(SAGA_ACTIONS.DEVICE.GET_DEVICES, deviceSaga.getAllDevice),
    takeEvery(SAGA_ACTIONS.DEVICE.GET_DEVICES_DETAILS, deviceSaga.getDeviceDetails),
    takeEvery(SAGA_ACTIONS.DEVICE.SAVE_DEVICES, deviceSaga.saveDevice),
    takeEvery(SAGA_ACTIONS.DEVICE.UPDATE_DEVICES, deviceSaga.updateDevice),
    takeEvery(SAGA_ACTIONS.DEVICE.CHANGE_DEVICES_STATUS, deviceSaga.changeDeviceStatus),
    takeEvery(SAGA_ACTIONS.DEVICE.DELETE_DEVICES, deviceSaga.deleteDevice),
    takeEvery(SAGA_ACTIONS.DEVICE.DEVICES_WORKING_STATUS, deviceSaga.getAllDeviceWorkingStatus),


    takeEvery(SAGA_ACTIONS.USER.FORGET_PASSWORD, userSaga.forgetPassword),
    takeEvery(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.restPassword),
    takeEvery(SAGA_ACTIONS.USER.PASSWORD_RESET, userSaga.restprofilePassword),


    takeEvery(SAGA_ACTIONS.EVENT.GET_EVENTS, eventSaga.getAllEvents),

    takeEvery(SAGA_ACTIONS.EVENT.GET_EVENTS_TYPES, eventSaga.getAllEventtypes),
    takeEvery(SAGA_ACTIONS.EVENT.GET_EVENTS_ORGANISER, eventSaga.getAllEventorganiser),

   
    takeEvery(SAGA_ACTIONS.EVENT.SAVE_EVENT, eventSaga.saveEvent),
    takeEvery(SAGA_ACTIONS.EVENT.UPDATE_EVENT, eventSaga.updateEvent),
    takeEvery(SAGA_ACTIONS.EVENT.DELETE_EVENT, eventSaga.deleteEvent),
    takeEvery(SAGA_ACTIONS.EVENT.CHANGE_STATUS_EVENT, eventSaga.changeStatus),
    takeEvery(SAGA_ACTIONS.EVENT.GET_EVENT_DETAILS, eventSaga.getEventDetails),
    takeEvery(SAGA_ACTIONS.EVENT.UPLOAD_EVENT_ATTENDEE, eventSaga.uploadEventAttendee),
    takeEvery(SAGA_ACTIONS.EVENT.GET_EVENT_FARMER_IMPORT_ALL, eventSaga.getEventFarmerImportAll),


    takeEvery(SAGA_ACTIONS.FARMER.GET_FARMER, farmerSaga.getAllFarmers),
    takeEvery(SAGA_ACTIONS.FARMER.GET_FARMER_DETAILS, farmerSaga.getFarmerDetails),
    takeEvery(SAGA_ACTIONS.FARMER.CHANGE_STATUS_FARMER, farmerSaga.changeStatus),
    takeEvery(SAGA_ACTIONS.FARMER.IMPORT_FARMER, farmerSaga.importFarmer),


    takeLatest(SAGA_ACTIONS.FARMS.LIST, farmSaga.farmList), 
    takeLatest(SAGA_ACTIONS.FARMS.DETAILS, farmSaga.farmDetails), 
    takeLatest(SAGA_ACTIONS.FARMS.SAVE, farmSaga.farmSave), 
    takeLatest(SAGA_ACTIONS.FARMS.UPDATE, farmSaga.farmUpdate), 
    takeLatest(SAGA_ACTIONS.FARMS.CHANGE_STATUS, farmSaga.farmChangeStatus), 
    takeLatest(SAGA_ACTIONS.FARMS.DELETE, farmSaga.farmDelete), 
    takeLatest(SAGA_ACTIONS.FARMS.IMPORT_FARMS, farmSaga.importFarms), 

    takeLatest(SAGA_ACTIONS.FARMS.FARMS_CROP_MAPPING_LIST, farmSaga.getFarmsCropMappingList), 
    takeLatest(SAGA_ACTIONS.FARMS.FARMS_CROP_MAPPING_SAVE, farmSaga.farmCropsMappingSave), 
    takeLatest(SAGA_ACTIONS.FARMS.FIELDS_HISTORY, farmSaga.getFieldsHistory), 

    takeLatest(SAGA_ACTIONS.FORECAST.SENSOR_RANGE_CHECKLIST, farmSaga.getForecastSensorRangeChecklists), 


    takeLatest(SAGA_ACTIONS.DASHBOARD.GET_DEVICE_AREAS, dashboardSaga.getAllDeviceAreas), 
    takeLatest(SAGA_ACTIONS.DASHBOARD.GET_AREA_PICTURES, dashboardSaga.getAreaPictures), 
    takeLatest(SAGA_ACTIONS.DASHBOARD.GET_AREA_CHARTS, dashboardSaga.getAreaChartData), 
    takeLatest(SAGA_ACTIONS.DASHBOARD.GET_CURRENT_WEATHER_REPORT, dashboardSaga.getCurrentWeatherReport), 
    takeLatest(SAGA_ACTIONS.DASHBOARD.GET_WEATHER_HISTORY_REPORT, dashboardSaga.getWeatherHistoryReport), 

    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.LIST, deviceInstallationStationSaga.deviceInstallationStationList), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.DETAILS, deviceInstallationStationSaga.deviceInstallationStationDetails), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.SAVE, deviceInstallationStationSaga.deviceInstallationStationSave), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.UPDATE, deviceInstallationStationSaga.deviceInstallationStationUpdate), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.CHANGE_STATUS, deviceInstallationStationSaga.deviceInstallationStationChangeStatus), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.DELETE, deviceInstallationStationSaga.deviceInstallationStationDelete), 

    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.UN_ASSIGNED_STATION_DEVICE, deviceInstallationStationSaga.getUnAssignedStationDevice), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.ASSIGNED_STATION_DEVICE, deviceInstallationStationSaga.getAssignedStationDevice), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.SAVE_STATION_DEVICE, deviceInstallationStationSaga.saveStationDevice), 

    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.STATION_CROP_ALL, deviceInstallationStationSaga.getStationCropAll), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.ASSIGNED_STATION_CROP, deviceInstallationStationSaga.getAssignedStationCrop), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.SAVE_STATION_CROP, deviceInstallationStationSaga.saveStationCrop), 
    takeLatest(SAGA_ACTIONS.DEVICE_INSTALLATION_STATION.SAVE_STATION_IMAGES, deviceInstallationStationSaga.saveStationImages), 

        
    //============= START:: Collateral Saga  ==========================//
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.LIST, collateralSaga.cropCategoryList), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.DETAILS, collateralSaga.cropCategoryDetails), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.SAVE, collateralSaga.cropCategorySave), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.UPDATE, collateralSaga.cropCategoryUpdate), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.CHANGE_STATUS, collateralSaga.cropCategoryChangeStatus), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_CATEGORY.DELETE, collateralSaga.cropCategoryDelete), 


    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.LIST, collateralSaga.cropList), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.DETAILS, collateralSaga.cropDetails), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.SAVE, collateralSaga.cropSave), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.UPDATE, collateralSaga.cropUpdate), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.CHANGE_STATUS, collateralSaga.cropChangeStatus), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.DELETE, collateralSaga.cropDelete), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.FILE_UPLOAD, collateralSaga.cropFileUpload), 

    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.CULTIVATION_METHODS_VIEW, collateralSaga.cropCultivationMethods), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.CULTIVATION_METHODS_UPDATE, collateralSaga.updateCropCultivationMethods), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.PROTECTION_METHODS_VIEW, collateralSaga.cropProtectionMethods), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP.PROTECTION_METHODS_UPDATE, collateralSaga.updateCropProtectionMethods), 

    
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.LIST, collateralSaga.cropProtectionMethodList), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.DETAILS, collateralSaga.cropProtectionMethodDetails), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.SAVE, collateralSaga.cropProtectionMethodSave), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.UPDATE, collateralSaga.cropProtectionMethodUpdate), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.CHANGE_STATUS, collateralSaga.cropProtectionMethodChangeStatus), 
    takeLatest(SAGA_ACTIONS.COLLATERAL.CROP_PROTECTION_METHOD.DELETE, collateralSaga.cropProtectionMethodDelete), 


    //============= END:: Collateral Saga  ==========================//
 

    //====================== START:: REPORT Section ===============//
    takeLatest(SAGA_ACTIONS.REPORT.FARMER_LIST, reportSaga.getFarmerList),  
    takeLatest(SAGA_ACTIONS.REPORT.FARMER_CONTACT_LIST, reportSaga.getFarmerContactList),  
    takeLatest(SAGA_ACTIONS.REPORT.FARM_LIST, reportSaga.getFarmList),  
    takeLatest(SAGA_ACTIONS.REPORT.FARMERS_TRAINING_ATTENDANCE_LIST, reportSaga.getFarmerTrainingAttendanceList),  

    //====================== END:: REPORT Section ===============//


    //====================== START:: Notification Section ===============//
    takeLatest(SAGA_ACTIONS.NOTIFICATION.MANUAL_NOTIFICATION, notificationSaga.getNotificationList),  
    takeLatest(SAGA_ACTIONS.NOTIFICATION.NOTIFICATION_DETAILS, notificationSaga.getNotificationDetails),  
    takeLatest(SAGA_ACTIONS.NOTIFICATION.NOTIFICATION_BROADCAST, notificationSaga.notificationStore),    
    takeLatest(SAGA_ACTIONS.NOTIFICATION.INVITE_FARMERS_STORE, notificationSaga.inviteFramerNotification),   

    //====================== END:: Notification Section ===============//



  ]);
}