import { toast } from "react-hot-toast";
//import { useCommonApi } from "./hooks/api/common/commonApiHook";
import { format } from "date-fns";
import moment from "moment";
import { useDistricts } from "./hooks/masterHook";

export function usNumber(e: any) {
    let n = e.target.value.replace(/[^\d]/g, '');
    var x = n.match(/(\d{3})(\d*)/);
    var y = n.match(/(\d{3})(\d{3})(\d*)/);
    var z = n.match(/(\d{3})(\d{3})(\d{4})/);
    if (z) {
        e.target.value = '(' + z[1] + ') ' + z[2] + '-' + z[3];
    } else if (y) {
        e.target.value = '(' + y[1] + ') ' + y[2] + ((y[3] != '') ? ('-' + y[3]) : '');
    } else if (x && x[2] != '') {
        e.target.value = '(' + x[1] + ') ' + x[2];
    } else {
        e.target.value = n;
    }

}

export function creditCard(e: any) {
    let n = e.target.value.replace(/\D/g, '');
    var a = n.match(/(\d{4})(\d*)/);
    var b = n.match(/(\d{4})(\d{4})(\d*)/);
    var c = n.match(/(\d{4})(\d{4})(\d{4})(\d*)/);
    var d = n.match(/(\d{4})(\d{4})(\d{4})(\d{4})(\d*)/);
    if (d) {
        e.target.value = d[1] + ' ' + d[2] + ' ' + d[3] + ' ' + d[4];
    } else if (c) {
        e.target.value = c[1] + ' ' + c[2] + ' ' + c[3] + ((c[4] != '') ? (' ' + c[4]) : '');
    } else if (b) {
        e.target.value = b[1] + ' ' + b[2] + ((b[3] != '') ? (' ' + b[3]) : '');
    } else if (a && a[2] != '') {
        e.target.value = a[1] + ' ' + a[2];
    }

}

export function creditCardExp(e: any) {
    let n = e.target.value.replace(/\D/g, '');
    var x = n.match(/(\d{2})(\d*)/);
    var y = n.match(/(\d{2})(\d{2})(\d*)/);
    if (y) {
        e.target.value = y[1] > 12 ? 12 : y[1] + ' / ' + y[2]
    } else if (x && x[2] != '') {
        e.target.value = x[1] > 12 ? 12 : x[1] + ' / ' + x[2];
    }
}

export function CheckNumber(e: any) {
    let n: any = e.target.value.replace(/^0+/, '').replace(/[^\d.]/g, '')
    let min: number = parseFloat(e.target.getAttribute('min'));
    let max: number = parseFloat(e.target.getAttribute('max'));
    if (n == '') { n = 0 }
    if (min && parseFloat(n) < min) {
        n = min
    }

    if (max && parseFloat(n) > max) {
        n = max
    }
    e.target.value = n;
}

export function checkImageBase64Code(url: any) {
    if (url.search('data:image/') == 0) {
        return url;
    } else {

        return 'data:image/jpeg;base64,' + url
    }

}

export function formatDate(date: any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function handleKeyPressNumber(event: any) {
    if (!/[0-9]|ArrowLeft|ArrowRight|Delete|Backspace/.test(event.key)) {
        event.preventDefault();
    }
}

export function handleKeyPressDecimalNumber(event: any) {
    if (!/[.0-9]|ArrowLeft|ArrowRight|Delete|Backspace/.test(event.key)) {
        event.preventDefault();
    } else if (!/^[0-9]*\.?[0-9]{0,1}$/.test(event.target.value) && !['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace'].includes(event.key)) {
        event.preventDefault()
    }
}

export const changeString = (str: any, len: number) => {
    if (str.length > 0 && str.length > len) {
        return str.substr(0, len)
    } else {
        return str
    }
}

export const extractContent = (s: any) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    span.innerHTML = span.innerHTML.replace(/\&nbsp;/g, '');
    return span.textContent || span.innerText;
}

export const formatNumber = (num?: any) => {
    // if (num) {
    //     return (Math.floor(Number(num) * 100) / 100).toLocaleString();
    // }
    // return 0
    if (num && num >= 0) {
        return (Math.floor(Number(num) * 100) / 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
    return "0.00";
}

export const handleKeyUpDown = (event: any) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault(); // prevent up/down arrow keys
    }
};

export const handleWheel = (event: any) => {
    event.preventDefault(); // prevent scrolling
};

export const handleFocus = (e: any) => {
    const input = e.target;
    input.addEventListener('wheel', disableScroll);
}

export const handleBlur = (e: any) => {
    const input = e.target;
    input.removeEventListener('wheel', disableScroll);
}

export const disableScroll = (e: any) => {
    e.preventDefault();
}

export const deleteNotification = (id: any, type: string, deleteFunc: Function, callbackFuncs: any) => {
    toast(
        (t) => (
            <span>
                Are you sure you want to delete {type}.
                {/* <strong>{id}</strong> */}
                <br />
                <hr />
                <div className="d-flex align-items-center justify-content-end">
                    <button
                        className="btn btn-danger btn-sm m-1"
                        onClick={() => {
                            toast.dismiss(t.id);
                            toast.promise(deleteFunc(id), {
                                loading: "working on request...",
                                success: (msg: any) => {
                                    // callbackFuncs?.forEach((func: any) => func()) 
                                    callbackFuncs();
                                    return <b>{type} removed successfully</b>
                                },
                                error: (msg) => { return <b>{msg}</b> },
                            }, { duration: 2000 });
                        }}
                    >
                        Yes
                    </button>

                    <button
                        className="btn btn-secondary btn-sm m-1"
                        onClick={() => toast.dismiss(t.id)}
                    >
                        No
                    </button>
                </div>
            </span>
        ),
        { duration: 15000, className: 'withdraw-toast' }
    );
}

export const generateYearList = () => {
    const currentYear = new Date().getFullYear();
    const futureYears = currentYear + 3;
    const yearList = [];

    for (let year = currentYear; year <= futureYears; year++) {
        yearList.push(year);
    };

    let tempList: any = [];
    yearList.forEach((item: any) => {
        tempList.push({
            label: item,
            value: item
        })
    })
    tempList.unshift({
        label: currentYear - 1,
        value: currentYear - 1
    })
    return tempList
};

export const getNextDate = (givenDate: any) => {
    const currentDate = new Date(givenDate);
    const nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    return nextDate
}

export const showDate = (dbDate: any, dateFormat: any = 'DD MMM, YYYY') => {
    return (dbDate !== '') ? moment(dbDate).format(dateFormat) : "";
}

export const utcToLocalDateTime = (dbDate: any, dateFormat: any = 'DD MMM, YYYY') => {
    var utc = new Date(dbDate);
    var offset = utc.getTimezoneOffset();
    var local = new Date(utc.getTime() + offset * 60000);

    return (dbDate !== '') ? moment(local).format(dateFormat) : "";
}

export const UpdateWithVariables = (url: string, data: any) => {
    if (data) {
        let dynamicParams = url.match(/:[A-Z_]*/gi)
        dynamicParams?.map(key => {
            if (key.length > 1) url = url.replace(key, data[key.replace(':', '')])
        })
    }
    return url
}

export const replaceUrlParams = (url: string, params: any) => {
    Object.keys(params).map((param) => {
        url = url.replace(`:${param}`, params[param])
    })
    return url
}

export const convertLocalFileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const getSelectedValue = (value: any, node: string, options: any[]) => {
    let getValue = (val: any) => {
        let result = null;
        options && options.map((option: any) => {
            if (option[node] == val) {
                result = option;
            }
        })
        return result;
    }
    if (Array.isArray(value)) {
        let selected: any = []

        value.map((val: any) => {
            selected.push(getValue(val))
        })
        return selected;
    } else {
        return getValue(value);
    }
}

export const getRelativeListWithMultiSelected = (parentList: any[], childList: any[], selectedChild: any[], parentKey: string, childKey: string, childId: string) => {

    let list: any = [];
    let selected: any = [];


    try {
        parentList && parentList?.map((parent: any) => {
            childList?.map((child: any) => {
                if (parent[parentKey] == child[childKey]) {
                    list.push(child);
                }
            })
        })
        selectedChild && selectedChild?.map((child: any) => {
            list.map((fChild: any) => {
                if (fChild[childId] == child[childId]) {
                    selected.push(child)
                }
            })
        });

    } catch (error) {

    }



    return {
        list, selected
    };
}

export const getRelativeList = (data: any[], parentID: any, parentKey: string) => {

    let list: any = [];
    let selected: any = [];


    try {
        /* parentList && parentList?.map((parent: any) => {
            childList?.map((child: any) => {
                if (parent[parentKey] == child[childKey]) {
                    list.push(child);
                }
            })
        })
        selectedChild && selectedChild?.map((child: any) => {
            list.map((fChild: any) => {
                if (fChild[childId] == child[childId]) {
                    selected.push(child)
                }
            })
        }); */

    } catch (error) {

    }



    return { list };
}

export const isImageUrl = (url: string) => {
    return /\.(jpg|jpeg|png|webp|gif|svg)$/.test(url);
}


export const localFileToBase64 = (files: any) => {
    return new Promise((resolve, reject) => {
        let base64Datas: any = [];
        Array.from(files).map((file: any) => {
            let reader = new FileReader();
            reader.onloadend = () => {
                base64Datas.push(reader.result as string | null);
                if (files.length == base64Datas.length) {
                    resolve(base64Datas)
                }
            };
            reader.readAsDataURL(file);
        })
    })
}

export const toastHandeler = (func: any, data: any, message: string) => {
    return new Promise((resolve: any, reject: any) => {
        toast.promise(func(data), {
            loading: message,
            success: (msg: any) => {
                resolve(msg);
                return <b>{msg}</b>
            },
            error: (msg: any) => { reject(msg); return <b>{msg}</b> },
        });
    })
}

export const getSerialNumber = (page: number, limit: number, i: number) => {
    return ((page - 1) * limit) + i + 1
}


export const _weatherEmoji = (rainfall: number, solarRadiation: number) => {
    let icon: any = '/images/emoji/weather/w1.svg'
    if (rainfall > 0) { // Rain 
        if (rainfall < 1) {
            icon = 'https://www.amcharts.com/wp-content/themes/amcharts4/css/img/icons/weather/animated/rainy-5.svg'
        } else {
            icon = 'https://www.amcharts.com/wp-content/themes/amcharts4/css/img/icons/weather/animated/rainy-6.svg'
        }
    } else if (solarRadiation > 0) { // Sunny 
        icon = 'https://www.amcharts.com/wp-content/themes/amcharts4/css/img/icons/weather/animated/cloudy-day-3.svg'
    } else { // Night 
        icon = 'https://www.amcharts.com/wp-content/themes/amcharts4/css/img/icons/weather/animated/cloudy-night-2.svg'
    }
    return icon
}

export const _temperatureEmoji = (value: number) => {
    let icon: any = ''
    let path = '/images/emoji/temperature/'
    if (value > 35) { // Red 
        icon = 'temp_red.png'
    } else if (31 < value && value <= 35) { // Orange 
        icon = 'temp_orange.png'
    } else if (23 < value && value <= 31) { // Yellow 
        icon = 'temp_yellow.png'
    } else if (14 < value && value <= 23) { // Green 
        icon = 'temp_green.png'
    } else if (5 <= value && value <= 14) { // Light Blue
        icon = 'temp_blue.png'
    } else if (5 > value) { // Deep Blue
        icon = 'temp_dark_blue.png'
    }
    return path + icon
}

export const _phEmoji = (value: number) => {
    let icon: any = ''
    let path = '/images/emoji/'
    if (value > 9) { // Purple Sad 
        icon = 'purple_sad_face.png'
    } else if (7.5 < value && value <= 9) { // Light Blue 
        icon = 'light_blue_face.png'
    } else if (6.5 < value && value <= 7.5) { // Green Smile 
        icon = 'green_smile_face.png'
    } else if (5 < value && value <= 6.5) { // lemon Yellow 
        icon = 'lemon_yellow_face.png'
    } else if (5 > value) { // Red Sad
        icon = 'red_sad_face.png'
    }
    return path + icon
}
export const _phText = (value: number) => {
    //     Extremely Acidic: < 4.5
    // Very Strongly Acidic: 4.5 - 5.0
    // Strongly Acidic: 5.1 - 5.5
    // Moderately Acidic: 5.6 - 6.0
    // Slightly Acidic: 6.1 - 6.5
    // Neutral: 6.6 - 7.3
    // Slightly Alkaline: 7.4 - 7.8
    // Moderately Alkaline: 7.9 - 8.4
    // Strongly Alkaline: 8.5 - 9.0
    // Very Strongly Alkaline: > 9.0

    let icon: any = 'Alkaline Soil'
    if (value > 9) { // Purple Sad 
        icon = 'Very Strongly Alkaline'
    } else if (8.5 < value && value <= 9) { // Light Blue 
        icon = 'Strongly Alkaline'
    } else if (7.9 < value && value <= 8.4) { // Green Smile 
        icon = 'Moderately Alkaline'
    } else if (7.4 < value && value <= 7.8) { // lemon Yellow 
        icon = 'Slightly Alkaline'
    } else if (6.6 < value && value <= 7.3) { // lemon Yellow 
        icon = 'Neutral'
    } else if (6.1 < value && value <= 7.5) { // lemon Yellow 
        icon = 'Slightly Acidic'
    } else if (5.6 < value && value <= 6.0) { // lemon Yellow 
        icon = 'Moderately Acidic'
    } else if (5.1 < value && value <= 5.5) { // lemon Yellow 
        icon = 'Strongly Acidic'
    } else if (4.5 < value && value <= 5.0) { // lemon Yellow 
        icon = 'Very Strongly Acidic'
    } else if (4.5 > value) { // Red Sad
        icon = 'Extremely Acidic'
    }
    return icon
}
export const _moistureEmoji = (value: number) => {
    let icon: any = ''
    let path = '/images/emoji/'
    if (value > 60) { // Red Sad Face 
        icon = 'red_sad_face.png'
    } else if (20 <= value && value <= 60) { // Green Smile  
        icon = 'green_smile_face.png'
    } else if (20 > value) { // Purple Sad Face
        icon = 'purple_sad_face.png'
    }
    return path + icon
}

export const _salinityEmoji = (value: number) => {
    // Low Salinity (Good for Most Crops): 0 - 0.8 dS/m
    // Medium Salinity (Some Sensitive Crops May Show Stress): 0.8 - 1.6 dS/m
    // High Salinity (Only Salt-Tolerant Crops Grow Well): 1.6 - 3.0 dS/m
    // Very High Salinity (Few Crops Can Tolerate): > 3.0 dS/m*/

    let icon: any = ''
    let path = '/images/emoji/'
    let salinityValue = value / 640;
    if (salinityValue > 3.0) {
        icon = 'red_sad_face.png';
    } else if (salinityValue >= 1.6) {
        icon = 'purple_sad_face.png';
    } else if (salinityValue >= 0.8) {
        icon = 'yellow_face.png';
    } else {
        icon = 'green_smile_face.png';
    }

    return path + icon
}
export const _ecEmoji = (value: number) => {
    // EC condition:
    let icon: any = ''
    let path = '/images/emoji/'
    let ecValue_InDs = value * 1000;

    if (ecValue_InDs > 3.0) {
        icon = 'red_sad_face.png';
    } else if (ecValue_InDs >= 1.6) {
        icon = 'purple_sad_face.png';
    } else if (ecValue_InDs >= 0.8) {
        icon = 'yellow_face.png';
    } else {
        icon = 'green_smile_face.png';
    }

    return path + icon
}

export const _humidityEmoji = (value: number) => {
    let icon: any = ''
    let path = '/images/emoji/'
    if (value > 80) { // red sad face
        icon = 'red_sad_face.png';
    } else if (70 < value && value <= 80) { // normal no laughs face
        icon = 'yellow_face.png';
    } else if (40 < value && value <= 70) { // green smile face
        icon = 'green_smile_face.png';
    } else { // yellow sad face
        icon = 'yellow_sad_face.png';
    }

    return path + icon
}

export const _rainfallEmoji = (value: number) => {
    let icon: any = ''
    let path = '/images/emoji/'
    if (5 <= value && value < 10) { // Green Smile
        icon = 'green_smile_face.png';
    } else if (10 <= value && value <= 15) { // normal no laughs face
        icon = 'yellow_face.png';
    } else if (5 > value || value > 15) { // yellow sad face 
        icon = 'yellow_sad_face.png';
    }

    return path + icon
}

export const _windSpeedEmoji = (value: number) => {
    let icon: any = ''
    let path = '/images/emoji/'
    if (0 < value && value <= 10) { // good
        icon = 'green_smile_face.png';
    } else if (10 < value && value <= 20) { // normal
        icon = 'light_blue_face.png';
    } else if (value > 20) { // bad 
        icon = 'red_sad_face.png';
    }

    return path + icon
}
export const _selarRadiationEmoji = (value: number) => {
    let icon: any = ''
    let path = '/images/emoji/'
    if (200 <= value && value <= 600) { // good
        icon = 'green_smile_face.png';
    } else if (600 < value && value <= 800) { // normal
        icon = 'light_blue_face.png';
    } else if (200 > value || value > 800) { // bad 
        icon = 'red_sad_face.png';
    }
    return path + icon
}

export const _co2Emoji = (value: number) => {
    let icon: any = ''
    let path = '/images/emoji/'
    if (300 <= value && value <= 1000) { // good
        icon = 'green_smile_face.png';
    } else if (1000 < value && value <= 1200) { // normal
        icon = 'light_blue_face.png';
    } else if (300 > value || value > 1200) { // bad 
        icon = 'red_sad_face.png';
    }
    return path + icon
}

export const validateEmail = (email: string) => {

    // custom email regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || email.length === 0) {
        return false;
    }

    if (regex.test(email)) {
        return true;
    } else {
        return false;
    }
}



